import { ProAssistantTaskUpdate } from "shared/models/pro_assistant/ProAssistantModel";

type Props = {
  taskUpdate?: ProAssistantTaskUpdate;
};

export const ChatTaskUpdate: React.FC<Props> = ({ taskUpdate }) => {
  return (
    <div>
      {taskUpdate && (
        <div className="mb-4 mt-4 flex justify-center">
          <div className="flex items-center gap-2 rounded-lg bg-gray-200 px-2.5 py-1 text-sm font-semibold dark:bg-gray-800">
            {taskUpdate.status === "pending" ? (
              <>
                <div className="animate-spin text-green-500">
                  <svg
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M 4 12 a 8 8 0 0 1 8 -8 V 0 C 5.373 0 0 5.373 0 12 h 4 z A 8.001 8.001 0 0 1 12 4 v 0 c -4 0 -8 3 -8 8 h -2 z z"
                    ></path>
                  </svg>
                </div>
                <div>{taskUpdate.update}</div>
                {taskUpdate.progress_percentage && (
                  <div>{taskUpdate.progress_percentage}&#37;</div>
                )}
              </>
            ) : (
              <>
                <div className="text-green-600">
                  <svg
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <div>{taskUpdate.update}</div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
