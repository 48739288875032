import { useCallback, useEffect, useState } from "react";
import { DateRange } from "react-aria";
import { useTranslation } from "react-i18next";
import { DataTable } from "shared/components/common/datatable/DataTable";
import { DateRangeSelector } from "shared/components/common/DateRangeSelector";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { Loading } from "shared/types/enums";
import { CurrencyFormat } from "shared/utils/currency";
import { formatDateTime } from "shared/utils/date";
import { parseDate } from "@internationalized/date";
import { Client } from "shared/models/client/ClientModel";
import { MultiClientPicker } from "modules/client_picker/MultiClientPicker";
import { Modal } from "shared/components/common/Modal";
import { TradingOrder } from "shared/models/trading/TradingOrderModel";
import { OrderDetails } from "modules/client_orders/OrderDetails";
import { Button } from "shared/components/controls/Button";
import useRecentOrdersStore from "shared/store/recentOrdersStore";
import useClientsStore from "shared/store/clientsStore";

export const RecentOrders: React.FC = () => {
  const { t } = useTranslation();
  const [selectedOrder, setSelectedOrder] = useState<TradingOrder | null>(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const { orders, loading, filters, fetchRecentOrders } =
    useRecentOrdersStore();
  const { clients, fetchClients } = useClientsStore();
  const submittedDate: DateRange | undefined =
    filters.submitted_to && filters.submitted_from
      ? {
        start: parseDate(filters.submitted_from),
        end: parseDate(filters.submitted_to),
      }
      : undefined;

  useEffect(() => {
    fetchClients();
    fetchRecentOrders();
  }, []);

  useEffect(() => {
    if (selectedOrder) {
      setShowDetailsModal(true);
    }
  }, [selectedOrder]);

  const handleDateRangeChange = useCallback(
    (dateRange: DateRange | undefined) => {
      fetchRecentOrders({
        ...filters,
        submitted_from: dateRange?.start.toString(),
        submitted_to: dateRange?.end.toString(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters.submitted_from, filters.submitted_to],
  );

  const handleClientIdsChange = useCallback(
    (clientIds: string[]) => {
      fetchRecentOrders({
        ...filters,
        client_ids: clientIds,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters.client_ids],
  );

  return (
    <Panel>
      <Modal open={showDetailsModal} setOpen={setShowDetailsModal}>
        {selectedOrder && (
          <OrderDetails
            order={selectedOrder}
            clientId={"" + selectedOrder?.user_id}
          />
        )}
        <Button
          label="global.close"
          btnStyle="blank"
          onClick={() => setShowDetailsModal(false)}
          className="mt-4 w-full"
        />
      </Modal>
      <div className="mb-4 flex items-center justify-between gap-4">
        <Labels.H3>{t("orders.recent")}</Labels.H3>
        <DateRangeSelector
          dateRange={submittedDate}
          setDateRange={handleDateRangeChange}
          placeholder={t("fees.selectDateRange")}
          clearable
        />
      </div>
      <MultiClientPicker onChange={handleClientIdsChange} />
      <div className="mt-4">
        <DataTable
          data={orders}
          loading={!orders.length && loading === Loading.InProgress}
          defaultSortSettings={{ direction: "desc", by: "created_at" }}
          columns={[
            "symbol",
            "side",
            "qty",
            "status",
            "filled_avg_price",
            "filled_qty",
            "created_at",
            "filled_at",
            "client",
            "origin",
          ]}
          headerLabels={{
            symbol: t("orders.recentHeaders.symbol"),
            side: t("orders.recentHeaders.side"),
            qty: t("orders.recentHeaders.qty"),
            status: t("orders.recentHeaders.status"),
            filled_avg_price: t("orders.recentHeaders.filled_avg_price"),
            filled_qty: t("orders.recentHeaders.filled_qty"),
            client: t("orders.recentHeaders.client"),
            created_at: t("orders.recentHeaders.created_at"),
            filled_at: t("orders.recentHeaders.filled_at"),
            origin: t("orders.recentHeaders.origin"),
          }}
          onRowClicked={(order) => setSelectedOrder(order)}
          sortableValue={(key, entry) => {
            if (key === "filled_avg_price") {
              return parseFloat(entry[key]) || -1;
            }
            if (key === "client") {
              const client: Client | undefined = clients.find(
                (c) => String(c.user_id) === String(entry["user_id"]),
              );
              return client?.summary.client_name || "";
            }
            return entry[key];
          }}
          cellFormatter={(key, value, entry: Record<string, any>) => {
            if (["created_at", "filled_at"].includes(key)) {
              return value ? formatDateTime(value) : "";
            }
            if (key === "symbol") {
              return <span className="font-semibold">{value}</span>;
            }
            if (key === "side") {
              return (
                <span
                  className={
                    value === "buy"
                      ? "text-sb-green-1001"
                      : "text-sb-magenta-500"
                  }
                >
                  {t(`orders.${value}`)}
                </span>
              );
            }
            if (key === "filled_avg_price") {
              return value ? CurrencyFormat.format(value) : "";
            }
            if (key === "status") {
              return t(`orders.statuses.${value}`);
            }
            if (key === "client") {
              const userId = entry["user_id"];
              if (!userId) {
                return "";
              }
              const client = clients.find(
                (c) => String(c.user_id) === String(userId),
              );
              return client?.summary.client_name;
            }
            if (key === "origin") {
              return t(`orders.origins.${value}`);
            }
            return value;
          }}
        />
      </div>
    </Panel>
  );
};
