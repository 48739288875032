import { ClientPicker } from "modules/client_picker/ClientPicker";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import useAssets from "shared/hooks/useAssets";
import useRealtimeTicker from "shared/hooks/useRealtimeTicker";
import { Client } from "shared/models/client/ClientModel";
import {
  CreateTradingOrder,
  Side,
} from "shared/models/trading/TradingOrderModel";
import { PositionsService } from "shared/services/positions/PositionsService";
import { BuyAsset } from "./buy/BuyAsset";
import { BuySellTabs } from "./BuySellTabs";
import { ClientBuyingPower } from "./ClientBuyingPower";
import { OrderSuccessModal } from "./OrderSuccessModal";
import { PositionValues } from "./PositionValues";
import { ClosePositionModal } from "./sell/ClosePositionModal";
import SellAsset from "./sell/SellAsset";
import useClientDetailsStore from "shared/store/clientDetailsStore";
import useClientsStore from "shared/store/clientsStore";
import useRecentOrdersStore from "shared/store/recentOrdersStore";

type Props = {
  assetId?: string;
  compact?: boolean;
};

export const AssetTrade: React.FC<Props> = ({ assetId, compact }) => {
  const { t } = useTranslation();
  const { latestPrice } = useRealtimeTicker(assetId || "");
  const [activeSide, setActiveSide] = useState<Side>("buy");

  const [searchParams] = useSearchParams();
  const { clients, fetchClients } = useClientsStore();
  const [selectedClient, setSelectedClient] = useState<Client | undefined>();
  const [closeAllModal, setCloseAllModal] = useState(false);
  const [closePositionLoading, setClosePositionLoading] = useState(false);

  const { fetchRecentOrders } = useRecentOrdersStore();

  const userId = selectedClient?.user_id || "";
  const clientDetails = useClientDetailsStore(
    (state) => state.clientDetails[userId],
  );
  const positions = clientDetails?.positions || [];

  const fetchPositions = useClientDetailsStore.getState().fetchPositions;

  const { assets_map } = useAssets();
  const [successOrder, setSuccessOrder] = useState<CreateTradingOrder>();

  const asset = assets_map[assetId || ""];
  const hasPosition = positions?.filter((p) => p.symbol === assetId).length > 0;

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    const clientId = searchParams.get("clientId");
    if (clientId) {
      const client = clients.find(
        (c) => String(c.user_id) === String(clientId),
      );
      if (client) {
        setSelectedClient(client);
      }
    }
  }, [clients, searchParams]);

  useEffect(() => {
    if (selectedClient?.user_id) {
      fetchPositions(selectedClient.user_id);
    }
  }, [selectedClient?.user_id]);

  const refreshOrders = () => {
    fetchRecentOrders();
    if (selectedClient?.user_id) {
      fetchPositions(selectedClient.user_id);
    }
  };

  const closeAll = () => {
    const client = selectedClient;
    if (client) {
      setClosePositionLoading(true);
      // @ts-ignore
      PositionsService.closePosition(asset.symbol, client.user_id)
        .then(() => {
          fetchPositions(selectedClient.user_id);
          toast.success(t("trade.positionClosed"));
        })
        .finally(() => {
          setClosePositionLoading(false);
          setCloseAllModal(false);
        });
    }
  };

  return (
    <Panel>
      <OrderSuccessModal order={successOrder} setOrder={setSuccessOrder} />
      {asset && (
        <ClosePositionModal
          open={closeAllModal}
          setOpen={setCloseAllModal}
          loading={closePositionLoading}
          onClosePosition={closeAll}
          symbol={asset.symbol}
        />
      )}
      {!compact && (
        <div className="mb-4 flex items-center justify-between border-b border-b-sb-gray-200 pb-4">
          <BuySellTabs active={activeSide} setActive={setActiveSide} />
          {activeSide === "buy" ? (
            <ClientBuyingPower client={selectedClient} />
          ) : (
            <PositionValues
              latestPrice={latestPrice}
              position={positions?.find((p) => p.symbol === asset?.symbol)}
            />
          )}
          <div className="w-fit">
            <div>{t("clients.account")}</div>
            <ClientPicker onChange={setSelectedClient} value={selectedClient} />
          </div>
        </div>
      )}
      <div className="space-y-4">
        {compact && (
          <div className="flex justify-between gap-4">
            <div className="flex gap-4">
              <div className="w-fit">
                <div>{t("clients.account")}</div>
                <div className="flex items-center gap-4">
                  <ClientPicker
                    onChange={setSelectedClient}
                    value={selectedClient}
                  />
                  <BuySellTabs active={activeSide} setActive={setActiveSide} />
                </div>
              </div>
            </div>
            {activeSide === "sell" && asset && hasPosition && (
              <div
                className="flex cursor-pointer justify-end font-semibold text-sb-green-1001"
                onClick={() => setCloseAllModal(true)}
              >
                {t("global.closeAll")}
              </div>
            )}
          </div>
        )}
        {!compact && !selectedClient && (
          <Labels.G1>{t("trade.selectClientToContinue")}</Labels.G1>
        )}
        {asset && selectedClient && (
          <>
            {activeSide === "buy" ? (
              <BuyAsset
                compact={compact}
                client={selectedClient}
                asset={asset}
                position={positions?.find((p) => p.symbol === asset?.symbol)}
                onSuccess={(o) => {
                  setSuccessOrder(o);
                  refreshOrders();
                }}
              />
            ) : (
              <SellAsset
                compact={compact}
                client={selectedClient}
                asset={asset}
                position={positions?.find((p) => p.symbol === asset?.symbol)}
                onSuccess={(o) => {
                  setSuccessOrder(o);
                  refreshOrders();
                }}
              />
            )}
          </>
        )}
      </div>
    </Panel>
  );
};
