import {
  PortfolioAssetAllocation,
  PortfolioSource,
  Portfolio,
  SimulationPeriod,
  PortfolioPerformanceChart,
  PortfolioResultsChart,
  ActivityEntryType,
  PortfolioActivity,
  PortfolioSettings,
  PortfolioComposition,
} from "types/assistant/PortfolioTypes";
import RestService from "shared/api/RestService";
import { ApiRoutes } from "config/routes/ApiRoutes";

export interface PortfolioDepositsParams {
  amount?: number;
}

export interface PortfolioWithdrawalsParams extends PortfolioDepositsParams {
  all?: boolean;
}

export interface PortfolioAllocationsUpdateParams {
  allocations: PortfolioAssetAllocation[];
}

export interface PortfoliosParams {
  details?: boolean;
  source?: PortfolioSource;
  active?: boolean;
}

export const PortfoliosService = {
  getPortfolios: async (params?: PortfoliosParams): Promise<Portfolio[]> => {
    try {
      const portfolios = await RestService.get<Portfolio[]>(
        ApiRoutes.STRATEGIES_V6,
        params,
      );
      return portfolios;
    } catch (error) {
      throw error;
    }
  },

  getStrategyById: async (strategyType: string): Promise<Portfolio> => {
    try {
      const strategy = await RestService.get<Portfolio>(
        ApiRoutes.STRATEGY_V6(strategyType) + "?details=true",
      );
      return strategy;
    } catch (error) {
      throw error;
    }
  },

  getChart: async (
    strategyType: string,
    period: SimulationPeriod,
  ): Promise<PortfolioPerformanceChart> => {
    try {
      const chartData = await RestService.get<PortfolioPerformanceChart>(
        `${ApiRoutes.STRATEGY_V6(
          strategyType,
        )}/charts?period=${period}&max_points=100`,
      );
      return chartData;
    } catch (error) {
      throw error;
    }
  },

  getResults: async (
    strategyType: string,
    period: SimulationPeriod,
  ): Promise<PortfolioResultsChart> => {
    try {
      const chartData = await RestService.get<{
        pnl: PortfolioResultsChart;
      }>(`${ApiRoutes.STRATEGY_V6(strategyType)}/results?period=${period}`);
      return chartData?.pnl;
    } catch (error) {
      throw error;
    }
  },

  getLatestComposition: async (
    strategyType: string,
  ): Promise<PortfolioComposition[]> => {
    try {
      const compositionData = await RestService.get<PortfolioComposition[]>(
        ApiRoutes.STRATEGY_COMPOSITIONS(strategyType),
      );
      return compositionData;
    } catch (error) {
      throw error;
    }
  },

  getCompositionById: async (
    strategyType: string,
    compositionId: string,
  ): Promise<PortfolioComposition> => {
    try {
      const compositionData = await RestService.get<PortfolioComposition>(
        ApiRoutes.STRATEGY_COMPOSITIONS_ID(strategyType, compositionId),
      );
      return compositionData;
    } catch (error) {
      throw error;
    }
  },

  getActivities: async (
    strategyType: string,
    recent: boolean,
    type?: ActivityEntryType,
  ): Promise<PortfolioActivity[]> => {
    try {
      const typeParam = type ? `&type=${type}` : "";
      const activities = await RestService.get<{
        activities: PortfolioActivity[];
      }>(
        `${ApiRoutes.STRATEGY_ACTIVITIES(
          strategyType,
        )}?recent=${recent}${typeParam}`,
      );
      return activities.activities;
    } catch (error) {
      throw error;
    }
  },

  getActivityById: async (
    strategyType: string,
    activityId: string,
  ): Promise<PortfolioActivity> => {
    try {
      const activity = await RestService.get<PortfolioActivity>(
        `${ApiRoutes.STRATEGY_ACTIVITIES(strategyType)}/${activityId}`,
      );
      return activity;
    } catch (error) {
      throw error;
    }
  },

  depositToStrategy: async (
    id: string,
    params: PortfolioDepositsParams,
  ): Promise<Portfolio> => {
    return RestService.post(`${ApiRoutes.STRATEGY_V6(id)}/deposits`, params);
  },

  withdrawToWallet: async (
    id: string,
    params: PortfolioWithdrawalsParams,
  ): Promise<Portfolio> => {
    return RestService.post(`${ApiRoutes.STRATEGY_V6(id)}/withdrawals`, params);
  },

  saveComposedStrategy: async (
    id: string,
    changes: Partial<Portfolio>,
  ): Promise<void> => {
    return RestService.patch<void>(ApiRoutes.STRATEGY_V6(id), changes);
  },

  fetchActiveStrategies: async (
    params?: PortfoliosParams,
  ): Promise<Portfolio[]> => {
    return RestService.get<Portfolio[]>(ApiRoutes.STRATEGIES_V6, params);
  },

  updateSettings: async (
    id: string,
    settings: PortfolioSettings,
  ): Promise<void> => {
    return RestService.patch(`${ApiRoutes.STRATEGY_V6(id)}/settings`, settings);
  },

  saveAllocations: async (
    id: string,
    params: PortfolioAllocationsUpdateParams,
  ): Promise<void> => {
    return RestService.post(`${ApiRoutes.STRATEGY_V6(id)}/allocations`, params);
  },
};
