import { ColumnDef } from "@tanstack/react-table";
import { DownloadIcon, ExpandIcon } from "lucide-react";
// @ts-ignore
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ProAssistantAttachment } from "shared/models/pro_assistant/ProAssistantModel";
import { ProAssistantService } from "shared/services/pro_assistant/ProAssistantService";
import { FullscreenOverlay } from "shared/components/ui/fullscreen-overlay";
import { ScrollArea, ScrollBar } from "shared/components/ui/scroll-area";
import { DataTable } from "shared/components/common/datatable/DataTable";

type Props = {
  attachment: ProAssistantAttachment;
};

export const TableAttachment: React.FC<Props> = ({ attachment }) => {
  const { t } = useTranslation();
  const params = useParams();
  const threadId = params.threadId;
  const [showOverlay, setShowOverlay] = useState(false);
  const [data, setData] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (threadId) {
      setLoading(true);
      ProAssistantService.downloadAttachment(
        threadId,
        attachment.attachment_id,
      ).then(async (response) => {
        const blob = response.data;
        Papa.parse(blob, {
          header: true,
          skipEmptyLines: true,
          dynamicTyping: true,
          // @ts-ignore
          complete: (results) => {
            setData(results.data);
          },
        });

        setLoading(false);
      });
    }
  }, []);

  const download = async () => {
    const fileName = attachment.attachment_name;
    const attachmentId = attachment.attachment_id;
    if (threadId) {
      const response = await ProAssistantService.downloadAttachment(
        threadId,
        attachmentId,
      );

      const link = document.createElement("a");
      link.href = URL.createObjectURL(response.data);
      link.download = fileName;
      link.click();
    }
  };

  const rowsToShow = 10;
  const moreRows = data?.length - rowsToShow;

  return (
    <div className="w-[20px] min-w-full max-w-full overflow-x-auto">
      <FullscreenOverlay open={showOverlay} setOpen={setShowOverlay}>
        <div className="h-full rounded-3xl bg-white p-4">
          <DataTable
            data={data}
            loading={loading}
            key={"table_" + attachment.attachment_id + "_chat_fs"}
          />
        </div>
      </FullscreenOverlay>
      <div className="relative h-full w-full min-w-0 rounded-xl border border-gray-200 p-2">
        <div className="mx-4 my-2 flex flex-wrap items-center justify-between gap-4">
          <div className="text-sm text-gray-500">
            {attachment.attachment_name}
          </div>
          <div className="flex justify-end gap-4">
            <DownloadIcon
              className="size-4 cursor-pointer duration-150 hover:opacity-60"
              onClick={download}
            />
            <ExpandIcon
              className="size-4 cursor-pointer duration-150 hover:opacity-60"
              onClick={() => setShowOverlay(true)}
            />
          </div>
        </div>
        <ScrollArea className="max-w-[100%]">
          <DataTable
            data={[...data].splice(0, rowsToShow)}
            loading={loading}
            key={"table_" + attachment.attachment_id + "_chat"}
          />
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        {moreRows > 0 && (
          <div className="my-2 text-center text-sm text-gray-500">
            {t("proAssistant.moreRows", { count: moreRows })}
          </div>
        )}
      </div>
    </div>
  );
};
