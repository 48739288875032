import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/components/controls/Button";
import DropdownSelect from "shared/components/controls/DropdownSelect";
import { Inputs } from "shared/components/hoc/Inputs";
import { Labels } from "shared/components/hoc/Labels";
import { useAccount } from "shared/hooks/useAccount";
import { OnboardingAddress } from "shared/models/onboarding/OnboardingModel";
import { BrokerageAccountService } from "shared/services/brokerage_account/BrokerageAccountService";
import { USA } from "shared/utils/kycConsts";
import { formatStreetAddress } from "shared/utils/kyc_utils";

interface AddressForm {
  street: string;
  addressUnit: string;
  city: string;
  state: string;
  postalCode: string;
  taxId?: string;
}

export const UpdateAddressForm: React.FC = () => {
  const { account } = useAccount();
  const { identity } =
    account?.client?.summary?.alpaca_details?.brokerage_account || {};
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const usaTaxResidence = identity?.country_of_tax_residence === "USA";

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: useMemo(
      () => ({
        taxId: "",
        street: "",
        addressUnit: "",
        city: "",
        state: "",
        postalCode: "",
      }),
      [],
    ),
  });

  const navigateAndPassValidDateOfBirth = (
    _address: OnboardingAddress,
    _taxId?: string,
  ) => {
    // TODO: pass address and taxId?
    navigate(`/settings/action_required/w8ben`);
  };

  const updateAddress = async (updatedAddress: OnboardingAddress) => {
    setLoading(true);
    try {
      BrokerageAccountService.updateAddress(updatedAddress);
      // TODO: rootNavigation.goBack()
      reset();
    } catch (error) {
      setErrorMessage((error as any).message);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (formData: AddressForm) => {
    const {
      street,
      addressUnit,
      city,
      state,
      postalCode: postal_code,
      taxId,
    } = formData;
    const street_address = formatStreetAddress(street, addressUnit);
    const updatedAddress = {
      contact: {
        city,
        postal_code,
        state,
        street_address,
      },
    };
    if (usaTaxResidence) {
      updateAddress(updatedAddress);
    } else {
      navigateAndPassValidDateOfBirth(updatedAddress, taxId);
    }
  };

  const onError = () => {
    setErrorMessage(t("settings.updateApplication.addressCorrectly"));
  };

  if (!identity) {
    return null;
  }

  return (
    <div>
      {!usaTaxResidence ? (
        <>
          <Labels.H2>
            {t("settings.updateApplication.toUpdateAddress")}
          </Labels.H2>
          <Controller
            name="taxId"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Inputs.Text
                value={value}
                label="settings.updateApplication.taxId"
                error={errors.taxId?.message}
                autoCapitalize="characters"
                onChange={onChange}
              />
            )}
          />
        </>
      ) : null}
      <Controller
        name="street"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Inputs.Text
            value={value}
            label="forms.address.street.label"
            error={errors.street?.message}
            autoCapitalize="words"
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="addressUnit"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Inputs.Text
            value={value}
            label="forms.address.unit.label"
            autoCapitalize="words"
            error={errors.addressUnit?.message}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="city"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Inputs.Text
            value={value}
            label="forms.address.city.label"
            autoCapitalize="words"
            error={errors.city?.message}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="state"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) =>
          usaTaxResidence ? (
            <DropdownSelect
              title={t("forms.address.state.label")}
              selected={value}
              options={USA.States}
              onSelect={(o) => onChange(o.value)}
            />
          ) : (
            <Inputs.Text
              value={value}
              label="forms.address.state.label"
              autoCapitalize="words"
              error={errors.state?.message}
              onChange={onChange}
            />
          )
        }
      />
      <Controller
        name="postalCode"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Inputs.Text
            value={value}
            label="forms.address.postalCode.label"
            autoCapitalize="none"
            error={errors.postalCode?.message}
            onChange={onChange}
          />
        )}
      />

      {errorMessage ? <div>{errorMessage}</div> : null}
      <Button
        label={
          usaTaxResidence
            ? "settings.updateApplication.submit"
            : "settings.updateApplication.updateAndConfirm"
        }
        loading={loading}
        onClick={handleSubmit(onSubmit, onError)}
      />
    </div>
  );
};
