// ActivityLog.jsx
import React from "react";
import { FileText, Phone, Users, Mail } from "lucide-react";
import styles from "./activityLog.module.css";
import { ClientDetailType } from "types/crm/ClientTypes";
import { formatDateWithTime, getInitials } from "utils/misc";
import crmStyles from "./../../crmPage.module.css";
import clientDetailsStyles from "./../crmClientDetailsPage.module.css";
import { useNavigate } from "react-router-dom";

type ActivityLogProps = {
  clientData: ClientDetailType;
};

export const ActivityLog: React.FC<ActivityLogProps> = ({ clientData }) => {
  const navigate = useNavigate();

  const activitiesList = clientData.activities;

  const getIcon = (type: any) => {
    switch (type) {
      case "contract":
        return <FileText size={16} />;
      case "phone_call":
        return <Phone size={16} />;
      case "negotiation":
        return <Users size={16} />;
      case "email":
        return <Mail size={16} />;
      default:
        return null;
    }
  };

  const callClient = (clientId: string) => {
    navigate(`/crm/clients/${clientId}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.timeline}>
        {activitiesList.length === 0 && (
          <div className={crmStyles.noContent}>
            Nessun contenuto disponibile
          </div>
        )}
        {activitiesList.length > 0 && (
          <>
            {activitiesList.map((activity) => (
              <div key={activity.activity_id} className={styles.timelineItem}>
                <div className={styles.timelineIcon}>
                  {getIcon(activity.activity_type)}
                </div>

                <div className={styles.timelineContent}>
                  <div className={styles.header}>
                    <div className={styles.titleWrapper}>
                      <h4 className={styles.title}>{activity.title}</h4>
                      <span className={styles.date}>
                        {formatDateWithTime(activity.activity_time)}
                      </span>
                    </div>
                    {activity.entity.value && (
                      <div className={styles.amount}>
                        {activity.entity.value}
                      </div>
                    )}
                  </div>

                  {activity.description && (
                    <p className={styles.description}>{activity.description}</p>
                  )}

                  <div className={clientDetailsStyles.participants}>
                    {activity.entity.participants.map((participant, index) => (
                      <div
                        key={index}
                        className={clientDetailsStyles.participant}
                      >
                        <div
                          onClick={() => {
                            callClient(participant.client_id.toString());
                          }}
                          className={clientDetailsStyles.participantAvatar}
                        >
                          {getInitials(
                            participant.first_name,
                            participant.last_name,
                          )}
                        </div>
                        <div className={clientDetailsStyles.participantTooltip}>
                          {participant.first_name} {participant.last_name}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
