import { AddContactForm } from "modules/add_contact_form/AddContactForm";
import { KycForm } from "modules/client_kyc/components/KycForm";
import { KycHeader } from "modules/client_kyc/components/KycHeader";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BackButton } from "shared/components/common/BackButton";
import { BasePage, RightBar } from "shared/components/common/BasePage";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import { AddContactModel } from "shared/models/contact/AddContactModel";
import { FeeFrequency } from "shared/models/fees/FeesModel";
import { Onboarding } from "shared/models/onboarding/OnboardingModel";
import { ContactsService } from "shared/services/contacts/ContactsService";

export const AddContactPage: React.FC = () => {
  const { t } = useTranslation();
  const [kycDraft, setKycDraft] = useState<Onboarding>();
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState<string>("");
  const [clientAdded, setClientAdded] = useState(false);

  const onKycSubmit = (KYCData: Onboarding) => {
    setKycDraft(KYCData);
    setShowOnboarding(false);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    const {
      first_name,
      last_name,
      email,
      phone_number,
      annual_rate,
      fee_frequency,
      invite,
    } = Object.fromEntries(formData.entries());

    const ar = parseFloat(annual_rate as string) / 100;

    const dataToSend: AddContactModel = {
      name: `${first_name as string} ${last_name as string}`,
      email: email as string,
      phone: `${phone_number}`.replace(/\s/g, "").replaceAll("-", ""),
      config: {
        fees: {
          fee_type: "nlv_percentage",
          configuration: {
            annual_rate: ar,
          },
          fee_frequency: fee_frequency as FeeFrequency,
          effective_from: effectiveDate,
        },
      },
    };

    setSubmitting(true);
    try {
      const newContact = await ContactsService.create(dataToSend);
      if (kycDraft) {
        await ContactsService.uploadKyc(newContact.contact_id, kycDraft);
      }
      setClientAdded(true);
      if (invite === "on") {
        await ContactsService.invite(newContact.contact_id);
      }
    } catch (error) {
      throw error;
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <BasePage>
      <RightBar />
      <div>
        <BackButton className="mb-10 ml-2" />
        <Layouts.Spaced>
          <AddContactForm
            handleSubmit={handleSubmit}
            submitting={submitting}
            onEffectiveDateChange={setEffectiveDate}
            clientAdded={clientAdded}
            setClientAdded={setClientAdded}
          />
          {!clientAdded && (
            <Panel>
              {!showOnboarding && (
                <div className="flex items-center justify-between gap-2">
                  <Labels.H3>{t("kyc.draftAttached")}</Labels.H3>
                  <Button
                    label="kyc.editDraft"
                    onClick={() => setShowOnboarding(true)}
                  />
                </div>
              )}
              {/* <div className={showOnboarding ? "block" : "hidden"}>
                <KycHeader />
                <KycForm onSubmit={onKycSubmit} />
              </div> */}
            </Panel>
          )}
        </Layouts.Spaced>
      </div>
    </BasePage>
  );
};
