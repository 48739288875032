import { useState } from "react";
import StreetbeatIconHollow from "shared/icons/StreetbeatIconHollow";
import { cn } from "utils";

type Props = {
  uri?: string;
  className?: string;
  wrapperClassName?: string;
};

export default function PortfolioCoverImage({
  uri,
  className,
  wrapperClassName,
}: Props) {
  const [show, setShow] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const sizeClass = `size-10 rounded-lg shrink-0`;

  return (
    <div
      className={cn(
        "border-gray-shadow bg-gray-shadow relative rounded-xl border-[4px]",
        wrapperClassName,
      )}
    >
      {show && uri ? (
        <>
          <img
            src={uri}
            className={cn(
              "aspect-square",
              sizeClass,
              !loaded ? "hidden" : "",
              className,
            )}
            onError={() => {
              setShow(false);
            }}
            onLoad={() => {
              setLoaded(true);
            }}
            alt=""
          />
          <div
            className={cn(
              "relative grid place-items-center border border-gray-800 bg-gray-800",
              sizeClass,
              loaded ? "hidden" : "",
              className,
            )}
          >
            <StreetbeatIconHollow className="h-5 animate-pulse text-gray-700" />
          </div>
        </>
      ) : (
        <div
          className={cn(
            "relative grid place-items-center border border-gray-800 bg-gray-800",
            sizeClass,
            className,
          )}
        >
          <StreetbeatIconHollow className="h-5 text-gray-700" />
        </div>
      )}
    </div>
  );
}
