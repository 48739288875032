import { ProAssistantService } from "shared/services/pro_assistant/ProAssistantService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AiChatGradient from "./svg/AiChatGradient";
import { ChatInput } from "./ChatInput";
import { WebsocketProvider } from "shared/api/WebsocketProvider";
import { FaApiRoutes } from "shared/utils/routes";
import {
  ProAssistantSocketMessage,
  ProAssistantTaskUpdate,
} from "shared/models/pro_assistant/ProAssistantModel";
import useProAssistantStore from "shared/store/proAssistantStore";
import { Assistant } from "./Assistant";

export const AssistantThreadWrapper: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [initialMessage, setInitialMessage] = useState<string | undefined>(
    undefined,
  );
  const params = useParams();
  const threadId = params.threadId;
  const [socketReady, setSocketReady] = useState(false);

  useEffect(() => {
    setSocketReady(false);
  }, [threadId]);

  const createNewThread = (text: string) => {
    ProAssistantService.newThread().then((thread) => {
      setInitialMessage(text);
      navigate(`/assistantWarren/${thread.id}`);
    });
  };

  if (!threadId) {
    return (
      <>
        <div className="h-full w-full space-y-8">
          <div className="mx-auto h-full px-0">
            <div className="flex h-full w-full flex-col justify-end pb-4">
              <div className="flex-grow">
                <div className="grid h-full place-items-center">
                  <div className="text-center">
                    <div className="mb-4 flex items-center justify-center gap-4">
                      <AiChatGradient className="shrink-0" />
                      <h1 className="text-xl font-semibold">
                        {t("proAssistant.howCanIHelp")}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <ChatInput onSend={createNewThread} />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <WebsocketProvider
      key={threadId}
      url={`${FaApiRoutes.ProAssistant.Websocket}/${threadId}`}
      onReady={() => setSocketReady(true)}
      children={(socket) => {
        if (!socket) {
          return null;
        }
        if (!socketReady) {
          return null;
        }
        return (
          <AssistantWrapper
            socket={socket}
            initialMessage={initialMessage}
            onInitialMessageSend={() => setInitialMessage(undefined)}
          />
        );
      }}
    />
  );
};

type AssistantWrapperProps = {
  socket: WebSocket;
  initialMessage?: string;
  onInitialMessageSend: () => void;
};

export const AssistantWrapper: React.FC<AssistantWrapperProps> = ({
  socket,
  initialMessage,
  onInitialMessageSend,
}) => {
  const params = useParams();
  const threadId = params.threadId;

  const [retryButton, setRetryButton] = useState(false);
  const [taskUpdate, setTaskUpdate] = useState<ProAssistantTaskUpdate>();

  const { setBlockInput, appendMessages, appendAttachments, fetchThreads } =
    useProAssistantStore();
  const messages = useProAssistantStore(
    (state) => state.messages[threadId || ""],
  );

  useEffect(() => {
    if (!threadId) return;

    setBlockInput(threadId, true);

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data) as ProAssistantSocketMessage;
      const type = message.type;

      if (type === "set_status") {
        const status = message.payload.status;
        setTaskUpdate(undefined);
        if (status === "in_progress") {
          setBlockInput(threadId, true);
        } else if (status === "completed") {
          setBlockInput(threadId, false);
        } else if (
          status &&
          ["expired", "failed", "cancelled"].includes(status)
        ) {
          setRetryButton(true);
        }
      }
      if (type === "append_messages") {
        if (!messages?.map((m) => m.role !== "user").length) {
          fetchThreads();
        }
        if (message.payload.messages) {
          if (threadId) {
            appendMessages(threadId, message.payload.messages);
          }
        }
      }
      if (type === "append_attachments") {
        if (threadId && message.payload.attachments) {
          appendAttachments(threadId, message.payload.attachments);
        }
      }
      if (type === "set_task_updates") {
        const taskUpdates = message.payload.updates || [];
        // get the latest task update
        if (taskUpdates.length > 0) {
          const latestTaskUpdate = taskUpdates[taskUpdates.length - 1];
          setTaskUpdate(latestTaskUpdate);
        } else {
          setTaskUpdate(undefined);
        }
      }
    };
  }, [socket]);

  return (
    <>
      <div className="h-full w-full space-y-8 pt-0">
        <div className="mx-auto h-full w-full">
          <Assistant
            socket={socket}
            initialMessage={initialMessage}
            onInitialMessageSend={onInitialMessageSend}
            retryButton={retryButton}
            setRetryButton={setRetryButton}
            taskUpdate={taskUpdate}
          />
        </div>
      </div>
    </>
  );
};
