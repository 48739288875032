import { Loading } from "shared/types/enums";
import { AccountService } from "shared/services/account/AccountService";
import { create } from "./store";
import { AumChart } from "shared/models/account/AumChart";
import { AumChartParams } from "shared/models/aum/AumChartParams";

type AggregatedAumStoreState = {
  chartData: AumChart | null | undefined;
  loading: Loading;
};

type AggregatedAumStoreActions = {
  fetchAggregatedAum: (params?: AumChartParams) => void;
};

const initialData: AggregatedAumStoreState = {
  chartData: undefined,
  loading: Loading.Idle,
};

const useAggregatedAumStore = create<
  AggregatedAumStoreState & AggregatedAumStoreActions
>()((set) => ({
  ...initialData,
  fetchAggregatedAum: async (params) => {
    set({ loading: Loading.InProgress });
    try {
      const chartData = await AccountService.getAumChart(params);
      set({ chartData });
    } finally {
      set({ loading: Loading.Finished });
    }
  },
}));

export default useAggregatedAumStore;
