import { ConstraintConfigType } from "types/assistant/ConstraintsTypes";
import { InputConstraints } from "../InputConstraints";

export const GridItem: React.FC<{
  cd: ConstraintConfigType;
  cv1: string;
  cv2?: string;
  fv: any;
  ohi: any;
  marginTop?: number;
  notEditableValue?: { value: string; label: string };
}> = ({ cd, cv1, cv2, fv, ohi, marginTop = 25, notEditableValue = null }) => {
  return (
    <>
      <div
        className="grid-item"
        style={{ marginTop: marginTop, width: "200%" }}
      >
        {cd[cv1].label}
      </div>
      <div className="grid-item">{/* Empty cell */}</div>
      <div className="grid-item">
        <div className="flex items-center space-x-2">
          {!cd[cv1].notWithTopLabel && <div className="minMax">Min:</div>}

          <InputConstraints
            configData={cd}
            formValues={fv}
            id={cv1}
            onChange={ohi}
          />
        </div>
      </div>
      {cv2 && (
        <div className="grid-item">
          <div className="flex items-center space-x-2">
            {!notEditableValue && <div className="minMax">Max:</div>}
            {notEditableValue && (
              <div className="minMax">{notEditableValue.label}</div>
            )}

            <InputConstraints
              configData={cd}
              formValues={fv}
              id={cv2}
              onChange={ohi}
              notEditableValue={
                notEditableValue ? notEditableValue.value : null
              }
            />
          </div>
        </div>
      )}
      {!cv2 && <div className="grid-item">{/* Empty cell */}</div>}
    </>
  );
};
