import { useTranslation } from "react-i18next";
import { ScrollArea } from "shared/components/ui/scroll-area";

import { CloseActivityPanelIcon } from "assets/svg/CloseActivityPanelIcon";
import appStatusStore from "shared/store/appStatusStore";
import EsgConstrainForm from "pages/assistant/components/EsgPreferencesForm";
import EsgFormData from "config/esg-form-data.json";
import activityPanelStore from "shared/store/activityPanelStore";
import { useToast } from "shared/components/ui/use-toast";
import colorsStore from "shared/store/colorsStore";

type Props = {
  expanded: boolean;
  setExpanded: (e: boolean) => void;
};

export const EsgPreferencesPanel: React.FC<Props> = ({
  expanded,
  setExpanded,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const setAppStatus = appStatusStore((state) => state.setAppStatus);
  const esgPreferencesStore = activityPanelStore(
    (state) => state.esgPreferencesStore,
  );
  const socketServiceRef = activityPanelStore(
    (state) => state.socketServiceRef,
  );
  const { t } = useTranslation();
  const { toast } = useToast();

  const children = () => {
    return (
      <div className="h-full space-y-8">
        <EsgConstrainForm
          activityPanel={true}
          prefilledData={esgPreferencesStore.data}
          questions={EsgFormData}
          onSubmitProp={(body) => {
            socketServiceRef.sendEsgPreferences(body);
            setAppStatus("idle");
            toast({
              title: t("ws_actions.titleEsg"),
              description: t("ws_actions.toastEsg"),
            });
          }}
          onChange={() => {
            setTimeout(() => {
              document.getElementById("scrollId")?.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
              });
            }, 40);
          }}
        />
      </div>
    );
  };

  const iconSizeClass = `icon-size-22-22`;
  const iconSizeClass2 = `icon-size-16-16`;
  return (
    <ScrollArea
      className={"flex-2 relative max-h-full min-h-[60px] rounded-xl pt-12"}
      style={{
        backgroundColor: colorsConfig.grayLight,
      }}
    >
      <div
        className="absolute left-4 right-4 top-4 z-50"
        style={{ marginTop: 20 }}
      >
        <div
          className={
            "flex items-center justify-between gap-4 " +
            (expanded ? "lg:justify-between" : " lg:justify-center")
          }
        >
          <div
            className={(expanded ? "lg:visible " : "lg:hidden ") + " font-bold"}
            style={{ color: colorsConfig.black }}
          >
            {t("menu.esgPreferences")}
          </div>
          <div
            className="hidden lg:block"
            onClick={() => setExpanded(!expanded)}
          >
            <div onClick={() => setAppStatus("idle")}>
              <CloseActivityPanelIcon
                className={iconSizeClass2}
                color={colorsConfig.black}
              />
            </div>
          </div>
        </div>
      </div>
      {expanded && (
        <div
          className="relative space-y-4 text-xs lg:text-base "
          style={{ marginTop: 40, marginLeft: 18, marginRight: 20 }}
        >
          {children()}
        </div>
      )}
      <div id="scrollId" className="pt-10" />
    </ScrollArea>
  );
};
