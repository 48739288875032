import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "shared/components/common/Skeleton";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { useAccount } from "shared/hooks/useAccount";
import { Loading } from "shared/types/enums";
import { ClientPendingTasks } from "./ClientPendingTasks";
import useAccountStore from "shared/store/accountStore";

type Props = {};

export const PendingTasks: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { fetchPendingTasks } = useAccountStore();
  const { pendingTasks, pendingTasksLoading } = useAccount();

  useEffect(() => {
    fetchPendingTasks();
  }, []);

  return (
    <Panel>
      <Labels.H3>{t("pendingTasks.title")}</Labels.H3>
      <div className="space-y-2">
        <div className="text-sm">
          {pendingTasksLoading === Loading.InProgress ? (
            <Skeleton className="my-1 h-10 w-full bg-sb-gray-100" />
          ) : (
            <div className="space-y-2">
              {pendingTasks.map((item, idx) => {
                return (
                  <ClientPendingTasks key={"pending_task_" + idx} task={item} />
                );
              })}
            </div>
          )}
        </div>
        {pendingTasksLoading === Loading.Finished && !pendingTasks.length ? (
          <Labels.G1>{t("pendingTasks.noTasks")}</Labels.G1>
        ) : null}
      </div>
    </Panel>
  );
};
