import { ProAssistantArtifact } from "shared/models/pro_assistant/ProAssistantModel";
import { PortfolioArtifact } from "./PortfolioArtifact";

type Props = {
  artifact: ProAssistantArtifact;
};

export const MessageArtifacts: React.FC<Props> = ({ artifact }) => {
  const type = artifact.type;

  if (type === "portfolio" && artifact.portfolio) {
    return <PortfolioArtifact portfolio={artifact.portfolio} />;
  }

  return <></>;
};
