import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AvatarImage } from "shared/components/common/AvatarImage";
import { DataTable } from "shared/components/common/datatable/DataTable";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { TopPortfolioPerformers } from "shared/models/top_performances/TopPerformancesModel";
import { Loading } from "shared/types/enums";
import { Client } from "shared/models/client/ClientModel";
import { CurrencyFormat, pctFormat } from "shared/utils/currency";
import useTopPerformancesStore from "shared/store/topPerformancesStore";
import usePortfoliosStore from "shared/store/portfoliosStore";
import useClientsStore from "shared/store/clientsStore";

export const TopPortfolioPerformances: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    portfolios: unsortedPortfolios,
    portfoliosLoading: loading,
    fetchTopPortfolios,
  } = useTopPerformancesStore();
  const { portfolios: allPortfolios, fetchPortfolios } = usePortfoliosStore();
  const { clients: faClients } = useClientsStore();

  const [sort, setSort] = useState<"top" | "bottom">("top");
  const [portfolios, setPortfolios] = useState<TopPortfolioPerformers>([]);

  useEffect(() => {
    fetchTopPortfolios();
    fetchPortfolios();
  }, []);

  useEffect(() => {
    const sortedPortfolios = [...unsortedPortfolios].sort((a, b) =>
      sort === "top"
        ? b.performance - a.performance
        : a.performance - b.performance,
    );
    setPortfolios(sortedPortfolios.slice(0, 10));
  }, [sort, unsortedPortfolios]);

  return (
    <Panel>
      <div className="flex items-center justify-between gap-4">
        <Labels.H3>
          <span className="text-sb-green-700">
            {t("topPerformances.portfolios")}
          </span>{" "}
          {t("topPerformances.title")}
        </Labels.H3>
        <div>
          <select
            className="cursor-pointer rounded-xl border border-sb-gray-200 px-4 py-2 outline-none"
            onChange={(e) => setSort(e.target.value as "top" | "bottom")}
          >
            <option value="top">{t("topPerformances.title")}</option>
            <option value="bottom">{t("topPerformances.titleBottom")}</option>
          </select>
        </div>
      </div>
      <div className="mt-8">
        <DataTable
          loading={loading === Loading.InProgress}
          data={portfolios}
          onRowClicked={(entry: any) => {
            navigate(`/clients/${entry.user_id}`);
          }}
          columns={["portfolio_name", "user_id", "equity", "performance"]}
          headerLabels={{
            user_id: t("topPerformances.clientName"),
            equity: t("topPerformances.aum"),
            portfolio_name: t("topPerformances.portfolioName"),
            performance: t("topPerformances.performance"),
          }}
          sortableValue={(key: string, entry: any) => {
            if (key === "performance") {
              return +entry["performance"];
            }
            if (key === "portfolio_name") {
              return entry["portfolio_name"];
            }
            if (key === "equity") {
              return +entry["equity"];
            }
            if (key === "user_id") {
              return (
                faClients.find(
                  (c: Client) =>
                    String(c.user_id) === String(entry["user_id"]),
                )?.summary.client_name || "Unknown"
              );
            }
            return undefined;
          }}
          cellFormatter={(
            key: string,
            value: string,
            entry: Record<string, any>,
          ) => {
            if (key === "performance") {
              return (
                <div
                  className={
                    parseFloat(value) < 0
                      ? "text-sb-red-700"
                      : "text-sb-green-700"
                  }
                >
                  {pctFormat.format(+value)}
                </div>
              );
            }
            if (key === "portfolio_name") {
              const portfolioDetails = allPortfolios.find(
                (p) => p.id === entry.portfolio_id,
              );
              return (
                <div className="flex items-center gap-2.5">
                  <AvatarImage
                    imageSrc={portfolioDetails?.cover?.uri}
                    width={25}
                    height={25}
                  />
                  <div>{value}</div>
                </div>
              );
            }
            if (key === "user_id") {
              return (
                faClients.find(
                  (c: Client) => String(c.user_id) === String(value),
                )?.summary.client_name || "Unknown"
              );
            }

            if (key === "equity") {
              return <div>{CurrencyFormat.format(value)}</div>;
            }
            return value;
          }}
        />
        {loading === Loading.Finished && !portfolios?.length ? (
          <Labels.G1>{t("global.nothingToShow")}</Labels.G1>
        ) : null}
      </div>
    </Panel>
  );
};
