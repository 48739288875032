import {
  AssistantThread,
  HistoryItemType,
} from "types/assistant/AssistantTypes";
import RestService from "shared/api/RestService";
import { AssistantRoutes } from "config/routes/AssistantRoutes";
import { Portfolio } from "types/assistant/PortfolioTypes";

export const AssistantService = {
  newThread: async (): Promise<AssistantThread> => {
    return RestService.post(AssistantRoutes.THREADS);
  },

  fetchThreads: async (): Promise<HistoryItemType[]> => {
    return RestService.get(AssistantRoutes.THREADS);
  },

  deleteThread: async (threadId: string) => {
    return RestService.delete(AssistantRoutes.THREAD_DETAILS(threadId));
  },
  getThreadInfo: async (threadId: string) => {
    return RestService.get(AssistantRoutes.THREAD_DETAILS(threadId));
  },

  downloadAttachment: async (threadId: string, attachmentId: number) => {
    const response = await RestService.download(
      AssistantRoutes.ATTACHMENT_DOWNLOAD(threadId, attachmentId),
    );
    return response;
  },

  getAttachmentUrl: async (
    threadId: string,
    attachmentId: number,
  ): Promise<string> => {
    const response = await RestService.get<string>(
      AssistantRoutes.ATTACHMENT_URL(threadId, attachmentId),
    );
    return response;
  },

  fetchPortfolios: async (threadId: string): Promise<Portfolio[]> => {
    return await RestService.get(AssistantRoutes.THREAD_PORTFOLIOS(threadId));
  },

  fetchThread: async (threadId: string): Promise<AssistantThread> => {
    return RestService.get(AssistantRoutes.BASE_THREAD_DETAILS(threadId));
  },

  activatePortfolio: async (threadId: string, portfolioId: string) => {
    return RestService.put(
      AssistantRoutes.THREAD_ACTIVATE_PORTFOLIO(threadId, portfolioId),
    );
  },

  threadAction: async (
    threadId: string,
    actionId: string,
    body: any,
  ): Promise<any> => {
    return RestService.put(
      AssistantRoutes.THREAD_ACTION(threadId, actionId),
      body,
    );
  },

  createPdf: async (
    threadId: string,
    portfolioId: string,
    body: any,
  ): Promise<any> => {
    return RestService.post(
      AssistantRoutes.CREATE_PDF(threadId, portfolioId),
      body,
    );
  },

  fetchReportData: async (
    threadId: string,
    portfolioId: string,
  ): Promise<any> => {
    return RestService.get(AssistantRoutes.REPORT_DATA(threadId, portfolioId));
  },

  fetchReportParameters: async (): Promise<any> => {
    return RestService.get(AssistantRoutes.REPORT_PARAMETERS);
  },

  createExcel: async (threadId: string, portfolioId: string): Promise<any> => {
    const response = await RestService.download(
      AssistantRoutes.CREATE_EXCEL(threadId, portfolioId),
    );
    return response;
  },
};
