import { AssistantService } from "shared/services/AssistantService";
import {
  AssistantAttachment,
  AssistantMessage,
  AssistantThread,
  HistoryItemType,
  AssistantEsgPreferencesType,
  FinecoPortfolioArtifactType,
  AssistantActionCreatePortfolioType,
} from "types/assistant/AssistantTypes";
import { create } from "./store";
import { Portfolio } from "types/assistant/PortfolioTypes";
import activityPanelStore from "./activityPanelStore";
import useAppStatusStore from "./appStatusStore";

type ThreadsStoreState = {
  threads: HistoryItemType[];
  busyThreads: HistoryItemType[];
  attachments: Record<string, AssistantAttachment[]>;
  messages: Record<string, AssistantMessage[]>;
  esgPreferences: Record<string, AssistantEsgPreferencesType>;
  actions: Record<string, AssistantActionCreatePortfolioType>;
  portfolios: Record<string, Portfolio[]>;
  threadDetails: Record<string, AssistantThread>;
  blockInput: Record<string, boolean>;
  fromHistory: boolean;
  refreshProposalId: number;
  // hasUserScrolled: boolean;
  // isCodeScrolling: boolean;
};

type ThreadsStoreActions = {
  fetchThreads: () => void;
  // addBusyThread: (thread: HistoryItemType) => void;
  removeBusyThread: (thread: HistoryItemType) => void;
  appendAttachments: (
    threadId: string,
    attachments: AssistantAttachment[],
  ) => void;
  appendMessages: (threadId: string, messages: AssistantMessage[]) => void;
  appendEsgPreferences: (
    threadId: string,
    esgConstraint: AssistantEsgPreferencesType,
  ) => void;
  appendAction: (
    threadId: string,
    action: AssistantActionCreatePortfolioType,
  ) => void;
  resetAction: (threadId: string) => void;
  resetThread: (threadId: string) => void;
  fetchPortfolios: (threadId: string) => void;
  fetchThread: (threadId: string) => void;
  setBlockInput: (threadId: string, blockInput: boolean) => void;
  setFromHistory: (isFromHistory: boolean) => void;
  setRefreshProposalId: (refreshProposalId: number) => void;
  // setHasUserScrolled: (hasUserScrolled: boolean) => void;
  // setIsCodeScrolling: (isCodeScrolling: boolean) => void;
};

const initialData: ThreadsStoreState = {
  threads: [],
  busyThreads: [],
  attachments: {},
  messages: {},
  esgPreferences: {},
  actions: {},
  portfolios: {},
  threadDetails: {},
  blockInput: {},
  fromHistory: false,
  refreshProposalId: -1,
  // hasUserScrolled: false,
  // isCodeScrolling: false,
};

const useThreadsStore = create<ThreadsStoreState & ThreadsStoreActions>()(
  (set, get) => ({
    ...initialData,

    removeBusyThread: (thread: HistoryItemType) => {
      const currentBusyThreads = get().busyThreads;
      // Check if the thread exists and remove it
      set({
        busyThreads: currentBusyThreads.filter(
          (t) => t.thread_id !== thread.thread_id,
        ),
      });
    },
    fetchThreads: async () => {
      const threadsResults = await AssistantService.fetchThreads();

      const threads = threadsResults.filter(
        (thread) => thread.title !== "Untitled" && thread.title !== "",
      );

      const oneDayAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);

      // Only add threads that are busy and updated within last 24 hours
      const busyThreads = threads.filter(
        (thread) =>
          thread.status === "busy" && new Date(thread.updated_at) >= oneDayAgo,
      );

      set({
        threads,
        busyThreads,
      });
    },

    appendAttachments: async (threadId, attachments) => {
      const attachmentIds = get().attachments[threadId]?.map(
        (m) => m.attachment_id,
      );
      const deduplicatedAttachments =
        attachments.filter(
          (m) =>
            !attachmentIds?.includes(m.attachment_id) &&
            m.thread_id === threadId,
        ) || [];

      const appStatus = useAppStatusStore.getState().appStatus;
      if (appStatus !== "attachments") {
        activityPanelStore.getState().setAttachmentsRedDot(true);

        //** lets be sure the user has sent one message and we are not loading from history or url. so we can show the bubble to the user */
        if (activityPanelStore.getState().userSentFirstMessage) {
          setTimeout(() => {
            activityPanelStore.getState().setNewAttachmentsAdded(true);
          }, 1000);
        }
      }

      set({
        attachments: {
          ...get().attachments,
          [threadId]: [
            ...(deduplicatedAttachments || []),
            ...(get().attachments[threadId] || []),
          ],
        },
      });
    },
    appendMessages: async (threadId, messages) => {
      const messageIds = get().messages[threadId]?.map((m) => m.id);
      const deduplicatedMessages =
        messages.filter(
          (m) => !messageIds?.includes(m.id) && m.thread_id === threadId,
        ) || [];

      set({
        messages: {
          ...get().messages,
          [threadId]: [
            ...(deduplicatedMessages || []),
            ...(get().messages[threadId] || []),
          ],
        },
      });
    },
    appendEsgPreferences: async (threadId, esgPreferences) => {
      set({
        esgPreferences: {
          [threadId]: esgPreferences,
        },
      });
    },
    appendAction: async (threadId, action) => {
      set({
        actions: {
          [threadId]: action,
        },
      });
    },
    resetAction: (threadId) => {
      set({
        actions: {
          [threadId]: null,
        },
      });
    },
    resetThread: (threadId) => {
      set({
        messages: {
          ...get().messages,
          [threadId]: [],
        },
        actions: {
          [threadId]: null,
        },
        attachments: { ...get().attachments, [threadId]: [] },
        blockInput: { ...get().blockInput, [threadId]: false },
        // hasUserScrolled: false,
      });
    },
    fetchPortfolios: async (threadId) => {
      const portfolios = await AssistantService.fetchPortfolios(threadId);
      set({
        portfolios: {
          ...get().portfolios,
          [threadId]: portfolios,
        },
      });
    },
    fetchThread: async (threadId) => {
      const thread = await AssistantService.fetchThread(threadId);
      set({ threadDetails: { ...get().threadDetails, [threadId]: thread } });
    },
    setBlockInput: async (threadId, blockInput) => {
      set({
        blockInput: {
          ...get().blockInput,
          [threadId]: blockInput,
        },
      });
    },
    setFromHistory: (isFromHistory) => {
      set({ fromHistory: isFromHistory });
    },
    setRefreshProposalId: (refreshProposalId) => {
      set({ refreshProposalId: refreshProposalId });
    },
    // setHasUserScrolled: (hasUserScrolled) => {
    //   set({ hasUserScrolled });
    // },
    // setIsCodeScrolling: (isCodeScrolling) => {
    //   set({ isCodeScrolling });
    // },
  }),
);

export default useThreadsStore;
