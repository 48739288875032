import React from "react";
import { useTranslation } from "react-i18next";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import DropdownSelect from "shared/components/controls/DropdownSelect";
import { Labels } from "shared/components/hoc/Labels";
import { AllCountries, ISOCountryCode } from "shared/types/Countries";
import { PickerModalOption } from "./components/KycDatePicker";

import { LetUsKnowYouColumn } from "./components/LetUsKnowYouColumn";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingPartProps } from "./OnboardingFlow";
import useOnboardingStore from "shared/store/onboardingStore";

export const Nationality: React.FC<OnboardingPartProps> = ({
  onNext,
  onPrevious,
}) => {
  const { t } = useTranslation();
  const { nationality } = useOnboardingStore((state) => state.data);
  const { update } = useOnboardingStore();

  const onSelectNationality = (nationalityValue: ISOCountryCode) => {
    update({ nationality: nationalityValue });
  };

  const options = AllCountries.map(
    ({ t, code }) =>
      ({ label: t, value: code }) as PickerModalOption<ISOCountryCode>,
  );

  return (
    <OnboardingContainer
      leftColumnContent={<LetUsKnowYouColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1">
            <Labels.H2 className="mb-2">
              {t("forms.kyc.screen_titles.nationality.title")}
            </Labels.H2>
            <Labels.B1 className="mb-6">
              {t("forms.kyc.screen_titles.nationality.sub_title")}
            </Labels.B1>
            <DropdownSelect
              selected={nationality || ""}
              className="rounded-xl bg-sb-gray-100"
              inputClassName="rounded-xl bg-sb-gray-100 !pb-5 !pt-5 px-5"
              options={options}
              onSelect={(o) => onSelectNationality(o.value as ISOCountryCode)}
            />
          </div>
          <OnboardingFooter
            onNext={() => onNext && onNext()}
            onPrev={() => onPrevious && onPrevious()}
            nextDisabled={!nationality}
          />
        </div>
      }
    />
  );
};
