import { AiIcon } from "assets/svg/AiIcon";
import colorsStore from "shared/store/colorsStore";

type Props = {
  className?: string;
  size: number;
};

export default function StreetbeatIcon({ className, size = 32 }: Props) {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  return (
    <div
      className={`size-12 grid h-full shrink-0 place-items-center rounded-full p-1.5 text-card`}
      style={{
        width: size,
        height: size,
        background: `linear-gradient(${colorsConfig.logoMain}, ${colorsConfig.logoGradient})`,
      }}
    >
      <AiIcon className="aspect-square" />
    </div>
  );
}
