import React from "react";
import { OnboardingPartProps } from "./OnboardingFlow";
import { LetUsKnowYouColumn } from "./components/LetUsKnowYouColumn";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { useTranslation } from "react-i18next";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import DropdownSelect from "shared/components/controls/DropdownSelect";
import { Inputs } from "shared/components/hoc/Inputs";
import { USA } from "shared/utils/kycConsts";
import useOnboardingStore from "shared/store/onboardingStore";

export const Address: React.FC<OnboardingPartProps> = ({
  onNext,
  onPrevious,
}) => {
  const { t } = useTranslation();
  const { taxCountry, street, addressUnit, postalCode, city, state } =
    useOnboardingStore((state) => state.data);
  const { update } = useOnboardingStore();

  const handleOnValueChange = (
    value: string,
    type: "street" | "addressUnit" | "postalCode" | "city" | "state",
  ) => {
    update({ [type]: value });
  };

  const isPayingTaxesInUsa = taxCountry === "USA";

  const continueDisabled =
    !street || !addressUnit || !postalCode || !city || !state;
  return (
    <OnboardingContainer
      leftColumnContent={<LetUsKnowYouColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1">
            <Inputs.Text
              value={street}
              className="mb-2"
              label="kyc.address.street"
              autoCapitalize="none"
              onChange={(value: string) => {
                handleOnValueChange(value, "street");
              }}
            />
            <Inputs.Text
              value={addressUnit}
              className="mb-2"
              label="kyc.address.addressUnit"
              autoCapitalize="none"
              onChange={(value: string) => {
                handleOnValueChange(value, "addressUnit");
              }}
            />
            <div className="flex gap-2">
              <Inputs.Text
                value={city}
                className="flex-1"
                label="kyc.address.city"
                autoCapitalize="none"
                onChange={(value: string) => {
                  handleOnValueChange(value, "city");
                }}
              />
              {isPayingTaxesInUsa ? (
                <DropdownSelect
                  placeholder={t("kyc.address.state")}
                  selected={state || ""}
                  className="bg-sb-gray-100"
                  inputClassName="rounded-xl bg-sb-gray-100 !pb-4 !pt-4 px-5"
                  options={USA.States}
                  onSelect={(o) =>
                    handleOnValueChange(o.value.toString(), "state")
                  }
                />
              ) : (
                <Inputs.Text
                  value={state}
                  className="flex-1"
                  label="kyc.address.state"
                  autoCapitalize="none"
                  onChange={(value: string) => {
                    handleOnValueChange(value, "state");
                  }}
                />
              )}

              <Inputs.Text
                value={postalCode}
                className="flex-1"
                label="kyc.address.postalCode"
                autoCapitalize="none"
                onChange={(value: string) => {
                  handleOnValueChange(value, "postalCode");
                }}
              />
            </div>
          </div>
          <OnboardingFooter
            onNext={() => onNext && onNext()}
            onPrev={() =>
              onPrevious && onPrevious("legal_name_phone_verification")
            }
            nextDisabled={continueDisabled}
          />
        </div>
      }
    />
  );
};
