type Props = {
  className?: string;
  color?: string;
};

export const CloseActivityPanelIcon: React.FC<Props> = ({
  className,
  color,
}) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3764 14.2504C13.2613 14.2509 13.1472 14.2285 13.0407 14.1846C12.9343 14.1406 12.8377 14.076 12.7564 13.9944L8.1264 9.3624L3.4954 13.9944C3.33123 14.1586 3.10857 14.2508 2.8764 14.2508C2.64423 14.2508 2.42157 14.1586 2.2574 13.9944C2.09323 13.8302 2.001 13.6076 2.001 13.3754C2.001 13.1432 2.09323 12.9206 2.2574 12.7564L6.8894 8.1254L2.2564 3.4944C2.17511 3.41311 2.11063 3.31661 2.06664 3.2104C2.02264 3.10419 2 2.99036 2 2.8754C2 2.76044 2.02264 2.64661 2.06664 2.5404C2.11063 2.43419 2.17511 2.33769 2.2564 2.2564C2.33769 2.17511 2.43419 2.11063 2.5404 2.06664C2.64661 2.02264 2.76044 2 2.8754 2C2.99036 2 3.10419 2.02264 3.2104 2.06664C3.31661 2.11063 3.41311 2.17511 3.4944 2.2564L8.1244 6.8884L12.7564 2.2564C12.8377 2.17511 12.9342 2.11063 13.0404 2.06664C13.1466 2.02264 13.2604 2 13.3754 2C13.4904 2 13.6042 2.02264 13.7104 2.06664C13.8166 2.11063 13.9131 2.17511 13.9944 2.2564C14.0757 2.33769 14.1402 2.43419 14.1842 2.5404C14.2282 2.64661 14.2508 2.76044 14.2508 2.8754C14.2508 2.99036 14.2282 3.10419 14.1842 3.2104C14.1402 3.31661 14.0757 3.41311 13.9944 3.4944L9.3634 8.1254L13.9954 12.7564C14.1179 12.8788 14.2014 13.0349 14.2352 13.2048C14.2689 13.3747 14.2515 13.5508 14.1851 13.7108C14.1187 13.8708 14.0063 14.0075 13.8622 14.1035C13.718 14.1996 13.5486 14.2507 13.3754 14.2504H13.3764Z"
        fill="#1C2226"
      />
    </svg>
  );
};
