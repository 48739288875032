import { PortfoliosService } from "shared/services/portfolios/PortfoliosService2";
import {
  PortfolioSimulationPeriod,
  PortfolioPerformanceChart,
} from "types/assistant/PortfolioTypes";
import { create } from "./store";

interface ChartCache {
  [strategyId: string]: {
    [key in PortfolioSimulationPeriod]?: PortfolioPerformanceChart;
  };
}

type PortfoliosChartStoreState = {
  period: PortfolioSimulationPeriod;
  cache: ChartCache;
  selectedIndex?: number;
};

type PortfoliosChartStoreActions = {
  fetchChartByPortfolioId: (
    portfolioId: string,
    period: PortfolioSimulationPeriod,
  ) => Promise<void>;
};

const initialData: PortfoliosChartStoreState = {
  period: "all_time",
  cache: {},
};

const usePortfoliosChartStore = create<
  PortfoliosChartStoreState & PortfoliosChartStoreActions
>()((set) => ({
  ...initialData,
  fetchChartByPortfolioId: async (portfolioId, period) => {
    const result = await PortfoliosService.getChart(portfolioId, period);
    set((state) => ({
      ...state,
      cache: {
        ...state.cache,
        [portfolioId]: {
          ...state.cache[portfolioId],
          [period]: result,
        },
      },
    }));
  },
}));

export default usePortfoliosChartStore;
