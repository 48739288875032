// FamilyWidget.jsx
import React from "react";
import { User } from "lucide-react";
import styles from "./familyWidget.module.css";
import { ClientDetailType } from "types/crm/ClientTypes";
import { useTranslation } from "react-i18next";

type FamilyWidgetProps = {
  clientData: ClientDetailType;
};
export const FamilyWidget: React.FC<FamilyWidgetProps> = ({ clientData }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t("crm.family")}</h3>

      <div className={styles.membersList}>
        {clientData.relationships.map((relationship) => (
          <div key={relationship.relationship_id} className={styles.memberCard}>
            <div className={styles.avatar}>
              <User size={16} />
            </div>
            <div className={styles.memberInfo}>
              <div className={styles.name}>
                {relationship.relative.first_name}{" "}
                {relationship.relative.last_name}
              </div>
              <div className={styles.relation}>
                {relationship.relationship_type}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
