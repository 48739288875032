export const startsWithPrompt = (str: string): boolean => {
  return str.trim().toLowerCase().startsWith("prompt:");
};

// Optional: Function to extract the prompt content
export const extractPromptContent = (str: string): string => {
  if (!startsWithPrompt(str)) {
    return str;
  }
  // Remove 'prompt:' prefix and trim any whitespace
  return str.trim().slice(7).trim();
};
