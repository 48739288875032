import remarkGfm from "remark-gfm";
import ReactMarkdown, { defaultUrlTransform } from "react-markdown";
import { PrismCode } from "react-prism";
import { useState } from "react";
import { useParams } from "react-router";
import useProAssistantStore from "shared/store/proAssistantStore";
import { cn } from "utils";
import { ProAssistantService } from "shared/services/pro_assistant/ProAssistantService";

type Props = {
  text: string;
};

export const TextResponse: React.FC<Props> = ({ text }) => {
  const [urls, setUrls] = useState<Record<string, string>>({});
  const { threadId } = useParams();
  const attachments = useProAssistantStore(
    (state) => state.attachments[threadId || ""],
  );

  const renderers = {
    a: ({ href, children }: any) => {
      const url = urls[href];
      if (!url) return null;
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-green-500 underline"
          download={href.replace("sandbox:/home/user/artifacts/", "")}
        >
          {children}
        </a>
      );
    },

    table(props: any) {
      const { node, ...rest } = props;
      return (
        <div className="table-wrapper" {...rest}>
          <table>{props.children}</table>
        </div>
      );
    },
    pre(props: any) {
      const className = props.node.children?.[0]?.properties?.className?.[0];
      return (
        <PrismCode
          component="pre"
          className={cn("rounded-xl", className)}
          {...props.attributes}
        >
          {props.children}
        </PrismCode>
      );
    },
    img(props: any) {
      return (
        <div className="my-8 flex justify-center">
          <img {...props} />
        </div>
      );
    },
  };

  const sandboxUrlTransform = (url: string) => {
    if (url.startsWith("sandbox:/home/user/artifacts")) {
      const artifactName = url.replace("sandbox:/home/user/artifacts/", "");
      const attachment = attachments?.find(
        (attachment) => attachment.attachment_name === artifactName,
      );
      if (attachment && !urls[url] && threadId) {
        ProAssistantService.downloadAttachment(
          threadId,
          attachment.attachment_id,
        ).then((response) => {
          const resolvedUrl = URL.createObjectURL(response.data);
          setUrls({ ...urls, [url]: resolvedUrl });
        });
      }
      return url;
    } else {
      return defaultUrlTransform(url);
    }
  };

  return (
    <>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={renderers}
        className="assistantMarkdown mt-2 text-base text-gray-900"
        urlTransform={sandboxUrlTransform}
      >
        {text}
      </ReactMarkdown>
    </>
  );
};
