// LatestLead.tsx
import React, { useEffect, useState } from "react";
import { MoreVertical, Calendar, Info, DollarSign } from "lucide-react";
import styles from "./latestLead.module.css";
import { Tooltip } from "./Tooltip";
import { ClientDetailType } from "types/crm/ClientTypes";
import { formatDateWithTime } from "utils/misc";

type LatestLeadProps = {
  clientData: ClientDetailType;
  activeTab: string;
  onTabChange: (tab: string) => void;
};

export const LatestLead: React.FC<LatestLeadProps> = ({
  clientData,
  activeTab,
  onTabChange,
}) => {
  const [latestLead, setLatestLead] = useState<any>(null);

  useEffect(() => {
    if (clientData.activities.length > 0) {
      // @ts-ignore
      setLatestLead(clientData.activities[0]);
    }
  }, clientData.activities);

  return (
    <>
      {!latestLead && <></>}
      {latestLead && (
        <div className={styles.container}>
          <div className={styles.header}>
            <h3 className={styles.title}>Latest Lead</h3>
            {activeTab !== "Activity log" && (
              <button
                onClick={() => {
                  onTabChange("Activity log");
                }}
                className={styles.viewMore}
              >
                View more
              </button>
            )}
          </div>
          <div className={styles.leadCard}>
            <div className={styles.cardHeader}>
              <div className={styles.tags}>
                <span className={styles.tag}>{latestLead.activity_type}</span>
              </div>
            </div>
            <p className={styles.leadTitle}>{latestLead.title}</p>
            <p className={styles.leadDescription}>{latestLead.description}</p>
            <p className={styles.date}>
              {formatDateWithTime(latestLead.activity_time)}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
