import { ActivityPanelContainer } from "./components/activity-panel/ActivityPanelContainer";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useThreadsStore from "shared/store/threadsStore";
import { extractArtifactsData } from "utils/artifactsUtils";
import activityPanelStore from "shared/store/activityPanelStore";
import { ChatPanel } from "./components/chat/ChatPanel";
import mainStyles from "style/main.module.css";
import colorsStore from "shared/store/colorsStore";
import { extractPromptContent, startsWithPrompt } from "utils/promptUtils";

export const AssistantPage: React.FC = () => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const params = useParams();
  const threadId = params.threadId;
  const { fetchThread, resetThread } = useThreadsStore();
  const { setEsgPreferencesStore, setConstraintsStore } = activityPanelStore();

  const [historyFetched, setHistoryFetched] = useState(false);

  const messages = useThreadsStore((state) => state.messages[threadId || ""]);

  useEffect(() => {
    if (messages && messages.length > 0) {
      const result = extractArtifactsData(messages);
      if (result.latestFinecoESGPreferences) {
        setEsgPreferencesStore(result.latestFinecoESGPreferences);
      }
      if (result.latestFinecoPortfolioConstraints) {
        setConstraintsStore(result.latestFinecoPortfolioConstraints, true);
      }
      //** fetch history threads if new user message has been sent */
      if (!historyFetched) {
        for (let i = 0; i < messages.length; i++) {
          // @ts-ignore
          if (messages[i].role === "user") {
            setHistoryFetched(true);
            useThreadsStore.getState().fetchThreads();
            break;
          }
        }
      }
    }
  }, [messages]);

  useEffect(() => {
    if (threadId) {
      if (startsWithPrompt(threadId)) {
        const prompt = extractPromptContent(threadId);
      } else {
        setHistoryFetched(false);
        resetThread(threadId);
        fetchThread(threadId);
      }
    }
  }, [threadId]);

  return (
    <div
      className={mainStyles.mainContainer}
      style={{
        backgroundColor: colorsConfig.white,
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
      }}
    >
      <div className="relative flex h-full flex-row flex-nowrap ">
        <div className="block">
          <ActivityPanelContainer />
        </div>

        <div
          className="h-full max-h-full w-full max-w-full flex-grow"
          style={{
            backgroundColor: colorsConfig.white,
          }}
        >
          {/* <div className="absolute left-10 top-0 z-10">{branding}</div> */}
          {/* <div className="relative z-10" style={{ top: 20, left: 40 }}> */}
          {/* {branding} */}
          {/* </div> */}
          <ChatPanel />
        </div>
      </div>
    </div>
  );
};
