import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ProAssistantThreadHistoryItem } from "shared/models/pro_assistant/ProAssistantModel";
import useProAssistantStore from "shared/store/proAssistantStore";
import { Loading } from "shared/types/enums";
import { DateRange, mapByDateRanges } from "./utils";
import { Skeleton } from "shared/components/common/Skeleton";
import { ExpandableWidget } from "modules/pro_ai_assistant/ExpandableWidget";
import { ThreadRow } from "./ThreadRow";

type Props = {
  expanded: boolean;
  setExpanded: (e: boolean) => void;
};

export const ThreadHistoryWidget: React.FC<Props> = ({
  expanded,
  setExpanded,
}) => {
  const { t } = useTranslation();
  const [deletedThreads, setDeletedThreads] = useState<string[]>([]);
  const { threads, historyLoading } = useProAssistantStore();
  const mappedThreads = mapByDateRanges(
    threads.filter((t) => !deletedThreads.includes(t.thread_id)),
  );

  const updateDeletedThreads = (threadId: string) => {
    setDeletedThreads((prev) => [...prev, threadId]);
  };

  return (
    <ExpandableWidget
      title={t("history.title")}
      expanded={expanded}
      setExpanded={setExpanded}
    >
      {!threads?.length && historyLoading === Loading.InProgress ? (
        <Skeleton />
      ) : (
        <div className="h-full space-y-4">
          <ThreadList
            range="today"
            threads={mappedThreads}
            onThreadDelete={updateDeletedThreads}
          />
          <ThreadList
            range="yesterday"
            threads={mappedThreads}
            onThreadDelete={updateDeletedThreads}
          />
          <ThreadList
            range="lastMonth"
            threads={mappedThreads}
            onThreadDelete={updateDeletedThreads}
          />
          <ThreadList
            range="lastYear"
            threads={mappedThreads}
            onThreadDelete={updateDeletedThreads}
          />
          <ThreadList
            range="remaining"
            threads={mappedThreads}
            onThreadDelete={updateDeletedThreads}
          />
        </div>
      )}
    </ExpandableWidget>
  );
};

type ThreadListProps = {
  range: DateRange;
  threads: Record<DateRange, ProAssistantThreadHistoryItem[]>;
  onThreadDelete?: (threadId: string) => void;
};

export const ThreadList: React.FC<ThreadListProps> = ({
  range,
  threads,
  onThreadDelete,
}) => {
  const { t } = useTranslation();

  if (!threads[range].length) return null;

  return (
    <div className="overflow-hidden text-ellipsis whitespace-nowrap">
      <div className="mb-2 text-sm font-bold text-black">
        {t(`assistant.dateRange.${range}`)}
      </div>
      {threads[range].map((thread) => (
        <ThreadRow
          key={thread.thread_id}
          thread={thread}
          onThreadDelete={onThreadDelete}
        />
      ))}
    </div>
  );
};
