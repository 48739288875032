type Props = {
  className?: string;
  style?: React.CSSProperties; // Aggiungi style qui
};

export default function CrmIcon({ className, style }: Props) {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      id="crm"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 52 52"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M46.908 35.749H5.092V11.625a2 2 0 0 1 2-2h37.816a2 2 0 0 1 2 2v24.124zM2 35.749h48v6.626H2z"
      ></path>
      <path d="m18.564 24.165 1.31.331c-.275 1.076-.77 1.896-1.483 2.461-.713.565-1.585.847-2.616.847-1.066 0-1.934-.217-2.602-.651-.669-.434-1.177-1.063-1.526-1.887a6.733 6.733 0 0 1-.523-2.653c0-1.031.197-1.93.59-2.697a4.047 4.047 0 0 1 1.682-1.749 4.912 4.912 0 0 1 2.4-.597c.99 0 1.822.252 2.498.756.675.504 1.145 1.213 1.41 2.126l-1.289.304c-.23-.72-.563-1.244-1-1.573-.436-.328-.985-.493-1.646-.493-.761 0-1.397.183-1.908.547s-.87.854-1.076 1.469a5.912 5.912 0 0 0-.31 1.9c0 .842.122 1.576.367 2.204.245.628.627 1.097 1.144 1.408s1.078.466 1.681.466c.734 0 1.355-.212 1.864-.635.508-.423.853-1.05 1.033-1.884zM21.507 27.636v-9.898h4.388c.883 0 1.553.09 2.012.267a2.2 2.2 0 0 1 1.1.942c.275.45.412.947.412 1.492 0 .702-.227 1.294-.681 1.775-.455.482-1.157.788-2.107.919.347.166.61.33.79.492.383.351.745.79 1.087 1.317l1.722 2.694h-1.648l-1.31-2.06a18.77 18.77 0 0 0-.945-1.363c-.247-.315-.469-.536-.665-.662a1.966 1.966 0 0 0-.597-.263c-.149-.032-.392-.047-.73-.047h-1.518v4.395h-1.31zm1.31-5.53h2.815c.599 0 1.067-.062 1.404-.185s.594-.322.77-.594c.175-.273.263-.569.263-.888 0-.468-.17-.853-.51-1.155-.34-.301-.876-.452-1.61-.452h-3.132v3.274zM31.431 27.636v-9.898h1.972l2.342 7.008c.216.653.374 1.141.473 1.465.112-.36.288-.889.526-1.586l2.37-6.887h1.762v9.898h-1.262v-8.284l-2.876 8.284h-1.182l-2.862-8.426v8.426H31.43z"></path>
      <g>
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M46.908 35.749H5.092V11.625a2 2 0 0 1 2-2h37.816a2 2 0 0 1 2 2v24.124zM2 35.749h48v6.626H2z"
        ></path>
        <path
          stroke="currentColor"
          fill="currentColor"
          d="m18.564 24.165 1.31.331c-.275 1.076-.77 1.896-1.483 2.461-.713.565-1.585.847-2.616.847-1.066 0-1.934-.217-2.602-.651-.669-.434-1.177-1.063-1.526-1.887a6.733 6.733 0 0 1-.523-2.653c0-1.031.197-1.93.59-2.697a4.047 4.047 0 0 1 1.682-1.749 4.912 4.912 0 0 1 2.4-.597c.99 0 1.822.252 2.498.756.675.504 1.145 1.213 1.41 2.126l-1.289.304c-.23-.72-.563-1.244-1-1.573-.436-.328-.985-.493-1.646-.493-.761 0-1.397.183-1.908.547s-.87.854-1.076 1.469a5.912 5.912 0 0 0-.31 1.9c0 .842.122 1.576.367 2.204.245.628.627 1.097 1.144 1.408s1.078.466 1.681.466c.734 0 1.355-.212 1.864-.635.508-.423.853-1.05 1.033-1.884zM21.507 27.636v-9.898h4.388c.883 0 1.553.09 2.012.267a2.2 2.2 0 0 1 1.1.942c.275.45.412.947.412 1.492 0 .702-.227 1.294-.681 1.775-.455.482-1.157.788-2.107.919.347.166.61.33.79.492.383.351.745.79 1.087 1.317l1.722 2.694h-1.648l-1.31-2.06a18.77 18.77 0 0 0-.945-1.363c-.247-.315-.469-.536-.665-.662a1.966 1.966 0 0 0-.597-.263c-.149-.032-.392-.047-.73-.047h-1.518v4.395h-1.31zm1.31-5.53h2.815c.599 0 1.067-.062 1.404-.185s.594-.322.77-.594c.175-.273.263-.569.263-.888 0-.468-.17-.853-.51-1.155-.34-.301-.876-.452-1.61-.452h-3.132v3.274zM31.431 27.636v-9.898h1.972l2.342 7.008c.216.653.374 1.141.473 1.465.112-.36.288-.889.526-1.586l2.37-6.887h1.762v9.898h-1.262v-8.284l-2.876 8.284h-1.182l-2.862-8.426v8.426H31.43z"
        ></path>
      </g>
    </svg>
  );
}
