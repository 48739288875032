import { cn } from "utils/misc";
import { Palette } from "utils/misc";
import { MicIcon, RotateCw, StopCircleIcon } from "lucide-react";
import { Duration } from "luxon";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { SpeechToTextService } from "services/SpeechToTextService";
import MicrophoneIcon from "assets/svg/MicrophoneIcon";
import colorsStore from "shared/store/colorsStore";

type Props = {
  onText?: (text: string) => void;
  onRecordingChanged?: (recording: boolean) => void;
  disabled?: boolean;
};

export const MicrophoneButton = forwardRef((props: Props, ref) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const { onText, onRecordingChanged, disabled } = props;
  const [recording, setRecording] = useState(false);
  const [converting, setConverting] = useState(false);

  const [recordingStartTime, setRecordingStartTime] = useState(0);
  const [recordingTime, setRecordingTime] = useState(0);
  const [timer, setTimer] = useState<number | null>(null);

  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [chunks, setChunks] = useState<Blob[]>([]);
  const [recordingFinished, setRecordingFinished] = useState(false);

  const initMediaRecorder = async () => {
    let stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    const mc = new MediaRecorder(stream);

    mc.ondataavailable = (e) => {
      setChunks([...chunks, e.data]);
    };

    mc.onstop = () => {
      setRecordingFinished(true);
    };

    setMediaRecorder(mc);
    return mc;
  };

  useEffect(() => {
    onRecordingChanged?.(recording);
    if (recording && !timer) {
      const t = setInterval(() => {
        setRecordingTime(Date.now() - recordingStartTime);
      }, 1000) as unknown as number;

      setTimer(t);
    }
    if (!recording && timer) {
      clearInterval(timer);
      setTimer(null);
    }
  }, [recording, timer]);

  useEffect(() => {
    if (recordingFinished && chunks.length) {
      const blob = new Blob(chunks, { type: "audio/wav;" });
      setConverting(true);
      SpeechToTextService.getText(blob)
        .then((text) => {
          if (text) {
            onText?.(text);
          }
        })
        .finally(() => {
          setConverting(false);
        });

      setChunks([]);
      setRecordingFinished(false);
    }
  }, [recordingFinished, chunks]);

  useEffect(() => {
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stop();
      }
    };
  }, []);

  const stop = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
      // Reset the mediaRecorder after stopping
      setMediaRecorder(undefined);
    }
  };

  const record = async () => {
    if (disabled) return;
    // Always create a new MediaRecorder instance
    await initMediaRecorder().then((mc) => {
      mc.start();
      setRecording(true);
      setRecordingStartTime(Date.now());
      setRecordingTime(0);
    });
  };
  useImperativeHandle(ref, () => ({
    record,
    stop,
  }));

  const formatedDuration = Duration.fromMillis(recordingTime).toFormat("mm:ss");

  if (disabled) {
    // return null;
  }
  return (
    <div className="flex items-center gap-4">
      {converting ? (
        <RotateCw className="sizze-5 animate-spin text-gray-500" />
      ) : (
        <div
          className="relative  duration-150 hover:opacity-50"
          style={{ cursor: disabled ? "" : "pointer" }}
          onClick={() => {
            if (recording) {
              stop();
            } else {
              record();
            }
          }}
        >
          {!recording && (
            <MicrophoneIcon
              // className={cn("size-6")}
              style={{
                color: recording
                  ? colorsConfig.chatInputColor
                  : colorsConfig.chatInputColor,
                opacity: disabled ? 0.5 : 1,
              }}
            />
          )}
          {recording && (
            <>
              <StopCircleIcon
                className="size-6"
                style={{ color: colorsConfig.chatInputColor }}
              />
              <div
                className={cn(
                  "absolute inset-0 size-6 animate-ping rounded-full",
                )}
              />
            </>
          )}
        </div>
      )}
      <div>{recording ? formatedDuration : null}</div>
      {recording && mediaRecorder && (
        <LiveAudioVisualizer
          mediaRecorder={mediaRecorder}
          barColor={Palette.Red}
          width={200}
          height={24}
        />
      )}
    </div>
  );
});
