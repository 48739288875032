import { useEffect } from "react";
import { useRecentSearch } from "shared/hooks/useRecentSearch";
import useAppStore from "shared/store/appStore";
import { auth } from "shared/utils/firebase";

export const RecentSearchFetcher: React.FC = () => {
  const { getRecentSearches } = useRecentSearch();
  const { setSearchHistory } = useAppStore();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getRecentSearches().then((result) => {
          setSearchHistory(result);
        });
      }
    });
    return () => unsubscribe();
  }, []);

  return <></>;
};
