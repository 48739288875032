import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Dropdown } from "../Dropdown";
import { useTranslation } from "react-i18next";
import activityPanelStore from "shared/store/activityPanelStore";
import {
  convertToPercentage,
  parseValue,
  riskProfileMapEngToIta,
} from "utils/misc";
import { GridItem } from "./GridItem";
import colorsStore from "shared/store/colorsStore";

type RiskContentProps = {
  configData: {
    financial_plan: {
      values: number[];
      type: string;
      default: number;
    };
    risk_profile: {
      values: string[];
      type: string;
      default: string;
    };
  };
  onDataChange: (data: Record<string, any>) => void;
  setHasChanged: (value: boolean) => void;
};

export const value_at_risk_limits = {
  "1": { min: 1.0, max_verde: 3.2, max: 3.5, central: 2.03 },
  "2": { min: 3.0, max_verde: 4.5, max: 5.0, central: 3.53 },
  "3": { min: 4.5, max_verde: 7.4, max: 8.0, central: 6.48 },
  "4": { min: 7.5, max_verde: 10.2, max: 11.0, central: 9.01 },
  "5": { min: 10.0, max_verde: 14.3, max: 15.0, central: 12.5 },
  "6": { min: 13.5, max_verde: 19.0, max: 20.0, central: 16.0 },
  "7": { min: 17.0, max_verde: 23.8, max: 25.0, central: 21.02 },
  "8": { min: 22.0, max_verde: 31.4, max: 45.0, central: 25.03 },
};

export const RiskContent = forwardRef<{ reset: () => void }, RiskContentProps>(
  ({ configData, onDataChange, setHasChanged }, ref) => {
    const colorsConfig = colorsStore((state) => state.colorsConfigStore);

    const constraintsState = activityPanelStore(
      (state) => state.constraintsState,
    );
    const setFinancialPlanStore = activityPanelStore(
      (state) => state.setFinancialPlanStore,
    );

    const [isUsingDefaults, setIsUsingDefaults] = useState<boolean>(true);
    const { t } = useTranslation();
    const getData = () => {
      if (constraintsState?.data?.risk) {
        const riskProfile = constraintsState.data.risk.risk_profile
          ? riskProfileMapEngToIta(constraintsState.data.risk.risk_profile) ||
            constraintsState.data.risk.risk_profile
          : configData.risk_profile.default;

        let valueOutputTargetVar = "";
        if (constraintsState?.data?.risk.target_var) {
          valueOutputTargetVar = convertToPercentage(
            constraintsState?.data?.risk.target_var,
          );
        } else {
          valueOutputTargetVar =
            value_at_risk_limits[
              constraintsState.data.risk.financial_plan ??
                configData.financial_plan.default
            ].central;
        }
        const tempFinancialPlan =
          constraintsState.data.risk.financial_plan ??
          configData.financial_plan.default;

        return {
          financial_plan:
            constraintsState.data.risk.financial_plan ??
            configData.financial_plan.default,
          risk_profile: riskProfile,
          target_var: value_at_risk_limits[tempFinancialPlan].central,
        };
      }

      return {
        financial_plan: configData.financial_plan.default,
        risk_profile: configData.risk_profile.default,
      };
    };

    const [hasUserChanged, setHasUserChanged] = useState<boolean>(false);
    const [activeValue, setActiveValue] = useState(getData());
    const [prevFinancialPlan, setPrevFinancialPlan] = useState<number | null>(
      null,
    );
    const initialValuesRef = useRef<Record<string, string | number>>({});

    const financialPlanMapping = {
      Cauto: { max: 1 },
      Prudente: { max: 3 },
      Bilanciato: { max: 5 },
      Dinamico: { max: 7 },
    };
    useImperativeHandle(ref, () => ({
      reset: () => {
        const defaultValues = getData();
        setActiveValue(getData());
        initialValuesRef.current = defaultValues;
        setHasUserChanged(false);
        setPrevFinancialPlan(null);
      },
    }));
    useEffect(() => {
      setIsUsingDefaults(!constraintsState?.data?.risk);
      setActiveValue(getData());
    }, [constraintsState?.data?.risk]);

    useEffect(() => {
      if (
        hasUserChanged &&
        activeValue.financial_plan &&
        activeValue.risk_profile
      ) {
        onDataChange(activeValue);
        setHasChanged(true);
        setPrevFinancialPlan(activeValue.financial_plan);
      }
    }, [activeValue, hasUserChanged]);

    useEffect(() => {
      onDataChange(activeValue);
    }, []);

    const onFinancialPlanChange = (value: string) => {
      setFinancialPlanStore(Number(value));
      setHasUserChanged(true);
      const currentRiskProfile = activeValue.risk_profile;

      if (Number(value) > financialPlanMapping[currentRiskProfile]?.max) {
        let newRiskProfile = Object.keys(financialPlanMapping).find(
          (key) => Number(value) <= financialPlanMapping[key].max,
        );
        if (!newRiskProfile) {
          newRiskProfile = Object.keys(financialPlanMapping).find(
            (key) => Number(value) <= financialPlanMapping[key].max + 1,
          );
        }
        setActiveValue({
          financial_plan: Number(value),
          risk_profile: newRiskProfile || currentRiskProfile,
          target_var: value_at_risk_limits[Number(value)].central,
        });
      } else {
        setActiveValue((prev) => ({
          ...prev,
          financial_plan: Number(value),
          target_var: value_at_risk_limits[Number(value)].central,
        }));
      }
    };

    const onRiskProfileChange = (value: string) => {
      setHasUserChanged(true);
      const financialPlanMax = financialPlanMapping[value]?.max;
      if (activeValue.financial_plan > financialPlanMax) {
        setActiveValue({
          financial_plan: financialPlanMax,
          risk_profile: value,
          target_var: value_at_risk_limits[Number(financialPlanMax)].central,
        });
      } else {
        setActiveValue((prev) => ({
          ...prev,
          risk_profile: value,
          target_var:
            value_at_risk_limits[Number(activeValue.financial_plan)].central,
        }));
      }
    };

    const handleInputChange = (e) => {
      setHasUserChanged(true);
      const value = parseValue(e.target, configData);
      let limitedValue = value;
      if (value > value_at_risk_limits[activeValue.financial_plan].max) {
        limitedValue = value_at_risk_limits[activeValue.financial_plan].max;
      }

      setActiveValue((prevValues) => ({
        ...prevValues,
        [e.target.id]: limitedValue,
      }));
    };

    useEffect(() => {
      const updatedValues = getData();
      initialValuesRef.current = updatedValues;
    }, [constraintsState?.data?.risk]);
    return (
      <>
        <div style={{ opacity: activeValue.risk_profile ? 1 : 1 }}>
          <Dropdown
            label={t("constraints.financial_plan")}
            options={configData.financial_plan.values.map(String)}
            defaultValue={activeValue.financial_plan?.toString()}
            onChange={onFinancialPlanChange}
          />
        </div>
        <div
          style={{
            opacity: activeValue.financial_plan ? 1 : 1,
            marginTop: 10,
          }}
        >
          <Dropdown
            label={t("constraints.risk_profile")}
            options={configData.risk_profile.values}
            defaultValue={activeValue.risk_profile}
            onChange={onRiskProfileChange}
          />
        </div>
        <div
          className="grid-container-2x2"
          style={{ color: colorsConfig.finecoBlack, fontSize: 14 }}
        >
          <GridItem
            cd={configData}
            cv1={Object.keys(configData)[2]}
            cv2={Object.keys(configData)[3]}
            notEditableValue={{
              value: value_at_risk_limits[activeValue.financial_plan].max,
              label: "VaR Max",
            }}
            fv={activeValue}
            ohi={handleInputChange}
          />
        </div>
      </>
    );
  },
);
