import { TrashIcon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { ProAssistantThreadHistoryItem } from "shared/models/pro_assistant/ProAssistantModel";
import { ProAssistantService } from "shared/services/pro_assistant/ProAssistantService";
import useProAssistantStore from "shared/store/proAssistantStore";
import { cn } from "utils";

type Props = {
  thread: ProAssistantThreadHistoryItem;
  onThreadDelete?: (threadId: string) => void;
};

export const ThreadRow: React.FC<Props> = ({ thread, onThreadDelete }) => {
  const { t } = useTranslation();
  const { threadId } = useParams();
  const navigate = useNavigate();
  const [deleted, setDeleted] = useState(false);
  const { fetchThreads } = useProAssistantStore();
  const [modalOpen, setModalOpen] = useState(false);

  const onDelete = () => {
    setDeleted(true);
    onThreadDelete?.(thread.thread_id);
    ProAssistantService.deleteThread(thread.thread_id)
      .then(() => {
        navigate("/assistantWarren");
        fetchThreads();
        toast.success(t("proAssistant.threadDeleted"));
      })
      .catch(() => {
        setDeleted(false);
      });
  };

  if (deleted) {
    return null;
  }

  return (
    <div
      className={cn(
        "relative max-w-[280px] cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap rounded-lg px-2 py-2 pr-10 text-sm text-gray-700 duration-150 hover:bg-gray-200",
        threadId === thread.thread_id && "bg-gray-shadow",
      )}
      onClick={() => navigate(`/assistantWarren/${thread.thread_id}`)}
    >
      <div className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-700 duration-150 hover:text-gray-500">
        <TrashIcon
          className="size-3 cursor-pointer"
          onClick={() => setModalOpen(true)}
        />
        <Modal open={modalOpen} setOpen={setModalOpen}>
          <h3>{t("proAssistant.deleteThread")}</h3>
          <p>{t("proAssistant.deleteThreadDesc")}</p>
          <div className="mt-4 flex items-center justify-between">
            <Button
              onClick={() => setModalOpen(false)}
              btnStyle="blank"
              label={t("global.cancel")}
            />
            <Button onClick={onDelete} label={t("watchlist.delete")} />
          </div>
        </Modal>
      </div>
      {thread.title || t("assistant.noTitle")}
    </div>
  );
};
