import { StrategySubscriptionsModel } from "shared/models/subscriptions/StrategySubscriptionsModel";
import { create } from "./store";
import { Loading } from "shared/types/enums";
import { SubscriptionsService } from "shared/services/subscriptions/SubscriptionsService";



type SubscriptionsStoreState = {
  subscriptions: StrategySubscriptionsModel[];
  loading: Loading;
  fetched: boolean;
};

type SubscriptionsStoreActions = {
  fetchSubscriptions: () => Promise<StrategySubscriptionsModel[]>;
};

const initialData: SubscriptionsStoreState = {
  subscriptions: [],
  loading: Loading.Idle,
  fetched: false,
};

const useSubscriptionsStore = create<SubscriptionsStoreState & SubscriptionsStoreActions>()(
  (set, get) => ({
    ...initialData,
    fetchSubscriptions: async () => {
      set({ loading: Loading.InProgress });
      try {
        const subscriptions = await SubscriptionsService.getMany();
        set({ subscriptions });
        return subscriptions;
      } finally {
        set({ loading: Loading.Finished, fetched: true });
      }
    }
  }),
);

export default useSubscriptionsStore;
