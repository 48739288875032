import React from "react";

import { LetUsKnowYouColumn } from "./components/LetUsKnowYouColumn";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingPartProps } from "./OnboardingFlow";
import { Labels } from "shared/components/hoc/Labels";
import { useTranslation } from "react-i18next";
import { Inputs } from "shared/components/hoc/Inputs";
import { BrokerageAccountDocumentUpload } from "shared/models/brokerage_account/BrokerageAccountModel";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import useOnboardingStore from "shared/store/onboardingStore";

export const UploadVisa: React.FC<OnboardingPartProps> = ({
  onNext,
  onPrevious,
}) => {
  const { t } = useTranslation();
  const { visaOrResidenceCardProofPhoto } = useOnboardingStore(
    (state) => state.data,
  );
  const { update } = useOnboardingStore();

  const handleOnValueChange = (
    visaOrResidenceCardProofPhotoValue:
      | BrokerageAccountDocumentUpload
      | undefined,
  ) => {
    update({
      visaOrResidenceCardProofPhoto: visaOrResidenceCardProofPhotoValue,
    });
  };

  return (
    <OnboardingContainer
      leftColumnContent={<LetUsKnowYouColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1">
            <Labels.H2 className="mb-2">{t("kyc.visa.title")}</Labels.H2>
            <Labels.B1 className="mb-6">{t("kyc.visa.subTitle")}</Labels.B1>
            <Inputs.File
              label="documents.options.file"
              onAttach={(_, base64, mimeType) => {
                handleOnValueChange({
                  ...visaOrResidenceCardProofPhoto,
                  document_type: "identity_verification",
                  content: base64,
                  mime_type: mimeType,
                });
              }}
            />
          </div>
          <OnboardingFooter
            onNext={() => onNext && onNext()}
            onPrev={() => onPrevious && onPrevious()}
            nextDisabled={!visaOrResidenceCardProofPhoto}
          />
        </div>
      }
    />
  );
};
