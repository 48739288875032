import { ClientHeader } from "modules/client_header/ClientHeader";
import { PortfolioBacktestingChart } from "modules/portfolio_backtesting_chart/PortfolioBacktestingChart";
import { PortfolioProjectionChart } from "modules/portfolio_projection_chart/PortfolioProjectionChart";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BasePage, RightBar } from "shared/components/common/BasePage";
import { Panel } from "shared/components/hoc/Panels";
import { FrequencyType } from "shared/types/RecurringInvestmentModel";
import { Button } from "shared/components/controls/Button";
import { AiAssistant } from "modules/ai_assistant/AiAssistant";
import { PaperAirplane } from "modules/proposals/svg/PaperAirplane";
import { ProposalsService } from "shared/services/proposals/ProposalsService";
import { PortfolioNews } from "modules/news/PortfolioNews";
import { ProposalReadme } from "./ProposalReadme";
import { EditableInitialInvestment } from "./EditableInitialInvestment";
import { EditableRecurrentInvestment } from "./EditableRecurrentInvestment";
import { EditablePortfolioComposition } from "./EditablePortfolioComposition";
import { EditablePortfolioNames } from "./EditablePortfolioNames";
import { BackButton } from "shared/components/common/BackButton";
import { Layouts } from "shared/components/hoc/Layouts";
import { SendProposalModal } from "./SendProposalModal";
import { FaApiRoutes } from "shared/utils/routes";
import { AiPortfolios } from "modules/ai_assistant/AiPortfolios";
import { WebsocketProvider } from "shared/api/WebsocketProvider";
import useProposalsStore from "shared/store/proposalsStore";
import useClientsStore from "shared/store/clientsStore";
import usePortfoliosStore from "shared/store/portfoliosStore";
import { ChatPanel } from "pages/assistant/components/chat/ChatPanel";
import useAppStatus from "shared/store/appStatusStore";
import useThreadsStore from "shared/store/threadsStore";
import RebalanceDropdown from "pages/fa/portoflio/RebalanceDropdown";

export const UpdateProposalPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { proposalId } = useParams();
  const proposal = useProposalsStore((state) =>
    state.proposals.find((p) => p.proposal_id === proposalId),
  );
  const [showMessageModal, setShowMessageModal] = useState(false);

  const client = useClientsStore((state) =>
    state.clients.find(
      (c) => String(c.user_id) === String(proposal?.client_id),
    ),
  );
  const portfolio = usePortfoliosStore((state) =>
    state.portfolios.find((p) => p.id === proposal?.strategy_type),
  );

  const { fetchProposalById, fetchProposals } = useProposalsStore();
  const { fetchPortfolioById } = usePortfoliosStore();
  const windowH = useAppStatus((state) => state.windowH);
  const { fetchClientById } = useClientsStore();
  const { refreshProposalId } = useThreadsStore();

  const [loading, setLoading] = useState(false);
  const initialAmount = proposal?.initial_deposit
    ? `${proposal?.initial_deposit}`
    : "0";
  const recurringAmount = proposal?.recurring_deposit
    ? `${proposal?.recurring_deposit}`
    : "0";
  const recurringFrequency = "month";

  useEffect(() => {
    if (proposalId) {
      fetchProposalById(proposalId);
    }
  }, [proposalId, refreshProposalId]);

  useEffect(() => {
    if (proposal) {
      fetchPortfolioById(proposal.strategy_type);
    }
  }, [proposal, refreshProposalId]);

  useEffect(() => {
    if (proposal?.client_id) {
      fetchClientById(proposal?.client_id);
    }
  }, [proposal?.client_id, refreshProposalId]);

  const cancelProposal = async () => {
    if (!proposalId) return;
    setLoading(true);

    try {
      await ProposalsService.cancelProposal(proposalId);
      navigate("/proposals");
      toast.success(t("proposals.proposalCancelledSuccessfully"));
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteProposal = async () => {
    if (!proposalId) return;
    setLoading(true);

    try {
      await ProposalsService.deleteProposal(proposalId);
      await fetchProposals();
      navigate("/proposals");
      toast.success(t("proposals.proposalDeletedSuccessfully"));
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const autoApproveProposal = async () => {
    if (!proposalId) return;
    setLoading(true);
    try {
      await ProposalsService.autoApproveProposal(proposalId);
      navigate("/proposals");
      toast.success(t("proposals.proposalAutoApprovedSuccessfully"));
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const sendProposal = async (message?: string) => {
    if (!proposalId) return;
    setLoading(true);
    try {
      await ProposalsService.sendProposal(proposalId, message);
      navigate("/proposals");
      toast.success(t("proposals.proposalSentSuccessfully"));
      setShowMessageModal(false);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const onPortfolioClicked = (portfolioId: string) => {
    if (portfolioId && proposalId) {
      setLoading(true);
      return ProposalsService.patchProposal(proposalId, {
        strategy_id: portfolioId,
      })
        .then(() => {
          fetchProposalById(proposalId);
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <BasePage wideRightBar>
      <RightBar>
        <Layouts.Spaced className="mt-4">
          <div
            style={{
              width: "auto",
              height: windowH - 260,
              backgroundColor: "white",
              borderRadius: 20,
              overflow: "hidden",
            }}
          >
            {proposal && (
              <>
                <ChatPanel
                  isWidget={true}
                  proposalId={proposalId}
                  widgetThreadId={proposal.thread_id}
                />
              </>
            )}
          </div>
          <div className="mt-4">
            <AiPortfolios
              proposalId={proposalId}
              activePortfolioId={proposal?.strategy_type}
              loading={loading}
              onPortfolioClicked={onPortfolioClicked}
            />
          </div>
          {/* {proposalId && (
            <WebsocketProvider
              url={
                FaApiRoutes.Advisor.Proposals.Details(proposalId).Assistant.Path
              }
              children={(socket) => (
                <>
                  <AiAssistant
                    socket={socket}
                    sessionId={proposalId}
                    onPortfolioClicked={onPortfolioClicked}
                    activePortfolioId={proposal?.strategy_type}
                    onAllowInput={() => {
                      fetchProposalById(proposalId);
                      setLoading(false);
                    }}
                    onPostMessage={() => setLoading(true)}
                  />
                  <div className="mt-4">
                    <AiPortfolios
                      socket={socket}
                      proposalId={proposalId}
                      activePortfolioId={proposal?.strategy_type}
                      loading={loading}
                      onPortfolioClicked={onPortfolioClicked}
                    />
                  </div>
                </>
              )}
            />
          )} */}
        </Layouts.Spaced>
      </RightBar>
      <SendProposalModal
        open={showMessageModal}
        setOpen={setShowMessageModal}
        onProposalSend={sendProposal}
        sendLoading={loading}
      />
      <div>
        {proposal && <EditablePortfolioNames proposal={proposal} />}
        <div className="flex flex-wrap items-center justify-between gap-4">
          <BackButton className="mt-4" />
          <div className="flex flex-wrap items-center justify-between gap-4">
            <div className="mt-4 flex w-full items-center justify-end gap-4">
              {proposal?.status === "sent" && (
                <Button
                  onClick={cancelProposal}
                  className="outline-btn"
                  label="proposals.cancel"
                  disabled={loading}
                />
              )}

              {(proposal?.status === "draft" ||
                proposal?.status === "canceled" ||
                proposal?.status === "rejected") && (
                <Button
                  onClick={deleteProposal}
                  className="outline-btn"
                  label="proposals.delete"
                  disabled={loading}
                />
              )}

              {proposal?.status !== "accepted" && (
                <Button
                  onClick={autoApproveProposal}
                  className="outline-btn"
                  label="proposals.autoApproveProposal"
                  disabled={loading}
                />
              )}

              {proposal?.status === "draft" && (
                <Button
                  onClick={() => setShowMessageModal(true)}
                  className="green-btn"
                  label="proposals.sendProposalToClient"
                  rightIcon={
                    <div className="grid aspect-square h-6 place-items-center rounded-full bg-black">
                      <PaperAirplane className="mr-0.5 mt-0.5 aspect-square h-3 text-white" />
                    </div>
                  }
                  disabled={loading}
                />
              )}
              {/* <Button label="proposals.rebalance" disabled={loading} /> */}
              <RebalanceDropdown />
            </div>
          </div>
        </div>
      </div>
      <Layouts.Spaced>
        <Panel>
          <ClientHeader client={client} />
        </Panel>
        {proposal && <ProposalReadme proposal={proposal} />}
        <div className="flex flex-wrap justify-between gap-4">
          {proposal && (
            <>
              <div className="flex-1">
                <EditableInitialInvestment proposal={proposal} />
              </div>
              <div className="flex-1">
                <EditableRecurrentInvestment proposal={proposal} />
              </div>
            </>
          )}
        </div>
        {proposal ? (
          <>
            <EditablePortfolioComposition proposal={proposal} />
            <PortfolioProjectionChart
              portfolioId={proposal.strategy_type}
              frequency={recurringFrequency as FrequencyType}
              recurringAmount={recurringAmount}
              duration={240}
              initialAmount={initialAmount}
            />
            <PortfolioBacktestingChart portfolio={portfolio} />
            <PortfolioNews portfolioId={proposal.strategy_type} />
          </>
        ) : null}
      </Layouts.Spaced>
    </BasePage>
  );
};
