import { PropsWithChildren, useEffect, useState } from "react";
import useRealtimeTicker from "shared/hooks/useRealtimeTicker";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import useCreateOrder from "../hooks/useCreateOrder";
import GenericBuyOrder from "./GenericBuyOrder";
import useCreateOrderStore from "shared/store/createOrderStore";

type Props = PropsWithChildren & {
  asset: AssetWrapper;
  onEstimatedValueChange: (value: number) => void;
  onEstimatedSharesChange: (value: number) => void;
};

export default function MarketOrder({
  asset,
  onEstimatedValueChange,
  onEstimatedSharesChange,
  children,
}: Props) {
  const { update } = useCreateOrderStore();
  const [estimatedValue, setEstimatedValue] = useState(0);
  const [estimatedShares, setEstimatedShares] = useState(0);
  const { latestPrice } = useRealtimeTicker(asset.symbol);

  const order = useCreateOrder();

  useEffect(() => {
    setEstimatedValue(
      latestPrice && order?.qty ? latestPrice * parseFloat(order.qty) : 0,
    );
    setEstimatedShares(
      latestPrice && latestPrice > 0 && order && order?.notional
        ? parseFloat(order.notional) / latestPrice
        : 0,
    );
  }, [latestPrice, order]);

  useEffect(() => {
    onEstimatedValueChange(estimatedValue);
  }, [estimatedValue, onEstimatedValueChange]);

  useEffect(() => {
    onEstimatedSharesChange(estimatedShares);
  }, [estimatedShares, onEstimatedSharesChange]);

  useEffect(() => {
    if (order) {
      let newOrder = { ...order };
      newOrder.time_in_force = "day";
      newOrder.type = "market";
      newOrder.limit_price = undefined;
      update({ ...newOrder });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.type]);

  return (
    <GenericBuyOrder asset={asset} notionalEnabled fractional>
      {children}
    </GenericBuyOrder>
  );
}
