import { create } from "./store";
import { ColorConfigSetType, targetColorsConfig } from "config/colors-config";

type ColorsStoreState = {
  colorsConfigStore: ColorConfigSetType;
};

type ColorsStoreActions = {
  setColorsConfigStore: (theme: string, colorMode: string) => void;
};

const initialData: ColorsStoreState = {
  colorsConfigStore: { default: {}, light: {}, dark: {} },
};

const colorsStore = create<ColorsStoreState & ColorsStoreActions>()(
  (set, get) => ({
    ...initialData,
    setColorsConfigStore: (
      colorBrandName: string,
      colorMode: string = "dark",
    ) => {
      if (colorBrandName === "") {
        colorBrandName = "default";
      }
      if (colorMode === "") {
        colorMode = "dark";
      }
      //** clean Old config set */
      const root = document.documentElement;

      const colorsConfigStore = get().colorsConfigStore;
      Object.keys(colorsConfigStore).forEach((key) => {
        root.style.removeProperty(`--${key}-color`);
      });

      //** store and set for css modules */
      const colorConfigSet = targetColorsConfig[colorBrandName][colorMode];
      Object.entries(colorConfigSet).forEach(([key, value]) => {
        //** standard color */
        if (typeof value === "string") {
          root.style.setProperty(`--${key}`, value);
        }
        //** color shades */
        if (typeof value === "object") {
          Object.entries(value).forEach(([keyInner, valueInner]) => {
            root.style.setProperty(`--${key}-${keyInner}`, valueInner as any);
          });
        }
      });

      set({ colorsConfigStore: colorConfigSet as ColorConfigSetType });
    },
  }),
);

export default colorsStore;
