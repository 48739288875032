import { AggregatedAum } from "modules/aggregated_aum/AggregatedAum";
import { FeeStats } from "modules/fee_stats/FeeStats";
import { MacroeconomicConditions } from "modules/macroeconomic_conditions/MacroeconomicConditions";
import { PendingTasks } from "modules/pending_tasks/PendingTasks";
import { TopClientPerformances } from "modules/top_client_performances/TopClientPerformances";
import { TopPortfolioPerformances } from "modules/top_portfolio_performances/TopPortfolioPerformances";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BasePage } from "shared/components/common/BasePage";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { CtaCard } from "./cta/CtaCard";
import useAccountStore from "shared/store/accountStore";

export const DashboardPage = () => {
  const { t } = useTranslation();
  const { fetchAccount } = useAccountStore();

  useEffect(() => {
    fetchAccount();
  }, []);

  return (
    <BasePage globalSearch>
      <Labels.H1 className="mb-8">{t("dashboard.title")}</Labels.H1>
      <Layouts.Spaced>
        <CtaCard />
        <AggregatedAum />
        {/* <FeeStats /> */}
        <PendingTasks />
        <TopClientPerformances />
        <TopPortfolioPerformances />
        <MacroeconomicConditions />
      </Layouts.Spaced>
    </BasePage>
  );
};
