import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteHistoryIcon } from "assets/svg/DeleteHistoryIcon";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "shared/components/ui/alert-dialog";
import { useToast } from "shared/components/ui/use-toast";
import { AssistantService } from "shared/services/AssistantService";
import activityPanelStore from "shared/store/activityPanelStore";
import useThreadsStore from "shared/store/threadsStore";
import { HistoryItemType } from "types/assistant/AssistantTypes";
import { cn } from "utils/misc";
import { createNewChat } from "utils/chatUtils";
import colorsStore from "shared/store/colorsStore";

import React from "react";
import { X } from "lucide-react";

type Props = {
  thread: HistoryItemType;
  onThreadDelete?: (threadId: string) => void;
  sizeExpanded: number;
};

export const HistoryItem: React.FC<Props> = ({
  thread,
  onThreadDelete,
  sizeExpanded,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const setFromHistory = useThreadsStore((state) => state.setFromHistory);
  const busyThreads = useThreadsStore((state) => state.busyThreads);
  const isThreadBusy = busyThreads.some(
    (busyThread) => busyThread.thread_id === thread.thread_id,
  );

  const { t } = useTranslation();
  const { toast } = useToast();
  const { threadId } = useParams();
  const navigate = useNavigate();
  const [deleted, setDeleted] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const onDelete = (e: any) => {
    e.stopPropagation();
    setDeleted(true);
    // @ts-ignore
    onThreadDelete(thread.thread_id);
    AssistantService.deleteThread(thread.thread_id)
      .then(() => {
        if (threadId === thread.thread_id) {
          createNewChat(navigate);
        }
        useThreadsStore.getState().fetchThreads();
        toast({
          title: t("global.success"),
          description: t("assistant.threadDeleted"),
        });
      })
      .catch(() => {
        setDeleted(false);
      });
  };

  if (deleted) {
    return null;
  }

  const isActive = threadId === thread?.thread_id;
  return (
    <div
      className={cn(
        "relative cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap rounded-lg px-2 py-2 pr-10 text-sm duration-150",
      )}
      style={{
        width: sizeExpanded - 20,
        backgroundColor: isActive ? colorsConfig.finecoBlueLight : "",
        // @ts-ignore
        color: isActive
          ? colorsConfig.finecoBluePrimary
          : colorsConfig.finecoBlack,
        cursor: isActive ? "auto" : "pointer",
      }}
      onMouseEnter={(e) => {
        setIsHover(true);
        // @ts-ignore
        e.currentTarget.style.color = isActive
          ? colorsConfig.finecoBluePrimary
          : colorsConfig.finecoBluePrimary;
      }}
      onMouseLeave={(e) => {
        setIsHover(false);
        // @ts-ignore
        e.currentTarget.style.color = isActive
          ? colorsConfig.finecoBluePrimary
          : colorsConfig.finecoBlack;
      }}
      onClick={() => {
        if (isActive) return;
        setFromHistory(true);
        activityPanelStore.getState().resetStore();
        navigate(`/assistant/${thread.thread_id}`);
      }}
    >
      <div
        className="absolute right-2 top-1/2 flex -translate-y-1/2 items-center gap-2"
        // @ts-ignore
        style={{ color: colorsConfig.threadHistoryCopy }}
        onMouseEnter={(e) => {
          setIsHover(true);
          e.currentTarget.style.opacity = isActive ? "1" : "0.5";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.opacity = isActive ? "0.5" : "1";
        }}
      >
        {!isHover ? (
          <></>
        ) : (
          <>
            {/* <div
              className="h-3 w-3 rounded-full"
              style={{ backgroundColor: isThreadBusy ? "red" : "green" }}
            /> */}
            <AlertDialog>
              <AlertDialogTrigger onClick={(e) => e.stopPropagation()}>
                <div className="flex h-3 w-3 items-center justify-center">
                  <DeleteHistoryIcon className="size-3 cursor-pointer" />
                </div>
              </AlertDialogTrigger>
              <AlertDialogContent
                style={{
                  // @ts-ignore
                  backgroundColor: "white",
                  // @ts-ignore
                  color: colorsConfig.threadHistoryCopy,
                }}
              >
                <AlertDialogHeader>
                  <AlertDialogTitle
                    style={{
                      // @ts-ignore
                      color: colorsConfig.threadHistoryCopy,
                    }}
                  >
                    {t("assistant.deleteThread")}
                  </AlertDialogTitle>
                </AlertDialogHeader>
                {t("assistant.deleteThreadDesc")}
                <AlertDialogFooter>
                  <AlertDialogCancel
                    style={{
                      color: colorsConfig.threadHistoryCopy,
                    }}
                  >
                    {t("global.cancel")}
                  </AlertDialogCancel>
                  <AlertDialogAction onClick={onDelete}>
                    {t("global.delete")}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </>
        )}
      </div>
      {thread.title || t("assistant.noTitle")}
    </div>
  );
};

const AlertDialogWithClose = () => {
  const { t } = useTranslation();
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  return (
    <AlertDialog>
      <AlertDialogTrigger onClick={(e) => e.stopPropagation()}>
        <div className="flex h-3 w-3 items-center justify-center">
          <DeleteHistoryIcon className="size-3 cursor-pointer" />
        </div>
      </AlertDialogTrigger>
      <AlertDialogContent
        style={{
          backgroundColor: "white",
          color: colorsConfig.threadHistoryCopy,
        }}
      >
        <div className="flex items-start justify-between">
          <AlertDialogHeader>
            <AlertDialogTitle
              style={{
                color: colorsConfig.threadHistoryCopy,
              }}
            >
              {t("assistant.deleteThread")}
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogCancel className="rounded-full p-2 hover:bg-main">
            <X className="size-4 text-white" style={{ color: "black" }} />
          </AlertDialogCancel>
        </div>

        <div className="py-4 text-white" style={{ color: "black" }}>
          Are you sure you want to delete this thread?
        </div>

        <AlertDialogFooter>
          <AlertDialogCancel className="border-white text-white hover:bg-main">
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction className="bg-white text-pink-500 hover:bg-gray-100">
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AlertDialogWithClose;
