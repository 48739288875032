import React from "react";

const YieldSummaryTable = ({ dataObject }) => {
  if (!dataObject) {
    return null;
  }

  const rows = [
    {
      label: "Duration (anni)",
      total: dataObject.duration_total.toFixed(2),
      bonds: dataObject.duration_obbligazioni || "0,00",
    },
    {
      label: "Yield to Maturity (%)",
      total: dataObject.ytm_total.toFixed(2),
      bonds: dataObject.ytm_obbligazioni || "0,00",
    },
    {
      label: "Distribuzione proventi (%)",
      total: dataObject.monthly_distribution.toFixed(2),
      bonds: "-",
    },
  ];

  return (
    <div className="table-container" style={{ marginTop: 10 }}>
      <table className="distribution-table">
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>{row.label}</td>
              <td>{row.total}</td>
              {/* <td>{row.bonds}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default YieldSummaryTable;
