import { ChatInput } from "./ChatInput";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RefreshCcwIcon } from "lucide-react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import {
  ProAssistantTaskUpdate,
  ProAssistantMessage,
  ProAssistantAttachment,
} from "shared/models/pro_assistant/ProAssistantModel";
import useProAssistantStore from "shared/store/proAssistantStore";
import { AttachmentResponse } from "./responses/AttachmentResponse";
import { ChatMessage } from "./ChatMessage";
import { Button } from "shared/components/controls/Button";
import { ChatTaskUpdate } from "./ChatTaskUpdate";
import { ScrollArea } from "shared/components/ui/scroll-area";

type Props = {
  socket: WebSocket;
  initialMessage?: string;
  onInitialMessageSend?: () => void;
  retryButton: boolean;
  setRetryButton: React.Dispatch<React.SetStateAction<boolean>>;
  taskUpdate?: ProAssistantTaskUpdate;
};

export const Assistant: React.FC<Props> = ({
  socket,
  initialMessage,
  onInitialMessageSend,
  retryButton,
  setRetryButton,
  taskUpdate,
}) => {
  const params = useParams();
  const threadId = params.threadId;
  const messages = useProAssistantStore(
    (state) => state.messages[threadId || ""],
  );
  const attachments = useProAssistantStore(
    (state) => state.attachments[threadId || ""],
  );
  const blockInput = useProAssistantStore(
    (state) => state.blockInput[threadId || ""],
  );
  const { fetchThreads } = useProAssistantStore();

  const getCombinedMessages = (): (
    | ProAssistantMessage
    | ProAssistantAttachment
  )[] => {
    const combined = [...(messages || []), ...(attachments || [])];
    return combined.sort(
      (a, b) =>
        DateTime.fromISO(a.created_at).toMillis() -
        DateTime.fromISO(b.created_at).toMillis(),
    );
  };

  const postMessage = useCallback(
    (message: string) => {
      socket?.send(
        JSON.stringify({ type: "add_message", payload: { content: message } }),
      );
    },
    [socket],
  );

  useEffect(() => {
    if (socket && initialMessage && !blockInput && !messages?.length) {
      // @ts-ignore
      if (messages.filter((m) => m.role === "user").length === 0) {
        postMessage(initialMessage);
        onInitialMessageSend?.();
        fetchThreads();
      }
    }
  }, [
    socket,
    initialMessage,
    blockInput,
    messages?.length,
    postMessage,
    messages,
  ]);

  const scrollLastMessageIntoView = () => {
    document.getElementById("lastAdvisorMessage")?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(() => {
    scrollLastMessageIntoView();
  }, [messages?.length, blockInput]);

  return (
    <>
      <div className="flex h-full max-h-full w-full flex-col justify-end pb-4">
        <ScrollArea
          className="relative space-y-4 pr-3 "
          style={{
            maxHeight: "calc(100dvh - 250px)",
            minHeight: "calc(100dvh - 250px)",
          }}
        >
          <div
            className="mx-auto flex max-w-6xl flex-col justify-end space-y-10 pr-2"
            style={{ minHeight: "calc(100% - 50px)" }}
          >
            {getCombinedMessages()?.map((message, idx) => {
              if ("attachment_id" in message) {
                return <AttachmentResponse attachment={message} key={idx} />;
              } else {
                return <ChatMessage key={idx} message={message} />;
              }
            })}
            {blockInput && messages?.length ? <ChatMessage typing /> : null}
            {retryButton && (
              <RetryButton
                socket={socket}
                onClick={() => setRetryButton(false)}
              />
            )}
          </div>
          <div id="lastAdvisorMessage" className="pt-10" />
        </ScrollArea>
        <ChatTaskUpdate taskUpdate={taskUpdate} />
        <ChatInput onSend={(text) => postMessage(text)} disabled={blockInput} />
      </div>
    </>
  );
};

export const RetryButton: React.FC<{
  socket?: WebSocket;
  onClick?: () => void;
}> = ({ socket, onClick }) => {
  const { t } = useTranslation();

  const retry = () => {
    onClick?.();
    socket?.send(JSON.stringify({ type: "retry" }));
  };

  return (
    <div>
      <div className="my-4 text-center text-red-600">
        {t("assistant.requestFailed")}
      </div>
      <div className="flex justify-center">
        <Button
          onClick={retry}
          leftIcon={<RefreshCcwIcon className="size-4" />}
          label={t("global.retry")}
        />
      </div>
    </div>
  );
};
