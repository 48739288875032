import { useTranslation } from "react-i18next";
import appStatusStore from "shared/store/appStatusStore";
import { CloseActivityPanelIcon } from "assets/svg/CloseActivityPanelIcon";
import colorsStore from "shared/store/colorsStore";

type ConstraintsPanelHeaderProps = {
  expanded: boolean;
  setExpanded: (e: boolean) => void;
  title: string;
  enableSubmitButton?: boolean;
  onSubmit?: () => void;
  onReset?: () => void;
};
export const HeaderPanel: React.FC<ConstraintsPanelHeaderProps> = ({
  expanded,
  setExpanded,
  title,
  enableSubmitButton,
  onSubmit,
  onReset,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const { t } = useTranslation();

  const iconSizeClass2 = `icon-size-16-16`;
  const setAppStatus = appStatusStore((state) => state.setAppStatus);
  return (
    <div
      className={"flex-2 relative  rounded-xl"}
      style={{ marginTop: 40, marginLeft: 20, marginRight: 20 }}
    >
      <div className="flex items-center justify-between">
        <div
          className={(expanded ? "lg:visible " : "lg:hidden ") + " font-bold"}
          style={{ fontSize: 18, color: colorsConfig.black }}
        >
          {t(title)}
        </div>
        <div className="cursor-pointer" onClick={() => setExpanded(!expanded)}>
          <div onClick={() => setAppStatus("idle")}>
            <CloseActivityPanelIcon
              className={iconSizeClass2}
              color={colorsConfig.black}
            />
          </div>
        </div>
      </div>
      {onSubmit && (
        <div className="mt-2 flex cursor-pointer justify-end">
          <div
            className="constraint-submit-button mr-[10px]"
            onClick={() => {
              onReset();
            }}
            style={{
              backgroundColor: colorsConfig.finecoBluePrimary,
              color: colorsConfig.white,
              opacity: enableSubmitButton ? 1 : 0.4,
            }}
          >
            {t("actions.reset")}
          </div>
          <div
            className="constraint-submit-button"
            onClick={() => {
              onSubmit();
            }}
            style={{
              backgroundColor: colorsConfig.finecoBluePrimary,
              color: colorsConfig.white,
              opacity: enableSubmitButton ? 1 : 0.4,
            }}
          >
            {t("actions.submit")}
          </div>
        </div>
      )}
    </div>
  );
};
