import { X } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import crmStyles from "./../../crmPage.module.css";
import { useTranslation } from "react-i18next";

// @ts-ignore
export const ParticipantsPopup = ({
  setSelectedParticipants,
  selectedParticipants,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={crmStyles.popup}>
      <div className={crmStyles.popupContent}>
        <div
          className={crmStyles.closeIcon}
          onClick={() => setSelectedParticipants(null)}
        >
          <X size={18} />
        </div>
        {selectedParticipants.map((participant: any, index: any) => (
          <div
            key={index}
            className={crmStyles.eventItem}
            onClick={() => {
              navigate(`/crm/clients/${participant.client_id}`);
              setSelectedParticipants(null);
            }}
          >
            <div className={crmStyles.eventTitle}>
              {participant.first_name} {participant.last_name}
            </div>
          </div>
        ))}
        <button
          className={crmStyles.closeButton}
          onClick={() => setSelectedParticipants(null)}
        >
          {t("crm.close")}
        </button>
      </div>
    </div>
  );
};
