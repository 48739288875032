import useRealtimePricesStore from "shared/store/realtimePricesStore";

export interface RealtimeStockPrice {
  previousClose: number;
  latestPrice: number;
  bidPrice: number;
  askPrice: number;
  trends: any[];
  subscribed_to_realtime: boolean;
}

export const useRealtimePrices = (symbol: string) => {
  const ticker = useRealtimePricesStore((state) => state.tickers[symbol]);

  if (!ticker) {
    return {
      previousClose: 0,
      latestPrice: 0,
      bidPrice: 0,
      askPrice: 0,
      trends: [],
      subscribed_to_realtime: false,
    } as RealtimeStockPrice;
  }

  const bidPrice = ticker.bidPrice || 0;
  const askPrice = ticker.askPrice || 0;

  const previousClose = ticker.previousClose || 0;
  const latestPrice = ticker.latestPrice || 0;

  // We will show an empty graph until we have enough data to show
  // instead of showing previousClose.
  // To proper fix this we could use IEX extended hours data
  const trends = ticker.trends || [];
  const subscribed_to_realtime = ticker.subscribed_to_realtime;

  return {
    previousClose,
    latestPrice,
    trends,
    subscribed_to_realtime,
    bidPrice,
    askPrice,
  } as RealtimeStockPrice;
};
