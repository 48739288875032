import { UserIcon } from "lucide-react";
import { auth } from "shared/utils/firebase";

export const UserImage: React.FC = () => {
  return (
    <>
      {auth.currentUser?.photoURL ? (
        <img
          src={auth.currentUser?.photoURL || ""}
          className="size-8 rounded-full"
          alt=""
        />
      ) : (
        <div className="size-8 grid shrink-0 place-items-center rounded-full border border-gray-600">
          <UserIcon className="size-5 text-gray-600" />
        </div>
      )}
    </>
  );
};
