import { useTranslation } from "react-i18next";
import "./../styles.css";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ConstraintConfigType } from "types/assistant/ConstraintsTypes";
import activityPanelStore from "shared/store/activityPanelStore";
import {
  convertToPercentage,
  isEmptyObject,
  parseConstraintsFormValues,
  parseValue,
} from "utils/misc";
import { GridItem } from "./GridItem";
import colorsStore from "shared/store/colorsStore";

type MacroContentProps = {
  configData: ConstraintConfigType;
  onDataChange: (data: Record<string, string | number>) => void;
  setHasChanged: (value: boolean) => void;
};

type FinancialPlanDefaults = {
  [key: string]: number;
};

export const MacroContent = forwardRef<
  { reset: () => void },
  MacroContentProps
>(({ configData, onDataChange, setHasChanged }, ref) => {
  //** store */
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const constraintsState = activityPanelStore(
    (state) => state.constraintsState || { data: {} },
  );
  const financialPlanStore = activityPanelStore(
    (state) => state.financialPlanStore,
  );

  //** get data */
  const getData = () => {
    if (constraintsState?.data?.macro) {
      const dataObject = Object.keys(configData).reduce(
        (acc, key) => {
          let value =
            constraintsState.data.macro[key] ??
            getDefaultValue(key, financialPlanStore.toString());

          let valueOutput = "";
          if (typeof value === "object") {
            valueOutput = convertToPercentage(value[financialPlanStore]);
          } else {
            if (typeof value === "number") {
              switch (configData[key]?.type) {
                case "percentage":
                  valueOutput = convertToPercentage(value);
                  break;
                case "float":
                  valueOutput = value.toFixed(2);
                  break;
                default:
                  valueOutput = value.toString();
              }
            }
          }

          acc[key] = valueOutput;
          return acc;
        },
        {} as Record<string, string | number>,
      );
      return dataObject;
    }
    // Using only defaults based on financial plan
    const defaultValues = Object.keys(configData).reduce(
      (acc, key) => {
        let value = getDefaultValue(key, financialPlanStore.toString());
        let valueOutput = "";

        if (typeof value === "number") {
          switch (configData[key]?.type) {
            case "percentage":
              valueOutput = convertToPercentage(value);
              break;
            case "float":
              valueOutput = value.toFixed(2);
              break;
            default:
              valueOutput = value.toString();
          }
        }

        acc[key] = valueOutput;
        return acc;
      },
      {} as Record<string, string | number>,
    );
    return defaultValues;
  };
  const getDefaultValue = (key: string, financialPlan: string): number => {
    const defaultValue = configData[key]?.default;

    if (defaultValue && typeof defaultValue === "object") {
      const planDefaults = defaultValue as FinancialPlanDefaults;
      return planDefaults[financialPlan] ?? 0;
    }

    return typeof defaultValue === "number" ? defaultValue : 0;
  };

  //** refs */
  const initialValuesRef = useRef<Record<string, string | number>>({});
  const hasChangedRef = useRef<boolean>(false);

  //** states */
  const [isUsingDefaults, setIsUsingDefaults] = useState<boolean>(true);
  const [hasUserChanged, setHasUserChanged] = useState<boolean>(false);
  const [formValues, setFormValues] = useState(getData());

  useImperativeHandle(ref, () => ({
    reset: () => {
      const defaultValues = getData();
      setFormValues(defaultValues);
      initialValuesRef.current = defaultValues;
      setHasUserChanged(false);
      hasChangedRef.current = false;
    },
  }));
  useEffect(() => {
    try {
      // Check if we're using defaults here
      setIsUsingDefaults(!constraintsState?.data?.macro);
      const updatedValues = getData();
      setFormValues(updatedValues);
      initialValuesRef.current = updatedValues;
    } catch (error) {
      console.error("Error updating macro values:", error);
    }
  }, [
    constraintsState?.data?.macro,
    constraintsState?.data?.risk?.financial_plan,
  ]);

  useEffect(() => {
    // if (isUsingDefaults) {
    const defaultValues = getData();
    setFormValues(defaultValues);
    // }
  }, [financialPlanStore, isUsingDefaults]);

  useEffect(() => {
    if (hasUserChanged) {
      if (!isEmptyObject(formValues)) {
        const hasChanged = Object.keys(configData).some(
          (key) => formValues[key] !== initialValuesRef.current[key],
        );

        if (hasChanged !== hasChangedRef.current) {
          setHasChanged(hasChanged);
          hasChangedRef.current = hasChanged;
        }

        if (hasChanged) {
          const convertedFormValues = parseConstraintsFormValues(
            formValues,
            configData,
          );
          onDataChange(convertedFormValues);
        }
      }
    }
  }, [formValues]);

  // @ts-ignore
  const handleInputChange = (e) => {
    setHasUserChanged(true);
    const value = parseValue(e.target, configData);
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.id]: value,
    }));
  };

  const renderGridItems = () => {
    const allKeys = Object.keys(configData);
    const gridItems = [];

    for (let i = 0; i < allKeys.length; i += 2) {
      if (i + 1 < allKeys.length) {
        gridItems.push(
          <GridItem
            key={allKeys[i]}
            cd={configData}
            cv1={allKeys[i]}
            cv2={allKeys[i + 1]}
            fv={formValues}
            ohi={handleInputChange}
          />,
        );
      }
    }

    return gridItems;
  };

  return (
    <div style={{ marginTop: 5, height: "auto" }}>
      <div
        className="grid-container"
        style={{
          color: colorsConfig.black,
          fontSize: 14,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
          gap: "1rem",
          width: "100%",
        }}
      >
        {renderGridItems()}
      </div>
    </div>
  );
});
