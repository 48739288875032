// TabNavigation.jsx
import React from "react";
import styles from "./tabNavigation.module.css";

// @ts-ignore
export const TabNavigation = ({ activeTab, onTabChange, clientData }) => {
  const tabsObject = [
    { label: "Deals", objectName: "deals" },
    { label: "Campaigns", objectName: "campaigns" },
    { label: "Schedule", objectName: "calendar_events" },
    { label: "Activity log", objectName: "activities" },
    { label: "Note", objectName: "notes" },
  ];
  const createTabs = () => {
    const tempTabs = [];
    for (let i = 0; i < tabsObject.length; i++) {
      // if (
      //   clientData[tabsObject[i].objectName] &&
      //   clientData[tabsObject[i].objectName].length > 0
      // ) {
      //   tempTabs.push({
      //     label: tabsObject[i].label,
      //     count: clientData[tabsObject[i].objectName].length,
      //   });
      // }
      tempTabs.push({
        // @ts-ignore
        label: tabsObject[i].label,
        count:
          // @ts-ignore
          clientData[tabsObject[i].objectName] &&
            // @ts-ignore
            clientData[tabsObject[i].objectName].length > 0
            ? // @ts-ignore
            clientData[tabsObject[i].objectName].length
            : "0",
      });
    }
    return tempTabs;
  };
  const tabs = createTabs();

  return (
    <div className={styles.tabs}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`${styles.tab} ${tab.label === activeTab ? styles.active : ""
            }`}
          onClick={() => onTabChange(tab.label)}
        >
          {tab.label}
          {tab.count && <span className={styles.badge}>{tab.count}</span>}
        </button>
      ))}
    </div>
  );
};
