import React, { useEffect, useState } from "react";
import styles from "./goalsViewWidget.module.css";
import crmStore from "shared/store/crmStore";
import { calculateProgress, formatCurrencyCrm } from "utils/misc";
import crmStyles from "./../../crmPage.module.css";
import { useTranslation } from "react-i18next";

type goalLocalType = {
  id: number;
  name: string;
  amount: string;
  progress: number;
};
export const GoalsViewWidget = () => {
  const { t } = useTranslation();
  //** store */
  const { crmGoals, fetchGoals } = crmStore();

  useEffect(() => {
    fetchGoals();
  }, []);

  useEffect(() => {
    let goalData: goalLocalType[] = [];
    for (let i = 0; i < crmGoals.length; i++) {
      const goalLocalDataObject = {
        id: i,
        name: crmGoals[i].name,
        amount: formatCurrencyCrm(crmGoals[i].current, crmGoals[i].unit),
        progress: calculateProgress(crmGoals[i].current, crmGoals[i].target),
      };
      goalData.push(goalLocalDataObject);
    }
    setGoals(goalData);
  }, [crmGoals]);

  const [goals, setGoals] = useState<any>([]);
  // const contestData = [
  //   {
  //     id: 1,
  //     name: "Contest Qualità III - 2025",
  //     amount: "200,500",
  //     progress: 85,
  //   },
  //   {
  //     id: 2,
  //     name: "Contest I - 2025",
  //     amount: "300,500",
  //     progress: 25,
  //   },
  //   {
  //     id: 3,
  //     name: "Contest II - 2025",
  //     amount: "1,200,500",
  //     progress: 65,
  //   },
  // ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t("crm.monitorContext")}</h2>
      </div>

      {goals.length === 0 && (
        <div className={crmStyles.noContent}>{t("crm.noContentAvailable")}</div>
      )}
      {goals.length > 0 && (
        <div className={styles.contestList}>
          {goals.map((contest: any) => (
            <div key={contest.id} className={styles.contestItem}>
              <div className={styles.contestInfo}>
                <div className={styles.nameSection}>
                  <span className={styles.contestName}>{contest.name}</span>
                  <span className={styles.contestAmount}>{contest.amount}</span>
                </div>
              </div>
              <div className={styles.progressContainer}>
                <div
                  className={styles.progressBar}
                  style={{ width: `${contest.progress}%` }}
                />
                <div
                  className={styles.remainingBar}
                  style={{ width: `${100 - contest.progress}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
