import React from "react";

export const ExpandAttachmentIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => (
  <svg
    {...props}
    ref={ref}
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1172_10240)">
      <path
        d="M22.989 14.6271C23.3996 15.1346 23.3996 15.8654 22.989 16.373C21.7109 17.9529 18.4997 21.1246 15.0007 21.1246C11.5016 21.1246 8.28914 17.9529 7.01105 16.3729C6.60048 15.8654 6.60048 15.1346 7.01105 14.627C8.28914 13.0471 11.5016 9.8754 15.0006 9.8754C18.4997 9.8754 21.7109 13.0471 22.989 14.6271Z"
        stroke="#00549F"
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0008 18.3123C16.554 18.3123 17.8131 17.0532 17.8131 15.5C17.8131 13.9468 16.554 12.6877 15.0008 12.6877C13.4476 12.6877 12.1885 13.9468 12.1885 15.5C12.1885 17.0532 13.4476 18.3123 15.0008 18.3123Z"
        stroke="#00549F"
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1172_10240">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(6 6.5)"
        />
      </clipPath>
    </defs>
  </svg>
));

ExpandAttachmentIcon.displayName = "ExpandAttachmentIcon";
