import React, { useEffect } from "react";
import { CustomDataArtifactRecord } from "types/assistant/AssistantTypes";
import { EyeIcon, UserIcon, CornerDownLeft, PlusIcon } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "shared/components/ui/dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "shared/components/ui/tabs";
import { Button } from "shared/components/ui/button";
import { Input } from "shared/components/ui/input";
import { Textarea } from "shared/components/ui/textarea";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./finecoCRMArtifact.module.css";
import colorsStore from "shared/store/colorsStore";

type FinecoCRMArtifactProps = {
  data: CustomDataArtifactRecord;
  onLoaded?: () => void;
  isConnectionOpen: boolean;
  onSend: (text: string) => void;
  disabled: boolean;
};

const PersonalInformation: React.FC<{ contact: CustomDataArtifactRecord }> = ({
  contact,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-2 gap-6">
      <div
        className="rounded-lg p-4"
        style={{
          backgroundColor: colorsConfig.grayLight,
          color: colorsConfig.black,
        }}
      >
        <h2
          className="mb-4 text-xl font-semibold"
          style={{ color: colorsConfig.black }}
        >
          {t("crmClients.basicInformation")}
        </h2>
        <div className="space-y-2">
          <p>
            <strong style={{ color: colorsConfig.black }}>
              {t("crmClients.givenName")}:
            </strong>{" "}
            {contact.given_name}
          </p>
          <p>
            <strong style={{ color: colorsConfig.black }}>
              {t("crmClients.familyName")}:
            </strong>{" "}
            {contact.family_name}
          </p>
          <p>
            <strong style={{ color: colorsConfig.black }}>
              {t("crmClients.email")}:
            </strong>{" "}
            <a href={`mailto:${contact.email}`}>{contact.email}</a>
          </p>
          <p>
            <strong style={{ color: colorsConfig.black }}>
              {t("crmClients.dateOfBirth")}:
            </strong>{" "}
            {contact.date_of_birth}
          </p>
          <p>
            <strong style={{ color: colorsConfig.black }}>
              {t("crmClients.country")}:
            </strong>{" "}
            {contact.country}
          </p>
          <p>
            <strong style={{ color: colorsConfig.black }}>
              {t("crmClients.city")}:
            </strong>{" "}
            {contact.city}
          </p>
          <p>
            <strong style={{ color: colorsConfig.black }}>
              {t("crmClients.signupDate")}:
            </strong>{" "}
            {contact.signup_date}
          </p>
          <p>
            <strong style={{ color: colorsConfig.black }}>
              {t("crmClients.lastActiveDate")}:
            </strong>{" "}
            {contact.last_active_date}
          </p>
        </div>
      </div>

      <div
        className="rounded-lg p-4"
        style={{
          backgroundColor: colorsConfig.grayLight,
          color: colorsConfig.black,
        }}
      >
        <h2
          className="mb-4 text-xl font-semibold"
          style={{ color: colorsConfig.black }}
        >
          {t("crmClients.employmentInformation")}
        </h2>
        <div className="space-y-2">
          <p>
            <strong style={{ color: colorsConfig.black }}>
              {t("crmClients.employmentStatus")}:
            </strong>{" "}
            {contact.employment_status}
          </p>
          <p>
            <strong style={{ color: colorsConfig.black }}>
              {t("crmClients.employmentPosition")}:
            </strong>{" "}
            {contact.employment_position}
          </p>
          <p>
            <strong style={{ color: colorsConfig.black }}>
              {t("crmClients.employerName")}:
            </strong>{" "}
            {contact.employer_name}
          </p>
          <p>
            <strong style={{ color: colorsConfig.black }}>
              {t("crmClients.liquidNetWorthMax")}:
            </strong>{" "}
            {contact.liquid_net_worth_max}
          </p>
        </div>
      </div>
    </div>
  );
};

const AddNoteDialog: React.FC<{
  onSave: (note: {
    content: string;
    note_type: string;
    tags: string[];
  }) => void;
}> = ({ onSave }) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const { t } = useTranslation();
  const [content, setContent] = useState("");
  const [noteType, setNoteType] = useState("");
  const [tags, setTags] = useState("");
  const [open, setOpen] = useState(false);

  const handleSubmit = () => {
    onSave({
      content,
      note_type: noteType,
      tags: tags.split(",").map((tag) => tag.trim()),
    });
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="mr-2 text-blue-400 hover:text-blue-300"
        >
          <PlusIcon size={16} className="mr-1 " />
          {t("crmClients.addNote")}
        </Button>
      </DialogTrigger>
      <DialogContent
        className="flex h-[80vh] flex-col sm:max-w-[600px]"
        style={{
          color: colorsConfig.black,
          backgroundColor: colorsConfig.grayLight,
        }}
      >
        <h2
          className="mb-4 text-lg font-semibold"
          style={{ color: colorsConfig.black }}
        >
          {t("crmClients.addNewNote")}
        </h2>
        <div
          className="flex flex-grow flex-col space-y-4"
          style={{ color: colorsConfig.black }}
        >
          <div className="flex flex-grow flex-col">
            <label
              htmlFor="content"
              className="mb-1 block text-sm font-medium"
              style={{ color: colorsConfig.black }}
            >
              {t("crmClients.content")}
            </label>
            <Textarea
              id="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="w-full flex-grow"
            />
          </div>
          <div>
            <label
              htmlFor="noteType"
              className="mb-1 block text-sm font-medium"
              style={{ color: colorsConfig.black }}
            >
              {t("crmClients.noteType")}
            </label>
            <Input
              id="noteType"
              value={noteType}
              onChange={(e) => setNoteType(e.target.value)}
              className="w-full"
            />
          </div>
          <div>
            <label
              htmlFor="tags"
              className="mb-1 block text-sm font-medium"
              style={{ color: colorsConfig.black }}
            >
              {t("crmClients.tags")}
            </label>
            <Input
              id="tags"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              className="w-full"
            />
          </div>
          <Button onClick={handleSubmit} className="mt-4 w-full">
            {t("crmClients.saveNote")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const Notes: React.FC<{
  contact: CustomDataArtifactRecord;
  onSend: (text: string) => void;
}> = ({ contact, onSend }) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  return (
    <div className="flex h-full flex-col">
      <div className="flex-grow overflow-auto">
        {contact.crm_notes && contact.crm_notes.length > 0 ? (
          <div className="space-y-4 px-4">
            {contact.crm_notes.map((note, index) => (
              <div
                key={index}
                className="rounded-lg p-4"
                style={{
                  backgroundColor: colorsConfig.grayLight,
                  color: colorsConfig.black,
                }}
              >
                <div className="mb-2 flex items-center justify-between">
                  <span
                    className="text-sm "
                    style={{ color: colorsConfig.black }}
                  >
                    {new Date(note.created_at).toLocaleDateString()}
                  </span>
                  <span
                    className="text-sm font-semibold"
                    style={{ color: colorsConfig.black }}
                  >
                    {note.note_type}
                  </span>
                </div>
                <p className="mb-3 " style={{ color: colorsConfig.black }}>
                  {note.content}
                </p>
                <div className="flex flex-wrap gap-2">
                  {note.tags.map((tag, tagIndex) => (
                    <span
                      key={tagIndex}
                      className="rounded-full bg-gray-700 px-2 py-1 text-xs text-gray-300"
                      style={{
                        backgroundColor: colorsConfig.finecoBlueLight,
                        color: colorsConfig.black,
                      }}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="px-4 text-gray-400">No notes available.</p>
        )}
      </div>
    </div>
  );
};

const ContactViewDialog: React.FC<{
  contact: CustomDataArtifactRecord;
  onSend: (text: string) => void;
}> = ({ contact, onSend }) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const { t } = useTranslation();
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="mr-2 text-blue-400 hover:text-blue-300 "
        >
          <EyeIcon size={16} className="mr-1 " />
          {t("crmClients.view")}
        </Button>
      </DialogTrigger>
      <DialogContent
        className={styles.crmModal}
        // @ts-ignore
        xColor={colorsConfig.black}
      >
        <Tabs defaultValue="personal-info" className="flex h-full flex-col">
          <TabsList className={styles.tabList}>
            <TabsTrigger value="personal-info">
              {t("crmClients.personalInformation")}
            </TabsTrigger>
            <TabsTrigger value="notes">{t("crmClients.notes")}</TabsTrigger>
          </TabsList>
          <div className="flex-grow overflow-auto">
            <TabsContent
              value="personal-info"
              className="h-[600px] overflow-auto"
            >
              <PersonalInformation contact={contact} />
            </TabsContent>
            <TabsContent value="notes" className="h-[600px] overflow-auto">
              <Notes contact={contact} onSend={onSend} />
            </TabsContent>
          </div>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};

const ContactHeader: React.FC<{
  contact: CustomDataArtifactRecord;
  isConnectionOpen: boolean;
  onSend: (text: string) => void;
  disabled: boolean;
}> = ({ contact, isConnectionOpen, onSend, disabled }) => {
  const { t } = useTranslation();
  const handleProceedClick = () => {
    const prompt = `Proceed: ${contact.next_action}`;
    onSend(prompt);
  };

  const handleSaveNote = (note: {
    content: string;
    note_type: string;
    tags: string[];
  }) => {
    const message = `Save CRM Note:
Content: ${note.content}
Note Type: ${note.note_type}
Tags: ${note.tags.join(", ")}
Contact user ID: ${contact.user_id}`;
    onSend(message);
  };

  const isClickable = isConnectionOpen && !disabled && contact.next_action;
  const iconStyle = {
    marginRight: "5px",
    cursor: isClickable ? "pointer" : "default",
    opacity: isClickable ? 1 : 0.5,
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <UserIcon style={{ marginRight: "5px" }} />
        {contact.given_name} {contact.family_name}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "4px",
        }}
      >
        <ContactViewDialog contact={contact} onSend={onSend} />
        <AddNoteDialog onSave={handleSaveNote} />
        {contact.next_action && (
          <>
            <CornerDownLeft
              style={iconStyle}
              onClick={isClickable ? handleProceedClick : undefined}
            />
            <span style={{ opacity: isClickable ? 1 : 0.5 }}>
              {t("crmClients.proceed")}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

const ContactBody: React.FC<{ contact: CustomDataArtifactRecord }> = ({
  contact,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  return (
    <div>
      <div>
        <strong style={{ color: colorsConfig.black }}>Country:</strong>
        {contact.country}
      </div>
      <div>
        <strong style={{ color: colorsConfig.black }}>Last Active Date:</strong>{" "}
        {contact.last_active_date}
      </div>
      <div>
        <strong style={{ color: colorsConfig.black }}>Email:</strong>{" "}
        <a href={`mailto:${contact.email}`}>{contact.email}</a>
      </div>
    </div>
  );
};

const ContactItem: React.FC<{
  contact: CustomDataArtifactRecord;
  isConnectionOpen: boolean;
  onSend: (text: string) => void;
  disabled: boolean;
}> = ({ contact, isConnectionOpen, onSend, disabled }) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  return (
    <div
      key={contact.id}
      className="rounded-lg p-4"
      style={{ backgroundColor: colorsConfig.grayLight }}
    >
      <ContactHeader
        contact={contact}
        isConnectionOpen={isConnectionOpen}
        onSend={onSend}
        disabled={disabled}
      />
      <ContactBody contact={contact} />
    </div>
  );
};

const ContactList: React.FC<{
  contacts: CustomDataArtifactRecord[];
  isConnectionOpen: boolean;
  onSend: (text: string) => void;
  disabled: boolean;
}> = ({ contacts, isConnectionOpen, onSend, disabled }) => {
  if (!contacts || contacts.length === 0) {
    return null;
  }

  return (
    <div className="grid grid-cols-2 gap-4">
      {contacts.map((contact) => {
        return (
          <ContactItem
            key={Math.random()}
            contact={contact}
            isConnectionOpen={isConnectionOpen}
            onSend={onSend}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export const FinecoCRMArtifact: React.FC<FinecoCRMArtifactProps> = ({
  data,
  onLoaded,
  isConnectionOpen,
  onSend,
  disabled,
}) => {
  useEffect(() => onLoaded?.(), []);

  if (!data || !data.contacts) {
    return null;
  }

  return (
    <div className="w-full">
      <ContactList
        contacts={data.contacts}
        isConnectionOpen={isConnectionOpen}
        onSend={onSend}
        disabled={disabled}
      />
    </div>
  );
};

export default FinecoCRMArtifact;
