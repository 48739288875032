import React from "react";
import { useTranslation } from "react-i18next";
import { Panel } from "shared/components/hoc/Panels";
import { PortfolioList } from "./PortfolioList";

interface Props {
  portfolioId?: string;
  onPortfolioIdChange(id?: string): void;
}

export const ProposalPortfolioSelector: React.FC<Props> = ({
  portfolioId,
  onPortfolioIdChange,
}) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <div className="mb-8">
        <div className="flex flex-row items-center justify-between">
          <div className="text-xl font-medium">{t("global.portfolio")}</div>
        </div>
        <h3 className="text-md mb-3 text-gray-400">
          {t("proposals.pickOnePortfolio")}
        </h3>
      </div>
      <PortfolioList
        portfolioId={portfolioId}
        onPortfolioIdChange={onPortfolioIdChange}
        //onCreateNewPortfolioHandler={switchToCreateNewPortfolio}
      />
    </Panel>
  );
};
