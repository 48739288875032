import Decimal from "decimal.js";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { useAccount } from "shared/hooks/useAccount";
import { ClientsService } from "shared/services/clients/ClientsService";
import useAccountStore from "shared/store/accountStore";
import useClientDetailsStore from "shared/store/clientDetailsStore";
import useClientsStore from "shared/store/clientsStore";
import { CurrencyFormat } from "shared/utils/currency";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  clientId?: string;
  portfolioId: string;
};
export const PortfolioDepositModal: React.FC<Props> = ({
  open,
  setOpen,
  clientId,
  portfolioId,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>();
  const { account } = useAccount();
  const client = useClientsStore((state) =>
    state.clients.find((c) => String(c.user_id) === String(clientId)),
  );
  const { fetchClientById } = useClientsStore();
  const { fetchAccount } = useAccountStore();
  const { fetchClientPortfolio, fetchRecentActivities } =
    useClientDetailsStore();

  const clientTradingAccount = client?.trading;
  const tradingAccount = clientId
    ? clientTradingAccount
    : account?.client.trading;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (clientId) {
      fetchClientById(clientId);
    } else {
      fetchAccount();
    }
  }, [clientId]);

  const depositSuccess = () => {
    if (clientId) {
      fetchClientPortfolio(clientId, portfolioId);
      fetchRecentActivities(clientId);
      fetchClientById(clientId);
    }
    setLoading(false);
    setOpen(false);
    toast.success(t("faPortfolio.depositSuccess"));
  };

  const onDeposit = () => {
    if (clientId && value) {
      setLoading(true);
      const amountAsString = new Decimal(value).toFixed();
      ClientsService.depositToPortfolio(
        clientId,
        portfolioId,
        amountAsString,
      ).then(depositSuccess);
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Labels.H3>{t("clientCash.deposit")}</Labels.H3>
      {tradingAccount?.buying_power && (
        <div className="text-base text-sb-gray-600">
          {t("trade.buyingPower", {
            value: CurrencyFormat.format(tradingAccount?.buying_power),
          })}
        </div>
      )}
      <div className="mt-4">
        <CurrencyInput
          prefix="$"
          className="w-full min-w-0 text-3xl caret-sb-green-600 outline-none"
          placeholder="0"
          decimalsLimit={2}
          decimalScale={2}
          onValueChange={(value) => setValue(value)}
        />
      </div>
      <div className="mt-4 flex items-center justify-center gap-4">
        <Button
          loading={loading}
          label="clientCash.deposit"
          disabled={
            !value ||
            parseFloat(tradingAccount?.buying_power || "0") < parseFloat(value)
          }
          onClick={onDeposit}
        />
        <Button
          disabled={loading}
          btnStyle="blank"
          label="global.cancel"
          onClick={() => setOpen(false)}
        />
      </div>
    </Modal>
  );
};
