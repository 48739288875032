import Papa from "papaparse";
import { ColumnDef } from "@tanstack/react-table";
import { DownloadIcon, ExpandIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DataTable, SortableHeader } from "shared/components/ui/data-table";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "shared/components/ui/dialog";
import { AssistantService } from "shared/services/AssistantService";
import { AssistantAttachment } from "types/assistant/AssistantTypes";
import { ScrollArea, ScrollBar } from "shared/components/ui/scroll-area";
import { DownloadAttachmentIcon } from "assets/svg/DownloadAttachmentIcon";
import { ExpandAttachmentIcon } from "assets/svg/ExpandAttachmentIcon";
import colorsStore from "shared/store/colorsStore";

type Props = {
  attachment: AssistantAttachment;
};

export const TableAttachment: React.FC<Props> = ({ attachment }) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const { t } = useTranslation();
  const params = useParams();
  const threadId = params.threadId;

  const [tableData, setTableData] = useState<{
    columnDefs: ColumnDef<string[]>[];
    rows: string[][];
  }>({ columnDefs: [], rows: [] });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    AssistantService.downloadAttachment(
      threadId,
      attachment.attachment_id,
    ).then(async (response) => {
      const blob = response.data;
      Papa.parse(blob, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete: (results) => {
          const columnDefs: ColumnDef<string[]>[] = [];
          for (let columnIdx in results.meta.fields) {
            const columnName = results.meta.fields[columnIdx];
            columnDefs.push({
              id: columnIdx,
              accessorKey: columnName,
              accessorFn: (row) => row[columnIdx],
              header: ({ column }) => {
                return <SortableHeader column={column} text={columnName} />;
              },
            });
          }
          const rowsArray = results.data.map((row) => {
            return Object.values(row);
          });
          setTableData({
            columnDefs: columnDefs,
            rows: rowsArray,
          });
        },
      });

      setLoading(false);
    });
  }, []);

  const download = async () => {
    const fileName = attachment.attachment_name;
    const attachmentId = attachment.attachment_id;
    const response = await AssistantService.downloadAttachment(
      threadId,
      attachmentId,
    );

    const link = document.createElement("a");
    link.href = URL.createObjectURL(response.data);
    link.download = fileName;
    link.click();
  };

  if (!tableData.columnDefs) {
    return null;
  }

  return (
    <>
      <div className="w-fit overflow-x-auto">
        <div className="relative h-full w-full min-w-0  rounded-xl">
          <div className="my-2 flex flex-wrap items-center justify-between gap-4">
            <div className="flex justify-end">
              {/* <DownloadIcon
                className="size-4 cursor-pointer duration-150 hover:opacity-60"
                style={{ color: colorsConfig.finecoBlack }}
                onClick={download}
              /> */}
              <div
                style={{ width: 30, height: 31, cursor: "pointer" }}
                onClick={download}
                className="hover:opacity-60"
              >
                <DownloadAttachmentIcon />
              </div>
              <Dialog>
                <DialogTrigger asChild>
                  <ExpandAttachmentIcon className="ml-[4px] cursor-pointer hover:opacity-60" />
                </DialogTrigger>
                <DialogContent
                  className="max-h-screen max-w-[90%] !bg-transparent dark:!bg-transparent" // Add !bg-transparent
                  style={{
                    backgroundColor: colorsConfig.white,
                  }}
                >
                  <ScrollArea className="max-h-screen py-4 pb-12">
                    <DataTable
                      key={"table_" + attachment.attachment_id + "_dialog"}
                      loading={loading}
                      columns={tableData.columnDefs}
                      data={tableData.rows}
                    />
                    <ScrollBar orientation="horizontal" />
                  </ScrollArea>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
      <div
        id={attachment.attachment_id.toString()}
        className="csv-attachment-scroll-margin"
      />
    </>
  );
};
