import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "shared/components/ui/dialog";
import { DownloadIcon, ExpandIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProAssistantAttachment } from "shared/models/pro_assistant/ProAssistantModel";
import { ProAssistantService } from "shared/services/pro_assistant/ProAssistantService";

type Props = {
  attachment: ProAssistantAttachment;
};

export const ImageAttachment: React.FC<Props> = ({ attachment }) => {
  const params = useParams();
  const threadId = params.threadId;
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (threadId) {
      ProAssistantService.getAttachmentUrl(
        threadId,
        attachment.attachment_id,
      ).then(setUrl);
    }
  }, [attachment.attachment_id]);

  const download = async () => {
    const fileName = attachment.attachment_name;
    const attachmentId = attachment.attachment_id;
    if (threadId) {
      const response = await ProAssistantService.downloadAttachment(
        threadId,
        attachmentId,
      );

      const link = document.createElement("a");
      link.href = URL.createObjectURL(response.data);
      link.download = fileName;
      link.click();
    }
  };

  if (!url) {
    return null;
  }

  return (
    <div className="group relative w-fit overflow-hidden">
      <div className="absolute right-4 top-4 z-20">
        <div className="flex items-center gap-2">
          <div
            className="grid cursor-pointer place-items-center rounded-lg bg-gray-200 p-2 opacity-0 duration-150 group-hover:opacity-100 dark:bg-gray-800"
            onClick={download}
          >
            <DownloadIcon className="size-4" />
          </div>
          <Dialog>
            <DialogTrigger asChild>
              <div className="grid cursor-pointer place-items-center rounded-lg bg-gray-200 p-2 opacity-0 duration-150 group-hover:opacity-100 dark:bg-gray-800">
                <ExpandIcon className="size-4" />
              </div>
            </DialogTrigger>
            <DialogContent className="h-fit max-h-[90%] w-fit max-w-[90%]">
              <div className="mt-4">
                <img src={url} className="w-fit" />
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <img className="w-full max-w-[400px]" src={url} />
    </div>
  );
};
