type Props = {
  className?: string;
};

export const FinecoAgentIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="6" fill="#E5F1FB" />
      <g clipPath="url(#clip0_110_7691)">
        <path
          d="M24.5 25.775L19.9625 23.375H11.9006C11.2642 23.3744 10.654 23.1214 10.204 22.6714C9.75392 22.2215 9.50076 21.6114 9.5 20.975V10.775C9.50076 10.1387 9.75384 9.52871 10.2038 9.07879C10.6537 8.62886 11.2637 8.37576 11.9 8.375H22.1C22.7363 8.37576 23.3463 8.62886 23.7962 9.07879C24.2462 9.52871 24.4992 10.1387 24.5 10.775V25.775ZM13.5385 11.2596V20.4904H15.3171V16.5737H20.1829V15.189H15.3171V12.6442H20.4615V11.2596H13.5385Z"
          fill="#00549F"
        />
      </g>
      <defs>
        <clipPath id="clip0_110_7691">
          <rect
            width="15"
            height="17.4"
            fill="white"
            transform="translate(9.5 8.375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
