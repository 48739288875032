import PortfolioConfigData from "config/portfolio-config.json";
import React from "react";
import EfficientFrontierChart from "../../EfficientFrontierChart";
import styles from "./../../../finecoPortfolioArtifact.module.css";

type SectionChildrenProps = {
  reportData: any;
  ef_data;
  artifact;
};
const EfficientFrontierContainer: React.FC<SectionChildrenProps> = ({
  reportData,
  ef_data,
  artifact,
}) => {
  if (!reportData) {
    return <></>;
  }

  const selectedPlan = ef_data[artifact.data?.financial_plan - 1];
  const portfolio = {
    risk: artifact.data?.var * 100,
    return: artifact.data?.return * 100,
  };

  return (
    <div style={{ marginLeft: 10, marginBottom: 30 }}>
      <h3 className={styles.subTitle}>
        {
          PortfolioConfigData.analisi_rischio_rendimento_atteso.data
            .efficient_frontier_data.label
        }
      </h3>
      <div style={{ marginTop: 20 }}></div>
      <EfficientFrontierChart
        data={ef_data}
        selectedPlan={selectedPlan}
        portfolio={portfolio}
      />
    </div>
  );
};
export default React.memo(
  EfficientFrontierContainer,
  (prevProps: SectionChildrenProps, nextProps: SectionChildrenProps) => {
    // Custom comparison function
    return prevProps.reportData === nextProps.reportData;
  },
);
