import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Scatter,
  Legend,
} from "recharts";
import styles from "./../finecoPortfolioArtifact.module.css";
import colorsStore from "shared/store/colorsStore";

type DataPoint = {
  risk: number;
  return: number;
};

type Props = {
  data: DataPoint[];
  selectedPlan: DataPoint;
  portfolio: DataPoint;
};

const EfficientFrontierChart: React.FC<Props> = ({
  data,
  selectedPlan,
  portfolio,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const sortedData = [...data].sort((a, b) => a.risk - b.risk);

  const axisStyle = {
    fontSize: "12px",
    fontFamily: "inherit",
    fontWeight: 500,
    fill: "#4b5563",
  };

  const labelStyle = {
    fontSize: "14px",
    fontFamily: "inherit",
    fontWeight: 500,
    fill: colorsConfig.black,
  };

  return (
    <ResponsiveContainer width="100%" height={500}>
      <ComposedChart
        margin={{
          top: 20,
          right: 20,
          bottom: 60,
          left: 40,
        }}
      >
        <Legend
          verticalAlign="top"
          height={36}
          wrapperStyle={{
            paddingTop: "-20px",
            marginTop: "-10px",
            fontSize: "12px",
            fontFamily: "inherit",
          }}
          iconSize={10}
          iconType="circle"
          formatter={(value) => (
            <span style={{ marginRight: "20px" }}>{value}</span>
          )}
        />
        <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
        <XAxis
          type="number"
          dataKey="risk"
          name="VAR ATTESO"
          domain={[0, 30]}
          ticks={[0, 5, 10, 15, 20, 25, 30]}
          tickFormatter={(value) => `${value}%`}
          label={{
            value: "% Perdita Potenziale massima ad un anno 95% di confidenza",
            position: "insideBottom",
            dy: 25,
            ...labelStyle,
          }}
          tick={axisStyle}
          axisLine={{ stroke: "#4b5563" }}
        />
        <YAxis
          type="number"
          dataKey="return"
          name="% Rendimento medio annuo atteso"
          domain={[0, 10]}
          interval={0}
          ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          tickFormatter={(value) => `${value}%`}
          label={{
            value: "% Rendimento medio annuo atteso",
            angle: -90,
            position: "center",
            dx: -60,
            ...labelStyle,
          }}
          tick={axisStyle}
          axisLine={{ stroke: "#4b5563" }}
        />
        <Tooltip
          cursor={false}
          content={({ active, payload }) => {
            if (active && payload?.length) {
              return (
                <div className={styles.tooltipContainer}>
                  <div className={styles.tooltipRow}>
                    VaR: {payload[0].payload.risk.toFixed(2)}%
                  </div>
                  <div className={styles.tooltipRow}>
                    Rendimento: {payload[0].payload.return.toFixed(2)}%
                  </div>
                </div>
              );
            }
            return null;
          }}
        />

        <Line
          type="monotone"
          data={sortedData}
          dataKey="return"
          name="Frontiera Efficiente"
          stroke="#4b5563"
          strokeWidth={2}
          dot={{
            fill: "white",
            stroke: "#4b5563",
            r: 6,
          }}
          connectNulls
        />
        <Scatter
          name="Piano finanziario selezionato"
          data={[selectedPlan]}
          fill="#EB7100"
          r={6}
        />
        <Scatter
          name="Portafoglio ottimizzato"
          data={[portfolio]}
          fill="green"
          r={6}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default EfficientFrontierChart;
