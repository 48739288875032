import { useTranslation } from "react-i18next";
import { AvatarImage } from "shared/components/common/AvatarImage";
import { Labels } from "shared/components/hoc/Labels";
import { ArrowUp } from "shared/icons/ArrowUp";
import { pctFormat } from "shared/utils/currency";
import { StrategySubscriptionsModel } from "shared/models/subscriptions/StrategySubscriptionsModel";
import { CurrencyFormat } from "shared/utils/currency";
import Decimal from "decimal.js";
import { Client } from "shared/models/client/ClientModel";
import { Link } from "react-router-dom";
type Props = {
  item: StrategySubscriptionsModel;
  clients: Client[];
};

export const StrategySubscriptions: React.FC<Props> = ({ item, clients }) => {
  const { t } = useTranslation();

  const { strategy, subscriptions: unsortedSubscriptions } = item;

  const subscriptions = [...unsortedSubscriptions].sort((a, b) =>
    new Decimal(b.equity).minus(new Decimal(a.equity)).toNumber(),
  );

  const totalEquity = subscriptions.reduce(
    (acc, subscription) => acc.add(new Decimal(subscription.equity)),
    new Decimal(0),
  );

  return (
    <div className="flex flex-col gap-8 rounded-3xl border border-sb-gray-200 p-4">
      <div className="flex w-full justify-between gap-4">
        <Link to={`/portfolios/${strategy.id}`}>
          <div className="cursor-pointer rounded-3xl p-2 duration-150 hover:bg-sb-gray-100">
            <div className="flex items-center gap-4">
              <AvatarImage
                imageSrc={strategy?.cover?.uri}
                width={40}
                height={40}
                className="shrink-0"
              />
              <div>
                <Labels.H3>{strategy.title}</Labels.H3>
              </div>
            </div>
            <div className="mt-4">{strategy.description}</div>
          </div>
        </Link>
        <div>
          <div className="flex items-center gap-2">
            <div className="text-lg text-sb-gray-500">
              {t("clientPortfolio.invested")}
            </div>
            <div className="text-xl font-semibold">
              {CurrencyFormat.format(totalEquity.toNumber())}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {subscriptions.map((subscription, idx) => {
          const client = clients.find(
            (client) =>
              client.user_id.toString() === subscription.user_id.toString(),
          );
          return (
            <Link
              to={`${client.user_id ? "/clients/" + client.user_id : ""}/portfolios/${strategy.id}`}
            >
              <div
                key={idx}
                className="cursor-pointer rounded-3xl border p-4 duration-150 hover:bg-sb-gray-100"
              >
                <div className="flex items-center gap-4">
                  <Labels.H3>
                    {
                      clients.find(
                        (client) =>
                          client.user_id.toString() ===
                          subscription.user_id.toString(),
                      )?.summary.client_name
                    }
                  </Labels.H3>
                  <div className="flex gap-2">
                    <Labels.H3 className="text-xl font-semibold">
                      {CurrencyFormat.format(subscription.equity)}
                    </Labels.H3>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2">
                    <div className="-mt-2 flex items-center gap-1 text-sb-gray-500">
                      <div
                        className={
                          "flex items-center gap-1 " +
                          (parseFloat(subscription.total_return) >= 0
                            ? "text-sb-green-700"
                            : "text-sb-red-700")
                        }
                      >
                        <ArrowUp
                          className={
                            "aspect-square w-4 " +
                            (parseFloat(subscription.total_return) < 0
                              ? " rotate-180"
                              : "")
                          }
                        />
                        <div className="font-medium">
                          {CurrencyFormat.format(subscription.total_return)} (
                          {pctFormat.format(subscription.total_return_pct)})
                        </div>
                      </div>
                      {t("positions.totalReturn")}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
