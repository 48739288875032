type Props = {
  className?: string;
};

export const MaximizeTableIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_23576_6750)">
        <path
          d="M7.453 17.2969H2.10925C1.33262 17.2969 0.703003 16.6673 0.703003 15.8906V10.5469C0.703003 9.77024 1.33262 9.14062 2.10925 9.14062H7.453C8.22964 9.14062 8.85925 9.77024 8.85925 10.5469V15.8906C8.85925 16.6673 8.22964 17.2969 7.453 17.2969Z"
          stroke="#9B9FA7"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.453 17.2969H2.10925C1.33262 17.2969 0.703003 16.6673 0.703003 15.8906V10.5469C0.703003 9.77024 1.33262 9.14062 2.10925 9.14062H7.453C8.22964 9.14062 8.85925 9.77024 8.85925 10.5469V15.8906C8.85925 16.6673 8.22964 17.2969 7.453 17.2969Z"
          stroke="black"
          strokeOpacity="0.2"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.23425 6.32812V2.8125C3.23425 1.64753 4.17866 0.703125 5.34363 0.703125H15.1874C16.3524 0.703125 17.2968 1.64753 17.2968 2.8125V12.6562C17.2968 13.8212 16.3524 14.7656 15.1874 14.7656H11.6718"
          stroke="#9B9FA7"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.23425 6.32812V2.8125C3.23425 1.64753 4.17866 0.703125 5.34363 0.703125H15.1874C16.3524 0.703125 17.2968 1.64753 17.2968 2.8125V12.6562C17.2968 13.8212 16.3524 14.7656 15.1874 14.7656H11.6718"
          stroke="black"
          strokeOpacity="0.2"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6718 3.51562H13.078C13.8546 3.51562 14.4843 4.14524 14.4843 4.92188V6.32812"
          stroke="#9B9FA7"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6718 3.51562H13.078C13.8546 3.51562 14.4843 4.14524 14.4843 4.92188V6.32812"
          stroke="black"
          strokeOpacity="0.2"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4348 7.56511L14.0724 3.92749"
          stroke="#9B9FA7"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4348 7.56511L14.0724 3.92749"
          stroke="black"
          strokeOpacity="0.2"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_23576_6750">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
