import { DashboardIcon } from "shared/icons/DashboardIcon";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { EvivaLogo } from "shared/icons/EvivaLogo";
import { PortfoliosIcon } from "shared/icons/PortfoliosIcon";
import { ProposalsIcon } from "shared/icons/ProposalsIcon";
import { Routes } from "shared/router/Route";
import { EvivaLogoSmall } from "shared/icons/EvivaLogoSmall";
import { TradeIcon } from "shared/icons/TradeIcon";
import { PersonPlus } from "shared/icons/PersonPlus";
import { PersonGroup } from "shared/icons/PersonGroup";
import sbLogoBlack from "assets/images/sb-logo-black.png";
import sbLogoGradient from "assets/images/logo-gradient.png";
import useAppStatus from "shared/store/appStatusStore";
import { Network, Activity } from "lucide-react";
import CrmIcon from "assets/svg/CrmIcon";
import brandStore from "shared/store/brandStore";
import { BanknotesIcon } from "shared/icons/BanknotesIcon";
import colorsStore from "shared/store/colorsStore";
import { FC, useEffect, useState } from "react";
import styles from "./navigationBar.module.css";

type NavigationItem = {
  href: Routes;
  text: string;
  icon: JSX.Element;
  hide?: boolean;
};

export const NavigationBar: React.FC = () => {
  const { t } = useTranslation();

  const hide = false; //accountLoading !== Loading.Idle && cardType !== undefined;

  const menuItems: NavigationItem[] = [
    {
      href: "/dashboard",
      icon: <DashboardIcon className="h-5 w-5 shrink-0" />,
      text: t("navigation.dashboard"),
    },
    {
      href: "/contacts",
      icon: <PersonPlus className="h-5 w-5 shrink-0" />,
      text: t("navigation.contacts"),
    },
    {
      href: "/clients",
      icon: <PersonGroup className="h-5 w-5 shrink-0" />,
      text: t("navigation.clients"),
      hide: hide,
    },
    {
      href: "/portfolios",
      icon: <PortfoliosIcon className="h-5 w-5 shrink-0" />,
      text: t("navigation.portfolios"),
      hide: hide,
    },
    {
      href: "/proposals",
      icon: <ProposalsIcon className="h-5 w-5 shrink-0" />,
      text: t("navigation.proposals"),
      hide: hide,
    },
    {
      href: "/subscriptions",
      icon: <BanknotesIcon className="h-5 w-5 shrink-0" />,
      text: t("navigation.subscriptions"),
      hide: hide,
    },
    // {
    //   href: "/fees",
    //   icon: <DollarIcon className="h-5 w-5 shrink-0" />,
    //   text: t("navigation.fees"),
    //   hide: hide,
    // },
    // {
    //   href: "/payouts",
    //   icon: <BanknotesIcon className="h-5 w-5 shrink-0" />,
    //   text: t("navigation.payouts"),
    //   hide: hide,
    // },
    {
      href: "/trade",
      icon: <TradeIcon className="h-5 w-5 shrink-0" />,
      text: t("navigation.trade"),
      hide: hide,
    },
    {
      href: "/crm",
      icon: <CrmIcon className="h-5 w-5 shrink-0" />,
      text: "CRM",
      hide: hide,
    },
    // {
    //   href: "/watchlists",
    //   icon: <DocumentMagnifyingGlass className="h-5 w-5 shrink-0" />,
    //   text: t("navigation.watchlist"),
    //   hide: hide,
    // },
    {
      href: "/assistant",
      icon: <Activity className="h-6 w-6 shrink-0" />,
      text: t("aiAssistant.title"),
      hide: hide,
    },
    // {
    //   href: "/assistantWarren",
    //   icon: <Network className="h-6 w-6 shrink-0" />,
    //   text: t("navigation.assistant"),
    //   hide: hide,
    // },
  ];

  return (
    <div className="flex flex-col" style={{ alignItems: "start" }}>
      <BrandLogo />

      <div className="flex h-full flex-col justify-between">
        <div className="flex flex-col gap-1.5">
          {menuItems.map((item, idx) => {
            if (item.hide) {
              return (
                <div className="relative">
                  <div className="absolute inset-0 bg-sb-gray-50 opacity-70"></div>
                  <MenuItem item={item} isActive={false} />
                </div>
              );
            }
            return (
              <NavLink key={"nav_item_" + idx} to={item.href}>
                {({ isActive }) => <MenuItem item={item} isActive={isActive} />}
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

type MenuItemProps = {
  item: NavigationItem;
  isActive: boolean;
};

const MenuItem: FC<MenuItemProps> = ({ item, isActive }) => {
  return (
    <div className={`${styles.menuItem} ${isActive ? styles.active : ""}`}>
      <div className={styles.icon}>{item.icon}</div>
      <span className={styles.text}>{item.text}</span>
    </div>
  );
};

//** BRAND LOGO */
const BrandLogo = ({}) => {
  const { brandLoading, brandImageType, brandImage, brandName } = brandStore();
  const windowW = useAppStatus((state) => state.windowW);

  const CustomBrandLogo = () => {
    if (!brandImage || brandLoading) return null;

    if (brandImageType === "svg") {
      return (
        <div
          className="w-20 xl:w-40"
          dangerouslySetInnerHTML={{ __html: brandImage }}
        />
      );
    }
    if (brandImageType === "png" || brandImageType === "jpg") {
      return (
        <img
          src={brandImage}
          alt={brandName}
          className="w-40 object-contain xl:w-60"
        />
      );
    }
    return null;
  };

  return (
    <>
      {brandName !== "eviva" && brandName !== "streetbeat" && (
        <div
          style={{
            marginBottom: 20,
          }}
        >
          {!brandLoading && brandName && <CustomBrandLogo />}
        </div>
      )}

      {((brandName && brandName === "eviva") || brandName === "streetbeat") && (
        <>
          {brandName === "eviva" && (
            <>
              <EvivaLogo
                className=".5 float-left mb-[55px] hidden w-44 shrink-0 xl:block"
                href="/dashboard"
              />
              <EvivaLogoSmall
                className=".5 float-left mb-[55px] block aspect-square w-10 shrink-0 xl:hidden"
                href="/dashboard"
              />
            </>
          )}
          {brandName === "streetbeat" && windowW > 1280 && (
            <div style={{ marginBottom: 30 }}>
              <img style={{ width: 200 }} src={sbLogoBlack} alt="Streetbeat" />
            </div>
          )}
          {brandName === "streetbeat" && windowW <= 1280 && (
            <div style={{ marginBottom: 10 }}>
              <img
                style={{ width: 40 }}
                src={sbLogoGradient}
                alt="Streetbeat"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BrandLogo;
