import { AssistantMessage } from "types/assistant/AssistantTypes";

export const createAssistantMessage = (message, threadId) => {
  const messageObject: AssistantMessage = {
    id: "esg-preferences" + Math.random().toString(),
    role: "assistant",
    content: [{ type: "text", text: { value: message } }],
    created_at: new Date().toISOString(),
    thread_id: threadId,
    artifacts: [],
    object: "message",
  };
  return messageObject;
};
