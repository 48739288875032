export const AssistantRoutes = {
  THREADS: "/pro/api/v1/threads",
  THREAD_DETAILS: (threadId: string) => `/pro/api/v1/threads/${threadId}`,
  BASE_THREAD_DETAILS: (threadId: string) =>
    `/api/v1/assistant/threads/${threadId}`,
  THREAD_PORTFOLIOS: (id: string) =>
    `/api/v1/assistant/threads/${id}/portfolios`,
  THREAD_ACTIVATE_PORTFOLIO: (threadId: string, portfolioId: string) =>
    `/api/v1/assistant/threads/${threadId}/portfolios/${portfolioId}/activate`,
  THREAD_ACTION: (threadId: string, actionId: string) =>
    `/api/v1/assistant/threads/${threadId}/actions/${actionId}`,
  ATTACHMENT_DOWNLOAD: (threadId: string, attachmentId: number) =>
    `/pro/api/v1/threads/${threadId}/attachments/${attachmentId}/download`,
  ATTACHMENT_URL: (threadId: string, attachmentId: number) =>
    `/pro/api/v1/threads/${threadId}/attachments/${attachmentId}/url`,
  CREATE_PDF: (threadId: string, portfolioId: string) =>
    `/pro/api/v1/fineco/threads/${threadId}/report/${portfolioId}`,
  REPORT_DATA: (threadId: string, portfolioId: string) =>
    `/pro/api/v1/fineco/threads/${threadId}/report/${portfolioId}/data`,
  CREATE_EXCEL: (threadId: string, portfolioId: string) =>
    `/pro/api/v1/fineco/threads/${threadId}/report/${portfolioId}/excel`,
  REPORT_PARAMETERS: "/pro/api/v1/fineco/report/parameters",

  SPEECH_TO_TEXT: `api/v1/audio/stt`,
  TEXT_TO_SPEECH: `api/v1/audio/tts`,

  WS_THREADS: "/pro/ws/v1/threads",
};
