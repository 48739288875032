import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { InfoSection } from "../InfoSection";

export const NetLiquidationBlendedForm: React.FC = () => {
  const { t } = useTranslation();
  const [rangesCnt, setRangesCnt] = useState<number>(2);
  const [rangeValues, setRangeValues] = useState<Record<number, string>>({});

  const [maximumRanges, setMaximumRanges] = useState<Record<number, number>>(
    {},
  );

  useEffect(() => {
    let newMaximumRanges = { ...maximumRanges };
    if (newMaximumRanges[rangesCnt - 1]) {
      delete newMaximumRanges[rangesCnt - 1];
      setMaximumRanges(newMaximumRanges);
    }
  }, [rangesCnt, maximumRanges]);

  const setRangeMaximumValue = (index: number, value: number) => {
    if (index < rangesCnt - 1) {
      let newRangeValues = { ...rangeValues };
      let newMaximumRanges = { ...maximumRanges };
      if (value) {
        newRangeValues[index + 1] = (value + 0.01).toFixed(2).toString();
        newMaximumRanges[index] = value;
      } else {
        delete newRangeValues[index + 1];
        delete newMaximumRanges[index];
      }
      setRangeValues(newRangeValues);
      setMaximumRanges(newMaximumRanges);
    }
  };

  const isValueValid = (idx: number) => {
    const prevValue = parseFloat(idx === 0 ? "0" : rangeValues[idx] ?? "0");
    const currentValue = maximumRanges[idx];
    const isValid =
      // @ts-ignore
      idx > 0 && idx < rangesCnt - 1 ? currentValue > prevValue : true;

    return isValid;
  };

  return (
    <>
      {Array.from({ length: rangesCnt }).map((_, idx) => {
        return (
          <>
            <label key={"label_" + idx}>
              {t("feeTemplateForm.netLiquidation.navRange", { index: idx + 1 })}
              <Labels.G1 className="text-sm">
                {t("feeTemplateForm.netLiquidation.navRangeDescription")}
              </Labels.G1>
            </label>
            <div
              key={"inputs_" + idx}
              className="flex items-center justify-between"
            >
              <input
                type="text"
                name={"netLiquidation_navRange_" + idx + "_min"}
                readOnly
                className="flex-grow bg-sb-gray-200"
                value={
                  idx === 0
                    ? "0"
                    : rangeValues[idx]
                    ? rangeValues[idx]
                    : t("feeTemplateForm.netLiquidation.minimum")
                }
              />
              <span className="mx-10 font-bold">-</span>
              <input
                type="number"
                name={"netLiquidation_navRange_" + idx + "_max"}
                className="flex-grow"
                step={0.01}
                placeholder={t("feeTemplateForm.netLiquidation.maximum")}
                onChange={(e) =>
                  setRangeMaximumValue(idx, parseFloat(e.target.value))
                }
                value={idx === rangesCnt - 1 ? "" : undefined}
                disabled={idx === rangesCnt - 1}
              />
            </div>
            {!isValueValid(idx) && (
              <div className="col-span-2">
                <InfoSection
                  text={t("feeTemplateForm.netLiquidation.invalidRange")}
                  type="warning"
                />
              </div>
            )}
            <label className="border-b border-b-sb-gray-300 pb-4">
              {t("feeTemplateForm.netLiquidation.feePct")}
              <div className="text-sm text-sb-gray-500">
                {t("feeTemplateForm.netLiquidation.feePctDescription")}
              </div>
            </label>
            <div className="border-b border-b-sb-gray-300 pb-4">
              <input
                type="number"
                name={"netLiquidation_feePct_" + idx}
                placeholder={t("feeTemplateForm.netLiquidation.optional")}
                className="w-full"
              />
            </div>
          </>
        );
      })}
      <div className="col-span-2 flex items-center justify-center gap-4">
        {rangesCnt > 2 && (
          <Button
            label={t("feeTemplateForm.remove")}
            onClick={() => rangesCnt > 2 && setRangesCnt((prev) => prev - 1)}
          />
        )}
        <Button
          label={t("feeTemplateForm.add")}
          onClick={() => setRangesCnt((prev) => prev + 1)}
        />
      </div>
    </>
  );
};
