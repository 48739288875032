import RestService from "shared/api/RestService";
import { RealTimeTickerLatestClose } from "shared/models/asset/AssetModel";
import { TICKERS_SUBSCRIBE_V1 } from "shared/utils/routes";

export interface RealtimeTicker extends RealTimeTickerLatestClose {
  trends?: number[];
  subscribed_to_realtime?: boolean;
  bidPrice?: number;
  askPrice?: number;
}

export interface RealTimeTickers {
  [symbol: string]: RealtimeTicker | undefined;
}

export const RealtimeService = {
  subscribe: async (symbols: string[]) => {
    return await RestService.post<RealTimeTickers>(TICKERS_SUBSCRIBE_V1, {
      tickers: symbols,
    });
  },
};
