import { SearchIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { XMarkIcon } from "shared/icons/XMarkIcon";
import { cn } from "utils";

type Props = {
  query: string;
  setQuery: (val: string) => void;
  className?: string;
};

export default function SearchAssets({ query, setQuery, className }: Props) {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        "flex flex-1 items-center gap-4 rounded-xl border border-gray-300 p-2",
        className,
      )}
    >
      {query ? (
        <div onClick={() => setQuery("")}>
          <XMarkIcon
            strokeWidth={2}
            className="h-5 w-5 cursor-pointer text-gray-500"
          />
        </div>
      ) : (
        <SearchIcon className="h-5 w-5 text-gray-500" />
      )}
      <div className="w-full">
        <input
          type="text"
          className="w-full bg-transparent outline-none"
          placeholder={t("watchlists.searchStocksOrCrypto")}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
    </div>
  );
}
