import { useTranslation } from "react-i18next";
import "./styles.css";
import ReactMarkdown from "react-markdown";
import {
  ProAssistantMessage,
  ProAssistantMessageContent,
} from "shared/models/pro_assistant/ProAssistantModel";
import { TypingAnimation } from "../chat/svg/TypingAnimation";
import { MessageArtifacts } from "./artifacts/MessageArtifacts";
import { FeedbackButtons } from "./FeedbackButtons";
import { TextResponse } from "./responses/TextResponse";
import { UserImage } from "./UserImage";
import AiChatGradient from "./svg/AiChatGradient";

type Props = {
  message?: ProAssistantMessage;
  typing?: boolean;
};

export const ChatMessage: React.FC<Props> = ({ message, typing }) => {
  const isUser = message?.role === "user";

  if (typing) {
    return (
      <div className="p-4">
        <TypingAnimation />
      </div>
    );
  }

  return (
    <>
      {message?.content?.map((content, idx) => {
        return (
          <div>
            <ChatContent
              key={idx}
              content={content}
              isUser={isUser}
              name={message?.name || ""}
            />
            {message?.artifacts?.map((artifact, index) => (
              <div className="ml-10 mt-4" key={index}>
                <MessageArtifacts artifact={artifact} />
              </div>
            ))}
          </div>
        );
      })}
      {!isUser && <FeedbackButtons messageId={message?.id || ""} />}
    </>
  );
};

type ChatContentProps = {
  content: ProAssistantMessageContent;
  name: string;
  isUser: boolean;
};

export const ChatContent: React.FC<ChatContentProps> = ({
  content,
  isUser,
}) => {
  const { t } = useTranslation();

  const getResponseComponent = () => {
    if (content.type === "text" && content.text?.value) {
      return <TextResponse text={content.text.value} />;
    }
  };

  return (
    <div>
      <div className="mt-2">
        {isUser ? (
          <div className="flex items-start gap-4">
            <UserImage />
            <div>
              <div className="text-lg font-bold">{t("proAssistant.you")}</div>
              <ReactMarkdown className="mt-2 whitespace-pre-wrap text-base text-gray-500">
                {content.text?.value}
              </ReactMarkdown>
            </div>
          </div>
        ) : (
          <div className="flex items-start gap-4">
            <AiChatGradient className="size-8 shrink-0" />
            <div>
              <div className="font-bold capitalize lg:text-lg">Warren</div>
              {getResponseComponent()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
