import { t } from "i18next";
import { useEffect } from "react";
import { Labels } from "shared/components/hoc/Labels";
import useFeePayoutTermStore from "shared/store/feePayoutTermStore";
import { pctFormatUnsigned } from "shared/utils/currency";

export const PayoutTerms: React.FC = () => {
  const { payoutTerms, fetchPayoutTerms } = useFeePayoutTermStore();

  useEffect(() => {
    fetchPayoutTerms();
  }, []);

  if (!payoutTerms) {
    return null;
  }

  const sb_share = payoutTerms.streetbeat_share;

  return (
    <>
      <Labels.H3 className="mt-8">{t("payouts.payoutTerms")}</Labels.H3>
      <div>
        <div>
          <span className="mr-2 font-medium">
            {t("payouts.effectiveFrom")}:
          </span>
          {payoutTerms?.effective_from}
        </div>
        <div>
          <span className="mr-2 font-medium">
            {t("payouts.streetbeatShare")}:
          </span>
          {pctFormatUnsigned.format(sb_share)}
        </div>
      </div>
    </>
  );
};
