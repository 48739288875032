import React, { useEffect, useState } from "react";
import {
  ChevronLeft,
  ChevronRight,
  UserCircle2,
  Info,
  Trash2,
  MoreHorizontal,
  ChevronDown,
  Settings,
} from "lucide-react";
import styles from "./activeCampaignsWidget.module.css";
import crmStore from "shared/store/crmStore";
import crmStyles from "./../../crmPage.module.css";
import { CrmCampaignSummary } from "types/crm/ClientTypes";
import { formatDate } from "utils/misc";
import { useTranslation } from "react-i18next";

export const ActiveCampaignsWidget = () => {
  const { t } = useTranslation();
  // const { crmCampaignsSummary, fetchCrmCampaignsSummary } = crmStore(
  //   // @ts-ignore
  //   (state) => ({
  //     crmCampaignsSummary: state.crmCampaignsSummary,
  //     fetchCrmCampaignsSummary: state.fetchCrmCampaignsSummary,
  //   }),
  // );
  const { crmCampaignsSummary, fetchCrmCampaignsSummary } = crmStore();
  // // Simulated data

  useEffect(() => {
    fetchCrmCampaignsSummary();
  }, []);

  const [campaigns, setCampaigns] = useState<CrmCampaignSummary[]>([]);

  useEffect(() => {
    if (crmCampaignsSummary.length > 0) {
      setCampaigns(crmCampaignsSummary);
    }
  }, [crmCampaignsSummary]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("lastUpdated");
  const [sortDirection, setSortDirection] = useState("desc");

  const ITEMS_PER_PAGE = 6;
  const totalItems = campaigns.length;
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, totalItems);

  const handleCheckboxChange = (campaign_id: number | string) => {
    setCampaigns(
      campaigns.map((campaign) =>
        campaign.campaign_id === campaign_id
          ? { ...campaign, checked: !campaign.checked }
          : campaign,
      ),
    );
  };

  const handleSort = () => {
    setSortDirection((current) => (current === "asc" ? "desc" : "asc"));
  };

  const getPriorityClass = (priority: string): any => {
    const classes = {
      urgent: crmStyles.urgentPriority,
      urgente: crmStyles.urgentPriority,
      Urgente: crmStyles.urgentPriority,
      high: crmStyles.highPriority,
      alta: crmStyles.highPriority,
      Alta: crmStyles.highPriority,
      medium: crmStyles.mediumPriority,
      media: crmStyles.mediumPriority,
      Media: crmStyles.mediumPriority,
      low: crmStyles.lowPriority,
      bassa: crmStyles.lowPriority,
      Bassa: crmStyles.lowPriority,
    } as any;
    return classes[priority] || "";
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t("crm.activeCampaigns")}</h2>
        <div className={styles.headerRight}>
          <span className={styles.counter}>
            {startIndex + 1} - {endIndex} {t("crm.outOf")} {totalItems}
          </span>
          <div className={styles.navigation}>
            <button
              className={styles.navButton}
              onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
              disabled={currentPage === 1}
            >
              <ChevronLeft size={20} />
            </button>
            <button
              className={styles.navButton}
              onClick={() =>
                setCurrentPage((p) =>
                  Math.min(Math.ceil(totalItems / ITEMS_PER_PAGE), p + 1),
                )
              }
              disabled={endIndex >= totalItems}
            >
              <ChevronRight size={20} />
            </button>
          </div>
        </div>
      </div>

      <div className={styles.toolbarContainer}>
        <div className={styles.leftToolbar}>
          <UserCircle2 size={20} className={styles.toolbarIcon} />
          <Info size={20} className={styles.toolbarIcon} />
          <Trash2 size={20} className={styles.toolbarIcon} />
          <MoreHorizontal size={20} className={styles.toolbarIcon} />
        </div>
        <div className={styles.rightToolbar}>
          <Settings size={20} className={styles.toolbarIcon} />
          <button className={styles.sortButton} onClick={handleSort}>
            <span>{t("crm.sortLastUpdate")}</span>
            <ChevronDown
              size={16}
              className={sortDirection === "desc" ? styles.rotated : ""}
            />
          </button>
        </div>
      </div>
      {campaigns.length === 0 && (
        <div className={crmStyles.noContent}>{t("crm.noContentAvailable")}</div>
      )}
      {campaigns.length > 0 && (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.checkboxColumn}>
                  <input
                    type="checkbox"
                    onChange={() => {
                      /* Handle all check */
                    }}
                    className={styles.checkbox}
                  />
                </th>
                <th>{t("crm.priority")}</th>
                <th>{t("crm.campaignName")}</th>
                <th>{t("crm.targetUsers")}</th>
                <th>{t("crm.activeUsersInCampaign")}</th>
                <th>{t("crm.openRate")}</th>
                <th>{t("crm.clickRate")}</th>
                <th>{t("crm.lastUpdate")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {campaigns.slice(startIndex, endIndex).map((campaign) => (
                <tr key={campaign.campaign_id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={campaign.checked}
                      onChange={() =>
                        handleCheckboxChange(campaign.campaign_id)
                      }
                      className={styles.checkbox}
                    />
                  </td>
                  <td>
                    <span
                      className={`${styles.priority} ${getPriorityClass(
                        campaign.priority,
                      )}`}
                    >
                      {campaign.priority}
                    </span>
                  </td>
                  <td>{campaign.name}</td>
                  <td>{campaign.target_users}</td>
                  <td>{campaign.active_users}</td>
                  <td>{campaign.open_rate}%</td>
                  <td>{campaign.click_rate}%</td>
                  <td>{formatDate(campaign.last_update)}</td>
                  <td>
                    <button className={styles.addButton}>{t("crm.add")}</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
