import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "lucide-react";

type RebalanceFrequency = "weekly" | "monthly" | "quarterly" | "none";

const RebalanceDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<RebalanceFrequency>("none");
  const { t } = useTranslation();

  const frequencies: RebalanceFrequency[] = [
    "weekly",
    "monthly",
    "quarterly",
    "none",
  ];

  const getButtonLabel = () => {
    if (selected === "none") {
      return t("faPortfolio.rebalance");
    }
    return `${t("faPortfolio.rebalance")}: ${t(`faPortfolio.rebalanceFrequencies.${selected}`)}`;
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 rounded-lg bg-sb-gray-100 px-4 py-2 text-black hover:bg-sb-gray-200"
      >
        <span>{getButtonLabel()}</span>
        <ChevronDown size={16} />
      </button>

      {isOpen && (
        <div className="absolute left-0 top-full z-50 mt-1 w-40 rounded-lg border border-sb-gray-200 bg-white shadow-lg">
          {frequencies.map((frequency) => (
            <button
              key={frequency}
              className="w-full px-4 py-2 text-left hover:bg-sb-gray-100"
              onClick={() => {
                setSelected(frequency);
                setIsOpen(false);
              }}
            >
              {t(`faPortfolio.rebalanceFrequencies.${frequency}`)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default RebalanceDropdown;
