import { PlusIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AiChatGradient from "./svg/AiChatGradient";
import { Panel } from "shared/components/hoc/Panels";

type Props = {
  expanded: boolean;
};

export const NewThreadWidget: React.FC<Props> = ({ expanded }) => {
  const { t } = useTranslation();

  return (
    <Panel className="w-full cursor-pointer select-none text-base duration-150 hover:opacity-60">
      <Link to={"/assistantWarren"}>
        <div className="flex items-center justify-between text-gray-500">
          <div className="flex items-center gap-4 whitespace-nowrap">
            <AiChatGradient className="size-8 -ml-1 mr-2" />
            <h3 className={expanded ? "visible" : "hidden"}>
              {t("advisor.newChat")}
            </h3>
          </div>
          <div className={expanded ? "visible" : "hidden"}>
            <PlusIcon className="size-4" />
          </div>
        </div>
      </Link>
    </Panel>
  );
};
