// CrmClientDetailsPage.jsx
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import crmStore from "shared/store/crmStore";
import { ClientHeader } from "./components/ClientHeader";
import { ActivityLog } from "./components/ActivityLog";
import { TabNavigation } from "./components/TabNavigation";
import styles from "./crmClientDetailsPage.module.css";
import { findByProp } from "utils/misc";
import { CrmService } from "services/CrmServices";
import { ClientDetailsDeals } from "./components/ClientDetailsDeals";
import { ClientDetailsCampagne } from "./components/ClientDetailsCampagne";
import { ClientDetailsNotes } from "./components/ClientDetailsNotes";
import { ClientDetailsSchedule } from "./components/ClientDetailsSchedule";
import { useTranslation } from "react-i18next";
import { BasePage } from "shared/components/common/BasePage";

export const CrmClientDetailsPage = () => {
  const { t } = useTranslation();
  const { clientsList } = crmStore();

  const [clientData, setClientData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const params = useParams();
  const clientId = params.clientId;
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("Activity log");

  const { fetchClientsList } = crmStore();

  useEffect(() => {
    const client = findByProp(clientsList, "client_id", clientId);
    if (client) {
      setClientData(client);
    } else {
      awaitFetchClientsDetails();
    }
  }, [clientsList, clientId]);

  useEffect(() => {
    fetchClientsList();
  }, []);

  const awaitFetchClientsDetails = async () => {
    setIsLoading(true);
    try {
      // @ts-ignore
      const client = await CrmService.fetchClientDetail(clientId);
      // @ts-ignore
      setClientData(client);
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status === 500) {
        // @ts-ignore
        setError("Server error while fetching client details");
      } else {
        // @ts-ignore
        setError("Error fetching client details");
      }
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    // setCrmActiveTab("clients");
    navigate("/crm");
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error || !clientData) {
    return <div>No data available for this client</div>;
  }

  return (
    <BasePage hasFooter={false}>
      <div className={styles.container}>
        <button onClick={handleBack} className={styles.backButton}>
          <ChevronLeft size={20} />
          <span>{t("global.back")}</span>
        </button>

        <div className={styles.content}>
          <div className={styles.leftColumn}>
            <ClientHeader
              clientData={clientData}
              activeTab={activeTab}
              onTabChange={setActiveTab}
            />
          </div>

          <div className={styles.rightColumn}>
            <TabNavigation
              clientData={clientData}
              activeTab={activeTab}
              onTabChange={setActiveTab}
            />
            <div className={styles.tabContent}>
              {activeTab === "Activity log" && (
                <ActivityLog clientData={clientData} />
              )}
              {activeTab === "Deals" && (
                <ClientDetailsDeals clientData={clientData} />
              )}
              {activeTab === "Campagne" && (
                <ClientDetailsCampagne clientData={clientData} />
              )}
              {activeTab === "Schedule" && (
                <ClientDetailsSchedule clientData={clientData} />
              )}
              {activeTab === "Note" && (
                <ClientDetailsNotes clientData={clientData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </BasePage>
  );
};
