import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shared/components/ui/select";
import colorsStore from "shared/store/colorsStore";

type DropdownProps = {
  label: string;
  options: any;
  defaultValue?: string;
  onChange: (value: string) => void;
};

export const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  defaultValue,
  onChange,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  return (
    <div>
      <p
        style={{
          fontSize: "12px",
          color: colorsConfig.black,
        }}
      >
        {label}
      </p>
      <Select
        value={defaultValue ?? undefined}
        onValueChange={(value) => onChange(value)}
      >
        <SelectTrigger
          style={{
            width: "100%",
            padding: "1rem",
            borderRadius: "12px",
            border: "1px solid #8F949C",
            backgroundColor: "#ffffff",
            fontSize: "12px",
          }}
        >
          <SelectValue placeholder="Select an option" />
        </SelectTrigger>
        <SelectContent
          style={{
            borderRadius: "12px",
            border: "1px solid #8F949C",
            backgroundColor: "#ffffff",
          }}
        >
          {options?.map((option) => (
            <SelectItem
              key={option}
              value={option}
              style={{
                padding: "0.6rem",
                fontSize: "12px",
                color: "#1f2937",
              }}
            >
              {option}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};
