import { ChartRange } from "shared/utils/types";
import useMarketInfo from "./useMarketInfo";
import useRealtimeTicker from "./useRealtimeTicker";
import useAssetChartStore from "shared/store/assetChartStore";
import useMarketInfoStore from "shared/store/marketInfoStore";

export const useAssetPrice = (assetId: string, range: ChartRange) => {
  const { marketInfo } = useMarketInfo();
  const { fetchMarketInfo } = useMarketInfoStore();
  const chartData = useAssetChartStore(
    (state) => state.cache?.[assetId]?.[range],
  );

  const { latestPrice, previousClose, askPrice, bidPrice } = useRealtimeTicker(
    assetId ? assetId : "",
  );

  if (!marketInfo) {
    fetchMarketInfo();
  }

  const firstPoint =
    // @ts-ignore
    chartData && chartData.length > 1 ? chartData[0].latestPrice || 0 : 0;
  const pnl =
    range === "date" ? latestPrice - previousClose : latestPrice - firstPoint;
  const pnlPct = firstPoint > 0 ? (pnl * 100) / firstPoint : 0;
  const positive = pnl >= 0;

  return {
    latestPrice,
    previousClose,
    askPrice,
    bidPrice,
    pnl,
    pnlPct,
    positive,
  };
};
