import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/components/controls/Button";
import { FilledAddIcon } from "shared/icons/FilledAddIcon";
import { PortfolioAssistantService } from "shared/services/portfolio_assistant/PortfolioAssistantService";
import useAppStore from "shared/store/appStore";

interface Props {
  className?: string;
}

export const CreatePortfolioButton: React.FC<Props> = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const { setAssistantActiveSession, openAiAssistant } = useAppStore();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const createNewSession = () => {
    navigate("/assistant/prompt:" + t("assistant.requestCreatePortfolio"));
    // setLoading(true);
    // PortfolioAssistantService.createSession()
    //   .then((s) => {
    //     setAssistantActiveSession(s);
    //     openAiAssistant();
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  return (
    <Button
      leftIcon={<FilledAddIcon className="fill-sb-green-800" />}
      label="portfolios.create"
      onClick={createNewSession}
      className={className}
      loading={loading}
    />
  );
};
