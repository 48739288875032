import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  ReferenceLine,
} from "recharts";
import PortfolioConfigData from "config/portfolio-config.json";
import { PortfolioElementType } from "types/assistant/PortfolioTypes";

type Props = {
  dataItem: PortfolioElementType;
};

const DistributionBarChart: React.FC<Props> = ({ dataItem }) => {
  const hasNegative = dataItem.data.some((item) => item.weight < 0);

  let maxWeight = Math.max(...dataItem.data.map((item) => item.weight), 10);
  let minWeight = hasNegative
    ? Math.min(...dataItem.data.map((item) => item.weight), -10)
    : 0;

  const totalHeight = dataItem.data.length * 42; // exact height needed

  const formattedData = dataItem.data.map((item) => ({
    ...item,
    weight: Number(item.weight).toFixed(2),
  }));

  maxWeight = Number(maxWeight.toFixed(2));
  minWeight = Number(minWeight.toFixed(2));

  return (
    <div className="bar-chart-container" style={{ marginTop: 47.5 }}>
      <ResponsiveContainer width="100%" height={totalHeight + 32}>
        <BarChart
          layout="vertical"
          data={formattedData}
          margin={{ left: 0, top: 0, right: 0, bottom: 0 }}
          barGap={0}
          barCategoryGap={0}
          height={totalHeight} // THIS IS THE KEY - set exact height here
        >
          <XAxis type="number" domain={[minWeight, maxWeight]} />
          <YAxis type="category" dataKey="label" tick={false} width={10} />
          <Tooltip />
          {hasNegative && <ReferenceLine x={0} stroke="#000" />}
          <Bar dataKey="weight" fill="#8884d8" barSize={30}>
            {formattedData.map((entry, index) => {
              const paletteArray =
                PortfolioConfigData.palettes[dataItem.configData.palette];
              const colorIndex = index % paletteArray.length;
              return (
                <Cell key={`cell-${index}`} fill={paletteArray[colorIndex]} />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DistributionBarChart;
