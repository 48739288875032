import colorsStore from "shared/store/colorsStore";

export const Processing: React.FC = () => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  return (
    <>
      <div
        className="grid size-8 shrink-0 place-items-center rounded-full"
        style={{ borderColor: colorsConfig.main }}
      >
        <div
          className="animate-spin text-green-500"
          style={{ color: colorsConfig.main }}
        >
          <svg
            className="h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M 4 12 a 8 8 0 0 1 8 -8 V 0 C 5.373 0 0 5.373 0 12 h 4 z A 8.001 8.001 0 0 1 12 4 v 0 c -4 0 -8 3 -8 8 h -2 z z"
            ></path>
          </svg>
        </div>
      </div>
    </>
  );
};
