import { Loading } from "shared/types/enums";
import { create } from "./store";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { AssetsService } from "shared/services/assets/AssetsService";

type AssetsStoreState = {
  assets: AssetWrapper[] | null | undefined;
  loading: Loading;
};

type AssetsStoreActions = {
  fetchAssets: () => void;
};

const initialData: AssetsStoreState = {
  assets: undefined,
  loading: Loading.Idle,
};

const useAssetsStore = create<AssetsStoreState & AssetsStoreActions>()(
  (set) => ({
    ...initialData,
    fetchAssets: async () => {
      set({ loading: Loading.InProgress });
      try {
        const assets = await AssetsService.getAssets();
        set({ assets });
      } finally {
        set({ loading: Loading.Finished });
      }
    },
  }),
);

export default useAssetsStore;
