import { create } from "./store";
import { ClientRecentActivities } from "shared/models/client/ClientModel";
import { FinancialAdvisorFee } from "shared/models/fees/FeesModel";
import { PlaidInvestmentsInstitution } from "shared/models/plaid/PlaidInvestmentsModel";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import { TradingOrder } from "shared/models/trading/TradingOrderModel";
import { TradingPosition } from "shared/models/trading/TradingPositionmodel";
import {
  WireDepositAccountModel,
  WireDepositCurrencies,
} from "shared/models/wire_deposit/WireDepositModel";
import { ClientsService } from "shared/services/clients/ClientsService";
import { FeesService } from "shared/services/fees/FeesService";
import { OrdersService } from "shared/services/orders/OrdersService";
import { PlaidInvestmentsService } from "shared/services/plaid/PlaidInvestmentsService";
import { PositionsService } from "shared/services/positions/PositionsService";
import { Loading } from "shared/types/enums";

type ClientDetails = {
  positions: TradingPosition[];
  orders: TradingOrder[];
  portfolios: Portfolio[];
  recentActivities: ClientRecentActivities;
  recentCompositionActivities: ClientRecentActivities;
  wireAccounts: {
    accounts: WireDepositAccountModel[];
    selectedCurrency?: WireDepositCurrencies;
  };
  openFees: FinancialAdvisorFee[];
  feeSchedule: FinancialAdvisorFee[];
  institutions: PlaidInvestmentsInstitution[];

  positionsLoading: Loading;
  ordersLoading: Loading;
  portfoliosLoading: Loading;
  recentActivitiesLoading: Loading;
  recentCompositionActivitiesLoading: Loading;
  wireAccountsLoading: Loading;
  feesLoading: Loading;
  feeScheduleLoading: Loading;
};

type ClientDetailsStoreState = {
  clientDetails: {
    [clientId: string]: ClientDetails;
  };
};

type ClientDetailsStoreActions = {
  fetchPositions: (clientId: string) => Promise<void>;
  fetchPortfolios: (clientId: string) => Promise<void>;
  fetchRecentActivities: (
    clientId: string
  ) => Promise<void>;
  fetchRecentCompositionActivities: (clientId: string) => Promise<void>;
  fetchClientPortfolio: (
    clientId: string,
    portfolioId: string,
  ) => Promise<void>;
  fetchWireAccounts: (clientId: string) => Promise<void>;
  fetchOpenFees: (clientId: string) => Promise<void>;
  fetchFeeSchedule: (clientId: string) => Promise<void>;
  fetchInstitutions: (clientId: string) => Promise<void>;
  fetchOrders: (clientId: string, limit: number) => Promise<void>;
};

const initialData: ClientDetailsStoreState = {
  clientDetails: {},
};

const initialState: ClientDetails = {
  positions: [],
  orders: [],
  portfolios: [],
  recentActivities: [],
  recentCompositionActivities: [],
  wireAccounts: {
    accounts: [],
    selectedCurrency: undefined,
  },
  openFees: [],
  feeSchedule: [],
  institutions: [],

  positionsLoading: Loading.Idle,
  ordersLoading: Loading.Idle,
  portfoliosLoading: Loading.Idle,
  recentActivitiesLoading: Loading.Idle,
  recentCompositionActivitiesLoading: Loading.Idle,
  wireAccountsLoading: Loading.Idle,
  feesLoading: Loading.Idle,
  feeScheduleLoading: Loading.Idle,
};

const useClientDetailsStore = create<
  ClientDetailsStoreState & ClientDetailsStoreActions
>()((set, get) => ({
  ...initialData,
  fetchPositions: async (clientId) => {
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          positionsLoading: Loading.InProgress,
        },
      },
    });
    const positions = await PositionsService.fetch(clientId);
    set({
      // @ts-ignore
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...get().clientDetails[clientId],
          positions,
          positionsLoading: Loading.Finished,
        },
      },
    });
  },
  fetchPortfolios: async (clientId) => {
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          portfoliosLoading: Loading.InProgress,
        },
      },
    });
    const portfolios = await ClientsService.getPortfolios(clientId);
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          portfolios,
          portfoliosLoading: Loading.Finished,
        },
      },
    });
  },
  fetchRecentActivities: async (clientId) => {
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          recentActivitiesLoading: Loading.InProgress,
        },
      },
    });
    const recentActivities = await ClientsService.getRecentActivities(
      clientId
    );
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          recentActivities,
          recentActivitiesLoading: Loading.Finished,
        },
      },
    });
  },
  fetchRecentCompositionActivities: async (clientId) => {
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          recentCompositionActivitiesLoading: Loading.InProgress,
        },
      },
    });
    const recentCompositionActivities = await ClientsService.getRecentActivities(
      clientId,
      { type: "composition" },
    );
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          recentCompositionActivities,
          recentCompositionActivitiesLoading: Loading.Finished,
        },
      },
    });
  },

  fetchClientPortfolio: async (clientId, portfolioId) => {
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          portfoliosLoading: Loading.InProgress,
        },
      },
    });
    const portfolio = await ClientsService.getPortfolioById(
      clientId,
      portfolioId,
    );
    const existingPortfolios = get().clientDetails[clientId]?.portfolios || [];
    const portfolioIndex = existingPortfolios.findIndex(p => p.id === portfolioId);
    
    const updatedPortfolios = portfolioIndex >= 0
      ? [
          ...existingPortfolios.slice(0, portfolioIndex),
          portfolio,
          ...existingPortfolios.slice(portfolioIndex + 1)
        ]
      : [...existingPortfolios, portfolio];

    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          portfolios: updatedPortfolios,
          portfoliosLoading: Loading.Finished,
        },
      },
    });
  },
  fetchWireAccounts: async (clientId) => {
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          wireAccountsLoading: Loading.InProgress,
        },
      },
    });
    const wireAccounts = await ClientsService.getWireAccounts(clientId);
    let selectedCurrency =
      get().clientDetails[clientId]?.wireAccounts?.selectedCurrency;
    if (!selectedCurrency) {
      // auto-select default currency
      const currencies: WireDepositCurrencies[] = ["GBP", "CHF", "EUR", "USD"];
      const available = currencies.filter((c) =>
        wireAccounts.find((a) => a.currency === c),
      );
      selectedCurrency = available[0];
    }
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          wireAccounts: {
            selectedCurrency,
            accounts: [
              // @ts-ignore
              ...get().clientDetails[clientId].wireAccounts.accounts,
              ...wireAccounts,
            ],
          },
          wireAccountsLoading: Loading.Finished,
        },
      },
    });
  },
  fetchOpenFees: async (clientId) => {
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          feesLoading: Loading.InProgress,
        },
      },
    });
    const fees = await FeesService.getClientOpenFees(clientId);
    set({
      // @ts-ignore
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...get().clientDetails[clientId],
          openFees: fees,
          feesLoading: Loading.Finished,
        },
      },
    });
  },
  fetchFeeSchedule: async (clientId) => {
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          feeScheduleLoading: Loading.InProgress,
        },
      },
    });
    const fees = await FeesService.getClientFeeSchedules(clientId);
    set({
      // @ts-ignore
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...get().clientDetails[clientId],
          feeSchedule: fees,
          feeScheduleLoading: Loading.Finished,
        },
      },
    });
  },
  fetchInstitutions: async (clientId) => {
    const institutions =
      await PlaidInvestmentsService.getInstitutions(clientId);
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          institutions,
        },
      },
    });
  },
  fetchOrders: async (clientId, limit) => {
    const orders = await OrdersService.fetch(clientId, `?limit=${limit}`);
    set({
      clientDetails: {
        ...get().clientDetails,
        [clientId]: {
          ...initialState,
          ...get().clientDetails[clientId],
          orders,
        },
      },
    });
  },
}));

export default useClientDetailsStore;
