import { create } from "./store";
import { Loading } from "shared/types/enums";
import { MacroeconomicConditions } from "shared/models/tools/MacroeconomicConditionsModel";
import { ToolsService } from "shared/services/tools/ToolsService";

type MacroeconomicConditionsStoreState = {
  conditions: MacroeconomicConditions | null | undefined;
  loading: Loading;
};

type MacroeconomicConditionsStoreActions = {
  fetchMacroeconomicConditions: () => Promise<void>;
};

const initialData: MacroeconomicConditionsStoreState = {
  conditions: undefined,
  loading: Loading.Idle,
};

const useMacroeconomicConditionsStore = create<
  MacroeconomicConditionsStoreState & MacroeconomicConditionsStoreActions
>()((set) => ({
  ...initialData,
  fetchMacroeconomicConditions: async () => {
    set({ loading: Loading.InProgress });
    try {
      const conditions = await ToolsService.fetchMacroeconomicConditions();
      set({ conditions });
    } finally {
      set({ loading: Loading.Finished });
    }
  },
}));

export default useMacroeconomicConditionsStore;
