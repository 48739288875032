type Props = {
  className?: string;
  style?: React.CSSProperties;
  isExpanded?: boolean;
};

export const ChatDownArrowIcon: React.FC<Props> = ({
  className,
  style,
  isExpanded,
}) => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1505_12897)">
          <path
            d="M1.17188 15C1.17188 22.6371 7.36295 28.8281 15 28.8281C22.6371 28.8281 28.8281 22.6371 28.8281 15C28.8281 7.36295 22.6371 1.17188 15 1.17188C7.36295 1.17188 1.17188 7.36295 1.17188 15Z"
            fill="#E5F1FB"
            stroke="#00549F"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M21.3281 13.125L14.8828 19.5703L8.4375 13.125"
            stroke="#00549F"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1505_12897">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
