import { useEffect, useState } from "react";
import { get, ref, set } from "firebase/database";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { auth, database, storage } from "shared/utils/firebase";

interface Brand {
  name: string;
  image: string;
  imageType: "svg" | "png" | "jpg";
  storagePath?: string;
}

// Environment detection
export const getEnvironment = () => {
  const isProd = window.location.href.includes("eviva.streetbeat.com");
  const returnObj = {
    isDev: !isProd,
    dbName: isProd
      ? "eviva-brand-prod"
      : "eviva-brand-staging",
  };
  return returnObj;
};

export const useBrandManager = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState<string>("");
  const env = getEnvironment();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const getBrands = async (): Promise<Brand[]> => {
    if (!isAuthenticated) {
      throw new Error("User not authenticated");
    }

    try {
      const nodeRef = ref(database(), `${env.dbName}/brands`);
      const snapshot = await get(nodeRef);
      const brandsData = snapshot.val();
      return brandsData ? Object.values(brandsData) : [];
    } catch (err) {
      console.error("Error getting brands:", err);
      throw err;
    }
  };

  const getCurrentBrand = async () => {
    try {
      const brandNameRef = ref(database(), `${env.dbName}/brandName`);
      const snapshot = await get(brandNameRef);
      if (snapshot.exists()) {
        setSelectedBrand(snapshot.val());
      }
    } catch (err) {
      console.error("Error getting current brand:", err);
    }
  };

  const selectBrand = async (brandName: string) => {
    if (!isAuthenticated) {
      throw new Error("User not authenticated");
    }

    try {
      const brandNameRef = ref(database(), `${env.dbName}/brandName`);
      await set(brandNameRef, brandName);
      setSelectedBrand(brandName);
    } catch (err) {
      console.error("Error selecting brand:", err);
      throw err;
    }
  };

  const createBrand = async (name: string, file: File) => {
    if (!isAuthenticated) {
      throw new Error("User not authenticated");
    }

    try {
      let imageValue: string;
      let imageType: Brand["imageType"];
      let storagePath: string | undefined;

      if (file.type === "image/svg+xml") {
        const reader = new FileReader();
        imageValue = await new Promise((resolve, reject) => {
          reader.onload = (e) => resolve(e.target?.result as string);
          reader.onerror = (e) => reject(e);
          reader.readAsText(file);
        });
        imageType = "svg";
      } else {
        // Create storage path with environment prefix
        storagePath = `${env.dbName}/brands/${name}/${file.name}`;
        const fileRef = storageRef(storage, storagePath);
        await uploadBytes(fileRef, file);
        imageValue = await getDownloadURL(fileRef);
        imageType = file.type.includes("png") ? "png" : "jpg";
      }

      // Get current brands array
      const brandsRef = ref(database(), `${env.dbName}/brands`);
      const snapshot = await get(brandsRef);
      const currentBrands = snapshot.val() || [];

      // Add new brand to the array
      const newBrandIndex = currentBrands.length;
      const newBrandRef = ref(
        database(),
        `${env.dbName}/brands/${newBrandIndex}`,
      );

      await set(newBrandRef, {
        name,
        image: imageValue,
        imageType,
        ...(storagePath && { storagePath }), // Only add storagePath if it exists
      });
    } catch (err) {
      console.error("Error creating brand:", err);
      throw err;
    }
  };

  const deleteBrand = async (brandName: string) => {
    if (!isAuthenticated) {
      throw new Error("User not authenticated");
    }

    try {
      // Get current brands array
      const brandsRef = ref(database(), `${env.dbName}/brands`);
      const snapshot = await get(brandsRef);
      const currentBrands = snapshot.val() || [];

      // Find the brand to delete
      const brandIndex = currentBrands.findIndex(
        (brand: Brand) => brand.name === brandName,
      );
      if (brandIndex === -1) {
        throw new Error("Brand not found");
      }

      const brandToDelete = currentBrands[brandIndex];

      // If it's a PNG or JPG and has a storagePath, delete from storage
      if (brandToDelete.imageType !== "svg" && brandToDelete.storagePath) {
        try {
          const fileRef = storageRef(storage, brandToDelete.storagePath);
          await deleteObject(fileRef);
        } catch (storageErr) {
          console.error("Error deleting from storage:", storageErr);
        }
      }

      // Remove the brand and reindex the array
      currentBrands.splice(brandIndex, 1);

      // Update the database with the new array
      await set(brandsRef, currentBrands);

      // If this was the selected brand, clear the selection
      if (selectedBrand === brandName) {
        const brandNameRef = ref(database(), `${env.dbName}/brandName`);
        await set(brandNameRef, "");
        setSelectedBrand("");
      }
    } catch (err) {
      console.error("Error deleting brand:", err);
      throw err;
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getCurrentBrand();
    }
  }, [isAuthenticated]);

  return {
    getBrands,
    selectBrand,
    createBrand,
    deleteBrand,
    loading,
    error,
    isAuthenticated,
    selectedBrand,
  };
};
export const UpdateBrandPage: React.FC = () => {
  const {
    loading,
    error,
    getBrands,
    selectBrand,
    createBrand,
    deleteBrand,
    isAuthenticated,
    selectedBrand,
  } = useBrandManager();

  const [brands, setBrands] = useState<Brand[]>([]);
  const [newBrandName, setNewBrandName] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [deleting, setDeleting] = useState<string | null>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const loadedBrands = await getBrands();
        setBrands(loadedBrands);
      } catch (err) {
        console.error("Error loading data:", err);
      }
    };

    if (isAuthenticated) {
      loadData();
    }
  }, [isAuthenticated]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <div>Please log in to access brand management.</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Check file type
    if (!["image/png", "image/jpeg", "image/svg+xml"].includes(file.type)) {
      setUploadError("Please select a PNG, JPG, or SVG file");
      return;
    }

    setSelectedFile(file);
    setUploadError(null);
  };

  const handleUpload = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!newBrandName.trim() || !selectedFile) {
      setUploadError("Please provide both name and file");
      return;
    }

    setUploading(true);
    setUploadError(null);

    try {
      await createBrand(newBrandName.trim(), selectedFile);

      // Refresh brand list
      const updatedBrands = await getBrands();
      setBrands(updatedBrands);

      // Reset form
      setNewBrandName("");
      setSelectedFile(null);
      // Reset file input by clearing its value
      const fileInput = document.querySelector(
        'input[type="file"]',
      ) as HTMLInputElement;
      if (fileInput) fileInput.value = "";
    } catch (err) {
      console.error("Upload failed:", err);
      setUploadError("Upload failed. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async (brandName: string, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering brand selection

    if (window.confirm(`Are you sure you want to delete ${brandName}?`)) {
      setDeleting(brandName);
      try {
        await deleteBrand(brandName);
        const updatedBrands = await getBrands();
        setBrands(updatedBrands);
      } catch (err) {
        console.error("Delete failed:", err);
        alert("Failed to delete brand. Please try again.");
      } finally {
        setDeleting(null);
      }
    }
  };

  const BrandImage = ({ brand }: { brand: Brand }) => {
    if (brand.imageType === "svg") {
      return (
        <div
          className="mx-auto h-24 w-24"
          dangerouslySetInnerHTML={{ __html: brand.image }}
        />
      );
    }
    return (
      <img
        src={brand.image}
        alt={brand.name}
        className="h-70 w-70 mx-auto object-contain"
        style={{ maxWidth: 120 }}
      />
    );
  };

  return (
    <div style={{ margin: 20 }}>
      <h1 style={{ fontSize: 24, fontWeight: "bold" }}>Select Brand</h1>
      <div className="mt-4 grid grid-cols-2 gap-4">
        {brands.map((brand, index) => (
          <div
            key={index}
            className={`cursor-pointer rounded border p-4 transition-all hover:shadow-lg ${selectedBrand === brand.name
                ? "border-blue-500 bg-blue-50"
                : "border-gray-200"
              }`}
            onClick={() => selectBrand(brand.name)}
          >
            <div className="flex items-center gap-2">
              <span
                style={{
                  marginLeft: 5,
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {brand.name}
              </span>
              <span style={{ marginLeft: 5, fontSize: 12, color: "black" }}>
                {brand.imageType.toUpperCase()}
              </span>
            </div>

            {/* <div
                className="mx-auto h-24 w-24"
                dangerouslySetInnerHTML={{ __html: brand.image }}
              /> */}
            <button
              onClick={(e) => handleDelete(brand.name, e)}
              disabled={deleting === brand.name}
              className="rounded bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-600 disabled:opacity-50"
              style={{
                borderRadius: 5,
                backgroundColor: "red",
                padding: 5,
                fontSize: 12,
                color: "white",
              }}
            >
              {deleting === brand.name ? "Deleting..." : "Delete"}
            </button>
            <BrandImage brand={brand} />
          </div>
        ))}
      </div>

      {selectedBrand && (
        <div className="mt-4 text-center text-green-600">
          Selected brand: {selectedBrand}
        </div>
      )}

      {/* Upload Form */}
      <div className="mt-8 border-t pt-4">
        <h2 className="mb-4 text-lg font-semibold">Upload New Brand</h2>
        <form onSubmit={handleUpload} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Brand Name
            </label>
            <input
              type="text"
              value={newBrandName}
              onChange={(e) => setNewBrandName(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none focus:ring-blue-500"
              disabled={uploading}
              placeholder="Enter brand name"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Brand Image (PNG, JPG, or SVG)
            </label>
            <input
              type="file"
              accept=".png,.jpg,.jpeg,.svg"
              onChange={handleFileSelect}
              className="mt-1 block w-full"
              disabled={uploading}
            />
          </div>

          {uploadError && (
            <div className="text-sm text-red-600">{uploadError}</div>
          )}

          <button
            type="submit"
            disabled={uploading || !newBrandName || !selectedFile}
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:opacity-50"
          >
            {uploading ? "Uploading..." : "Upload Brand"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateBrandPage;
