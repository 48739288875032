import * as React from "react";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { cn } from "utils/misc";

export type ScrollAreaRef = {
  scrollDown: (amount: number) => void;
  scrollToBottom: () => void;
  isAtBottom: () => boolean;
  getCurrentScrollPosition: () => number;
};

type ScrollEventHandler = (
  event: React.UIEvent<HTMLDivElement>,
  isAtBottom: boolean,
) => void;

type ScrollAreaProps = Omit<
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root>,
  "onScroll"
> & {
  onScroll?: ScrollEventHandler;
};

const ScrollArea = React.forwardRef<ScrollAreaRef, ScrollAreaProps>(
  ({ className, children, onScroll, ...props }, forwardedRef) => {
    const viewportRef = React.useRef<HTMLDivElement>(null);
    const rootRef = React.useRef<HTMLDivElement>(null);
    const prevScrollHeightRef = React.useRef<number>(0);

    const isAtBottom = () => {
      if (viewportRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = viewportRef.current;
        const scrollBottom = scrollTop + clientHeight;

        if (scrollHeight < prevScrollHeightRef.current) {
          return true;
        }

        const threshold = 30;
        return scrollHeight - scrollBottom <= threshold;
      }
      return false;
    };

    const getCurrentScrollPosition = () => {
      if (viewportRef.current) {
        return viewportRef.current.scrollTop;
      }
      return 0;
    };

    const scrollDown = (amount: number) => {
      if (viewportRef.current) {
        viewportRef.current.style.scrollBehavior = "smooth";
        viewportRef.current.scrollTop += amount;
        viewportRef.current.style.scrollBehavior = "auto";
      }
    };

    const scrollToBottom = () => {
      if (viewportRef.current) {
        viewportRef.current.style.scrollBehavior = "smooth";
        viewportRef.current.scrollTop = viewportRef.current.scrollHeight;
        viewportRef.current.style.scrollBehavior = "auto";
      }
    };

    const handleScroll = React.useCallback(
      (event: React.UIEvent<HTMLDivElement>) => {
        if (onScroll) {
          onScroll(event, isAtBottom());
        }
      },
      [onScroll],
    );

    React.useImperativeHandle(forwardedRef, () => ({
      scrollDown,
      scrollToBottom,
      isAtBottom,
      getCurrentScrollPosition,
    }));

    return (
      <ScrollAreaPrimitive.Root
        ref={rootRef}
        className={cn("relative overflow-hidden", className)}
        {...props}
      >
        <ScrollAreaPrimitive.Viewport
          ref={viewportRef}
          className="h-full w-full rounded-[inherit]"
          onScroll={handleScroll}
        >
          {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar />
        <ScrollAreaPrimitive.Corner />
      </ScrollAreaPrimitive.Root>
    );
  },
);

ScrollArea.displayName = "ScrollArea";

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = "vertical", ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      "flex touch-none select-none transition-colors",
      orientation === "vertical" &&
        "h-full w-2.5 border-l border-l-transparent p-[1px]",
      orientation === "horizontal" &&
        "h-2.5 flex-col border-t border-t-transparent p-[1px]",
      className,
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className="relative flex-1 rounded-full bg-zinc-200 dark:bg-zinc-800" />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));

ScrollBar.displayName = "ScrollBar";

export type { ScrollAreaProps };
export { ScrollArea, ScrollBar };
