import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import { Inputs } from "shared/components/hoc/Inputs";
import { Labels } from "shared/components/hoc/Labels";
import PhoneVerificationService from "shared/services/phone_verification/PhoneVerificationService";

import { LetUsKnowYouColumn } from "./components/LetUsKnowYouColumn";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingPartProps } from "./OnboardingFlow";
import useOnboardingStore from "shared/store/onboardingStore";
import useAccountStore from "shared/store/accountStore";

export const OneTimePhoneVerificationPassword: React.FC<
  OnboardingPartProps
> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const phoneNumber = useOnboardingStore((state) => state.phoneNumber);
  const { fetchAccount } = useAccountStore();
  const [resend, setResend] = useState(false);

  const nextHandler = () => {
    onNext && onNext();
  };

  const prevHandler = () => {
    onPrevious && onPrevious();
  };

  const resendPhoneVerification = async () => {
    if (!phoneNumber || resend) return;
    await PhoneVerificationService.sendSmsVerification(phoneNumber);
    setResend(true);
  };
  const submitOneTimePassword = async () => {
    setSubmitting(true);
    if (!phoneNumber) return;
    try {
      await PhoneVerificationService.checkVerification(phoneNumber, code);
      await fetchAccount();
      nextHandler();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <OnboardingContainer
      leftColumnContent={<LetUsKnowYouColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1">
            <Labels.H2 className="mb-2">{t("webOnboarding.verify")}</Labels.H2>
            <Labels.B1 className="inline-flex flex-wrap">
              <Labels.B1>{t("webOnboarding.weSend")}</Labels.B1>
              <Labels.B1 className="mx-1">{phoneNumber}</Labels.B1>
              <Labels.B1>{t("webOnboarding.sixDigits")}</Labels.B1>
            </Labels.B1>
            <Labels.B1 className="mb-6">
              {t("webOnboarding.pleaseEnter")}
            </Labels.B1>
            <div className="flex justify-start">
              <Inputs.Text
                inputMode="numeric"
                value={code}
                inputClassName="pb-5 px-5"
                className="mb-6"
                placeholder="XXXXXX"
                autoCapitalize="none"
                onChange={setCode}
              />
            </div>
            <Labels.B1 className="inline-flex flex-wrap">
              <Labels.B1>{t("webOnboarding.receiveIt")}</Labels.B1>
              <div onClick={resendPhoneVerification}>
                <Labels.B1 className="ml-1 cursor-pointer underline">
                  {t("webOnboarding.resendCode")}
                </Labels.B1>
              </div>
            </Labels.B1>
          </div>
          <OnboardingFooter
            loading={submitting}
            onNext={submitOneTimePassword}
            onPrev={prevHandler}
            nextDisabled={!code}
          />
        </div>
      }
    />
  );
};
