import React from "react";
import PortfolioConfigData from "config/portfolio-config.json";

const DistributionTable = ({ dataObject }) => {
  if (!dataObject.data) {
    return null;
  }
  const total = dataObject.data.reduce((sum, row) => sum + row.weight, 0);
  const paletteArray =
    PortfolioConfigData.palettes[dataObject.configData.palette];
  return (
    <div className="table-container">
      <table className="distribution-table">
        <thead>
          <tr>
            <th>{dataObject.configData.label_table_left}</th>
            <th>{dataObject.configData.label_table_right}</th>
          </tr>
        </thead>
        <tbody>
          {dataObject.data.map((row, index) => {
            const colorIndex = index % paletteArray.length;
            const displayLabel = dataObject.configData.rows
              ? dataObject.configData.rows[row.label] || row.label
              : row.label;
            return (
              <tr key={index}>
                <td>
                  {dataObject.configData.type !== "table_simple" && (
                    <span
                      className="color-box"
                      style={{ backgroundColor: paletteArray[colorIndex] }}
                    />
                  )}

                  {displayLabel}
                </td>
                <td>
                  {row.label === "sharpe"
                    ? row.weight.toFixed(3)
                    : row.weight.toFixed(2)}
                </td>
              </tr>
            );
          })}
          {dataObject.configData.type !== "table_simple" && (
            <tr className="total-row">
              <td>Totale</td>
              <td>{total.toFixed(2)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DistributionTable;
