import { ClientHeader } from "modules/client_header/ClientHeader";
import { LatestUpdates } from "modules/latest_updates/LatestUpdates";
import { PortfolioNews } from "modules/news/PortfolioNews";
import { PortfolioBacktestingChart } from "modules/portfolio_backtesting_chart/PortfolioBacktestingChart";
import { PortfolioComposition } from "modules/portfolio_composition/PortfolioComposition";
import { PortfolioProjectionChart } from "modules/portfolio_projection_chart/PortfolioProjectionChart";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AvatarImage } from "shared/components/common/AvatarImage";
import { BackButton } from "shared/components/common/BackButton";
import { BasePage } from "shared/components/common/BasePage";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import {
  PortfolioSimulatedMetrics,
  PortfolioSimulationPeriod,
} from "shared/models/portfolio/PortfolioModel";
import { PortfoliosService } from "shared/services/portfolios/PortfoliosService";
import { EditMenu } from "../proposals/update_proposal/ProposalEditMenu";
import { SelectClientModal } from "./SelectClientModal";
import usePortfoliosStore from "shared/store/portfoliosStore";
import useProposalsStore from "shared/store/proposalsStore";
import useClientsStore from "shared/store/clientsStore";
import RebalanceDropdown from "./RebalanceDropdown";

function removeNonAlphanumeric(str: string) {
  return str.replace(/[^a-zA-Z0-9]/g, "");
}

export const FaPortfolioPage: React.FC = () => {
  const navigate = useNavigate();
  const { portfolioId } = useParams();
  const portfolio = usePortfoliosStore((state) =>
    state.portfolios.find((el) => el.id === portfolioId),
  );
  const { proposals, fetchProposals } = useProposalsStore();
  const { clients, fetchClients } = useClientsStore();
  const { t } = useTranslation();
  const { fetchPortfolioById } = usePortfoliosStore();

  const [showModal, setShowModal] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [strategySymbol, setStrategySymbol] = useState(portfolio?.symbol || "");
  const [strategyTitle, setStrategyTitle] = useState(portfolio?.title || "");
  const [strategyDescription, setStrategyDescription] = useState(
    portfolio?.description || "",
  );

  const proposalClientId = portfolio?.origin?.proposal_id
    ? proposals.find((el) => el.proposal_id === portfolio.origin.proposal_id)
        ?.client_id
    : undefined;
  const proposalClient = proposalClientId
    ? clients.find((el) => String(el.user_id) === String(proposalClientId))
    : undefined;

  const isSymbolValid = () => {
    return strategySymbol.length >= 4;
  };

  const isTitleValid = () => {
    return strategyTitle.length >= 10;
  };

  const isDescriptionValid = () => {
    return strategyDescription.length >= 10;
  };

  const onSaveClicked = () => {
    if (portfolioId) {
      setSaveLoading(true);
      PortfoliosService.editTexts(portfolioId, {
        symbol: strategySymbol,
        title: strategyTitle,
        description: strategyDescription,
      })
        .then(() => {
          fetchPortfolioById(portfolioId);
          setEditing(false);
          setSaveLoading(false);
          toast.success(t("faPortfolio.portfolioUpdated"));
        })
        .finally(() => setSaveLoading(false));
    }
  };

  const getError = () => {
    if (!isSymbolValid() || !isTitleValid() || !isDescriptionValid())
      return t("faPortfolio.validationErrors");
    return undefined;
  };

  useEffect(() => {
    if (portfolioId) {
      fetchPortfolioById(portfolioId);
      fetchClients();
      fetchProposals();
    }
  }, [portfolioId]);

  useEffect(() => {
    setStrategySymbol(portfolio?.symbol || "");
    setStrategyTitle(portfolio?.title || "");
    setStrategyDescription(portfolio?.description || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  const getPortfolioMetric = (
    period: PortfolioSimulationPeriod,
    metricName: keyof PortfolioSimulatedMetrics,
  ): number => {
    if (!portfolio && !period) {
      return 0;
    }

    if (!portfolio?.simulated_metrics) return 0;
    const metric = portfolio?.simulated_metrics[period][metricName];
    return +metric || 0;
  };

  return (
    <BasePage globalSearch>
      {/* NAME */}
      <div>
        {portfolioId && (
          <SelectClientModal
            portfolioId={portfolioId}
            open={showModal}
            setOpen={setShowModal}
          />
        )}
        <div className="relative flex flex-row items-center">
          {saveLoading && (
            <div className="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50" />
          )}

          <AvatarImage
            className="mr-6"
            imageSrc={portfolio?.cover?.uri}
            width={52}
            height={52}
          />
          <div className="w-full">
            <Labels.H1 className="flex w-full items-center gap-4">
              {editing ? (
                <input
                  type="text"
                  value={strategyTitle}
                  className="mb-2 w-full rounded-lg border border-sb-gray-300 px-2 outline-none"
                  onChange={(e) => setStrategyTitle(e.target.value)}
                  required
                />
              ) : (
                portfolio?.title
              )}

              {portfolioId && portfolio?.source !== "streetbeat" ? (
                <EditMenu
                  editing={editing}
                  setEditing={setEditing}
                  onSaveClicked={onSaveClicked}
                  error={getError}
                  className="ml-2"
                  hideErrorMessage
                />
              ) : null}
            </Labels.H1>
            <div className="flex items-center">
              <div className="mr-2 text-lg text-sb-gray-500">
                {editing ? (
                  <input
                    type="text"
                    value={strategySymbol}
                    className="rounded-lg border border-sb-gray-300 px-2 outline-none"
                    onChange={(e) =>
                      setStrategySymbol(
                        removeNonAlphanumeric(
                          (e.target.value || "").toUpperCase(),
                        ),
                      )
                    }
                    required
                  />
                ) : (
                  portfolio?.symbol
                )}
              </div>
              <div className="flex items-center gap-4">
                <div className="text-lg font-semibold text-sb-green-700">
                  {(getPortfolioMetric("all_time", "cagr") * 100).toFixed(2) +
                    "% CAGR"}
                </div>
                {editing && (
                  <div className="text-sb-red-700">
                    {!isTitleValid() && (
                      <div>{t("faPortfolio.invalidTitle")}</div>
                    )}
                    {!isSymbolValid() && (
                      <div>{t("faPortfolio.invalidSymbol")}</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <BackButton />
      </div>
      <div className="my-8 flex items-center gap-4">
        {/* <Button label="faPortfolio.rebalance" /> */}
        <RebalanceDropdown />
        <Button
          label="faPortfolio.assignToClient"
          onClick={() => setShowModal(true)}
        />
        {portfolio?.origin.proposal_id && (
          <Button
            label="faPortfolio.gotoProposal"
            onClick={() =>
              navigate(`/proposals/${portfolio?.origin.proposal_id}`)
            }
          />
        )}
      </div>
      <Layouts.Spaced>
        {proposalClient && (
          <Panel>
            <ClientHeader client={proposalClient} />
          </Panel>
        )}
        <Panel>
          <Labels.H3>{t("global.description")}</Labels.H3>
          {!editing && (
            <div className="text-base">{portfolio?.description}</div>
          )}

          {editing && (
            <>
              <textarea
                placeholder={t("portfolios.form.description")}
                value={strategyDescription}
                onChange={(e) => setStrategyDescription(e.target.value)}
                className="h-32 min-h-32 w-full overflow-hidden rounded-xl border border-sb-gray-200 px-4 py-2 focus:border-sb-green-700 focus:outline-none"
              />
              {!isDescriptionValid() && (
                <p className="mb-8 text-sm text-sb-red-600">
                  {t("faPortfolio.invalidDescription")}
                </p>
              )}
            </>
          )}
        </Panel>
        <Panel className="mt-8">
          <div className="relative">
            <div className="mb-8 flex w-full items-center justify-between font-semibold">
              <Labels.H3>{t("global.portfolio")}</Labels.H3>
            </div>
            <PortfolioComposition portfolio={portfolio} />
          </div>
        </Panel>
        <PortfolioBacktestingChart portfolio={portfolio} />
        <PortfolioProjectionChart
          portfolioId={portfolio?.id}
          frequency={"month"}
          recurringAmount={"1000"}
          duration={240}
          initialAmount={"10000"}
        />
        {portfolio?.id && <LatestUpdates portfolioId={portfolio?.id} />}
        <PortfolioNews portfolioId={portfolio?.id} />
      </Layouts.Spaced>
    </BasePage>
  );
};
