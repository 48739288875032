import { ManualTrading } from "modules/client_portfolios/ManualTrading";
import { PortfolioSubscription } from "modules/client_portfolios/PortfolioSubscription";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import useClientDetailsStore from "shared/store/clientDetailsStore";
import { Loading } from "shared/types/enums";
import { useCallback } from "react";

type Props = {
  clientId?: string;
};

export const ClientPortfolioSubscriptions: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();

  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(null);

  const { fetchPortfolios } = useClientDetailsStore();
  const { clientDetails } = useClientDetailsStore();

  // const clientDetails = useClientDetailsStore(
  //   (state) =>
  //     state.clientDetails[clientId || ""] || {
  //       portfolios: [],
  //       portfoliosLoading: Loading.Idle,
  //     },
  // );
  // const { portfolios, portfoliosLoading: loading } = clientDetails;

  // const portfolios = clientDetails[clientId].portfolios;
  // const portfoliosLoading = clientDetails[clientId].portfoliosLoading;
  // // @ts-ignore
  // const { portfolios, portfoliosLoading: loading } = useClientDetailsStore(
  //   (state) => state.clientDetails[clientId || ""],
  // );
  useEffect(() => {
    if (clientDetails[clientId]) {
      setPortfolios(clientDetails[clientId].portfolios);
      setLoading(clientDetails[clientId].portfoliosLoading);
    }
  }, [clientDetails, setPortfolios, setLoading, clientId]);

  useEffect(() => {
    if (clientId) {
      fetchPortfolios(clientId);
    }
  }, [clientId, fetchPortfolios]);

  return (
    <Panel>
      <Labels.H3>{t("clientDetails.portfolios")}</Labels.H3>
      <Layouts.Spaced className="mt-8">
        {portfolios?.map((item, idx) => {
          return (
            <PortfolioSubscription
              key={idx}
              portfolio={item}
              clientId={clientId}
            />
          );
        })}
        {clientId && <ManualTrading clientId={clientId} />}
        {loading === Loading.Finished && !portfolios.length ? (
          <Labels.G1>{t("portfolios.noPortfolios")}</Labels.G1>
        ) : null}
      </Layouts.Spaced>
    </Panel>
  );
};
