import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import OrderRow from "./OrderRow";
import { useAccount } from "shared/hooks/useAccount";
import { useNavigate } from "react-router-dom";
import useClientDetailsStore from "shared/store/clientDetailsStore";
import { TradingOrder } from "shared/models/trading/TradingOrderModel";

type Props = {
  clientId?: string;
};

export const ClientOrders: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const { account } = useAccount();

  const clientDetails = useClientDetailsStore(
    (state) =>
      state.clientDetails[clientId || ""] || {
        orders: [],
      },
  );

  const { orders } = clientDetails;
  const { fetchOrders } = useClientDetailsStore();

  const setupInterval = useCallback(() => {
    let refreshTimeout: NodeJS.Timeout | null = null;

    const refresh = async () => {
      if (clientId) {
        await fetchOrders(clientId, limit);
      }
      refreshTimeout = setTimeout(refresh, 5000);
    };

    refresh();

    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    };
  }, [clientId, limit, fetchOrders]);

  useEffect(() => {
    const clearInterval = setupInterval();
    return () => clearInterval();
  }, [setupInterval]);

  const handleSeeMore = () => {
    setLimit((prevLimit) => prevLimit + 10);
  };

  return (
    <Panel>
      <div className="flex justify-between gap-4">
        <Labels.H3>{t("orders.title")}</Labels.H3>
        <Button
          label="orders.buy"
          onClick={() => navigate(`/trade?clientId=${clientId}`)}
        />
      </div>

      {!orders?.length ? (
        <Labels.G1 className="mt-4">{t("orders.noOrders")}</Labels.G1>
      ) : (
        <div className="mt-8">
          {orders.map((order: TradingOrder, idx: number) => (
            <div key={`order_${order.id || idx}`}>
              <OrderRow order={order} clientId={clientId} />
            </div>
          ))}
        </div>
      )}

      {orders && limit <= orders.length && (
        <div
          className="mt-8 cursor-pointer text-base text-sb-gray-500 underline"
          onClick={handleSeeMore}
        >
          {t("global.seeMore")}
        </div>
      )}
    </Panel>
  );
};
