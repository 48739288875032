import React from "react";
import { getInitials } from "utils/misc";
import clientDetailsStyles from "./../../clients/crmClientDetailsPage.module.css";
import { useNavigate } from "react-router-dom";

// @ts-ignore
export const Participants = ({ participants = [], onShowParticipants }) => {
  const navigate = useNavigate();

  const parts = [...participants];

  const visibleParticipants = parts.slice(0, 3);
  const remainingCount = parts.length - 3;

  return (
    <div className={clientDetailsStyles.participants}>
      {visibleParticipants.map((participant: any, index: any) => (
        <div key={index} className={clientDetailsStyles.participant}>
          <div
            onClick={() => navigate(`/crm/clients/${participant.client_id}`)}
            className={clientDetailsStyles.participantAvatar}
          >
            {getInitials(participant.first_name, participant.last_name)}
          </div>
          <div className={clientDetailsStyles.participantTooltip}>
            {participant.first_name} {participant.last_name}
          </div>
        </div>
      ))}

      {remainingCount > 0 && (
        <div className={clientDetailsStyles.participant}>
          <div
            className={clientDetailsStyles.participantAvatar}
            onClick={() => onShowParticipants(parts)}
          >
            +{remainingCount}
          </div>
        </div>
      )}
    </div>
  );
};
