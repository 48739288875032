import remarkGfm from "remark-gfm";
import ReactMarkdown, { defaultUrlTransform } from "react-markdown";
import { PrismCode } from "react-prism";
import { AssistantMessage } from "types/assistant/AssistantTypes";
import useThreadsStore from "shared/store/threadsStore";
import { useParams } from "react-router-dom";
import { AssistantService } from "shared/services/AssistantService";
import { useState } from "react";
import classNames from "classnames";
import styles from "./../../chat.module.css";

import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import { TableWrapper } from "./TableWrapper";
import colorsStore from "shared/store/colorsStore";

type Props = {
  message: AssistantMessage | string;
};

// Regex to check if the processed content contains any potential LaTeX patterns
const containsLatexRegex =
  /\\\(.*?\\\)|\\\[.*?\\\]|\$.*?\$|\\begin\{equation\}.*?\\end\{equation\}/;
// Regex for inline and block LaTeX expressions
const inlineLatex = new RegExp(/\\\((.+?)\\\)/, "g");
const blockLatex = new RegExp(/\\\[(.*?[^\\])\\\]/, "gs");
// Function to restore code blocks
const restoreCodeBlocks = (content: string, codeBlocks: string[]) => {
  return content.replace(
    /<<CODE_BLOCK_(\d+)>>/g,
    (match, index) => codeBlocks[index],
  );
};
// Regex to identify code blocks and inline code
const codeBlockRegex = /(```[\s\S]*?```|`.*?`)/g;
export const processLaTeX = (_content: string) => {
  let content = _content;
  // Temporarily replace code blocks and inline code with placeholders
  const codeBlocks: string[] = [];
  let index = 0;
  content = content.replace(codeBlockRegex, (match) => {
    codeBlocks[index] = match;
    return `<<CODE_BLOCK_${index++}>>`;
  });
  // Escape dollar signs followed by a digit or space and digit
  let processedContent = content.replace(/(\$)(?=\s?\d)/g, "\\$");
  // If no LaTeX patterns are found, restore code blocks and return the processed content
  if (!containsLatexRegex.test(processedContent)) {
    return restoreCodeBlocks(processedContent, codeBlocks);
  }
  // Convert LaTeX expressions to a markdown compatible format
  processedContent = processedContent
    .replace(inlineLatex, (match: string, equation: string) => `$${equation}$`) // Convert inline LaTeX
    .replace(
      blockLatex,
      (match: string, equation: string) => `$$${equation}$$`,
    ); // Convert block LaTeX
  // Restore code blocks
  return restoreCodeBlocks(processedContent, codeBlocks);
};
/**
 * Preprocesses LaTeX content by replacing delimiters and escaping certain characters.
 *
 * @param content The input string containing LaTeX expressions.
 * @returns The processed string with replaced delimiters and escaped characters.
 */
export function preprocessLaTeX(content: string): string {
  // Step 1: Protect code blocks
  const codeBlocks: string[] = [];
  content = content.replace(/(```[\s\S]*?```|`[^`\n]+`)/g, (match, code) => {
    codeBlocks.push(code);
    return `<<CODE_BLOCK_${codeBlocks.length - 1}>>`;
  });
  // Step 2: Protect existing LaTeX expressions
  const latexExpressions: string[] = [];
  content = content.replace(
    /(\$\$[\s\S]*?\$\$|\\\[[\s\S]*?\\\]|\\\(.*?\\\))/g,
    (match) => {
      latexExpressions.push(match);
      return `<<LATEX_${latexExpressions.length - 1}>>`;
    },
  );
  // Step 3: Escape dollar signs that are likely currency indicators
  content = content.replace(/\$(?=\d)/g, "\\$");
  // Step 4: Restore LaTeX expressions
  content = content.replace(
    /<<LATEX_(\d+)>>/g,
    (_, index) => latexExpressions[parseInt(index)],
  );
  // Step 5: Restore code blocks
  content = content.replace(
    /<<CODE_BLOCK_(\d+)>>/g,
    (_, index) => codeBlocks[parseInt(index)],
  );
  // Step 6: Apply additional escaping functions
  content = escapeBrackets(content);
  content = escapeMhchem(content);
  return content;
}
export function escapeBrackets(text: string): string {
  const pattern =
    /(```[\S\s]*?```|`.*?`)|\\\[([\S\s]*?[^\\])\\]|\\\((.*?)\\\)/g;
  return text.replace(
    pattern,
    (
      match: string,
      codeBlock: string | undefined,
      squareBracket: string | undefined,
      roundBracket: string | undefined,
    ): string => {
      if (codeBlock != null) {
        return codeBlock;
      } else if (squareBracket != null) {
        return `$$${squareBracket}$$`;
      } else if (roundBracket != null) {
        return `$${roundBracket}$`;
      }
      return match;
    },
  );
}
export function escapeMhchem(text: string) {
  return text.replaceAll("$\\ce{", "$\\\\ce{").replaceAll("$\\pu{", "$\\\\pu{");
}
export const TextResponse: React.FC<Props> = ({ message }) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const textContent =
    typeof message === "string" ? message : message?.content?.[0]?.text?.value;
  const { threadId } = useParams();
  const attachments = useThreadsStore((state) => state.attachments[threadId]);
  const [urls, setUrls] = useState<Record<string, string>>({});

  const latexSafeContent = preprocessLaTeX(textContent);

  const renderers = {
    a: ({ href, children }) => {
      const url = urls[href];
      if (!url) return null;
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
          download={href.replace("sandbox:/home/user/artifacts/", "")}
          style={{ color: colorsConfig.main }}
        >
          {children}
        </a>
      );
    },
    // table(props: any) {
    //   const { node, ...rest } = props;
    //   return (
    //     <div style={{ width: 400, height: "auto" }}>
    //       <div className="table-wrapper" {...rest}>
    //         <table>{props.children}</table>
    //       </div>
    //     </div>
    //   );
    // },
    table: ({ children }) => <TableWrapper>{children}</TableWrapper>,
    pre(props: any) {
      const className = props.node.children?.[0]?.properties?.className?.[0];
      return (
        <PrismCode
          component="pre"
          className={"rounded-xl " + className}
          {...props.attributes}
        >
          {props.children}
        </PrismCode>
      );
    },
    img(props: any) {
      return (
        <div className="my-8 flex justify-center">
          <img {...props} />
        </div>
      );
    },
    p: ({ node, ...props }) => <p className={styles.chatPCopy} {...props} />,
    // li: ({ node, ...props }) => (
    //   <li style={{ color: colorsConfig.chatCopyColor }} {...props} />
    // // ),
    h1: ({ node, ...props }) => (
      <h1 style={{ color: colorsConfig.chatCopyColor }} {...props} />
    ),
    h2: ({ node, ...props }) => (
      // @ts-ignore
      <h2 style={{ color: colorsConfig.chatCopyColor }} {...props} />
    ),
    h3: ({ node, ...props }) => (
      // @ts-ignore
      <h3 style={{ color: colorsConfig.chatCopyColor }} {...props} />
    ),
    strong: ({ node, ...props }) => (
      // @ts-ignore
      <strong style={{ color: colorsConfig.black }} {...props} />
    ),
  };

  const sandboxUrlTransform = (url: string) => {
    if (url.startsWith("sandbox:/home/user/artifacts")) {
      const artifactName = url.replace("sandbox:/home/user/artifacts/", "");
      const attachment = attachments?.find(
        (attachment) => attachment.attachment_name === artifactName,
      );
      if (attachment && !urls[url]) {
        AssistantService.downloadAttachment(
          threadId,
          attachment.attachment_id,
        ).then((response) => {
          const resolvedUrl = URL.createObjectURL(response.data);
          setUrls({ ...urls, [url]: resolvedUrl });
        });
      }
      return url;
    } else {
      return defaultUrlTransform(url);
    }
  };

  return (
    <div className={styles.reactMarkdownContainer}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeKatex]}
        components={renderers}
        // className="reactMarkdown mt-2 lg:text-base"
        className={classNames(styles.reactMarkdown, styles.chatMainCopy)}
        urlTransform={sandboxUrlTransform}
      >
        {latexSafeContent}
      </ReactMarkdown>
    </div>
  );
};
