import React from "react";
import { useEffect, useState } from "react";
import WebsocketService from "./WebsocketService";

type Props = {
  url: string;
  onReady?: () => void;
  children?: (socket?: WebSocket) => JSX.Element | null;
};

export const WebsocketProvider: React.FC<Props> = ({
  url,
  onReady,
  children,
}) => {
  const [socket, setSocket] = useState<WebSocket>();

  const connectToSocket = () => {
    WebsocketService.createWebsocketInstance(url, onReady).then(setSocket);
  };

  useEffect(() => {
    connectToSocket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.onclose = () => {};
        socket.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.onclose = () => {
        setTimeout(() => {
          connectToSocket();
        }, 1000);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return <>{children?.(socket)}</>;
};
