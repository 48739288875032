import React, { useState } from "react";

import { OnboardingPartProps } from "./OnboardingFlow";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { TermsCheckbox } from "./components/TermsCheckbox";
import { useAccount } from "shared/hooks/useAccount";
import { convertFullName } from "shared/utils/converts";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import { EvivaLogo } from "shared/icons/EvivaLogo";
import { Labels } from "shared/components/hoc/Labels";
import { AgreementCheck } from "shared/icons/AgreementCheck";
import { Inputs } from "shared/components/hoc/Inputs";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { useNavigate } from "react-router-dom";
import useOnboardingStore from "shared/store/onboardingStore";
import { AccountService } from "shared/services/account/AccountService";
import { FaOnboardingService } from "shared/services/fa_onboarding/FaOnboardingService";

export const Signature: React.FC<OnboardingPartProps> = ({ onPrevious }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const KYCData = useOnboardingStore((state) => state.data);
  const { account } = useAccount();

  const custom_agreements_to_sign = account?.custom_agreements_to_sign || [];

  const { firstName, middleName, lastName } = KYCData;

  const [agreementTerms, setAgreementTerms] = useState(false);
  const [signature, setSignature] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const fullName = convertFullName([firstName, middleName, lastName]);

  const submitTraderApplication = async () => {
    setSubmitting(true);
    try {
      if (custom_agreements_to_sign.length) {
        AccountService.postCustomAgreements(custom_agreements_to_sign);
      }

      await FaOnboardingService.post(KYCData, signature);
      navigate("/");
    } catch (error) {
      const errorMessage = (error as Error)?.message;
      toast.error(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  const canSubmit = !!signature.trim() && agreementTerms;
  return (
    <OnboardingContainer
      leftColumnContent={
        <div>
          <div className="rounded-3xl bg-black p-10">
            <EvivaLogo className="mb-20 w-[150px] text-white" />
            <Labels.H1 className="mb-4 text-4xl text-white">
              {t("webOnboarding.reviewConditions")}
            </Labels.H1>
            <Labels.B1 className="text-white">
              {t("webOnboarding.pleaseRead")}
            </Labels.B1>
            <AgreementCheck className="h-[250px] w-[250px]" />
          </div>
        </div>
      }
      rightColumnContent={
        <div className="mt-14 flex flex-col">
          <div className="flex-1">
            <Labels.H2 className="mb-2">
              {t("forms.kyc.section.signature")}
            </Labels.H2>
            <Labels.B1 className="mb-6">
              {t("forms.kyc.signature.description")}
            </Labels.B1>
            <Inputs.Text
              value={signature}
              label={t("forms.kyc.section.yourSignature", { fullName })}
              onChange={setSignature}
              className="mb-8"
            />
            <TermsCheckbox
              label="forms.kyc.signature.agreeToTerms"
              value={!!agreementTerms}
              labelClass="!text-base"
              onChange={(v) => setAgreementTerms(!v)}
            />
          </div>
          <OnboardingFooter
            loading={submitting}
            onNext={submitTraderApplication}
            onPrev={() => onPrevious && onPrevious()}
            nextDisabled={!canSubmit}
            nextLabel="forms.kyc.signAndSubmit"
          />
        </div>
      }
    />
  );
};
