import {
  AssistantArtifact,
  AssistantMessage,
} from "types/assistant/AssistantTypes";
import { PortfolioArtifact } from "pages/assistant/components/artifacts/components/portfolio-artifact/PortfolioArtifact";
import { FinecoPortfolioArtifact } from "pages/assistant/components/artifacts/components/fineco-portfolio-artifact/FinecoPortfolioArtifact";
import { FinecoCRMArtifact } from "pages/assistant/components/artifacts/components/fineco-crm-artifact/FinecoCRMArtifact";
import activityPanelStore from "shared/store/activityPanelStore";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import "./styles/styles.css";
import FinecoCRMPortfoliosArtifact from "./components/fineco-crm-portfolios-artifact/FinecoCRMPortfoliosArtifact";
import { FinecoPortfolioStoryArtifact } from "./components/fineco-portfolio-story-artifact/FinecoPortfolioStoryArtifact";
import { FinecoPortfolioComparisonArtifact } from "./components/fineco-portfolio-comparison-artifact/FinecoPortfolioComparisonArtifact";
import { FinecoProductComparisonArtifact } from "./components/fineco-product-comparison-artifact/FinecoProductComparisonArtifact";

type Props = {
  message: AssistantMessage;
  finecoPortfolioAndPortfolioStoryTogether: boolean;
  artifact: AssistantArtifact;
  onLoaded?: () => void;
  isConnectionOpen: boolean;
  onSend: (text: string) => void;
  disabled: boolean;
};

export const MessageArtifacts: React.FC<Props> = ({
  message,
  finecoPortfolioAndPortfolioStoryTogether,
  artifact,
  onLoaded,
  isConnectionOpen,
  onSend,
  disabled,
}) => {
  const params = useParams();
  const threadId = params.threadId;

  const { setFinecoPortfolioArtifact } = activityPanelStore();

  const type = artifact.type;
  useEffect(() => {
    if (type === "fineco_portfolio" && artifact.data) {
      setFinecoPortfolioArtifact(threadId, artifact);
    }
  }, [artifact, threadId, setFinecoPortfolioArtifact, type]);

  switch (true) {
    // ** standard portfolio
    case type === "portfolio" &&
      artifact.portfolio !== null &&
      artifact.portfolio !== undefined:
      return (
        <PortfolioArtifact portfolio={artifact.portfolio} onLoaded={onLoaded} />
      );
    // ** fineco portfolio
    case type === "fineco_portfolio" &&
      artifact.data !== null &&
      artifact.data !== undefined:
      return (
        <div style={{ width: "100%" }}>
          <div
            id={artifact.portfolio_id.toString()}
            className="fineco-portfolio-scroll-margin"
          ></div>
          <FinecoPortfolioArtifact
            message={message}
            finecoPortfolioAndPortfolioStoryTogether={
              finecoPortfolioAndPortfolioStoryTogether
            }
            artifact={artifact}
            onLoaded={onLoaded}
          />
        </div>
      );
    case type === "custom_data" &&
      artifact.version === "fineco_crm_client_portfolios" &&
      artifact.data !== null &&
      artifact.data !== undefined:
      return (
        <FinecoCRMPortfoliosArtifact data={artifact.data} onLoaded={onLoaded} />
      );
    case type === "custom_data" &&
      artifact.version === "fineco_crm" &&
      artifact.data !== null &&
      artifact.data !== undefined:
      //** fineco crm */
      return (
        <FinecoCRMArtifact
          data={artifact.data}
          onLoaded={onLoaded}
          isConnectionOpen={isConnectionOpen}
          onSend={onSend}
          disabled={disabled}
        />
      );
    case type === "custom_data" &&
      artifact.version === "portfolio_story" &&
      !finecoPortfolioAndPortfolioStoryTogether &&
      artifact.data !== null &&
      artifact.data !== undefined:
      return (
        <FinecoPortfolioStoryArtifact
          data={artifact.data}
          onLoaded={onLoaded}
        />
      );
    case type === "custom_data" &&
      artifact.version === "portfolio_comparison" &&
      artifact.data !== null &&
      artifact.data !== undefined:
      return (
        <FinecoPortfolioComparisonArtifact
          data={artifact.data}
          onLoaded={onLoaded}
        />
      );
    case type === "custom_data" &&
      artifact.version === "product_comparison" &&
      artifact.data !== null &&
      artifact.data !== undefined:
      return (
        <FinecoProductComparisonArtifact
          data={artifact.data}
          onLoaded={onLoaded}
        />
      );
    default:
      return null;
  }
};
