import useAccountStore from "shared/store/accountStore";
import { Loading } from "shared/types/enums";

export function useAccount() {
  const {
    account,
    accountLoading,
    pendingTasks: { tasks: pendingTasks, loading: pendingTasksLoading },
  } = useAccountStore();

  const finishedLoading =
    accountLoading === Loading.Finished ||
    (account && accountLoading === Loading.InProgress);
  const needsIbkrAuth =
    finishedLoading &&
    account &&
    ["N", "P"].includes(account.client.summary.kyc_status);

  const phoneIsVerified = false;
  const identity =
    account?.client.summary.alpaca_details?.brokerage_account?.identity;
  const contact =
    account?.client.summary.alpaca_details?.brokerage_account?.contact;

  const last_event =
    account?.client.summary.alpaca_details?.brokerage_account?.last_event;
  const documents =
    account?.client.summary.alpaca_details?.brokerage_account?.documents;
  const updated_at =
    account?.client.summary.alpaca_details?.brokerage_account?.updated_at;
  const custom_message =
    account?.client.summary.alpaca_details?.brokerage_account?.custom_message;

  return {
    account,
    identity,
    contact,
    last_event,
    documents,
    updated_at,
    custom_message,
    accountLoading,
    pendingTasks,
    pendingTasksLoading,
    needsIbkrAuth,
    phoneIsVerified,
  };
}
