import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Panel } from "shared/components/hoc/Panels";
import { useStrategyPeriods } from "shared/hooks/useStrategyPeriods";
import {
  PortfolioPerformanceChart,
  PortfolioSimulationPeriod,
} from "shared/models/portfolio/PortfolioModel";
import { PortfoliosService } from "shared/services/portfolios/PortfoliosService";
import useProAssistantStore from "shared/store/proAssistantStore";
import { CurrencyFormat } from "shared/utils/currency";
import { SessionPortfolioHeader } from "./SessionPortfolioHeader";
import PnlChart from "./artifacts/PnlChart";
import { ChartRanges } from "./ChartRanges";

type Props = {
  onClose: () => void;
  portfolioId: string;
  threadId: string;
};

export const ThreadActivePortfolio: React.FC<Props> = ({
  portfolioId,
  threadId,
}) => {
  const { t } = useTranslation();

  const portfolios = useProAssistantStore(
    (state) => state.portfolios[threadId || ""],
  );

  const [period, setPeriod] = useState<PortfolioSimulationPeriod>("all_time");
  const [chartData, setChartData] = useState<PortfolioPerformanceChart>([]);

  const portfolio = portfolios?.find((p) => p.id === portfolioId);
  const equity = portfolio?.subscription?.equity;
  const periods = useStrategyPeriods();

  useEffect(() => {
    if (portfolioId) {
      PortfoliosService.getPortfolioBacktestingChart(portfolioId, {
        period,
      }).then((data) => {
        setChartData(data);
      });
    }
  }, [portfolioId, period]);

  return (
    <>
      <Panel>
        <h3 className="mb-4">{t("proAssistant.activePortfolio")}</h3>
        <SessionPortfolioHeader
          portfolio={portfolio}
          sessionId={threadId}
          active
        />
        <div>
          <div className="mt-4">
            <div className="flex items-center gap-4">
              <div className="h-[226px] w-full">
                <PnlChart
                  chartData={chartData}
                  height={226}
                  domain={["dataMin", "dataMax"]}
                />
              </div>
            </div>
            <ChartRanges
              values={periods}
              activeValue={period}
              onClick={(v) => setPeriod(v)}
            />
          </div>
        </div>
        <div className="my-8 text-center text-sm text-gray-500 underline">
          <Link to={`/portfolios/${portfolio?.id}`}>
            {t("assistant.viewPortfolioDetails")}
          </Link>
        </div>
        {equity && (
          <div className="my-4 flex justify-between rounded-lg p-2">
            <span className="text-gray-500">{t("proposals.invested")}</span>
            <div className="font-semibold">
              {CurrencyFormat.format(portfolio?.subscription?.equity)}
            </div>
          </div>
        )}
      </Panel>
    </>
  );
};
