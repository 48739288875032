// ClientHeader.jsx
import React from "react";
import { MessageSquare, Mail, Phone } from "lucide-react";
import styles from "./clientHeader.module.css";
import { ClientDetailType } from "types/crm/ClientTypes";
import { GeneralInfo } from "./GeneralInfo";
import { ClientInterests } from "./ClientInterests";
import { FamilyWidget } from "./FamilyWidget";
import { LatestLead } from "./LatestLead";
import { getInitials } from "utils/misc";

type ClientHeaderProps = {
  clientData: ClientDetailType;
  activeTab: string;
  onTabChange: (tab: string) => void;
};

export const ClientHeader: React.FC<ClientHeaderProps> = ({
  clientData,
  activeTab,
  onTabChange,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.profileSection}>
        <div className={styles.avatarContainer}>
          <div className={styles.avatar}>
            <span className={styles.initials}>
              {getInitials(
                clientData.client_info.first_name,
                clientData.client_info.last_name,
              )}
            </span>
          </div>
          <h2 className={styles.name}>
            {clientData.client_info.first_name}{" "}
            {clientData.client_info.last_name}
          </h2>
          <div className={styles.actions}>
            {clientData?.client_info?.phone && (
              <button
                className={styles.actionButton}
                onClick={() =>
                  (window.location.href = `sms:${clientData.client_info.phone}`)
                }
              >
                <MessageSquare size={18} />
              </button>
            )}

            {clientData?.client_info?.email && (
              <button
                className={styles.actionButton}
                onClick={() =>
                  (window.location.href = `mailto:${clientData.client_info.email}`)
                }
              >
                <Mail size={18} />
              </button>
            )}

            {clientData?.client_info?.phone && (
              <button
                className={styles.actionButton}
                onClick={() =>
                  (window.location.href = `tel:${clientData.client_info.phone}`)
                }
              >
                <Phone size={18} />
              </button>
            )}
          </div>
        </div>
      </div>
      <GeneralInfo clientData={clientData} />
      <LatestLead
        clientData={clientData}
        activeTab={activeTab}
        onTabChange={onTabChange}
      />
      <ClientInterests clientData={clientData} />
      <FamilyWidget clientData={clientData} />
    </div>
  );
};
