import { Textarea } from "shared/components/ui/textarea";
import { SendIcon } from "lucide-react";
import { KeyboardEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  onSend?: (text: string) => void;
  disabled?: boolean;
};

const DELTA_HEIGHT = 22;
const BASE_HEIGHT = 42;

export const ChatInput: React.FC<Props> = ({ onSend, disabled }) => {
  const { t } = useTranslation();
  const [inputHeight, setInputHeight] = useState(BASE_HEIGHT);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const linesCount = message?.split("\n").length - 1;
    setInputHeight(BASE_HEIGHT + linesCount * DELTA_HEIGHT);
  }, [message]);

  const handleSend = () => {
    if (message) {
      onSend?.(message);
      setMessage("");
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // To prevent adding a new line to the end of a message
      handleSend();
    }
  };

  return (
    <div className="relative">
      <div
        className="absolute bottom-[10px] right-4 cursor-pointer text-gray-400 duration-150 hover:opacity-50"
        onClick={handleSend}
      >
        <SendIcon
          className={
            "size-6 " +
            (message ? "text-green-500" : "text-gray-500 dark:text-gray-700")
          }
        />
      </div>
      <Textarea
        value={message}
        onKeyDownCapture={handleKeyDown}
        onChange={(e) => setMessage(e.target.value)}
        placeholder={t("proAssistant.inputPlaceholder")}
        className="max-h-52 resize-none rounded-xl py-3 pr-8 text-base"
        style={{ height: inputHeight }}
        disabled={disabled}
      />
    </div>
  );
};
