import { DateTime, Info } from "luxon";

export const formatDate = (inputDate: string | number): string => {
  if (typeof inputDate == "number") {
    return DateTime.fromMillis(inputDate).toFormat("MMM dd, yyyy");
  }
  return DateTime.fromISO(inputDate).toFormat("MMM dd, yyyy ");
};

export const formatDateTime = (inputDate: string | number): string => {
  if (typeof inputDate == "number") {
    return DateTime.fromMillis(inputDate).toFormat("MMM dd, yyyy 'at' hh:mm a");
  }
  return DateTime.fromISO(inputDate).toFormat("MMM dd, yyyy 'at' hh:mm a");
};

export const MONTHS = Info.months("long");

const generateYearsFrom = (year: number) => {
  const years = [];
  for (let i = year; i <= DateTime.now().year; i++) {
    years.push("" + i);
  }
  return years;
};

export const YEARS = generateYearsFrom(2023);
