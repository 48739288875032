import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import { CopyIcon } from "shared/icons/CopyIcon";
import useClientDetailsStore from "shared/store/clientDetailsStore";
import { Loading } from "shared/types/enums";
import { useCopyToClipboard } from "usehooks-ts";

type Props = {
  clientId?: string;
};

export const ClientDepositInformation: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const [, copy] = useCopyToClipboard();

  const clientDetails = useClientDetailsStore(
    (state) =>
      state.clientDetails[clientId || ""] || {
        wireAccounts: {
          accounts: [],
          selectedCurrency: undefined,
        },
        wireAccountsLoading: Loading.Idle,
      },
  );
  const {
    wireAccounts: { accounts, selectedCurrency },
    wireAccountsLoading: loading,
  } = clientDetails;

  const { fetchWireAccounts } = useClientDetailsStore();

  const [currentCurrency, setCurrentCurrency] = useState(selectedCurrency);
  // @ts-ignore
  const allCurrencies = accounts.map((a) => a.currency);

  // @ts-ignore
  const account = accounts.find((a) => a.currency === selectedCurrency);

  useEffect(() => {
    if (clientId) {
      fetchWireAccounts(clientId);
    }
  }, [clientId]);

  const copyToClipboard = (text: string) => {
    copy(text);
    toast.success(t("global.copied"));
  };

  const wireData = [
    {
      title: t("deposit.accountHolderName"),
      value: account?.account_holder_name,
    },
    {
      title: t("deposit.accountNumber"),
      value: account?.account_number,
    },
    {
      title: t("deposit.address"),
      value: `${account?.account_holder_street} ${account?.account_holder_city}, ${account?.account_holder_state} ${account?.account_holder_postcode} ${account?.account_holder_country}`,
    },
    {
      title: t("deposit.bankCountry"),
      value: account?.bank_country,
    },
    {
      title: t("deposit.currency"),
      value: account?.currency,
    },
    {
      title:
        account?.routing_code_type === "bic_swift"
          ? t("deposit.bicSwift")
          : t("deposit.iban"),
      value: account?.routing_code,
    },
  ];

  if (account?.reference) {
    wireData.push({
      title: t("deposit.reference"),
      value: account?.reference,
    });
  }

  return (
    <Panel>
      <Labels.H2>{t("deposit.wire")}</Labels.H2>
      {accounts.length ? (
        <Panel className="mt-10 !bg-sb-gray-100">
          <div className="my-4">
            <select
              className="cursor-pointer rounded-xl border border-sb-gray-200 px-4 py-2 outline-none"
              value={currentCurrency}
              onChange={(e) => setCurrentCurrency(e.target.value)}
            >
              {
                // @ts-ignore
                allCurrencies.map((item, idx) => {
                  return <option key={idx}>{item}</option>;
                })
              }
            </select>
          </div>
          <div>
            {wireData.map((item, idx) => {
              return (
                <div
                  key={"wire_data_" + idx}
                  className="flex items-center justify-between rounded-lg p-2 duration-150 hover:bg-sb-gray-200"
                >
                  <div>
                    <div className="text-base text-sb-gray-500">
                      {item.title}
                    </div>
                    <div className="text-lg">{item.value}</div>
                  </div>
                  {item.value && (
                    <div
                      className="cursor-pointer"
                      onClick={() => item.value && copyToClipboard(item.value)}
                    >
                      <CopyIcon className="aspect-square w-6 text-sb-green-700" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <hr className="my-8" />
          <Layouts.Spaced>
            <div className="flex items-center gap-4">
              <NumberCircle text="1" />
              {t("deposit.step1")}
            </div>
            <div className="flex items-center gap-4">
              <NumberCircle text="2" />
              {t("deposit.step2")}
            </div>
            <div className="flex items-center gap-4">
              <NumberCircle text="3" />
              {t("deposit.step3")}
            </div>
          </Layouts.Spaced>
          <hr className="my-8" />
          <Layouts.Spaced>
            <div className="font-semibold">{t("deposit.disclaimer1")}</div>
            <div>{t("deposit.disclaimer2")}</div>
            <div>{t("deposit.disclaimer3")}</div>
          </Layouts.Spaced>
        </Panel>
      ) : null}
      {!accounts.length && loading === Loading.Finished && (
        <Labels.G1>{t("deposit.noAccounts")}</Labels.G1>
      )}
    </Panel>
  );
};

const NumberCircle: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className="grid aspect-square w-6 place-items-center rounded-full border border-black">
      {text}
    </div>
  );
};
