import RestService from "shared/api/RestService";
import {
  PlaidInvestmentsHolding,
  PlaidInvestmentsInstitution,
  PlaidInvestmentsTransactionsResponse,
} from "shared/models/plaid/PlaidInvestmentsModel";
import { FaApiRoutes } from "shared/utils/routes";
import { ISODate } from "shared/utils/types";

export interface CreatePlaidACHRelationshipRequest {
  publicToken: string;
  accountId: string;
}

export type PlaidInvestmentsTransactionsParams = {
  page?: number;
  page_size?: number;
  from_date?: ISODate;
  to_date?: ISODate;
  symbol?: string;
};

export const PlaidInvestmentsService = {
  async getInstitutions(
    clientId: string,
  ): Promise<PlaidInvestmentsInstitution[]> {
    const result = await RestService.get<PlaidInvestmentsInstitution[]>(
      FaApiRoutes.Advisor.Clients.Details(clientId).Investments.Institutions
        .Path,
    );
    return result;
  },

  async getInstitutionHoldings(
    clientId: string,
    institutionId: string,
  ): Promise<PlaidInvestmentsHolding[]> {
    const result = await RestService.get<PlaidInvestmentsHolding[]>(
      FaApiRoutes.Advisor.Clients.Details(
        clientId,
      ).Investments.Institutions.Details(institutionId).Positions,
    );
    return result;
  },

  async getInstitutionTransactions(
    clientId: string,
    institutionId: string,
    params?: PlaidInvestmentsTransactionsParams,
  ): Promise<PlaidInvestmentsTransactionsResponse> {
    const result = await RestService.get<PlaidInvestmentsTransactionsResponse>(
      FaApiRoutes.Advisor.Clients.Details(
        clientId,
      ).Investments.Institutions.Details(institutionId).Transactions,
      params,
    );
    return result;
  },
};
