import useThreadsStore from "shared/store/threadsStore";
import { DateRange, mapByDateRanges } from "utils/misc";
import { useTranslation } from "react-i18next";
import { HistoryItemType } from "types/assistant/AssistantTypes";
import { useEffect, useState } from "react";
import { ScrollArea } from "shared/components/ui/scroll-area";

import { useLocation } from "react-router-dom";
import { HistoryItem } from "./components/HistoryItem";
import { HeaderPanel } from "../HeaderPanel";
import { AssistantService } from "shared/services/AssistantService";
import colorsStore from "shared/store/colorsStore";

type Props = {
  expanded: boolean;
  setExpanded: (e: boolean) => void;
  sizeExpanded: number;
};

export const ChatHistoryPanel: React.FC<Props> = ({
  expanded,
  setExpanded,
  sizeExpanded,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const [deletedThreads, setDeletedThreads] = useState<string[]>([]);
  const threads = useThreadsStore((state) => state.threads);
  const busyThreads = useThreadsStore((state) => state.busyThreads);
  const removeBusyThread = useThreadsStore((state) => state.removeBusyThread);
  const mappedThreads = mapByDateRanges(
    threads.filter((t) => !deletedThreads.includes(t.thread_id)),
  );

  const updateDeletedThreads = (threadId: string) => {
    setDeletedThreads((prev) => [...prev, threadId]);
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const loc = useLocation();

  useEffect(() => {
    setDialogOpen(false);
  }, [loc.pathname]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (busyThreads.length > 0) {
      intervalId = setInterval(async () => {
        try {
          await Promise.all(
            busyThreads.map(async (thread) => {
              const threadInfo = (await AssistantService.getThreadInfo(
                thread.thread_id,
              )) as HistoryItemType;
              if (threadInfo.status === "idle") {
                removeBusyThread(thread);
              }
            }),
          );
        } catch (error) {
          console.error("Error checking thread status:", error);
        }
      }, 5000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [busyThreads, removeBusyThread]);

  const children = () => {
    return (
      <div className="h-full space-y-8">
        <ThreadList
          range="today"
          threads={mappedThreads}
          onThreadDelete={updateDeletedThreads}
          sizeExpanded={sizeExpanded}
        />
        <ThreadList
          range="yesterday"
          threads={mappedThreads}
          onThreadDelete={updateDeletedThreads}
          sizeExpanded={sizeExpanded}
        />
        <ThreadList
          range="lastMonth"
          threads={mappedThreads}
          onThreadDelete={updateDeletedThreads}
          sizeExpanded={sizeExpanded}
        />
        <ThreadList
          range="lastYear"
          threads={mappedThreads}
          onThreadDelete={updateDeletedThreads}
          sizeExpanded={sizeExpanded}
        />
        <ThreadList
          range="remaining"
          threads={mappedThreads}
          onThreadDelete={updateDeletedThreads}
          sizeExpanded={sizeExpanded}
        />
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: colorsConfig.grayLight,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderPanel
        expanded={expanded}
        setExpanded={setExpanded}
        title={"userMenu.threads"}
      />
      <ScrollArea
        className={"flex-2 relative max-h-full min-h-[60px] rounded-xl"}
        style={{
          marginLeft: 22,
          marginTop: 10,
        }}
      >
        {expanded && (
          <div
            className="relative space-y-4 text-xs lg:text-base"
            style={{ top: 10 }}
          >
            {children()}
          </div>
        )}
        {/* <div id="scrollId" className="pt-10" /> */}
      </ScrollArea>
    </div>
  );
};

type ThreadListProps = {
  range: DateRange;
  threads: Record<DateRange, HistoryItemType[]>;
  onThreadDelete?: (threadId: string) => void;
  sizeExpanded: number;
};

export const ThreadList: React.FC<ThreadListProps> = ({
  range,
  threads,
  onThreadDelete,
  sizeExpanded,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const { t } = useTranslation();
  const busyThreads = useThreadsStore((state) => state.busyThreads);
  if (!threads[range].length) return;

  const getStatusColor = (thread: HistoryItemType) =>
    busyThreads.some((busyThread) => busyThread.thread_id === thread.thread_id)
      ? "red"
      : "green";

  return (
    <div className="overflow-hidden text-ellipsis whitespace-nowrap">
      <div
        className="mb-2 text-xs font-bold"
        style={{
          fontSize: 12,
          color: colorsConfig.finecoBluePrimary,
        }}
      >
        {t(`assistant.dateRange.${range}`)}
      </div>
      {threads[range].map((thread) => (
        <HistoryItem
          key={thread.thread_id}
          thread={thread}
          onThreadDelete={onThreadDelete}
          sizeExpanded={sizeExpanded}
        />
      ))}
    </div>
  );
};
