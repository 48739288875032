import { ThumbsDownIcon, ThumbsUpIcon } from "lucide-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import "./styles.css";
import {
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogTitle,
} from "shared/components/ui/alert-dialog";
import { ProAssistantService } from "shared/services/pro_assistant/ProAssistantService";
import { cn } from "utils";

type Props = {
  messageId: string;
};

type FeedbackType = "positive" | "negative";

export const FeedbackButtons: React.FC<Props> = ({ messageId }) => {
  const { t } = useTranslation();
  const { threadId } = useParams();
  const [selected, setSelected] = useState<FeedbackType>();
  const [open, setOpen] = useState(false);
  const feedbackRef = useRef<HTMLTextAreaElement>(null);

  const sendFeedback = (selected: FeedbackType) => {
    if (threadId) {
      ProAssistantService.sendFeedback(threadId, {
        type: selected,
        message_id: messageId,
        explanation: feedbackRef.current?.value,
      });
    }
  };

  return (
    <div className="mt-2 flex items-center justify-end gap-2">
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t("aiAssistant.feedbackTitle")}
            </AlertDialogTitle>
          </AlertDialogHeader>
          <textarea
            ref={feedbackRef}
            className="h-[200px] w-full rounded-xl border border-gray-700 bg-transparent p-2 outline-none"
          />
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setSelected(undefined)}>
              {t("actions.cancel")}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                setSelected("negative");
                sendFeedback("negative");
              }}
            >
              {t("actions.send")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <ThumbsButton
        btnType="negative"
        selected={selected}
        onClick={() => !selected && setOpen(true)}
      />
      <ThumbsButton
        btnType="positive"
        selected={selected}
        onClick={() => {
          if (!selected) {
            sendFeedback("positive");
            setSelected("positive");
          }
        }}
      />
    </div>
  );
};

type ThumbsButtonProps = {
  btnType: FeedbackType;
  selected?: FeedbackType;
  onClick?: () => void;
};

export const ThumbsButton: React.FC<ThumbsButtonProps> = ({
  btnType,
  selected,
  onClick,
}) => {
  const IconComponent = btnType === "positive" ? ThumbsUpIcon : ThumbsDownIcon;

  return (
    <div
      className={cn(
        "animated text-gray-500 duration-150",
        !selected ? "cursor-pointer hover:opacity-60" : "",
        selected === btnType ? "tada" : "",
      )}
      onClick={() => {
        if (!selected) {
          onClick?.();
        }
      }}
    >
      {selected === btnType ? (
        <IconComponent
          className={cn(
            "stroke-green-dark aspect-square w-4",
            btnType === "negative" ? "stroke-magenta-30" : "",
          )}
        />
      ) : (
        <IconComponent className={cn("aspect-square w-4")} />
      )}
    </div>
  );
};
