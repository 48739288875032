import { Textarea } from "shared/components/ui/textarea";
// import { SendIcon } from "lucide-react";
import SendIcon from "assets/svg/SendIcon";
import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MicrophoneButton } from "./speech-to-text/MicrophoneButton";
import "./chat-input-styles.css";
import activityPanelStore from "shared/store/activityPanelStore";
import { ChatDownArrowIcon } from "assets/svg/ChatDownArrowIcon";
import { AssistantTaskUpdate } from "types/assistant/AssistantTypes";
import { Processing } from "./Processing";
import styles from "./chatInput.module.css";
import colorsStore from "shared/store/colorsStore";
type Props = {
  onSend?: (text: string) => void;
  onCancel?: () => void;
  disabled?: boolean;
  showArrow?: boolean;
  arrowAction?: () => void;
  taskUpdate?: AssistantTaskUpdate;
};

export const ChatInput: React.FC<Props> = ({
  onSend,
  onCancel,
  disabled,
  showArrow,
  arrowAction,
  taskUpdate,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [recording, setRecording] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { setUserSentFirstMessage } = activityPanelStore();

  useEffect(() => {
    if (textAreaRef) {
      adjustHeight(textAreaRef.current);
    }
  }, [textAreaRef, message]);

  useEffect(() => {
    if (recording) {
      setMessage("");
    }
  }, [recording]);

  // Focus on the text area when disabled becomes false
  useEffect(() => {
    if (!disabled && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [disabled]);

  const handleSend = () => {
    if (message) {
      setUserSentFirstMessage(true);
      onSend?.(message);
      setMessage("");
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // To prevent adding a new line to the end of a message
      handleSend();
    }
  };

  function adjustHeight(el: any) {
    if (!el) return;
    el.style.height =
      el.scrollHeight > el.clientHeight ? el.scrollHeight + "px" : "46px";
  }

  let copyPlaceHolder = t("assistant.inputPlaceholder");
  if (taskUpdate) {
    if (taskUpdate.update) {
      copyPlaceHolder = taskUpdate.update;
    }
  }
  if (recording) {
    copyPlaceHolder = "";
  }

  const useCustomPlaceholderStyle = true; // Replace this with your actual condition

  // Custom placeholder style if the condition is met
  const customPlaceholderStyle = `
    textarea::placeholder {
      color: #00549F;
      font-size: 14px;
      font-weight: 600;
    }
  `;

  return (
    <div className="relative" ref={containerRef}>
      {showArrow && (
        <div
          onClick={arrowAction}
          className="-translate-x-1/2"
          style={{
            cursor: "pointer",
            position: "absolute",
            left: "50%",
            top: -55,
            width: 40,
            height: 40,
            // backgroundColor: "white", // White background
            // border: "3px solid " + colorsConfig.finecoBluePrimary, // Pink border
            // borderRadius: "50%", // Makes it circular
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChatDownArrowIcon
            style={{ color: colorsConfig.finecoBluePrimary }}
            isExpanded={true}
          />
        </div>
      )}

      <div className="relative">
        <Textarea
          ref={textAreaRef}
          value={message}
          onKeyDownCapture={handleKeyDown}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={copyPlaceHolder}
          className={styles.chatTextInput}
          style={{
            backgroundColor: colorsConfig.white,
            color: colorsConfig.black,
            // width: "calc(100% - 58px)",
          }}
          // @ts-ignore
          placeholderstyle={taskUpdate ? customPlaceholderStyle : undefined}
          disabled={disabled}
        />
        <div className="absolute right-4" style={{ right: 10, bottom: 14 }}>
          <div
            onClick={handleSend}
            className={
              message ? "cursor-pointer duration-150 hover:opacity-50" : ""
            }
          >
            {!taskUpdate && (
              <SendIcon
                style={{
                  color: colorsConfig.chatInputColor,
                  width: 18,
                  height: 18,
                }}
              />
            )}
            {taskUpdate && (
              <div
                onClick={onCancel}
                className={
                  message ? "cursor-pointer duration-150 hover:opacity-50" : ""
                }
              >
                <div className={styles.chatCancel}>
                  <CancelButton />
                </div>
              </div>
            )}
          </div>
        </div>

        {!taskUpdate && (
          <div className="absolute bottom-[14px] z-50" style={{ left: 10 }}>
            <MicrophoneButton
              onText={(t) => {
                onSend?.(t);
              }}
              onRecordingChanged={setRecording}
              disabled={disabled}
            />
          </div>
        )}
        {taskUpdate && (
          <div className="absolute bottom-[6px] z-50" style={{ left: 10 }}>
            <Processing />
          </div>
        )}
      </div>
    </div>
  );
};

const CancelButton = () => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  return (
    <div
      style={{
        width: "26px",
        height: "26px",
        border: "2px solid",
        borderColor: colorsConfig.finecoBluePrimary,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "12px",
          height: "12px",
          backgroundColor: colorsConfig.finecoBluePrimary,
        }}
      />
    </div>
  );
};
