export const QuestionnaireIcon: React.FC = () => {
  return (
    <div className="size-8 grid shrink-0 place-items-center rounded-full border border-gray-600 p-[5px]">
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 1000"
        id="questions"
      >
        <path
          fill="#40a2fa"
          d="M530.33 769.44H426.79a16 16 0 01-16-16V527.3a16 16 0 0115.8-16l102.06-1.26A141.91 141.91 0 00641.73 371.22c0-78.15-63.58-141.73-141.73-141.73A140.94 140.94 0 00361.81 339.6a16 16 0 01-11 11.77L233.37 386.55a16 16 0 01-20.59-15.33A287.2 287.2 0 11546.33 654.71v98.73A16 16 0 01530.33 769.44zm-87.54-32h71.54V640.75a16 16 0 0114.23-15.9c129.22-14.38 226.66-123.42 226.66-253.63C755.22 230.49 640.73 116 500 116c-133.4 0-243.22 102.88-254.3 233.45L333 323.31A173.79 173.79 0 01500 197.49c95.79 0 173.73 77.94 173.73 173.73 0 83-59 154.67-140.34 170.51a15.25 15.25 0 01-2.86.3l-87.74 1.08zM530.33 916H426.79a16 16 0 01-16-16V823a16 16 0 0116-16H530.33a16 16 0 0116 16v77A16 16 0 01530.33 916zm-87.54-32h71.54V839H442.79z"
        ></path>
      </svg> */}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 1000"
        id="questions"
      >
        <path
          fill="#40a2fa"
          d="M500,100c-149.79,0-271.22,121.43-271.22,271.22L346.21,336A157.75,157.75,0,1,1,530.33,526L426.79,527.3V753.44H530.33V640.75c135.51-15.08,240.89-130,240.89-269.53C771.22,221.43,649.79,100,500,100Z"
        ></path>
        <rect
          width="103.54"
          height="76.97"
          x="426.79"
          y="823.03"
          fill="#40a2fa"
        ></rect>
      </svg>
    </div>
  );
};
