import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Watchlist } from "shared/models/watchlist/WatchlistModel";
import useWatchlistsStore from "shared/store/watchlistsStore";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  watchlist: Watchlist;
};

export const RenameWatchlistModal: React.FC<Props> = ({
  open,
  setOpen,
  watchlist,
}) => {
  const { t } = useTranslation();
  const watchlistNameRef = useRef<HTMLInputElement>(null);
  const { rename } = useWatchlistsStore();

  const renameWatchlist = () => {
    if (watchlistNameRef && watchlistNameRef.current?.value) {
      const newName = watchlistNameRef.current?.value;
      rename(watchlist.id, newName);
      setOpen(false);
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Labels.H3 className="mb-2">{t("watchlist.rename")}</Labels.H3>
      <input
        ref={watchlistNameRef}
        type="text"
        className="w-full rounded-lg border border-sb-gray-100 p-4"
        placeholder={t("watchlist.renamePlaceholder")}
      />
      <div className="mt-4 flex justify-between gap-4">
        <Button
          label="global.cancel"
          btnStyle="blank"
          onClick={() => setOpen(false)}
          className="w-full"
        />
        <Button
          label="global.save"
          onClick={renameWatchlist}
          className="w-full"
        />
      </div>
    </Modal>
  );
};
