type Props = {
  className?: string;
};

export const EsgIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3046 4.921L12.0001 5.51823L12.6954 4.92099C12.5212 4.71828 12.2673 4.60161 12 4.60159C11.7328 4.60157 11.4788 4.71821 11.3046 4.921ZM12 6.97528C12.2867 7.34405 12.6303 7.80349 12.9728 8.30241C13.3918 8.91308 13.7968 9.56507 14.0935 10.1714C14.4035 10.8046 14.5403 11.2794 14.5404 11.5679C14.5404 12.3586 14.2646 12.9829 13.8398 13.4077C13.415 13.8324 12.7907 14.1083 12.0001 14.1083C11.2094 14.1084 10.5851 13.8325 10.1603 13.4077C9.73556 12.9829 9.45965 12.3586 9.45973 11.5679C9.45965 11.2794 9.59654 10.8047 9.9065 10.1715C10.2033 9.56513 10.6083 8.91301 11.0274 8.3024C11.3697 7.80352 11.7134 7.34399 12 6.97528ZM12.0001 5.51823C12.6954 4.92099 12.6953 4.92088 12.6954 4.92099L12.6991 4.92523L12.7077 4.93537L12.739 4.97244C12.7661 5.00437 12.8051 5.05075 12.8542 5.11007C12.9525 5.22858 13.0918 5.39901 13.2586 5.60938C13.5914 6.02928 14.0369 6.61308 14.4843 7.26498C14.9295 7.91376 15.3888 8.6475 15.7402 9.36534C16.0783 10.0563 16.3737 10.8441 16.3737 11.5679C16.3737 12.8024 15.9336 13.9066 15.1362 14.704C14.3386 15.5015 13.2345 15.9416 12 15.9416C10.7656 15.9417 9.66141 15.5015 8.86397 14.7041C8.06654 13.9066 7.62636 12.8024 7.62639 11.568C7.62639 10.8441 7.92168 10.0563 8.25989 9.36541C8.61128 8.64756 9.07052 7.9138 9.51574 7.26506C9.96314 6.61315 10.4087 6.02936 10.7415 5.60941C10.9082 5.399 11.0476 5.2286 11.1458 5.1101C11.195 5.05076 11.2339 5.00443 11.261 4.9724L11.2924 4.93536L11.301 4.92529L11.3046 4.921C11.3047 4.9209 11.3046 4.921 12.0001 5.51823Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 14.1083C11.4936 14.1083 11.0833 14.5187 11.0833 15.0249L11.0833 18.4819C11.0833 18.9882 11.4937 19.3986 12 19.3986C12.5062 19.3986 12.9167 18.9882 12.9166 18.4819L12.9166 15.025C12.9166 14.5187 12.5062 14.1083 11.9999 14.1083Z"
        fill="currentColor"
      />
      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};
