import { useMemo } from "react";
import useOnboardingStore from "shared/store/onboardingStore";
import { AllCountries } from "shared/types/Countries";

export function useAvailableCountries() {
  const { availableCountries } = useOnboardingStore();
  return useMemo(() => {
    return AllCountries.filter((e) => availableCountries.indexOf(e.code) > -1);
  }, [availableCountries]);
}
