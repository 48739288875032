import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Labels } from "shared/components/hoc/Labels";
import { ArrowUp } from "shared/icons/ArrowUp";
import { TradeIcon } from "shared/icons/TradeIcon";
import { TradingPosition } from "shared/models/trading/TradingPositionmodel";
import useClientDetailsStore from "shared/store/clientDetailsStore";
import { CurrencyFormat, pctFormat } from "shared/utils/currency";
import { calculateWeightedAverage } from "shared/utils/trading";

type Props = {
  clientId: string;
  onClick?: () => void;
};

export const ManualTrading: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();

  const [positions, setPositions] = useState([]);

  const { clientDetails, fetchPositions } = useClientDetailsStore();

  useEffect(() => {
    fetchPositions(clientId);
  }, []);

  useEffect(() => {
    if (clientDetails[clientId]) {
      setPositions(clientDetails[clientId].positions);
      // setLoading(clientDetails[clientId].portfoliosLoading);
    }
  }, [clientDetails, setPositions, clientId]);
  // const clientDetails = useClientDetailsStore(
  //   (state) => state.clientDetails[clientId] || { positions: [] },
  // );

  // const { positions } = clientDetails;

  if (!positions?.length) {
    return null;
  }

  const totalReturn = positions
    .map((p) => p.unrealized_pl)
    .reduce((a, b) => a + b, 0);
  const totalReturnPct = calculateWeightedAverage(positions);
  const invested = positions
    .map((p) => p.market_value)
    .reduce((a, b) => a + b, 0);

  return (
    <div>
      <Link to="?tab=1">
        <div className="flex cursor-pointer justify-between gap-8 rounded-3xl border border-sb-gray-200 p-4 duration-150 hover:bg-sb-gray-100">
          <div className="flex w-full justify-between gap-4">
            <div>
              <div className="flex items-center gap-4">
                <div className="grid aspect-square w-[40px] place-items-center rounded-full border border-sb-gray-300 bg-sb-gray-100">
                  <TradeIcon className="aspect-square w-[20px] shrink-0" />
                </div>
                <div>
                  <Labels.H3>{t("trade.manualTrading")}</Labels.H3>
                  <div className="-mt-2 flex items-center gap-1 text-sb-gray-500">
                    <div
                      className={
                        "flex items-center gap-1 " +
                        (totalReturn >= 0
                          ? "text-sb-green-700"
                          : "text-sb-red-700")
                      }
                    >
                      <ArrowUp
                        className={
                          "aspect-square w-4 " +
                          (totalReturn < 0 ? " rotate-180" : "")
                        }
                      />
                      <div className="font-medium">
                        {CurrencyFormat.format(totalReturn)} (
                        {pctFormat.format(totalReturnPct)})
                      </div>
                    </div>
                    {t("positions.totalReturn")}
                  </div>
                </div>
              </div>
              <div className="mt-4">
                {
                  // @ts-ignore
                  positions.map((p, idx) => {
                    return (
                      <PositionDescription
                        key={"manual_position_" + idx}
                        position={p}
                      />
                    );
                  })
                }
              </div>
            </div>
            <div>
              <div className="flex items-center gap-2">
                <div className="text-lg text-sb-gray-500">
                  {t("clientPortfolio.invested")}
                </div>
                <div className="text-xl font-semibold">
                  {CurrencyFormat.format(invested)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

type PositionDescriptionProps = {
  position: TradingPosition;
};

const PositionDescription: React.FC<PositionDescriptionProps> = ({
  position,
}) => {
  const positive = position.unrealized_pl >= 0;
  return (
    <div
      className={
        (positive ? "text-sb-green-700" : "text-sb-red-700") +
        " mr-8 inline-block truncate font-semibold"
      }
    >
      {position.symbol} {pctFormat.format(position.unrealized_plpc)}
    </div>
  );
};
