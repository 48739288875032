import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { InfoBox } from "shared/components/common/InfoBox";
import useCallToAction from "shared/hooks/useCallToAction";

export const CtaCard: React.FC = () => {
  const { t } = useTranslation();
  const { cardType } = useCallToAction();
  const navigate = useNavigate();

  return (
    <>
      {/* {cardType === "kyc" && (
        <InfoBox
          title={t(`dashboard.card.${cardType}.title`)}
          description={t(`dashboard.card.${cardType}.desc`)}
          type="warning"
          buttonText={t("global.continue")}
          onClick={() => navigate("/onboarding")}
        />
      )} */}
      {cardType === "action_required" && (
        <InfoBox
          title={t("ibrKyc.title")}
          description={t("kyc.someDetailsNeeded")}
          type="warning"
          buttonText={t("global.continue")}
          onClick={() => navigate("/onboarding/action_required")}
        />
      )}
      {cardType === "onfido" && (
        <InfoBox
          title={t("ibrKyc.title")}
          description={t("kyc.someDetailsNeeded")}
          type="warning"
          buttonText={t("global.continue")}
          onClick={() => navigate("/onboarding/onfido")}
        />
      )}
    </>
  );
};
