import React from "react";
import {
  formatNumber,
  formatNumber3,
  formatPercentage,
  formatPercentageNo100,
} from "utils/formatUtils";
import { riskProfileMapEngToIta } from "utils/misc";
import colorsStore from "shared/store/colorsStore";

type SectionChildrenProps = {
  reportData: any;
  artifact: any;
};
const Stats: React.FC<SectionChildrenProps> = ({ reportData, artifact }) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  if (!reportData) {
    return <></>;
  }

  return (
    <div style={{ marginLeft: 30, marginBottom: 30, marginTop: 20 }}>
      {/* **********CONTENT ********** */}
      <div
        className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3"
        style={{ color: colorsConfig.finecoBlack }}
      >
        <Stat
          title="Rendimento Atteso"
          value={formatPercentage(artifact.data?.return)}
        />

        <Stat
          title="Dividend yield 1y"
          value={formatPercentage(artifact.data?.dividend_yield)}
        />

        <Stat
          title="Rating ESG Fineco medio"
          value={formatNumber(artifact.data?.esg)}
        />

        <Stat title="VAR" value={formatPercentage(artifact.data?.var)} />

        <Stat title="YTM" value={formatPercentageNo100(artifact.data?.ytm)} />

        {/* <Stat
          title="ESG Aligned"
          value={artifact.data?.esg_aligned ? "YES" : "NO"}
        /> */}
        <Stat
          title="Final Score Medio"
          value={formatNumber(artifact.data?.final_score)}
        />

        <Stat title="Sharpe" value={formatNumber3(artifact.data?.sharpe)} />

        <Stat
          title="Duration"
          value={formatNumber(artifact.data?.duration) + " anni"}
        />

        <Stat
          title="Final Score Advice medio"
          value={formatNumber(artifact.data?.final_score_advice)}
        />

        {artifact.data?.financial_plan && (
          <Stat
            title="Piano finanziario"
            value={artifact.data?.financial_plan}
          />
        )}
        <Stat
          title="Costi strumenti"
          value={formatPercentage(artifact.data?.total_cost)}
        />
        <Stat
          title="Profilo di rischio"
          value={riskProfileMapEngToIta(artifact.data?.risk_profile)}
        />
      </div>
    </div>
  );
};
export default React.memo(
  Stats,
  (prevProps: SectionChildrenProps, nextProps: SectionChildrenProps) => {
    // Custom comparison function
    return prevProps.reportData === nextProps.reportData;
  },
);

type StatProps = {
  title: string;
  value: string;
};

const Stat: React.FC<StatProps> = ({ title, value }) => {
  return (
    <div className="text-sm">
      {title} <span className="ml-2 font-semibold">{value}</span>
    </div>
  );
};
