import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styles from "./../finecoPortfolioArtifact.module.css";

type PortfolioEvolutionChartProps = {
  data: any;
};

const PortfolioEvolutionChart: React.FC<PortfolioEvolutionChartProps> = ({
  data,
}) => {
  // Get all values to compute min/max
  const allValues = [
    ...data.map((item: any) => item.avg_return),
    ...data.map((item: any) => item.upper_bound),
    ...data.map((item: any) => item.lower_bound),
  ];

  // Calculate min and max values
  const yMin = Math.min(...allValues);
  const yMax = Math.max(...allValues);

  // Round to nearest 10
  const adjustedMin = Math.floor(yMin / 10) * 10;
  const adjustedMax = Math.ceil(yMax / 10) * 10;

  // Generate ticks every 10 from adjustedMin to adjustedMax
  const yTicks = Array.from(
    { length: (adjustedMax - adjustedMin) / 10 + 1 },
    (_, i) => adjustedMin + i * 10,
  );

  // Format date for X-axis and tooltip
  const formatDate = (date: string) => {
    const d = new Date(date);
    return `${d.toLocaleString("default", { month: "short" })}'${d.getFullYear().toString().slice(2)}`;
  };

  // Calculate X-axis ticks for every 12 months
  const xTicks = data.reduce((acc: string[], item: any, index: number) => {
    if (index % 12 === 0) {
      acc.push(item.date);
    }
    return acc;
  }, []);

  return (
    <div className={styles.evolutionChart}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
          <XAxis
            dataKey="date"
            tickFormatter={formatDate}
            ticks={xTicks} // Use calculated ticks
            tick={{ fill: "#4b5563", fontSize: 14 }}
          />
          <YAxis
            tickFormatter={(value) => `${value.toFixed(2)}%`}
            domain={[adjustedMin, adjustedMax]}
            ticks={yTicks}
            tick={{ fill: "#4b5563", fontSize: 12 }}
          />
          <Tooltip
            formatter={(value: number) => `${Number(value).toFixed(2)}%`}
            labelFormatter={formatDate}
            contentStyle={{ fontSize: 14 }}
          />
          <Line
            type="natural"
            dataKey="upper_bound"
            name="Upper Bound"
            stroke="#008A00"
            strokeDasharray="5 5"
            dot={false}
            strokeWidth={2}
          />
          <Line
            type="natural"
            dataKey="avg_return"
            name="Avg return"
            stroke="#EB7100"
            dot={false}
            strokeWidth={2}
          />
          <Line
            type="natural"
            dataKey="lower_bound"
            name="Lower Bound"
            stroke="#BB2525"
            strokeDasharray="5 5"
            dot={false}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PortfolioEvolutionChart;
