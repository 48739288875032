import { create } from "./store";

type RealtimeTickerSubscriptionsStoreState = {
  subscriptions: { [symbol: string]: number };
};

type RealtimeTickerSubscriptionsStoreActions = {
  subscribe: (symbols: string[]) => void;
  unsubscribe: (symbols: string[]) => void;
};

const initialData: RealtimeTickerSubscriptionsStoreState = {
  subscriptions: {},
};

const useRealtimeTickerSubscriptionsStore = create<
  RealtimeTickerSubscriptionsStoreState &
    RealtimeTickerSubscriptionsStoreActions
>()((set, get) => ({
  ...initialData,
  subscribe: async (symbols) => {
    const subscriptions = symbols.reduce(
      (acc, value) => {
        acc[value] = Date.now();
        return acc;
      },
      { ...get().subscriptions },
    );
    set({ subscriptions });
  },
  unsubscribe: async (symbols) => {
    const subscriptions = symbols.reduce(
      (acc, value) => {
        delete acc[value];
        return acc;
      },
      { ...get().subscriptions },
    );
    set({ subscriptions });
  },
}));

export default useRealtimeTickerSubscriptionsStore;
