import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { ProAssistantService } from "shared/services/pro_assistant/ProAssistantService";
import useProAssistantStore from "shared/store/proAssistantStore";
import { ThreadActivePortfolio } from "./ThreadActivePortfolio";
import { Panel } from "shared/components/hoc/Panels";
import { cn } from "utils";
import { SessionPortfolioHeader } from "./SessionPortfolioHeader";
import { ChevronCircle } from "./ChevronCircle";

export const ThreadPortfolios: React.FC = () => {
  const params = useParams();
  const threadId = params.threadId;
  const portfolios = useProAssistantStore(
    (state) => state.portfolios[threadId || ""],
  );
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const details = useProAssistantStore(
    (state) => state.threadDetails[threadId || ""],
  );
  const { setBlockInput, fetchThread, fetchPortfolios } =
    useProAssistantStore();

  if (!portfolios?.length) {
    return null;
  }

  const activePortfolioId = details?.metadata?.active_portfolio_id;

  const activatePortfolio = async (portfolioId: string) => {
    if (threadId) {
      try {
        setBlockInput(threadId, true);
        await ProAssistantService.activatePortfolio(threadId, portfolioId);
        fetchThread(threadId);
        fetchPortfolios(threadId);
      } finally {
        setBlockInput(threadId, false);
      }
    }
  };

  return (
    <>
      {activePortfolioId && threadId && (
        <ThreadActivePortfolio
          onClose={() => {}}
          portfolioId={activePortfolioId}
          threadId={threadId}
        />
      )}
      <Panel>
        <div
          className={cn(
            "flex items-center justify-between",
            expanded ? "mb-4" : "",
          )}
        >
          <h3>{t("assistant.chatPortfolios")}</h3>
          <div className="flex items-center gap-4">
            <ChevronCircle
              expanded={expanded}
              setExpanded={(e) => setExpanded(e)}
            />
          </div>
        </div>
        {expanded && (
          <div className="space-y-4">
            {portfolios?.map((item, idx) => {
              const portfolio = portfolios?.find((p) => p.id === item.id);
              return (
                <SessionPortfolioHeader
                  key={"ai_portfolio_" + idx}
                  portfolio={portfolio}
                  sessionId={threadId || ""}
                  active={item.id === activePortfolioId}
                  onClick={() => activatePortfolio(item.id)}
                />
              );
            })}
          </div>
        )}
      </Panel>
    </>
  );
};
