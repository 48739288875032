export const twoDecimals = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export const formatPercentage = (value?: number): string => {
  return value !== undefined ? (value * 100).toFixed(2) + "%" : "-";
};
export const formatPercentageNo100 = (value?: number): string => {
  return value !== undefined ? (value * 1).toFixed(2) + "%" : "-";
};
export const formatNumber = (value?: number): string => {
  return value !== undefined ? value.toFixed(2) : "-";
};
export const formatNumber3 = (value?: number): string => {
  return value !== undefined ? value.toFixed(3) : "-";
};
