import { RealTimeTickerLatestClose } from "shared/models/asset/AssetModel";
import { create } from "./store";
import {
  RealTimeTickers,
  RealtimeService,
} from "shared/services/realtime_prices/RealtimeService";

type RealtimePriceStoreState = {
  tickers: RealTimeTickers;
};

type RealtimePriceStoreActions = {
  updateMany: (tickers: { [key: string]: RealTimeTickerLatestClose }) => void;
  update: (symbol: string, ticker: RealTimeTickerLatestClose) => void;
  unsubscribe: (symbol: string) => void;
  subscribe: (tickers: string[]) => void;
};

const initialData: RealtimePriceStoreState = {
  tickers: {},
};

const useRealtimePricesStore = create<
  RealtimePriceStoreState & RealtimePriceStoreActions
>()((set, get) => ({
  ...initialData,
  updateMany: (tickers) => {
    Object.entries(tickers).forEach(([symbol, ticker]) => {
      const updated = {
        ...get().tickers[symbol],
        ...ticker,
        subscribed_to_realtime: true,
      };
      set({ tickers: { ...get().tickers, [symbol]: updated } });
    });
  },
  update: (symbol, ticker) => {
    const updated = {
      ...get().tickers[symbol],
      ...ticker,
      subscribed_to_realtime: true,
    };
    set({ tickers: { ...get().tickers, [symbol]: updated } });
  },
  unsubscribe: (symbol) => {
    const updated = {
      ...get().tickers[symbol],
      latestPrice: get().tickers[symbol]?.latestPrice || null,
      previousClose: get().tickers[symbol]?.previousClose || null,
      subscribed_to_realtime: false,
    };
    set({ tickers: { ...get().tickers, [symbol]: updated } });
  },
  subscribe: async (tickers) => {
    const response = await RealtimeService.subscribe(tickers);
    Object.entries(response).forEach(([key, value]) => {
      if (value) {
        const updated = {
          ...get().tickers[key],
          ...value,
          subscribed_to_realtime: true,
        };
        set({ tickers: { ...get().tickers, [key]: updated } });
      }
    });
  },
}));

export default useRealtimePricesStore;
