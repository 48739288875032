

import RestService from "shared/api/RestService";
import { StrategySubscriptionsModel } from "shared/models/subscriptions/StrategySubscriptionsModel";
import { FaApiRoutes } from "shared/utils/routes";

export const SubscriptionsService = {
    getMany: async (): Promise<StrategySubscriptionsModel[]> => {
        return RestService.get<StrategySubscriptionsModel[]>(FaApiRoutes.Advisor.Subscriptions);
    }
}