import React, { useEffect, useState } from "react";
import { AlignJustifyIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { UserMenu } from "components/UserMenu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "shared/components/ui/tooltip";
import useAppStatus from "shared/store/appStatusStore";
import { ThreadHistoryIcon } from "assets/svg/menu-icons/ThreadHistoryIcon";
import { PlusIcon } from "assets/svg/menu-icons/PlusIcon";
import { AttachmentsIcon } from "assets/svg/menu-icons/AttachmentsIcon";
import { ConstraintsIcon } from "assets/svg/menu-icons/ConstraintsIcon";
import { useNavigate, useParams } from "react-router-dom";
import useThreadsStore from "shared/store/threadsStore";
import { EsgIcon } from "assets/svg/menu-icons/EsgIcon";
import activityPanelStore from "shared/store/activityPanelStore";
import { RedDotIcon } from "assets/svg/menu-icons/RedDotIcon";
import { createNewChat } from "utils/chatUtils";
import styles from "./SideMenu.module.css";
import colorsStore from "shared/store/colorsStore";
import brandStore from "shared/store/brandStore";
import { ColorShades } from "config/colors-config";

export const SideMenu: React.FC = () => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const { t } = useTranslation();

  const setAppStatus = useAppStatus((state) => state.setAppStatus);
  const fromHistory = useThreadsStore((state) => state.fromHistory);
  const appStatus = useAppStatus((state) => state.appStatus);

  const params = useParams();
  const threadId = params.threadId;
  const attachments = useThreadsStore((state) => state.attachments[threadId]);

  const {
    esgPreferencesStore,
    setEsgRedDot,
    esgRedDot,
    setConstraintsRedDot,
    constraintsRedDot,
    attachmentsRedDot,
    setAttachmentsRedDot,
    userSentFirstMessage,
    newAttachmentsAdded,
    setNewAttachmentsAdded,
    newConstraintsAdded,
    setNewConstraintsAdded,
    newEsgAdded,
    setNewEsgAdded,
  } = activityPanelStore();
  const { disabledFeatures } = brandStore();

  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const [showAttachmentBubble, setShowAttachmentBubble] = useState(false);
  const [showConstraintsBubble, setShowConstraintsBubble] = useState(false);
  const [showEsgBubble, setShowEsgBubble] = useState(false);

  const [attachmentBubblePosition, setAttachmentBubblePosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });
  const [constraintsBubblePosition, setConstraintsBubblePosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });
  const [esgBubblePosition, setEsgBubblePosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  const calculateBubblePosition = (menuItemId: string) => {
    const element = document.getElementById(menuItemId);
    if (element) {
      const rect = element.getBoundingClientRect();
      return {
        top: rect.top,
        left: rect.right + 10, // Adjust as needed
      };
    }
    return { top: 0, left: 0 };
  };

  useEffect(() => {
    if (!fromHistory && userSentFirstMessage && newAttachmentsAdded) {
      setAttachmentBubblePosition(calculateBubblePosition("attachments"));
      setShowAttachmentBubble(true);
      const timeout = setTimeout(() => {
        setNewAttachmentsAdded(false);
        setShowAttachmentBubble(false);
      }, 4000);
      return () => clearTimeout(timeout);
    }
  }, [fromHistory, userSentFirstMessage, newAttachmentsAdded]);

  useEffect(() => {
    if (!fromHistory && userSentFirstMessage && newConstraintsAdded) {
      setConstraintsBubblePosition(calculateBubblePosition("constraints"));
      setShowConstraintsBubble(true);
      const timeout = setTimeout(() => {
        setNewConstraintsAdded(false);
        setShowConstraintsBubble(false);
      }, 4000);
      return () => clearTimeout(timeout);
    }
  }, [fromHistory, userSentFirstMessage, newConstraintsAdded]);

  useEffect(() => {
    if (!fromHistory && userSentFirstMessage && newEsgAdded) {
      setEsgBubblePosition(calculateBubblePosition("esgPreferences"));
      setShowEsgBubble(true);
      const timeout = setTimeout(() => {
        setNewEsgAdded(false);
        setShowEsgBubble(false);
      }, 4000);
      return () => clearTimeout(timeout);
    }
  }, [fromHistory, userSentFirstMessage, newEsgAdded]);

  return (
    <aside
      className={`flex h-full flex-col justify-between transition-all duration-300 ease-in-out ${
        expanded ? "w-72" : "w-24"
      }`}
      style={{
        backgroundColor: colorsConfig.white,
        borderRight: `1px solid ${colorsConfig.grayLight}`,
        borderTopLeftRadius: "20px",
        borderBottomLeftRadius: "20px",
      }}
    >
      <div className="space-y-4">
        {/* <MenuItemButton
          item={{
            icon: AlignJustifyIcon,
            activeIconColor: colorsConfig.main,
            inactiveIconColor: colorsConfig.iconsColor,
            activeBackgroundColor: colorsConfig.iconActiveBackground,
            inactiveBackgroundColor: colorsConfig.secondBackground,
            width: 20,
            height: 20,
          }}
          active={expanded}
          expanded={expanded}
          onClick={() => setExpanded((prev) => !prev)}
          isExpandToggle
        /> */}
        <MenuItemButton
          item={{
            icon: PlusIcon,
            name: "newChat",
            activeIconColor: colorsConfig.main,
            inactiveIconColor: colorsConfig.iconsColor,
            activeBackgroundColor: colorsConfig.iconActiveBackground,
            inactiveBackgroundColor: colorsConfig.secondBackground,
            width: 22,
            height: 22,
          }}
          active={false}
          expanded={expanded}
          onClick={() => {
            createNewChat(navigate);
          }}
        />
        <MenuItemButton
          item={{
            icon: ThreadHistoryIcon,
            name: "threadHistory",
            activeIconColor: colorsConfig.finecoBlack,
            inactiveIconColor: colorsConfig.finecoBlack,
            activeBackgroundColor: colorsConfig.iconActiveBackground,
            inactiveBackgroundColor: colorsConfig.secondBackground,
            width: 22,
            height: 22,
          }}
          active={appStatus === "history"}
          expanded={expanded}
          onClick={() => {
            if (appStatus === "history") {
              setAppStatus("idle");
            } else {
              setAppStatus("history");
            }
          }}
        />
        <div
          className="separator"
          style={{ backgroundColor: colorsConfig.gray2 }}
        />
        <MenuItemButton
          item={{
            icon: AttachmentsIcon,
            name: "attachments",
            activeIconColor: colorsConfig.main,
            inactiveIconColor: colorsConfig.iconsColor,
            activeBackgroundColor: colorsConfig.iconActiveBackground,
            inactiveBackgroundColor: colorsConfig.secondBackground,
          }}
          active={appStatus === "attachments"}
          expanded={expanded}
          onClick={() => {
            if (appStatus === "attachments") {
              setAppStatus("idle");
            } else {
              setAttachmentsRedDot(false);
              setAppStatus("attachments");
            }
          }}
          isDisabled={attachments && attachments.length === 0}
          showRedDot={
            attachmentsRedDot && attachments && attachments.length > 0
          }
          id="attachments"
        />
        {/* <MenuItemButton
          item={{
            icon: PortfoliosIcon,
            name: "portfolios",
            activeIconColor: colorsConfig.main,
            inactiveIconColor: colorsConfig.iconsColor,
            activeBackgroundColor: colorsConfig.iconActiveBackground,
            inactiveBackgroundColor: colorsConfig.secondBackground,
          }}
          active={appStatus === "portfolios"}
          expanded={expanded}
          onClick={() => {
            setPortfoliosRedDot(false);
            setAppStatus("portfolios");
          }}
          isDisabled={
            (finecoPortfolioArtifacts[threadId] &&
              finecoPortfolioArtifacts[threadId].length === 0) ||
            !finecoPortfolioArtifacts[threadId]
          }
          showRedDot={
            portfoliosRedDot &&
            finecoPortfolioArtifacts[threadId] &&
            finecoPortfolioArtifacts[threadId].length > 0
          }
        /> */}

        {!disabledFeatures["constraints"] && (
          <MenuItemButton
            item={{
              icon: ConstraintsIcon,
              name: "constraints",
              activeIconColor: colorsConfig.icon,
              inactiveIconColor: colorsConfig.icon,
              activeBackgroundColor: colorsConfig.iconActiveBackground,
              inactiveBackgroundColor: colorsConfig.secondBackground,
            }}
            active={appStatus === "constraints"}
            expanded={expanded}
            onClick={() => {
              if (appStatus === "constraints") {
                setAppStatus("idle");
              } else {
                setConstraintsRedDot(false);
                setAppStatus("constraints");
              }
            }}
            // isDisabled={!constraintsState}
            isDisabled={false}
            showRedDot={constraintsRedDot}
            id="constraints"
          />
        )}

        {!disabledFeatures["esg"] && (
          <MenuItemButton
            item={{
              icon: EsgIcon,
              name: "esgPreferences",
              activeIconColor: colorsConfig.finecoBlack,
              inactiveIconColor: colorsConfig.finecoBlack,
              activeBackgroundColor: colorsConfig.iconActiveBackground,
              inactiveBackgroundColor: colorsConfig.secondBackground,
              width: 24,
              height: 24,
            }}
            active={appStatus === "esgPreferences"}
            isDisabled={!esgPreferencesStore}
            expanded={expanded}
            onClick={() => {
              if (appStatus === "esgPreferences") {
                setAppStatus("idle");
              } else {
                setEsgRedDot(false);
                setAppStatus("esgPreferences");
              }
            }}
            showRedDot={esgRedDot}
            copy="ESG"
            id="esgPreferences"
          />
        )}
      </div>
      {/* <div className={styles.menuUserItemContainer}>
        <UserMenu />
      </div> */}
      {showAttachmentBubble && (
        <div
          className={`${styles.speechBubble} ${
            showAttachmentBubble ? styles.show : ""
          }`}
          style={{
            top: attachmentBubblePosition.top,
            left: attachmentBubblePosition.left,
          }}
          onClick={() => setShowAttachmentBubble(false)}
        >
          {t("menu.newAttachmentsAdded")}
        </div>
      )}
      {showConstraintsBubble && (
        <div
          className={`${styles.speechBubble} ${
            showConstraintsBubble ? styles.show : ""
          }`}
          style={{
            top: constraintsBubblePosition.top,
            left: constraintsBubblePosition.left,
          }}
          onClick={() => setShowConstraintsBubble(false)}
        >
          {t("menu.newConstraintsAdded")}
        </div>
      )}
      {showEsgBubble && (
        <div
          className={`${styles.speechBubble} ${
            showEsgBubble ? styles.show : ""
          }`}
          style={{
            top: esgBubblePosition.top,
            left: esgBubblePosition.left,
          }}
          onClick={() => setShowEsgBubble(false)}
        >
          {t("menu.newEsgAdded")}
        </div>
      )}
    </aside>
  );
};

export type MenuItem = {
  icon: React.ComponentType<{ className?: string }>;
  name?: string;
  activeIconColor: any;
  inactiveIconColor: any;
  activeBackgroundColor: any;
  inactiveBackgroundColor: any;
  width?: number;
  height?: number;
};

type MenuItemProps = {
  item: MenuItem;
  expanded: boolean;
  active?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  isExpandToggle?: boolean;
  showRedDot?: boolean;
  copy?: string;
  id?: string;
};
export const MenuItemButton: React.FC<MenuItemProps> = ({
  item,
  expanded,
  active,
  isDisabled = false,
  onClick,
  isExpandToggle = false,
  showRedDot = false,
  id,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const { t } = useTranslation();
  const activeButton = active;
  const IconComponent = item.icon;
  const iconSizeClass = `icon-size-${item.width || 24}-${item.height || 24}`;

  const trigger = () => {
    return (
      <div
        id={id}
        className={`
          ${activeButton ? "border-r-2" : isDisabled ? "" : "hover:opacity-60"}
          ${isDisabled ? "opacity-30" : ""}
          w-full select-none px-4 py-2
          ${expanded ? "lg:pr-4" : ""}
        `}
        style={{
          borderColor: activeButton ? item.activeIconColor : null,
          color: activeButton ? item.activeIconColor : null,
          cursor: isDisabled ? "default" : "pointer",
        }}
        onClick={() => {
          if (!isDisabled) {
            onClick?.();
          }
        }}
      >
        {/* Changed this div to use flex-col and center alignment */}
        <div className="flex flex-col items-center justify-center">
          <div
            className={`
              grid size-10 flex-shrink-0 place-items-center rounded-full 
              ${
                activeButton || (isExpandToggle && expanded)
                  ? "#ffff00"
                  : "#00ff00"
              }
            `}
            style={{
              color: activeButton
                ? item.activeIconColor
                : item.inactiveIconColor,
              fill: activeButton
                ? item.activeIconColor
                : item.inactiveIconColor,
              stroke: activeButton
                ? item.activeIconColor
                : item.inactiveIconColor,
            }}
          >
            <IconComponent className={iconSizeClass} />
            {showRedDot && (
              <div
                style={{
                  position: "absolute",
                  transform: "translate(14px, -12px)",
                }}
              >
                <RedDotIcon />
              </div>
            )}
          </div>
          {!isExpandToggle && (
            <div
              className="mt-1 text-center"
              style={{
                color: colorsConfig.finecoBlack,
                fontSize: "11px",
              }}
            >
              {t(`menu.${item.name}`)}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{trigger()}</TooltipTrigger>
        <TooltipContent
          side="right"
          sideOffset={0}
          align="start"
          backgroundColor={colorsConfig.tooltipBackground}
          textColor={colorsConfig.tooltipText}
        >
          <p>{t(`menu.${item.name}`)}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
