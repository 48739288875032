import { Watchlist } from "shared/models/watchlist/WatchlistModel";
import {
  CreateWatchlistData,
  WatchlistsService,
} from "shared/services/watchlists/WatchlistsService";
import { Loading } from "shared/types/enums";
import { create } from "zustand";

type WatchlistsStoreState = {
  watchlists: Watchlist[];
  loading: Loading;
};

type WatchlistsStoreActions = {
  reset: () => void;
  fetchWatchlists: () => Promise<void>;
  addSymbol: (
    watchlistId: string,
    watchlistData: CreateWatchlistData,
  ) => Promise<void>;
  removeSymbol: (watchlistId: string, symbol: string) => Promise<void>;
  rename: (id: string, name: string) => Promise<void>;
  remove: (id: string) => void;
};

const initialData: WatchlistsStoreState = {
  watchlists: [],
  loading: Loading.Idle,
};

const useWatchlistsStore = create<
  WatchlistsStoreState & WatchlistsStoreActions
>()((set, get) => ({
  ...initialData,
  fetchWatchlists: async () => {
    set({ loading: Loading.InProgress });
    try {
      const watchlists = await WatchlistsService.fetchAll();
      set({ watchlists });
    } finally {
      set({ loading: Loading.Finished });
    }
  },
  addSymbol: async (id, watchlistData) => {
    await WatchlistsService.addSymbol(id, watchlistData);
  },
  removeSymbol: async (id, symbol) => {
    await WatchlistsService.removeSymbol(id, symbol);
  },
  rename: async (id, name) => {
    await WatchlistsService.put(id, {
      name,
      symbols: get().watchlists.find((w) => w.id === id)?.assets || [],
    });
  },
  reset: () => {
    set({ ...initialData });
  },
  remove: async (id) => {
    await WatchlistsService.delete(id);
  },
}));

export default useWatchlistsStore;
