import styles from "./DeleteHistoryIcon.module.css";
type Props = {
  className?: string;
  color?: string;
};

export const DeleteHistoryIcon: React.FC<Props> = ({ className }) => {
  return (
    <div className={styles.container}>
      <svg
        className={className}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_536_11715)">
          <mask
            id="mask0_536_11715"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
          >
            <path d="M0 0H16V16H0V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_536_11715)">
            <path
              d="M0 8C0.00264619 5.87908 0.846351 3.84579 2.34607 2.34607C3.84579 0.846351 5.87908 0.00264619 8 0C10.1209 0.00264619 12.1542 0.846351 13.6539 2.34607C15.1536 3.84579 15.9974 5.87908 16 8C15.9974 10.1209 15.1536 12.1542 13.6539 13.6539C12.1542 15.1536 10.1209 15.9974 8 16C5.87908 15.9974 3.84579 15.1536 2.34607 13.6539C0.846351 12.1542 0.00264619 10.1209 0 8ZM1.5 8C1.50185 9.72334 2.18727 11.3756 3.40585 12.5941C4.62444 13.8127 6.27666 14.4981 8 14.5C9.72326 14.4979 11.3753 13.8124 12.5939 12.5939C13.8124 11.3753 14.4979 9.72326 14.5 8C14.4979 6.27674 13.8124 4.62467 12.5939 3.40614C11.3753 2.18762 9.72326 1.50212 8 1.5C6.27666 1.50185 4.62444 2.18727 3.40585 3.40585C2.18727 4.62444 1.50185 6.27666 1.5 8ZM10.369 11.43L7.999 9.06L5.629 11.43C5.56074 11.505 5.47796 11.5655 5.38568 11.6076C5.29339 11.6497 5.19352 11.6727 5.09211 11.6751C4.99069 11.6775 4.88984 11.6593 4.79565 11.6217C4.70147 11.584 4.6159 11.5276 4.54414 11.4559C4.47237 11.3842 4.4159 11.2987 4.37813 11.2045C4.34036 11.1104 4.32209 11.0095 4.32441 10.9081C4.32674 10.8067 4.34961 10.7068 4.39165 10.6145C4.43369 10.5222 4.49402 10.4393 4.569 10.371L6.938 8L4.569 5.63C4.43231 5.48861 4.35663 5.2992 4.35824 5.10255C4.35986 4.9059 4.43865 4.71775 4.57764 4.57863C4.71663 4.43951 4.9047 4.36054 5.10135 4.35874C5.298 4.35694 5.48748 4.43245 5.629 4.569L7.999 6.939L10.369 4.569C10.4735 4.46326 10.6072 4.39106 10.7529 4.36162C10.8986 4.33217 11.0498 4.34683 11.1872 4.4037C11.3245 4.46058 11.4418 4.5571 11.5241 4.68094C11.6063 4.80478 11.6498 4.95033 11.649 5.099C11.649 5.19763 11.6296 5.29529 11.5919 5.38641C11.5541 5.47753 11.4988 5.5603 11.429 5.63L9.06 8L11.429 10.371C11.5344 10.4756 11.6063 10.6091 11.6355 10.7547C11.6648 10.9002 11.65 11.0512 11.5932 11.1884C11.5364 11.3255 11.44 11.4426 11.3164 11.5249C11.1928 11.6071 11.0475 11.6506 10.899 11.65C10.8005 11.6503 10.7029 11.631 10.6119 11.5932C10.5209 11.5555 10.4383 11.5 10.369 11.43Z"
              fill="#1C2226"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_536_11715">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
