import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import { PortfolioActivity } from "shared/models/portfolio/PortfolioModel";
import { formatDate } from "shared/utils/date";
import CompositionUpdate from "./CompositionUpdate";
import { Loading } from "shared/types/enums";
import useClientDetailsStore from "shared/store/clientDetailsStore";

type Props = {
  clientId?: string;
  portfolioId?: string;
};

type SortedActivities = {
  date: string;
  entry: {
    portfolioId: string;
    activities: PortfolioActivity[];
  };
};

export const LatestUpdates: React.FC<Props> = ({ portfolioId, clientId }) => {
  const { t } = useTranslation();
  const { fetchRecentCompositionActivities, clientDetails } =
    useClientDetailsStore();

  // Use a ref to track if we've already processed the activities
  const [processedActivities, setProcessedActivities] = useState<
    SortedActivities[]
  >([]);
  const [loading, setLoading] = useState<Loading>(Loading.Idle);

  // Get client activities and loading state
  useEffect(() => {
    if (clientId && clientDetails[clientId]) {
      setLoading(clientDetails[clientId].recentCompositionActivitiesLoading);
    }
  }, [clientId, clientDetails]);

  // Fetch activities when clientId changes
  useEffect(() => {
    if (clientId) {
      fetchRecentCompositionActivities(clientId);
    }
  }, [clientId, fetchRecentCompositionActivities]);

  // Process activities only when loading is finished
  useEffect(() => {
    if (loading === Loading.Finished && clientId && clientDetails[clientId]) {
      const activities = clientDetails[clientId].recentCompositionActivities;
      const filteredActivities = !portfolioId
        ? activities
        : activities?.filter((a) => a.portfolio_id === portfolioId);

      const dateMappedActivities: Record<
        string,
        { portfolioId: string; activities: PortfolioActivity[] }
      > = {};

      if (filteredActivities) {
        for (const activity of filteredActivities) {
          const portfolioId = activity.portfolio_id;
          const compositionActivities = activity.activities?.filter(
            (a) => a.entry_type === "composition",
          );

          for (const a of compositionActivities) {
            const date = formatDate(a.timestamp).toString();
            if (!dateMappedActivities[date]) {
              dateMappedActivities[date] = { portfolioId, activities: [a] };
            } else if (dateMappedActivities[date].portfolioId === portfolioId) {
              dateMappedActivities[date].activities.push(a);
            } else {
              dateMappedActivities[date] = { portfolioId, activities: [a] };
            }
          }
        }
      }

      const sorted = Object.entries(dateMappedActivities)
        .map(([date, entry]) => ({ date, entry }))
        .sort((a, b) => (a.date > b.date ? -1 : 1));

      setProcessedActivities(sorted);
    }
  }, [loading, clientId, portfolioId, clientDetails]);

  if (!clientId) {
    return null;
  }

  return (
    <Panel>
      <Labels.H3 className="mb-4">{t("latestUpdates.title")}</Labels.H3>
      <Layouts.Spaced>
        {processedActivities.map((item, idx) => (
          <div key={`latest_updates_${idx}`}>
            <div className="text-lg text-sb-green-700">{item.date}</div>
            <div className="mt-4">
              <CompositionUpdate
                latestActivities={item.entry.activities}
                portfolioId={item.entry.portfolioId}
              />
            </div>
          </div>
        ))}
        {processedActivities.length === 0 && loading === Loading.Finished && (
          <Labels.G1>{t("latestUpdates.noUpdates")}</Labels.G1>
        )}
      </Layouts.Spaced>
    </Panel>
  );
};
