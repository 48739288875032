import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { AssistantPortfolio } from "types/assistant/AssistantTypes";
import { Button } from "shared/components/ui/button";
import usePortfoliosStore from "shared/store/portfoliosStore";
import { Loading, cn, pctFormat } from "utils/misc";
import usePortfoliosChartStore from "shared/store/portfoliosChartStore";
import useThreadsStore from "shared/store/threadsStore";
import { AssistantService } from "shared/services/AssistantService";
import { Skeleton } from "shared/components/ui/skeleton";
import PnlChart from "pages/assistant/components/chat/components/portfolios/PnlChart";
import PortfolioCoverImage from "./PortfolioCoverImage";
import colorsStore from "shared/store/colorsStore";
import { AvatarImage } from "shared/components/common/AvatarImage";
import { PortfolioComposition } from "modules/portfolio_composition/PortfolioComposition";
import { MetaPortfolioChart } from "modules/portfolio_composition/MetaPortfolioChart";
import { CompositionChart } from "modules/portfolio_composition/CompositionChart";
import { MetaPortfolioRow } from "modules/portfolio_composition/MetaPortfolioRow";
import { useChartColors } from "modules/portfolio_composition/hooks/useChartColors";
import { useNavigate } from "react-router-dom";

type Props = {
  portfolio: AssistantPortfolio;
  onLoaded?: () => void;
};

export const PortfolioArtifact: React.FC<Props> = ({
  portfolio: assistantPortfolio,
  onLoaded,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const { t } = useTranslation();
  const params = useParams();
  const threadId = params.threadId;
  const portfolio = usePortfoliosStore((state) =>
    state.portfolios?.find((p) => p.id === assistantPortfolio.id),
  );
  const chartData = usePortfoliosChartStore(
    (state) => state.cache[portfolio?.id || ""],
  );
  const threadDetails = useThreadsStore(
    (state) => state.threadDetails[threadId || ""],
  );
  const { fetchPortfolioById, loading } = usePortfoliosStore();
  const { fetchChartByPortfolioId } = usePortfoliosChartStore();
  const { fetchThread, fetchPortfolios } = useThreadsStore();

  useEffect(() => {
    if (portfolio) onLoaded?.();
  }, [portfolio]);

  useEffect(() => {
    if (assistantPortfolio.id) {
      fetchPortfolioById(assistantPortfolio.id);
      fetchChartByPortfolioId(assistantPortfolio.id, "all_time");
    }
  }, [assistantPortfolio.id]);

  useEffect(() => {
    if (threadId && !portfolio && loading !== Loading.InProgress) {
      fetchPortfolios(threadId);
      fetchThread(threadId);
    }
    let imageTimeout: NodeJS.Timeout | null = null;
    const refetchImage = async () => {
      if (portfolio?.id && !portfolio.cover?.uri) {
        fetchPortfolioById(portfolio.id);
        imageTimeout = setTimeout(refetchImage, 10000);
      }
    };

    refetchImage();

    return () => {
      if (imageTimeout) {
        clearTimeout(imageTimeout);
      }
    };
  }, []);

  // @ts-ignore
  const cagr = parseFloat(portfolio?.simulated_metrics.all_time.cagr || "0");
  const isActive =
    threadDetails?.metadata?.active_portfolio_id === portfolio?.id;

  const handlePortfolioClicked = () => {
    if (portfolio?.id && threadId) {
      AssistantService.activatePortfolio(threadId, portfolio.id).then(() => {
        fetchThread(threadId);
        fetchPortfolios(threadId);
      });
    }
  };

  const { innerColors = [] } = useChartColors(
    portfolio?.meta_portfolio ?? null,
  );

  const navigate = useNavigate();

  return (
    <div
      className={cn(
        "activeShadow relative my-2 max-w-2xl rounded-xl border p-4",
        isActive ? "activeShadow" : "deActiveShadow",
      )}
      style={{
        backgroundColor: "white",
        borderColor: isActive ? colorsConfig.main : colorsConfig.main,
        cursor: "pointer",
      }}
      onClick={() => {
        navigate("/portfolios/" + portfolio.id);
        // navigate("/portfolios/15c400c8-757b-47ed-8d02-1d7770eeda75");
      }}
    >
      {portfolio ? (
        <>
          {isActive && (
            <div
              className="absolute right-4 top-4 rounded-md  px-2 text-[10px] font-semibold text-black"
              style={{ backgroundColor: colorsConfig.main }}
            >
              {t("portfolios.active").toUpperCase()}
            </div>
          )}
          <div
            className={cn(
              "flex items-center justify-between rounded-3xl duration-150",
            )}
          >
            <div className="w-full">
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center gap-2">
                  {/* <PortfolioCoverImage
                    uri={portfolio?.cover?.uri}
                    className="size-14"
                    wrapperClassName="border-gray-700 bg-gray-700 border-[6px]"
                  /> */}
                  <AvatarImage
                    className="mr-6"
                    imageSrc={portfolio?.cover?.uri}
                    width={52}
                    height={52}
                  />
                  <div>
                    <div
                      className="pr-14 text-lg font-semibold"
                      style={{ color: colorsConfig.portfolioTitle }}
                    >
                      {portfolio?.title}
                    </div>
                    <div className="flex items-center gap-2 text-xs">
                      <span
                        className={cn("text-xl")}
                        style={{
                          color:
                            cagr >= 0
                              ? colorsConfig.portfolioPercPositive
                              : colorsConfig.portfolioPercNegative,
                        }}
                      >
                        {pctFormat.format(cagr)}
                      </span>
                      <span
                        style={{
                          color: colorsConfig.artifactsDatoTextColor,
                        }}
                      >
                        {t("portfolios.yearlyAvg").toUpperCase()}{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="h-[200px] w-full">
              <PnlChart
                chartData={chartData?.all_time}
                height={200}
                domain={["dataMin", "dataMax"]}
              />
            </div>
          </div>
          <div className="justify-left flex w-full">
            {/* <div className="mt-8 grid grid-cols-3 text-sm">
              {portfolio?.allocations?.map((allocation, idx) => (
                <div
                  key={"allocation_" + idx}
                  className="grid grid-cols-2 gap-4 px-2"
                >
                  <div
                    className="font-semibold"
                    style={{
                      color: colorsConfig.artifactsLabelTextColor,
                    }}
                  >
                    {allocation.symbol}
                  </div>
                  <div
                    style={{
                      color: colorsConfig.artifactsDatoTextColor,
                    }}
                  >
                    {(+allocation.weight * 100).toFixed(2)}%
                  </div>
                </div>
              ))}
            </div> */}
          </div>
          {/* <PortfolioComposition portfolio={portfolio} /> */}
          <div style={{ marginTop: 15 }}>
            {portfolio.meta_portfolio
              .filter((p) => p.weight > 0)
              ?.map((portfolio, idx) => {
                return (
                  <MetaPortfolioRow
                    key={
                      "meta_portfolio_row_" + portfolio.asset_class + "_" + idx
                    }
                    metaPortfolio={portfolio}
                    color={innerColors[idx]}
                    forceOpen={true}
                  />
                );
              })}
          </div>
          {/* {portfolio?.meta_portfolio && (
            <MetaPortfolioChart
              key={portfolio?.id}
              metaPortfolio={portfolio?.meta_portfolio}
            />
          )} */}
          <div
            style={{
              marginTop: 10,
              justifyContent: "center",
              display: "flex",
            }}
          >
            <CompositionChart metaPortfolio={portfolio.meta_portfolio} />
          </div>
          <div className="mt-8 flex justify-center">
            <div className="space-y-4">
              {!isActive && (
                <Button
                  onClick={() => !isActive && handlePortfolioClicked()}
                  className="min-w-[150px]"
                  size="sm"
                >
                  {t("global.activate")}
                </Button>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="space-y-4">
          <Skeleton className="h-4 w-[250px]" />
          <Skeleton className="h-4 w-[200px]" />
        </div>
      )}
    </div>
  );
};
