export const brandConfig = {
  fineco: {
    language: "it",
    colorSet: "fineco",
    aiChatLogo: "fineco",
    prompts: "fineco",
    disabledFeatures: {
      esg: true,
      constraints: false,
    },
  },
  azimut: {
    language: "it",
    colorSet: "azimut",
    prompts: "azimut",
    disabledFeatures: {
      esg: true,
      constraints: true,
    },
  },
  eviva: {
    language: "en",
    colorSet: "eviva",
    disabledFeatures: {
      esg: true,
      constraints: true,
    },
  },
  streetbeat: {
    language: "en",
    disabledFeatures: {
      esg: true,
      constraints: true,
    },
  },
  "wells fargo": {
    language: "en",
    colorSet: "default",
    aiChatLogo: "streetbeat",
    prompts: "default",
    disabledFeatures: {
      esg: true,
      constraints: true,
    },
  },
  "Edward Jones": {
    language: "en",
    colorSet: "edwardJonesColorSet",
    aiChatLogo: "streetbeat",
    prompts: "default",
    disabledFeatures: {
      esg: true,
      constraints: true,
    },
  },
  citi: {
    language: "en",
    colorSet: "citi",
    aiChatLogo: "streetbeat",
    prompts: "default",
    disabledFeatures: {
      esg: true,
      constraints: true,
    },
  },
  default: {
    language: "en",
    colorSet: "default",
    aiChatLogo: "streetbeat",
    prompts: "default",
    disabledFeatures: {
      esg: true,
      constraints: true,
    },
  },
};
