import { ScrollArea } from "shared/components/ui/scroll-area";
import { useParams } from "react-router-dom";
import { HeaderPanel } from "../HeaderPanel";
import useThreadsStore from "shared/store/threadsStore";
import { getCombinedMessages } from "utils/misc";
import { AssistantAttachment } from "types/assistant/AssistantTypes";
import { AttachmentItem } from "./components/AttachmentItem";
import colorsStore from "shared/store/colorsStore";

type Props = {
  expanded: boolean;
  setExpanded: (e: boolean) => void;
};

export const AttachmentsPanel: React.FC<Props> = ({
  expanded,
  setExpanded,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const params = useParams();
  const threadId = params.threadId;

  // @ts-ignore
  const attachments = useThreadsStore((state) => state.attachments[threadId]);

  const children = () => {
    return (
      <div className="h-full space-y-8">
        {// @ts-ignore
        getCombinedMessages(null, null, null, attachments)?.map(
          (message, idx) => {
            //** ATTACHMENT */
            switch (message.object) {
              case "attachment":
                return (
                  <AttachmentItem
                    message={message as AssistantAttachment}
                    key={idx}
                  />
                );
              default:
                return null;
            }
          },
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: colorsConfig.grayLight,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderPanel
        expanded={expanded}
        setExpanded={setExpanded}
        title={"menu.attachments"}
      />
      <ScrollArea
        className={"flex-2 relative max-h-full min-h-[60px] rounded-xl pt-12"}
        style={{
          backgroundColor: colorsConfig.white,
          margin: 20,
          padding: 10,
        }}
      >
        {expanded && (
          <div className="relative space-y-4 text-xs lg:text-base ">
            {children()}
          </div>
        )}
      </ScrollArea>
    </div>
  );
};
