// ActivityLog.jsx
import React from "react";
import { ClientDetailType } from "types/crm/ClientTypes";
import { getInitials } from "utils/misc";
import crmStyles from "./../../crmPage.module.css";
import styles from "./clientDetailsCampagne.module.css";
import clientDetailsStyles from "./../crmClientDetailsPage.module.css";
import classNames from "classnames";

type ClientDetailsCampagneProps = {
  clientData: ClientDetailType;
};

export const ClientDetailsCampagne: React.FC<ClientDetailsCampagneProps> = ({
  clientData,
}) => {
  const campaigns = clientData.campaigns;

  return (
    <div className={styles.container}>
      <div>
        {campaigns.length === 0 && <></>}
        {campaigns.length > 0 && (
          <>
            {campaigns.map((campaign) => (
              <CampaignCard
                key={campaign.campaign_id}
                campaignData={campaign}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

// @ts-ignore
const CampaignCard = ({ campaignData }) => {
  const getPriorityClass = (priority: any) => {
    const classes = {
      urgent: crmStyles.urgentPriority,
      urgente: crmStyles.urgentPriority,
      Urgente: crmStyles.urgentPriority,
      high: crmStyles.highPriority,
      alta: crmStyles.highPriority,
      Alta: crmStyles.highPriority,
      medium: crmStyles.mediumPriority,
      media: crmStyles.mediumPriority,
      Media: crmStyles.mediumPriority,
      low: crmStyles.lowPriority,
      bassa: crmStyles.lowPriority,
      Bassa: crmStyles.lowPriority,
    } as any;
    return classes[priority] || "";
  };

  return (
    <div key={campaignData.campaign_id} className={styles.cardWrapper}>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <div className={styles.cardHeader}>
            <div className={styles.titleContainer}>
              <h3 className={styles.title}>{campaignData.name}</h3>
              <div
                className={classNames(
                  styles.priority,
                  getPriorityClass(campaignData.priority),
                )}
              >
                {campaignData.priority}
              </div>
            </div>

            <div className={styles.description}>{campaignData.description}</div>
            <div className={styles.description}>
              Target users: {campaignData.target_users}
            </div>
            <div className={styles.cardInfo}></div>
            <div className={styles.cardInfo}></div>
          </div>
          <div className={styles.cardFooter}>
            <span className={styles.badge}>{campaignData.status}</span>

            <div className={clientDetailsStyles.participants}>
              {campaignData.participants.map((participant: any, index: any) => (
                <div key={index} className={clientDetailsStyles.participant}>
                  <div className={clientDetailsStyles.participantAvatar}>
                    {getInitials(participant.first_name, participant.last_name)}
                  </div>
                  <div className={clientDetailsStyles.participantTooltip}>
                    {participant.first_name} {participant.last_name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
