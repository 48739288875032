export const Palette = {
  Green: "#00FD86",
  LightGreen: "#00CC4F",
  DarkGreen: "#00FD86",
  Green80: "#00AD3A",
  Green100: "#007A29",
  Cyan: "#05F9F9",
  DarkCyan: "#02EDED",
  LightCyan: "#00CCCB",
  Red: "#FF5833",
  Red60: "#C42E0E",
  Purple: "#B073FF",
  Purple10: "#E5D9FF",
  Purple30: "#B88CFF",
  Purple60: "#6D36B2",
  Magenta: "#FF4C77",
  Yellow: "#EEFF59",
  LightYellow: "#D9D936",
  Yellow10: "#FBFFD9",
  Yellow30: "#F4FF8C",
  Orange1: "#F0A61B",
  Green1: "#9DFF75",
  Blue: "#329DFF",
  Blue20: "#80D5FF",
  Blue30: "#4DB5FF",
  Blue50: "#0A6FCC",
  Blue60: "#005DB2",
  Teal20: "#31E4F5",
  Teal30: "#00C9F1",
  Black: "#111112",
  CryptoBackground: "#130B1E",
  White: "#FFFFFF",
  Grey10: "#F5F5F6",
  Grey20: "#E0E1E3",
  Grey30: "#C7C9CC",
  Grey40: "#B5B8BF",
  Grey50: "#9B9FA7",
  Grey60: "#4E5157",
  Grey70: "#34363A",
  Grey80: "#1E1F21",
  Grey90: "#202124",
  Red10: "#FFDAD4",
  Green10: "#E0FFEB",
  Transparent: "#00000000",
};

export const FinecoPalette = [
  "#2DB6E9",
  "#EAA133",
  "#0D6EB9",
  "#103779",
  "#8E3309",
  "#0D0816",
  "#83B6BF",
  "#E9D98C",
  "#8A7A54",
  "#53130A",
  "#ABDDE1",
  "#FBFBED",
  "#605B46",
  "#AF957A",
  "#F8F0AF",
  "#DCFBFB",
  "#DCDCDC",
  "#945454",
  "#BDBCBD",
  "#D0D4D4",
  "#C2ECFC",
  "#C4B0B8",
  "#DCCCE4",
  "#D4D4CC",
  "#E4E8E0",
  "#E8D8D8",
  "#DCE0D8",
];
