import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AssistantActionCreatePortfolioType } from "types/assistant/AssistantTypes";
import { FinecoButton } from "../../FinecoButton";
import { AssistantService } from "shared/services/AssistantService";
import {
  Column,
  DataSheetGrid,
  keyColumn,
  textColumn,
} from "react-datasheet-grid";
import "react-datasheet-grid/dist/style.css";
import useThreadsStore from "shared/store/threadsStore";
import { useToast } from "shared/components/ui/use-toast";
import colorsStore from "shared/store/colorsStore";

type ExcelPastOneColumnsProps = {
  messagePortfolioCast: AssistantActionCreatePortfolioType;
};

type Row = {
  isin: string | null;
};

const ExcelPastOneColumns: React.FC<ExcelPastOneColumnsProps> = ({
  messagePortfolioCast,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const resetAction = useThreadsStore((state) => state.resetAction);
  const { t } = useTranslation();
  const { toast } = useToast();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [data, setData] = useState<Row[]>(() => {
    if (
      messagePortfolioCast?.action.input.isins &&
      messagePortfolioCast?.action.input.isins.length > 0
    ) {
      return messagePortfolioCast.action.input.isins.map((isin) => ({
        isin: isin,
      }));
    }
    // If no data, return an empty row
    return [{ isin: "", weight: "" }];
  });

  const columns: Column<Row>[] = [
    {
      ...keyColumn<Row, "isin">("isin", textColumn),
      title: "ISIN",
    },
  ];

  const handleRowChange = (newData: Row[]) => {
    const uppercaseData = newData.map((row) => ({
      ...row,
      isin: row.isin?.toUpperCase() ?? null,
    }));
    setData(uppercaseData);
  };

  const onSubmit = async () => {
    setSubmitting(true);
    const body = {
      isins: data.map((row) => row.isin), // Changed from products to isins
    };
    AssistantService.threadAction(
      messagePortfolioCast.action.thread_id,
      messagePortfolioCast.action.id,
      body,
    )
      .then(() => {
        resetAction(messagePortfolioCast.action.thread_id);
        setSubmitting(false);
        toast({
          title: t("global.success"),
          description: t("assistant.manualPortfolioSuccessOne"),
        });
      })
      .catch((error) => {
        setSubmitting(false);
        toast({
          title: t("global.error"),
          description:
            error.response?.data?.message ||
            error.message ||
            t("assistant.manualPortfolioError"), // Updated to show error message
          variant: "destructive",
        });
      });
  };

  const isSubmitDisabled = data.length === 0 || data.every((row) => !row.isin);

  return (
    <div>
      <h2
        style={{
          color: colorsConfig.finecoBlack,
          marginBottom: "20px",
        }}
      >
        {t("assistant.createManualPortfolioStrumenti")}
      </h2>
      <DataSheetGrid
        value={data}
        onChange={handleRowChange}
        columns={columns}
      />
      <div
        style={{ marginTop: "20px", display: "flex", justifyContent: "end" }}
      >
        <FinecoButton
          click={onSubmit}
          copy={submitting ? t("actions.submitting") : t("actions.submit")}
          disabled={isSubmitDisabled}
        />
      </div>
    </div>
  );
};

export default ExcelPastOneColumns;
