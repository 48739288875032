import { TextResponse } from "pages/assistant/components/chat/components/responses/TextResponse";
import { useEffect } from "react";
import { CustomDataArtifactRecord } from "types/assistant/AssistantTypes";

type Props = {
  data: CustomDataArtifactRecord;
  onLoaded?: () => void;
};

export const FinecoPortfolioStoryArtifact = ({ data, onLoaded }: Props) => {
  useEffect(() => onLoaded?.(), []);

  const text = data?.story;

  if (!text) {
    return null;
  }
  return <TextResponse message={text} />;
};
