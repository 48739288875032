import { create } from "./store";
import {
  AssetChartDataPoint,
  AssetChartQueryParams,
} from "shared/models/asset/AssetModel";
import { AssetsService } from "shared/services/assets/AssetsService";
import { ChartRange } from "shared/utils/types";

interface ChartCache {
  [symbol: string]: {
    [key in ChartRange]?: AssetChartDataPoint[];
  };
}

type AssetChartStoreState = {
  params: AssetChartQueryParams;
  cache: ChartCache;
  selectedIndex?: number;
};

type AssetChartStoreActions = {
  fetchChartBySymbol: (
    symbol: string,
    params: AssetChartQueryParams,
    isOpen: boolean,
  ) => void;
};

const initialData: AssetChartStoreState = {
  params: { range: "date" },
  cache: {},
};

const useAssetChartStore = create<
  AssetChartStoreState & AssetChartStoreActions
>()((set, get) => ({
  ...initialData,
  fetchChartBySymbol: async (symbol, params, isOpen) => {
    try {
      const cached = get().cache[symbol]?.[params.range];
      
      // Always fetch if we don't have data for this symbol and range
      if (!cached || cached.length === 0) {
        const chart = await AssetsService.getChart(symbol, params, isOpen);
        
        set((state) => ({
          cache: {
            ...state.cache,
            [symbol]: {
              ...(state.cache[symbol] || {}),
              [params.range]: chart.data
            }
          }
        }));
      }
    } catch (error) {
      console.error(`Failed to fetch chart data for ${symbol}:`, error);
      // Initialize with empty array if fetch fails
      set((state) => ({
        cache: {
          ...state.cache,
          [symbol]: {
            ...(state.cache[symbol] || {}),
            [params.range]: []
          }
        }
      }));
    }
  },
}));

export default useAssetChartStore;
