import { Input } from "shared/components/ui/input";
import { AddAsset } from "./AddAsset";
import { TrashIcon } from "lucide-react";
import { PropsWithChildren, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTrigger,
} from "shared/components/ui/dialog";
import { Textarea } from "shared/components/ui/textarea";
import useAssets from "shared/hooks/useAssets";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import { PortfoliosService } from "shared/services/portfolios/PortfoliosService";
import { ProAssistantService } from "shared/services/pro_assistant/ProAssistantService";
import usePortfoliosStore from "shared/store/portfoliosStore";
import useProAssistantStore from "shared/store/proAssistantStore";
import { pctFormat } from "shared/utils/currency";
import { cn } from "utils";
import { Button } from "shared/components/controls/Button";
import { ScrollArea } from "shared/components/ui/scroll-area";

type Props = PropsWithChildren & {
  portfolio: Portfolio;
  sessionId?: string;
};

export const EditPortfolioDialog: React.FC<Props> = ({
  portfolio,
  sessionId,
  children,
}) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const { t } = useTranslation();
  const { assets_map } = useAssets();
  const [allocations, setAllocations] = useState<Record<string, string>>({});
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [open, setOpen] = useState(false);
  const { threadId } = useParams();
  const { fetchThread, fetchPortfolios: fetchThreadPortfolios } =
    useProAssistantStore();
  const { fetchPortfolios } = usePortfoliosStore();

  useEffect(() => {
    if (portfolio) {
      setTitle(portfolio.title);
      setDescription(portfolio.description);
      setAllocations(() => {
        let mapped: Record<string, string> = {};
        if (portfolio.allocations) {
          for (let a of portfolio.allocations) {
            mapped[a.symbol] = (parseFloat(a.weight) * 100).toFixed(2);
          }
        }
        return mapped;
      });
    }
  }, []);

  const addAllocation = (symbol: string, value: string) => {
    const newAllocations = { ...allocations };
    newAllocations[symbol] = value;
    setAllocations(newAllocations);
  };

  const removeAllocation = (symbol: string) => {
    const newAllocations = { ...allocations };
    delete newAllocations[symbol];
    setAllocations(newAllocations);
  };

  const save = () => {
    setSaveLoading(true);
    const p = {
      ...portfolio,
    };
    if (description && title) {
      if (threadId) {
        ProAssistantService.savePortfolio(threadId, {
          id: p.id,
          symbol: p.symbol,
          description: description,
          name: title,
          allocations: Object.keys(allocations).map((key) => {
            return {
              symbol: key,
              // @ts-ignore
              weight: (+allocations[key] / 100).toFixed(4),
            };
          }),
        })
          .then(() => {
            setOpen(false);
            fetchThread(threadId);
            fetchThreadPortfolios(threadId);
            toast.success(t("aiAssistant.portfolioSaved"));
          })
          .finally(() => {
            setSaveLoading(false);
          });
      } else {
        PortfoliosService.editAllocations(p.id, {
          allocations: Object.keys(allocations).map((key) => {
            return {
              contract_id: key,
              // @ts-ignore
              weight: (+allocations[key] / 100).toFixed(4),
            };
          }),
        })
          .then(async () => {
            fetchPortfolios();
            setOpen(false);
            toast.success(t("aiAssistant.portfolioSaved"));
          })
          .finally(() => {
            setSaveLoading(false);
          });
      }
    }
  };

  const totalAllocated =
    Object.values(allocations)
      ?.map((item) => {
        return parseFloat(item);
      })
      .reduce((a, b) => a + b, 0) / 100;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          {sessionId && (
            <DialogDescription>
              {t("aiAssistant.editAllocationsDesc")}
            </DialogDescription>
          )}
        </DialogHeader>
        <div className="flex max-h-[80vh] flex-col">
          {(sessionId || threadId) && (
            <>
              <div className="mb-4">
                <div className="mb-2 text-sm font-semibold">
                  {t("aiAssistant.editTitle")}
                </div>
                <Input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <div className="mb-2 text-sm font-semibold">
                  {t("aiAssistant.editDescription")}
                </div>
                <Textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="min-h-[120px]"
                />
              </div>
            </>
          )}
          <h2>{t("aiAssistant.editAllocations")}</h2>
          <div className="mt-4">
            <div className="mb-2 text-sm">
              {t("aiAssistant.totalAllocated")}:
              <span
                className={cn(
                  "ml-1 font-semibold",
                  totalAllocated > 1 ? "text-magenta-30" : "text-green-dark",
                )}
              >
                {pctFormat.format(totalAllocated || 0)}
              </span>
            </div>
            <AddAsset onClick={(symbol) => addAllocation(symbol, "0")} />
          </div>
          <ScrollArea className="mt-8 h-[500px] pr-4">
            <div className="space-y-4">
              {Object.keys(allocations)?.map((symbol) => {
                return (
                  <div
                    className={cn(
                      "rounded-lg",
                      allocations[symbol] === "0" ? "opacity-40" : "",
                    )}
                    key={"allocation_" + symbol}
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="text-sm font-semibold text-black">
                          {symbol}
                        </div>
                        <div className="text-base text-gray-500">
                          {assets_map[symbol]?.name}
                        </div>
                      </div>
                      <div className="flex items-center gap-4 text-xl">
                        <CurrencyInput
                          type="text"
                          suffix="%"
                          value={allocations[symbol] || "0"}
                          onValueChange={(value) => {
                            addAllocation(symbol, value || "0");
                          }}
                          className="ml-4 rounded-lg border-gray-700 bg-transparent text-right font-semibold outline-none focus:border focus:bg-gray-800"
                        />
                        <div
                          className="cursor-pointer duration-150 hover:opacity-60"
                          onClick={() => removeAllocation(symbol)}
                        >
                          <TrashIcon className="size-4 text-gray-500" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </ScrollArea>
          <div className="mt-10 flex items-center justify-end gap-4">
            <Button
              btnStyle="blank"
              onClick={() => setOpen(false)}
              disabled={saveLoading}
              className="min-w-[150px]"
              label={t("global.cancel")}
            />
            <Button
              onClick={save}
              loading={saveLoading}
              disabled={totalAllocated > 1}
              className="min-w-[150px]"
              label={t("actions.save")}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
