import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "shared/components/ui/alert-dialog";
import { Input } from "shared/components/ui/input";
import { Label } from "shared/components/ui/label";
import { AssistantService } from "shared/services/AssistantService";
import {
  ReportSectionsTree,
  ReportSections,
  isReportSubSection,
  ReportSection,
} from "./ReportSectionsTree";
import { FinecoButton } from "pages/assistant/components/FinecoButton";
import colorsStore from "shared/store/colorsStore";

type GenerateReportProps = {
  artifact: {
    financial_plan: number;
    risk_profile: string;
  };
  children: React.ReactNode;
  onGenerate: (data: any, fromReport?: boolean) => void;
};

export const GenerateReport = forwardRef<
  { generate: () => void },
  GenerateReportProps
>(({ artifact, children, onGenerate }, ref) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const { t } = useTranslation();
  const [bankerName, setBankerName] = useState("");
  const [bankerRole, setBankerRole] = useState("");
  const [department, setDepartment] = useState("");
  const [proposedValue, setProposedValue] = useState(100000);
  const [selectedFeeTypes, setSelectedFeeTypes] = useState<string[]>([
    "FEE ON TOP",
  ]);
  const [feeTypes, setFeeTypes] = useState<string[]>(["FEE ON TOP"]);
  const [isLoading, setIsLoading] = useState(true);
  const [reportSections, setReportSections] = useState<ReportSections>({});

  useImperativeHandle(ref, () => ({
    generate: () => {
      handleGenerate(false);
    },
  }));

  useEffect(() => {
    const loadReportParameters = async () => {
      try {
        const response = await AssistantService.fetchReportParameters();
        setBankerName(response.banker_name || "");
        setBankerRole(response.banker_role || "");
        setDepartment(response.department || "");

        // Process sections to add hidden flag
        const processedSections = JSON.parse(
          JSON.stringify(response.report_sections),
        );
        Object.entries(processedSections).forEach(
          ([_, section]: [string, ReportSection]) => {
            if (!section.visible) {
              section.hidden = true;
            }
            Object.entries(section).forEach(([_, value]) => {
              if (isReportSubSection(value) && !value.visible) {
                value.hidden = true;
              }
            });
          },
        );

        setReportSections(processedSections);
      } catch (error) {
        console.error("Failed to load report parameters:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadReportParameters();
  }, []);

  const handleGenerate = (fromReport: boolean = false) => {
    const data = {
      banker_name: bankerName,
      banker_role: bankerRole,
      financial_plan: artifact.financial_plan.toString(),
      mifid_profile: artifact.risk_profile,
      proposed_value: proposedValue,
      fee_types: selectedFeeTypes,
      report_sections: reportSections,
    };
    onGenerate(data, fromReport);
  };

  const hasVisibleSections = () => {
    return Object.values(reportSections).some((section) => section.visible);
  };

  // const handleFeeTypeChange = (feeType: string) => {
  //   setSelectedFeeTypes((prev) => {
  //     if (prev.includes(feeType)) {
  //       return prev.filter((type) => type !== feeType);
  //     } else {
  //       return [...prev, feeType];
  //     }
  //   });
  // };

  const handleFeeTypeChange = (checked: boolean, feeType: string) => {
    setSelectedFeeTypes((prev) => {
      if (!checked) {
        return prev.filter((type) => type !== feeType);
      } else {
        return [...prev, feeType];
      }
    });
  };
  const [open, setOpen] = useState(false);
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogContent
        style={{
          backgroundColor: "white",
          color: colorsConfig.threadHistoryCopy || "#ffffff",
          maxWidth: "600px",
        }}
      >
        <AlertDialogHeader>
          <AlertDialogTitle style={{ color: colorsConfig.black }}>
            {t("generateReport.title")}
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          <div className="space-y-4">
            <div className="grid grid-cols-3 gap-4">
              {/* <div> */}
              {/* <Label
                  htmlFor="bankerName"
                  style={{ color: colorsConfig.finecoBlack }}
                >
                  {t("generateReport.bankerName")}
                </Label>
                <Input
                  id="bankerName"
                  value={bankerName}
                  onChange={(e) => setBankerName(e.target.value)}
                /> */}
              {/* </div> */}
              <div>
                <Label
                  htmlFor="bankerRole"
                  style={{ color: colorsConfig.black }}
                >
                  {t("generateReport.bankerRole")}
                </Label>
                <select
                  id="bankerRole"
                  value={bankerRole}
                  onChange={(e) => setBankerRole(e.target.value)}
                  className="w-full rounded border p-2"
                >
                  <option value="PFA">PFA</option>
                  <option value="Private Banker">Private Banker</option>
                  <option value="Senior Private Banker">
                    Senior Private Banker
                  </option>
                  <option value="Senior Private Banker Ambassador">
                    Senior Private Banker Ambassador
                  </option>
                </select>
              </div>
            </div>
            <div className="mt-8 grid grid-cols-3 gap-4 space-y-2">
              <div>
                <Label
                  htmlFor="proposedValue"
                  style={{ color: colorsConfig.black }}
                >
                  {t("generateReport.proposedValue")}
                </Label>
                <Input
                  id="proposedValue"
                  type="number"
                  value={proposedValue}
                  onChange={(e) => setProposedValue(Number(e.target.value))}
                  className="w-40 text-right"
                />
              </div>
              <div>
                {/* <Label style={{ color: colorsConfig.finecoBlack }}>
                  Fee Types
                </Label> */}
                {/* {feeTypes.map((type) => (
                  <div key={type} className="flex items-center">
                    <CheckboxSquare
                      id={type}
                      checked={selectedFeeTypes.includes(type)}
                      onCheckedChange={(checked) =>
                        handleFeeTypeChange(checked as boolean, type)
                      }
                    />
                    <label
                      htmlFor={type}
                      style={{ color: colorsConfig.finecoBlack, marginLeft: 4 }}
                    >
                      {type}
                    </label>
                  </div>
                ))} */}
              </div>
            </div>
            <div className="mt-8">
              <h3
                className="mb-4 font-medium"
                style={{ color: colorsConfig.black }}
              >
                {t("generateReport.sections")}
              </h3>
              <ReportSectionsTree
                sections={reportSections}
                onChange={setReportSections}
              />
            </div>
          </div>
        </AlertDialogDescription>
        <AlertDialogFooter>
          {/* <AlertDialogCancel style={{ color: colorsConfig.finecoBlack }}>
            {t("global.cancel")}
          </AlertDialogCancel> */}

          <FinecoButton
            click={() => {
              setOpen(false);
            }}
            white={true}
            copy={t("global.cancel").toUpperCase()}
          />
          <FinecoButton
            disabled={!hasVisibleSections()}
            click={() => {
              handleGenerate(true);
              setOpen(false);
            }}
            copy={t("generateReport.generate").toUpperCase()}
          />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
});
