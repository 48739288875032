import { useState } from "react";
import {
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { AxisDomain } from "recharts/types/util/types";
import { BarChartIcon } from "shared/icons/BarChartIcon";
import { pctFormat } from "shared/utils/currency";
import { formatDate } from "shared/utils/date";
import { CompactFormat } from "shared/utils/utils";
import colorsStore from "shared/store/colorsStore";

type Props = {
  data?: any[] | null;
  yKey: string;
  xKey: string;
  pctKey?: string;
  height?: number;
  domain?: AxisDomain;
  xTicks?: number[];
  yTicks?: number[];
  yAxisOrientation?: "left" | "right";
  xFormatter?: (value: any, tooltip?: boolean) => string;
  yFormatter?: (value: any) => string;
  yValueFormatter?: (value: any) => string;
  onZoom?: (left: string, right: string) => void;
};

export type XAxisTickProps = XAxisProps & {
  index: number;
  payload: { value: string };
};

export type YAxisTickProps = XAxisProps & {
  index: number;
  payload: { value: string };
};

export const Chart: React.FC<Props> = ({
  data,
  yKey,
  xKey,
  pctKey,
  domain,
  xTicks,
  yTicks,
  yAxisOrientation,
  height = 300,
  xFormatter,
  yFormatter,
  yValueFormatter,
  onZoom,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const [refArea, setRefArea] = useState<{ left?: string; right?: string }>({
    left: "",
    right: "",
  });

  const tooltipContent = (tooltipProps: TooltipProps<ValueType, NameType>) => {
    const yValue =
      tooltipProps.payload && tooltipProps.payload.length > 0
        ? // @ts-ignore
          tooltipProps.payload[0]["payload"][yKey]
        : "";
    const xValue =
      tooltipProps.payload && tooltipProps.payload.length > 0
        ? // @ts-ignore
          tooltipProps.payload[0]["payload"][xKey]
        : "";
    const pctValue =
      tooltipProps.payload && tooltipProps.payload.length > 0 && pctKey
        ? // @ts-ignore
          tooltipProps.payload[0]["payload"][pctKey]
        : undefined;
    return (
      <div className="rounded-md bg-sb-gray-100 px-2.5 py-1.5 text-base font-medium">
        {yValueFormatter
          ? yValueFormatter(yValue)
          : yFormatter
            ? yFormatter(yValue)
            : CompactFormat.format(yValue)}
        {pctValue && (
          <span
            className={
              "mx-1 " + (pctValue < 0 ? "text-sb-red-500" : "text-sb-green-700")
            }
          >
            ({pctFormat.format(pctValue)})
          </span>
        )}
        {xValue && (
          <span className="text-sb-gray-600">
            {" "}
            {xFormatter ? xFormatter(xValue, true) : formatDate(xValue)}
          </span>
        )}
      </div>
    );
  };

  const placeholderStyle = { height: `${height}px` };

  const xTicksArray = xTicks
    ? xTicks
    : data?.length
      ? [data?.[0]?.[xKey], data?.[data?.length - 1]?.[xKey]]
      : [data?.[0]?.[xKey]];

  return (
    <div className="relative w-full" style={{ height: height || 300 }}>
      <div className="absolute bottom-0 left-0 right-0 top-0">
        {data?.length ? (
          <ResponsiveContainer width="100%" height={height || 300}>
            <LineChart
              height={height}
              data={data}
              margin={{
                top: 10,
                bottom: 5,
                left: 40,
                right: 20,
              }}
              onMouseDown={(e) =>
                onZoom &&
                setRefArea((prev) => {
                  return { ...prev, left: e?.activeLabel };
                })
              }
              onMouseMove={(e) =>
                onZoom &&
                refArea.left &&
                setRefArea((prev) => {
                  return { ...prev, right: e?.activeLabel };
                })
              }
              onMouseUp={() => {
                if (onZoom) {
                  onZoom(refArea.left || "", refArea.right || "");
                  setRefArea({ left: "", right: "" });
                }
              }}
            >
              <defs>
                <linearGradient
                  id="chartGradient"
                  x1="0"
                  y1="0"
                  x2="100%"
                  y2="0"
                >
                  <stop
                    offset="50%"
                    stopColor={colorsConfig["gradient-start"]}
                  />
                  <stop
                    offset="100%"
                    stopColor={colorsConfig["gradient-end"]}
                  />
                </linearGradient>
              </defs>
              <XAxis
                dataKey={xKey}
                tickFormatter={(tick: string) =>
                  xFormatter ? xFormatter(tick) : formatDate(tick)
                }
                ticks={xTicksArray}
                tick={({
                  index,
                  x,
                  y,
                  payload,
                  tickFormatter,
                }: XAxisTickProps) => {
                  const formattedTick = tickFormatter
                    ? tickFormatter(payload.value, index)
                    : null;
                  return (
                    <g>
                      <text
                        x={x}
                        y={y}
                        textAnchor={index === 0 ? "start" : "middle"}
                        fontSize={12}
                      >
                        {formattedTick ? formattedTick : ""}
                      </text>
                    </g>
                  );
                }}
                tickLine={false}
                tickMargin={25}
                strokeWidth={0.5}
              />
              <YAxis
                tickFormatter={(tick: number) =>
                  yFormatter ? yFormatter(tick) : CompactFormat.format(tick)
                }
                domain={domain || ["dataMin", "dataMax"]}
                interval={0}
                ticks={yTicks}
                tickLine={false}
                strokeWidth={0.5}
                orientation={yAxisOrientation || "left"}
              />
              <Tooltip
                content={tooltipContent}
                cursor={{ strokeDasharray: "3 3" }}
              />
              <Line
                strokeWidth={2}
                type="linear"
                dataKey={yKey}
                stroke="url(#chartGradient)"
                dot={false}
                isAnimationActive={true}
              />
              {refArea.left && refArea.right && onZoom ? (
                <ReferenceArea
                  yAxisId="0"
                  x1={refArea.left}
                  x2={refArea.right}
                  strokeOpacity={0.3}
                  // @ts-ignore
                  fill={colorsConfig["sb-gray"][100]}
                />
              ) : null}
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div
            className="grid w-full animate-pulse place-items-center rounded-xl bg-sb-gray-100"
            style={placeholderStyle}
          >
            <BarChartIcon className="h-24 w-24 fill-sb-gray-300 stroke-sb-gray-300" />
          </div>
        )}
      </div>
    </div>
  );
};
