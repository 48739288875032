import { create } from "./store";
import { Loading } from "shared/types/enums";
import { Proposal } from "shared/models/proposals/ProposalsModel";
import { ProposalsService } from "shared/services/proposals/ProposalsService";

type ProposalsStoreState = {
  proposals: Proposal[];
  loading: Loading;
  fetched: boolean;
};

type ProposalsStoreActions = {
  fetchProposals: () => Promise<Proposal[]>;
  fetchProposalById: (proposalId: string) => Promise<void>;
};

const initialData: ProposalsStoreState = {
  proposals: [],
  loading: Loading.Idle,
  fetched: false,
};

const useProposalsStore = create<ProposalsStoreState & ProposalsStoreActions>()(
  (set, get) => ({
    ...initialData,
    fetchProposals: async () => {
      set({ loading: Loading.InProgress });
      try {
        const proposals = await ProposalsService.getMany();
        set({ proposals });
        return proposals;
      } finally {
        set({ loading: Loading.Finished, fetched: true });
      }
    },
    fetchProposalById: async (proposalId: string) => {
      const proposal = await ProposalsService.getOne(proposalId);
      if (proposal) {
        set({
          proposals: [
            ...get().proposals.filter((p) => p.proposal_id !== proposalId),
            proposal,
          ],
        });
      }
    },
  }),
);

export default useProposalsStore;
