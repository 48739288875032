import { useEffect } from "react";
import useAssetStatsStore from "shared/store/assetStatsStore";

const useSupportLevelPrices = (symbol: string): number[] | undefined => {
  const { fetchStatsBySymbol } = useAssetStatsStore();
  const supportLevelPrices = useAssetStatsStore(
    (state) => state.supportLevelsPrices,
  );

  useEffect(() => {
    fetchStatsBySymbol(symbol);
  }, [symbol]);

  return supportLevelPrices;
};

export default useSupportLevelPrices;
