import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import DropdownSelect, {
  DropdownOption,
} from "shared/components/controls/DropdownSelect";
import { Labels } from "shared/components/hoc/Labels";
import { useAvailableCountries } from "shared/hooks/useAvailableCountries";
import { CountryNames } from "shared/types/Countries";

import { LetUsKnowYouColumn } from "./components/LetUsKnowYouColumn";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingPartProps } from "./OnboardingFlow";
import useOnboardingStore from "shared/store/onboardingStore";

export const TaxCountry: React.FC<OnboardingPartProps> = ({
  onNext,
  onPrevious,
}) => {
  const { t } = useTranslation();
  const { taxCountry } = useOnboardingStore((state) => state.data);
  const { update, fetchAvailableCountries } = useOnboardingStore();
  const countries = useAvailableCountries();

  const updateTaxCountry = (value?: any) => {
    update({ taxCountry: value });
  };

  const countryOptions: DropdownOption[] = countries.map(
    (c) =>
      ({
        value: c.code,
        label: CountryNames[c.code],
      }) as DropdownOption,
  );

  const fetchCountries = useCallback(() => {
    fetchAvailableCountries();
  }, []);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  return (
    <OnboardingContainer
      leftColumnContent={<LetUsKnowYouColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1">
            <Labels.H2 className="mb-2">
              {t("forms.kyc.screen_titles.tax_country.title")}
            </Labels.H2>
            <Labels.B1 className="mb-6">
              {t("forms.kyc.screen_titles.tax_country.sub_title")}
            </Labels.B1>
            <DropdownSelect
              selected={taxCountry || ""}
              className="rounded-xl bg-sb-gray-100"
              inputClassName="rounded-xl bg-sb-gray-100 !pb-5 !pt-5 px-5"
              options={countryOptions}
              onSelect={(o) => updateTaxCountry(o.value)}
            />
          </div>
          <OnboardingFooter
            onNext={() => onNext && onNext()}
            onPrev={onPrevious}
            nextDisabled={!taxCountry}
          />
        </div>
      }
    />
  );
};
