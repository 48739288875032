import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import type { Resource } from "i18next";
import English from "./en.json";
import Italian from "./it.json";
import brandStore from "shared/store/brandStore";

const resources: Resource = {
  en: { translation: English },
  it: { translation: Italian },
};

i18n.use(initReactI18next).init({
  debug: false,
  lng: brandStore.getState().languageSetName,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources,
});

brandStore.subscribe((state) => {
  i18n.changeLanguage(state.languageSetName);
});

export default i18n;
