import { create } from "./store";
import { ChatMessageNotification } from "shared/types/Chat";
import { TimestampedRecentSearch } from "shared/hooks/useRecentSearch";
import { AIAssistantSession } from "shared/models/assistant/AIAssistantModel";

type AppStoreState = {
  chatsOpened: string[];
  unseenMessages: ChatMessageNotification[];
  advisorId: string;
  searchHistory: TimestampedRecentSearch[];
  assistantOpen: boolean;
  assistantActiveSession: AIAssistantSession | undefined;
};

type AppStoreActions = {
  toggleChat: (chatId: string) => void;
  openChat: (chatId: string) => void;
  openAiAssistant: () => void;
  toggleAiAssistant: () => void;
  setAssistantActiveSession: (session: AIAssistantSession | undefined) => void;
  setUnseenMessages: (unseenMessages: ChatMessageNotification[]) => void;
  setAdvisorId: (advisorId: string) => void;
  setSearchHistory: (searchHistory: TimestampedRecentSearch[]) => void;
};

const initialData: AppStoreState = {
  chatsOpened: [],
  unseenMessages: [],
  advisorId: "",
  searchHistory: [],
  assistantOpen: false,
  assistantActiveSession: undefined,
};

const useAppStore = create<AppStoreState & AppStoreActions>()((set, get) => ({
  ...initialData,
  toggleChat: async (chatId) => {
    const chatsOpened = get().chatsOpened;
    if (chatsOpened.includes(chatId)) {
      set({ chatsOpened: chatsOpened.filter((id) => id !== chatId) });
    } else {
      set({ chatsOpened: [...chatsOpened, chatId] });
    }
  },
  openChat: async (chatId) => {
    const chatsOpened = get().chatsOpened;
    if (!chatsOpened.includes(chatId)) {
      set({ chatsOpened: [...chatsOpened, chatId] });
    }
  },
  openAiAssistant: () => {
    set({ assistantOpen: true });
  },
  toggleAiAssistant: () => {
    set({ assistantOpen: !get().assistantOpen });
  },
  setAssistantActiveSession: (session) => {
    set({ assistantActiveSession: session });
  },
  setUnseenMessages: (unseenMessages) => {
    set({ unseenMessages });
  },
  setAdvisorId: (advisorId) => {
    set({ advisorId });
  },
  setSearchHistory: (searchHistory) => {
    set({ searchHistory });
  },
}));

export default useAppStore;
