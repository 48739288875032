// CrmPage.tsx
import { useEffect, useState } from "react";
import styles from "./crmPage.module.css";
import crmStore from "shared/store/crmStore";
import classNames from "classnames";
import TodayTaskWidget from "./components/widgets/TodayTaskWidget";
import { AlertWidget } from "./components/widgets/AlertWidget";
import { CalendarWidget } from "./components/widgets/CalendarWidget";
import { SuggestionsTableWidget } from "./components/widgets/SuggestionsTableWidget";
import { GoalsViewWidget } from "./components/widgets/GoalsViewWidget";
import { ActiveCampaignsWidget } from "./components/widgets/ActiveCampaignsWidget";
import { ClientList } from "./components/ClientList";
import { BasePage } from "shared/components/common/BasePage";
import { useTranslation } from "react-i18next";

export const CrmPage: React.FC = () => {
  const { t } = useTranslation();
  const [subtractW, setSubtractW] = useState(180);
  const {
    crmStatus,
    crmActiveTab,
    crmIsPanelOpen,
    setCrmActiveTab,
    setCrmIsPanelOpen,
    fetchClientsList,
  } = crmStore();

  useEffect(() => {
    fetchClientsList();
    window.addEventListener("resize", () => {
      calcSubtractW();
    });
    calcSubtractW();
  }, []);

  const calcSubtractW = () => {
    if (window.innerWidth >= 1280) {
      setSubtractW(180);
    } else {
      setSubtractW(50);
    }
  };

  useEffect(() => {
    if (crmStatus === "conversation") {
      setCrmIsPanelOpen(true);
    }
  }, [crmStatus, setCrmIsPanelOpen]);

  const renderContent = () => {
    switch (crmActiveTab) {
      case "dashboard":
        return (
          <div className={styles.widgetContainer}>
            <TodayTaskWidget />
            {/* <AlertWidget /> */}
            <CalendarWidget />
            <SuggestionsTableWidget />
            <GoalsViewWidget />
            <ActiveCampaignsWidget />
            {/* <ConversationsWidget /> */}
          </div>
        );
      case "clients":
        return (
          <div className={styles.clientsContainer}>
            <ClientList />
          </div>
        );
    }
  };

  return (
    <BasePage hasFooter={false}>
      <div
        style={{
          width: "calc(100% - " + subtractW + "px)",
          position: "absolute",
          height: "calc(100% - 115px)",
        }}
      >
        <div className={styles.tabsContainer}>
          <button
            className={classNames(styles.tabButton, {
              [styles.activeTab]: crmActiveTab === "dashboard",
            })}
            onClick={() => setCrmActiveTab("dashboard")}
          >
            {t("crm.dashboard")}
          </button>
          <button
            className={classNames(styles.tabButton, {
              [styles.activeTab]: crmActiveTab === "clients",
            })}
            onClick={() => setCrmActiveTab("clients")}
          >
            {t("crm.clients")}
          </button>
        </div>

        <div className={styles.crmPageContainer}>
          <div
            className={classNames(styles.conversationsContainer, {
              [styles.open]: crmIsPanelOpen,
            })}
          ></div>
          {renderContent()}
        </div>
      </div>
    </BasePage>
  );
};
