export type RouteComponent =
  | React.ComponentType<any>
  | Record<Role, React.ComponentType<any> | null>;

export interface RouteConfig {
  path: Routes;
  component: RouteComponent;
  acl: {
    [key in Role]: {
      type: DecisionType;
      meta?: string;
    };
  };
}

export enum Role {
  GUEST = "guest",
  FA = "fa",
}

export enum DecisionType {
  ALLOW = "allow",
  REDIRECT = "redirect",
}

export type Routes =
  | "/"
  | "/admin-tools"
  | "/clients"
  | "/clients/:clientId"
  | "/clients/:clientId/portfolios/:portfolioId"
  | "/clients/:clientId/proposals/create"
  | "/clients/add-client"
  | "/contacts"
  | "/contacts/:contactId"
  | "/contacts/add-contact"
  | "/dashboard"
  | "/dashboard/assets/:assetId"
  | "/debug/portfolio-assistant"
  | "/education"
  | "/existing-strategies"
  | "/fee-templates"
  | "/fee-templates/:templateId"
  | "/fee-templates/new"
  | "/fees"
  | "/ibkr-kyc"
  | "/login"
  | "/onboarding"
  | "/onboarding/action_required"
  | "/onboarding/onfido"
  | "/payouts"
  | "/portfolios"
  | "/portfolios/:portfolioId"
  | "/portfolios/:portfolioId/edit"
  | "/portfolios/create"
  | "/proposals"
  | "/proposals/:proposalId"
  | "/subscriptions"
  | "/reporting"
  | "/reports"
  | "/research"
  | "/trade"
  | "/transfer"
  | "/verify-email"
  | "/welcome"
  | "/watchlists"
  | "/assistantWarren"
  | "/assistantWarren/:threadId"
  | "/crm"
  | "/crm/clients"
  | "/crm/clients/:clientId"
  | "/assistant"
  | "/assistant/:threadId"
  | "/updateBrand";
