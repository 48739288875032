import { Trans, useTranslation } from "react-i18next";
import { AlpacaLogo } from "shared/icons/AlpacaLogo";
import { BmoLogo } from "shared/icons/BmoLogo";
import { EvivaLogo } from "shared/icons/EvivaLogo";
import { PageWidthConstraint } from "./PageWidthConstraint";

export const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="bg-black px-12 py-12">
        <PageWidthConstraint>
          <EvivaLogo className="mb-12 w-44 text-white" />
          <div className="flex flex-wrap">
            <div className="flex-1 text-white">
              <Trans i18nKey="footer.description">
                <a
                  className="font-medium underline"
                  href="https://www.bmo.com/en-us/"
                  aria-label="BMO"
                ></a>
                <a
                  className="font-medium underline"
                  href="https://alpaca.markets"
                  aria-label="Alpaca Markets"
                ></a>
              </Trans>
              <div className="mt-8">
                {t("webOnboarding.performanceDisclosure")}
              </div>
              <div className="mt-12 flex gap-8">
                <BmoLogo />
                <AlpacaLogo />
              </div>
            </div>
            <div className="flex-1 space-y-1 text-right text-white">
              <div>
                <a
                  className="text-sb-green-1002 hover:underline"
                  href="https://www.streetbeat.com/disclosure-library"
                >
                  {t("footer.disclosureLibrary")}
                </a>
              </div>
              <div>
                <a
                  className="text-sb-green-1002 hover:underline"
                  href="https://www.streetbeat.com/terms"
                >
                  {t("footer.termsOfService")}
                </a>
              </div>
              <div>
                <a
                  className="text-sb-green-1002 hover:underline"
                  href="https://www.streetbeat.com/privacy"
                >
                  {t("footer.privacyNotice")}
                </a>
              </div>
            </div>
          </div>
        </PageWidthConstraint>
      </div>
      <div className="bg-sb-gray-800 py-4 text-sb-gray-200">
        <PageWidthConstraint>
          <div className="flex flex-wrap justify-center">
            {t("footer.allRightsReserved")}
          </div>
        </PageWidthConstraint>
      </div>
    </div>
  );
};
