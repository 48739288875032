import { ClientOrders } from "modules/client_orders/ClientOrders";
import { ClientPositions } from "modules/client_positions/ClientPositions";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useClientsStore from "shared/store/clientsStore";

type Props = {
  clientId?: string;
};

export const ClientHoldings: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const client = useClientsStore((state) =>
    state.clients.find((c) => String(c.user_id) === String(clientId)),
  );
  const { fetchClientById } = useClientsStore();

  useEffect(() => {
    if (!client && clientId) {
      fetchClientById(clientId);
    }
  }, [client, clientId]);

  if (client && !client.summary.account_name) {
    return (
      <div className="text-xl font-semibold">
        {t("clientDetails.noAccount")}
      </div>
    );
  }

  return (
    <div>
      {clientId ? (
        <div className="space-y-8">
          <ClientPositions clientId={clientId} />
          <ClientOrders clientId={clientId} />
        </div>
      ) : (
        <div className="text-xl font-semibold">
          {t("clientDetails.noAccount")}
        </div>
      )}
    </div>
  );
};
