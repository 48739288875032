import React, { useState } from "react";
import { Search, Plus } from "lucide-react";
import { ClientDetailType } from "types/crm/ClientTypes";
import { formatDateOnlyDate, getInitials } from "utils/misc";
import styles from "./clientDetailsNotes.module.css";
import clientDetailsStyles from "./../crmClientDetailsPage.module.css";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { PrismCode } from "react-prism";
import rehypeKatex from "rehype-katex";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import colorsStore from "shared/store/colorsStore";
import { NoteForm } from "./NoteForm";
import { useTranslation } from "react-i18next";

type ClientDetailsNotesProps = {
  clientData: ClientDetailType;
};

export const ClientDetailsNotes: React.FC<ClientDetailsNotesProps> = ({
  clientData,
}) => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");
  const [showNewNoteForm, setShowNewNoteForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState<any>(null);
  const notes = clientData.notes;

  const filteredNotes = notes.filter((note) => {
    const searchFields = [note.source, note.content].map((field) =>
      (field || "").toLowerCase(),
    );

    return searchFields.some((field) =>
      field.includes(searchTerm.toLowerCase()),
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.searchContainer}>
          <Search size={16} className={styles.searchIcon} />
          <input
            type="text"
            placeholder={t("crm.searchInNotesOrContent")}
            className={styles.searchInput}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <button
          className={`${styles.addButton}`}
          onClick={() => setShowNewNoteForm(true)}
          style={{ textAlign: "right" }}
        >
          <div style={{ display: "flex" }}>
            <Plus size={18} style={{ marginRight: 5 }} /> {t("crm.addNewNote")}
          </div>
        </button>
      </div>
      <div>
        {filteredNotes.length === 0 && (
          <div className={styles.noResults}>Nessun risultato trovato</div>
        )}
        {filteredNotes.length > 0 && (
          <>
            {filteredNotes.map((note) => (
              <NoteCard
                key={note.note_id}
                note={note}
                onEdit={() => setShowEditForm(note)}
              />
            ))}
          </>
        )}
      </div>

      {showNewNoteForm && (
        <NoteForm
          onClose={() => setShowNewNoteForm(false)}
          onNoteAdded={() => {
            // TODO: Refresh notes
            setShowNewNoteForm(false);
          }}
          clientData={clientData}
        />
      )}

      {showEditForm && (
        <NoteForm
          onClose={() => setShowEditForm(null)}
          onNoteAdded={() => {
            // TODO: Refresh notes
            setShowEditForm(null);
          }}
          initialNote={showEditForm}
          clientData={clientData}
        />
      )}
    </div>
  );
};

// @ts-ignore
const NoteCard = ({ note, onEdit }) => {
  const { t } = useTranslation();

  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const navigate = useNavigate();
  const callClient = (clientId: string) => {
    navigate(`/crm/clients/${clientId}`);
  };

  const renderers = {
    pre(props: any) {
      const className = props.node.children?.[0]?.properties?.className?.[0];
      return (
        <PrismCode
          component="pre"
          className={"rounded-xl " + className}
          {...props.attributes}
        >
          {props.children}
        </PrismCode>
      );
    },
    img(props: any) {
      return (
        <div className="my-8 flex justify-center">
          <img {...props} />
        </div>
      );
    },
    // @ts-ignore
    p: ({ node, ...props }) => <p className={styles.copy} {...props} />,
    // @ts-ignore
    h1: ({ node, ...props }) => (
      // @ts-ignore
      <h1 style={{ color: colorsConfig.black }} {...props} />
    ),
    // @ts-ignore
    h2: ({ node, ...props }) => (
      // @ts-ignore
      <h2 style={{ color: colorsConfig.black }} {...props} />
    ),
    // @ts-ignore
    h3: ({ node, ...props }) => (
      // @ts-ignore
      <h3 style={{ color: colorsConfig.black }} {...props} />
    ),
    // @ts-ignore
    strong: ({ node, ...props }) => (
      // @ts-ignore
      <strong style={{ color: colorsConfig.black }} {...props} />
    ),
  };

  return (
    <div key={note.note_id} className={styles.cardWrapper}>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <div className={styles.cardHeader}>
            <div className={styles.titleContainer}>
              <h3 className={styles.title}>{note.source}</h3>
              <button onClick={onEdit} className={styles.editButton}>
                {t("crm.edit")}
              </button>
            </div>
            <span className={styles.date}>
              {formatDateOnlyDate(note.updated_at)}
            </span>

            <ReactMarkdown
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeKatex]}
              // @ts-ignore
              components={renderers}
            >
              {note.content}
            </ReactMarkdown>
          </div>
          <div className={styles.cardFooter}>
            <div className={clientDetailsStyles.participants}>
              {note.participants.map((participant: any, index: any) => (
                <div key={index} className={clientDetailsStyles.participant}>
                  <div
                    onClick={() => {
                      callClient(participant.client_id);
                    }}
                    className={clientDetailsStyles.participantAvatar}
                  >
                    {getInitials(participant.first_name, participant.last_name)}
                  </div>
                  <div className={clientDetailsStyles.participantTooltip}>
                    {participant.first_name} {participant.last_name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
