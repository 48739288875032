import * as React from "react";
import colorsStore from "shared/store/colorsStore";
import { cn } from "utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    const colorsConfig = colorsStore((state) => state.colorsConfigStore);
    return (
      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-md border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-zinc-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-zinc-800 dark:bg-zinc-950 dark:ring-offset-zinc-950 dark:placeholder:text-zinc-400 dark:focus-visible:ring-zinc-300",
          className,
        )}
        ref={ref}
        {...props}
        style={{
          backgroundColor: colorsConfig.white,
          color: colorsConfig.finecoBlack,
          borderColor: colorsConfig.gray2,
        }}
      />
    );
  },
);
Input.displayName = "Input";

export { Input };
