import React, { useState, useRef, useEffect } from "react";
import { Clock, MapPin, ChevronLeft, ChevronRight, X } from "lucide-react";
import styles from "./todayTaskWidget.module.css";
import crmStore from "shared/store/crmStore";
import { formatTimeRange } from "utils/misc";
import crmStyles from "./../../crmPage.module.css";
import { Participants } from "./Partecipants";
import { ParticipantsPopup } from "./ParticipantsPopup";
import { useTranslation } from "react-i18next";

const CARD_WIDTH = 300; // Fixed card width
const CARD_GAP = 16; // Gap between cards

const DailyTasks = () => {
  const { t } = useTranslation();
  //** store */
  const { tasks, fetchTasks } = crmStore();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(1);
  const containerRef = useRef(null);
  const [selectedParticipants, setSelectedParticipants] = useState(null);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    initialFetch();
  }, []);

  const initialFetch = async () => {
    await fetchTasks("today");
    setFetching(false);
  };

  useEffect(() => {
    const updateVisibleCards = () => {
      if (!containerRef.current) return;

      // @ts-ignore
      const containerWidth = containerRef.current.offsetWidth;
      // Calculate how many cards can fit
      const possibleCards = Math.floor(
        containerWidth / (CARD_WIDTH + CARD_GAP),
      );
      setVisibleCards(Math.max(1, possibleCards));
    };

    const resizeObserver = new ResizeObserver(updateVisibleCards);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  // This ensures currentIndex stays valid when tasks length changes
  useEffect(() => {
    if (tasks.length > 0) {
      setCurrentIndex((prev) => Math.min(prev, tasks.length - 1));
    }
  }, [tasks.length]);

  // Calculate maxIndex more precisely
  const maxIndex = Math.max(0, tasks.length - visibleCards);

  const slideNext = () => {
    setCurrentIndex((prev) => Math.min(prev + visibleCards, maxIndex));
  };

  const slidePrev = () => {
    setCurrentIndex((prev) => Math.max(prev - visibleCards, 0));
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>{t("crm.todayTasks")}</h2>
        </div>

        <div className={styles.cardsWrapper} ref={containerRef}>
          <div
            className={styles.cardsContainer}
            style={{
              transform: `translateX(-${
                currentIndex * (CARD_WIDTH + CARD_GAP)
              }px)`,
            }}
          >
            {tasks.length === 0 && !fetching && (
              <div className={crmStyles.noContent}>
                {t("crm.noContentAvailable")}
              </div>
            )}
            {tasks.length === 0 && fetching && (
              <div className={crmStyles.noContent}>{t("crm.fetching")}</div>
            )}
            {tasks.length > 0 && (
              <>
                {tasks.map((task, index) => (
                  // @ts-ignore
                  <TaskCard
                    key={index}
                    {...task}
                    onShowParticipants={setSelectedParticipants}
                  />
                ))}
              </>
            )}
          </div>
        </div>

        {tasks.length > visibleCards && (
          <div className={styles.navigation}>
            <button
              onClick={slidePrev}
              disabled={currentIndex === 0}
              className={styles.navButton}
            >
              <ChevronLeft size={20} />
            </button>
            <button
              onClick={slideNext}
              disabled={currentIndex === maxIndex}
              className={styles.navButton}
            >
              <ChevronRight size={20} />
            </button>
          </div>
        )}
      </div>

      {selectedParticipants && (
        // <div className={styles.popup}>
        //   <div className={styles.popupContent}>
        //     <div
        //       className={styles.closeIcon}
        //       onClick={() => setSelectedParticipants(null)}
        //     >
        //       <X size={18} />
        //     </div>
        //     {selectedParticipants.map((participant, index) => (
        //       <div
        //         key={index}
        //         className={styles.eventItem}
        //         onClick={() => {
        //           navigate(`/crm/clients/${participant.client_id}`);
        //           setSelectedParticipants(null);
        //         }}
        //       >
        //         <div className={styles.eventTitle}>
        //           {participant.first_name} {participant.last_name}
        //         </div>
        //       </div>
        //     ))}
        //     <button
        //       className={styles.closeButton}
        //       onClick={() => setSelectedParticipants(null)}
        //     >
        //       Close
        //     </button>
        //   </div>
        // </div>
        <ParticipantsPopup
          setSelectedParticipants={setSelectedParticipants}
          selectedParticipants={selectedParticipants}
        />
      )}
    </>
  );
};

const TaskCard = ({
  title,
  time_start,
  time_end,
  location,
  event_type,
  participants = [],
  onShowParticipants,
}) => {
  const time = formatTimeRange(time_start, time_end);

  const parts = [...participants];

  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.cardHeader}>
          <h3 className={styles.cardTitle}>{title}</h3>
          <div className={styles.cardInfo}>
            <Clock size={16} />
            <span>{time}</span>
          </div>
          <div className={styles.cardInfo}>
            <MapPin size={16} />
            <span>{location}</span>
          </div>
        </div>
        <div className={styles.cardFooter}>
          <span className={styles.badge}>{event_type}</span>
          <Participants
            participants={parts}
            onShowParticipants={onShowParticipants}
          />
        </div>
      </div>
    </div>
  );
};

export default DailyTasks;
