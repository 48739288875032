import { Contact } from "modules/brokerage_account/Contact";
import { Employment } from "modules/brokerage_account/Employment";
import { Identity } from "modules/brokerage_account/Identity";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Layouts } from "shared/components/hoc/Layouts";
import useClientsStore from "shared/store/clientsStore";

type Props = {
  clientId?: string;
};

export const ClientBrokerageAccount: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const client = useClientsStore((state) =>
    state.clients.find((c) => String(c.user_id) === String(clientId)),
  );
  const fetchClientById = useClientsStore((state) => state.fetchClientById);

  const brokerageAccount = client?.summary.alpaca_details?.brokerage_account;
  const identity = brokerageAccount?.identity;
  const contact = brokerageAccount?.contact;

  useEffect(() => {
    if (!client && clientId) {
      fetchClientById(clientId);
    }
  }, [client, clientId]);

  if (client && !client.summary.account_name) {
    return (
      <div className="text-xl font-semibold">
        {t("clientDetails.noAccount")}
      </div>
    );
  }

  return (
    <Layouts.Spaced>
      <Identity identity={identity} />
      <Contact contact={contact} />
      <Employment brokerageAccount={brokerageAccount} />
    </Layouts.Spaced>
  );
};
