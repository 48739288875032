import { create } from "./store";
import { brandConfig } from "config/brand-config";
import colorsStore from "./colorsStore";
import { BrandType, DisabledFeaturesType } from "types/BrandTypes";
type BrandStoreState = {
  brandName: string;
  brandImage: string;
  brandImageType: string;
  brandLoading: boolean;
  languageSetName: string;
  colorSetName: string;
  aiChatLogo: string;
  promptsSet: string;
  disabledFeatures: DisabledFeaturesType;
};

type ConfigSetType = {
  language?: string;
  colorSet?: string;
  aiChatLogo?: string;
  prompts?: string;
  disabledFeatures?: DisabledFeaturesType;
};

type BrandStoreActions = {
  setBrandData: (currentBrand: BrandType) => void;
  setBrandLoading: (brandLoading: boolean) => void;
};

const initialData: BrandStoreState = {
  brandName: "default",
  brandImage: "",
  brandImageType: "",
  brandLoading: false,
  languageSetName: brandConfig.default.language,
  colorSetName: brandConfig.default.colorSet,
  aiChatLogo: brandConfig.default.aiChatLogo,
  promptsSet: brandConfig.default.prompts,
  disabledFeatures: brandConfig.default.disabledFeatures,
};

const brandStore = create<BrandStoreState & BrandStoreActions>()(
  (set, get) => ({
    ...initialData,
    setBrandLoading: (brandLoading: boolean) => {
      set({ brandLoading: brandLoading });
    },
    setBrandData: (currentBrand: BrandType) => {
      //** be sure we get proper values */
      const {
        languageSet,
        colorSet,
        aiChatLogo,
        promptsSet,
        disabledFeatures,
      } = getConfig(currentBrand.name);

      //** store colors object */
      colorsStore.getState().setColorsConfigStore(colorSet, "dark");

      //** store anything else here */
      set({
        brandName: currentBrand.name,
        brandImage: currentBrand.image,
        brandImageType: currentBrand.imageType,
        languageSetName: languageSet,
        aiChatLogo: aiChatLogo,
        promptsSet: promptsSet,
        disabledFeatures: disabledFeatures,
      });
    },
  }),
);

const getConfig = (brand: string) => {
  let configSet: ConfigSetType = brandConfig[brand];
  const configSetDefault: ConfigSetType = brandConfig.default;
  if (!configSet) {
    configSet = configSetDefault;
  }

  //** language */
  let languageSet = configSet.language;
  if (!languageSet) {
    languageSet = configSetDefault.language;
  }

  //** color set */
  let colorSet = configSet.colorSet;
  if (!colorSet) {
    colorSet = configSetDefault.colorSet;
  }

  //** ai chat logo */
  let aiChatLogo = configSet.aiChatLogo;
  if (!aiChatLogo) {
    aiChatLogo = configSetDefault.aiChatLogo;
  }

  //** prompts */
  let promptsSet = configSet.prompts;
  if (!promptsSet) {
    promptsSet = configSetDefault.prompts;
  }

  //** disabled features */
  let disabledFeatures = configSet.disabledFeatures;
  if (!disabledFeatures) {
    disabledFeatures = configSetDefault.disabledFeatures;
  }

  return { languageSet, colorSet, aiChatLogo, promptsSet, disabledFeatures };
};

export default brandStore;
