import { DateTime } from "luxon";
import { AssetChartDataPoint } from "shared/models/asset/AssetModel";
import { MarketInfo } from "shared/models/market/MarketModel";
import { ChartRange } from "shared/utils/types";

const generateFullHourTimeStamps = (
  startTimestamp: number,
  endTimestamp: number,
) => {
  const intervalInMinutes = 60;
  const startDateTime = DateTime.fromSeconds(startTimestamp);
  const endDateTime = DateTime.fromSeconds(endTimestamp);

  const fullTimeStamps = [];

  let currentDateTime = startDateTime.startOf("hour");

  while (currentDateTime <= endDateTime) {
    fullTimeStamps.push(currentDateTime.toSeconds());
    currentDateTime = currentDateTime.plus({ minutes: intervalInMinutes });
    if (currentDateTime.minute !== 0) {
      currentDateTime = currentDateTime.startOf("hour").plus({ hours: 1 });
    }
  }

  return fullTimeStamps;
};

export const generateXTicks = (
  chartData?: AssetChartDataPoint[],
  range?: ChartRange,
) => {
  if (!chartData?.length) return [0, 0];
  return chartData?.length && range === "date"
    ? [
        ...generateFullHourTimeStamps(
          // @ts-ignore
          chartData[0].date - 7 * 3600,
          // @ts-ignore
          chartData[0].date + 7 * 3600,
        ),
      ]
    : // @ts-ignore
      [chartData[0].date, chartData[chartData.length - 1].date];
};

export const getDataMinMax = (chartData: AssetChartDataPoint[]) => {
  const max = Math.max(...chartData.map((d) => d.latestPrice || 0)) * 1.02;
  const min = Math.min(...chartData.map((d) => d.latestPrice || 0)) * 0.98;
  return [min, max];
};

export const generateYTicks = (chartData: AssetChartDataPoint[]) => {
  if (!chartData?.length) return [0, 0];
  const [min, max] = getDataMinMax(chartData);
  // @ts-ignore
  const d = max - min;
  const interval = d / 3;
  // @ts-ignore
  const results = [min, min, min + interval, max - interval, max];
  const latestPrice = chartData?.[chartData.length - 1]?.latestPrice;
  if (latestPrice) {
    results.push(latestPrice);
  }
  return results;
};

/**
 * Maps y value to value on y axis. First it normalizes the value in relative position on the axis (0-1)
 * and then we can add this value to the min of axis to get the y axis value for given y coordinate
 */
export const calculateYToYAxisValue = (
  chartData: AssetChartDataPoint[],
  y: number,
  axisHeight: number,
) => {
  const [min, max] = getDataMinMax(chartData);
  const normalizedVal = (axisHeight - y) / axisHeight;
  // @ts-ignore
  const amountDistance = max - min;
  // @ts-ignore
  return normalizedVal * amountDistance + min;
};

/*
 * It does opposite of the previous function
 */
export const calculateYAxisValueToY = (
  chartData: AssetChartDataPoint[],
  r: number,
  axisHeight: number,
) => {
  const [min, max] = getDataMinMax(chartData);
  // @ts-ignore
  const amountDistance = max - min;
  // @ts-ignore
  const normalizedVal = (r - min) / amountDistance;
  return axisHeight - normalizedVal * axisHeight;
};

export const getFilledChartData = (
  chartData?: AssetChartDataPoint[],
  marketInfo?: MarketInfo | null,
) => {
  const fullMarketPoints = 390;
  let marketPoints = fullMarketPoints;
  if (marketInfo) {
    const timestamp = DateTime.fromISO(marketInfo.timestamp);
    const nextClose = DateTime.fromISO(marketInfo.next_close);
    const minutesDifference = Math.ceil(
      nextClose.diff(timestamp, "minutes").toObject().minutes ||
        fullMarketPoints,
    );
    marketPoints = 2 + minutesDifference + (chartData?.length || 0);
  }
  if (chartData && chartData.length < marketPoints) {
    let startingDate = chartData[chartData.length - 1]?.date || 0;
    const toAppend = [];
    for (let i = chartData.length; i < marketPoints; i++) {
      startingDate += 60;
      toAppend.push({
        date: startingDate,
        latestPrice: undefined,
        previousClose: undefined,
      });
    }
    return [...chartData, ...toAppend];
  }
  return chartData;
};
