import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import { useAccount } from "shared/hooks/useAccount";
import useAccountStore from "shared/store/accountStore";
import useClientsStore from "shared/store/clientsStore";
import { CurrencyFormat } from "shared/utils/currency";

type Props = {
  clientId?: string;
};

export const ClientCash: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const client = useClientsStore((state) =>
    state.clients.find((client) => String(client.user_id) === String(clientId)),
  );
  const { account } = useAccount();
  const { fetchAccount } = useAccountStore();

  useEffect(() => {
    if (!account) {
      fetchAccount();
    }
  }, [clientId, account]);

  const tradingAccount = clientId ? client?.trading : account?.client.trading;

  const cash = parseFloat(tradingAccount?.cash || "0");
  const equity = parseFloat(tradingAccount?.equity || "0");
  const buying_power = parseFloat(tradingAccount?.buying_power || "0");

  return (
    <Panel className="w-full">
      <Layouts.Spaced>
        <div>
          <div className="text-base">{t("clientCash.cash")}</div>
          <div className="text-3xl font-medium">
            {CurrencyFormat.format(cash)}
          </div>
        </div>
        <div>
          <div>{t("clientCash.equity")}</div>
          <div className="text-3xl font-medium">
            {CurrencyFormat.format(equity)}
          </div>
        </div>
        <div>
          <div>{t("clientCash.buying_power")}</div>
          <div className="text-3xl font-medium">
            {CurrencyFormat.format(buying_power)}
          </div>
        </div>
      </Layouts.Spaced>
    </Panel>
  );
};
