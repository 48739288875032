type Props = {
  className?: string;
};

export const AttachmentsIcon: React.FC<Props> = ({ className }) => {
  return (
    <>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_581_19372"
          style={{ maskType: "luminance" }} // Fixed: Changed "mask-type:luminance" to proper object syntax
          maskUnits="userSpaceOnUse"
          x="1"
          y="1"
          width="22"
          height="22"
        >
          <path d="M23 1H1V23H23V1Z" fill="white" />
        </mask>
        <g mask="url(#mask0_581_19372)">
          <path
            d="M14.5781 6.15625V15.2656C14.5781 16.6895 13.4239 17.8438 12 17.8438C10.5761 17.8438 9.42188 16.6895 9.42188 15.2656V6.58594C9.42188 3.97554 11.538 1.85938 14.1484 1.85938C16.7588 1.85938 18.875 3.97554 18.875 6.58594V15.2656C18.875 19.0626 15.797 22.1406 12 22.1406C8.20302 22.1406 5.125 19.0626 5.125 15.2656V6.15625"
            stroke="#1C2226"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
};
