import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BrokerageAccountDocumentUpload,
  DocumentType,
} from "shared/models/brokerage_account/BrokerageAccountModel";
import { BrokerageAccountService } from "shared/services/brokerage_account/BrokerageAccountService";

type UploadState = "idle" | "processing" | "finished";

export function useUploadDocuments() {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState<UploadState>("idle");

  const DocumentTypes: { label: string; value: DocumentType }[] = [
    {
      label: t("documents.identity_verification"),
      value: "identity_verification",
    },
    {
      label: t("documents.address_verification"),
      value: "address_verification",
    },
    {
      label: t("documents.date_of_birth_verification"),
      value: "date_of_birth_verification",
    },
    {
      label: t("documents.tax_id_verification"),
      value: "tax_id_verification",
    },
    {
      label: t("documents.account_approval_letter"),
      value: "account_approval_letter",
    },
    {
      label: t("documents.w8ben"),
      value: "w8ben",
    },
  ];

  const upload = async (document: BrokerageAccountDocumentUpload) => {
    setUploading("processing");
    try {
      await BrokerageAccountService.uploadDocuments([document]);
    } finally {
      setUploading("finished");
    }
  };

  return {
    DocumentTypes,
    uploading,
    upload,
  };
}
