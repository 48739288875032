import React from "react";
import { BackButton } from "shared/components/common/BackButton";
import { Client } from "shared/models/client/ClientModel";
import { CheckBadge } from "shared/icons/CheckBadge";
import { Skeleton } from "shared/components/common/Skeleton";
import { useTranslation } from "react-i18next";
import { CurrencyFormat } from "shared/utils/currency";

type Props = {
  client?: Client;
  backButton?: boolean;
};

export const ClientHeader: React.FC<Props> = ({ client, backButton }) => {
  const { t } = useTranslation();

  const loading = client === undefined;

  const tradingAccount = client?.trading;

  const cash = parseFloat(tradingAccount?.cash || "0");
  const equity = parseFloat(tradingAccount?.equity || "0");
  const buying_power = parseFloat(tradingAccount?.buying_power || "0");

  return (
    <div>
      <div className="flex items-center gap-2">
        {loading ? (
          <Skeleton className="h-10 w-80 bg-sb-gray-200" />
        ) : (
          <>
            <div className="text-2xl font-semibold">
              {client?.summary.client_name}
            </div>
            <CheckBadge className="h-6 w-6 text-sb-green-700" />
          </>
        )}
      </div>
      <div className="mt-2.5 text-base text-sb-gray-900">
        {loading ? (
          <Skeleton className="h-5 w-40 bg-sb-gray-200" />
        ) : (
          <>
            {t("clientCash.cash")}: {CurrencyFormat.format(cash)} •{" "}
            {t("clientCash.equity")}: {CurrencyFormat.format(equity)} •{" "}
            {t("clientCash.buying_power")}:{" "}
            {CurrencyFormat.format(buying_power)}
          </>
        )}
      </div>
      <div className="mt-2.5 text-base text-sb-gray-500">
        {loading ? (
          <Skeleton className="h-5 w-40 bg-sb-gray-200" />
        ) : (
          <>
            {client?.summary.account_name} •{" "}
            {client?.summary.risk_level || "Moderate"}
          </>
        )}
      </div>
      {backButton && (
        <div className="mt-4">
          <BackButton />
        </div>
      )}
    </div>
  );
};
