import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Chart } from "shared/components/common/chart/Chart";
import { ChartPeriodSelector } from "shared/components/common/chart/ChartPeriodSelector";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { convertPercentage } from "shared/utils/converts";

import { BacktestingMetric } from "./components/BacktestingMetric";
import {
  Portfolio,
  PortfolioSimulatedMetrics,
  PortfolioSimulationPeriod,
} from "shared/models/portfolio/PortfolioModel";
import useBacktestingPortfolioChartsStore from "shared/store/backtestingPortfolioChartsStore";

interface Props {
  portfolio?: Portfolio;
}

export const PortfolioBacktestingChart: React.FC<Props> = ({ portfolio }) => {
  const { t } = useTranslation();

  const [selectedPeriod, setSelectedPeriod] =
    useState<PortfolioSimulationPeriod>("last_year");

  const chartData = useBacktestingPortfolioChartsStore(
    (state) => state.cache[portfolio?.id || ""],
  );
  const { fetchBacktestingPortfolioCharts } =
    useBacktestingPortfolioChartsStore();
  const checkedChartData = (chartData && chartData[selectedPeriod]) || [];

  const convertedDatesChartData = checkedChartData.map((cd) => ({
    ...cd,
    date: DateTime.fromFormat(cd.date, "yyyy-MM-dd").toISO(),
  }));

  const getPortfolioMetric = (
    period: PortfolioSimulationPeriod,
    metricName: keyof PortfolioSimulatedMetrics,
  ): number => {
    if (!portfolio && !period) {
      return 0;
    }

    if (!portfolio?.simulated_metrics) return 0;
    const metric = portfolio?.simulated_metrics[period][metricName];
    return +metric || 0;
  };

  const periods: { id: PortfolioSimulationPeriod; text: string }[] = [
    {
      id: "last_month",
      text: t("aggregatedAum.period.oneMonth"),
    },
    {
      id: "last_three_months",
      text: t("aggregatedAum.period.threeMonths"),
    },
    {
      id: "last_six_months",
      text: t("aggregatedAum.period.sixMonths"),
    },
    {
      id: "last_year",
      text: t("aggregatedAum.period.oneYear"),
    },
    {
      id: "all_time",
      text: t("aggregatedAum.period.all"),
    },
  ];

  const histReturn = getPortfolioMetric(selectedPeriod, "pnl");
  const histReturnConverted = convertPercentage(histReturn);

  const cagr = getPortfolioMetric(selectedPeriod, "cagr");
  const cagrConverted = convertPercentage(cagr);

  const sharpeRatio = getPortfolioMetric(selectedPeriod, "sharpe_ratio");

  const fetchStrategyChart = useCallback(
    async (chartPeriod: PortfolioSimulationPeriod) => {
      if (!portfolio?.id) return;
      await fetchBacktestingPortfolioCharts(portfolio.id, {
        period: chartPeriod,
      });
    },
    [portfolio?.id],
  );

  useEffect(() => {
    if (!convertedDatesChartData.length) {
      fetchStrategyChart(selectedPeriod);
    }
  }, [convertedDatesChartData.length, fetchStrategyChart, selectedPeriod]);

  return (
    <Panel>
      <Labels.H3>{t("portfolioBacktestingChart.title")}</Labels.H3>
      <div className="mb-8 flex items-center justify-between">
        <BacktestingMetric
          label="portfolioBacktestingChart.histReturn"
          value={histReturnConverted}
          numValue={histReturn}
        />
        {["last_year", "all_time"].includes(selectedPeriod) && (
          <BacktestingMetric
            label="portfolioBacktestingChart.cagr"
            value={cagrConverted}
            numValue={cagr}
          />
        )}
        <BacktestingMetric
          label="portfolioBacktestingChart.sharpeRatio"
          value={`${sharpeRatio}`}
          numValue={sharpeRatio}
        />
      </div>
      <div className="mb-8">
        <Chart
          xKey="date"
          height={150}
          yKey="cash"
          yFormatter={(value) => (parseFloat(value) * 100).toFixed(2) + "%"}
          data={convertedDatesChartData}
        />
      </div>
      <ChartPeriodSelector
        periods={periods}
        selectedPeriod={selectedPeriod}
        onChange={setSelectedPeriod}
      />
    </Panel>
  );
};
