import { create } from "./store";
import { OnboardingPart } from "pages/fa/onboarding/OnboardingFlow";
import { Onboarding } from "shared/models/onboarding/OnboardingModel";
import CountriesService from "shared/services/fa_onboarding/CountriesService";

enum OnboardingStatusEnum {
  NotStarted = "NOT_STARTED",
  Started = "STARTED",
  Finished = "FINISHED",
}

type OnboardingStoreState = {
  status: OnboardingStatusEnum;
  data: Onboarding;
  availableCountries: string[];
  filledSteps: (keyof Onboarding)[];
  activeOnboardingPart?: OnboardingPart;
  phoneNumber?: string;
  advisorRegistrationNumber: string;
};

type OnboardingStoreActions = {
  reset: () => void;
  clearOnboarding: () => void;
  updateStatus: (status: OnboardingStatusEnum) => void;
  update: (updates: Partial<Onboarding>) => void;
  updateFilledSteps: (filledStep: keyof Onboarding) => void;
  updateOnboardingPart: (onboardingPart: OnboardingPart) => void;
  updatePhoneNumber: (phoneNumber: string) => void;
  updateAdvisorRegistrationNumber: (advisorRegistrationNumber: string) => void;
  fetchAvailableCountries: () => void;
};

const initialData: OnboardingStoreState = {
  status: OnboardingStatusEnum.NotStarted,
  data: {},
  availableCountries: [],
  filledSteps: [],
  activeOnboardingPart: "tax_country",
  advisorRegistrationNumber: "",
};

const useOnboardingStore = create<
  OnboardingStoreState & OnboardingStoreActions
>()((set, get) => ({
  ...initialData,
  updateStatus: (status: OnboardingStatusEnum) => {
    set({ status });
  },
  update: (updates: Partial<Onboarding>) => {
    set({ data: { ...get().data, ...updates } });
  },
  updateFilledSteps: (filledStep: keyof Onboarding) => {
    set({
      filledSteps: [...get().filledSteps, filledStep],
    });
  },
  updateOnboardingPart: (onboardingPart: OnboardingPart) => {
    set({ activeOnboardingPart: onboardingPart });
  },
  updatePhoneNumber: (phoneNumber: string) => {
    set({ phoneNumber });
  },
  updateAdvisorRegistrationNumber: (advisorRegistrationNumber: string) => {
    set({ advisorRegistrationNumber });
  },
  reset: () => {
    set({ status: OnboardingStatusEnum.NotStarted, data: {}, filledSteps: [] });
  },
  clearOnboarding: () => {
    set(initialData);
  },
  fetchAvailableCountries: async () => {
    const availableCountries = await CountriesService.get();
    set({ availableCountries });
  },
}));

export default useOnboardingStore;
