import { CompactAssetDescription } from "modules/asset_info/CompactAssetDescription";
import { CompactAssetInfo } from "modules/asset_info/CompactAssetInfo";
import { CompactAssetStats } from "modules/asset_stats/CompactAssetStats";
import { GlobalSearch } from "modules/global_search/GlobalSearch";
import { RecentSearches } from "modules/global_search/RecentSearches";
import { RecentOrders } from "modules/orders_list/RecentOrders";
import { AssetTrade } from "modules/trade/AssetTrade";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BasePage } from "shared/components/common/BasePage";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import useAccountStore from "shared/store/accountStore";

export const TradePage: React.FC = () => {
  const { t } = useTranslation();
  const [selectedAsset, setSelectedAsset] = useState<AssetWrapper>();
  const { fetchAccount } = useAccountStore();

  useEffect(() => {
    fetchAccount();
  }, []);

  return (
    <BasePage>
      <Layouts.Spaced>
        <Panel>
          <Labels.H2 className="mb-8">{t("trade.orders.label")}</Labels.H2>
          <div className="flex gap-10">
            <div className="min-w-0">
              <Labels.H3 className="mb-2">
                {t("trade.orders.browseSymbol")}
              </Labels.H3>
              <GlobalSearch
                value={selectedAsset}
                onClicked={setSelectedAsset}
                wFull
                hidePlaceholder
                onClear={() => setSelectedAsset(undefined)}
              />
            </div>
            {selectedAsset ? (
              <div className="flex-grow">
                <CompactAssetInfo asset={selectedAsset} />
              </div>
            ) : (
              <div className="flex-grow">
                <RecentSearches onClick={setSelectedAsset} />
              </div>
            )}
          </div>
          <hr className="mt-8 border-sb-gray-200" />
          <AssetTrade assetId={selectedAsset?.symbol} compact />
          {selectedAsset && (
            <>
              <CompactAssetDescription assetId={selectedAsset?.symbol} />
              <CompactAssetStats assetId={selectedAsset?.symbol} />
            </>
          )}
        </Panel>
        <RecentOrders />
      </Layouts.Spaced>
    </BasePage>
  );
};
