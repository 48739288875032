import React from "react";
import PerformanceTable from "../PerrformanceTable";
import PortfolioConfigData from "config/portfolio-config.json";
import styles from "./../../finecoPortfolioArtifact.module.css";

type SectionChildrenProps = {
  reportData: any;
};
const AnalisiRischio: React.FC<SectionChildrenProps> = ({ reportData }) => {
  if (!reportData) {
    return <></>;
  }
  const configData = PortfolioConfigData.analisi_strumenti.data;
  return (
    <div
      style={{
        marginLeft: 10,
        marginBottom: 30,
        marginTop: 10,
        // backgroundColor: "white",
      }}
    >
      {/* **********CONTENT ********** */}
      <h3 className={styles.subTitle}>
        {configData.performance_strumenti_fondi_etf.label}
      </h3>
      <div style={{ marginBottom: 20 }}></div>
      <PerformanceTable
        dataObject={{
          data: reportData.analisi_strumenti.performance_strumenti_fondi_etf,
          configData: {
            type: "table_simple",
          },
        }}
      />
    </div>
  );
};
export default React.memo(
  AnalisiRischio,
  (prevProps: SectionChildrenProps, nextProps: SectionChildrenProps) => {
    // Custom comparison function
    return prevProps.reportData === nextProps.reportData;
  },
);
