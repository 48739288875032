import { useEffect } from "react";
import useAssets from "shared/hooks/useAssets";
import useAssetsStore from "shared/store/assetsStore";
import { auth } from "shared/utils/firebase";

export const AssetInfoFetcher: React.FC = () => {
  const { assets } = useAssets();
  const { fetchAssets } = useAssetsStore();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        if (!assets?.length) {
          fetchAssets();
        }
      }
    });

    return () => unsubscribe();
  }, []);

  return null;
};
