// ActivityLog.jsx
import React, { useState } from "react";
import { MapPin, Search } from "lucide-react";

import { ClientDetailType } from "types/crm/ClientTypes";
import { formatDateWithTime, getInitials } from "utils/misc";
import styles from "./clientDetailsSchedule.module.css";
import clientDetailsStyles from "./../crmClientDetailsPage.module.css";
import { useNavigate } from "react-router-dom";

type ClientDetailsScheduleProps = {
  clientData: ClientDetailType;
};

export const ClientDetailsSchedule: React.FC<ClientDetailsScheduleProps> = ({
  clientData,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const calendarEvents = clientData.calendar_events;

  const filteredEvents = calendarEvents.filter((event) => {
    const searchFields = [
      event.description,
      event.event_type,
      event.location,
      event.title,
    ].map((field) => (field || "").toLowerCase());

    return searchFields.some((field) =>
      field.includes(searchTerm.toLowerCase()),
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <Search size={16} className={styles.searchIcon} />
        <input
          type="text"
          placeholder="Cerca per descrizione, tipo, luogo o titolo"
          className={styles.searchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div>
        {filteredEvents.length === 0 && (
          <div className={styles.noResults}>Nessun risultato trovato</div>
        )}
        {filteredEvents.length > 0 && (
          <>
            {filteredEvents.map((event) => (
              <ScheduleCard key={event.event_id} eventData={event} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

// @ts-ignore
const ScheduleCard = ({ eventData }) => {
  const navigate = useNavigate();
  // @ts-ignore
  const formatDuration = (start, end) => {
    const startTime = new Date(start);
    const endTime = new Date(end);
    const diffHours =
      (endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60);
    return `${diffHours.toFixed(1)}h`;
  };

  // @ts-ignore
  const formatDateTime = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const hours = date.getHours().toString().padStart(2, "0");
    const mins = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${mins} - ${day} ${month}`;
  };

  const callClient = (clientId: string) => {
    navigate(`/crm/clients/${clientId}`);
  };

  return (
    <div key={eventData.event_id} className={styles.cardWrapper}>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <div className={styles.cardHeader}>
            <div className={styles.titleContainer}>
              <h3 className={styles.title}>{eventData.title}</h3>
              <span className={styles.badge}>{eventData.event_type}</span>
            </div>
            <span className={styles.dateInfo}>
              {formatDateWithTime(eventData.time_start)}
            </span>
            <span className={styles.dateInfo}>
              Durata: {formatDuration(eventData.time_start, eventData.time_end)}
            </span>

            <div className={styles.location} style={{ marginTop: 10 }}>
              <MapPin size={16} />
              <div
                className={styles.location}
                onClick={() =>
                  window.open(
                    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      eventData.location,
                    )}`,
                    "_blank",
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {eventData.location}
              </div>
            </div>

            <div className={styles.description} style={{ marginTop: 10 }}>
              {eventData.description}
            </div>
          </div>

          <div className={styles.cardFooter}>
            <div className={clientDetailsStyles.participants}>
              {eventData.participants.map((participant: any, index: any) => (
                <div key={index} className={clientDetailsStyles.participant}>
                  <div
                    onClick={() => {
                      callClient(participant.client_id);
                    }}
                    className={clientDetailsStyles.participantAvatar}
                  >
                    {getInitials(participant.first_name, participant.last_name)}
                  </div>
                  <div className={clientDetailsStyles.participantTooltip}>
                    {participant.first_name} {participant.last_name}
                  </div>
                </div>
              ))}
            </div>
            <span className={styles.status}>{eventData.status}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
