import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowsUpDown } from "shared/icons/ArrowsUpDown";
import { Panel } from "shared/components/hoc/Panels";
import { RecentPortfolioActivities } from "./RecentPortfolioActivities";
import { Skeleton } from "shared/components/common/Skeleton";
import { Loading } from "shared/types/enums";
import { Labels } from "shared/components/hoc/Labels";
import useClientDetailsStore from "shared/store/clientDetailsStore";

type Props = {
  clientId?: string;
  portfolioId?: string;
  ungroup?: boolean;
};

export const RecentActivities: React.FC<Props> = ({ clientId, portfolioId, ungroup }) => {
  const { t } = useTranslation();

  const { fetchRecentActivities } = useClientDetailsStore();
  const { clientDetails } = useClientDetailsStore();

  const [recentActivities, setRecentActivities] = useState([]);
  const [loading, setLoading] = useState(null);

  // Initialize with default values
  // const clientDetails = useClientDetailsStore(
  //   (state) =>
  //     state.clientDetails[clientId || ""] || {
  //       recentActivities: [],
  //       recentActivitiesLoading: Loading.Idle,
  //     },
  // );

  // const { recentActivities: activities, recentActivitiesLoading: loading } =
  //   clientDetails;

  useEffect(() => {
    if (clientDetails[clientId]) {
      setRecentActivities(clientDetails[clientId].recentActivities.filter((activity) => portfolioId ? activity.portfolio_id === portfolioId : true));
      setLoading(clientDetails[clientId].recentActivitiesLoading);
    }
  }, [clientDetails, setRecentActivities, clientId]);

  useEffect(() => {
    if (clientId) {
      fetchRecentActivities(clientId);
    }
  }, [clientId]);

  return (
    <Panel className="w-full">
      <div className="mb-4 flex items-center gap-2 text-lg font-semibold">
        <ArrowsUpDown className="h-5 w-5" />
        {t("recentActivities.title")}
      </div>
      {loading === Loading.InProgress && (
        <>
          <Skeleton className="mb-2 h-[10px] w-[80px] bg-sb-gray-200" />
          <Skeleton className="mb-2 h-[10px] w-[80px] bg-sb-gray-200" />
        </>
      )}
      {// @ts-ignore
        recentActivities?.map((item, idx) => {
          return (
            <RecentPortfolioActivities
              title={item.title}
              symbol={item.symbol}
              activities={item.activities}
              key={idx}
              ungroup={ungroup}
            />
          );
        })}
      {loading === Loading.Finished && !recentActivities?.length && (
        <Labels.G1>{t("recentActivities.noRecentActivities")}</Labels.G1>
      )}
    </Panel>
  );
};
