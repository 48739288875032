import { create } from "./store";
import { Loading } from "shared/types/enums";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import { PortfoliosService } from "shared/services/portfolios/PortfoliosService";

type PortfoliosStoreState = {
  portfolios: Portfolio[];
  loading: Loading;
  fetched: boolean;
};

type PortfoliosStoreActions = {
  fetchPortfolios: () => Promise<void>;
  fetchPortfolioById: (portfolioId: string) => Promise<void>;
};

const initialData: PortfoliosStoreState = {
  portfolios: [],
  fetched: false,
  loading: Loading.Idle,
};

const usePortfoliosStore = create<
  PortfoliosStoreState & PortfoliosStoreActions
>()((set, get) => ({
  ...initialData,
  fetchPortfolios: async () => {
    set({ loading: Loading.InProgress });
    try {
      const portfolios = await PortfoliosService.getMany();
      set({ portfolios });
    } finally {
      set({ loading: Loading.Finished, fetched: true });
    }
  },
  fetchPortfolioById: async (portfolioId: string) => {
    const portfolio = await PortfoliosService.getOne(portfolioId);
    set({
      portfolios: [
        ...get().portfolios.filter((p) => p.id !== portfolioId),
        portfolio,
      ],
    });
  },
}));

export default usePortfoliosStore;
