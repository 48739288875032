import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import { cn } from "utils/misc";
import styles from "./checkboxSquare.module.css";

const CheckboxSquare = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(styles.checkboxSquare, className)}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={styles.indicatorSquare}>
      <Check className="h-4 w-4 text-white" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));

CheckboxSquare.displayName = CheckboxPrimitive.Root.displayName;

export { CheckboxSquare };
