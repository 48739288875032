import { FinecoPortfolioArtifactType } from "types/assistant/AssistantTypes";
import { PaperclipIcon } from "lucide-react";
import colorsStore from "shared/store/colorsStore";

type Props = {
  finecoPortfolioArtifact: FinecoPortfolioArtifactType;
};

export const PortfolioItem: React.FC<Props> = ({ finecoPortfolioArtifact }) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  return (
    <div
      className="flex cursor-pointer items-start gap-4 hover:opacity-50"
      onClick={() => {
        const el = document.getElementById(
          finecoPortfolioArtifact.portfolio_id.toString(),
        );
        el.scrollIntoView({ block: "start", behavior: "smooth" });
      }}
    >
      <AttachmentIcon />
      <div className="w-full">
        <div
          className="mb-4 font-bold lg:text-lg"
          style={{ color: colorsConfig.black }}
        >
          {finecoPortfolioArtifact.portfolio_id}
        </div>
      </div>
    </div>
  );
};

export const AttachmentIcon: React.FC = () => {
  return (
    <div className="grid size-8 shrink-0 place-items-center rounded-full border border-gray-600">
      <PaperclipIcon className="size-4 text-gray-600" />
    </div>
  );
};
