import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BasePage } from "shared/components/common/BasePage";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import useSubscriptionsStore from "shared/store/subscriptionsStore";
import useClientsStore from "shared/store/clientsStore";
import { Loading } from "shared/types/enums";
import { Layouts } from "shared/components/hoc/Layouts";
import { StrategySubscriptions } from "./StrategySubscriptions";


export const SubscriptionsPage: React.FC = () => {
    const { t } = useTranslation();

    const {
        fetchSubscriptions: fetchSubscriptionsStore,
        subscriptions,
        loading,
    } = useSubscriptionsStore();

    const { clients, fetchClients } = useClientsStore();

    useEffect(() => {
        fetchSubscriptionsStore();
        fetchClients();
    }, [fetchSubscriptionsStore, fetchClients]);

    return (
        <BasePage>
            <Labels.H1>{t("subscriptions.title")}</Labels.H1>
            <Panel className="mt-8">
                <Layouts.Spaced className="mt-8">
                    {subscriptions?.map((item, idx) => {
                        return (
                            <StrategySubscriptions
                                key={idx}
                                item={item}
                                clients={clients}
                            />
                        );
                    })}

                    {loading === Loading.Finished && !subscriptions.length ? (
                        <Labels.G1>{t("subscriptions.noSubscriptions")}</Labels.G1>
                    ) : null}
                </Layouts.Spaced>
            </Panel>
        </BasePage>
    );
};
