import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import useClientDetailsStore from "shared/store/clientDetailsStore";
import { CurrencyFormat, pctFormat } from "shared/utils/currency";

type Props = {
  clientId?: string;
  portfolioId?: string;
};

export const PortfolioSubscriptionStats: React.FC<Props> = ({
  portfolioId,
  clientId,
}) => {
  const { t } = useTranslation();

  const clientDetails = useClientDetailsStore(
    (state) => state.clientDetails[clientId || ""] || { portfolios: [] },
  );

  const portfolio = clientDetails.portfolios?.find((p) => p.id === portfolioId);
  const { fetchClientPortfolio } = useClientDetailsStore();

  useEffect(() => {
    if (clientId && portfolioId) {
      fetchClientPortfolio(clientId, portfolioId);
    }
  }, [clientId, portfolioId]);

  if (!portfolio || !clientId) {
    return null;
  }

  const positive = (portfolio?.subscription?.total_return || 0) >= 0;
  return (
    <Panel>
      <Labels.H3>{t("stats.title")}</Labels.H3>
      <div className="mt-4 flex w-full flex-wrap justify-center gap-12">
        <div>
          <div className="text-center text-sb-gray-600">
            {t("stats.equity")}
          </div>
          <div className="text-4xl font-semibold">
            {CurrencyFormat.format(portfolio?.subscription?.equity || "0")}
          </div>
        </div>
        <div>
          <div className="text-center text-sb-gray-600">
            {t("stats.marketValue")}
          </div>
          <div className="text-4xl font-semibold">
            {CurrencyFormat.format(
              portfolio?.subscription?.market_value || "0",
            )}
          </div>
        </div>
        <div>
          <div className="text-center text-sb-gray-600">
            {t("stats.totalReturn")}
          </div>
          <div
            className={
              "text-4xl font-semibold " + (positive ? "text-sb-green-700" : "")
            }
          >
            {CurrencyFormat.format(
              portfolio?.subscription?.total_return || "0",
            )}{" "}
            ({pctFormat.format(portfolio?.subscription?.total_return_pct || 0)})
          </div>
        </div>
      </div>
    </Panel>
  );
};
