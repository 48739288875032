import React from "react";
import { FewMoreQuestionsColumn } from "./components/FewMoreQuestionsColumn";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { RadioAnswers } from "./components/RadioAnswers";
import { OnboardingPartProps } from "./OnboardingFlow";
import { NET_VALUES } from "shared/utils/common";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import { Labels } from "shared/components/hoc/Labels";
import { useTranslation } from "react-i18next";
import i18n from "shared/i18n/i18n";
import useOnboardingStore from "shared/store/onboardingStore";

export const AnnualIncome: React.FC<OnboardingPartProps> = ({
  onNext,
  onPrevious,
}) => {
  const { t } = useTranslation();
  const { annualIncome } = useOnboardingStore((state) => state.data);
  const { update } = useOnboardingStore();

  const handleOnValueChange = (annualIncomeValue: string | undefined) => {
    update({ annualIncome: annualIncomeValue });
  };

  const options = NET_VALUES.map((nv) => ({
    label: i18n.t(nv.label),
    value: nv.value,
  }));

  return (
    <OnboardingContainer
      leftColumnContent={<FewMoreQuestionsColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1">
            <Labels.H2 className="mb-2">
              {t("forms.kyc.screen_titles.annual_income.title")}
            </Labels.H2>
            <Labels.B1 className="mb-6">
              {t("forms.kyc.screen_titles.annual_income.sub_title")}
            </Labels.B1>
            <RadioAnswers
              value={annualIncome}
              options={options}
              onChange={(v) => handleOnValueChange(v)}
            />
          </div>
          <OnboardingFooter
            onNext={() => onNext && onNext()}
            onPrev={() => onPrevious && onPrevious()}
            nextDisabled={!annualIncome}
            // change to next
          />
        </div>
      }
    />
  );
};
