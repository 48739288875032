import Decimal from "decimal.js";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { ClientsService } from "shared/services/clients/ClientsService";
import useClientDetailsStore from "shared/store/clientDetailsStore";
import useClientsStore from "shared/store/clientsStore";
import { CurrencyFormat } from "shared/utils/currency";

type Props = {
  portfolioId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const SelectClientModal: React.FC<Props> = ({
  portfolioId,
  open,
  setOpen,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clients, fetchClients } = useClientsStore();
  const { fetchPortfolios } = useClientDetailsStore();
  const [amount, setAmount] = useState<string>();
  const [clientId, setClientId] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchClients();
  }, []);

  const onAddClicked = () => {
    if (clientId && portfolioId && amount) {
      setLoading(true);
      const amountAsString = new Decimal(amount).toFixed();
      ClientsService.depositToPortfolio(clientId, portfolioId, amountAsString)
        .then(() => {
          toast.success(t("clientPortfolio.portfolioAddedSuccess"));
          fetchPortfolios(clientId);
        })
        .finally(() => {
          setLoading(false);
          setOpen(false);
          navigate(`/clients/${clientId}/portfolios/${portfolioId}`);
        });
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Labels.H3>
        {t("clientPortfolio.selectClientAndInvestInPortfolio")}
      </Labels.H3>
      <div className="mt-8">
        <div className="text-xl font-medium">{t("proposals.investment")}</div>
        <div className="flex items-center gap-4">
          <div className="text-2xl">$</div>
          <CurrencyInput
            className="w-full min-w-0 text-3xl caret-sb-green-600 outline-none"
            placeholder="0"
            decimalsLimit={2}
            decimalScale={2}
            onValueChange={(value) => setAmount(value)}
          />
        </div>
      </div>
      <div className="mt-8 max-h-[200px] w-full space-y-2 overflow-y-auto">
        {clients.map((item, idx) => {
          const buying_power = parseFloat(item.trading?.buying_power || "0");
          return (
            <div
              key={"client_" + idx}
              className={
                (clientId === item.user_id
                  ? "border-sb-green-500 bg-sb-green-100"
                  : "border-sb-gray-200") +
                " flex w-full cursor-pointer items-center justify-between rounded-lg border p-4"
              }
              onClick={() => setClientId(item.user_id)}
            >
              {item.summary.client_name}
              <div className="text-base text-sb-gray-500">
                <div className="text-lg font-semibold text-black">
                  {CurrencyFormat.format(buying_power)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-8 flex items-center justify-center gap-5">
        <Button
          label="clientPortfolio.invest"
          onClick={onAddClicked}
          disabled={!amount || !clientId}
          loading={loading}
        />
        <Button
          btnStyle="blank"
          label="clientPortfolio.cancel"
          onClick={() => setOpen(false)}
          disabled={loading}
        />
      </div>
    </Modal>
  );
};
