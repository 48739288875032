import { type ClassValue, clsx } from "clsx";
import { DateTime } from "luxon";
import { twMerge } from "tailwind-merge";
import {
  AssistantActionCreatePortfolioType,
  AssistantAttachment,
  AssistantEsgPreferencesType,
  AssistantMessage,
  HistoryItemType,
} from "types/assistant/AssistantTypes";
import { ConstraintConfigType } from "types/assistant/ConstraintsTypes";

// @ts-ignore
export const formatDate = (dateString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(dateString);
  return `${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
};

export const formatCurrency = (amount: string, currency = "€") => {
  const num = parseInt(amount);
  return `${currency} ${num.toLocaleString()}`;
};

// @ts-ignore
export const formatDateWithTime = (input) => {
  // Ensure we have a valid Date object
  let date;

  try {
    // If input is already a Date object
    if (input instanceof Date) {
      date = input;
    }
    // If input is a string or number
    else {
      date = new Date(input);
    }

    // Check if date is valid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }
  } catch (error) {
    console.error("Error parsing date:", error);
    return "Invalid date format";
  }

  // Array of month abbreviations
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get components
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  // Return formatted string
  return `${hours}:${minutes} - ${month} ${day}, ${year}`;
};

// @ts-ignore
export const formatDateOnlyDate = (input) => {
  // Ensure we have a valid Date object
  let date;

  try {
    // If input is already a Date object
    if (input instanceof Date) {
      date = input;
    }
    // If input is a string or number
    else {
      date = new Date(input);
    }

    // Check if date is valid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }
  } catch (error) {
    console.error("Error parsing date:", error);
    return "Invalid date format";
  }

  // Array of month abbreviations
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get components
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  // Return formatted string
  return `${month} ${day}, ${year}`;
};

export const calculateProgress = (current: string, total: string) => {
  return Math.round((parseInt(current) / parseInt(total)) * 100);
};
export const formatCurrencyCrm = (amount: string, currency = "€") => {
  const num = parseInt(amount);
  return `${currency} ${num.toLocaleString()}`;
};

export const getInitials = (firstName: string, lastName: string) => {
  return `${firstName?.[0] || ""}${lastName?.[0] || ""}`.toUpperCase();
};

export const mapPriority: any = {
  urgent: "urgente",
  high: "alto",
  medium: "medio",
  low: "basso",
};
export const formatTimeRange = (timeStart: any, timeEnd: any) => {
  // const start = new Date(timeStart).getUTCHours();
  const start = new Date(timeStart).getHours();
  // const end = new Date(timeEnd).getUTCHours();
  const end = new Date(timeEnd).getHours();
  return `${start}:00 - ${end}:00`;
};
export const findByProp = (arr: any, prop: any, value: any) => {
  const searchValue = typeof value === "string" ? Number(value) : value;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][prop] === searchValue) return arr[i];
  }
  return null;
};
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getCombinedMessages = (
  // @ts-ignore
  actions: AssistantActionCreatePortfolioType = null,
  // @ts-ignore
  esgPreferences: AssistantEsgPreferencesType = null,
  // @ts-ignore
  messages: AssistantMessage[] = null,
  // @ts-ignore
  attachments: AssistantAttachment[] = null,
): (
  | AssistantMessage
  | AssistantAttachment
  | AssistantEsgPreferencesType
  | AssistantActionCreatePortfolioType
)[] => {
  let combined: any;
  if (actions) {
    combined = [...(messages || []), ...(attachments || []), actions];
  } else {
    combined = [...(messages || []), ...(attachments || [])];
  }

  return combined.sort(
    (a: any, b: any) =>
      DateTime.fromISO(a.created_at).toMillis() -
      DateTime.fromISO(b.created_at).toMillis(),
  );
};

export type DateRange =
  | "today"
  | "yesterday"
  | "lastMonth"
  | "lastYear"
  | "remaining";

export const mapByDateRanges = (threads: HistoryItemType[]) => {
  let result: Record<DateRange, HistoryItemType[]> = {
    today: [],
    yesterday: [],
    lastMonth: [],
    lastYear: [],
    remaining: [],
  };

  const now = DateTime.now();
  for (let t of threads) {
    const isoDate = DateTime.fromISO(t.created_at);
    const isToday = now.hasSame(isoDate, "day");
    const isYesterday = now.minus({ days: 1 }).hasSame(isoDate, "day");
    const thirtyDaysAgo = now.minus({ days: 30 });

    const isLastMonth = isoDate >= thirtyDaysAgo && isoDate < now;
    const isLastYear = now.hasSame(isoDate, "year");

    // TODO rest
    if (isToday) {
      result.today.push(t);
    } else if (isYesterday) {
      result.yesterday.push(t);
    } else if (isLastMonth) {
      result.lastMonth.push(t);
    } else if (isLastYear) {
      result.lastYear.push(t);
    } else {
      result.remaining.push(t);
    }
  }
  return result;
};
export const convertToPercentage = (value: number) => {
  return (value * 100).toFixed(2);
};
export const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0;
};
export const parseConstraintsFormValues = (
  formValues: Record<string, string | number>,
  configData: ConstraintConfigType,
) => {
  const convertedFormValues = {};
  for (let key in formValues) {
    const value = formValues[key];
    const type = configData[key].type;
    let valueConverted: any;
    if (typeof value === "string") {
      if (value.length === 0) {
        valueConverted = null;
      } else {
        switch (type) {
          case "boolean":
            break;
          case "string":
            break;
          default:
            valueConverted = Number(value);
            if (type === "percentage") {
              valueConverted = valueConverted / 100;
            }
        }
      }
    }

    // @ts-ignore
    convertedFormValues[key] = valueConverted;
  }
  return convertedFormValues;
};
export const parseValue = (targetObject: any, data: any) => {
  let inputValue = targetObject.value;

  const inputType = data[targetObject.id].type;

  // Replace commas with dots for decimal support (only for float or percentage types)
  if (inputType === "float" || inputType === "percentage") {
    inputValue = inputValue.replace(",", "."); // Convert comma to dot
  }

  // Remove any non-numeric characters (this includes letters)
  inputValue = inputValue.replace(/[^0-9.]/g, ""); // Remove anything that isn't a digit or dot

  // Handle validation for integers: no dots allowed
  if (inputType === "int") {
    // Remove any dots if present (for integers)
    inputValue = inputValue.replace(/[.]/g, "");

    // Ensure only digits are present
    if (!/^\d*$/.test(inputValue)) {
      return ""; // Return empty if invalid character typed
    }
  }

  // Handle validation for floats or percentages: allow one dot
  if (inputType === "float" || inputType === "percentage") {
    // Ensure the input contains only digits and at most one dot
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      return inputValue; // Allow partial input like "1." or "1.2"
    }
  }

  // Convert the string to a number if it's valid
  let value = parseFloat(inputValue);

  // If the value is not a number, return the string (e.g., during partial input)
  if (isNaN(value) && inputValue !== "") {
    return inputValue; // Keep the raw string if user is still typing
  }

  // Check for max value limits if provided
  if (data[targetObject.id]?.le) {
    let maxValue = data[targetObject.id].le;
    if (inputType === "percentage") {
      maxValue = data[targetObject.id].le * 100;
    }
    if (value > maxValue) {
      inputValue = maxValue.toString();
    }
  }

  // Check for min value limits if provided
  if (data[targetObject.id]?.ge) {
    let minValue = data[targetObject.id].ge;
    if (inputType === "percentage") {
      minValue = data[targetObject.id].ge * 100;
    }
    if (value < minValue) {
      inputValue = minValue.toString();
    }
  }

  return inputValue; // Return the validated string during input
};

const riskProfileMappingObject = {
  engToIta: {
    cautious: "Cauto",
    prudent: "Prudente",
    balanced: "Bilanciato",
    dynamic: "Dinamico",
  },
  itaToEng: {
    cauto: "cautious",
    prudente: "prudent",
    bilanciato: "balanced",
    dinamico: "dynamic",
  },
};
export const riskProfileMapEngToIta = (riskProfile: string) => {
  const riskProfileLC = riskProfile.toLowerCase();
  // @ts-ignore
  if (riskProfileMappingObject.engToIta[riskProfileLC]) {
    // @ts-ignore
    return riskProfileMappingObject.engToIta[riskProfileLC];
  }
  return riskProfile;
};
export const riskProfileMapItaToEng = (riskProfile: string) => {
  const riskProfileLC = riskProfile.toLowerCase();
  if (riskProfileMappingObject.itaToEng[riskProfileLC]) {
    return riskProfileMappingObject.itaToEng[riskProfileLC];
  }
  return riskProfile;
};

export enum Loading {
  Idle = "IDLE",
  InProgress = "IN_PROGRESS",
  Finished = "FINISHED",
}
export const pctFormat = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  signDisplay: "always", // 'always' to display + for positive numbers
});

export const scrollToBottom = (divId: string) => {
  // if (!useThreadsStore.getState().hasUserScrolled) {
  //   useThreadsStore.getState().setIsCodeScrolling(true);
  //   // setTimeout(() => {
  //   //   useThreadsStore.getState().setIsCodeScrolling(false);
  //   // }, 200);
  document.getElementById(divId)?.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  });
  // }
};
export const Palette = {
  Green: "#00FD86",
  LightGreen: "#00CC4F",
  DarkGreen: "#00FD86",
  Green80: "#00AD3A",
  Green100: "#007A29",
  Cyan: "#05F9F9",
  DarkCyan: "#02EDED",
  LightCyan: "#00CCCB",
  Red: "#FF5833",
  Red60: "#C42E0E",
  Purple: "#B073FF",
  Purple10: "#E5D9FF",
  Purple30: "#B88CFF",
  Purple60: "#6D36B2",
  Magenta: "#FF4C77",
  Yellow: "#EEFF59",
  LightYellow: "#D9D936",
  Yellow10: "#FBFFD9",
  Yellow30: "#F4FF8C",
  Orange1: "#F0A61B",
  Green1: "#9DFF75",
  Blue: "#329DFF",
  Blue20: "#80D5FF",
  Blue30: "#4DB5FF",
  Blue50: "#0A6FCC",
  Blue60: "#005DB2",
  Teal20: "#31E4F5",
  Teal30: "#00C9F1",
  Black: "#111112",
  CryptoBackground: "#130B1E",
  White: "#FFFFFF",
  Grey10: "#F5F5F6",
  Grey20: "#E0E1E3",
  Grey30: "#C7C9CC",
  Grey40: "#B5B8BF",
  Grey50: "#9B9FA7",
  Grey60: "#4E5157",
  Grey70: "#34363A",
  Grey80: "#1E1F21",
  Grey90: "#202124",
  Red10: "#FFDAD4",
  Green10: "#E0FFEB",
  Transparent: "#00000000",
};
export const shallowCompareObjects = (obj1, obj2) => {
  // First, compare the number of keys (length of keys)
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false; // Objects are not the same if they have different number of keys
  }

  // Compare the values of each key in both objects
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false; // If any value differs, objects are not equal
    }
  }

  // If we haven't returned false, the objects are equal
  return true;
};
