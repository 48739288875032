import React from "react";
import styles from "./../finecoPortfolioArtifact.module.css";
import colorsStore from "shared/store/colorsStore";

type PortfolioAnalysisTableProps = {
  data: any;
};
const PortfolioAnalysisTable: React.FC<PortfolioAnalysisTableProps> = ({
  data,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  // const data = [
  //   { period: "1y", return: 3.58, maxLoss: -2.9 },
  //   { period: "2y", return: 7.16, maxLoss: -2.0 },
  //   { period: "3y", return: 10.74, maxLoss: -0.48 },
  //   { period: "4y", return: 14.32, maxLoss: 1.36 },
  //   { period: "5y", return: 17.9, maxLoss: 3.41 },
  // ];

  return (
    <div className={styles.portfolioAnalysisTable}>
      <table className="w-full text-sm">
        <thead>
          <tr>
            <th
              className="p-4 text-left font-medium text-gray-600"
              style={{
                fontWeight: "bold",
                color: colorsConfig.black,
              }}
            >
              Orizzonte Temporale
            </th>
            {data.map(({ year }) => (
              <th
                key={year}
                className="p-4 text-right font-medium text-gray-600"
                style={{
                  fontWeight: "bold",
                  color: colorsConfig.black,
                }}
              >
                {year}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="border-t border-gray-200">
            <td
              className="p-4 text-left font-medium"
              style={{ color: colorsConfig.black }}
            >
              Rendimento atteso di portafoglio
            </td>
            {data.map(({ year, avg_return }) => (
              <td
                key={year}
                className="p-4 text-right"
                style={{ color: colorsConfig.black }}
              >
                {avg_return.toFixed(2)}
              </td>
            ))}
          </tr>
          <tr className="border-t border-gray-200">
            <td
              className="p-4 text-left font-medium"
              style={{ color: colorsConfig.black }}
            >
              Max perdita potenziale 95%
            </td>
            {data.map(({ year, lower_bound }) => (
              <td
                key={year}
                className="p-4 text-right"
                style={{ color: colorsConfig.black }}
              >
                {lower_bound.toFixed(2)}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PortfolioAnalysisTable;
