import { useEffect, useMemo } from "react";
import useAssetInfoStore from "shared/store/assetInfoStore";
import useAssetStatsStore from "shared/store/assetStatsStore";

const defaultStats = { stats: null, earnings: null };
const defaultInfo = {
  info: {
    companyName: "",
    symbol: "",
    description: "",
    industry: "",
    website: "",
    CEO: "",
    sector: "",
    tags: [],
  },
};

export default function useAssetsExtras(symbol: string) {
  // Create stable references for default values
  const extras = useAssetStatsStore((state) => {
    const cachedData = state.cache[symbol];
    return cachedData || defaultStats;
  });

  const assetInfo = useAssetInfoStore((state) => {
    const cachedData = state.cache[symbol];
    return cachedData || defaultInfo;
  });

  const { info } = assetInfo;

  // Fetch functions should be stable
  const fetchStatsBySymbol = useAssetStatsStore(
    (state) => state.fetchStatsBySymbol,
  );
  const fetchInfoBySymbol = useAssetInfoStore(
    (state) => state.fetchInfoBySymbol,
  );

  const hasAnyInfo = useMemo(
    () =>
      Boolean(
        info?.description ||
          info?.industry ||
          info?.website ||
          info?.CEO ||
          info?.sector ||
          info?.tags?.length,
      ),
    [info],
  );

  useEffect(() => {
    let mounted = true;

    if (symbol && mounted) {
      fetchStatsBySymbol(symbol);
      fetchInfoBySymbol(symbol);
    }

    return () => {
      mounted = false;
    };
  }, [symbol, fetchStatsBySymbol, fetchInfoBySymbol]);

  return {
    stats: extras.stats,
    earnings: extras.earnings,
    info,
    hasAnyInfo,
  };
}
