import React from "react";
import Stats from "../Stats";
import styles from "./../../../finecoPortfolioArtifact.module.css";

type SectionChildrenProps = {
  reportData: any;
  artifact;
  label;
};
const StatsContainer: React.FC<SectionChildrenProps> = ({
  reportData,
  artifact,
  label,
}) => {
  if (!reportData) {
    return <></>;
  }

  return (
    <div style={{ marginLeft: 10, marginBottom: 30 }}>
      <h3 className={styles.subTitle}>{label}</h3>
      <Stats reportData={reportData} artifact={artifact} />
    </div>
  );
};
export default React.memo(
  StatsContainer,
  (prevProps: SectionChildrenProps, nextProps: SectionChildrenProps) => {
    // Custom comparison function
    return prevProps.reportData === nextProps.reportData;
  },
);
