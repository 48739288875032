import { DateTime } from "luxon";
import { ProAssistantThreadHistoryItem } from "shared/models/pro_assistant/ProAssistantModel";

export type DateRange =
  | "today"
  | "yesterday"
  | "lastMonth"
  | "lastYear"
  | "remaining";

export const mapByDateRanges = (threads: ProAssistantThreadHistoryItem[]) => {
  let result: Record<DateRange, ProAssistantThreadHistoryItem[]> = {
    today: [],
    yesterday: [],
    lastMonth: [],
    lastYear: [],
    remaining: [],
  };

  const now = DateTime.now();
  for (let t of threads) {
    const isoDate = DateTime.fromISO(t.created_at);
    const isToday = now.hasSame(isoDate, "day");
    const isYesterday = now.minus({ days: 1 }).hasSame(isoDate, "day");
    const thirtyDaysAgo = now.minus({ days: 30 });

    const isLastMonth = isoDate >= thirtyDaysAgo && isoDate < now;
    const isLastYear = now.hasSame(isoDate, "year");

    // TODO rest
    if (isToday) {
      result.today.push(t);
    } else if (isYesterday) {
      result.yesterday.push(t);
    } else if (isLastMonth) {
      result.lastMonth.push(t);
    } else if (isLastYear) {
      result.lastYear.push(t);
    } else {
      result.remaining.push(t);
    }
  }
  return result;
};
