import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "shared/components/ui/table";
import PortfolioConfigData from "config/portfolio-config.json";
import { CheckIcon } from "lucide-react";
import styles from "./complexTable.module.css";
import colorsStore from "shared/store/colorsStore";

type TooltipState = {
  visible: boolean;
  text: string;
  position: {
    top: number;
    left: number;
  };
};

const ComplexTable = ({ data }) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const shouldRemoveControvalore = data.every(
    (product) => product["controvalore"] === 0,
  );

  const headers =
    data && data.length > 0
      ? Object.keys(data[0]).filter(
          (key) =>
            key &&
            (!shouldRemoveControvalore || key !== "controvalore") &&
            key !== "investimento_minimo" &&
            key !== "rating_sostenibilita_morningstar",
        )
      : [];
  // Get column mappings from config
  const columnMappings =
    PortfolioConfigData.portfolio.data.strumenti_fondi.columns;

  const formatCellValue = (key: string, value: any) => {
    if (key === "peso_sul_ptf") {
      return Number(value).toFixed(2);
    }
    return String(value);
  };

  const [tooltip, setTooltip] = useState<TooltipState>({
    visible: false,
    text: "Click to copy",
    position: { top: 0, left: 0 },
  });

  const handleCellClick = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setTooltip((prev) => ({
          ...prev,
          text: "Copied",
        }));
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const showTooltip = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setTooltip({
      visible: true,
      text: "Click to copy",
      position: {
        top: rect.top + window.scrollY - 30, // Adjust for scroll
        left: rect.left + window.scrollX, // Align to the start of the cell
      },
    });
  };
  const hideTooltip = () => {
    setTooltip((prev) => ({
      ...prev,
      visible: false,
    }));
  };

  return (
    <div
      className="w-full"
      style={{
        paddingLeft: 0,
        paddingRight: 20,
        paddingBottom: 10,
        marginTop: 20,
        // backgroundColor: "white",
        // borderRadius: 8,
        // boxShadow: "0px 24px 18px rgba(28, 34, 38, 1)",
      }}
    >
      <div
        className="w-full overflow-x-auto"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 10,
          backgroundColor: "white",
          borderRadius: 8,
          boxShadow: "0px 4px 18px rgba(28, 34, 38, 0.1)",
        }}
      >
        <Table className={`${styles.table} min-w-[400px]`}>
          <TableHeader>
            <TableRow>
              {headers.map((header, index) => (
                <TableHead
                  key={index}
                  className="tooltip-container  px-4"
                  onMouseEnter={showTooltip}
                  onMouseLeave={hideTooltip}
                  style={{ fontWeight: "bold" }}
                >
                  {columnMappings[header] || header}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((product, index) => (
              <TableRow key={index}>
                {headers.map((key, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    className="tooltip-container px-4"
                    onMouseEnter={showTooltip}
                    onMouseLeave={hideTooltip}
                    onClick={() => handleCellClick(String(product[key]))}
                  >
                    {formatCellValue(key, product[key])}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {tooltip.visible && (
          <div
            className="tooltip-text"
            style={{
              position: "fixed",
              backgroundColor: colorsConfig.black,
              padding: "8px 12px",
              borderRadius: 5,
              color: "white",
              fontSize: "14px",
              zIndex: 1000,
              pointerEvents: "none",
              whiteSpace: "nowrap",
              top: tooltip.position.top,
              left: tooltip.position.left,
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            {tooltip.text}
            {tooltip.text === "Copied" && (
              <CheckIcon style={{ marginLeft: 4, width: 16, height: 16 }} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ComplexTable;
