import { useTranslation } from "react-i18next";
import { LineAreaValueChart } from "shared/components/common/chart/LineAreaValueChart";
import { ArrowUp } from "shared/icons/ArrowUp";
import { CurrencyFormat, pctFormat } from "shared/utils/currency";
import { ISOTimestamp } from "shared/utils/types";
import { useCallback, useMemo, useState } from "react";
import { Panel } from "shared/components/hoc/Panels";
import { Labels } from "shared/components/hoc/Labels";
import {
  DefaultManagedCapitalSettings,
  ManagedCapital,
  ManagedCapitalSettings,
} from "modules/managed_capital/ManagedCapital";
import { Loading } from "shared/types/enums";
import useAggregatedAumStore from "shared/store/aggregatedAumStore";

export const AggregatedAum: React.FC = () => {
  const { t } = useTranslation();

  const {
    chartData: data,
    loading,
    fetchAggregatedAum,
  } = useAggregatedAumStore();

  const [settings, setSettings] = useState<ManagedCapitalSettings>(
    DefaultManagedCapitalSettings,
  );

  const onPeriodChange = useCallback(
    (startDate: ISOTimestamp | null, endDate: ISOTimestamp | null) => {
      fetchAggregatedAum({
        start_date: startDate,
        end_date: endDate,
      });
    },
    [],
  );

  const aum =
    data && data.points.length
      ? // @ts-ignore
        data.points[data.points.length - 1].end_equity
      : 0;

  const perfMeasure = settings.perfMeasure;
  const cumulativePerfMeasure =
    data && data.performance
      ? perfMeasure === "twr"
        ? data.performance?.twr
        : data.performance?.mwr
      : 0;
  const positive = cumulativePerfMeasure >= 0;

  const points = useMemo(() => {
    return data?.points.map((point) => ({
      ...point,
      twr: +point.performance.twr,
      mwr: +point.performance.mwr,
      end_equity: +point.end_equity,
    }));
  }, [data]);

  return (
    <>
      <Panel className="w-full">
        <Labels.H3>{t("aggregatedAum.title")}</Labels.H3>
        {loading === Loading.Finished && !points?.length ? (
          <Labels.G1>{t("totalInvesting.noData")}</Labels.G1>
        ) : (
          <>
            <div className="my-4">
              <ManagedCapital onSettingsChange={setSettings} />
            </div>
            <Labels.H1>
              {CurrencyFormat.format(aum).replace(/^(\D+)/, "$1 ")}
            </Labels.H1>
            <div
              className={
                "flex items-center gap-2 text-xl font-bold " +
                (positive ? "text-sb-green-1001" : "")
              }
            >
              <ArrowUp
                className={"h-6 w-6 " + (positive ? "" : "rotate-180")}
              />
              <div>{pctFormat.format(cumulativePerfMeasure)}</div>
            </div>
            <LineAreaValueChart
              data={points}
              xKey="date"
              yKey="end_equity"
              yValueFormatter={(value) => CurrencyFormat.format(value)}
              yRightKey={perfMeasure}
              yRightFormatter={(value) => pctFormat.format(value)}
              yRightValueFormatter={(value) => pctFormat.format(value)}
              onPeriodChange={onPeriodChange}
              domain={[
                (dataMin: number) =>
                  dataMin ? dataMin - 0.03 * dataMin : dataMin,
                (dataMax: number) => dataMax + 0.05 * dataMax,
              ]}
              zoomable
            />
          </>
        )}
      </Panel>
    </>
  );
};
