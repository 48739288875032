import { DefaultUser } from "pages/fa/clients/svg/DefaultUser";
import { useNavigate } from "react-router-dom";
import { AvatarImage } from "shared/components/common/AvatarImage";
import { Panel } from "shared/components/hoc/Panels";
import { Client } from "shared/models/client/ClientModel";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import { formatDate } from "shared/utils/date";

type Props = {
  portfolio: Portfolio;
  client?: Client;
};

export const PortfolioCard: React.FC<Props> = ({ portfolio, client }) => {
  const navigate = useNavigate();

  return (
    <Panel
      className="relative cursor-pointer duration-150 hover:bg-sb-gray-200"
      onClick={() => navigate(`/portfolios/${portfolio.id}`)}
    >
      <div className="absolute right-4 top-4 text-sb-gray-500">
        {formatDate(portfolio.created_at)}
      </div>
      <div className="flex h-full flex-col justify-between gap-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <AvatarImage
              className="mr-3 shrink-0"
              imageSrc={portfolio.cover?.uri}
              width={40}
              height={40}
            />
            <div>
              <div className="text-base font-medium text-sb-gray-500">
                {portfolio.symbol}
              </div>
              <div className="text-lg font-semibold">{portfolio.title}</div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
};
