import React, { useState, useMemo, useEffect } from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  Line,
  Text,
} from "recharts";
import { useTranslation } from "react-i18next";
import { ChartRanges } from "shared/components/charts/ChartRanges";
import { formatDate } from "utils/dateUtils";
import { Palette } from "config/palette";

type PortfolioValue = {
  date: string;
  value: number;
};

type CashFlow = {
  date: string;
  type: "deposit" | "withdrawal";
  amount: number;
};

type Props = {
  portfolioValues: PortfolioValue[];
  cashFlows: CashFlow[];
  height: number;
  range: ChartRange;
  onRangeChange: (range: ChartRange) => void;
};

export type ChartRange = "1m" | "3m" | "6m" | "1y" | "max";

const ranges: { label: string; value: ChartRange }[] = [
  { label: "1M", value: "1m" },
  { label: "3M", value: "3m" },
  { label: "6M", value: "6m" },
  { label: "1Y", value: "1y" },
  { label: "Max", value: "max" },
];

const PortfolioValueChart: React.FC<Props> = ({
  portfolioValues,
  cashFlows,
  height,
  range,
  onRangeChange,
}) => {
  const { t } = useTranslation();
  const [hoverData, setHoverData] = useState<PortfolioValue | null>(null);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(value);
  };

  const getFilteredData = (data: PortfolioValue[], range: ChartRange) => {
    const now = new Date();
    let startDate = new Date();

    switch (range) {
      case "1m":
        startDate.setMonth(now.getMonth() - 1);
        break;
      case "3m":
        startDate.setMonth(now.getMonth() - 3);
        break;
      case "6m":
        startDate.setMonth(now.getMonth() - 6);
        break;
      case "1y":
        startDate.setFullYear(now.getFullYear() - 1);
        break;
      case "max":
      default:
        return data;
    }

    return data.filter((item) => new Date(item.date) >= startDate);
  };

  const availableRanges = useMemo(() => {
    if (portfolioValues.length === 0) return [];

    return ranges.filter(({ value }) => {
      const filteredData = getFilteredData(portfolioValues, value);
      // Ensure there are at least 2 data points to render a chart
      return filteredData.length >= 2;
    });
  }, [portfolioValues]);

  const filteredPortfolioValues = getFilteredData(portfolioValues, range);
  const filteredCashFlows = cashFlows.filter((cf) =>
    filteredPortfolioValues.some((pv) => pv.date === cf.date),
  );

  const combinedData = filteredPortfolioValues.map((pv) => ({
    ...pv,
    cashFlow: filteredCashFlows.find((cf) => cf.date === pv.date),
  }));

  if (combinedData.length === 0) {
    return <div>{t("noDataAvailable")}</div>;
  }

  const positive =
    combinedData[0].value <= combinedData[combinedData.length - 1].value;

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="rounded-md bg-gray-800 p-2">
          <p className="text-gray-300">{`Date: ${formatDate(data.date)}`}</p>
          <p className="text-white">{`${t("portfolioValue")}: ${formatCurrency(
            data.value,
          )}`}</p>
          {data.cashFlow && (
            <p className="text-white">
              {`${t(data.cashFlow.type)}: ${formatCurrency(
                data.cashFlow.amount,
              )}`}
            </p>
          )}
        </div>
      );
    }
    return null;
  };

  const CustomCursor = ({ points }: any) => {
    return (
      <g>
        <line
          x1={points[0].x}
          y1={0}
          x2={points[0].x}
          y2={height}
          stroke={Palette.Grey60}
          strokeWidth={1}
          strokeDasharray="3 3"
        />
      </g>
    );
  };

  const lastValue = combinedData[combinedData.length - 1].value;

  const CustomYAxisTick = ({ x, y, payload }: any) => {
    const isLastValue = payload.value === lastValue;
    const formattedValue = formatCurrency(payload.value);

    if (isLastValue) {
      return (
        <g transform={`translate(${x},${y})`}>
          <rect
            x={0}
            y={-9}
            fill={
              isLastValue
                ? positive
                  ? Palette.DarkGreen
                  : Palette.Magenta
                : Palette.Grey60
            }
            width={90}
            height={18}
            rx={4}
            ry={4}
          />
          <text
            x={5}
            y={0}
            dy=".355em"
            textAnchor="start"
            fill={
              isLastValue
                ? positive
                  ? Palette.Black
                  : Palette.White
                : Palette.White
            }
            fontSize={12}
            fontWeight="bold"
          >
            {formattedValue}
          </text>
        </g>
      );
    }
    return null; // Don't render other ticks
  };

  const yDomain = [
    Math.min(...combinedData.map((d) => d.value)) * 0.95,
    Math.max(...combinedData.map((d) => d.value)) * 1.05,
  ];

  // New function to generate round number ticks
  const generateYAxisTicks = (
    min: number,
    max: number,
    maxTicks: number = 6,
  ): number[] => {
    const range = max - min;
    const roughStep = range / (maxTicks - 1);
    const stepMagnitude = Math.pow(10, Math.floor(Math.log10(roughStep)));
    let step = Math.ceil(roughStep / stepMagnitude) * stepMagnitude;

    const start = Math.floor(min / step) * step;
    const end = Math.ceil(max / step) * step;

    const ticks = [];
    for (let tick = start; tick <= end; tick += step) {
      ticks.push(tick);
    }

    return ticks;
  };

  const yAxisTicks = generateYAxisTicks(yDomain[0], yDomain[1]);

  return (
    <>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          data={combinedData}
          margin={{ top: 50, right: 100, left: 100, bottom: 0 }}
          onMouseMove={(e) => {
            if (e.activePayload) {
              setHoverData(e.activePayload[0].payload);
            }
          }}
          onMouseLeave={() => setHoverData(null)}
        >
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={positive ? Palette.DarkGreen : Palette.Magenta}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={positive ? Palette.DarkGreen : Palette.Magenta}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            tickFormatter={formatDate}
            tick={{ fill: Palette.Grey50 }}
          />
          <YAxis
            yAxisId="left"
            tickFormatter={(value: number) => formatCurrency(value)}
            domain={yDomain}
            tick={{ fill: Palette.Grey50 }}
            ticks={yAxisTicks}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            tick={CustomYAxisTick}
            tickLine={false}
            axisLine={false}
            domain={[Math.min(...yAxisTicks), Math.max(...yAxisTicks)]}
            ticks={[lastValue]}
          />
          <Tooltip content={<CustomTooltip />} cursor={<CustomCursor />} />
          <Area
            type="monotone"
            dataKey="value"
            stroke={positive ? Palette.DarkGreen : Palette.Magenta}
            fillOpacity={1}
            fill="url(#colorValue)"
            yAxisId="left"
          />
          {filteredCashFlows.map((cf, index) => (
            <ReferenceLine
              key={index}
              x={cf.date}
              yAxisId="left"
              stroke={
                cf.type === "deposit" ? Palette.DarkGreen : Palette.Magenta
              }
              label={{
                value: `${formatCurrency(
                  cf.type === "deposit" ? cf.amount : -cf.amount,
                )}`,
                position: "left",
                fill:
                  cf.type === "deposit" ? Palette.DarkGreen : Palette.Magenta,
                angle: -90,
                offset: 10,
                style: { textAnchor: "middle" },
              }}
            />
          ))}
          {hoverData && (
            <ReferenceLine
              y={hoverData.value}
              yAxisId="left"
              stroke={Palette.Grey60}
              strokeDasharray="3 3"
            />
          )}
          <ReferenceLine
            y={lastValue}
            yAxisId="left"
            stroke={Palette.Grey60}
            strokeDasharray="3 3"
          />
        </AreaChart>
      </ResponsiveContainer>
      {availableRanges.length > 0 && (
        <div style={{ marginLeft: "50px" }}>
          <ChartRanges
            values={availableRanges}
            activeValue={range}
            onClick={(value) => onRangeChange(value as ChartRange)}
          />
        </div>
      )}
      {hoverData && (
        <div className="mt-2 text-center">
          <span className="font-bold">{formatCurrency(hoverData.value)}</span>
          <span className="ml-2 text-gray-400">
            {formatDate(hoverData.date)}
          </span>
        </div>
      )}
    </>
  );
};

export default PortfolioValueChart;
