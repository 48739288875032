import React, { useState, useEffect, useCallback } from "react";
import { X, Search, Check } from "lucide-react";
import { useTranslation } from "react-i18next";
import styles from "./clientDetailsNotes.module.css";
import { CrmService } from "services/CrmServices";
import crmStore from "shared/store/crmStore";
import { NoteFormType, NoteType } from "types/crm/ClientTypes";

type ClientDataType = {
  participants: (string | number)[];
};
interface NoteFormProps {
  onClose: () => void;
  onNoteAdded: () => void;
  initialNote?: any;
  clientData: any;
}

export const NoteForm: React.FC<NoteFormProps> = ({
  onClose,
  onNoteAdded,
  initialNote,
  clientData,
}) => {
  const { t } = useTranslation();
  const { clientsList } = crmStore();
  const [searchTerm, setSearchTerm] = useState("");
  const [showParticipantSelector, setShowParticipantSelector] = useState(false);

  const { fetchClientsList } = crmStore();

  const getParticipantsIds = useCallback(() => {
    if (initialNote?.participants && Array.isArray(initialNote.participants)) {
      return initialNote.participants.map(
        (participant) => participant.client_id,
      );
    }
    // If it's a new note, automatically include the current client's ID
    return clientData.client_id ? [clientData.client_id] : [];
  }, [initialNote, clientData.client_id]);

  const [selectedParticipants, setSelectedParticipants] = useState<
    (string | number)[]
  >(() => {
    const participants = getParticipantsIds();
    return participants;
  });

  const [formData, setFormData] = useState({
    content: initialNote?.content || "",
    source: initialNote?.source || "",
  });

  const filteredClients = clientsList.filter((client) => {
    const fullName =
      `${client.client_info.first_name} ${client.client_info.last_name}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    if (initialNote?.participants) {
      const participants = getParticipantsIds();
      setSelectedParticipants(participants);
    }
  }, [initialNote, setSelectedParticipants, getParticipantsIds]);

  const handleParticipantToggle = (clientId: string | number) => {
    setSelectedParticipants((prev) => {
      const newParticipants = prev.includes(clientId)
        ? prev.filter((id) => id !== clientId)
        : [...prev, clientId];
      return newParticipants;
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const noteData: NoteFormType = {
        content: formData.content,
        source: formData.source,
        participants: selectedParticipants,
        updated_at: new Date().toISOString(), // Changed from Date.now()
      };

      if (initialNote) {
        await CrmService.updateCrmNote(initialNote.note_id, noteData);
      } else {
        await CrmService.addCrmNote(noteData);
      }
      onNoteAdded();
      fetchClientsList();
      onClose();
    } catch (error) {
      console.error("Error saving note:", error);
    }
  };

  return (
    <div className={styles.popup}>
      <div className={styles.popupContent}>
        <div className={styles.closeIcon} onClick={onClose}>
          <X size={18} />
        </div>
        <h2 className={styles.titleDay}>
          {initialNote ? t("crm.editNote") : t("crm.newNote")}
        </h2>

        <form onSubmit={handleSubmit} className={styles.eventForm} noValidate>
          <div className={styles.formGroup}>
            <label htmlFor="source">{t("crm.source")}*</label>
            <input
              type="text"
              id="source"
              value={formData.source}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, source: e.target.value }))
              }
              className={styles.input}
              required
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="content">{t("crm.content")}*</label>
            <textarea
              id="content"
              required
              value={formData.content}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, content: e.target.value }))
              }
              className={styles.textarea}
              rows={6}
            />
          </div>

          <div className={styles.formGroup}>
            {!initialNote && (
              <>
                <label>{t("crm.participants")}*</label>
                <button
                  type="button"
                  className={styles.participantButton}
                  onClick={() =>
                    setShowParticipantSelector(!showParticipantSelector)
                  }
                >
                  {selectedParticipants.length > 0
                    ? `${selectedParticipants.length} ${t("crm.selectedParticipants")}`
                    : t("crm.selectParticipants")}
                </button>
              </>
            )}
            {initialNote && <label>{t("crm.participants")}*</label>}

            {selectedParticipants.length > 0 && (
              <div className={styles.selectedParticipantsList}>
                {selectedParticipants.map((participantId) => {
                  const client = clientsList.find(
                    (c) => c.client_id === participantId,
                  );
                  if (!client) return null;
                  return (
                    <div
                      key={participantId}
                      className={styles.selectedParticipant}
                    >
                      <span>
                        {client.client_info.first_name}{" "}
                        {client.client_info.last_name}
                      </span>
                      {!initialNote && (
                        <button
                          type="button"
                          className={styles.removeParticipant}
                          onClick={() => handleParticipantToggle(participantId)}
                        >
                          <X size={14} />
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            {showParticipantSelector && (
              <div className={styles.participantSelector}>
                <div className={styles.searchContainer}>
                  <Search size={16} className={styles.searchIcon} />
                  <input
                    type="text"
                    placeholder={t("crm.searchParticipants")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={styles.searchInput}
                  />
                </div>

                <div className={styles.participantList}>
                  {filteredClients.map((client) => {
                    const isSelected = selectedParticipants.includes(
                      client.client_id,
                    );
                    return (
                      <div
                        key={client.client_id}
                        className={`${styles.participantItem} ${
                          isSelected ? styles.selected : ""
                        }`}
                        onClick={() =>
                          handleParticipantToggle(client.client_id)
                        }
                      >
                        <span>
                          {client.client_info.first_name}{" "}
                          {client.client_info.last_name}
                        </span>
                        {isSelected && (
                          <Check size={16} className={styles.checkIcon} />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <div className={styles.formActions}>
            <button
              type="button"
              onClick={onClose}
              className={styles.closeButton}
            >
              {t("crm.cancel")}
            </button>
            <button type="submit" className={styles.submitButton}>
              {initialNote ? t("crm.update") : t("crm.save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NoteForm;
