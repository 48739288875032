import React, { useState, useEffect } from "react";
import { Checkbox } from "shared/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shared/components/ui/select";
import usePortfolioEditorStore from "shared/store/portfolioEditorStore";
import { AssistantService } from "shared/services/AssistantService";
import { QuestionnaireIcon } from "./chat/components/QuestionnaireIcon";
import { isEmptyObject, shallowCompareObjects } from "utils/misc";
import { useTranslation } from "react-i18next";
import mainStyles from "style/main.module.css";
import colorsStore from "shared/store/colorsStore";

type Option = {
  value: string;
  label: string;
};

type Question = {
  id: string;
  type: string;
  label?: string;
  question?: string;
  options?: Option[];
  default?: boolean;
  dependency?: boolean;
  isDependent?: {
    id: string;
    value: string[];
  };
};

type EsgPreferencesFormProps = {
  activityPanel?: boolean;
  prefilledData?: Record<string, any>;
  questions: Question[];
  actionId?: string;
  actionThreadId?: string;
  onChange?: (formState: Record<string, any>) => void;
  onSubmitProp: (body: any | null) => void;
};

const EsgPreferencesForm: React.FC<EsgPreferencesFormProps> = ({
  activityPanel,
  prefilledData,
  questions,
  actionId,
  actionThreadId,
  onChange,
  onSubmitProp,
}) => {
  const { t } = useTranslation();

  const [formState, setFormState] = useState<Record<string, any>>({});
  const [showSubmit, setShowSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const setFormUserData = usePortfolioEditorStore(
    (state) => state.setFormUserData,
  );

  const checkIfLastQuestion = () => {
    for (let i = 0; i < questions.length; i++) {
      const q = questions[i];
      if (q.isDependent) {
        const dependentValue = formState[q.isDependent.id];
        if (!dependentValue || !q.isDependent.value.includes(dependentValue)) {
          continue;
        }
      }

      if (q.type === "multiple-checkbox") {
        if (!Array.isArray(formState[q.id]) || formState[q.id].length === 0) {
          return false;
        }
      } else if (formState[q.id] === undefined && q.type !== "checkbox") {
        return false;
      } else if (q.type === "checkbox" && !formState[q.id]) {
        formState[q.id] = false;
      }
    }
    return true; // All questions are answered
  };

  useEffect(() => {
    const allAnswered = checkIfLastQuestion();
    setShowSubmit(allAnswered);

    if (prefilledData) {
      if (isEmptyObject(formState)) {
        setShowSubmit(false);
      } else {
        const { esg_questionnaire } = convertFormState(formState);
        const areTheSame = shallowCompareObjects(
          esg_questionnaire,
          prefilledData.esg_questionnaire,
        );
        if (!areTheSame) {
          setShowSubmit(true);
        } else {
          setShowSubmit(false);
        }
      }
    }
  }, [formState]);

  const onChangeFormState = (newState: Record<string, any>) => {
    setFormState(newState);
    if (onChange) {
      onChange(newState);
    }
  };
  const onSubmit = () => {
    setSubmitting(true);
    const { body, esg_questionnaire } = convertFormState(formState);
    setFormUserData({
      userHasFilled: true,
      questions: {
        question_1: esg_questionnaire.question_1,
        question_2: esg_questionnaire.question_2,
        question_3: esg_questionnaire.question_3,
        question_4: esg_questionnaire.question_4,
        question_5: esg_questionnaire.question_5,
        question_6: esg_questionnaire.question_6,
      },
    });

    if (!activityPanel) {
      AssistantService.threadAction(actionThreadId, actionId, body)
        .then((response) => {
          setSubmitted(true);
          setSubmitting(false);
          onSubmitProp(null);
        })
        .catch((error) => {
          console.error("Error performing action", error);
        });
    } else {
      setSubmitting(false);
      onSubmitProp(body);
    }
  };

  const convertFormState = (formState: Record<string, any>) => {
    const dirty_esg_questionnaire = {
      question_1: formState["question_1"],
      question_2: formState["question_2"],
      question_3: formState["question_3"],
      question_4: formState["question_4"],
      question_5: formState["question_5"],
      question_6: formState["question_6"],
    };
    const esg_questionnaire = {
      question_1: null,
      question_2: null,
      question_3: null,
      question_4: null,
      question_5: null,
      question_6: null,
    };
    for (const key in dirty_esg_questionnaire) {
      const value = dirty_esg_questionnaire[key];

      switch (true) {
        case value === "true":
          esg_questionnaire[key] = true;
          break;
        case value === "false":
          esg_questionnaire[key] = false;

          break;
        case !value:
          esg_questionnaire[key] = null;

          break;
        default:
          esg_questionnaire[key] = value;
      }
    }
    const body = { esg_questionnaire };
    return { body, esg_questionnaire };
  };

  return (
    <>
      {!submitted && (
        <div className="flex items-start gap-4">
          {!activityPanel && <QuestionnaireIcon />}
          <div>
            <EsgForm
              prefilledData={prefilledData}
              submitted={submitted}
              questions={questions}
              onChange={onChangeFormState}
            ></EsgForm>
            {!submitted && showSubmit && (
              <div
                className={mainStyles.button}
                onClick={() => {
                  onSubmit();
                }}
                style={{ cursor: "pointer" }}
              >
                {submitting ? t("actions.submitting") : t("actions.submit")}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EsgPreferencesForm;
type EsgFormProps = {
  prefilledData?: Record<string, any>;
  submitted: boolean;
  questions: Question[];
  onChange: (formState: Record<string, any>) => void;
  formStateProp?: any;
};

export const EsgForm: React.FC<EsgFormProps> = ({
  prefilledData,
  submitted,
  questions,
  onChange,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const [formState, setFormState] = useState<Record<string, any>>({});

  useEffect(() => {
    if (prefilledData?.esg_questionnaire) {
      const form = Object.entries(prefilledData.esg_questionnaire).reduce(
        (acc, [key, value]) => {
          if (value === true || value === false) {
            acc[key] = value;
          } else if (Array.isArray(value)) {
            acc[key] = value;
          } else {
            acc[key] = value;
          }
          return acc;
        },
        {},
      );
      setFormState(form);
    }
  }, [prefilledData]);

  const handleChange = (id: string, value: any) => {
    const newState = { ...formState, [id]: value };
    setFormState(newState);
    onChange(newState);
  };

  const handleMultipleCheckboxChange = (id: string, value: string) => {
    const currentValues = Array.isArray(formState[id]) ? formState[id] : [];
    const newValue = currentValues.includes(value)
      ? currentValues.filter((v) => v !== value)
      : [...currentValues, value];

    const newState = { ...formState, [id]: newValue };
    setFormState(newState);
    onChange(newState);
  };

  const renderQuestion = (question: Question) => {
    const isDependentMet = (q: Question): boolean => {
      if (!q.isDependent) return true;

      const dependentValue = formState[q.isDependent.id];
      // Convert boolean true to "true" string for comparison
      const valueToCheck =
        dependentValue === true
          ? "true"
          : dependentValue === false
            ? "false"
            : dependentValue;

      return q.isDependent.value.includes(valueToCheck);
    };

    if (!isDependentMet(question)) {
      return null;
    }

    switch (question.type) {
      case "checkbox":
        return (
          <div key={question.id} className="mb-4">
            <Checkbox
              id={question.id}
              checked={formState[question.id] || false}
              onCheckedChange={(checked) => handleChange(question.id, checked)}
            />
            <label htmlFor={question.id} className={mainStyles.label}>
              {question.label}
            </label>
          </div>
        );
      case "dropdown":
        return (
          <div key={question.id} className="mb-4">
            <p className="mb-2">{question.question}</p>
            <Select
              value={String(formState[question.id] ?? "")}
              onValueChange={(value) => handleChange(question.id, value)}
            >
              <SelectTrigger
                className="w-full"
                style={{
                  width: "100%",
                  padding: "1rem",
                  borderRadius: "12px",
                  border: "1px solid #8F949C",
                  backgroundColor: "#ffffff",
                  fontSize: "14px",
                }}
              >
                <SelectValue placeholder="Select an option" />
              </SelectTrigger>
              <SelectContent
                style={{
                  borderRadius: "12px",
                  border: "1px solid #8F949C",
                  backgroundColor: "#ffffff",
                }}
              >
                {question.options?.map((option) => (
                  <SelectItem
                    key={option.value}
                    value={option.value}
                    style={{
                      padding: "0.6rem",
                      fontSize: "14px",
                      color: "#1f2937",
                    }}
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        );
      case "multiple-checkbox":
        return (
          <div key={question.id} className="mb-4">
            <p className="mb-2">{question.question}</p>
            {question.options?.map((option) => (
              <div key={option.value} className="mb-2 flex items-center">
                <Checkbox
                  id={`${question.id}-${option.value}`}
                  checked={(formState[question.id] || []).includes(
                    option.value,
                  )}
                  onCheckedChange={() =>
                    handleMultipleCheckboxChange(question.id, option.value)
                  }
                />
                <label
                  htmlFor={`${question.id}-${option.value}`}
                  className={mainStyles.label}
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <form
      className="mb-10 space-y-4 rounded-lg p-4"
      style={{ backgroundColor: colorsConfig.white }}
    >
      {!submitted && (
        <h2
          className="mb-4 text-xl font-semibold"
          style={{ color: colorsConfig.finecoBlack }}
        >
          ESG questionnaire
        </h2>
      )}
      {!submitted && questions.map((question) => renderQuestion(question))}
    </form>
  );
};
