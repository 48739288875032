import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PillSelector } from "shared/components/controls/PillSelector";
import { ClientRow } from "./ClientRow";
import { SearchClients } from "./SearchClients";
import useClientsStore from "shared/store/clientsStore";

type Props = {
  onChange: (client_ids: string[]) => void;
};

export const MultiClientPicker: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();
  const { clients, fetchClients } = useClientsStore();
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [filter, setFilter] = useState("");

  const filteredClients = clients.filter(
    (c) =>
      c.summary.client_name.includes(filter) ||
      c.summary.email.includes(filter),
  );

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    onChange(selectedClients);
  }, [onChange, selectedClients]);

  const onClientSelected = (client_id: string) => {
    if (selectedClients.includes(client_id)) {
      setSelectedClients(selectedClients.filter((c) => c !== client_id));
    } else {
      setSelectedClients([...selectedClients, client_id]);
    }
  };

  const clearSelectedClients = () => {
    setSelectedClients([]);
  };

  const selectAllClients = () => {
    setSelectedClients(clients.map((c) => c.user_id));
  };

  return (
    <PillSelector
      selectedValues={selectedClients.map((c) => {
        return {
          label: clients.find((client) => String(client.user_id) === String(c))
            ?.summary.client_name,
          value: c,
        };
      })}
      placeholder={t("clientPicker.placeholder")}
      onPillRemoved={onClientSelected}
    >
      <div className="mx-2 border-b border-sb-gray-100 px-2 pb-2 pt-4">
        <SearchClients
          filter={filter}
          setFilter={setFilter}
          selectAllClients={selectAllClients}
          clearSelectedClients={clearSelectedClients}
        />
      </div>
      <div className="max-h-[300px] select-none overflow-y-auto">
        {filteredClients.map((client) => {
          return (
            <ClientRow
              key={"client_row_" + client.user_id}
              client={client}
              onSelected={onClientSelected}
              checked={selectedClients.includes(client.user_id)}
            />
          );
        })}
      </div>
    </PillSelector>
  );
};
