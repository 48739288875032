import React from "react";
import SimpleTable from "./SimpleTable";
import FinecoPieChart from "./FinecoPieChart";
import "./PortfolioItem.css"; // Custom CSS for layout
import { PortfolioElementType } from "types/assistant/PortfolioTypes";
import BarChart from "./BarChart";
import styles from "./../finecoPortfolioArtifact.module.css";
type Props = {
  dataItem: PortfolioElementType;
};
const PortfolioItem: React.FC<Props> = ({ dataItem }) => {
  return (
    <>
      <h2 className={styles.subTitle}>{dataItem.configData.label}</h2>
      <div className="portfolio-distribution">
        <SimpleTable dataObject={dataItem} />
        {dataItem.configData.type === "pie" && (
          <FinecoPieChart dataItem={dataItem} />
        )}
        {dataItem.configData.type === "bar_chart" && (
          <BarChart dataItem={dataItem} />
        )}
      </div>
    </>
  );
};

export default PortfolioItem;
