import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import { Labels } from "shared/components/hoc/Labels";
import { DatePicker, DateStates } from "./components/KycDatePicker";

import { LetUsKnowYouColumn } from "./components/LetUsKnowYouColumn";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingPartProps } from "./OnboardingFlow";
import useOnboardingStore from "shared/store/onboardingStore";

export const Birthday: React.FC<OnboardingPartProps> = ({
  onNext,
  onPrevious,
}) => {
  const { t } = useTranslation();
  const { birthday, taxCountry } = useOnboardingStore((state) => state.data);
  const { update } = useOnboardingStore();
  const [dateState, setDateState] = useState<DateStates | undefined>(
    birthday ? DateStates.ValidDate : DateStates.Empty,
  );

  const handleOnValueChange = (
    newDate: string | undefined,
    dateStateValue?: DateStates,
  ) => {
    setDateState(dateStateValue);
    if (newDate) {
      update({ birthday: newDate });
    } else {
      update({ birthday: undefined });
    }
  };

  const isPayingTaxesInUsa = taxCountry === "USA";

  const birthdayIsInvalid =
    dateState === DateStates.AgeUnderMin || dateState === DateStates.Empty;

  const userIsUnderAge = dateState === DateStates.AgeUnderMin;

  return (
    <OnboardingContainer
      leftColumnContent={<LetUsKnowYouColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1">
            <Labels.H2 className="mb-2">
              {t("forms.kyc.screen_titles.birthday.title")}
            </Labels.H2>
            <Labels.B1 className="mb-6">
              {t("forms.kyc.screen_titles.birthday.sub_title")}
            </Labels.B1>
            <div className="rounded-lg bg-sb-gray-100 pb-2 pl-4 ">
              <DatePicker
                usaDateOrder={isPayingTaxesInUsa}
                minAge={18}
                maxAge={150}
                onDateSelect={(newDate: Date | undefined, dateState) => {
                  handleOnValueChange(newDate?.toISOString() || "", dateState);
                }}
                initialDate={new Date(birthday || "")}
              />
            </div>
            {userIsUnderAge ? (
              <Labels.B1 className="my-4 text-sb-red-500">
                {t("kyc.error.underage")}
              </Labels.B1>
            ) : null}
            <Labels.B1 />
          </div>
          <OnboardingFooter
            onNext={() => onNext && onNext()}
            onPrev={() => onPrevious && onPrevious()}
            nextDisabled={birthdayIsInvalid}
          />
        </div>
      }
    />
  );
};
