import React, { useEffect, useRef, useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import PortfolioConfigData from "config/portfolio-config.json";

const FinecoPieChart = ({ dataItem }) => {
  const containerRef = useRef(null);

  const truncate = (text, maxLength) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  const [pieH, setPieH] = useState(400);
  useEffect(() => {
    const table = document.querySelector(".table-container");
    const parentHeight = containerRef.current.parentElement
      .querySelector(".table-container")
      .getBoundingClientRect().height;

    if (parentHeight && parentHeight < 150) {
      setPieH(150);
    }
    // if (table && table.getBoundingClientRect().height < 200) {
    //   setHeight(table.getBoundingClientRect().height);
    // }
  }, []);
  return (
    <div
      ref={containerRef}
      className="pie-chart-container"
      style={{ minHeight: "100%" }}
    >
      <ResponsiveContainer width="100%" height={pieH}>
        <PieChart>
          <Pie
            data={dataItem.data}
            dataKey="weight"
            nameKey="label"
            cx="50%"
            cy="50%"
            innerRadius="50%"
            outerRadius="70%"
          >
            {dataItem.data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  PortfolioConfigData.palettes[dataItem.configData.palette][
                    index %
                      PortfolioConfigData.palettes[dataItem.configData.palette]
                        .length
                  ]
                }
              />
            ))}
          </Pie>
          <Tooltip
            formatter={(value, name) => [
              `${truncate(String(name), 20)}: ${Number(value).toFixed(2)}%`,
            ]}
            contentStyle={{ width: "100%", textAlign: "center" }}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FinecoPieChart;
