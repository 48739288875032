import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AssistantMessage } from "types/assistant/AssistantTypes";
import { TextResponse } from "../responses/TextResponse";
import { MessageArtifacts } from "pages/assistant/components/artifacts/MessageArtifacts";
import { FinecoAgentIcon } from "assets/svg/FinecoAgentIcon";
import { CopyIcon, CheckIcon } from "lucide-react";
import styles from "./chatMessage.module.css";
import StreetbeatIcon from "../StreetbeatIcon";
import brandStore from "shared/store/brandStore";

type Props = {
  message?: AssistantMessage;
  onArtifactsLoaded?: () => void;
  isConnectionOpen?: boolean;
  onSend?: (text: string) => void;
  disabled?: boolean;
  isWidget?: boolean;
};

export const ChatAgentMessage: React.FC<Props> = ({
  message,
  onArtifactsLoaded,
  isConnectionOpen,
  onSend,
  disabled,
  isWidget = false,
}) => {
  const { t } = useTranslation();
  const { aiChatLogo } = brandStore();
  const [copied, setCopied] = useState(false);
  const [
    finecoPortfolioAndPortfolioStoryTogether,
    setFinecoPortfolioAndPortfolioStoryTogether,
  ] = useState(false);

  const handleCopy = () => {
    const textToCopy = message?.content
      ?.map((item) => item.text.value)
      .join(" ");
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    }
  };

  const getResponseComponent = () => {
    if (message?.content?.[0]?.type === "text") {
      return <TextResponse message={message} />;
    }
  };
  //** let's verify if we have both, portfolio fineco and portfolio story together */
  useEffect(() => {
    const hasPortfolio = message.artifacts.some(
      (artifact) => artifact.type === "fineco_portfolio",
    );

    const hasPortfolioStory = message.artifacts.some(
      (artifact) =>
        artifact.type === "custom_data" &&
        artifact.version === "portfolio_story",
    );

    if (hasPortfolio && hasPortfolioStory) {
      setFinecoPortfolioAndPortfolioStoryTogether(true);
    } else {
      setFinecoPortfolioAndPortfolioStoryTogether(false);
    }
  }, [message]);

  const iconSizeClass = `icon-size-32-32`;
  return (
    <div className="relative max-w-full">
      <div className="mt-2">
        <div className="flex min-w-0 flex-nowrap items-start gap-4">
          <div className="flex-shrink-0" style={{ width: 32, height: 32 }}>
            {aiChatLogo === "fineco" && (
              <FinecoAgentIcon className={iconSizeClass} />
            )}
            {aiChatLogo === "streetbeatn" && <StreetbeatIcon size={40} />}
          </div>
          <div className="min-w-0 flex-1">
            <div className={styles.agentTitle}>{t("assistant.agent")}</div>
            <div className="group relative break-words">
              {getResponseComponent()}
            </div>
            {message?.artifacts?.map((artifact, index) => {
              if (!isWidget) {
                return (
                  <div key={index}>
                    <MessageArtifacts
                      finecoPortfolioAndPortfolioStoryTogether={
                        finecoPortfolioAndPortfolioStoryTogether
                      }
                      message={message}
                      artifact={artifact}
                      onLoaded={onArtifactsLoaded}
                      isConnectionOpen={isConnectionOpen}
                      onSend={onSend}
                      disabled={disabled}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
        <CopyButton handleCopy={handleCopy} copied={copied}></CopyButton>
      </div>
    </div>
  );
};

const CopyButton = ({ handleCopy, copied }) => {
  return (
    <button onClick={handleCopy} className={styles.chatCopyButton}>
      {copied ? (
        <>
          <CheckIcon className="h-4 w-4" /> Copied
        </>
      ) : (
        <>
          <CopyIcon className="h-4 w-4" />
        </>
      )}
    </button>
  );
};
