import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useRealtimeTicker from "shared/hooks/useRealtimeTicker";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import {
  CreateTradingOrder,
  TradingOrderType,
} from "shared/models/trading/TradingOrderModel";
import { TradingPosition } from "shared/models/trading/TradingPositionmodel";
import { OrdersService } from "shared/services/orders/OrdersService";
import { convertToNumber } from "shared/utils/converts";
import useCreateOrder from "../hooks/useCreateOrder";
import { useCreateOrderRequirements } from "../hooks/useCreateOrderRequirements";
import {
  CreateOrderReviewInfo,
  CreateOrderValidator,
  useCreateOrderValidation,
} from "../hooks/useCreateOrderValidation";
import { useAccount } from "shared/hooks/useAccount";
import { Spinner } from "shared/components/common/Spinner";
import OrderTypeSelect from "../OrderTypeSelect";
import LimitOrder from "./LimitOrder";
import MarketOrder from "./MarketOrder";
import StopOrder from "./StopOrder";
import TrailingStopOrder from "./TrailingStopOrder";
import { EstimatedValue } from "../EstimatedValue";
import { ClientBuyingPower } from "../ClientBuyingPower";
import { Client } from "shared/models/client/ClientModel";
import { ReviewOrderModal } from "../ReviewOrderModal";
import useClientsStore from "shared/store/clientsStore";
import useAccountStore from "shared/store/accountStore";
import useCreateOrderStore, {
  InitializeOrderPayload,
} from "shared/store/createOrderStore";
import useClientDetailsStore from "shared/store/clientDetailsStore";

type Props = {
  asset: AssetWrapper;
  position: TradingPosition | undefined;
  client?: Client;
  onSuccess?: (order?: CreateTradingOrder) => void;
  compact?: boolean;
};

export const BuyAsset: React.FC<Props> = ({
  asset,
  position,
  client,
  onSuccess,
  compact,
}) => {
  const { t } = useTranslation();
  const clientId = client?.user_id;
  const validation = useCreateOrderValidation({ asset, position, clientId });
  const order = useCreateOrder();

  const { fetchClientById } = useClientsStore();
  const { fetchAccount } = useAccountStore();
  const { initialize } = useCreateOrderStore();
  const { fetchOrders, fetchPositions } = useClientDetailsStore();

  const [type, setType] = useState<TradingOrderType>("limit");
  const requirements = useCreateOrderRequirements({
    side: "buy",
    type: type,
    position,
    asset,
  });

  const { latestPrice } = useRealtimeTicker(asset.symbol);

  const [loading, setLoading] = useState(false);

  const [warningsValue, setWarningsValue] = useState<string[]>();
  const [reviewed, setReviewed] = useState(false);
  const [orderValid, setOrderValid] = useState(false);
  const [reviewedOrder, setReviewedOrder] = useState<CreateOrderReviewInfo>();
  const { account } = useAccount();

  const [estimatedValue, setEstimatedValue] = useState<number>();
  const [estimatedShares, setEstimatedShares] = useState<number>();

  const [open, setOpen] = useState(false);

  const orderTypeSelect = (
    <div>
      <div>{t("trade.orderType")}</div>
      <OrderTypeSelect
        type={type}
        onChange={setType}
        side="buy"
        className="rounded-xl border border-sb-gray-200 px-3 py-2 !font-normal !text-black"
      />
    </div>
  );

  useEffect(() => {
    if (reviewed && reviewedOrder) {
      setOpen(true);
    }
  }, [reviewed, reviewedOrder]);

  const typeComponents: Record<TradingOrderType, JSX.Element> = {
    limit: (
      <LimitOrder asset={asset} onEstimatedValueChange={setEstimatedValue}>
        {orderTypeSelect}
      </LimitOrder>
    ),
    stop: (
      <StopOrder asset={asset} onEstimatedValueChange={setEstimatedValue}>
        {orderTypeSelect}
      </StopOrder>
    ),
    trailing_stop: (
      <TrailingStopOrder
        asset={asset}
        onEstimatedValueChange={setEstimatedValue}
      >
        {orderTypeSelect}
      </TrailingStopOrder>
    ),
    stop_limit: (
      <LimitOrder asset={asset} onEstimatedValueChange={setEstimatedValue}>
        {orderTypeSelect}
      </LimitOrder>
    ),
    market: (
      <MarketOrder
        asset={asset}
        onEstimatedValueChange={setEstimatedValue}
        onEstimatedSharesChange={setEstimatedShares}
      >
        {orderTypeSelect}
      </MarketOrder>
    ),
  };

  const amountType =
    order.qty !== undefined
      ? t("trade.summary.amountType.qty")
      : t("trade.summary.amountType.cash");

  useEffect(() => {
    if (clientId) {
      fetchClientById(clientId);
    } else {
      fetchAccount();
    }
  }, [clientId]);

  useEffect(() => {
    const assetClass = "us_equity";
    const newOrder: InitializeOrderPayload = {
      assetClass: assetClass,
      symbol: asset.symbol,
      side: "buy",
      type: type,
      allowNotional: requirements.allowNotional,
    };
    initialize(newOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  useEffect(() => {
    setReviewed(false);
  }, [order]);

  if (!account) {
    return null;
  }

  const validateOrder = () => {
    if (type === "limit") {
      const tradingAtPrice = convertToNumber(order?.limit_price, 0);
      reviewOrder({ tradingAtPrice, latestPrice }, [
        validation.limitPriceValidation,
        validation.remainingFractionValidation,
      ]);
    } else if (type === "market") {
      const tradingAtPrice = latestPrice;
      reviewOrder({ tradingAtPrice, latestPrice });
    } else if (type === "stop") {
      const tradingAtPrice = convertToNumber(order?.stop_price, 0);
      reviewOrder({ tradingAtPrice, latestPrice }, [
        validation.stopPriceValidation,
        validation.remainingFractionValidation,
      ]);
    } else if (type === "trailing_stop") {
      const tradingAtPrice = convertToNumber(order?.trail_price, 0);
      const tradingAtPercentage = convertToNumber(order?.trail_percent, 0);
      reviewOrder({ tradingAtPrice, tradingAtPercentage, latestPrice }, [
        validation.trailingStopPercentValidation,
        validation.remainingFractionValidation,
      ]);
    }
  };

  const reviewOrder = (
    reviewInfo: CreateOrderReviewInfo,
    validators: CreateOrderValidator[] = [],
  ) => {
    const [isValid, warnings] = validation.validateOrder(reviewInfo, [
      validation.minimumAmountValidation,
      validation.reducingPositionValidation,
      validation.buyingPowerValidation,
      validation.takeProfitValidation,
      validation.stopLossValidation,
      ...validators,
    ]);

    setOrderValid(isValid);
    if (isValid) {
      setReviewed(true);
      setReviewedOrder(reviewInfo);
    }
    setWarningsValue(warnings);
  };

  const makeOrder = () => {
    if (order && orderValid && clientId) {
      setLoading(true);
      OrdersService.create([order], clientId)
        .then(() => {
          fetchOrders(clientId, 10);
          fetchPositions(clientId);
          onSuccess?.(order);
          setReviewed(false);
          setReviewedOrder(undefined);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div>
      {typeComponents[type]}
      <div
        className={
          (compact ? "mt-12 flex w-full justify-between gap-4" : "w-full") +
          " !mt-12"
        }
      >
        {compact && <ClientBuyingPower client={client} />}
        <div className="flex justify-end gap-4">
          <EstimatedValue
            estimatedValue={estimatedValue}
            estimatedShares={estimatedShares}
            qtyType={order.qty ? "Shares" : "USD"}
          />
          <div
            className={"green-btn h-fit text-center"}
            onClick={() => {
              if (!loading) {
                validateOrder();
              }
            }}
          >
            {loading ? (
              <div className="flex justify-center">
                <Spinner className="h-4 w-4 fill-white text-sb-gray-500" />
              </div>
            ) : (
              <>{t("global.review")}</>
            )}
          </div>
        </div>
      </div>
      {reviewed && reviewedOrder && (
        <ReviewOrderModal
          open={open}
          setOpen={setOpen}
          order={order}
          reviewedOrder={reviewedOrder}
          amountType={amountType}
          warningsValue={warningsValue}
          setReviewed={setReviewed}
          makeOrder={makeOrder}
        />
      )}
      {warningsValue && warningsValue.length > 0 ? (
        <div className="my-4 space-y-2 text-base">
          <div className="font-roobert text-sm font-semibold">
            {t("trade.warnings_title").toUpperCase()}
          </div>
          {warningsValue?.map((item, idx) => {
            return <div key={"warning_" + idx}>{item}</div>;
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
