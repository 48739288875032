import { FeePayoutTerm } from "shared/models/fees/FeesModel";
import { create } from "./store";
import { Loading } from "shared/types/enums";
import { FeesService } from "shared/services/fees/FeesService";

type FeePayoutTermStoreState = {
  payoutTerms: FeePayoutTerm | undefined;
  loading: Loading;
};

type FeePayoutTermStoreActions = {
  fetchPayoutTerms: () => Promise<void>;
};

const initialData: FeePayoutTermStoreState = {
  payoutTerms: undefined,
  loading: Loading.Idle,
};

const useFeePayoutTermStore = create<
  FeePayoutTermStoreState & FeePayoutTermStoreActions
>()((set) => ({
  ...initialData,
  fetchPayoutTerms: async () => {
    set({ loading: Loading.InProgress });
    try {
      const payoutTerms = await FeesService.getPayoutTerms();
      set({ payoutTerms });
    } finally {
      set({ loading: Loading.Finished });
    }
  },
}));

export default useFeePayoutTermStore;
