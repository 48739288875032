import React, { useState, useEffect } from "react";
import { CheckboxSquare } from "shared/components/ui/checkboxSquare";
import colorsStore from "shared/store/colorsStore";

type ReportFormat = string;

interface ReportSubSection {
  visible: boolean;
  hidden?: boolean;
  title: string;
  formats: ReportFormat[];
}

export interface ReportSection {
  visible: boolean;
  hidden?: boolean;
  title: string;
  [key: string]: boolean | string | ReportSubSection | undefined;
}

export interface ReportSections {
  [key: string]: ReportSection;
}

export const isReportSubSection = (value: any): value is ReportSubSection => {
  return (
    value &&
    typeof value === "object" &&
    "visible" in value &&
    "formats" in value
  );
};

interface ReportSectionTreeProps {
  sections: ReportSections;
  onChange: (sections: ReportSections) => void;
}

export const ReportSectionsTree: React.FC<ReportSectionTreeProps> = ({
  sections,
  onChange,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const [selectedSections, setSelectedSections] = useState<
    Record<string, boolean>
  >({});
  const [selectedSubSections, setSelectedSubSections] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    const initSections: Record<string, boolean> = {};
    const initSubSections: Record<string, boolean> = {};

    Object.entries(sections).forEach(([sectionKey, section]) => {
      initSections[sectionKey] = section.visible;

      Object.entries(section).forEach(([key, value]) => {
        if (isReportSubSection(value)) {
          const subSectionKey = `${sectionKey}.${key}`;
          initSubSections[subSectionKey] = value.visible;
        }
      });
    });

    setSelectedSections(initSections);
    setSelectedSubSections(initSubSections);
  }, [sections]);

  const handleSectionChange = (sectionKey: string, checked: boolean) => {
    const newSelectedSections = { ...selectedSections, [sectionKey]: checked };
    const newSelectedSubSections = { ...selectedSubSections };

    // Update all non-hidden subsections when parent is changed
    Object.entries(sections[sectionKey]).forEach(([key, value]) => {
      if (isReportSubSection(value) && !value.hidden) {
        const subSectionKey = `${sectionKey}.${key}`;
        newSelectedSubSections[subSectionKey] = checked;
      }
    });

    setSelectedSections(newSelectedSections);
    setSelectedSubSections(newSelectedSubSections);
    updateParentSections(newSelectedSections, newSelectedSubSections);
  };

  const handleSubSectionChange = (
    sectionKey: string,
    subSectionKey: string,
    checked: boolean,
  ) => {
    const fullKey = `${sectionKey}.${subSectionKey}`;
    const newSelectedSubSections = {
      ...selectedSubSections,
      [fullKey]: checked,
    };

    // Check if any subsections are selected for this section
    const hasSelectedSubSections = Object.entries(newSelectedSubSections).some(
      ([key, value]) => key.startsWith(sectionKey) && value,
    );

    const newSelectedSections = {
      ...selectedSections,
      [sectionKey]: hasSelectedSubSections,
    };

    setSelectedSections(newSelectedSections);
    setSelectedSubSections(newSelectedSubSections);
    updateParentSections(newSelectedSections, newSelectedSubSections);
  };

  const updateParentSections = (
    selectedSections: Record<string, boolean>,
    selectedSubSections: Record<string, boolean>,
  ) => {
    // Create a deep copy of the original sections
    const updatedSections: ReportSections = JSON.parse(
      JSON.stringify(sections),
    );

    // Update main sections visibility
    Object.keys(selectedSections).forEach((sectionKey) => {
      if (updatedSections[sectionKey]) {
        updatedSections[sectionKey].visible = selectedSections[sectionKey];
      }
    });

    // Update subsections visibility
    Object.entries(selectedSubSections).forEach(([fullKey, isVisible]) => {
      const [sectionKey, subSectionKey] = fullKey.split(".");
      if (
        updatedSections[sectionKey] &&
        isReportSubSection(updatedSections[sectionKey][subSectionKey])
      ) {
        (
          updatedSections[sectionKey][subSectionKey] as ReportSubSection
        ).visible = isVisible;
      }
    });

    onChange(updatedSections);
  };

  const formatList = (formats: ReportFormat[]) => {
    return formats.length > 0 ? `(${formats.join(", ")})` : "";
  };

  return (
    <div className="h-[300px] overflow-y-auto pr-4">
      <div className="space-y-4">
        {Object.entries(sections).map(([sectionKey, section]) => {
          // Don't render hidden sections
          if (section.hidden) {
            return null;
          }

          return (
            <div key={sectionKey} className="space-y-2">
              <div
                className="flex items-center space-x-2"
                style={{ color: colorsConfig.black }}
              >
                <CheckboxSquare
                  checked={selectedSections[sectionKey]}
                  onCheckedChange={(checked) =>
                    handleSectionChange(sectionKey, checked as boolean)
                  }
                />
                <span className="font-medium">{section.title}</span>
              </div>

              <div
                className="ml-6 space-y-1"
                style={{ color: colorsConfig.finecoBlack }}
              >
                {Object.entries(section).map(([key, value]) => {
                  if (isReportSubSection(value)) {
                    // Don't render hidden subsections
                    if (value.hidden) {
                      return null;
                    }

                    const subSectionKey = `${sectionKey}.${key}`;
                    return (
                      <div key={key} className="flex items-center space-x-2">
                        <CheckboxSquare
                          checked={selectedSubSections[subSectionKey]}
                          onCheckedChange={(checked) =>
                            handleSubSectionChange(
                              sectionKey,
                              key,
                              checked as boolean,
                            )
                          }
                        />
                        <span className="text-sm">
                          {value.title}{" "}
                          <span className="text-xs text-gray-500">
                            {formatList(value.formats)}
                          </span>
                        </span>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
