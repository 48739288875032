import { Client } from "shared/models/client/ClientModel";
import { create } from "./store";
import { Loading } from "shared/types/enums";
import { ClientsService } from "shared/services/clients/ClientsService";

type ClientsStoreState = {
  clients: Client[];
  loading: Loading;
  fetched: boolean;
};

type ClientsStoreActions = {
  fetchClients: () => Promise<void>;
  fetchClientById: (clientId: string) => Promise<void>;
};

const initialData: ClientsStoreState = {
  clients: [],
  loading: Loading.Idle,
  fetched: false,
};

const useClientsStore = create<ClientsStoreState & ClientsStoreActions>()(
  (set, get) => ({
    ...initialData,
    fetchClients: async () => {
      set({ loading: Loading.InProgress });
      try {
        const clients = await ClientsService.getMany();
        set({ clients });
      } finally {
        set({ loading: Loading.Finished });
      }
    },
    fetchClientById: async (clientId) => {
      set({ loading: Loading.InProgress });
      try {
        const client = await ClientsService.getById(clientId);
        if (client) {
          const clients = get().clients;
          const existingIndex = clients.findIndex(c => String(c.user_id) === String(clientId));
          
          if (existingIndex >= 0) {
            // Replace existing client at same position
            const updatedClients = [...clients];
            updatedClients[existingIndex] = client;
            set({ clients: updatedClients });
          } else {
            // Add new client to end
            set({ clients: [...clients, client] });
          }
        }
      } finally {
        set({ loading: Loading.Finished });
      }
    },
  }),
);

export default useClientsStore;
