import { useTranslation } from "react-i18next";
import { CompanyLogo } from "shared/components/common/CompanyLogo";
import { Panel } from "shared/components/hoc/Panels";
import useAssetsExtras from "shared/hooks/useAssetsExtras";

type Props = {
  assetId?: string;
};

export const AssetInfo: React.FC<Props> = ({ assetId }) => {
  const { t } = useTranslation();
  const { info, hasAnyInfo } = useAssetsExtras(assetId ? assetId : "");

  if (!hasAnyInfo) {
    return null;
  }

  return (
    <Panel>
      {info && (
        <>
          <CompanyLogo
            className="h-16 w-fit rounded-md border border-sb-gray-200"
            symbol={assetId}
          />
          {info.description && (
            <div className="mt-8">
              <div className="text-xl font-medium">{t("assetInfo.about")}</div>
              <div className="mt-4 text-base">{info.description}</div>
            </div>
          )}
          {info.CEO && (
            <div className="mt-8">
              <div className="text-lg font-medium">{t("assetInfo.ceo")}</div>
              <div className="text-base">{info.CEO}</div>
            </div>
          )}
          {info.website && (
            <div className="mt-8">
              <div className="text-lg font-medium">
                {t("assetInfo.website")}
              </div>
              <a href={info.website} className="text-base">
                {info.website}
              </a>
            </div>
          )}
          {info.industry && (
            <div className="mt-8">
              <div className="text-lg font-medium">
                {t("assetInfo.industry")}
              </div>
              <div className="text-base">{info.industry}</div>
            </div>
          )}
          {info.sector && (
            <div className="mt-8">
              <div className="text-lg font-medium">{t("assetInfo.sector")}</div>
              <div className="text-base">{info.sector}</div>
            </div>
          )}
          {info.tags && (
            <div className="mt-8">
              <div className="mb-2 text-lg font-medium">
                {t("assetInfo.tags")}
              </div>
              <div className="flex flex-wrap items-center gap-2">
                {
                  // @ts-ignore
                  info.tags.map((item, idx) => {
                    return (
                      <div
                        key={"tag_" + idx}
                        className="w-fit rounded-full border border-sb-gray-400 bg-sb-gray-100 px-3 text-sm"
                      >
                        {item}
                      </div>
                    );
                  })
                }
              </div>
            </div>
          )}
        </>
      )}
    </Panel>
  );
};
