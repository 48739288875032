import { useEffect, useState } from "react";
import useAssets from "shared/hooks/useAssets";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import SearchAssets from "./SearchAssets";
import { ScrollArea } from "shared/components/ui/scroll-area";
import { CompanyLogo } from "shared/components/common/CompanyLogo";

type Props = {
  onClick: (symbol: string) => void;
};

export const sortSearchedAssets = (assets: AssetWrapper[], query: string) => {
  const filtered = assets.filter(
    (a) =>
      a.symbol.toLowerCase().includes(query.toLowerCase()) ||
      a.name.toLowerCase().includes(query.toLowerCase()),
  );
  const sorted = filtered.sort((a, b) => {
    const aStarts = a.symbol
      .toLocaleLowerCase()
      .startsWith(query.toLowerCase());
    const bStarts = b.symbol
      .toLocaleLowerCase()
      .startsWith(query.toLowerCase());
    if (aStarts && !bStarts) return -1;
    if (bStarts && !aStarts) return 1;
    if (a.symbol === b.symbol) return 0;
    if (b.symbol < a.symbol) return 1;
    return -1;
  });
  return sorted;
};

export const AddAsset: React.FC<Props> = ({ onClick }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<AssetWrapper[]>([]);
  const { assets } = useAssets();

  useEffect(() => {
    if (!query) {
      setResults([]);
    }
    if (assets && query && query.length >= 2) {
      const sorted = sortSearchedAssets(assets, query);
      setResults(sorted);
    }
  }, [query, assets]);

  return (
    <div className="relative">
      <SearchAssets query={query} setQuery={setQuery} />
      {results?.length > 0 && (
        <div className="bg-card absolute left-0 top-[110%] z-50 min-w-[400px] rounded-xl border border-gray-300 bg-white p-1 drop-shadow-lg">
          <ScrollArea className="max-h-[300px] ">
            {results.map((item, idx) => {
              return (
                <div
                  key={"asset_result_" + idx}
                  className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 duration-150 hover:bg-gray-100"
                  onClick={() => {
                    onClick(item.symbol);
                    setQuery("");
                  }}
                >
                  <div className="flex items-start gap-4">
                    <div className="size-12 border-gray-shadow bg-gray-shadow relative rounded-xl border-[4px]">
                      <CompanyLogo
                        symbol={item.symbol}
                        className="size-10 rounded-lg"
                      />
                    </div>
                    <div>
                      <div className="text-sm font-semibold text-gray-600">
                        {item.symbol}
                      </div>
                      <div className="text-base text-gray-500">{item.name}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </ScrollArea>
        </div>
      )}
    </div>
  );
};
