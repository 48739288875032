import { CrmStatusTypes } from "types/crm/CrmStatusTypes";
import { create } from "shared/store/store";
import { CrmService } from "services/CrmServices";
import {
  ClientDetailType,
  ClientPriorityContactType,
  CrmAlertType,
  CrmAssistantTask,
  CrmCampaignSummary,
  CrmGoalType,
  CrmTaskCalendarType,
  CrmTasksWhenType,
  TaskType,
} from "types/crm/ClientTypes";

type TabType = "dashboard" | "clients";

type CrmStoreState = {
  crmStatus: CrmStatusTypes;
  crmActiveTab: TabType;
  crmIsPanelOpen: boolean;
  clientsList: ClientDetailType[];
  tasks: TaskType[];
  tasksCalendar: CrmTaskCalendarType[];
  crmAlerts: CrmAlertType[];
  crmGoals: CrmGoalType[];
  clientPriorityContacts: ClientPriorityContactType[];
  crmAssistantTasks: CrmAssistantTask[];
  crmCampaignsSummary: CrmCampaignSummary[];
};

type CrmStoreActions = {
  setCrmStatus: (status: CrmStatusTypes) => void;
  setCrmActiveTab: (crmActiveTab: TabType) => void;
  setCrmIsPanelOpen: (isOpen: boolean) => void;

  fetchClientsList: () => void;

  fetchTasks: (
    when: CrmTasksWhenType,
    date?: string,
    clientIds?: (number | string)[],
  ) => void;

  fetchTasksCalendar: (
    year: string,
    month: string,
    clientIds?: (number | string)[],
  ) => void;

  fetchAlerts: (clientIds?: (number | string)[]) => void;
  fetchGoals: () => void;
  fetchClientPriorityContacts: () => void;
  fetchCrmAssistantTasks: () => void;
  fetchCrmCampaignsSummary: () => void;

  //** clear */
  clearCrmStatus: () => void;
};

const initialState: CrmStoreState = {
  crmStatus: "full",
  crmActiveTab: "dashboard",
  crmIsPanelOpen: false,
  clientsList: [],
  tasks: [],
  tasksCalendar: [],
  crmAlerts: [],
  crmGoals: [],
  clientPriorityContacts: [],
  crmAssistantTasks: [],
  crmCampaignsSummary: [],
};

const crmStore = create<CrmStoreState & CrmStoreActions>()((set) => ({
  ...initialState,
  setCrmStatus: (status) => {
    set({ crmStatus: status });
  },
  setCrmActiveTab: (tab) => set({ crmActiveTab: tab }),
  setCrmIsPanelOpen: (isOpen) => set({ crmIsPanelOpen: isOpen }),

  fetchClientsList: async () => {
    const clientsList = await CrmService.fetchClientsList();
    set({ clientsList: clientsList });
  },
  fetchTasks: async (
    when: CrmTasksWhenType,
    date?: string,
    clientIds?: (number | string)[],
  ) => {
    const tasks = await CrmService.fetchCrmTasks(when, date, clientIds);
    set({ tasks: tasks });
  },
  fetchTasksCalendar: async (
    year: string,
    month: string,
    clientIds?: (number | string)[],
  ) => {
    const tasksCalendar = await CrmService.fetchCrmTasksCalendar(
      year,
      month,
      clientIds,
    );
    set({ tasksCalendar: tasksCalendar });
  },
  fetchAlerts: async (clientIds?: (number | string)[]) => {
    const alerts = await CrmService.fetchCrmAlerts(clientIds);
    set({ crmAlerts: alerts });
  },
  fetchGoals: async () => {
    const goals = await CrmService.fetchCrmGoals();
    set({ crmGoals: goals });
  },
  fetchClientPriorityContacts: async () => {
    const clients = await CrmService.fetchClientPriorityContacts();
    set({ clientPriorityContacts: clients });
  },

  fetchCrmAssistantTasks: async () => {
    const assistantTasks = await CrmService.fetchCrmAssistantTasks();
    set({ crmAssistantTasks: assistantTasks });
  },

  fetchCrmCampaignsSummary: async () => {
    const campaignsSummary = await CrmService.fetchCrmCampaignsSummary();
    set({ crmCampaignsSummary: campaignsSummary });
  },

  clearCrmStatus: async () => {
    set({ crmStatus: "full" });
  },
}));

export default crmStore;
