import { useEffect } from "react";
import { XIcon } from "lucide-react";
import { PropsWithChildren } from "react";
import { ScrollArea, ScrollBar } from "./scroll-area";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
} & PropsWithChildren;

export const FullscreenOverlay: React.FC<Props> = ({
  open,
  setOpen,
  children,
}) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    }
  }, [open]);

  if (!open) return null;

  return (
    <>
      <div className="fixed inset-0 z-[98] bg-black opacity-80" />
      <div className="bg-card fixed inset-[40px] z-[99] flex items-center justify-center rounded-xl">
        <div
          className="absolute right-6 top-6 z-[100] cursor-pointer duration-150 hover:opacity-60"
          onClick={() => setOpen(false)}
        >
          <XIcon className="size-5" />
        </div>
        <ScrollArea className="my-12 h-full max-h-screen w-full p-4">
          {children}
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
    </>
  );
};
