import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ArrowUpDown, XIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MaximizeTableIcon } from "assets/svg/MaximizeTableIcon";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "shared/components/ui/table";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from "./alert-dialog";
import { Skeleton } from "./skeleton";
import colorsStore from "shared/store/colorsStore";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  loading?: boolean;
  onRowClicked?: (row: TData) => void;
  onRowSelection?: (rows: Row<TData>[]) => void;
  expandable?: boolean;
  hoverable?: boolean;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  loading,
  onRowClicked,
  onRowSelection,
  expandable,
  hoverable,
}: DataTableProps<TData, TValue>) {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [expanded, setExpanded] = useState(false);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });

  useEffect(() => {
    onRowSelection && onRowSelection(table.getSelectedRowModel().rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  return (
    <div className="w-full min-w-0">
      {expandable && (
        <AlertDialog open={expanded} onOpenChange={setExpanded}>
          <AlertDialogTrigger asChild>
            <div className="absolute right-2 top-2 z-50 cursor-pointer duration-150 hover:opacity-80 lg:right-4 lg:top-4">
              <MaximizeTableIcon className="size-3 lg:size-4" />
            </div>
          </AlertDialogTrigger>
          <AlertDialogContent className="max-w-[98%]">
            <div className="flex justify-end text-gray-600 dark:text-gray-500">
              <XIcon
                className="size-5 cursor-pointer"
                onClick={() => setExpanded(false)}
              />
            </div>
            <DataTable
              expandable={false}
              columns={columns}
              data={data}
              loading={loading}
              onRowClicked={onRowClicked}
              onRowSelection={onRowSelection}
            />
          </AlertDialogContent>
        </AlertDialog>
      )}
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className="text-sm"
                    style={{
                      backgroundColor: colorsConfig.finecoBluePrimary,
                      color: colorsConfig.white,
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody
          style={{
            backgroundColor: colorsConfig.grayLight,
          }}
        >
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                onClick={() => onRowClicked && onRowClicked(row.original)}
                onMouseEnter={() => hoverable && setHoveredRow(row.id)}
                onMouseLeave={() => hoverable && setHoveredRow(null)}
                style={{
                  cursor: onRowClicked ? "pointer" : "default",
                  backgroundColor:
                    hoverable && hoveredRow === row.id
                      ? colorsConfig.white
                      : "inherit",
                  transition: "background-color 0.2s, color 0.2s",
                }}
              >
                {row.getVisibleCells().map((cell, index) => (
                  <TableCell
                    key={cell.id}
                    style={{
                      color:
                        hoverable && hoveredRow === row.id
                          ? colorsConfig.black
                          : colorsConfig.chatCopyColor,
                      borderTopLeftRadius:
                        hoverable && index === 0 ? "0.75rem" : "0",
                      borderBottomLeftRadius:
                        hoverable && index === 0 ? "0.75rem" : "0",
                      borderTopRightRadius:
                        hoverable && index === row.getVisibleCells().length - 1
                          ? "0.75rem"
                          : "0",
                      borderBottomRightRadius:
                        hoverable && index === row.getVisibleCells().length - 1
                          ? "0.75rem"
                          : "0",
                      transition: "color 0.2s",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {loading ? (
                  <div className="space-y-4">
                    <Skeleton className="h-4 w-[250px]" />
                    <Skeleton className="h-4 w-[200px]" />
                  </div>
                ) : (
                  t("global.noResults")
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

type SortableHeaderProps = {
  text: string;
  column: Column<any, unknown>;
};

export const SortableHeader: React.FC<SortableHeaderProps> = ({
  text,
  column,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  return (
    <div
      className="flex cursor-pointer items-center"
      onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    >
      {text}
      <ArrowUpDown
        className="ml-2 h-4 w-4"
        style={{ color: colorsConfig.vectorTableHeaderCopy }}
      />
    </div>
  );
};
