type Props = {
  className?: string;
  style?: React.CSSProperties; // Aggiungi style qui
};

export default function SendIcon({ className, style }: Props) {
  return (
    <svg
      className={className}
      style={style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_234_1755)">
        <mask
          id="mask0_234_1755"
          style={{ maskType: "luminance" }} // Usa style come oggetto
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <path d="M0 0H16V16H0V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_234_1755)">
          <path
            d="M15.607 8.63206L2.11597 15.9051C1.56197 16.1991 0.892972 15.7401 1.00897 15.1351L2.02697 9.65406C2.05697 9.4954 2.13743 9.35069 2.25636 9.24148C2.3753 9.13228 2.52634 9.06443 2.68697 9.04806L9.09797 8.38106H9.10797C9.20863 8.37952 9.30476 8.33891 9.37603 8.26782C9.44731 8.19673 9.48817 8.10071 9.48997 8.00006C9.4879 7.89923 9.44665 7.80318 9.37497 7.73225C9.30329 7.66131 9.20681 7.62108 9.10597 7.62006H9.09697L2.65997 6.72706C2.50544 6.70938 2.36059 6.6428 2.24654 6.53703C2.1325 6.43126 2.05522 6.29183 2.02597 6.13906L1.01697 0.866058C0.902972 0.260058 1.57197 -0.207942 2.12597 0.0960584L15.607 7.35006C15.7239 7.41128 15.8218 7.50333 15.8901 7.61623C15.9585 7.72914 15.9946 7.85859 15.9946 7.99056C15.9946 8.12253 15.9585 8.25198 15.8901 8.36488C15.8218 8.47779 15.7239 8.56984 15.607 8.63106V8.63206Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_234_1755">
          <rect width="16" height="16" fill="black" />
        </clipPath>
      </defs>
    </svg>
  );
}
