import React, { useEffect, useState } from "react";
import { CustomDataArtifactRecord } from "types/assistant/AssistantTypes";
import { EyeIcon, BarChart3Icon } from "lucide-react";
import { Button } from "shared/components/ui/button";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
} from "shared/components/ui/dialog";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "shared/components/ui/tabs";
import PortfolioValueChart from "../fineco-portfolio-artifact/components/PortfolioValueChart";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "shared/components/ui/table";
import PnlChart from "pages/assistant/components/chat/components/portfolios/PnlChart";
import { Palette } from "config/palette";
import { ChartRange } from "../fineco-portfolio-artifact/components/PortfolioValueChart";

type FinecoCRMPortfoliosArtifactProps = {
  data: CustomDataArtifactRecord;
  onLoaded?: () => void;
};

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  }).format(value);
};

const formatPercentage = (value: number) => {
  return new Intl.NumberFormat("it-IT", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString("it-IT", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const PortfolioHeader: React.FC<{ portfolio: any }> = ({ portfolio }) => {
  const { t } = useTranslation();

  return (
    <div className="mb-4 flex items-center justify-between">
      <div className="flex items-center">
        <BarChart3Icon className="mr-2" size={20} />
        {t("crmPortfolios.portfolioRiskProfile")}{" "}
        {portfolio.portfolio.risk_profile} - {t("crmPortfolios.financialPlan")}:{" "}
        {portfolio.portfolio.financial_plan}
      </div>
      <Dialog>
        <DialogTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="text-blue-400 hover:text-blue-300"
          >
            <EyeIcon size={16} className="mr-1" />
            {t("crmPortfolios.view")}
          </Button>
        </DialogTrigger>
        <DialogContent style={{ maxWidth: "80%" }}>
          <PortfolioDetails portfolio={portfolio} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const PortfolioSummary: React.FC<{ portfolio: any }> = ({ portfolio }) => {
  const { t } = useTranslation();
  const lastCashFlow =
    portfolio.investment.cash_flows[portfolio.investment.cash_flows.length - 1];
  const lastPortfolioValue =
    portfolio.investment.portfolio_value[
      portfolio.investment.portfolio_value.length - 1
    ];

  return (
    <div className="rounded-lg bg-gray-800 p-4">
      <PortfolioHeader portfolio={portfolio} />
      <div className="mb-4 h-[200px]">
        <PnlChart
          chartData={portfolio.investment.portfolio_value.map((pv: any) => ({
            date: pv.date,
            cash: pv.value,
            tooltipValue: pv.value,
          }))}
          height={200}
          domain={["dataMin", "dataMax"]}
          showTooltip={true}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <p>
            <strong>{t("crmPortfolios.volatility")}:</strong>{" "}
            {formatPercentage(
              portfolio.investment.current_performance.volatility / 100,
            )}
          </p>
          <p>
            <strong>{t("crmPortfolios.sharpeRatio")}:</strong>{" "}
            {portfolio.investment.current_performance.sharpe_ratio.toFixed(2)}
          </p>
          <p>
            <strong>{t("crmPortfolios.totalReturn")}:</strong>{" "}
            {formatPercentage(
              portfolio.investment.current_performance.total_return,
            )}
          </p>
          <p>
            <strong>{t("crmPortfolios.annualizedReturn")}:</strong>{" "}
            {formatPercentage(
              portfolio.investment.current_performance.annualized_return,
            )}
          </p>
        </div>
        <div className="space-y-2">
          <p>
            <strong>{t("crmPortfolios.startDate")}:</strong>
            <span className="ml-2 font-medium">
              {formatDate(portfolio.investment.investment_start)}
            </span>
          </p>
          <p>
            <strong>{t("crmPortfolios.currentInvestment")}:</strong>
            <span className="ml-2 font-medium">
              {formatCurrency(portfolio.investment.current_investment_amount)}
            </span>
          </p>
          <p>
            <strong>{t("crmPortfolios.lastCashFlow")}:</strong>
            <span className="ml-2 font-medium">
              {formatCurrency(lastCashFlow.amount)}
            </span>
            <span className="ml-2 text-sm text-gray-400">
              ({formatDate(lastCashFlow.date)})
            </span>
          </p>
          <p>
            <strong>{t("crmPortfolios.lastPortfolioValue")}:</strong>
            <span className="ml-2 font-medium">
              {formatCurrency(lastPortfolioValue.value)}
            </span>
            <span className="ml-2 text-sm text-gray-400">
              ({formatDate(lastPortfolioValue.date)})
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

const PortfolioDetails: React.FC<{ portfolio: any }> = ({ portfolio }) => {
  const { t } = useTranslation();
  const [range, setRange] = useState<ChartRange>("max");

  return (
    <Tabs defaultValue="portfolio-value" className="h-full w-full">
      <TabsList className="mb-2">
        <TabsTrigger value="portfolio-value">
          {t("crmPortfolios.portfolioValue")}
        </TabsTrigger>
        <TabsTrigger value="asset-allocation">
          {t("crmPortfolios.assetAllocation")}
        </TabsTrigger>
        <TabsTrigger value="cash-flows">
          {t("crmPortfolios.cashFlows")}
        </TabsTrigger>
        <TabsTrigger value="performance">
          {t("crmPortfolios.performance")}
        </TabsTrigger>
      </TabsList>
      <TabsContent value="portfolio-value" className="h-[600px] w-full">
        <PortfolioValueChart
          portfolioValues={portfolio.investment.portfolio_value}
          cashFlows={portfolio.investment.cash_flows}
          height={500}
          range={range}
          onRangeChange={setRange}
        />
      </TabsContent>
      <TabsContent
        value="asset-allocation"
        className="h-[600px] w-full overflow-hidden"
      >
        <AssetAllocation
          data={portfolio.portfolio.assets}
          totalInvestment={portfolio.investment.current_investment_amount}
        />
      </TabsContent>
      <TabsContent
        value="cash-flows"
        className="h-[600px] w-full overflow-hidden"
      >
        <div className="flex h-full justify-center overflow-y-auto">
          <CashFlows investment={portfolio.investment} />
        </div>
      </TabsContent>
      <TabsContent
        value="performance"
        className="h-[600px] w-full overflow-hidden"
      >
        <div className="flex h-full justify-center overflow-y-auto">
          <PortfolioPerformanceDetails portfolio={portfolio} />
        </div>
      </TabsContent>
    </Tabs>
  );
};

const PortfolioPerformanceDetails: React.FC<{ portfolio: any }> = ({
  portfolio,
}) => {
  const { t } = useTranslation();

  const formatPercentage = (value: number) => {
    if (value < 0.0001) {
      return "< 0.01%";
    }
    return new Intl.NumberFormat("it-IT", {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const formatNumber = (value: number) => {
    return new Intl.NumberFormat("it-IT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const combinedData = [
    {
      category: t("crmPortfolios.currentPerformance"),
      items: [
        {
          label: t("crmPortfolios.volatility"),
          value: formatPercentage(
            portfolio.investment.current_performance.volatility / 100,
          ),
        },
        {
          label: t("crmPortfolios.sharpeRatio"),
          value:
            portfolio.investment.current_performance.sharpe_ratio.toFixed(2),
        },
        {
          label: t("crmPortfolios.totalReturn"),
          value: formatPercentage(
            portfolio.investment.current_performance.total_return,
          ),
        },
        {
          label: t("crmPortfolios.annualizedReturn"),
          value: formatPercentage(
            portfolio.investment.current_performance.annualized_return,
          ),
        },
      ],
    },
    {
      category: t("crmPortfolios.riskMetrics"),
      items: [
        {
          label: t("crmPortfolios.var"),
          value: formatPercentage(portfolio.portfolio.var),
        },
        {
          label: t("crmPortfolios.totalRisk"),
          value: formatPercentage(portfolio.portfolio.total_risk / 100),
        },
        {
          label: t("crmPortfolios.marketRisk"),
          value: formatPercentage(portfolio.portfolio.market_risk / 100),
        },
        {
          label: t("crmPortfolios.specificRisk"),
          value: formatPercentage(portfolio.portfolio.specific_risk / 100),
        },
      ],
    },
    {
      category: t("crmPortfolios.performanceMetrics"),
      items: [
        {
          label: t("crmPortfolios.return"),
          value: formatPercentage(portfolio.portfolio.return),
        },
        {
          label: t("crmPortfolios.sharpe"),
          value: formatNumber(portfolio.portfolio.sharpe),
        },
        {
          label: t("crmPortfolios.ytm"),
          value: formatPercentage(portfolio.portfolio.ytm / 100),
        },
        {
          label: t("crmPortfolios.dividendYield"),
          value: formatPercentage(portfolio.portfolio.dividend_yield),
        },
      ],
    },
    {
      category: t("crmPortfolios.portfolioCharacteristics"),
      items: [
        {
          label: t("crmPortfolios.duration"),
          value: formatNumber(portfolio.portfolio.duration),
        },
        {
          label: t("crmPortfolios.esg"),
          value: formatNumber(portfolio.portfolio.esg),
        },
        {
          label: t("crmPortfolios.esgAligned"),
          value: portfolio.portfolio.esg_aligned
            ? t("global.yes")
            : t("global.no"),
        },
        {
          label: t("crmPortfolios.finalScore"),
          value: formatNumber(portfolio.portfolio.final_score),
        },
        {
          label: t("crmPortfolios.finalScoreAdvice"),
          value: formatNumber(portfolio.portfolio.final_score_advice),
        },
      ],
    },
  ];

  return (
    <div className="h-full w-1/2 overflow-auto">
      <Table>
        <TableBody>
          {combinedData.map((category, categoryIndex) => (
            <React.Fragment key={categoryIndex}>
              <TableRow>
                <TableCell
                  colSpan={2}
                  className="py-6 text-center text-2xl font-semibold text-gray-500"
                >
                  {category.category}
                </TableCell>
              </TableRow>
              {category.items.map((item, itemIndex) => (
                <TableRow key={`${categoryIndex}-${itemIndex}`}>
                  <TableCell className="py-2">{item.label}</TableCell>
                  <TableCell className="py-2 text-right">
                    {item.value}
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const CashFlows: React.FC<{ investment: any }> = ({ investment }) => {
  const { t } = useTranslation();

  const getPillColor = (type: string) => {
    if (type === "deposit") {
      return { backgroundColor: Palette.Green, color: Palette.Black };
    } else if (type === "withdrawal") {
      return { backgroundColor: Palette.Red, color: Palette.Black };
    }
    return {};
  };

  // Sort cash flows by date in descending order
  const sortedCashFlows = [...investment.cash_flows].sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );

  return (
    <div className="h-full w-1/2 overflow-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="p-2 text-left">
              {t("crmPortfolios.date")}
            </TableHead>
            <TableHead className="p-2 text-left">
              {t("crmPortfolios.type")}
            </TableHead>
            <TableHead className="p-2 text-right">
              {t("crmPortfolios.amount")}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedCashFlows.map((cashFlow: any, index: number) => (
            <TableRow key={index}>
              <TableCell className="p-2">{formatDate(cashFlow.date)}</TableCell>
              <TableCell className="p-2">
                <span
                  className="rounded-full px-2 py-1 text-xs font-semibold"
                  style={getPillColor(cashFlow.type)}
                >
                  {t(`crmPortfolios.${cashFlow.type}`)}
                </span>
              </TableCell>
              <TableCell className="p-2 text-right">
                {formatCurrency(cashFlow.amount)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const AssetAllocation: React.FC<{ data: any[]; totalInvestment: number }> = ({
  data,
  totalInvestment,
}) => {
  const { t } = useTranslation();

  // Sort the data array by weight in descending order
  const sortedData = [...data].sort((a, b) => b.weight - a.weight);

  return (
    <div className="h-full overflow-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="p-2 text-left">
              {t("crmPortfolios.isin")}
            </TableHead>
            <TableHead className="p-2 text-left">
              {t("crmPortfolios.titolo")}
            </TableHead>
            <TableHead className="p-2 text-left">
              {t("crmPortfolios.microcat")}
            </TableHead>
            <TableHead className="p-2 text-right">
              {t("crmPortfolios.investment")}
            </TableHead>
            <TableHead className="p-2 text-right">
              {t("crmPortfolios.ret")}
            </TableHead>
            <TableHead className="p-2 text-right">
              {t("crmPortfolios.var")}
            </TableHead>
            <TableHead className="p-2 text-right">
              {t("crmPortfolios.peso")}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedData.map((asset, index) => (
            <TableRow key={index}>
              <TableCell className="p-2">{asset.isin}</TableCell>
              <TableCell className="p-2">{asset.description}</TableCell>
              <TableCell className="p-2">{asset.microcategory}</TableCell>
              <TableCell className="p-2 text-right">
                {formatCurrency(asset.weight * totalInvestment)}
              </TableCell>
              <TableCell className="p-2 text-right">
                {formatPercentage(asset.expected_return)}
              </TableCell>
              <TableCell className="p-2 text-right">
                {formatPercentage(asset.var)}
              </TableCell>
              <TableCell className="p-2 text-right">
                {formatPercentage(asset.weight)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const ClientHeader: React.FC<{ contact: any }> = ({ contact }) => {
  return (
    <div className="mb-4">
      <h2 className="text-xl font-semibold">
        {contact.given_name} {contact.family_name}
      </h2>
    </div>
  );
};

const PortfolioList: React.FC<{ portfolios: any[] }> = ({ portfolios }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      {portfolios.map((portfolio) => (
        <PortfolioSummary key={portfolio.portfolio_id} portfolio={portfolio} />
      ))}
    </div>
  );
};

export const FinecoCRMPortfoliosArtifact: React.FC<
  FinecoCRMPortfoliosArtifactProps
> = ({ data, onLoaded }) => {
  useEffect(() => onLoaded?.(), []);

  if (!data.portfolios || data.portfolios.length === 0) {
    return null;
  }

  return (
    <div className="w-full">
      <ClientHeader contact={data.contact} />
      <PortfolioList portfolios={data.portfolios} />
    </div>
  );
};

export default FinecoCRMPortfoliosArtifact;
