import SuggestedPromptsData from "config/suggestedPromptsData.json";
import styles from "./suggestedPrompts.module.css";
import * as SuggestedIconsIndex from "assets/svg/suggested-prompts/SuggestedPromptsIndex";
import brandStore from "shared/store/brandStore";
import { useMemo } from "react";

type SuggestedPromptsProps = {
  onSend?: (text: string) => void;
};
type PromptType = {
  text: string;
  iconName: string;
};
export const SuggestedPrompts: React.FC<SuggestedPromptsProps> = ({
  onSend,
}) => {
  const { promptsSet } = brandStore();
  //** feed list of suggested prompts with correct suggested prompts from json */

  const suggestedPromptsList: PromptType[] | string[] =
    SuggestedPromptsData.helpPrompt[promptsSet];

  const gridClass = useMemo(() => {
    const itemCount = suggestedPromptsList.length;

    if (itemCount <= 1) return styles.gridSingle;
    if (itemCount <= 4) return styles.gridDouble;
    if (itemCount <= 6) return styles.gridTriple;
    return styles.gridQuad;
  }, [suggestedPromptsList.length]);

  const getIconComponent = (iconName: string) => {
    const IconComponent =
      SuggestedIconsIndex[iconName as keyof typeof SuggestedIconsIndex];
    return IconComponent ? (
      <IconComponent className={styles.iconPrompt} />
    ) : null;
  };
  return (
    <div>
      <div className={`${styles.gridContainer} ${gridClass}`}>
        {suggestedPromptsList.map((message, idx) => {
          return (
            <div
              key={"init_" + idx}
              className={styles.gridItemContainer}
              onClick={() => {
                onSend(message.text);
              }}
            >
              <div className={styles.gridItem}>
                {getIconComponent(message.iconName)}
                {message.text}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
