import { Contact } from "shared/models/contact/AddContactModel";
import { create } from "./store";
import { Loading } from "shared/types/enums";
import { ContactsService } from "shared/services/contacts/ContactsService";

type ContactsStoreState = {
  contacts: Contact[];
  loading: Loading;
};

type ContactsStoreActions = {
  fetchContacts: () => void;
  fetchContactById: (contactId: string) => Promise<void>;
};

const initialData: ContactsStoreState = {
  contacts: [],
  loading: Loading.Idle,
};

const useContactsStore = create<ContactsStoreState & ContactsStoreActions>()(
  (set, get) => ({
    ...initialData,
    fetchContacts: async () => {
      set({ loading: Loading.InProgress });
      try {
        const contacts = await ContactsService.getAll();
        set({ contacts });
      } finally {
        set({ loading: Loading.Finished });
      }
    },
    fetchContactById: async (contactId: string) => {
      set({ loading: Loading.InProgress });
      try {
        const contact = await ContactsService.get(contactId);
        set({
          contacts: [
            ...get().contacts.filter((c) => c.contact_id !== contactId),
            contact,
          ],
        });
      } finally {
        set({ loading: Loading.Finished });
      }
    },
  }),
);

export default useContactsStore;
