import { useEffect, useState } from "react";
import useThreadsStore from "shared/store/threadsStore";
import appStatusStore from "shared/store/appStatusStore";
import { ChatHistoryPanel } from "./panels/chat-history/ChatHistoryPanel";
import { EsgPreferencesPanel } from "./panels/esg-preferences/EsgPreferencesPanel";
import { ConstraintsPanel } from "./panels/constraints/ConstraintsPanel";
import { AttachmentsPanel } from "./panels/attachments/AttachmentsPanel";
import { PortfoliosPanel } from "./panels/portfolios/PortfoliosPanel";
import { useParams } from "react-router-dom";
import activityPanelStore from "shared/store/activityPanelStore";
import ConstraintsConfig from "config/constraints-config.json";

const sizeExpanded = 400;
export const ActivityPanelContainer: React.FC = () => {
  const params = useParams();
  const threadId = params.threadId;

  const appStatus = appStatusStore((state) => state.appStatus);
  const setAppStatus = appStatusStore((state) => state.setAppStatus);

  const constraintsState = activityPanelStore(
    (state) => state.constraintsState,
  );
  const hasSentMessage = activityPanelStore((state) => state.hasSentMessage);
  const setHasSentMessage = activityPanelStore(
    (state) => state.setHasSentMessage,
  );

  const financialPlanStore = activityPanelStore(
    (state) => state.financialPlanStore,
  );
  const [expanded, setExpanded] = useState(false);
  const [widthSize, setWidthSize] = useState(0);

  useEffect(() => {
    useThreadsStore.getState().fetchThreads();
  }, []);

  useEffect(() => {
    if (hasSentMessage) {
      if (appStatus === "constraints") {
        setAppStatus("idle");
      }
      setHasSentMessage(false);
    }
  }, [hasSentMessage]);

  useEffect(() => {
    if (!constraintsState?.data) {
      const defaultData = {};
      ConstraintsConfig.constraints.forEach((constraint) => {
        // @ts-ignore
        defaultData[constraint.type] = {};
        Object.entries(constraint.data).forEach(
          ([key, config]: [string, any]) => {
            // @ts-ignore
            defaultData[constraint.type][key] =
              config.default ?? config.value ?? null;
          },
        );
      });
      const test = {
        messageId: Date.now().toString,
        data: defaultData,
      };
      activityPanelStore.getState().setPreviousConstraintsStore(test);
    }
  }, [constraintsState?.data, financialPlanStore]);

  useEffect(() => {
    if (threadId) {
      if (appStatus !== "history") {
        setAppStatus("idle");
      }
    }
  }, [threadId]);

  useEffect(() => {
    switch (appStatus) {
      case "history":
        setExpanded(true);
        setWidthSize(sizeExpanded);
        break;
      case "esgPreferences":
        setExpanded(true);
        setWidthSize(sizeExpanded);
        break;
      case "constraints":
        setExpanded(true);
        setWidthSize(sizeExpanded);
        break;
      case "attachments":
        setExpanded(true);
        setWidthSize(sizeExpanded);
        break;
      case "portfolios":
        setExpanded(true);
        setWidthSize(sizeExpanded);
        break;
      default:
        setExpanded(false);
        setWidthSize(0);
    }
  }, [appStatus]);

  return (
    <>
      <aside
        className={"h-full overflow-hidden duration-500"}
        style={{ width: widthSize }}
      >
        <div className="lex-col flex h-full grid-cols-2 justify-stretch gap-x-4  gap-y-4">
          {appStatus === "history" && (
            <ChatHistoryPanel
              expanded={expanded}
              setExpanded={setExpanded}
              sizeExpanded={sizeExpanded}
            />
          )}
          {appStatus === "esgPreferences" && (
            <EsgPreferencesPanel
              expanded={expanded}
              setExpanded={setExpanded}
            />
          )}
          {appStatus === "constraints" && (
            <ConstraintsPanel expanded={expanded} setExpanded={setExpanded} />
          )}
          {appStatus === "attachments" && (
            <AttachmentsPanel expanded={expanded} setExpanded={setExpanded} />
          )}
          {appStatus === "portfolios" && (
            <PortfoliosPanel expanded={expanded} setExpanded={setExpanded} />
          )}

          <div className="col-span-2 block lg:hidden">
            {/* <AttachmentsWidget expanded={expanded} setExpanded={setExpanded} /> */}
          </div>
        </div>
      </aside>
    </>
  );
};
