import { AttachmentsWidget } from "modules/pro_ai_assistant/AttachmentsWidget";
import { ThreadPortfolios } from "modules/pro_ai_assistant/ThreadPortfolios";
import { ScrollArea } from "shared/components/ui/scroll-area";

export const RightBar: React.FC = () => {
  return (
    <ScrollArea
      className="h-full"
      style={{
        maxHeight: "calc(100dvh - 125px)",
      }}
    >
      <div className="h-full w-[320px] flex-nowrap space-y-4">
        <AttachmentsWidget />
        <ThreadPortfolios />
      </div>
    </ScrollArea>
  );
};
