import React from "react";
import { useTranslation } from "react-i18next";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import { Labels } from "shared/components/hoc/Labels";
import i18n from "shared/i18n/i18n";
import { EmploymentStatusType } from "shared/models/brokerage_account/BrokerageAccountModel";

import { FewMoreQuestionsColumn } from "./components/FewMoreQuestionsColumn";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { RadioAnswers, RadioOption } from "./components/RadioAnswers";
import { OnboardingPartProps } from "./OnboardingFlow";
import useOnboardingStore from "shared/store/onboardingStore";

const options: RadioOption<EmploymentStatusType>[] = [
  { label: i18n.t("forms.employmentStatus.employed"), value: "employed" },
  { label: i18n.t("forms.employmentStatus.student"), value: "student" },
  { label: i18n.t("forms.employmentStatus.unemployed"), value: "unemployed" },
  { label: i18n.t("forms.employmentStatus.retired"), value: "retired" },
];

export const EmploymentStatus: React.FC<OnboardingPartProps> = ({
  onNext,
  onPrevious,
}) => {
  const { t } = useTranslation();
  const { employmentStatus, nationality, taxCountry } = useOnboardingStore(
    (state) => state.data,
  );
  const { update } = useOnboardingStore();
  const nonUSNationality = nationality !== "USA";
  const isPayingTaxesInUSA = taxCountry === "USA";

  const onSelectEmploymentStatus = (value: EmploymentStatusType) => {
    update({ employmentStatus: value });
  };

  const nextHandler = () => {
    if (!onNext) return;
    if (employmentStatus === "employed") {
      onNext();
      return;
    }
    onNext("funding_source");
  };

  const onPreviousHandler = () => {
    const previousStep =
      nonUSNationality && isPayingTaxesInUSA ? "upload_visa" : "taxId";
    if (onPrevious) {
      onPrevious(previousStep);
    }
  };

  return (
    <OnboardingContainer
      leftColumnContent={<FewMoreQuestionsColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1">
            <Labels.H2 className="mb-8">
              {t("webOnboarding.employmentStatus")}
            </Labels.H2>
            <RadioAnswers
              options={options}
              value={employmentStatus}
              onChange={onSelectEmploymentStatus}
            />
          </div>
          <OnboardingFooter
            onNext={nextHandler}
            onPrev={() => onPreviousHandler()}
            nextDisabled={!employmentStatus}
          />
        </div>
      }
    />
  );
};
