import { ChevronLeft } from "lucide-react";
import { cn } from "utils";

type Props = {
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
};

export const ChevronCircle: React.FC<Props> = ({ expanded, setExpanded }) => {
  return (
    <div
      className="size-10 grid cursor-pointer place-items-center rounded-full border border-gray-300 text-gray-500 duration-150"
      onClick={() => setExpanded && setExpanded(!expanded)}
    >
      <ChevronLeft
        className={cn("size-6 duration-150", expanded ? "-rotate-90" : "")}
        strokeWidth={1}
      />
    </div>
  );
};
