import { create } from "./store";
import { Loading } from "shared/types/enums";
import { FeeTemplateEntry } from "shared/types/FeeTemplateType";
import { FeeTemplatesService } from "shared/services/fee_templates/FeeTemplatesService";

type FeeTemplatesStoreState = {
  feeTemplates: FeeTemplateEntry[];
  loading: Loading;
};

type FeeTemplatesStoreActions = {
  fetchFeeTemplates: () => Promise<void>;
};

const initialData: FeeTemplatesStoreState = {
  feeTemplates: [],
  loading: Loading.Idle,
};

const useFeeTemplatesStore = create<
  FeeTemplatesStoreState & FeeTemplatesStoreActions
>()((set) => ({
  ...initialData,
  fetchFeeTemplates: async () => {
    set({ loading: Loading.InProgress });
    try {
      const templates = await FeeTemplatesService.getMany();
      set({ feeTemplates: templates });
    } finally {
      set({ loading: Loading.Finished });
    }
  },
}));

export default useFeeTemplatesStore;
