import { AssetWrapper } from "shared/models/asset/AssetModel";
import useAssetsStore from "shared/store/assetsStore";

export default function useAssets() {
  const { assets, loading } = useAssetsStore();
  const assets_map: Record<string, AssetWrapper> = {};

  if (assets) {
    for (let asset of assets) {
      assets_map[asset.symbol] = asset;
    }
  }

  return {
    assets,
    loading,
    assets_map,
  };
}
