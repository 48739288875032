import { DownloadIcon } from "lucide-react";
import { useParams } from "react-router-dom";
import { AssistantService } from "shared/services/AssistantService";
import { AssistantAttachment } from "types/assistant/AssistantTypes";

type Props = {
  attachment: AssistantAttachment;
};

export const DownloadableAttachment: React.FC<Props> = ({ attachment }) => {
  const params = useParams();
  const threadId = params.threadId;

  const download = async () => {
    const fileName = attachment.attachment_name;
    const attachmentId = attachment.attachment_id;
    const response = await AssistantService.downloadAttachment(
      threadId,
      attachmentId,
    );

    const link = document.createElement("a");
    link.href = URL.createObjectURL(response.data);
    link.download = fileName;
    link.click();
  };

  return (
    <>
      <div className="group relative w-fit">
        <div
          className="flex cursor-pointer items-center gap-2 rounded-lg bg-gray-200 p-2"
          onClick={download}
        >
          <DownloadIcon className="size-4" />
        </div>
      </div>
      <div
        id={attachment.attachment_id.toString()}
        className="image-attachment-scroll-margin"
      ></div>
    </>
  );
};
