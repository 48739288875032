import { AssistantAttachment } from "types/assistant/AssistantTypes";
import { ImageAttachment } from "./ImageAttachment";
import { TableAttachment } from "./TableAttachment";
import { ExpandIcon, PaperclipIcon } from "lucide-react";
import { SvgAttachment } from "./SvgAttachment";
import { TxtAttachment } from "./TxtAttachment";
import { DownloadableAttachment } from "./DownloadableAttachment";
import styles from "./attachmentResponse.module.css";
import "./styles.css";
import { useRef } from "react";
import { DownloadAttachmentIcon } from "assets/svg/DownloadAttachmentIcon";
import colorsStore from "shared/store/colorsStore";

type Props = {
  message: AssistantAttachment;
};
type SvgAttachmentRef = {
  expandSvg: () => void;
  downloadSvg: () => void;
};

export const AttachmentResponse: React.FC<Props> = ({ message }) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const dialogRef = useRef<SvgAttachmentRef>(null);
  let attachment = null;
  if (message.content_type.startsWith("image/svg")) {
    attachment = <SvgAttachment attachment={message} />;
  } else if (message.content_type.startsWith("image/")) {
    attachment = <ImageAttachment attachment={message} />;
  } else if (message.content_type === "text/csv") {
    attachment = <TableAttachment attachment={message} />;
  } else if (message.content_type === "text/plain") {
    attachment = <TxtAttachment attachment={message} />;
  } else {
    attachment = <DownloadableAttachment attachment={message} />;
  }
  // Extract file extension
  const fileExtension = message.attachment_name.split(".").pop();
  const attachmentNameWithoutExtension = message.attachment_name.replace(
    /\.[^/.]+$/,
    "",
  );
  return (
    <>
      {message.content_type !== "image/svg" && (
        <div className={styles.attachmentResponseContainer}>
          <div className={styles.attachmentIcon}>
            <PaperclipIcon className="size-4 text-gray-600" />
          </div>
          <div className={styles.attachmentDetails}>
            <div className={styles.fileName}>
              {attachmentNameWithoutExtension}
            </div>
            <div className={styles.fileInfo}>
              {fileExtension?.toUpperCase()}
            </div>
          </div>
          <div className={styles.attachmentActions}>{attachment}</div>
        </div>
      )}
      {message.content_type === "image/svg" && (
        <div className={styles.attachmentResponseContainerSVG}>
          <div className={styles.attachmentIcon}>
            <PaperclipIcon className="size-4 text-gray-600" />
          </div>
          <div className="w-full">
            <div className={styles.btContainer}>
              <div
                className="mb-4 font-bold lg:text-lg"
                style={{ color: colorsConfig.finecoBluePrimary }}
              >
                {attachmentNameWithoutExtension}
              </div>
              <div
                className={styles.downloadStyle}
                onClick={() => {
                  dialogRef.current?.downloadSvg();
                }}
              >
                <DownloadAttachmentIcon />
              </div>
              <div
                className={styles.expandStyle}
                onMouseEnter={(e) => {}}
                onMouseLeave={(e) => {}}
                onClick={() => {
                  dialogRef.current?.expandSvg();
                }}
              >
                <ExpandIcon
                  style={{
                    marginLeft: 5,
                    marginTop: 5,
                    width: 18,
                    height: 18,
                    color: colorsConfig.finecoBluePrimary,
                  }}
                />
              </div>
            </div>
            <SvgAttachment ref={dialogRef} attachment={message} />
          </div>
        </div>
      )}
    </>
  );
};

export const AttachmentNewIcocon: React.FC = () => {
  return (
    <div className="grid size-8 shrink-0 place-items-center rounded-full border border-gray-600">
      <PaperclipIcon className="size-4 text-gray-600" />
    </div>
  );
};

export const AttachmentIcon: React.FC = () => {
  return (
    <div className="grid size-8 shrink-0 place-items-center rounded-full border border-gray-600">
      <PaperclipIcon className="size-4 text-gray-600" />
    </div>
  );
};
