import { SideMenu } from "./SideMenu";
import { useEffect, useState } from "react";
import { auth } from "shared/utils/firebase";
import useAppStatus from "shared/store/appStatusStore";
import { AssistantPage } from "./AssistantPage";
import { BasePage } from "shared/components/common/BasePage";

export const AssistantPageContainer: React.FC = () => {
  const [userLogged, setUserLogged] = useState<boolean>(false);
  const [subtractW, setSubtractW] = useState(200);
  const [leftP, setLeftP] = useState(200);

  const appStatus = useAppStatus((state) => state.appStatus);
  // const setWindowW = useAppStatus((state) => state.setWindowW);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserLogged(!!user);
    });

    const handleResize = () => {
      // setWindowW(window.innerWidth);
      // if (appStatus === "idle") {
      //   setIsSmallScreen(window.innerWidth < 650);
      // } else {
      //   setIsSmallScreen(window.innerWidth < 1050);
      // }
      if (window.innerWidth < 1280) {
        setSubtractW(105);
        setLeftP(100);
      } else {
        setSubtractW(185);
        setLeftP(180);
      }
    };
    // setWindowW(window.innerWidth);

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [appStatus]);

  // useEffect(() => {
  //   if (appStatus === "idle") {
  //     setIsSmallScreen(window.innerWidth < 650);
  //   } else {
  //     setIsSmallScreen(window.innerWidth < 1050);
  //   }
  // }, [appStatus]);

  return (
    <BasePage hasFooter={false}>
      <div
        // style={{
        //   position: "relative",
        //   display: "flex",
        //   height: "100%",
        //   minWidth: "150px",
        //   width: "100%",
        //   overflowX: isSmallScreen ? "auto" : "hidden",
        //   alignItems: "stretch",
        //   justifyContent: "stretch",
        //   gap: 0,
        //   backgroundColor: colorsConfig.white,
        //   color: colorsConfig.finecoBlack,
        // }}
        style={{
          width: "calc(100% - " + subtractW + "px)",
          position: "absolute",
          height: "calc(100% - 110px)",
          left: leftP,
          display: "flex",
          marginTop: "20px",
        }}
      >
        {userLogged && (
          <div className="h-full flex-1 flex-grow">
            <SideMenu />
          </div>
        )}

        <div
          className="flex h-full flex-col justify-stretch"
          style={{
            width: "100%",
            minWidth: "0",
            flex: "1 1 auto",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              inset: "0",
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                minWidth: "fit-content",
                width: "100%",
                height: "100%",
              }}
            >
              {/* <Outlet /> */}
              <AssistantPage />
            </div>
          </div>
        </div>
      </div>
    </BasePage>
  );
};
