import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BasePage } from "shared/components/common/BasePage";
import usePortfoliosStore from "shared/store/portfoliosStore";
import useProAssistantStore from "shared/store/proAssistantStore";
import { SideBar } from "./SideBar";
import { Panel } from "shared/components/hoc/Panels";
import { AssistantThreadWrapper } from "modules/pro_ai_assistant/AssistantThreadWrapper";
import { RightBar } from "./RightBar";

export const AssistantWarrenPage: React.FC = () => {
  const params = useParams();
  const threadId = params.threadId;
  const [expanded, setExpanded] = useState(true);
  const { fetchPortfolios } = usePortfoliosStore();
  const {
    fetchThread,
    resetThread,
    fetchPortfolios: fetchThreadPortfolios,
  } = useProAssistantStore();

  useEffect(() => {
    fetchPortfolios();
  }, []);

  useEffect(() => {
    if (threadId) {
      resetThread(threadId);
      fetchThread(threadId);
      fetchThreadPortfolios(threadId);
    }
  }, [threadId]);

  const left = <SideBar expanded={expanded} setExpanded={setExpanded} />;
  const center = (
    <>
      <Panel className="relative h-full overflow-hidden">
        <AssistantThreadWrapper />
      </Panel>
    </>
  );
  const right = <RightBar />;

  return (
    <BasePage>
      <div className="relative flex items-stretch justify-between gap-4 pl-4">
        <div className={"flex flex-col"}>{left}</div>
        <div className="flex flex-[4] flex-col">{center}</div>

        {threadId ? (
          <div className="mr-4 flex flex-col">{right}</div>
        ) : undefined}
      </div>
    </BasePage>
  );
};
