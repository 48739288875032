import { usePortfolios } from "shared/hooks/usePortfolios";
import { PortfolioCard } from "modules/portfolio_card/PortfolioCard";
import { PortfolioCardSkel } from "modules/portfolio_card/PortfolioCardSkel";
import { useTranslation } from "react-i18next";
import { BasePage } from "shared/components/common/BasePage";
import { CreatePortfolioButton } from "shared/components/controls/buttons/CreatePortfolioButton";
import { Labels } from "shared/components/hoc/Labels";
import { Loading } from "shared/types/enums";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import { useState, useEffect, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab } from "@headlessui/react";

export const PortfoliosPage = () => {
  const { loading, portfolios } = usePortfolios();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("tab")) {
      setSelectedIndex(parseInt(searchParams.get("tab") || "0"));
    }
  }, [searchParams]);

  const tabChanged = (index: number) => {
    setSearchParams({ tab: index.toString() });
    setSelectedIndex(index);
  };

  const customPortfolios = portfolios.filter(
    (item) => item.source === "custom",
  );

  const streetbeatPortfolios = portfolios.filter(
    (item) => item.source === "streetbeat",
  );

  return (
    <BasePage>
      <div className="flex items-center justify-between">
        <div>
          <Labels.H1>{t("portfolios.title")}</Labels.H1>
        </div>
        <CreatePortfolioButton />
      </div>

      <div className="mb-4 mt-8">
        <Tab.Group
          defaultIndex={0}
          selectedIndex={selectedIndex}
          onChange={tabChanged}
        >
          <Tab.List className="mb-8 box-border border-b-[3px] border-sb-gray-200 font-medium">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={(selected ? "active" : "") + " tab !px-10"}>
                  {t("portfolios.streetbeatOrigin")}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={(selected ? "active" : "") + " tab !px-10"}>
                  {t("portfolios.customOrigin")}
                </button>
              )}
            </Tab>
          </Tab.List>
          {loading === Loading.InProgress && !portfolios.length ? (
            <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
              <PortfolioCardSkel />
              <PortfolioCardSkel />
              <PortfolioCardSkel />
            </div>
          ) : null}
          <Tab.Panels>
            <Tab.Panel>
              {streetbeatPortfolios.length ? (
                <PortfoliosCards portfolios={streetbeatPortfolios} />
              ) : null}
            </Tab.Panel>
            <Tab.Panel>
              {customPortfolios.length ? (
                <PortfoliosCards portfolios={customPortfolios} />
              ) : null}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      <div className="mt-8 space-y-12"></div>
    </BasePage>
  );
};

type PortfoliosProps = {
  portfolios: Portfolio[];
};

const PortfoliosCards: React.FC<PortfoliosProps> = ({ portfolios }) => {
  return (
    <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
      {portfolios.map((item, idx) => {
        return <PortfolioCard key={"portfolio_" + idx} portfolio={item} />;
      })}
    </div>
  );
};
