import React, { useState, useEffect, useRef, useMemo } from "react";
import { ScrollArea } from "shared/components/ui/scroll-area";
import { useLocation, useParams } from "react-router-dom";
import activityPanelStore from "shared/store/activityPanelStore";
import ConstraintsConfig from "config/constraints-config.json";
import { ConstraintsChevronIcon } from "assets/svg/ConstraintsChevronIcon";
import {
  RiskContent,
  value_at_risk_limits,
} from "./components/content/RiskContent";
import { DividendContent } from "./components/content/DividendContent";
import { CostContent } from "./components/content/CostContent";
import { TrafficLightContent } from "./components/content/TrafficLightContent";
import { AssetContent } from "./components/content/AssetContent";
import { IndicatorContent } from "./components/content/IndicatorContent";
import { HeaderPanel } from "../HeaderPanel";
import appStatusStore from "shared/store/appStatusStore";
import { useToast } from "shared/components/ui/use-toast";
import { useTranslation } from "react-i18next";
import { MacroContent } from "./components/content/MacroContent";
import { riskProfileMapItaToEng } from "utils/misc";
import { MacroAssetAllocationContent } from "./components/content/MacroAssetAllocationContent";
import { CurrencyExposureContent } from "./components/content/CurrencyExposureContent";
import { TopQualityContent } from "./components/content/TopQualityContent";
import colorsStore from "shared/store/colorsStore";

type Props = {
  expanded: boolean;
  setExpanded: (e: boolean) => void;
  opacityDuration?: number;
  heightOpenDuration?: number;
};

export const ConstraintsPanel: React.FC<Props> = ({
  expanded,
  setExpanded,
  opacityDuration = 300,
  heightOpenDuration = 600,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const constraintsState = activityPanelStore(
    (state) => state.constraintsState,
  );
  const sendConstraintsStore = activityPanelStore(
    (state) => state.sendConstraintsStore,
  );
  const setUserSentFirstMessage = activityPanelStore(
    (state) => state.setUserSentFirstMessage,
  );
  const financialPlanStore = activityPanelStore(
    (state) => state.financialPlanStore,
  );

  const socketServiceRef = activityPanelStore(
    (state) => state.socketServiceRef,
  );
  const agentStatus = appStatusStore((state) => state.agentStatus);

  const setAppStatus = appStatusStore((state) => state.setAppStatus);

  const [openedConstraints, setOpenedConstraints] = useState<string[]>([]);
  const [collectedData, setCollectedData] = useState<Record<string, any>>({});
  const [hasChangedState, setHasChangedState] = useState<
    Record<string, boolean>
  >({});
  const [enableSubmitButton, setEnableSubmitButton] = useState<boolean>(false);

  const loc = useLocation();
  const { toast } = useToast();
  const { t } = useTranslation();
  const params = useParams();
  const threadId = params.threadId;
  const contentRefs = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    setOpenedConstraints([]);
  }, [loc.pathname]);

  useEffect(() => {
    for (let key in hasChangedState) {
      if (hasChangedState[key]) {
        setEnableSubmitButton(true);
        return;
      }
    }
    setEnableSubmitButton(false);
  }, [hasChangedState]);

  useEffect(() => {
    if (expanded) {
      const changes = activityPanelStore.getState().getConstraintChanges();
      if (changes?.changedFields.length > 0) {
        const newConstraints = openedConstraints.slice();
        changes.changedFields.forEach((field) => {
          if (!newConstraints.includes(field)) {
            newConstraints.push(field);
          }
        });
        setOpenedConstraints(newConstraints);
        activityPanelStore
          .getState()
          .setOpenedConstraints(threadId, newConstraints);
      }
    }
  }, [constraintsState, expanded]);

  const handleDataChange = (type: string, data: Record<string, any>) => {
    setCollectedData((prev) => ({
      ...prev,
      [type]: data,
    }));
  };

  const handleHasChanged = (type: string, hasChanged: boolean) => {
    setHasChangedState((prev) => ({
      ...prev,
      [type]: hasChanged,
    }));
  };

  const handleSubmit = () => {
    const allDataToBeSubmitted = {};
    for (let i = 0; i < ConstraintsConfig.constraints.length; i++) {
      const constraintConfigObject = ConstraintsConfig.constraints[i];
      const type = constraintConfigObject.type;
      if (collectedData[type]) {
        allDataToBeSubmitted[type] = collectedData[type];
      } else if (constraintsState?.data?.[type]) {
        allDataToBeSubmitted[type] = constraintsState.data[type];
      } else {
        const defaultValues: any = {};
        Object.keys(constraintConfigObject.data).forEach((key) => {
          defaultValues[key] = constraintConfigObject.data[key]?.value ?? null;
        });

        if (type === "traffic_lights") {
          defaultValues.all_lights_green =
            defaultValues.all_lights_green ?? false;
        }

        allDataToBeSubmitted[type] = defaultValues;
      }
    }
    if (allDataToBeSubmitted["risk"].risk_profile) {
      const translationNeeded = riskProfileMapItaToEng(
        allDataToBeSubmitted["risk"].risk_profile,
      );
      if (translationNeeded) {
        allDataToBeSubmitted["risk"].risk_profile = translationNeeded;
      }
    }
    //** fix missing target_var if accordion haven't been opened */
    if (!allDataToBeSubmitted["risk"].target_var) {
      const valueOutputTargetVar =
        value_at_risk_limits[allDataToBeSubmitted["risk"].financial_plan]
          .central;
      allDataToBeSubmitted["risk"].target_var = valueOutputTargetVar;
    }
    for (let mc in allDataToBeSubmitted["macro"]) {
      const elMacro = allDataToBeSubmitted["macro"][mc];
      if (typeof elMacro === "object") {
        allDataToBeSubmitted["macro"][mc] =
          allDataToBeSubmitted["macro"][mc][financialPlanStore];
      }
    }

    // Now handle moving the instrument_type values
    allDataToBeSubmitted["instrument_type"] = {
      etf_max: allDataToBeSubmitted["asset"]["etf_max"],
      etf_min: allDataToBeSubmitted["asset"]["etf_min"],
      mutual_funds_max: allDataToBeSubmitted["asset"]["mutual_funds_max"],
      mutual_funds_min: allDataToBeSubmitted["asset"]["mutual_funds_min"],
    };

    // And handle moving the core_satellite values
    allDataToBeSubmitted["core_satellite"] = {
      core_max: allDataToBeSubmitted["asset"]["core_max"],
      core_min: allDataToBeSubmitted["asset"]["core_min"],
      satellite_max: allDataToBeSubmitted["asset"]["satellite_max"],
      satellite_min: allDataToBeSubmitted["asset"]["satellite_min"],
    };

    // Remove the moved fields from asset
    delete allDataToBeSubmitted["asset"]["etf_max"];
    delete allDataToBeSubmitted["asset"]["etf_min"];
    delete allDataToBeSubmitted["asset"]["mutual_funds_max"];
    delete allDataToBeSubmitted["asset"]["mutual_funds_min"];
    delete allDataToBeSubmitted["asset"]["core_max"];
    delete allDataToBeSubmitted["asset"]["core_min"];
    delete allDataToBeSubmitted["asset"]["satellite_max"];
    delete allDataToBeSubmitted["asset"]["satellite_min"];

    setUserSentFirstMessage(true);
    sendConstraintsStore({
      messageId: "msgId" + Math.random(),
      data: allDataToBeSubmitted,
    });
    socketServiceRef.sendConstraints(allDataToBeSubmitted);
    toast({
      title: t("ws_actions.titleConstraints"),
      description: t("ws_actions.toastConstraints"),
    });
    setAppStatus("idle");
  };

  const contentComponentRefs = useRef<{
    risk?: { reset: () => void };
    indicator?: { reset: () => void };
    dividend?: { reset: () => void };
    cost?: { reset: () => void };
    traffic_lights?: { reset: () => void };
    asset?: { reset: () => void };
    macro?: { reset: () => void };
    // instrument_type?: { reset: () => void };
    top_quality?: { reset: () => void };
    // core_satellite?: { reset: () => void };
    macro_asset_allocation?: { reset: () => void };
    currency_exposure?: { reset: () => void };
  }>({});

  const handleReset = () => {
    Object.values(contentComponentRefs.current).forEach((component) => {
      component?.reset();
    });

    // setConstraintsStore(resetData);
    setCollectedData({});
    setHasChangedState({});
    setEnableSubmitButton(false);
  };

  const renderContent = (constrainObject: any) => {
    const { type, data } = constrainObject;
    const props = {
      configData: data,
      onDataChange: (data: any) => {
        return handleDataChange(type, data);
      },
      setHasChanged: (changed: boolean) => handleHasChanged(type, changed),
    };

    const components = {
      risk: () => (
        <RiskContent
          {...props}
          ref={(el) => (contentComponentRefs.current.risk = el)}
        />
      ),
      dividend: () => (
        <DividendContent
          {...props}
          ref={(el) => (contentComponentRefs.current.dividend = el)}
        />
      ),
      cost: () => (
        <CostContent
          {...props}
          ref={(el) => (contentComponentRefs.current.cost = el)}
        />
      ),
      traffic_lights: () => (
        <TrafficLightContent
          {...props}
          ref={(el) => (contentComponentRefs.current.traffic_lights = el)}
        />
      ),
      asset: () => (
        <AssetContent
          {...props}
          ref={(el) => (contentComponentRefs.current.asset = el)}
        />
      ),
      indicator: () => (
        <IndicatorContent
          {...props}
          ref={(el) => (contentComponentRefs.current.indicator = el)}
        />
      ),
      // instrument_type: () => (
      //   <InstrumentTypeContent
      //     {...props}
      //     ref={(el) => (contentComponentRefs.current.instrument_type = el)}
      //   />
      // ),
      top_quality: () => (
        <TopQualityContent
          {...props}
          ref={(el) => (contentComponentRefs.current.top_quality = el)}
        />
      ),
      // core_satellite: () => (
      //   <CoreSatelliteContent
      //     {...props}
      //     ref={(el) => (contentComponentRefs.current.core_satellite = el)}
      //   />
      // ),
      macro_asset_allocation: () => (
        <MacroAssetAllocationContent
          {...props}
          ref={(el) =>
            (contentComponentRefs.current.macro_asset_allocation = el)
          }
        />
      ),
      currency_exposure: () => (
        <CurrencyExposureContent
          {...props}
          ref={(el) => (contentComponentRefs.current.currency_exposure = el)}
        />
      ),
      macro: () => (
        <MacroContent
          {...props}
          ref={(el) => (contentComponentRefs.current.macro = el)}
        />
      ),
    };

    return components[type]?.() || null;
  };

  const toggleConstraint = (type: string, forceOpen = false) => {
    setOpenedConstraints((prev) => {
      // If forcing open and type isn't already included, add it
      if (forceOpen && !prev.includes(type)) {
        const newConstraints = [...prev, type];
        activityPanelStore
          .getState()
          .setOpenedConstraints(threadId, newConstraints);
        return newConstraints;
      }
      // If not forcing open, toggle as before
      else if (!forceOpen) {
        const newConstraints = prev.includes(type)
          ? prev.filter((item) => item !== type)
          : [...prev, type];
        activityPanelStore
          .getState()
          .setOpenedConstraints(threadId, newConstraints);
        return newConstraints;
      }
      // If forcing open but type is already included, return unchanged
      return prev;
    });
  };

  useEffect(() => {
    const storedConstraints = activityPanelStore
      .getState()
      .getOpenedConstraints(threadId);
    setOpenedConstraints(storedConstraints);
  }, [threadId]);

  useEffect(() => {
    ConstraintsConfig.constraints.forEach((constraintConfigObject) => {
      const el = contentRefs.current[constraintConfigObject.type];
      if (el) {
        el.style.maxHeight = openedConstraints.includes(
          constraintConfigObject.type,
        )
          ? `${el.scrollHeight}px`
          : "0px";
      }
    });
  }, [openedConstraints]);

  const children = useMemo(
    () => (
      <div className="h-full space-y-10">
        <div className="flex flex-col space-y-8">
          {ConstraintsConfig.constraints.map(
            (constraintConfigObject, index) => {
              return (
                <div key={index}>
                  <div className="flex flex-col space-y-2">
                    <div
                      className="flex w-full cursor-pointer flex-row items-center justify-between space-x-2"
                      onClick={() =>
                        toggleConstraint(constraintConfigObject.type)
                      }
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <ConstraintsChevronIcon
                          style={{
                            color: colorsConfig.finecoBluePrimary,
                            width: 16,
                            height: 16,
                          }}
                          isExpanded={openedConstraints.includes(
                            constraintConfigObject.type,
                          )}
                        />
                        <div
                          className="text-sm font-bold"
                          style={{
                            fontSize: 14,
                            color: colorsConfig.black,
                          }}
                        >
                          {constraintConfigObject.label}
                        </div>
                      </div>
                    </div>
                    <div
                      ref={(el) =>
                        (contentRefs.current[constraintConfigObject.type] = el)
                      }
                      className="overflow-hidden"
                      style={{
                        maxHeight: openedConstraints.includes(
                          constraintConfigObject.type,
                        )
                          ? `${
                              contentRefs.current[constraintConfigObject.type]
                                ?.scrollHeight || 0
                            }px`
                          : "0px",
                        transition: openedConstraints.includes(
                          constraintConfigObject.type,
                        )
                          ? `max-height ${heightOpenDuration}ms ease, opacity ${opacityDuration}ms ease`
                          : "none",
                        opacity: openedConstraints.includes(
                          constraintConfigObject.type,
                        )
                          ? 1
                          : 0,
                      }}
                    >
                      <div className="ml-6 mt-2">
                        {openedConstraints.includes(
                          constraintConfigObject.type,
                        ) && renderContent(constraintConfigObject)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            },
          )}
        </div>
      </div>
    ),
    [openedConstraints],
  );

  return (
    <div className="relative h-full">
      {agentStatus === "working" && (
        <div
          style={{
            position: "absolute",
            // pointerEvents: "none",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.01)",
            zIndex: 999,
          }}
          onClick={() => {
            toast({
              title: t("ws_actions.titleAgentBusy"),
              description: t("ws_actions.agentBusy"),
              variant: "destructive",
            });
            setAppStatus("idle");
          }}
        />
      )}

      <div
        style={{
          backgroundColor: colorsConfig.grayLight,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderPanel
          expanded={expanded}
          setExpanded={setExpanded}
          title={"menu.constraints"}
          enableSubmitButton={enableSubmitButton}
          onSubmit={handleSubmit}
          onReset={handleReset}
        />
        <ScrollArea
          className="flex-2 relative max-h-full min-h-[60px] rounded-xl"
          style={{
            backgroundColor: colorsConfig.white,
            margin: 20,
            padding: 10,
          }}
        >
          {expanded && (
            <div
              className="relative space-y-4 text-xs lg:text-base"
              style={{ top: 0 }}
            >
              {children}
            </div>
          )}
        </ScrollArea>
      </div>
    </div>
  );
};
