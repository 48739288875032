import React, { useCallback, useEffect, useRef, useState } from "react";
import { ChatInput } from "./components/ChatInput";
import { ChatTaskUpdate } from "./components/ChatTaskUpdate";
import { ChatGradient } from "./components/ChatGradient";
import { Button } from "shared/components/ui/button";
import EsgPreferencesForm from "../EsgPreferencesForm";
import {
  AssistantActionCreatePortfolioType,
  AssistantAttachment,
  AssistantEsgPreferencesType,
  AssistantMessage,
  AssistantTaskUpdate,
} from "types/assistant/AssistantTypes";
import { ChatAgentMessage } from "./components/messages/ChatAgentMessage";
import { ChatUserMessage } from "./components/messages/ChatUserMessage";
import { AttachmentResponse } from "./components/attachments/AttachmentResponse";
import { ScrollArea, ScrollAreaRef } from "shared/components/ui/scroll-area";
import useThreadsStore from "shared/store/threadsStore";
import { useParams } from "react-router-dom";
import { createAssistantMessage } from "utils/assistantUtils";
import { useTranslation } from "react-i18next";
import SocketService from "shared/services/ws/SocketService";
import { RefreshCcwIcon } from "lucide-react";
import { getCombinedMessages, scrollToBottom } from "utils/misc";
import ExcelPastTwoColumns from "./components/ExcelPastTwoColumns";
import { AI_PRO_ADVISOR } from "constants/AiProAdvisor";
import ExcelPastOneColumns from "./components/ExcelPastOneColumns";
type Props = {
  isConnectionOpen: boolean;
  onSendUserMessage: (text: string) => void;
  socketService: SocketService;
  retryButton: boolean;
  messageSent: boolean;
  thinking: boolean;
  taskUpdate: AssistantTaskUpdate;
  onRetry: () => void;
  chatStatus: string;
  widgetThreadId?: string;
  isWidget?: boolean;
};

export const ChatMain: React.FC<Props> = ({
  isConnectionOpen,
  onSendUserMessage,
  socketService,
  retryButton,
  messageSent,
  thinking,
  taskUpdate,
  onRetry,
  widgetThreadId,
  isWidget = false,
}) => {
  const scrollAreaRef = useRef<ScrollAreaRef>(null);
  const [userHasSentMessage, setUserHasSentMessage] = useState(false);
  const lastMessagesLength = useRef(0);

  const params = useParams();
  const threadId = widgetThreadId ? widgetThreadId : params.threadId;
  const { blockInput } = useThreadsStore();
  const messages = useThreadsStore((state) => state.messages[threadId]);
  const attachments = useThreadsStore((state) => state.attachments[threadId]);

  const appendMessages = useThreadsStore((state) => state.appendMessages);
  const esgPreferences = useThreadsStore(
    (state) => state.esgPreferences[threadId],
  );

  const actions = useThreadsStore((state) => state.actions[threadId]);

  const [showArrow, setShowArrow] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!messages) return;

    // If it's a new user message, scroll to bottom
    if (userHasSentMessage) {
      setTimeout(() => {
        scrollAreaRef.current?.scrollToBottom();
      }, 500);
      setTimeout(() => {
        scrollToBottom("lastAdvisorMessage");
      }, 500);

      setUserHasSentMessage(false);
      lastMessagesLength.current = messages.length;
      return;
    }

    if (messages.length > lastMessagesLength.current) {
      if (messages[0].role === "assistant") {
        if (!showArrow) {
          scrollAreaRef.current?.scrollDown(200);
        }
      }
    }
  }, [messages, userHasSentMessage]);

  const handleSendMessage = (text: string) => {
    setUserHasSentMessage(true);
    onSendUserMessage(text);
  };

  //** form utils */
  const handleFormChange = useCallback(() => {
    setTimeout(() => {
      scrollAreaRef.current?.scrollToBottom();
    }, 100);
  }, []);

  const handleFormSubmit = () => {
    const assistantMessage = createAssistantMessage(
      t("assistant.thanksSubmittingForm"),
      threadId,
    );
    appendMessages(threadId, [assistantMessage]);
  };
  const cancel = () => {
    socketService.cancel();
  };

  const handleScroll = (
    event: React.UIEvent<HTMLDivElement>,
    isAtBottom: boolean,
  ) => {
    // Handle scroll event
    if (!isAtBottom) {
      // Show arrow above ChatInput if not at the bottom
      setShowArrow(true);
    } else {
      setShowArrow(false);
    }
  };

  return (
    <>
      <ChatGradient
        direction="to bottom"
        classNameInner="-mb-10 h-12"
        classNameOuter="pointer-events-none z-10 !-mb-2"
      />
      <ScrollArea
        ref={scrollAreaRef}
        onScroll={handleScroll}
        id="chat-main"
        className="relative max-h-full space-y-4"
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <div
          className="mr-2 mt-10 space-y-10 lg:mx-auto"
          // style={{ backgroundColor: "#ff0099" }}
        >
          {getCombinedMessages(
            actions,
            esgPreferences,
            messages,
            attachments,
          )?.map((message, idx) => {
            //** ATTACHMENT */
            switch (message.object) {
              case "attachment":
                //** remove all attachment if chat bot is widget and not full */
                if (!isWidget) {
                  return (
                    <AttachmentResponse
                      message={message as AssistantAttachment}
                      key={idx}
                    />
                  );
                }
                return null;

              //** MESSAGE ( TEXT OR ARTIFACTS) */
              case "message":
                const messageUserAgentCast = message as AssistantMessage;
                switch (messageUserAgentCast.role) {
                  //** USER MESSAGe*/
                  case "user":
                    return (
                      <ChatUserMessage
                        key={idx}
                        message={message as AssistantMessage}
                      />
                    );
                  //** AGENT MESSAGE (text / artifacts) */
                  case "assistant":
                    return (
                      <ChatAgentMessage
                        key={idx}
                        message={message as AssistantMessage}
                        isConnectionOpen={isConnectionOpen} // New prop
                        onSend={(text) => onSendUserMessage(text)} // New prop
                        disabled={blockInput[threadId]} // New prop
                        isWidget={isWidget}
                      />
                    );
                  default:
                    return null;
                }
              //** ESG PREFERENCES */
              case "esg_preferences_form":
                const messageCast = message as AssistantEsgPreferencesType;
                return (
                  <EsgPreferencesForm
                    key={idx}
                    questions={messageCast.questions}
                    actionId={messageCast.actionId}
                    actionThreadId={messageCast.actionThreadId}
                    onChange={handleFormChange}
                    onSubmitProp={handleFormSubmit}
                  />
                );
              //** PORTFOLIO. MANUALLY */
              case AI_PRO_ADVISOR.ANALYZE_PORTFOLIO_FROM_WEIGHTS:
                const messagePortfolioCast =
                  message as AssistantActionCreatePortfolioType;
                return (
                  <ExcelPastTwoColumns
                    key={idx}
                    messagePortfolioCast={messagePortfolioCast}
                  ></ExcelPastTwoColumns>
                );
              case AI_PRO_ADVISOR.SET_PRODUCTS:
                const messageIsinsCast =
                  message as AssistantActionCreatePortfolioType;
                return (
                  <ExcelPastOneColumns
                    key={idx}
                    messagePortfolioCast={messageIsinsCast}
                  ></ExcelPastOneColumns>
                );

              default:
                return null;
            }
          })}
          {retryButton && (
            <RetryButton
              onRetry={() => {
                onRetry();
              }}
            />
          )}
        </div>

        <div id="lastAdvisorMessage" className="pt-10" />
      </ScrollArea>
      <ChatGradient
        direction="to top"
        classNameInner="-mt-10 h-12"
        classNameOuter="pointer-events-none z-10"
      />
      {/* {blockInput && messageSent && thinking && (
        <ChatCancelButton onCancel={cancel} />
      )} */}
      <ChatTaskUpdate taskUpdate={taskUpdate} />
      <div
        style={{
          position: "relative",
          marginLeft: 20,
          marginRight: 20,
          zIndex: 20,
        }}
      >
        <ChatInput
          onCancel={cancel}
          taskUpdate={taskUpdate}
          onSend={handleSendMessage}
          disabled={blockInput[threadId]}
          showArrow={showArrow}
          arrowAction={scrollAreaRef.current?.scrollToBottom}
        />
      </div>
    </>
  );
};

const RetryButton: React.FC<{
  onRetry: () => void;
}> = ({ onRetry }) => {
  const { t } = useTranslation();

  const retry = () => {
    onRetry?.();
  };

  return (
    <div>
      <div className="my-4 text-center text-red-600">
        {t("assistant.requestFailed")}
      </div>
      <div className="flex justify-center">
        <Button variant="outline" onClick={retry}>
          <RefreshCcwIcon className="size-4 mr-2" />
          {t("global.retry")}
        </Button>
      </div>
    </div>
  );
};
