import { t } from "i18next";
import { ProposalsTable } from "pages/fa/proposals/ProposalsTable";
import { useEffect } from "react";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import useProposalsStore from "shared/store/proposalsStore";
import { Loading } from "shared/types/enums";

type Props = {
  clientId?: string;
};

export const ClientProposals: React.FC<Props> = ({ clientId }) => {
  const {
    proposals: allProposals,
    loading,
    fetchProposals,
  } = useProposalsStore();
  const proposals = allProposals.filter((proposal) => {
    return proposal.client_id.toString() === clientId?.toString();
  });

  useEffect(() => {
    fetchProposals();
  }, []);

  return (
    <Panel>
      <Labels.H3>{t("clientDetails.proposals")}</Labels.H3>
      <Layouts.Spaced className="mt-10">
        {clientId && (proposals?.length || loading === Loading.InProgress) ? (
          <ProposalsTable proposals={proposals} loading={loading} />
        ) : null}
        {loading === Loading.Finished && !proposals?.length ? (
          <Labels.G1>{t("clientProposals.noProposals")}</Labels.G1>
        ) : null}
      </Layouts.Spaced>
    </Panel>
  );
};
