import { FinecoPortfolioArtifactType } from "types/assistant/AssistantTypes";
import { create } from "./store";
import SocketService from "shared/services/ws/SocketService";
import useAppStatusStore from "./appStatusStore";

interface Constraint {
  data: {
    asset?: {
      asset_count_max: number;
      asset_count_min: number;
      asset_weight_max: number;
      asset_weight_min: number;
    };
    cost?: {
      discount_max: number;
      discount_min: number;
      total_cost_max: number;
      total_cost_min: number;
    };
    dividend?: {
      ytm_max: number;
      ytm_min: number;
      duration_max: number;
      duration_min: number;
      dividend_yield: number;
    };
    indicator?: {
      esg_rating_max: number;
      esg_rating_min: number;
      final_score_min: number;
      final_score_max: number;
      final_score_advice_min: number;
      final_score_advice_max: number;
      diversification_min: number;
      diversification_max: number;
    };
    instrument_type?: {
      mutual_funds_min: number;
      mutual_funds_max: number;
      etf_min: number;
      etf_max: number;
    };
    core_satellite?: {
      core_min: number;
      core_max: number;
      satellite_min: number;
      satellite_max: number;
    };
    top_quality?: {
      top_quality_percent_min: number;
      top_quality_percent_max: number;
    };
    currency_exposure?: {
      euro_min: number;
      euro_max: number;
      us_dollar_min: number;
      us_dollar_max: number;
      emerging_markets_min: number;
      emerging_markets_max: number;
      other_min: number;
      other_max: number;
    };
    macro_asset_allocation?: {
      equity_min: number;
      equity_max: number;
      high_risk_bonds_min: number;
      high_risk_bonds_max: number;
      low_medium_risk_bonds_min: number;
      low_medium_risk_bonds_max: number;
      money_market_min: number;
      money_market_max: number;
      alternative_commodity_min: number;
      alternative_commodity_max: number;
      other_min: number;
      other_max: number;
    };
    macro?: {
      north_america_equities_min: number;
      north_america_equities_max: number;
      europe_equities_min: number;
      europe_equities_max: number;
      pacific_equities_min: number;
      pacific_equities_max: number;
      emerging_equities_min: number;
      emerging_equities_max: number;
      global_high_yield_bonds_min: number;
      global_high_yield_bonds_max: number;
      euro_high_yield_bonds_min: number;
      euro_high_yield_bonds_max: number;
      emerging_market_hard_currency_bonds_min: number;
      emerging_market_hard_currency_bonds_max: number;
      emerging_market_local_currency_bonds_min: number;
      emerging_market_local_currency_bonds_max: number;
      international_bonds_min: number;
      international_bonds_max: number;
      euro_corporate_bonds_min: number;
      euro_corporate_bonds_max: number;
      euro_government_bonds_min: number;
      euro_government_bonds_max: number;
      money_market_and_short_term_min: number;
      money_market_and_short_term_max: number;
      alternative_min: number;
      alternative_max: number;
      other_max: number;
      other_min: number;
      commodity_max: number;
      commodity_min: number;
    };
    risk?: {
      financial_plan: string | number | null;
      // type: string;
      // target_var: number | null;
      risk_profile: string | null;
      // target_return: number | null;
    };
    traffic_lights?: {
      all_lights_green: boolean;
      none_lights_red: boolean;
    };
  };
  messageId: string;
}

interface ConstraintState {
  messageId: string;
  data: Constraint["data"];
}
interface ConstraintChanges {
  changedFields: string[];
  previousValues: Record<string, any>;
  currentValues: Record<string, any>;
  timestamp: number;
}

type ActivityPanelStoreState = {
  esgPreferencesStore: any;
  esgRedDot: boolean;
  constraintsState: any;
  savedSentConstraintsStore: ConstraintState | null;

  previousConstraintsState: ConstraintState | null;
  constraintChanges: {
    changedFields: string[];
    timestamp: number;
  } | null;

  constraintsRedDot: boolean;
  attachmentsRedDot: boolean;
  portfoliosRedDot: boolean;
  financialPlanStore: number;
  socketServiceRef: SocketService | null;

  finecoPortfolioArtifacts: Record<string, FinecoPortfolioArtifactType[]>;

  userSentFirstMessage: boolean;
  newAttachmentsAdded: boolean;
  newConstraintsAdded: boolean;
  newEsgAdded: boolean;

  openedConstraintsByThread: Record<string, string[]>;

  hasSentMessage: boolean;
};

type ActivityPanelStoreActions = {
  setEsgPreferencesStore: (esgPreferences: any) => void;
  setEsgRedDot: (redDot: boolean) => void;
  sendConstraintsStore: (constraints: any) => void;
  setConstraintsStore: (constraints: any, isFromBE?: boolean) => void;
  setPreviousConstraintsStore: (constraints: any) => void;
  setConstraintsRedDot: (redDot: boolean) => void;
  setAttachmentsRedDot: (redDot: boolean) => void;
  setPortfoliosRedDot: (redDot: boolean) => void;
  setFinancialPlanStore: (plan: number) => void;
  setSocketServiceRef: (socketService: SocketService) => void;

  setFinecoPortfolioArtifact: (
    threadId: string,
    finecoPortfolioArtifact: FinecoPortfolioArtifactType,
  ) => void;

  setUserSentFirstMessage: (userSentFirstMessage: boolean) => void;
  setNewAttachmentsAdded: (newAttachmentsRedDot: boolean) => void;
  setNewConstraintsAdded: (newConstraintsAdded: boolean) => void;
  setNewEsgAdded: (newConstraintsAdded: boolean) => void;

  setOpenedConstraints: (threadId: string, constraints: string[]) => void;
  getOpenedConstraints: (threadId: string) => string[];

  getConstraintChanges: () => ConstraintChanges | null;

  setHasSentMessage: (useHasSentMessage: boolean) => void;
  resetStore: () => void;
};

const detectConstraintChanges = (
  previousState: ConstraintState | null,
  newState: ConstraintState,
): string[] => {
  if (!previousState || !previousState.data) {
    return Object.keys(newState.data || {});
  }

  const changedFields: string[] = [];
  const categories = [
    "asset",
    "cost",
    "dividend",
    "indicator",
    "macro",
    "risk",
    "traffic_lights",
    "instrument_type",
    "top_quality",
    "core_satellite",
    "macro_asset_allocation",
    "currency_exposure",
  ];

  categories.forEach((category) => {
    const prevCategory = previousState.data[category];
    const newCategory = newState.data[category];

    if (!isEqual(prevCategory, newCategory)) {
      changedFields.push(category);
    }
  });

  return changedFields;
};
function isEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) return true;
  if (!obj1 || !obj2) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === "object" && typeof val2 === "object") {
      if (!isEqual(val1, val2)) return false;
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
}

const initialData: ActivityPanelStoreState = {
  esgPreferencesStore: null,
  esgRedDot: false,

  constraintsState: null,
  savedSentConstraintsStore: null,
  previousConstraintsState: null,
  constraintChanges: null,

  constraintsRedDot: false,
  attachmentsRedDot: false,
  portfoliosRedDot: false,
  socketServiceRef: null,
  financialPlanStore: 1,

  finecoPortfolioArtifacts: {},

  userSentFirstMessage: false,
  newAttachmentsAdded: false,
  newConstraintsAdded: false,
  newEsgAdded: false,

  openedConstraintsByThread: {},

  hasSentMessage: false,
};

const activityPanelStore = create<
  ActivityPanelStoreState & ActivityPanelStoreActions
>()((set, get) => ({
  ...initialData,
  setEsgPreferencesStore: (esgPreferences) => {
    if (esgPreferences.messageId !== get().esgPreferencesStore?.messageId) {
      if (activityPanelStore.getState().userSentFirstMessage) {
        setTimeout(() => {
          activityPanelStore.getState().setNewEsgAdded(true);
        }, 1000);
      }

      set({ esgPreferencesStore: esgPreferences, esgRedDot: true });
    }
  },
  setEsgRedDot: (redDot) => {
    set({ esgRedDot: redDot });
  },

  sendConstraintsStore: (constraints: ConstraintState) => {
    set({
      savedSentConstraintsStore: constraints,
    });
  },
  setConstraintsStore: (
    constraints: ConstraintState,
    isFromBE: boolean = false,
  ) => {
    if (!constraints || !constraints.messageId || !constraints.data) {
      console.warn("Invalid constraints object received");
      return;
    }

    const currentState = get().constraintsState;

    if (constraints.messageId !== currentState?.messageId) {
      const changedFields = detectConstraintChanges(currentState, constraints);

      if (changedFields.length > 0) {
        const constraintChanges = {
          changedFields,
          timestamp: Date.now(),
        };

        let isTheSame = false;
        const changedSendFields = detectConstraintChanges(
          get().savedSentConstraintsStore,
          constraints,
        );
        if (changedSendFields.length <= 0) {
          isTheSame = true;
        } else {
          if (activityPanelStore.getState().userSentFirstMessage) {
            setTimeout(() => {
              activityPanelStore.getState().setNewConstraintsAdded(true);
            }, 1000);
          }
        }
        const mergedAsset = {
          ...constraints.data.asset,
          // Merging instrument_type fields
          etf_max: constraints.data.instrument_type.etf_max,
          etf_min: constraints.data.instrument_type.etf_min,
          mutual_funds_max: constraints.data.instrument_type.mutual_funds_max,
          mutual_funds_min: constraints.data.instrument_type.mutual_funds_min,
          // Merging core_satellite fields
          core_max: constraints.data.core_satellite.core_max,
          core_min: constraints.data.core_satellite.core_min,
          satellite_max: constraints.data.core_satellite.satellite_max,
          satellite_min: constraints.data.core_satellite.satellite_min,
        };

        set({
          constraintsState: {
            ...constraints,
            data: {
              ...constraints.data,
              asset: mergedAsset,
            },
          },
          previousConstraintsState: currentState,
          constraintChanges,
          constraintsRedDot: isTheSame ? false : true,
        });
      }
    }
  },
  setPreviousConstraintsStore: (constraints: ConstraintState) => {
    set({
      constraintsState: constraints,
      previousConstraintsState: constraints,
    });
  },

  getConstraintChanges: () => {
    const state = get();

    if (
      !state.constraintChanges ||
      !state.previousConstraintsState ||
      !state.constraintsState
    ) {
      return null;
    }

    const changes: ConstraintChanges = {
      changedFields: state.constraintChanges.changedFields,
      previousValues: {},
      currentValues: {},
      timestamp: state.constraintChanges.timestamp,
    };

    state.constraintChanges.changedFields.forEach((field) => {
      // Now accessing the nested data structure correctly
      changes.previousValues[field] =
        state.previousConstraintsState?.data[field];
      changes.currentValues[field] = state.constraintsState?.data[field];
    });

    return changes;
  },

  setFinancialPlanStore: (plan) => {
    set({ financialPlanStore: plan });
  },
  setConstraintsRedDot: (redDot) => {
    set({ constraintsRedDot: redDot });
  },
  setAttachmentsRedDot: (redDot) => {
    set({ attachmentsRedDot: redDot });
  },
  setPortfoliosRedDot: (redDot) => {
    set({ portfoliosRedDot: redDot });
  },
  setSocketServiceRef: (socketService) => {
    set({ socketServiceRef: socketService });
  },
  setFinecoPortfolioArtifact: (threadId, finecoPortfolioArtifact) => {
    const existingArtifacts = get().finecoPortfolioArtifacts[threadId] || [];
    const isAlreadyAdded = existingArtifacts.some(
      (artifact) =>
        artifact.portfolio_id === finecoPortfolioArtifact.portfolio_id,
    );
    if (isAlreadyAdded) return;

    const appStatus = useAppStatusStore.getState().appStatus;
    if (appStatus !== "portfolios") {
      activityPanelStore.getState().setPortfoliosRedDot(true);
    }
    set({
      finecoPortfolioArtifacts: {
        ...get().finecoPortfolioArtifacts,
        [threadId]: [...existingArtifacts, finecoPortfolioArtifact],
      },
    });
  },

  setUserSentFirstMessage: (userSentFirstMessage) => {
    set({ userSentFirstMessage: userSentFirstMessage });
  },
  setNewAttachmentsAdded: (newAttachmentsAdded) => {
    set({ newAttachmentsAdded: newAttachmentsAdded });
  },
  setNewConstraintsAdded: (newConstraintsAdded) => {
    set({ newConstraintsAdded: newConstraintsAdded });
  },
  setNewEsgAdded: (newEsgAdded) => {
    set({ newEsgAdded: newEsgAdded });
  },

  setOpenedConstraints: (threadId, constraints) => {
    set((state) => ({
      openedConstraintsByThread: {
        ...state.openedConstraintsByThread,
        [threadId]: constraints,
      },
    }));
  },
  getOpenedConstraints: (threadId) => {
    return get().openedConstraintsByThread[threadId] || [];
  },

  setHasSentMessage: (hasSentMessage) => {
    set({ hasSentMessage: hasSentMessage });
  },

  resetStore: () => {
    set(initialData);
  },
}));

export default activityPanelStore;
