import { useEffect, useRef, useState } from "react";
import FinecoPortfolioTitle from "../FinecoPortfolioTitle";
import useAppStatus from "shared/store/appStatusStore";
import { ConstraintsChevronIcon } from "assets/svg/ConstraintsChevronIcon";
import colorsStore from "shared/store/colorsStore";

type SectionProps = {
  section: string;
  label: string;
  children: React.ReactNode;
  toggleSection: any;
  openSections: any;
  reportData: any;
  showFoldingTitle: boolean;
};

export const Section: React.FC<SectionProps> = ({
  children,
  section,
  toggleSection,
  openSections,
  reportData,
  label,
  showFoldingTitle,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const appStatus = useAppStatus((state) => state.appStatus);

  useEffect(() => {
    const updateContentHeight = () => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.scrollHeight);
      }
    };

    updateContentHeight();
    setTimeout(updateContentHeight, 500);

    window.addEventListener("resize", updateContentHeight);

    // Cleanup
    return () => window.removeEventListener("resize", updateContentHeight);
  }, [reportData, appStatus]);
  return (
    <>
      {showFoldingTitle && (
        <div
          onClick={() => toggleSection(section)}
          className="flex cursor-pointer items-center"
        >
          {/* <span
            className={`transform transition-transform duration-300 ${openSections[section] ? "rotate-90" : "rotate-0"}`}
            style={{ marginRight: 10 }}
          >
            ▶
          </span> */}
          <ConstraintsChevronIcon
            style={{
              color: colorsConfig.finecoBluePrimary,
              width: 16,
              height: 16,
              marginTop: -5,
            }}
            isExpanded={openSections[section]}
          />
          <FinecoPortfolioTitle title={label} />
        </div>
      )}

      <div
        ref={contentRef}
        className="transition-max-height overflow-hidden duration-500 ease-in-out"
        style={{
          maxHeight: openSections[section] ? `${contentHeight}px` : "0",
        }}
      >
        {/* **********CONTENT ********** */}
        {children}
        {/* **********END CONTENT ********** */}
      </div>
    </>
  );
};
