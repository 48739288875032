export const ApiRoutes = {
  OPENAPI: "/openapi.json",
  EMAIL_VERIFICATION: "/api/v1/accounts/send-verification-email",
  JOIN: "/pro/api/v1/users/join",
  ME: "/pro/api/v1/users/me",
  WS_THREADS: "/pro/ws/v1/threads",
  THREADS: "/pro/api/v1/threads",
  THREAD_DETAILS: (threadId: string) => `/pro/api/v1/threads/${threadId}`,

  // TODO check if pro
  BASE_THREAD_DETAILS: (threadId: string) =>
    `/api/v1/assistant/threads/${threadId}`,
  THREAD_PORTFOLIOS: (id: string) =>
    `/api/v1/assistant/threads/${id}/portfolios`,
  THREAD_ACTIVATE_PORTFOLIO: (threadId: string, portfolioId: string) =>
    `/api/v1/assistant/threads/${threadId}/portfolios/${portfolioId}/activate`,

  THREAD_ACTION: (threadId: string, actionId: string) =>
    `/api/v1/assistant/threads/${threadId}/actions/${actionId}`,

  STRATEGIES_V6: "/api/v6/strategies",
  STRATEGY_V6: (id: string) => `/api/v6/strategies/${id}`,
  STRATEGY_COMPOSITIONS: (id: string) => `api/v6/strategies/compositions/${id}`,
  STRATEGY_COMPOSITIONS_ID: (id: string, compositionId: string) =>
    `/api/v6/strategies/compositions/${id}/${compositionId}`,
  STRATEGY_ACTIVITIES: (id: string) => `/api/v6/strategies/${id}/activities`,
  // TODO end

  ATTACHMENT_DOWNLOAD: (threadId: string, attachmentId: number) =>
    `/pro/api/v1/threads/${threadId}/attachments/${attachmentId}/download`,
  ATTACHMENT_URL: (threadId: string, attachmentId: number) =>
    `/pro/api/v1/threads/${threadId}/attachments/${attachmentId}/url`,
  DOCUMENTS_FETCH: "/pro/api/v1/search/data-store/documents",
  DOCUMENTS_DOWNLOAD: "/pro/api/v1/search/data-store/documents/download",
  DOCUMENTS_ACTIVITIES: "/pro/api/v1/search/data-store/documents/activities",
  DOCUMENTS_UPLOAD: "/pro/api/v1/search/data-store/documents/upload",
  DOCUMENTS_SUMMARIZE: "/pro/api/v1/search/summarize",
  SECRET_KEYS: "/pro/api/v1/secret-keys",
  VECTOR_STORES_CREATE: "/pro/api/v1/vector-stores/create",
  VECTOR_STORES_DELETE: (vectorStoreId: string) =>
    `/pro/api/v1/vector-stores/${vectorStoreId}`,
  VECTOR_STORES_LIST: "/pro/api/v1/vector-stores/list",
  VECTOR_STORES_FILES: "/pro/api/v1/vector-stores/files",
  VECTOR_STORES_FILES_DOWNLOAD: (fileId: string) =>
    `/pro/api/v1/vector-stores/files/${fileId}/download`,
  VECTOR_STORES_FILE_UPLOAD: "/pro/api/v1/vector-stores/files/upload",
  VECTOR_STORES_FILE_LIST_GLOBAL: "/pro/api/v1/vector-stores/files/list",
  VECTOR_STORES_FILE_ATTACH: (vectorStoreId: string) =>
    `/pro/api/v1/vector-stores/${vectorStoreId}/files/attach`,
  VECTOR_STORES_FILE_LIST: (vectorStoreId: string) =>
    `/pro/api/v1/vector-stores/${vectorStoreId}/files/list`,
  VECTOR_STORES_FILE_DELETE: (vectorStoreId: string) =>
    `/pro/api/v1/vector-stores/${vectorStoreId}/files/delete`,
  PAYMENT_METHODS_V2: "/api/v2/payment_methods",
  SUBSCRIPTIONS: "/pro/api/v1/subscriptions",
  SUBSCRIPTION_STATUS: "/pro/api/v1/subscriptions/status",
  ACTIVE_SUBSCRIPTION: "/pro/api/v1/subscriptions/active",
  PAYMENTS_LIST: "/pro/api/v1/subscriptions/payments/list",
  SUBSCRIPTION_LIST: "/pro/api/v1/subscriptions/list",
  SUBSCRIPTION_CREDITS: "/pro/api/v1/subscriptions/credits",
  SUBSCRIPTION_USAGE: (year: number, month: number) =>
    `/pro/api/v1/subscriptions/usage/${year}/${month}`,
  DISABLE_CHECKOUT_SUBSCRIPTION: "/pro/api/v1/subscriptions/disable-auto-renew",
  ENABLE_CHECKOUT_SUBSCRIPTION: "/pro/api/v1/subscriptions/enable-auto-renew",
  ESTIMATE_FIRST_PAYMENT: "/pro/api/v1/subscriptions/estimate-first-payment",
  PAY_COMMITMENT: "/pro/api/v1/subscriptions/pay-now/commitment",
  PAY_EXTRA_PACKAGE: "/pro/api/v1/subscriptions/pay-now/extra-package",
  CHECK_PROMO_CODE: "/pro/api/v1/subscriptions/promo-code",

  SPEECH_TO_TEXT: `api/v1/audio/stt`,
  TEXT_TO_SPEECH: `api/v1/audio/tts`,

  CREATE_PDF: (theadId: string, portfolioId: string) =>
    `/pro/api/v1/fineco/threads/${theadId}/report/${portfolioId}`,

  REPORT_DATA: (threadId: string, portfolioId: string) =>
    `/pro/api/v1/fineco/threads/${threadId}/report/${portfolioId}/data`,

  CREATE_EXCEL: (threadId: string, portfolioId: string) =>
    `/pro/api/v1/fineco/threads/${threadId}/report/${portfolioId}/excel`,

  REPORT_PARAMETERS: "/pro/api/v1/fineco/report/parameters",
};
