import { useTranslation } from "react-i18next";
import { useAccount } from "shared/hooks/useAccount";
import useRealtimeTicker from "shared/hooks/useRealtimeTicker";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { TradingPosition } from "shared/models/trading/TradingPositionmodel";
import { convertToNumbers, convertQty } from "shared/utils/converts";
import { MathUtils } from "shared/utils/math";
import {
  checkIfOrderReducingPosition,
  getAmountType,
} from "shared/utils/orders";
import useCreateOrder from "./useCreateOrder";
import useClientsStore from "shared/store/clientsStore";

export interface CreateOrderReviewInfo {
  tradingAtPrice: number;
  tradingAtPercentage?: number;
  latestPrice: number;
}

type WarningsResult = [boolean, string | null];

export type CreateOrderValidator = (
  reviewInfo: CreateOrderReviewInfo,
) => WarningsResult;

interface UseCreateOrderValidationResult {
  minimumAmountValidation: CreateOrderValidator;
  buyingPowerValidation: CreateOrderValidator;
  reducingPositionValidation: CreateOrderValidator;
  remainingFractionValidation: CreateOrderValidator;

  limitPriceValidation: CreateOrderValidator;
  stopPriceValidation: CreateOrderValidator;
  trailingStopPercentValidation: CreateOrderValidator;

  takeProfitValidation: CreateOrderValidator;
  stopLossValidation: CreateOrderValidator;

  validateOrder: (
    reviewInfo: CreateOrderReviewInfo,
    validators: CreateOrderValidator[],
  ) => [boolean, string[]];
}
interface Props {
  asset: AssetWrapper;
  position?: TradingPosition;
  clientId?: string;
}
export const useCreateOrderValidation = ({
  asset,
  position,
  clientId,
}: Props): UseCreateOrderValidationResult => {
  const order = useCreateOrder();
  const { t } = useTranslation();

  const { account: myAccount } = useAccount();

  const client = useClientsStore((state) =>
    state.clients.find((c) => String(c.user_id) === String(clientId)),
  );
  const clientTradingAccount = client?.trading;
  const account = clientId ? clientTradingAccount : myAccount?.client.trading;

  const { latestPrice } = useRealtimeTicker(asset.symbol);

  const minimumAmountValidation = (): WarningsResult => {
    const amountType = getAmountType(order);
    const isAmountTypeShares = amountType === "qty";
    const isAmountTypeNotional = amountType === "cash";
    const isFractionalAllowed =
      asset.alpaca_details?.class === "crypto" || order.type === "market";
    const isCryptoAsset = asset.alpaca_details?.class === "crypto";
    const [qty, notional, positionQty] = convertToNumbers(
      order?.qty,
      order?.notional,
      position?.qty,
    );

    if (isCryptoAsset) {
      const min_order_size = parseFloat(
        asset.alpaca_details?.min_order_size || "0",
      );
      // @ts-ignore
      if (isAmountTypeShares && qty >= min_order_size) {
        return [true, null];
      }

      // @ts-ignore
      if (isAmountTypeNotional && notional / latestPrice >= min_order_size) {
        return [true, null];
      }

      return [
        false,
        t("trade.warnings.amount_minimum_qty", {
          amount: convertQty(asset.alpaca_details?.min_order_size),
        }),
      ];
    }

    if (isAmountTypeShares) {
      const computeMinimumAmount = () => {
        const min = isFractionalAllowed ? 0.00001 : 1;
        if (order.side === "buy") return min;
        // @ts-ignore
        if (positionQty > 0 && positionQty < 1) return 0.000001;
        return min;
      };
      const minimum = computeMinimumAmount();
      // @ts-ignore
      return qty >= minimum
        ? [true, null]
        : [
            false,
            t("trade.warnings.amount_minimum_qty", {
              amount: convertQty(minimum),
            }),
          ];
    }

    // @ts-ignore
    return notional >= 1
      ? [true, null]
      : [false, t("trade.warnings.amount_minimum_notional")];
  };

  const buyingPowerValidation = (
    reviewInfo: CreateOrderReviewInfo,
  ): WarningsResult => {
    if (!order) return [false, null];
    if (position && checkIfOrderReducingPosition(order?.side, position?.side))
      return [true, null];
    const [notional, qty, buying_power, non_marginable_buying_power] =
      convertToNumbers(order?.notional, order.qty, account?.buying_power);
    const amount =
      // @ts-ignore
      notional || MathUtils.roundCurrency(qty * reviewInfo.tradingAtPrice);
    const maxBuyingPower =
      asset.alpaca_details?.class === "crypto"
        ? non_marginable_buying_power
        : buying_power;
    // @ts-ignore
    if (amount > maxBuyingPower)
      return [false, t("trade.warnings.amount_to_high")];

    return [true, null];
  };

  const reducingPositionValidation = (): WarningsResult => {
    if (!order) return [false, null];
    if (!position) return [true, null];
    const isReducing = checkIfOrderReducingPosition(order.side, position.side);
    if (!isReducing) return [true, null];

    const [notional, qty] = convertToNumbers(order.notional, order.qty);
    const [positionQty, positionNotional] = convertToNumbers(
      position.qty,
      position.market_value,
    );

    const amountType = getAmountType(order);
    const hasEnoughShares =
      // @ts-ignore
      amountType === "qty" ? qty <= positionQty : notional <= positionNotional;

    return hasEnoughShares
      ? [true, null]
      : [
          false,
          t("trade.warnings.reducing_more_than_have", { side: order.side }),
        ];
  };

  const remainingFractionValidation = (): WarningsResult => {
    if (!order) return [false, null];
    if (!position) return [true, null];
    const isReducing = checkIfOrderReducingPosition(order.side, position.side);
    if (!isReducing) return [true, null];

    const amountType = getAmountType(order);
    if (amountType === "cash") return [true, null];

    const [qty, positionQty] = convertToNumbers(order.qty, position.qty);
    // @ts-ignore
    const remaining = convertQty(positionQty - qty);
    const hasRemainingFraction = remaining > 0 && remaining < 1;
    return hasRemainingFraction
      ? [true, t("trade.warnings.remaining_fraction", { remaining })]
      : [true, null];
  };

  // Limit price when the type is "Limit Order"
  const limitPriceValidation = (): WarningsResult => {
    if (!order) return [false, null];
    const [limit_price] = convertToNumbers(order.limit_price);

    if (!limit_price) return [false, t("trade.warnings.limit_price_missing")];

    return [true, null];
  };

  // Stop price when the type is "Stop Order"
  const stopPriceValidation = (
    reviewInfo: CreateOrderReviewInfo,
  ): WarningsResult => {
    if (!order) return [false, null];
    const { side } = order;
    const [stop_price] = convertToNumbers(order.stop_price);

    if (!stop_price) return [false, t("trade.warnings.stop_price_missing")];

    const { latestPrice: latestPriceValue } = reviewInfo;
    const isStopPriceValid =
      side === "buy"
        ? stop_price >= latestPriceValue
        : stop_price <= latestPriceValue;
    if (!isStopPriceValid) {
      const direction = side === "buy" ? t("general.less") : t("general.more");
      return [
        false,
        t("trade.warnings.stop_price_minimum", { direction: direction }),
      ];
    }

    return [true, null];
  };

  const trailingStopPercentValidation = (
    _: CreateOrderReviewInfo,
  ): WarningsResult => {
    if (!order) return [false, null];
    const [trail_percent] = convertToNumbers(order.trail_percent);
    if (!trail_percent || trail_percent < 0)
      return [false, t("trade.warnings.trail_percent_missing")];
    if (trail_percent > 25)
      return [false, t("trade.warnings.trail_percent_maximum")];
    return [true, null];
  };

  const takeProfitValidation = (
    reviewInfo: CreateOrderReviewInfo,
  ): WarningsResult => {
    if (!order) return [false, null];
    if (!order.take_profit) return [true, null];

    const [takeProfitLimitPrice] = convertToNumbers(
      order.take_profit.limit_price,
    );
    if (!takeProfitLimitPrice)
      return [false, t("trade.warnings.take_profit_missing")];

    const { side, type, limit_price, stop_price } = order;
    const [limitPrice, stopPrice] = convertToNumbers(limit_price, stop_price);
    const basePrice = limitPrice || stopPrice || reviewInfo.latestPrice;
    const isTakeProfitValid =
      side === "buy"
        ? takeProfitLimitPrice > basePrice
        : takeProfitLimitPrice < basePrice;

    if (!isTakeProfitValid) {
      const direction = side === "buy" ? t("general.less") : t("general.more");
      return [
        false,
        t("trade.warnings.take_profit_minimum", { direction, type }),
      ];
    }

    return [true, null];
  };

  const stopLossValidation = (
    reviewInfo: CreateOrderReviewInfo,
  ): WarningsResult => {
    if (!order) return [false, null];
    if (!order.stop_loss) return [true, null];

    const [stopLossStopPrice] = convertToNumbers(order.stop_loss.stop_price);
    if (!stopLossStopPrice)
      return [false, t("trade.warnings.stop_price_missing")];

    const { side, type, limit_price, stop_price } = order;
    const [limitPrice, stopPrice] = convertToNumbers(limit_price, stop_price);
    const basePrice = limitPrice || stopPrice || reviewInfo.latestPrice;
    const isStopLossValid =
      side === "buy"
        ? stopLossStopPrice < basePrice
        : stopLossStopPrice > basePrice;

    if (!isStopLossValid) {
      const direction = side === "buy" ? t("general.more") : t("general.less");
      return [
        false,
        t("trade.warnings.stop_loss_minimum", { direction, type }),
      ];
    }

    return [true, null];
  };

  const validateOrder = (
    reviewInfo: CreateOrderReviewInfo,
    validators: CreateOrderValidator[],
  ): [boolean, string[]] => {
    let warnings: string[] = [];
    let isOrderValid = true;

    for (let i = 0; i < validators.length; i++) {
      const validator = validators[i];
      // @ts-ignore
      const [valid, warning] = validator(reviewInfo);
      if (!valid) isOrderValid = false;
      if (warning) warnings.push(warning);
    }

    return [isOrderValid, warnings];
  };

  return {
    minimumAmountValidation,
    buyingPowerValidation,
    reducingPositionValidation,
    remainingFractionValidation,

    limitPriceValidation,
    stopPriceValidation,
    trailingStopPercentValidation,

    takeProfitValidation,
    stopLossValidation,

    validateOrder,
  };
};
