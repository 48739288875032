type Props = {
  className?: string;
};

export const AiIcon: React.FC<Props> = ({ className }) => {
  return (
    <>
      <svg
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9427 18.755L11.2163 21.7651L11.4012 21.6616C13.7956 20.3215 15.6698 18.5374 17.0209 16.3096C18.3708 14.0836 19.0478 11.7686 19.0478 9.36648C19.0478 8.03895 18.819 6.81157 18.3598 5.68591C17.9015 4.56262 17.2672 3.58597 16.4566 2.75741C15.6461 1.92886 14.6959 1.28502 13.6072 0.826152C12.5168 0.366547 11.3332 0.137482 10.0581 0.137482C7.46996 0.137482 5.25962 1.04668 3.43489 2.86243C1.61019 4.67814 0.696167 6.87531 0.696167 9.44623C0.696167 12.0169 1.60559 14.214 3.42136 16.0298C5.23712 17.8456 7.43422 18.755 10.0049 18.755H10.9427ZM12.8008 18.3712V16.885H10.0049C7.95019 16.885 6.19964 16.1596 4.7456 14.7055C3.29156 13.2515 2.56617 11.501 2.56617 9.44623C2.56617 7.39151 3.29156 5.64096 4.7456 4.18692C6.19916 2.73336 7.96722 2.00748 10.0581 2.00748C12.1151 2.00748 13.8112 2.69454 15.157 4.06636C16.5021 5.43752 17.1778 7.20021 17.1778 9.36648C17.1778 11.0089 16.7718 12.6495 15.9561 14.2896C15.1838 15.8425 14.1324 17.2029 12.8008 18.3712Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0616 9.77707C10.4814 10.3373 10.7256 11.0457 10.7256 11.8154C10.7256 12.4718 10.5245 13.0698 10.2172 13.6081C10.1378 13.7505 10.0238 13.8489 9.88595 13.8678C9.74712 13.8868 9.6131 13.8213 9.50281 13.6985L6.22431 10.3331C6.08214 10.1969 6.02376 10.0159 6.07799 9.85817C6.13399 9.69532 6.29662 9.59378 6.51938 9.59378L9.68336 9.59378C9.26355 9.03356 9.01936 8.32513 9.01936 7.55545C9.01936 6.89904 9.22043 6.30107 9.52777 5.76277C9.60719 5.62032 9.72116 5.52194 9.85899 5.50308C9.99783 5.48407 10.1318 5.54958 10.2421 5.67239L13.5206 9.03771C13.6628 9.17396 13.7212 9.35494 13.667 9.51268C13.611 9.67553 13.4483 9.77707 13.2256 9.77707H10.0616Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};
