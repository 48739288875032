import React from "react";
import styles from "./../finecoPortfolioArtifact.module.css";

type Props = {
  title: string;
};
const FinecoPortfolioTitle: React.FC<Props> = ({ title }) => {
  return <h1 className={styles.title}>{title}</h1>;
};

export default FinecoPortfolioTitle;
