import { AssistantAttachment } from "types/assistant/AssistantTypes";
import { PaperclipIcon } from "lucide-react";
import colorsStore from "shared/store/colorsStore";

type Props = {
  message: AssistantAttachment;
};

export const AttachmentItem: React.FC<Props> = ({ message }) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  let attachmentType = null;
  switch (true) {
    case message.content_type.startsWith("image/svg"):
      attachmentType = "SVG";
      break;
    case message.content_type.startsWith("image/"):
      attachmentType = "Image";
      break;
    case message.content_type === "text/csv":
      attachmentType = "CSV";
      break;
    case message.content_type === "text/plain":
      attachmentType = "Text";
      break;
  }

  return (
    <div
      className="flex cursor-pointer items-start gap-4 hover:opacity-50"
      onClick={() => {
        const el = document.getElementById(message.attachment_id.toString());
        // @ts-ignore
        el.scrollIntoView({ block: "start", behavior: "smooth" });
      }}
    >
      <AttachmentIcon />
      <div className="w-full">
        <div
          className="mb-4 font-bold lg:text-lg"
          style={{ color: colorsConfig.finecoBluePrimary }}
        >
          {message.attachment_name}
        </div>
        {attachmentType}
      </div>
    </div>
  );
};

export const AttachmentIcon: React.FC = () => {
  return (
    <div className="grid size-8 shrink-0 place-items-center rounded-full border border-gray-600">
      <PaperclipIcon className="size-4 text-gray-600" />
    </div>
  );
};
