import RestService from "shared/api/RestService";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import {
  ProAssistantThread,
  ProAssistantThreadHistoryItem,
  ProAssistantPortfolio,
} from "shared/models/pro_assistant/ProAssistantModel";
import { FaApiRoutes } from "shared/utils/routes";

export type FeedbackType = "positive" | "negative";

export type Feedback = {
  type: FeedbackType;
  explanation?: string;
  message_id: string;
};

export const ProAssistantService = {
  newThread: async (): Promise<ProAssistantThread> => {
    return RestService.post(FaApiRoutes.ProAssistant.Threads.Path);
  },

  fetchThreads: async (): Promise<ProAssistantThreadHistoryItem[]> => {
    return RestService.get(FaApiRoutes.ProAssistant.Threads.Path);
  },

  fetchThread: async (threadId: string): Promise<ProAssistantThread> => {
    return RestService.get(
      FaApiRoutes.ProAssistant.Threads.Details.Path(threadId),
    );
  },

  deleteThread: async (threadId: string) => {
    return RestService.delete(
      FaApiRoutes.ProAssistant.Threads.Details.Path(threadId),
    );
  },

  activatePortfolio: async (threadId: string, portfolioId: string) => {
    return RestService.put(
      FaApiRoutes.ProAssistant.Threads.Details.Portfolios.Details.Activate(
        threadId,
        portfolioId,
      ),
    );
  },

  downloadAttachment: async (threadId: string, attachmentId: number) => {
    const response = await RestService.download(
      FaApiRoutes.ProAssistant.Threads.Details.Attachments.Download(
        threadId,
        attachmentId,
      ),
    );
    return response;
  },

  getAttachmentUrl: async (
    threadId: string,
    attachmentId: number,
  ): Promise<string> => {
    const response = await RestService.get<string>(
      FaApiRoutes.ProAssistant.Threads.Details.Attachments.Url(
        threadId,
        attachmentId,
      ),
    );
    return response;
  },

  async savePortfolio(
    sessionId: string,
    portfolio: ProAssistantPortfolio,
  ): Promise<void> {
    await RestService.post(
      FaApiRoutes.ProAssistant.Threads.Details.Portfolios.Path(sessionId),
      portfolio,
    );
  },

  async fetchPortfolios(sessionId: string): Promise<Portfolio[]> {
    return await RestService.get(
      FaApiRoutes.ProAssistant.Threads.Details.Portfolios.Path(sessionId),
    );
  },

  async sendFeedback(sessionId: string, feedback: Feedback): Promise<void> {
    await RestService.post(
      FaApiRoutes.ProAssistant.Threads.Details.Feedbacks(sessionId),
      { ...feedback },
    );
  },
};
