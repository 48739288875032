import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AssistantActionCreatePortfolioType } from "types/assistant/AssistantTypes";
import { FinecoButton } from "../../FinecoButton";
import { AssistantService } from "shared/services/AssistantService";
import {
  Column,
  DataSheetGrid,
  keyColumn,
  textColumn,
} from "react-datasheet-grid";
import "react-datasheet-grid/dist/style.css";
import useThreadsStore from "shared/store/threadsStore";
import { useToast } from "shared/components/ui/use-toast";
import Decimal from "decimal.js";
import colorsStore from "shared/store/colorsStore";

type ExcelPastTwoColumnsProps = {
  messagePortfolioCast: AssistantActionCreatePortfolioType;
};

type Row = {
  isin: string | null;
  weight: string | null;
};

const ExcelPastTwoColumns: React.FC<ExcelPastTwoColumnsProps> = ({
  messagePortfolioCast,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const containerRef = useRef(null);

  const resetAction = useThreadsStore((state) => state.resetAction);
  const { t } = useTranslation();
  const { toast } = useToast();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [data, setData] = useState<Row[]>(() => {
    if (
      messagePortfolioCast?.action.input.products &&
      messagePortfolioCast?.action.input.products.length > 0
    ) {
      return messagePortfolioCast.action.input.products.map((product) => ({
        isin: product.isin,
        weight: `${(Number(product.weight) * 100).toFixed(2)}%`,
      }));
    }
    if (
      messagePortfolioCast?.action.input.isins &&
      messagePortfolioCast?.action.input.isins.length > 0
    ) {
      return messagePortfolioCast.action.input.isins.map((isin) => ({
        isin: isin,
        weight: "",
      }));
    }
    // If no data, return an empty row
    return [{ isin: "", weight: "" }];
  });

  const columns: Column<Row>[] = [
    {
      ...keyColumn<Row, "isin">("isin", textColumn),
      title: "ISIN",
    },
    {
      ...keyColumn<Row, "weight">("weight", textColumn),
      title: "PESO",
    },
  ];

  const handleRowChange = (newData: Row[]) => {
    const uppercaseData = newData.map((row) => ({
      ...row,
      isin: row.isin?.toUpperCase() ?? null,
    }));
    setData(uppercaseData);
  };

  const onSubmit = async () => {
    setSubmitting(true);
    const products = [];
    for (let i = 0; i < data.length; i++) {
      const w = data[i].weight;
      const p = parseFloat(w.replace(/[%,]/g, "."));
      const dec = new Decimal(p);
      const dec100 = dec.dividedBy(100);
      const isinObj = {
        isin: data[i].isin,
        weight: dec100.toFixed(),
      };
      products.push(isinObj);
    }
    const body = {
      products: products,
    };
    AssistantService.threadAction(
      messagePortfolioCast.action.thread_id,
      messagePortfolioCast.action.id,
      body,
    )
      .then(() => {
        resetAction(messagePortfolioCast.action.thread_id);
        setSubmitting(false);
        toast({
          title: t("global.success"),
          description: t("assistant.manualPortfolioSuccess"),
        });
      })
      .catch((error) => {
        setSubmitting(false);
        toast({
          title: t("global.error"),
          description:
            error.response?.data?.message ||
            error.message ||
            t("assistant.manualPortfolioError"), // Updated to show error message
          variant: "destructive",
        });
      });
  };

  let allIsins = true;
  const healthyTW = () => {
    let tw = new Decimal(0);
    for (let i = 0; i < data.length; i++) {
      if (data[i].weight) {
        const w = data[i].weight;
        const p = parseFloat(w.replace(/[%,]/g, "."));
        const dec = new Decimal(p);
        // const dec100 = dec.dividedBy(100);

        // const w = data[i].weight.replace(/[%,]/g, ".");
        // tw = tw + parseFloat(w);
        tw = tw.add(dec);
      }
      if (!data[i].isin) {
        allIsins = false;
      }
    }
    return +tw.toFixed();
  };
  const tw = healthyTW();

  let isSubmitDisabled = false;
  if (tw < 100 || !allIsins) {
    isSubmitDisabled = true;
  }

  return (
    <div ref={containerRef}>
      <h2
        style={{
          color: colorsConfig.finecoBlack,
          marginBottom: "20px",
        }}
      >
        {t("assistant.createManualPortfolio")}
      </h2>
      <DataSheetGrid
        value={data}
        onChange={handleRowChange}
        columns={columns}
      />
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <span>Totale: {tw}%</span>
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <FinecoButton
          click={onSubmit}
          copy={submitting ? t("actions.submitting") : t("actions.submit")}
          disabled={isSubmitDisabled}
        />
      </div>
    </div>
  );
};

export default ExcelPastTwoColumns;
