import {
  SortDirection,
  SortingArrows,
} from "shared/components/common/datatable/SortingArrows";
import "./styles.css";
import { useState } from "react";

type Props = {
  data: Record<string, any>[];
  columns?: string[];
  headerLabels?: Record<string, string>;
  cellFormatter?: (
    key: string,
    value: any,
    entry: Record<string, any>,
  ) => JSX.Element | string;
  onRowClicked?: (entry: any) => void;
  rowClass?: (entry: any) => string;
  loading?: boolean;
  sortableValue?: (key: string, entry: any) => any;
  defaultSortSettings?: SortSettings;
};

type SortSettings = {
  direction: SortDirection;
  by: string;
};

export const DataTable: React.FC<Props> = ({
  data,
  columns,
  headerLabels,
  cellFormatter,
  onRowClicked,
  rowClass,
  loading,
  sortableValue,
  defaultSortSettings,
}) => {
  const [sortSettings, setSortSettings] = useState<SortSettings | undefined>(
    defaultSortSettings,
  );

  const headers = columns
    ? columns
    : data.length > 0
    ? // @ts-ignore
      Object.keys(data[0])
    : null;

  const sortedData = sortSettings
    ? [...data].sort((a, b) => {
        const aValue =
          sortableValue?.(sortSettings.by, a) || a[sortSettings.by];
        const bValue =
          sortableValue?.(sortSettings.by, b) || b[sortSettings.by];
        if (aValue === bValue) {
          return 0;
        }
        if (aValue > bValue) {
          return sortSettings.direction === "asc" ? 1 : -1;
        }
        return sortSettings.direction === "asc" ? -1 : 1;
      })
    : data;

  return (
    <div className="data-table">
      {headers && (
        <table className="w-full">
          <thead>
            <tr>
              {headers.map((item, idx) => {
                return (
                  <th key={"table_header_" + idx}>
                    <div
                      className={
                        "sort " +
                        (idx === headers.length - 1 ? "float-right" : "")
                      }
                    >
                      {headerLabels && headerLabels[item]
                        ? headerLabels[item]
                        : item}
                      <SortingArrows
                        active={
                          sortSettings && sortSettings.by === item
                            ? sortSettings.direction
                            : undefined
                        }
                        onClick={(direction) =>
                          setSortSettings({ by: item, direction: direction })
                        }
                      />
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {!loading ? (
              sortedData.map((item, rowIdx) => {
                return (
                  <tr
                    key={"clients_row_" + rowIdx}
                    onClick={() => onRowClicked && onRowClicked(item)}
                    className={rowClass ? rowClass(item) : "row"}
                  >
                    {headers.map((field, cellIdx) => {
                      return (
                        <td key={`row_${rowIdx}_cell_${cellIdx}`}>
                          {cellFormatter
                            ? cellFormatter(field, item[field], item)
                            : item[field]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr className="row-skeleton">
                {headers.map((_, cellIdx) => {
                  return (
                    <td key={`field_${cellIdx}`}>
                      <div className="my-2 h-2.5 w-1/2 rounded-full bg-sb-gray-300" />
                    </td>
                  );
                })}
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};
