import { Popover } from "@headlessui/react";
import { t } from "i18next";
import { PropsWithChildren, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import CustomPopover from "shared/components/common/CustomPopover";
import { Layouts } from "shared/components/hoc/Layouts";
import ChevronRight from "shared/icons/ChevronRight";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { CreateTradingOrder } from "shared/models/trading/TradingOrderModel";
import { usdIntlConfig } from "shared/utils/currency";
import useCreateOrder from "../hooks/useCreateOrder";
import useCreateOrderStore from "shared/store/createOrderStore";

type Props = {
  asset: AssetWrapper;
  notionalEnabled?: boolean;
  fractional?: boolean;
};

export default function GenericSellOrder({
  asset,
  notionalEnabled,
  fractional,
  children,
}: PropsWithChildren<Props>) {
  const { update } = useCreateOrderStore();

  const order = useCreateOrder();

  const [qty, setQty] = useState(
    (fractional ? order.qty : Math.floor(parseFloat(order.qty || "0"))) || 0,
  );
  const [notional, setNotional] = useState<string | undefined>("0");
  const [qtyType, setQtyType] = useState<"Shares" | "USD">("Shares");

  useEffect(() => {
    const newOrder: CreateTradingOrder = {
      ...order,
      qty: qty && qtyType === "Shares" ? qty.toString() : undefined,
      notional:
        notionalEnabled && notional && qtyType === "USD" ? notional : undefined,
      symbol: asset.symbol,
    };
    update({ ...newOrder });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qty, asset, notional, qtyType, notionalEnabled]);

  return (
    <Layouts.Spaced>
      <div className="flex gap-8">
        <div>
          <div>{t("trade.quantity")}</div>
          <div className="flex items-center gap-2">
            <div className="rounded-xl border border-sb-gray-200 px-2 py-1 pr-2 font-medium">
              {qtyType === "USD" ? (
                <CurrencyInput
                  prefix="$"
                  value={notional}
                  intlConfig={usdIntlConfig}
                  decimalsLimit={2}
                  decimalScale={2}
                  onValueChange={(value) => {
                    setNotional(value);
                    setQty(0);
                  }}
                  className="py-1 text-right outline-none"
                />
              ) : (
                <CurrencyInput
                  prefix={undefined}
                  value={qty}
                  intlConfig={undefined}
                  decimalsLimit={6}
                  onValueChange={(value) => {
                    if (!fractional) {
                      setQty(parseFloat(value || "0").toFixed(0));
                    } else {
                      setQty(value || "0");
                    }
                    setNotional(undefined);
                  }}
                  className="py-1 text-right outline-none"
                />
              )}
            </div>
            {notionalEnabled ? (
              <CustomPopover
                align="middle"
                button={
                  <div className="flex cursor-pointer items-center gap-2 text-sb-gray-500">
                    {qtyType}
                    <div>
                      <ChevronRight className="text-sb-green-1001 h-4 w-4 rotate-90" />
                    </div>
                  </div>
                }
              >
                <div className="p-1.5">
                  <Popover.Button
                    className="cursor-pointer rounded-lg p-1.5 font-semibold duration-150 hover:bg-sb-gray-100"
                    onClick={() => setQtyType("Shares")}
                  >
                    {t("trade.amountType.qty")}
                  </Popover.Button>
                  <Popover.Button
                    className="cursor-pointer rounded-lg p-1.5 font-semibold duration-150 hover:bg-sb-gray-100"
                    onClick={() => setQtyType("USD")}
                  >
                    {t("trade.amountType.cash")}
                  </Popover.Button>
                </div>
              </CustomPopover>
            ) : (
              t("trade.amountType.qty")
            )}
          </div>
        </div>
        {children}
      </div>
    </Layouts.Spaced>
  );
}
