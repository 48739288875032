import {
  DefaultManagedCapitalSettings,
  ManagedCapital,
  ManagedCapitalSettings,
} from "modules/managed_capital/ManagedCapital";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LineAreaValueChart } from "shared/components/common/chart/LineAreaValueChart";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { ArrowUp } from "shared/icons/ArrowUp";
import { AumChart } from "shared/models/account/AumChart";
import { ClientsService } from "shared/services/clients/ClientsService";
import { CurrencyFormat, pctFormat } from "shared/utils/currency";
import { ISOTimestamp } from "shared/utils/types";

type Props = {
  clientId?: string;
};

export const TotalInvesting: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const [fetched, setFetched] = useState(false);

  const [settings, setSettings] = useState<ManagedCapitalSettings>(
    DefaultManagedCapitalSettings,
  );

  const [data, setData] = useState<AumChart>();

  const onPeriodChange = useCallback(
    (startDate: ISOTimestamp | null, endDate: ISOTimestamp | null) => {
      if (clientId) {
        ClientsService.getAdvisorClientAum(clientId, {
          start_date: startDate,
          end_date: endDate,
          frequency: settings.frequency,
        })
          .then(setData)
          .finally(() => setFetched(true));
      }
    },
    [clientId, settings.frequency],
  );

  const aum =
    data && data.points.length
      ? // @ts-ignore
        data.points[data.points.length - 1].end_equity
      : 0;

  const perfMeasure = settings.perfMeasure;
  const cumulativePerfMeasure =
    data && data.performance
      ? perfMeasure === "twr"
        ? data.performance.twr
        : data.performance.mwr
      : 0;
  const positive = cumulativePerfMeasure >= 0;

  const points = useMemo(() => {
    return data?.points.map((point) => ({
      ...point,
      twr: point.performance.twr,
      mwr: point.performance.mwr,
    }));
  }, [data]);

  return (
    <Panel className="w-full">
      <Labels.H3>{t("totalInvesting.title")}</Labels.H3>
      {fetched && !points ? (
        <Labels.G1>{t("totalInvesting.noData")}</Labels.G1>
      ) : (
        <>
          <div className="mt-4">
            <ManagedCapital onSettingsChange={setSettings} />
          </div>
          <div className="mt-8 flex items-center gap-4">
            <Labels.H1>
              {CurrencyFormat.format(aum).replace(/^(\D+)/, "$1 ")}
            </Labels.H1>
            <div
              className={
                "flex items-center gap-2 text-xl font-bold " +
                (positive ? "text-sb-green-1001" : "")
              }
            >
              <ArrowUp
                className={(positive ? "" : "rotate-180") + " h-6 w-6"}
              />
              <div>{pctFormat.format(cumulativePerfMeasure)}</div>
            </div>
          </div>
          <LineAreaValueChart
            domain={[
              (dataMin: number) =>
                dataMin ? dataMin - 0.03 * dataMin : dataMin,
              (dataMax: number) => dataMax + 0.05 * dataMax,
            ]}
            data={points}
            xKey="date"
            yKey="end_equity"
            yValueFormatter={(value) => CurrencyFormat.format(value)}
            yRightKey={perfMeasure}
            yRightFormatter={(value) => pctFormat.format(value)}
            yRightValueFormatter={(value) => pctFormat.format(value)}
            onPeriodChange={onPeriodChange}
            xFormatter={
              settings.frequency === "quarterly" ? (value) => value : undefined
            }
            zoomable
          />
        </>
      )}
    </Panel>
  );
};
