import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { useAccount } from "shared/hooks/useAccount";
import { ArrowUp } from "shared/icons/ArrowUp";
import { TradingPosition } from "shared/models/trading/TradingPositionmodel";
import { Loading } from "shared/types/enums";
import { CurrencyFormat, pctFormat } from "shared/utils/currency";
import { calculateWeightedAverage } from "shared/utils/trading";
import { Position } from "./Position";
import { PositionModal } from "./PositionModal";
import useClientDetailsStore from "shared/store/clientDetailsStore";

type Props = {
  clientId?: string;
};

export const ClientPositions: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();

  const clientDetails = useClientDetailsStore(
    (state) =>
      state.clientDetails[clientId || ""] || {
        positions: [], // or whatever default values needed
        positionsLoading: Loading.Idle,
      },
  );

  const { positions, positionsLoading: loading } = clientDetails;
  const { fetchPositions: fetchPositionsStore } = useClientDetailsStore();
  const [selectedPosition, setSelectedPosition] = useState<TradingPosition>();
  const { account } = useAccount();

  const totalReturn = positions
    // @ts-ignore
    .map((p) => p.unrealized_pl)
    // @ts-ignore
    .reduce((a, b) => a + b, 0);
  const totalReturnPct = calculateWeightedAverage(positions);
  const invested = positions
    // @ts-ignore
    .map((p) => p.market_value)
    // @ts-ignore
    .reduce((a, b) => a + b, 0);

  const setupInterval = useCallback(() => {
    let fetchPositionsTimeout: NodeJS.Timeout | null = null;

    const fetchPositions = async () => {
      if (clientId) {
        fetchPositionsStore(clientId);
      }
      fetchPositionsTimeout = setTimeout(fetchPositions, 5000);
    };

    fetchPositions();

    return () => {
      if (fetchPositionsTimeout) {
        clearTimeout(fetchPositionsTimeout);
      }
    };
  }, [account, clientId]);

  useEffect(() => {
    const clearInterval = setupInterval();
    return () => clearInterval();
  }, [account, clientId, setupInterval]);

  return (
    <Panel>
      {selectedPosition && (
        <PositionModal
          open={selectedPosition !== undefined}
          setOpen={(open) => {
            if (!open) {
              setSelectedPosition(undefined);
            }
          }}
          clientId={clientId}
          position={selectedPosition}
        />
      )}
      <Labels.H3 className="mb-4">{t("positions.title")}</Labels.H3>
      <div className="flex flex-wrap gap-12">
        <div>
          <div className="font-semibold">{t("positions.totalReturn")}</div>
          <div className="flex items-center gap-1 text-xl">
            <div
              className={
                "flex items-center gap-1 " +
                (totalReturn >= 0 ? "text-sb-green-700" : "text-sb-red-700")
              }
            >
              <ArrowUp
                className={
                  "aspect-square w-4 " + (totalReturn < 0 ? " rotate-180" : "")
                }
              />
              <div className="font-medium">
                {CurrencyFormat.format(totalReturn)} (
                {pctFormat.format(totalReturnPct)})
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="font-semibold">{t("clientPortfolio.invested")}</div>
          <div className="text-xl">{CurrencyFormat.format(invested)}</div>
        </div>
      </div>
      <div className="mt-8 flex flex-wrap items-center gap-4">
        {// @ts-ignore
        positions?.map((item, idx) => {
          return (
            <Position
              position={item}
              onClick={setSelectedPosition}
              key={"position_" + idx}
            />
          );
        })}
        {!account || (loading === Loading.Finished && !positions?.length) ? (
          <Labels.G1>{t("positions.noPositions")}</Labels.G1>
        ) : null}
      </div>
    </Panel>
  );
};
