import { CrmRoutes } from "config/routes/CrmRoutes";
import RestService from "shared/api/RestService";
import {
  CalendarEvent,
  ClientDetailType,
  ClientPriorityContactType,
  CrmAlertType,
  CrmAssistantTask,
  CrmCampaignSummary,
  CrmGoalType,
  CrmTaskCalendarType,
  CrmTasksWhenType,
  NoteFormType,
  TaskType,
} from "types/crm/ClientTypes";

export const CrmService = {
  fetchClientsList: async (): Promise<ClientDetailType[]> => {
    return await RestService.get(CrmRoutes.CLIENTS_LIST);
  },
  fetchClientDetail: async (clientId: string): Promise<ClientDetailType> => {
    return RestService.get(CrmRoutes.CLIENT_DETAIL(clientId));
  },
  fetchCrmTasks: async (
    when: CrmTasksWhenType,
    date?: string,
    clientIds?: (number | string)[],
  ): Promise<TaskType[]> => {
    let route = CrmRoutes.CRM_TASKS + `?when=${when}`;

    if (date) {
      route = route + `&date=${date}`;
    }
    if (clientIds && clientIds.length > 0) {
      for (let i = 0; i < clientIds.length; i++) {
        route = route + `&client_id=${clientIds[i]}`;
      }
    }
    return RestService.get(route);
  },
  fetchCrmTasksCalendar: async (
    year: string,
    month: string,
    clientIds?: (number | string)[],
  ): Promise<CrmTaskCalendarType[]> => {
    let route = CrmRoutes.CRM_TASKS_CALENDAR + `?year=${year}&month=${month}`;

    if (clientIds && clientIds.length > 0) {
      for (let i = 0; i < clientIds.length; i++) {
        route = route + `&client_id=${clientIds[i]}`;
      }
    }
    return RestService.get(route);
  },
  // addCrmTaskCalendarEvent: async () => {},
  // deleteCrmTaskCalendarEvent: async () => {
  //   const response = await api.delete(
  //     `/pro/api/v1/crm/calendar-events/${eventId}`,
  //   );
  //   return response.data;
  // },

  addCrmTaskCalendarEvent: async (
    eventData: CalendarEvent,
  ): Promise<CalendarEvent> => {
    return RestService.post(CrmRoutes.CRM_TASKS_CALENDAR_EVENT, eventData);
  },

  updateCrmTaskCalendarEvent: async (
    eventId: string,
    eventData: Partial<CalendarEvent>,
  ): Promise<CalendarEvent> => {
    return RestService.patch(
      CrmRoutes.CRM_TASKS_CALENDAR_EVENT_ID(eventId),
      eventData,
    );
  },

  deleteCrmTaskCalendarEvent: async (eventId: string): Promise<void> => {
    return RestService.delete(CrmRoutes.CRM_TASKS_CALENDAR_EVENT_ID(eventId));
  },

  // In CrmService.ts, add these functions:

  addCrmNote: async (noteData: NoteFormType): Promise<any> => {
    return RestService.post(CrmRoutes.CRM_NOTES, noteData);
  },

  // updateCrmNote: async (
  //   noteId: string,
  //   noteData: NoteFormType,
  // ): Promise<any> => {
  //   return RestService.patch(CrmRoutes.CRM_NOTE_ID(noteId), noteData);
  // },
  updateCrmNote: async (
    noteId: number,
    noteData: NoteFormType,
  ): Promise<any> => {
    const body = {
      content: noteData.content,
      source: noteData.source,
      participants: noteData.participants,
      update_at: noteData.updated_at,
    };
    return RestService.patch(CrmRoutes.CRM_NOTE_ID(noteId), body);
  },

  fetchCrmAlerts: async (
    clientIds?: (number | string)[],
  ): Promise<CrmAlertType[]> => {
    let route = CrmRoutes.CRM_ALERTS;
    if (clientIds && clientIds.length > 0) {
      for (let i = 0; i < clientIds.length; i++) {
        route = route + `&client_id=${clientIds[i]}`;
      }
    }
    return RestService.get(route);
  },
  fetchCrmGoals: async (): Promise<CrmGoalType[]> => {
    return RestService.get(CrmRoutes.CRM_GOALS);
  },
  fetchClientPriorityContacts: async (): Promise<
    ClientPriorityContactType[]
  > => {
    return RestService.get(CrmRoutes.CRM_PRIORITY_CONTACTS);
  },
  fetchCrmAssistantTasks: async (): Promise<CrmAssistantTask[]> => {
    return RestService.get(CrmRoutes.CRM_ASSISTANT_TASKS);
  },
  fetchCrmCampaignsSummary: async (): Promise<CrmCampaignSummary[]> => {
    return RestService.get(CrmRoutes.CRM_CAMPAIGNS_SUMMARY);
  },
};
