import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import { ProposalsService } from "shared/services/proposals/ProposalsService";
import { PortfolioAssistantResponse } from "./responses/PortfolioAssistantResponse";
import activityPanelStore from "shared/store/activityPanelStore";
import useThreadsStore from "shared/store/threadsStore";

type Props = {
  socket?: WebSocket;
  proposalId?: string;
  activePortfolioId?: string;
  loading?: boolean;
  onPortfolioClicked?: (portfolioId: string) => Promise<any> | undefined;
};

export const AiPortfolios: React.FC<Props> = ({
  proposalId,
  activePortfolioId,
  loading,
  onPortfolioClicked,
}) => {
  const { socketServiceRef } = activityPanelStore();
  const { t } = useTranslation();
  const [portfolios, setPortfolios] = useState<Portfolio[]>();

  const { refreshProposalId } = useThreadsStore();

  useEffect(() => {
    if (proposalId) {
      ProposalsService.getPortfolios(proposalId).then(setPortfolios);
    }
  }, [proposalId, loading, activePortfolioId, refreshProposalId]);

  useEffect(() => {
    if (activePortfolioId) {
      document.getElementById(activePortfolioId)?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [activePortfolioId, portfolios]);

  if (!portfolios?.length) {
    return null;
  }
  const disabledClasses = loading ? "pointer-events-none opacity-50" : "";

  const handlePortfolioClicked = async (portfolioId: string) => {
    onPortfolioClicked?.(portfolioId);
    socketServiceRef?.sendPortfolio(portfolioId);
  };

  return (
    <Panel className={`!p-2.5 ${disabledClasses}`}>
      <Labels.H3 className="px-2 py-2">
        {t("aiAssistant.generatedPortfolios")}
      </Labels.H3>
      <div className="max-h-[600px] overflow-y-auto rounded-2xl">
        <Layouts.Spaced className="mt-2">
          {portfolios?.map((item, idx) => (
            <div id={item.id} key={item.id}>
              <PortfolioAssistantResponse
                key={"portfolio_ai_" + idx}
                portfolioObject={item}
                active={item.id === activePortfolioId}
                onPortfolioClicked={handlePortfolioClicked}
              />
            </div>
          ))}
        </Layouts.Spaced>
      </div>
    </Panel>
  );
};
