import { useEffect } from "react";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import usePortfoliosStore from "shared/store/portfoliosStore";

import { Loading } from "shared/types/enums";

interface PortfoliosHookData {
  portfolios: Portfolio[];
  loading: Loading;
  fetched: boolean;
}

export function usePortfolios(): PortfoliosHookData {
  const { loading, fetched, portfolios, fetchPortfolios } =
    usePortfoliosStore();

  useEffect(() => {
    fetchPortfolios();
  }, []);

  return {
    loading,
    fetched,
    portfolios,
  };
}
