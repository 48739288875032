import React from "react";
import { AssistantMessage } from "types/assistant/AssistantTypes";
import styles from "./chatMessage.module.css";

type Props = {
  message: AssistantMessage;
};

export const ChatUserMessage: React.FC<Props> = ({ message }) => {
  const textContent = message?.content?.[0]?.text?.value;

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diffInSeconds < 60) {
      return "now";
    }

    if (
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear() &&
      diffInSeconds < 12 * 3600
    ) {
      return `${date.getHours().toString().padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    }

    return `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")} ${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };
  return (
    <div className="mt-2 flex justify-end">
      <div className={styles.chatUserMessage}>
        {textContent}
        <div className={styles.chatUserMessageTime}>
          {formatDate(message.created_at)}
        </div>
      </div>
    </div>
  );
};
