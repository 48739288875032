// ClientList.jsx
import React, { useEffect, useState } from "react";
import { Eye, Download, Search } from "lucide-react";
import { useNavigate } from "react-router-dom";
import crmStore from "shared/store/crmStore";
import styles from "./clientList.module.css";
import { useTranslation } from "react-i18next";

export const ClientList = () => {
  const { t } = useTranslation();
  const { clientsList } = crmStore();

  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleClientClick = (clientId: any) => {
    navigate(`/crm/clients/${clientId}`);
  };

  const handleDownload = (e: any, clientId: any) => {
    e.stopPropagation();
    // Handle download logic here
  };

  const filteredClients = clientsList.filter((client) => {
    const fullName =
      `${client.client_info.first_name} ${client.client_info.last_name}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  useEffect(() => { }, [clientsList]);

  const priority = "urgente";

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t("crm.clients")}</h2>
        <div className={styles.searchContainer}>
          <Search size={16} className={styles.searchIcon} />
          <input
            type="text"
            placeholder={t("crm.searchFor")}
            className={styles.searchInput}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.clientsList}>
        {filteredClients.map((client) => {
          return (
            <div
              key={client.client_id}
              className={styles.clientCard}
              onClick={() => handleClientClick(client.client_id)}
              role="button"
              tabIndex={0}
            >
              {/* Rest of the client card content remains the same */}
              <div className={styles.clientInfo}>
                <div className={styles.nameSection}>
                  <Eye size={16} className={styles.eyeIcon} />
                  <div>
                    <div className={styles.clientName}>
                      {client.client_info.first_name}{" "}
                      {client.client_info.last_name}
                    </div>
                    <div className={styles.clientAmount}>
                      €{client.client_info.tfa}
                    </div>
                  </div>
                </div>
                <div className={styles.messageSection}>
                  <p className={styles.message}>
                    {client.interests.length > 0 && (
                      <> {client.interests[0].description}</>
                    )}
                  </p>

                  <div className={`${styles.priority} ${styles[priority]}`}>
                    {priority.charAt(0).toUpperCase() + priority.slice(1)}
                  </div>
                </div>
                <button
                  className={styles.downloadButton}
                  onClick={(e) => handleDownload(e, client.client_id)}
                >
                  <Download size={16} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
