import { DownloadIcon, ExpandIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { DownloadAttachmentIcon } from "assets/svg/DownloadAttachmentIcon";
import { ExpandAttachmentIcon } from "assets/svg/ExpandAttachmentIcon";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "shared/components/ui/dialog";
import { ScrollArea, ScrollBar } from "shared/components/ui/scroll-area";
import { AssistantService } from "shared/services/AssistantService";
import { AssistantAttachment } from "types/assistant/AssistantTypes";
import styles from "./txtAttachment.module.css";
import colorsStore from "shared/store/colorsStore";

type Props = {
  attachment: AssistantAttachment;
  onFinishLoading?: () => void;
};

export const TxtAttachment: React.FC<Props> = ({
  attachment,
  onFinishLoading,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const params = useParams();
  const threadId = params.threadId;
  const [content, setContent] = useState<string>();

  useEffect(() => {
    if (threadId) {
      AssistantService.downloadAttachment(threadId, attachment.attachment_id)
        .then(async (response) => {
          const c = await response.data.text();
          setContent(c);
        })
        .finally(() => {
          onFinishLoading?.();
        });
    }
  }, [threadId]);

  const download = async () => {
    const fileName = attachment.attachment_name;
    const attachmentId = attachment.attachment_id;
    if (threadId) {
      const response = await AssistantService.downloadAttachment(
        threadId,
        attachmentId,
      );

      const link = document.createElement("a");
      link.href = URL.createObjectURL(response.data);
      link.download = fileName;
      link.click();
    }
  };

  return (
    <>
      <div className="relative h-full w-fit min-w-0 rounded-xl">
        <div className="my-2 flex flex-wrap items-center justify-between gap-4">
          <div className="flex justify-end">
            <div
              style={{ width: 30, height: 31, cursor: "pointer" }}
              onClick={download}
              className="hover:opacity-60"
            >
              <DownloadAttachmentIcon />
            </div>
            <Dialog>
              <DialogTrigger asChild>
                {/* <ExpandIcon className="size-4 cursor-pointer duration-150 hover:opacity-60" /> */}
                <ExpandAttachmentIcon className="ml-[4px] cursor-pointer hover:opacity-60" />
              </DialogTrigger>
              <DialogContent
                className={styles.contentContainer}
                style={{
                  backgroundColor: colorsConfig.finecoBlueLight,
                  color: colorsConfig.finecoBlack,
                }}
                // @ts-ignore
                xColor={colorsConfig.finecoBlack}
              >
                <ScrollArea className="max-h-screen py-4 pb-12">
                  <p className="p-4">{content}</p>
                  <ScrollBar orientation="horizontal" />
                </ScrollArea>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
      <div
        id={attachment.attachment_id.toString()}
        className="txt-attachment-scroll-margin"
      />
    </>
  );
};
