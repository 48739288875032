import { AssistantRoutes } from "config/routes/AssistantRoutes";
import RestService from "shared/api/RestService";

const blobToBase64 = async (
  blob: Blob,
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const SpeechToTextService = {
  getText: async (blob: Blob): Promise<string> => {
    const body = {
      format: "wav",
      base64: ((await blobToBase64(blob)) as string).split(",")[1],
    };
    const response = await RestService.post<{ text: string }>(
      AssistantRoutes.SPEECH_TO_TEXT,
      body,
    );
    return response.text;
  },

  getSpeech: async (text: string): Promise<Blob> => {
    const response = await RestService.postBaseDownload(
      AssistantRoutes.TEXT_TO_SPEECH,
      { text },
    );
    return response["data"];
  },
};
