type Props = {
  className?: string;
};

export const SuggestedPromptIcon2: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1744_12779)">
        <path
          d="M16.5 6.77475H12.75V4.5C12.75 4.30109 12.671 4.11032 12.5303 3.96967C12.3897 3.82902 12.1989 3.75 12 3.75H6C5.80109 3.75 5.61032 3.82902 5.46967 3.96967C5.32902 4.11032 5.25 4.30109 5.25 4.5V6.77475H1.5C1.10218 6.77475 0.720644 6.93279 0.43934 7.21409C0.158035 7.49539 0 7.87692 0 8.27475L0 18.0247C0 18.4226 0.158035 18.8041 0.43934 19.0854C0.720644 19.3667 1.10218 19.5247 1.5 19.5247H16.5C16.8978 19.5247 17.2794 19.3667 17.5607 19.0854C17.842 18.8041 18 18.4226 18 18.0247V8.27475C18 7.87692 17.842 7.49539 17.5607 7.21409C17.2794 6.93279 16.8978 6.77475 16.5 6.77475ZM6.75 5.25H11.25V6.765H6.75V5.25ZM11.25 18.0247H6.75V8.27475H11.25V18.0247ZM1.5 17.2747V9.02475C1.5 8.82584 1.57902 8.63507 1.71967 8.49442C1.86032 8.35377 2.05109 8.27475 2.25 8.27475H5.25V18.0247H2.25C2.05109 18.0247 1.86032 17.9457 1.71967 17.8051C1.57902 17.6644 1.5 17.4737 1.5 17.2747ZM16.5 17.2747C16.5 17.4737 16.421 17.6644 16.2803 17.8051C16.1397 17.9457 15.9489 18.0247 15.75 18.0247H12.75V8.27475H15.75C15.9489 8.27475 16.1397 8.35377 16.2803 8.49442C16.421 8.63507 16.5 8.82584 16.5 9.02475V17.2747Z"
          fill="#00549F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1744_12779">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
