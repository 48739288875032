import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "shared/components/ui/checkbox";
import activityPanelStore from "shared/store/activityPanelStore";
import { ConstraintConfigType } from "types/assistant/ConstraintsTypes";
import mainStyles from "style/main.module.css";

type TrafficLightContentProps = {
  configData: ConstraintConfigType;
  onDataChange: (data: Record<string, string | number | boolean>) => void;
  setHasChanged: (value: boolean) => void;
};

// Helper type to extract boolean values from config data
type TrafficLightConfigData = {
  all_lights_green: { default: boolean; type: "boolean" };
  none_lights_red: { default: boolean; type: "boolean" };
};

type TrafficLightState = {
  all_lights_green: boolean;
  none_lights_red: boolean;
};

export const TrafficLightContent = forwardRef<
  { reset: () => void },
  TrafficLightContentProps
>(({ configData, onDataChange, setHasChanged }, ref) => {
  // Type assertion for configData to ensure we're working with boolean defaults
  const typedConfigData = configData as unknown as TrafficLightConfigData;
  const constraintsState = activityPanelStore(
    (state) => state.constraintsState,
  );
  const { t } = useTranslation();

  // Get initial data for both parameters
  const getData = (): TrafficLightState => {
    if (constraintsState?.data?.traffic_lights) {
      return {
        all_lights_green:
          constraintsState.data.traffic_lights.all_lights_green ??
          typedConfigData.all_lights_green.default,
        none_lights_red:
          constraintsState.data.traffic_lights.none_lights_red ??
          typedConfigData.none_lights_red.default,
      };
    }
    return {
      all_lights_green: typedConfigData.all_lights_green.default,
      none_lights_red: typedConfigData.none_lights_red.default,
    };
  };

  const initialValuesRef = useRef<TrafficLightState>({
    all_lights_green: false,
    none_lights_red: false,
  });
  const hasChangedRef = useRef<boolean>(false);
  const [hasUserChanged, setHasUserChanged] = useState<boolean>(false);
  const [state, setState] = useState<TrafficLightState>(getData());
  const [initialValues, setInitialValues] = useState<TrafficLightState>(
    getData(),
  );

  useImperativeHandle(ref, () => ({
    reset: () => {
      const defaultValues = getData();
      setInitialValues(defaultValues);
      setState(defaultValues);
      initialValuesRef.current = defaultValues;
      setHasUserChanged(false);
      hasChangedRef.current = false;
    },
  }));

  useEffect(() => {
    const data = getData();
    setState(data);
    setInitialValues(data);
  }, [constraintsState?.data?.traffic_lights]);

  useEffect(() => {
    if (hasUserChanged) {
      const hasStateChanged =
        state.all_lights_green !== initialValues.all_lights_green ||
        state.none_lights_red !== initialValues.none_lights_red;

      setHasChanged(hasStateChanged);
      onDataChange({
        all_lights_green: state.all_lights_green,
        none_lights_red: state.none_lights_red,
      });

      const hasChanged = Object.keys(state).some(
        (key) =>
          initialValues[key as keyof TrafficLightState] !==
          initialValuesRef.current[key as keyof TrafficLightState],
      );

      if (hasChanged !== hasChangedRef.current) {
        setHasChanged(hasChanged);
        hasChangedRef.current = hasChanged;
      }
    }
  }, [state, hasUserChanged]);

  return (
    <div style={{ width: "100%", marginTop: 25 }}>
      {/* All Lights Green Section */}
      <div className="grid-container-2x3">
        <div className="grid-item">{configData.all_lights_green.label}</div>
        <div className="grid-item">
          <div className="flex items-center space-x-2">
            <label className={mainStyles.label}>
              {t("constraints.trafficLight.yes")}
            </label>
            <Checkbox
              id="allLightsGreenYes"
              checked={state.all_lights_green}
              onCheckedChange={(checked) => {
                setHasUserChanged(true);
                setState((prev) => ({
                  ...prev,
                  all_lights_green: checked === true,
                }));
              }}
            />
          </div>
        </div>
        <div className="grid-item">
          <div className="flex items-center space-x-2">
            <label className={mainStyles.label}>
              {t("constraints.trafficLight.no")}
            </label>
            <Checkbox
              id="allLightsGreenNo"
              checked={!state.all_lights_green}
              onCheckedChange={(checked) => {
                setHasUserChanged(true);
                setState((prev) => ({
                  ...prev,
                  all_lights_green: checked === false,
                }));
              }}
            />
          </div>
        </div>
      </div>

      {/* None Lights Red Section */}
      <div className="grid-container-2x3">
        <div className="grid-item">{configData.none_lights_red.label}</div>
        <div className="grid-item">
          <div className="flex items-center space-x-2">
            <label className={mainStyles.label}>
              {t("constraints.trafficLight.yes")}
            </label>
            <Checkbox
              id="noneLightsRedYes"
              checked={state.none_lights_red}
              onCheckedChange={(checked) => {
                setHasUserChanged(true);
                setState((prev) => ({
                  ...prev,
                  none_lights_red: checked === true,
                }));
              }}
            />
          </div>
        </div>
        <div className="grid-item">
          <div className="flex items-center space-x-2">
            <label className={mainStyles.label}>
              {t("constraints.trafficLight.no")}
            </label>
            <Checkbox
              id="noneLightsRedNo"
              checked={!state.none_lights_red}
              onCheckedChange={(checked) => {
                setHasUserChanged(true);
                setState((prev) => ({
                  ...prev,
                  none_lights_red: checked === false,
                }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
