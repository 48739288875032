import { DateTime } from "luxon";
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
} from "recharts";
import { PortfolioPerformanceChart } from "shared/models/portfolio/PortfolioModel";
import { CurrencyFormat } from "shared/utils/currency";
import { formatDate } from "shared/utils/date";
import colorsStore from "shared/store/colorsStore";
import { cn } from "utils";

type Props = {
  chartData?: PortfolioPerformanceChart;
  height: number;
  domain?: string[];
  showTooltip?: boolean;
};

type XAxisTickProps = XAxisProps & {
  index: number;
  payload: { value: string };
};

export default function PnlChart({
  chartData,
  height,
  domain,
  showTooltip,
}: Props) {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  if (!chartData) return null;

  const positive =
    chartData.length > 2
      ? // @ts-ignore
        chartData[chartData.length - 1].cash - chartData[0].cash > 0
      : true;

  type NewType = XAxisTickProps;

  const tooltipContent = (tooltipProps: any) => {
    const date =
      tooltipProps.payload && tooltipProps.payload.length > 0
        ? tooltipProps.payload[0]["payload"]["date"]
        : "";
    const value =
      tooltipProps.payload && tooltipProps.payload.length > 0
        ? tooltipProps.payload[0]["payload"]["tooltipValue"]
        : "";
    return (
      <div className="space-y-1 rounded-md bg-gray-800 px-2.5 py-1.5 text-sm font-medium">
        <div>
          {date && (
            <span className="text-xs font-semibold text-gray-600">
              {" "}
              {DateTime.fromISO(date).toFormat("MMM dd, yyyy")}
            </span>
          )}
        </div>

        <div className="text-gray-600">
          <div>
            <span
              className={cn(
                "mx-1",
                value >= 0 ? "text-green-dark" : "text-magenta-30",
              )}
            >
              {CurrencyFormat.format(value)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="relative w-full">
      <div className="absolute bottom-0 left-0 right-0 top-0">
        {chartData && chartData.length > 0 && (
          <ResponsiveContainer width="100%" height={height} minWidth={10}>
            <AreaChart
              height={600}
              data={chartData}
              margin={{ top: 5, right: 10, bottom: 5, left: 10 }}
            >
              <defs>
                <linearGradient
                  id="chartGreenGradient"
                  x1="0"
                  y1="0"
                  y2="100%"
                  x2="0"
                >
                  <stop offset="50%" stopColor={"#00FD86"} />
                  <stop
                    offset="100%"
                    // @ts-ignore
                    stopColor={colorsConfig["sb-gray"]["100"]}
                  />
                </linearGradient>
                <linearGradient
                  id="chartRedGradient"
                  x1="0"
                  y1="0"
                  y2="100%"
                  x2="0"
                >
                  <stop offset="50%" stopColor={"#ff6692"} />
                  <stop
                    offset="100%"
                    // @ts-ignore
                    stopColor={colorsConfig["sb-gray"]["100"]}
                  />
                </linearGradient>
              </defs>
              {domain && <YAxis domain={domain} hide />}
              {showTooltip && (
                <Tooltip
                  content={tooltipContent}
                  cursor={{ strokeDasharray: "3 3", stroke: "#34363A" }}
                />
              )}
              <ReferenceLine
                // @ts-ignore
                y={chartData[0].cash}
                stroke="#34363A"
                strokeDasharray="5 5"
              />
              <Area
                isAnimationActive={false}
                dataKey="cash"
                stroke={
                  positive
                    ? // @ts-ignore
                      colorsConfig["sb-green"][700]
                    : // @ts-ignore
                      colorsConfig["sb-red"][700]
                }
                fill={
                  positive
                    ? "url(#chartGreenGradient)"
                    : "url(#chartRedGradient)"
                }
                strokeWidth={2}
                fillOpacity={0.2}
              />
              <XAxis
                dataKey={"date"}
                tickFormatter={(tick: string) => formatDate(tick)}
                ticks={
                  chartData.length > 1
                    ? // @ts-ignore
                      [chartData[0].date, chartData[chartData.length - 1].date]
                    : // @ts-ignore
                      [chartData[0].date]
                }
                interval="preserveStartEnd"
                tick={({ index, x, y, payload, tickFormatter }: NewType) => {
                  const formattedTick = tickFormatter
                    ? tickFormatter(payload.value, index)
                    : null;
                  return (
                    <g>
                      <text
                        x={x}
                        y={y}
                        textAnchor={"middle"}
                        fontSize={12}
                        fill={"#9B9FA7"}
                      >
                        {formattedTick ? formattedTick : ""}
                      </text>
                    </g>
                  );
                }}
                axisLine={false}
                tickLine={false}
                tickMargin={25}
                strokeWidth={0.5}
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}
