import PortfolioConfigData from "config/portfolio-config.json";
import React from "react";
import PortfolioItem from "../../PortfolioItem";

type SectionChildrenProps = {
  reportData: any;
};
const ScomposizionePortafoglioContainer: React.FC<SectionChildrenProps> = ({
  reportData,
}) => {
  if (!reportData) {
    return <></>;
  }
  return (
    <div style={{ marginLeft: 10, marginBottom: 30 }}>
      {/* macro assets */}
      <div className="mt-[10px]"></div>

      {/* distribuzione_macro_asset_class */}
      <PortfolioItem
        dataItem={{
          data: reportData.scomposizione_portafoglio
            .distribuzione_macro_asset_class,
          configData:
            PortfolioConfigData.scomposizione_portafoglio.data
              .distribuzione_macro_asset_class,
        }}
      />
      {/* area geografica */}
      <div className="mt-[10px]"></div>
      <PortfolioItem
        dataItem={{
          data: reportData.scomposizione_portafoglio.distribuzione_geografica,
          configData:
            PortfolioConfigData.scomposizione_portafoglio.data
              .distribuzione_geografica,
        }}
      />

      {/* valuta */}
      <div className="mt-[10px]"></div>
      <PortfolioItem
        dataItem={{
          data: reportData.scomposizione_portafoglio.distribuzione_valuta,
          configData:
            PortfolioConfigData.scomposizione_portafoglio.data
              .distribuzione_valuta,
        }}
      />

      <div className="mt-[10px]"></div>
      <PortfolioItem
        dataItem={{
          data: reportData.focus_asset_azionario.distribuzione_settore,
          configData:
            PortfolioConfigData.focus_asset_azionario.data
              .distribuzione_settore,
        }}
      />

      {/* tipo bond */}
      <div className="mt-[10px]"></div>
      <PortfolioItem
        dataItem={{
          data: reportData.focus_asset_obbligazionario.tipo_bond,
          configData:
            PortfolioConfigData.focus_asset_obbligazionario.data.tipo_bond,
        }}
      />

      {/* duration */}
      <div className="mt-[10px]"></div>

      <PortfolioItem
        dataItem={{
          data: reportData.focus_asset_obbligazionario.duration,
          configData:
            PortfolioConfigData.focus_asset_obbligazionario.data.duration,
        }}
      />

      {/* rendimento_scadenza */}
      <div className="mt-[10px]"></div>

      <PortfolioItem
        dataItem={{
          data: reportData.focus_asset_obbligazionario.rendimento_scadenza,
          configData:
            PortfolioConfigData.focus_asset_obbligazionario.data
              .rendimento_scadenza,
        }}
      />

      {/* qualita_debito */}
      <div className="mt-[10px]"></div>

      <PortfolioItem
        dataItem={{
          data: reportData.focus_asset_obbligazionario.qualita_debito,
          configData:
            PortfolioConfigData.focus_asset_obbligazionario.data.qualita_debito,
        }}
      />
    </div>
  );
};
export default React.memo(
  ScomposizionePortafoglioContainer,
  (prevProps: SectionChildrenProps, nextProps: SectionChildrenProps) => {
    // Custom comparison function
    return prevProps.reportData === nextProps.reportData;
  },
);
