import { useTranslation } from "react-i18next";
import { Side } from "shared/models/trading/TradingOrderModel";

type Props = {
  active: Side;
  setActive: (side: Side) => void;
};

export const BuySellTabs: React.FC<Props> = ({ active, setActive }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-4">
      <SideTab
        text={t("orders.buy")}
        active={active === "buy"}
        onClick={() => setActive("buy")}
      />
      <SideTab
        text={t("orders.sell")}
        active={active === "sell"}
        onClick={() => setActive("sell")}
      />
    </div>
  );
};

type SideTabProps = {
  active: boolean;
  text: string;
  onClick: () => void;
};

const SideTab: React.FC<SideTabProps> = ({ active, text, onClick }) => {
  return (
    <div
      className={
        "box-border rounded-full px-6 py-2 font-semibold duration-150 " +
        (active
          ? "border-sb-green-1002 bg-sb-green-1002 border"
          : "cursor-pointer border border-sb-gray-400 hover:bg-sb-gray-100")
      }
      onClick={onClick}
    >
      {text.toUpperCase()}
    </div>
  );
};
