import { Cell, Pie, PieChart } from "recharts";
import {
  PortfolioAssetAllocation,
  PortfolioPerformanceChart,
} from "shared/models/portfolio/PortfolioModel";

type Props = {
  allocations?: PortfolioAssetAllocation[];
};

const Palette = {
  Green: "#00FD86",
  LightGreen: "#00CC4F",
  DarkGreen: "#00FD86",
  Green80: "#00AD3A",
  Green100: "#007A29",
  Cyan: "#05F9F9",
  DarkCyan: "#02EDED",
  LightCyan: "#00CCCB",
  Red: "#FF5833",
  Red60: "#C42E0E",
  Purple: "#B073FF",
  Purple10: "#E5D9FF",
  Purple30: "#B88CFF",
  Purple60: "#6D36B2",
  Magenta: "#FF4C77",
  Yellow: "#EEFF59",
  LightYellow: "#D9D936",
  Yellow10: "#FBFFD9",
  Yellow30: "#F4FF8C",
  Orange1: "#F0A61B",
  Green1: "#9DFF75",
  Blue: "#329DFF",
  Blue20: "#80D5FF",
  Blue30: "#4DB5FF",
  Blue50: "#0A6FCC",
  Blue60: "#005DB2",
  Teal20: "#31E4F5",
  Teal30: "#00C9F1",
  Black: "#111112",
  CryptoBackground: "#130B1E",
  White: "#FFFFFF",
  Grey10: "#F5F5F6",
  Grey20: "#E0E1E3",
  Grey30: "#C7C9CC",
  Grey40: "#B5B8BF",
  Grey50: "#9B9FA7",
  Grey60: "#4E5157",
  Grey70: "#34363A",
  Grey80: "#1E1F21",
  Grey90: "#202124",
  Red10: "#FFDAD4",
  Green10: "#E0FFEB",
  Transparent: "#00000000",
};

export default function CompositionPieChart({ allocations }: Props) {
  const colors = Palette;

  const pieChartColors = [
    colors.DarkGreen,
    colors.LightGreen,
    colors.Blue,
    colors.Cyan,
    colors.Purple,
    colors.Purple60,
  ];

  const getConvertedPieChartPoints = (
    d: PortfolioAssetAllocation[],
  ): PortfolioPerformanceChart[] => {
    if (d) {
      const assets = d.map((item) => {
        return {
          symbol: item.symbol,
          weight: parseFloat(item.weight),
        };
      });
      let result: any = [];
      if (assets) {
        const sortedAssets = assets.sort((a, b) => b.weight - a.weight);
        result = sortedAssets.slice(0, pieChartColors.length - 1);
        const othersWeight = sortedAssets
          .slice(pieChartColors.length - 1, assets.length)
          .map((el) => el.weight)
          .reduce((partialSum, a) => partialSum + a, 0);
        if (othersWeight > 0) {
          result.push({
            weight: othersWeight,
            symbol: "OTHER",
          });
        }
      }
      return result;
    }
    return [];
  };

  if (!allocations) {
    return null;
  }

  const chartData = getConvertedPieChartPoints(allocations);

  return (
    <div className="flex gap-2 outline-none">
      <PieChart width={70} height={70}>
        <Pie
          data={getConvertedPieChartPoints(allocations)}
          dataKey="weight"
          nameKey="symbol"
          cx="50%"
          cy="50%"
          innerRadius={"70%"}
          outerRadius={"100%"}
          stroke="transparent"
          isAnimationActive={false}
        >
          {chartData.map((_, index) => {
            return <Cell key={"cell-" + index} fill={pieChartColors[index]} />;
          })}
        </Pie>
      </PieChart>
      <div className="grid grid-cols-2 gap-x-2 gap-y-2 text-sm">
        {getConvertedPieChartPoints(allocations).map(
          (value: any, index: number) => {
            return (
              <div
                key={"legend_entry_" + index}
                className="flex items-center gap-2"
              >
                <div
                  className={`min-h-[4px] min-w-[4px] rounded-full md:min-h-[6px] md:min-w-[6px]`}
                  style={{ backgroundColor: pieChartColors[index] }}
                ></div>
                <div className="flex items-baseline gap-1">
                  <span className="text-sm font-semibold">{value.symbol}</span>{" "}
                  <span className="text-sm font-light text-gray-500">
                    {(value.weight * 100).toFixed(0)}%
                  </span>
                </div>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
}
