import React from "react";

import { Address } from "./Address";
import { Affiliate } from "./Affiliate";
import { AnnualIncome } from "./AnnualIncome";
import { Birthday } from "./Birthday";
import { EmploymentStatus } from "./EmploymentStatus";
import { FundingSource } from "./FundingSource";
import { LegalNamePhoneVerification } from "./LegalNamePhoneVerification";
import { LiquidAssets } from "./LiquidAssets";
import { Nationality } from "./Nationality";
import { OneTimePhoneVerificationPassword } from "./OneTimePhoneVerificationPassword";
import { TaxCountry } from "./TaxCountry";
import { TaxId } from "./TaxId";
import { UploadVisa } from "./UploadVisa";
import { TermsAndConditions } from "./TermsAndConditions";
import { Signature } from "./Signature";
import { EmployerDetails } from "./EmployerDetails";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AdvisorRegistrationNumber } from "./AdvisorRegistrationNumber";
import { W8benForm } from "./W8BenForm";
import useOnboardingStore from "shared/store/onboardingStore";

export type OnboardingPart =
  | "legal_name_phone_verification"
  | "advisor_registration"
  | "one_time_phone_verification_password"
  | "address"
  | "tax_country"
  | "birthday"
  | "nationality"
  | "taxId"
  | "upload_visa"
  | "employment_status"
  | "employer_details"
  | "funding_source"
  | "annual_income"
  | "liquid_assets"
  | "affiliate"
  | "w8ben_form"
  | "terms_and_conditions"
  | "signature";

const ONBOARDING_PARTS: OnboardingPart[] = [
  "tax_country",
  "advisor_registration",
  "legal_name_phone_verification",
  "one_time_phone_verification_password",
  "address",
  "birthday",
  "nationality",
  "taxId",
  "upload_visa",
  "employment_status",
  "employer_details",
  "funding_source",
  "annual_income",
  "liquid_assets",
  "affiliate",
  "w8ben_form",
  "terms_and_conditions",
  "signature",
];

export interface OnboardingPartProps {
  onNext?: (part?: OnboardingPart) => void;
  onPrevious?: (part?: OnboardingPart) => void;
}

export const OnboardingFlow: React.FC = () => {
  const activeOnboardingPart = useOnboardingStore(
    (state) => state.activeOnboardingPart,
  );
  const { updateOnboardingPart } = useOnboardingStore();

  const onSetExactOnboardingPart = (part: OnboardingPart) => {
    updateOnboardingPart(part);
    AsyncStorage.setItem("current_onboarding_step", part);
  };

  const handleOnNext = (part?: OnboardingPart) => {
    if (part) {
      onSetExactOnboardingPart(part);
      return;
    }

    if (!activeOnboardingPart) return;
    const currentIndex = ONBOARDING_PARTS.indexOf(activeOnboardingPart);
    const nextIndex = currentIndex + 1;
    if (nextIndex < ONBOARDING_PARTS.length) {
      const currentStep = ONBOARDING_PARTS[nextIndex];
      // @ts-ignore
      updateOnboardingPart(currentStep);
      // @ts-ignore
      AsyncStorage.setItem("current_onboarding_step", currentStep);
    } else {
      // Handle completion or navigate to the next screen after finishing onboarding
      console.log("Onboarding completed");
    }
  };

  const handleOnPrevious = (part?: OnboardingPart) => {
    if (part) {
      onSetExactOnboardingPart(part);
      return;
    }
    if (!activeOnboardingPart) return;
    const currentIndex = ONBOARDING_PARTS.indexOf(activeOnboardingPart);
    const prevIndex = currentIndex - 1;
    const currentStep = ONBOARDING_PARTS[prevIndex];
    if (currentIndex !== 0) {
      // @ts-ignore
      updateOnboardingPart(currentStep);
      // @ts-ignore
      AsyncStorage.setItem("current_onboarding_step", currentStep);
    }
  };
  switch (activeOnboardingPart) {
    case "tax_country":
      return <TaxCountry onNext={handleOnNext} />;
    case "advisor_registration":
      return (
        <AdvisorRegistrationNumber
          onNext={handleOnNext}
          onPrevious={handleOnPrevious}
        />
      );
    case "legal_name_phone_verification":
      return (
        <LegalNamePhoneVerification
          onNext={handleOnNext}
          onPrevious={handleOnPrevious}
        />
      );
    case "one_time_phone_verification_password":
      return (
        <OneTimePhoneVerificationPassword
          onNext={handleOnNext}
          onPrevious={handleOnPrevious}
        />
      );
    case "address":
      return <Address onNext={handleOnNext} onPrevious={handleOnPrevious} />;
    case "birthday":
      return <Birthday onNext={handleOnNext} onPrevious={handleOnPrevious} />;
    case "nationality":
      return (
        <Nationality onNext={handleOnNext} onPrevious={handleOnPrevious} />
      );
    case "taxId":
      return <TaxId onNext={handleOnNext} onPrevious={handleOnPrevious} />;
    case "upload_visa":
      return <UploadVisa onNext={handleOnNext} onPrevious={handleOnPrevious} />;
    case "employment_status":
      return (
        <EmploymentStatus onNext={handleOnNext} onPrevious={handleOnPrevious} />
      );
    case "employer_details":
      return (
        <EmployerDetails onNext={handleOnNext} onPrevious={handleOnPrevious} />
      );
    case "funding_source":
      return (
        <FundingSource onNext={handleOnNext} onPrevious={handleOnPrevious} />
      );
    case "annual_income":
      return (
        <AnnualIncome onNext={handleOnNext} onPrevious={handleOnPrevious} />
      );
    case "liquid_assets":
      return (
        <LiquidAssets onNext={handleOnNext} onPrevious={handleOnPrevious} />
      );
    case "affiliate":
      return <Affiliate onNext={handleOnNext} onPrevious={handleOnPrevious} />;
    case "w8ben_form":
      return <W8benForm onNext={handleOnNext} onPrevious={handleOnPrevious} />;
    case "terms_and_conditions":
      return (
        <TermsAndConditions
          onNext={handleOnNext}
          onPrevious={handleOnPrevious}
        />
      );
    case "signature":
      return <Signature onNext={handleOnNext} onPrevious={handleOnPrevious} />;
    default:
      return <></>;
  }
};
