import { useTranslation } from "react-i18next";
import "./../styles.css";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ConstraintConfigType } from "types/assistant/ConstraintsTypes";
import activityPanelStore from "shared/store/activityPanelStore";
import {
  convertToPercentage,
  isEmptyObject,
  parseConstraintsFormValues,
  parseValue,
} from "utils/misc";
import { GridItem } from "./GridItem";
import colorsStore from "shared/store/colorsStore";

type CostContentProps = {
  configData: ConstraintConfigType;
  onDataChange: (data: Record<string, string | number>) => void;
  setHasChanged: (value: boolean) => void;
};

export const CostContent = forwardRef<{ reset: () => void }, CostContentProps>(
  ({ configData, onDataChange, setHasChanged }, ref) => {
    //** store */
    const colorsConfig = colorsStore((state) => state.colorsConfigStore);
    const constraintsState = activityPanelStore(
      (state) => state.constraintsState,
    );

    //** get data */
    const getData = () => {
      if (constraintsState?.data?.cost) {
        return Object.keys(configData).reduce(
          (acc, key) => {
            let value =
              constraintsState.data.cost[key] ?? configData[key].default;
            let valueOutput = "";

            if (typeof value === "number") {
              switch (configData[key].type) {
                case "percentage":
                  valueOutput = convertToPercentage(value);
                  break;
                case "float":
                  valueOutput = value.toFixed(2);
                  break;
                default:
                  valueOutput = value.toString();
              }
            }

            acc[key] = valueOutput;
            return acc;
          },
          {} as Record<string, string | number>,
        );
      }

      return Object.keys(configData).reduce(
        (acc, key) => {
          let value = configData[key].default;
          let valueOutput = "";

          if (typeof value === "number") {
            switch (configData[key].type) {
              case "percentage":
                valueOutput = convertToPercentage(value);
                break;
              case "float":
                valueOutput = value.toFixed(2);
                break;
              default:
                valueOutput = value.toString();
            }
          }

          acc[key] = valueOutput;
          return acc;
        },
        {} as Record<string, string | number>,
      );
    };

    //** refs */
    const initialValuesRef = useRef<Record<string, string | number>>({});
    const hasChangedRef = useRef<boolean>(false);

    //** states */
    const [hasUserChanged, setHasUserChanged] = useState<boolean>(false);
    const [formValues, setFormValues] = useState(getData());

    //** RESET */
    useImperativeHandle(ref, () => ({
      reset: () => {
        const defaultValues = getData();
        setFormValues(defaultValues);
        initialValuesRef.current = defaultValues;
        setHasUserChanged(false);
        hasChangedRef.current = false;
      },
    }));

    //** use effects */
    useEffect(() => {
      const updatedValues = getData();
      setFormValues(updatedValues);
      initialValuesRef.current = updatedValues;
    }, [constraintsState?.data?.cost]);

    useEffect(() => {
      if (hasUserChanged) {
        if (!isEmptyObject(formValues)) {
          const hasChanged = Object.keys(configData).some(
            (key) => formValues[key] !== initialValuesRef.current[key],
          );

          if (hasChanged !== hasChangedRef.current) {
            setHasChanged(hasChanged);
            hasChangedRef.current = hasChanged;
          }

          if (hasChanged) {
            const convertedFormValues = parseConstraintsFormValues(
              formValues,
              configData,
            );
            onDataChange(convertedFormValues);
          }
        }
      }
    }, [formValues]);

    //** logic */
    // @ts-ignore
    const handleInputChange = (e) => {
      setHasUserChanged(true);
      const value = parseValue(e.target, configData);
      setFormValues((prevValues) => ({
        ...prevValues,
        [e.target.id]: value,
      }));
    };

    //** JSX */
    return (
      <div style={{ marginTop: 5, height: "auto" }}>
        <div
          className="grid-container-2x2"
          style={{ color: colorsConfig.black, fontSize: 14 }}
        >
          <GridItem
            cd={configData}
            cv1={Object.keys(configData)[0]}
            cv2={Object.keys(configData)[1]}
            fv={formValues}
            ohi={handleInputChange}
          />
          <GridItem
            cd={configData}
            cv1={Object.keys(configData)[2]}
            cv2={Object.keys(configData)[3]}
            fv={formValues}
            ohi={handleInputChange}
          />
        </div>
      </div>
    );
  },
);
