// Updated EventForm with Edit Support

import React, { useState, forwardRef, useEffect, useCallback } from "react";
import { X, MapPin, Calendar, Search, Check } from "lucide-react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./calendarWidget.module.css";
import { CalendarEvent } from "types/crm/ClientTypes";
import { CrmService } from "services/CrmServices";
import { Participants } from "./Partecipants";
import crmStore from "shared/store/crmStore";
import { ClientList } from "../ClientList";

interface CustomInputProps {
  value?: string;
  onClick?: () => void;
  onChange?: () => void;
}

const CustomInput = forwardRef<HTMLDivElement, CustomInputProps>(
  ({ value, onClick }, ref) => (
    <div className={styles.dateTimeInput} onClick={onClick} ref={ref}>
      <Calendar size={16} />
      <span>{value}</span>
    </div>
  ),
);

CustomInput.displayName = "CustomInput";

interface EventFormProps {
  onClose: () => void;
  onEventAdded: () => void;
  initialDate?: string;
  initialEvent?: Partial<CalendarEvent>; // Support editing existing events
}

export const EventForm: React.FC<EventFormProps> = ({
  onClose,
  onEventAdded,
  initialDate,
  initialEvent,
}) => {
  const { t } = useTranslation();
  const { clientsList } = crmStore();
  const [searchTerm, setSearchTerm] = useState("");
  const [showParticipantSelector, setShowParticipantSelector] = useState(false);

  const getParticipantsIds = useCallback(() => {
    if (
      initialEvent?.participants &&
      Array.isArray(initialEvent.participants)
    ) {
      return initialEvent.participants.map(
        (participant) => participant.client_id,
      );
    } else {
      return [];
    }
  }, [initialEvent]);
  const [selectedParticipants, setSelectedParticipants] = useState<
    (string | number)[]
  >(() => {
    const participants = getParticipantsIds();
    return participants;
  });

  const [formData, setFormData] = useState<
    Partial<CalendarEvent> & {
      time_start: string;
      time_end: string;
    }
  >(() => {
    if (initialEvent) {
      return {
        ...initialEvent,
        time_start: initialEvent.time_start || new Date().toISOString(),
        time_end:
          initialEvent.time_end ||
          new Date(
            new Date().setHours(new Date().getHours() + 1),
          ).toISOString(),
      };
    }
    return {
      event_type: "",
      title: "",
      time_start: initialDate || new Date().toISOString(),
      time_end: initialDate
        ? new Date(
            new Date(initialDate).setHours(
              new Date(initialDate).getHours() + 1,
            ),
          ).toISOString()
        : new Date(
            new Date().setHours(new Date().getHours() + 1),
          ).toISOString(),
      description: "",
      location: "",
      status: "scheduled",
      priority: "",
      participants: [],
    };
  });

  const filteredClients = clientsList.filter((client) => {
    const fullName =
      `${client.client_info.first_name} ${client.client_info.last_name}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    if (initialEvent) {
    }
  }, [initialEvent]);
  useEffect(() => {
    if (initialEvent?.participants) {
      const participants = getParticipantsIds();
      setSelectedParticipants(participants);
    }
  }, [initialEvent, setSelectedParticipants, getParticipantsIds]);

  useEffect(() => {
    setFormData((prev) => {
      return {
        ...prev,
        participants: selectedParticipants,
      } as typeof formData;
    });
  }, [selectedParticipants]);

  const handleParticipantToggle = (clientId: string | number) => {
    setSelectedParticipants((prev) => {
      const newParticipants = prev.includes(clientId)
        ? prev.filter((id) => id !== clientId)
        : [...prev, clientId];

      return newParticipants;
    });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleDateChange = (
    date: Date | null,
    field: "time_start" | "time_end",
  ) => {
    if (date) {
      setFormData((prev) => ({
        ...prev,
        [field]: date.toISOString(),
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const eventData = {
        ...formData,
        participants: selectedParticipants,
      };
      if (initialEvent) {
        await CrmService.updateCrmTaskCalendarEvent(
          eventData.event_id.toString(),
          eventData as CalendarEvent,
        );
      } else {
        await CrmService.addCrmTaskCalendarEvent(eventData as CalendarEvent);
      }
      onEventAdded();
      onClose();
    } catch (error) {
      console.error("Error saving event:", error);
    }
  };

  return (
    <div className={styles.popup}>
      <div className={styles.popupContent}>
        <div className={styles.closeIcon} onClick={onClose}>
          <X size={18} />
        </div>
        <h2 className={styles.titleDay}>
          {initialEvent ? t("crm.editEvent") : t("crm.newEvent")}
        </h2>

        <form onSubmit={handleSubmit} className={styles.eventForm} noValidate>
          <div className={styles.formGroup}>
            <label htmlFor="title">{t("crm.title")}*</label>
            <input
              type="text"
              id="title"
              name="title"
              required
              value={formData.title}
              onChange={handleChange}
              className={styles.input}
            />
          </div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>{t("crm.startTime")}*</label>
              <DatePicker
                selected={new Date(formData.time_start)}
                onChange={(date: Date) => handleDateChange(date, "time_start")}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd/MM/yyyy HH:mm"
                customInput={<CustomInput />}
                withPortal
                shouldCloseOnSelect={true}
              />
            </div>

            <div className={styles.formGroup}>
              <label>{t("crm.endTime")}*</label>
              <DatePicker
                selected={new Date(formData.time_end)}
                onChange={(date: Date) => handleDateChange(date, "time_end")}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd/MM/yyyy HH:mm"
                customInput={<CustomInput />}
                withPortal
                shouldCloseOnSelect={true}
              />
            </div>
          </div>

          {/* Updated Participants Section */}
          <div className={styles.formGroup}>
            {!initialEvent && (
              <>
                <label>{t("crm.participants")}*</label>
                <button
                  type="button"
                  className={styles.participantButton}
                  onClick={() =>
                    setShowParticipantSelector(!showParticipantSelector)
                  }
                >
                  {selectedParticipants.length > 0
                    ? `${selectedParticipants.length} ${t("crm.selectedParticipants")}`
                    : t("crm.selectParticipants")}
                </button>
              </>
            )}
            {initialEvent && <label>{t("crm.participants")}*</label>}
            {/* <label>{t("crm.participants")}*</label>
            <button
              type="button"
              className={styles.participantButton}
              onClick={() =>
                setShowParticipantSelector(!showParticipantSelector)
              }
            >
              {selectedParticipants.length > 0
                ? `${selectedParticipants.length} ${t("crm.selectedParticipants")}`
                : t("crm.selectParticipants")}
            </button> */}

            {/* Selected Participants Tags */}
            {selectedParticipants.length > 0 && (
              <div className={styles.selectedParticipantsList}>
                {selectedParticipants.map((participantId) => {
                  const client = clientsList.find(
                    (c) => c.client_id === participantId, //.client_id,
                  );
                  if (!client) return null;
                  return (
                    <div
                      key={participantId}
                      className={styles.selectedParticipant}
                    >
                      <span>
                        {client.client_info.first_name}{" "}
                        {client.client_info.last_name}
                      </span>
                      {!initialEvent && (
                        <button
                          type="button"
                          className={styles.removeParticipant}
                          onClick={() => handleParticipantToggle(participantId)}
                        >
                          <X size={14} />
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            {/* Participant Selector Dropdown */}
            {showParticipantSelector && (
              <div className={styles.participantSelector}>
                <div className={styles.searchContainer}>
                  <Search size={16} className={styles.searchIcon} />
                  <input
                    type="text"
                    placeholder={t("crm.searchParticipants")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={styles.searchInput}
                  />
                </div>

                <div className={styles.participantList}>
                  {filteredClients.map((client) => {
                    const isSelected = selectedParticipants.includes(
                      client.client_id,
                    );
                    return (
                      <div
                        key={client.client_id}
                        className={`${styles.participantItem} ${
                          isSelected ? styles.selected : ""
                        }`}
                        onClick={() =>
                          handleParticipantToggle(client.client_id)
                        }
                      >
                        <span>
                          {client.client_info.first_name}{" "}
                          {client.client_info.last_name}
                        </span>
                        {isSelected && (
                          <Check size={16} className={styles.checkIcon} />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="event_type">{t("crm.eventType")}*</label>
            {formData.event_type &&
            !["meeting", "call", "task", "custom"].includes(
              formData.event_type,
            ) ? (
              <input
                type="text"
                id="event_type"
                name="event_type"
                value={formData.event_type}
                onChange={handleChange}
                className={styles.input}
              />
            ) : (
              <select
                id="event_type"
                name="event_type"
                value={formData.event_type}
                onChange={handleChange}
                className={styles.input}
              >
                <option value="">{t("crm.selectType")}</option>
                <option value="meeting">{t("crm.meeting")}</option>
                <option value="call">{t("crm.call")}</option>
                <option value="task">{t("crm.task")}</option>
                <option value="custom">{t("crm.customType")}</option>
              </select>
            )}
            {formData.event_type === "custom" && (
              <input
                type="text"
                id="custom_event_type"
                name="event_type"
                placeholder={t("crm.enterCustomType")}
                onChange={handleChange}
                className={`${styles.input} ${styles.mt2}`}
              />
            )}
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="location">{t("crm.location")}</label>
            <div className={styles.locationInput}>
              <MapPin size={16} />
              <input
                type="text"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                className={styles.locationField}
              />
            </div>
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="description">{t("crm.description")}</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              className={styles.textarea}
              rows={3}
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="priority">{t("crm.priority")}</label>
            <select
              id="priority"
              name="priority"
              value={formData.priority}
              onChange={handleChange}
              className={styles.input}
            >
              <option value="">{t("crm.none")}</option>
              <option value="low">{t("crm.low")}</option>
              <option value="medium">{t("crm.medium")}</option>
              <option value="high">{t("crm.high")}</option>
            </select>
          </div>

          <div className={styles.formActions}>
            <button
              type="button"
              onClick={onClose}
              className={styles.closeButton}
            >
              {t("crm.cancel")}
            </button>
            <button type="submit" className={styles.submitButton}>
              {initialEvent ? t("crm.update") : t("crm.save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EventForm;
