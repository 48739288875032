import { NewThreadWidget } from "modules/pro_ai_assistant/NewThreadWidget";
import { ThreadHistoryWidget } from "modules/pro_ai_assistant/ThreadHistoryWidget";
import { useEffect } from "react";
import useProAssistantStore from "shared/store/proAssistantStore";

type Props = {
  expanded: boolean;
  setExpanded: (e: boolean) => void;
};

export const SideBar: React.FC<Props> = ({ expanded, setExpanded }) => {
  const { fetchThreads } = useProAssistantStore();

  useEffect(() => {
    fetchThreads();
  }, []);

  return (
    <>
      <aside
        className={
          "h-full overflow-hidden duration-500 " +
          (expanded ? "w-[312px]" : "w-[64px]")
        }
      >
        <div className="flex h-full grid-cols-2 flex-col flex-nowrap justify-stretch gap-x-4 gap-y-4">
          <NewThreadWidget expanded={expanded} />
          <ThreadHistoryWidget expanded={expanded} setExpanded={setExpanded} />
        </div>
      </aside>
    </>
  );
};
