import { Input } from "shared/components/ui/input";

type InputConstraintsProps = {
  configData: Record<
    string,
    {
      notEditable?: boolean;
      type?: string;
    }
  >;
  formValues: Record<string, string>;
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  notEditableValue?: string; // Made optional with ?
};

export const InputConstraints = ({
  configData,
  formValues,
  id,
  onChange,
  notEditableValue,
}: InputConstraintsProps) => {
  return !configData[id]?.notEditable ? (
    <>
      <Input
        id={id}
        value={formValues[id]}
        onChange={onChange}
        className="w-full"
        style={{ fontSize: 12 }}
      />
      {configData[id]?.type === "percentage" && <span>%</span>}
    </>
  ) : (
    <div
      style={{
        fontSize: 14,
        fontWeight: "bold",
        marginLeft: 38,
        marginTop: 10,
      }}
    >
      {notEditableValue}{" "}
      {configData[id]?.type === "percentage" && <span>%</span>}
    </div>
  );
};
