type Props = {
  className?: string;
};

export const SuggestedPromptIcon1: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1744_12774)">
        <path
          d="M7.93515 20.175C6.1445 19.9477 4.47943 19.1342 3.19965 17.8613C1.91253 16.5961 1.08026 14.9405 0.832651 13.1528C0.573759 11.3671 0.912878 9.54603 1.79715 7.97328C2.67197 6.39472 4.04457 5.15002 5.7009 4.43328C5.88236 4.3586 6.08587 4.35802 6.26775 4.43166C6.44964 4.50529 6.59542 4.64728 6.67383 4.82716C6.75224 5.00704 6.75703 5.21048 6.68717 5.39386C6.61731 5.57723 6.47837 5.72591 6.30015 5.80803C4.94517 6.39428 3.8223 7.41246 3.10665 8.70378C2.38321 9.99132 2.10531 11.4818 2.31615 12.9435C2.52 14.4058 3.20132 15.7597 4.25415 16.7948C5.30105 17.8356 6.66271 18.5012 8.12715 18.6878C9.59104 18.8825 11.0782 18.5877 12.3572 17.8493C13.6399 17.1189 14.6453 15.9852 15.2169 14.6243C15.2746 14.4874 15.3715 14.3706 15.4956 14.2889C15.6196 14.2071 15.7651 14.164 15.9137 14.1649C16.0622 14.1659 16.2071 14.2109 16.3301 14.2943C16.4531 14.3777 16.5485 14.4956 16.6044 14.6333C16.6413 14.7247 16.6599 14.8225 16.659 14.921C16.6581 15.0196 16.6377 15.1171 16.5992 15.2078C15.9004 16.8718 14.6718 18.2586 13.1042 19.1528C11.5407 20.0533 9.7237 20.4126 7.93515 20.175ZM9.00015 12.75C8.80124 12.75 8.61047 12.671 8.46982 12.5304C8.32917 12.3897 8.25015 12.1989 8.25015 12V4.50003C8.25015 4.30111 8.32917 4.11035 8.46982 3.9697C8.61047 3.82904 8.80124 3.75003 9.00015 3.75003C10.084 3.74728 11.1577 3.95951 12.159 4.37443C13.1603 4.78934 14.0694 5.39871 14.8337 6.16728C15.6017 6.93163 16.2106 7.84069 16.6251 8.84185C17.0397 9.84302 17.2516 10.9164 17.2486 12C17.2486 12.1989 17.1696 12.3897 17.029 12.5304C16.8883 12.671 16.6976 12.75 16.4987 12.75H9.0009H9.00015ZM9.75015 11.25H15.7089C15.5366 9.72912 14.8534 8.31165 13.771 7.22939C12.6886 6.14714 11.2711 5.46412 9.75015 5.29203V11.25Z"
          fill="#00549F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1744_12774">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
