// @ts-ignore
export const extractArtifactsData = (messages) => {
  let latestFinecoPortfolioConstraints = null;
  let finecoPortfolioList = [];
  let latestFinecoESGPreferences = null;
  let latestFinecoCRMContacts = null;

  const finecoEsgPreferences = [];
  const finecoConstraints = [];
  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];

    //** parse messages and store message artifacts info for activity panel */
    if (message.artifacts && Array.isArray(message.artifacts)) {
      for (let j = 0; j < message.artifacts.length; j++) {
        const artifact = message.artifacts[j];

        //** CONSTRAINTS */
        if (
          artifact.type === "custom_data" &&
          artifact.version === "fineco_portfolio_constraints"
        ) {
          // latestFinecoPortfolioConstraints = artifact.data;
          finecoConstraints.push({
            data: artifact.data,
            messageId: message.id,
            createdAt: message.created_at,
          });
        }

        //** ESG PREFERENCES */
        if (
          artifact.type === "custom_data" &&
          artifact.version === "fineco_esg_preferences"
        ) {
          // latestFinecoESGPreferences = {
          //   messageId: message.id,
          //   data: artifact.data,
          // };
          finecoEsgPreferences.push({
            data: artifact.data,
            messageId: message.id,
            createdAt: message.created_at,
          });
        }

        // Check for latest fineco_crm_contacts and store it
        if (
          artifact.type === "custom_data" &&
          artifact.version === "fineco_crm_contacts"
        ) {
          latestFinecoCRMContacts = {
            messageId: message.id,
            data: artifact.data,
          };
        }

        // Collect all fineco_portfolio types and store it
        if (artifact.type === "fineco_portfolio") {
          finecoPortfolioList.push(artifact);
        }
      }
    }
  }
  //** get latest  esg preferences */
  if (finecoEsgPreferences.length > 0) {
    finecoEsgPreferences.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
    latestFinecoESGPreferences = {
      messageId: finecoEsgPreferences[0].messageId,
      data: finecoEsgPreferences[0].data,
    };
  }
  //** get latest  constraints */
  if (finecoConstraints.length > 0) {
    finecoConstraints.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
    latestFinecoPortfolioConstraints = {
      messageId: finecoConstraints[0].messageId,
      data: finecoConstraints[0].data,
    };
  }

  return {
    latestFinecoPortfolioConstraints,
    latestFinecoESGPreferences,
    finecoPortfolioList,
    latestFinecoCRMContacts,
  };
};
