import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import { Labels } from "shared/components/hoc/Labels";
import { FaOnboardingService } from "shared/services/fa_onboarding/FaOnboardingService";
import { LetUsKnowYouColumn } from "./components/LetUsKnowYouColumn";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingPartProps } from "./OnboardingFlow";
import useOnboardingStore from "shared/store/onboardingStore";
import useAccountStore from "shared/store/accountStore";

export const AdvisorRegistrationNumber: React.FC<OnboardingPartProps> = ({
  onNext,
  onPrevious,
}) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const advisorRegistrationNumber = useOnboardingStore(
    (state) => state.advisorRegistrationNumber,
  );
  const { updateAdvisorRegistrationNumber } = useOnboardingStore();
  const { fetchAccount } = useAccountStore();

  const handleOnValueChange = (value: string) => {
    updateAdvisorRegistrationNumber(value);
  };

  const nextHandler = () => {
    setSubmitting(true);
    FaOnboardingService.postRegistrationNumber(advisorRegistrationNumber)
      .then(() => {
        fetchAccount();
        onNext?.();
      })
      .finally(() => setSubmitting(false));
  };

  const inputClassName =
    "peer block w-full appearance-none border-0 bg-sb-gray-100 px-4 py-2.5 text-base text-gray-900 focus:border-sb-green-700 focus:outline-none focus:ring-0 disabled:bg-sb-gray-100";

  return (
    <OnboardingContainer
      leftColumnContent={<LetUsKnowYouColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1 items-stretch space-y-2">
            <Labels.H2 className="mb-2">
              {t("forms.kyc.screen_titles.advisor_registration_number.title")}
            </Labels.H2>
            <Labels.B1 className="mb-6">
              {t(
                "forms.kyc.screen_titles.advisor_registration_number.sub_title",
              )}
            </Labels.B1>
            <input
              type="text"
              className={inputClassName + " + h-[52.5px] rounded-xl"}
              placeholder={"XXXXXXXXXX"}
              value={advisorRegistrationNumber}
              onChange={(e) => {
                handleOnValueChange(e.target.value);
              }}
            />
          </div>
          <OnboardingFooter
            loading={submitting}
            onNext={nextHandler}
            onPrev={() => onPrevious?.()}
            nextDisabled={!advisorRegistrationNumber}
          />
        </div>
      }
    />
  );
};
