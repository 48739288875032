import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BasePage } from "shared/components/common/BasePage";
import { DataTable } from "shared/components/common/datatable/DataTable";
import { Loading } from "shared/types/enums";
import { formatDateTime } from "shared/utils/date";
import { KycBadge } from "./KycBadge";
import { Panel } from "shared/components/hoc/Panels";
import { Labels } from "shared/components/hoc/Labels";
import { Client, ClientSummary } from "shared/models/client/ClientModel";
import { PendingTasks } from "modules/pending_tasks/PendingTasks";
import { TradingAccount } from "shared/models/brokerage/TradingAccountModel";
import { CurrencyFormat, pctFormat } from "shared/utils/currency";
import useClientsStore from "shared/store/clientsStore";

export const ClientListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { clients, loading: clientsLoading, fetchClients } = useClientsStore();

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <BasePage>
      <div>
        <div className="mb-12 flex items-center justify-between">
          <div>
            <Labels.H1>{t("clients.title")}</Labels.H1>
            <Labels.G1>
              {clients.length} {t("clients.countText")}
            </Labels.G1>
          </div>
        </div>
        <PendingTasks />
        <Panel className="mt-8">
          <DataTable
            loading={!clients.length && clientsLoading === Loading.InProgress}
            data={clients}
            columns={[
              "client_name",
              "risk_level",
              "kyc_status",
              "aum",
              "cash",
              "performance",
              "updated_at",
            ]}
            headerLabels={{
              client_name: t("clients.table.name"),
              risk_level: t("clients.table.riskProfile"),
              kyc_status: t("clients.table.kyc"),
              aum: t("clients.table.aum"),
              cash: t("clients.table.cash"),
              performance: t("clients.table.performance"),
              updated_at: t("clients.table.lastUpdate"),
            }}
            sortableValue={(key: string, entry: any) => {
              const summary = entry.summary as ClientSummary;
              const trading = entry?.trading as TradingAccount;

              if (key === "client_name") {
                return summary?.client_name;
              }

              if (key === "risk_level") {
                if (summary?.risk_level === "conservative") return 0;
                if (summary?.risk_level === "moderate") return 1;
                if (summary?.risk_level === "aggressive") return 2;
                return -1;
              }

              if (key === "kyc_status") {
                const kyc_status = summary?.kyc_status;
                if (kyc_status === "NOT_STARTED") return 0;
                if (kyc_status === "IN_PROGRESS") return 1;
                if (kyc_status === "COMPLETED") return 2;
                if (kyc_status === "REJECTED") return 3;
                if (kyc_status === "CLOSED") return 4;
                if (kyc_status === "ABANDONED") return 5;
                return -1;
              }
              if (key === "aum") {
                const aum = trading?.equity || "0";
                return +aum;
              }
              if (key === "cash") {
                const cash = trading?.cash || "0";
                return +cash;
              }
              if (key === "performance") {
                const performance = summary?.performance;
                return +performance;
              }
              if (key === "updated_at") {
                const date = summary?.updated_at;
                return date;
              }
              return undefined;
            }}
            cellFormatter={(key: string, value: string, entry: any) => {
              const summary = entry.summary as ClientSummary;
              const trading = entry?.trading as TradingAccount;
              if (key === "client_name") {
                return summary.client_name;
              }
              if (key === "risk_level") {
                return summary.risk_level;
              }
              if (key === "kyc_status") {
                const kyc_status = summary.kyc_status;
                return <KycBadge status={kyc_status} />;
              }
              if (key === "aum") {
                const aum = trading?.equity || "0";
                return CurrencyFormat.format(aum);
              }
              if (key === "cash") {
                const cash = trading?.cash || "0";
                return CurrencyFormat.format(cash);
              }
              if (key === "performance") {
                const performance = summary.performance;
                return (
                  <div
                    className={
                      performance < 0 ? "text-sb-red-700" : "text-sb-green-700"
                    }
                  >
                    {pctFormat.format(performance)}
                  </div>
                );
              }
              if (key === "updated_at") {
                const date = summary.updated_at;
                return formatDateTime(date);
              }
              return value;
            }}
            onRowClicked={(item: Client) => {
              navigate(`/clients/${item.user_id}`);
            }}
          />
        </Panel>
      </div>
    </BasePage>
  );
};
