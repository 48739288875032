// GeneralInfo.jsx
import React from "react";
import { Home, Phone, Mail, Wallet, Info } from "lucide-react";
import styles from "./generalInfo.module.css";
import { ClientDetailType } from "types/crm/ClientTypes";
type GeneralInfoProps = {
  clientData: ClientDetailType;
};
export const GeneralInfo: React.FC<GeneralInfoProps> = ({ clientData }) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>General info</h3>

      <div className={styles.infoList}>
        <div className={styles.infoRow}>
          <div className={styles.leftSide}>
            <Info size={20} />
            <span>Status</span>
          </div>
          <div className={styles.statusBadge}>
            {clientData.client_info.status}
          </div>
        </div>

        <div className={styles.infoRow}>
          <div className={styles.leftSide}>
            <Home size={20} />
            <span>Address</span>
          </div>
          <div
            className={styles.address}
            onClick={() =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  clientData.client_info.address,
                )}`,
                "_blank",
              )
            }
            style={{ cursor: "pointer" }}
          >
            {clientData.client_info.address}
          </div>
        </div>

        <div className={styles.infoRow}>
          <div className={styles.leftSide}>
            <Phone size={20} />
            <span>Phone number</span>
          </div>
          <div
            onClick={() =>
              (window.location.href = `tel:${clientData.client_info.phone}`)
            }
            style={{ cursor: "pointer" }}
          >
            {clientData.client_info.phone}
          </div>
        </div>

        <div className={styles.infoRow}>
          <div className={styles.leftSide}>
            <Mail size={20} />
            <span>Email</span>
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              (window.location.href = `mailto:${clientData.client_info.email}`)
            }
          >
            {clientData.client_info.email}
          </div>
        </div>

        <div className={styles.infoRow}>
          <div className={styles.leftSide}>
            <Wallet size={20} />
            <span>TFA</span>
          </div>
          <div>{clientData.client_info.tfa}</div>
        </div>
      </div>
    </div>
  );
};
