import { ClientHeader } from "modules/client_header/ClientHeader";
import { InitialInvestment } from "modules/initial_investment_form/InitialInvestment";
import { PortfolioBacktestingChart } from "modules/portfolio_backtesting_chart/PortfolioBacktestingChart";
import { PortfolioComposition } from "modules/portfolio_composition/PortfolioComposition";
import { PortfolioProjectionChart } from "modules/portfolio_projection_chart/PortfolioProjectionChart";
import { ProposalHeader } from "modules/proposals/ProposalHeader";
import { RecurrentInvestment } from "modules/proposals/RecurrentInvestment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BasePage } from "shared/components/common/BasePage";
import { Button } from "shared/components/controls/Button";
import { Panel } from "shared/components/hoc/Panels";
import { Client } from "shared/models/client/ClientModel";
import { ClientsService } from "shared/services/clients/ClientsService";
import {
  ProposalPayload,
  ProposalsService,
} from "shared/services/proposals/ProposalsService";
import { FrequencyType } from "shared/types/RecurringInvestmentModel";

import usePortfoliosStore from "shared/store/portfoliosStore";
import { ProposalPortfolioSelector } from "../components/ProposalPortfolioSelector";

export const CreateProposalPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { clientId } = useParams();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [initialAmount, setInitialAmount] = useState<string | undefined>();
  const [recurringAmount, setRecurringAmount] = useState<string | undefined>();
  const [recurringFrequency, setRecurringFrequency] = useState<
    string | undefined
  >("month");

  const [portfolioId, setPortfolioId] = useState<string | undefined>();
  const portfolio = usePortfoliosStore((state) =>
    state.portfolios.find((p) => p.id === portfolioId),
  );
  const { fetchPortfolios } = usePortfoliosStore();

  const [client, setClient] = useState<Client>();

  useEffect(() => {
    if (!portfolio?.composition_id && portfolioId) {
      fetchPortfolios();
    }
  }, [portfolio?.composition_id, portfolioId]);

  useEffect(() => {
    if (clientId) {
      ClientsService.getById(clientId).then(setClient);
    }
  }, [clientId]);

  useEffect(() => {
    if (searchParams.get("p")) {
      setPortfolioId(searchParams.get("p") || undefined);
    }
    if (searchParams.get("i")) {
      setInitialAmount(searchParams.get("i") || undefined);
    }
    if (searchParams.get("ra")) {
      setRecurringAmount(searchParams.get("ra") || undefined);
    }
    if (searchParams.get("rf")) {
      setRecurringFrequency(searchParams.get("rf") || undefined);
    }
    if (searchParams.get("d")) {
      setDescription(searchParams.get("d") || "");
    }
  }, [searchParams]);

  const handleClearForm = () => {
    setInitialAmount("");
    setRecurringAmount("");
    setRecurringFrequency("");
  };

  const disableSendingProposal =
    !portfolio?.id ||
    !initialAmount ||
    !recurringAmount ||
    !recurringFrequency ||
    !description;

  const createProposal = async () => {
    if (
      !clientId ||
      !description ||
      !portfolio?.composition_id ||
      !initialAmount ||
      !recurringAmount ||
      !recurringFrequency
    )
      return undefined;

    setLoading(true);

    const payload: ProposalPayload = {
      client_id: clientId,
      initial_deposit: initialAmount,
      recurring_deposit: recurringAmount,
      recurring_deposit_frequency: recurringFrequency,
      readme: description,
    };

    payload.strategy_id = portfolio.id;

    try {
      await ProposalsService.createProposal(payload);
      navigate("/proposals");
      toast.success(t("proposals.proposalCreated"));
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasePage>
      <div className="mb-10">
        <ProposalHeader
          disableSendingProposal={disableSendingProposal}
          loading={loading}
          onClearForm={handleClearForm}
          onCreateProposal={createProposal}
        />
      </div>
      <div className="space-y-8">
        <Panel>
          <ClientHeader client={client} />
        </Panel>
        <Panel>
          <div className="mb-4 text-xl font-medium">
            {t("global.description")}
          </div>
          <textarea
            placeholder={t("proposals.descriptionPlaceholder")}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="min-h-32 h-32 w-full overflow-hidden rounded-xl border border-sb-gray-200 px-4 py-2 focus:border-sb-green-700 focus:outline-none"
          />
        </Panel>
        <div className="flex flex-wrap justify-between gap-8">
          <div className="flex-1">
            <InitialInvestment
              value={initialAmount}
              onChange={(value) => setInitialAmount(value)}
            />
          </div>
          <div className="flex-1">
            <RecurrentInvestment
              value={recurringAmount}
              freqValue={recurringFrequency}
              onFrequencyChange={setRecurringFrequency}
              onChange={(value) => setRecurringAmount(value)}
            />
          </div>
        </div>
        <ProposalPortfolioSelector
          portfolioId={portfolioId}
          onPortfolioIdChange={setPortfolioId}
          //onCreateNewPortfolioHandler={switchToCreateNewPortfolio}
        />
        {portfolio ? (
          <>
            <Panel className="mt-8">
              <div className="mb-8 text-xl font-medium">
                {t("global.allocations")}
              </div>
              <PortfolioComposition portfolio={portfolio} />
            </Panel>
            <PortfolioBacktestingChart portfolio={portfolio} />
            <PortfolioProjectionChart
              portfolioId={portfolio?.id}
              frequency={recurringFrequency as FrequencyType}
              recurringAmount={recurringAmount}
              duration={240}
              initialAmount={initialAmount}
            />
          </>
        ) : null}
        <div className="text-right">
          <Button
            disabled={disableSendingProposal}
            onClick={createProposal}
            label="proposals.createProposal"
            loading={loading}
          />
        </div>
      </div>
    </BasePage>
  );
};
