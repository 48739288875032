import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "shared/components/ui/dialog";
import { DownloadIcon, ExpandIcon } from "lucide-react";
import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useParams } from "react-router";
import { AssistantService } from "shared/services/AssistantService";
import { AssistantAttachment } from "types/assistant/AssistantTypes";

type Props = {
  attachment: AssistantAttachment;
  onFinishLoading?: () => void;
};
const maxSvgHeight = "1000px";
export const SvgAttachment = forwardRef<{ expandSvg: () => void }, Props>(
  ({ attachment, onFinishLoading }, ref) => {
    useImperativeHandle(ref, () => ({
      expandSvg: () => {
        openDialog();
      },
      downloadSvg: () => {
        download();
      },
    }));

    const dialogTriggerRef = useRef(null);

    const openDialog = () => {
      dialogTriggerRef.current?.click();
    };

    const params = useParams();
    const threadId = params.threadId;
    const [content, setContent] = useState("");
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (threadId) {
        AssistantService.downloadAttachment(
          threadId,
          attachment.attachment_id,
        ).then(async (response) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const svgContent = reader.result as string;
            setContent(svgContent);
            onFinishLoading?.();
          };
          reader.readAsText(response.data);
        });
      }
    }, [attachment.attachment_id, threadId, onFinishLoading]);

    useEffect(() => {
      if (content && containerRef.current) {
        const svgElement = containerRef.current.querySelector("svg");
        if (svgElement) {
          const viewBox = svgElement.getAttribute("viewBox");
          if (viewBox) {
            const [, , width, height] = viewBox.split(" ").map(Number);
            const aspectRatio = width / height;
            containerRef.current.style.paddingBottom = `${
              (1 / aspectRatio) * 100
            }%`;
          }
          svgElement.setAttribute("width", "100%");
          svgElement.setAttribute("height", "100%");
          svgElement.setAttribute("preserveAspectRatio", "xMinYMin meet");
        }
      }
    }, [content]);

    const download = async () => {
      const fileName = attachment.attachment_name;
      const attachmentId = attachment.attachment_id;
      if (threadId) {
        const response = await AssistantService.downloadAttachment(
          threadId,
          attachmentId,
        );

        const link = document.createElement("a");
        link.href = URL.createObjectURL(response.data);
        link.download = fileName;
        link.click();
      }
    };

    if (!content) {
      return null;
    }

    return (
      <>
        <div
          id={attachment.attachment_id.toString()}
          className="svg-attachment-scroll-margin"
        ></div>
        <div
          style={{
            position: "relative",
            width: "100%",
            maxWidth: maxSvgHeight, // Limit the width to keep it manageable
            overflow: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              right: "16px",
              top: "16px",
              zIndex: 20,
              display: "flex",
              gap: "8px",
            }}
          >
            <ExpandDialog triggerRef={dialogTriggerRef}>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </ExpandDialog>
          </div>
          <div
            ref={containerRef}
            style={{
              position: "relative",
              width: "100%",
              maxHeight: maxSvgHeight, // Set a maximum height of 600px for the SVG container
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                maxHeight: maxSvgHeight, // Keep the SVG itself within the max height
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start", // Align the SVG content to the left
              }}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      </>
    );
  },
);

const ExpandDialog = ({ children, triggerRef }) => {
  return (
    <Dialog>
      <DialogTrigger ref={triggerRef}></DialogTrigger>
      <DialogContent className="h-fit max-h-[90vh] w-fit max-w-[90vw] overflow-auto">
        <DialogTitle></DialogTitle>
        <div className="mt-4">{children}</div>
      </DialogContent>
    </Dialog>
  );
};

export default ExpandDialog;
