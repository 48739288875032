import PortfolioConfigData from "config/portfolio-config.json";
import React from "react";
import PortfolioItem from "../../PortfolioItem";

type SectionChildrenProps = {
  reportData: any;
};
const DistribuzioneTipologiaStrumentiContainer: React.FC<
  SectionChildrenProps
> = ({ reportData }) => {
  if (!reportData) {
    return <></>;
  }

  return (
    <div style={{ marginLeft: 10, marginBottom: 30, marginTop: 40 }}>
      <PortfolioItem
        dataItem={{
          data: reportData.scomposizione_portafoglio.distribuzione_tipologia,
          configData:
            PortfolioConfigData.scomposizione_portafoglio.data
              .distribuzione_tipologia,
        }}
      />
    </div>
  );
};
export default React.memo(
  DistribuzioneTipologiaStrumentiContainer,
  (prevProps: SectionChildrenProps, nextProps: SectionChildrenProps) => {
    // Custom comparison function
    return prevProps.reportData === nextProps.reportData;
  },
);
