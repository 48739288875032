import { useTranslation } from "react-i18next";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Watchlist } from "shared/models/watchlist/WatchlistModel";
import useWatchlistsStore from "shared/store/watchlistsStore";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  watchlist: Watchlist;
};

export const DeleteWatchlistModal: React.FC<Props> = ({
  open,
  setOpen,
  watchlist,
}) => {
  const { t } = useTranslation();
  const { remove } = useWatchlistsStore();

  const deleteWatchlist = () => {
    remove(watchlist.id);
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Labels.H3 className="mb-2 text-center">
        {t("watchlist.deleteQuestion", { name: watchlist.name })}
      </Labels.H3>
      <div className="mt-4 flex items-center justify-between gap-4">
        <Button
          label="global.no"
          btnStyle="blank"
          onClick={() => setOpen(false)}
          className="w-full"
        />
        <Button
          label="global.yes"
          onClick={deleteWatchlist}
          className="w-full"
        />
      </div>
    </Modal>
  );
};
