type Currencies = string;

export type Number = string | number;

type CurrencyPrecision = "high" | "low";

// https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
// Also, the performance of both is the same for a single item, but if you have a lot of numbers to format,
// using Intl.NumberFormat is ~70 times faster.
// Therefore, it's usually best to use Intl.NumberFormat and instantiate only once per page load. Anyway,
// here's the equivalent usage of toLocaleString:

const CurrencyFormats: Record<Currencies, Intl.NumberFormat> = {
  USD: new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }),
  EUR: new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }),
  CHF: new Intl.NumberFormat("de-CH", {
    style: "currency",
    currency: "CHF",
  }),
  GBP: new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }),
};

const CurrencyFormatterNoPrecision: Record<Currencies, Intl.NumberFormat> = {
  USD: new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }),
  EUR: new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  }),
  CHF: new Intl.NumberFormat("de-CH", {
    style: "currency",
    currency: "CHF",
    minimumFractionDigits: 0,
  }),
  GBP: new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 0,
  }),
};

export const convertToNumber = (
  value: Number | undefined,
  defaultValue: number,
): number => {
  if (!value) return defaultValue;
  try {
    if (typeof value === "string") return parseFloat(value);
    return value;
  } catch (error) {
    return defaultValue;
  }
};

export const convertToNumbers = (
  ...values: (Number | undefined)[]
): number[] => {
  return values.map((v) => convertToNumber(v, 0));
};

export const convertCurrency = (
  value: Number | undefined,
  defaultValue: number = 0,
  precision: CurrencyPrecision = "high",
  currency?: Currencies,
): string => {
  const _number = convertToNumber(value, defaultValue);

  if (precision === "high" && _number > 0 && _number < 0.1) {
    return `$${_number.toFixed(10).replace(/\.?0+$/, "")}`;
  }
  // @ts-ignore
  return CurrencyFormats[currency || "USD"].format(_number);
};

export const convertCurrencyWithDecimalCheck = (
  value: Number | undefined,
  defaultValue: number = 0,
  precision: CurrencyPrecision = "high",
  currency?: Currencies,
) => {
  const v = convertToNumber(value, defaultValue);
  const hasDecimal = v % 1 !== 0 ? 2 : 0;
  return hasDecimal
    ? convertCurrency(value, defaultValue, precision, currency)
    : // @ts-ignore
      CurrencyFormatterNoPrecision[currency || "USD"].format(v);
};

export const convertPercentage = (value: number) => {
  return `${value >= 0 ? "+" : ""}${(value * 100).toFixed(2)}%`;
};

export const convertQty = (input: Number | undefined) => {
  const value = convertToNumber(input, 0);
  if (value > 0 && value < 1) return Math.ceil(value * 100000) / 100000;
  return Math.ceil(value * 100) / 100;
};

export const convertFullName = (tokens: (string | undefined)[]) => {
  return tokens
    .map((n) => (n ? n.trim() : ""))
    .filter((n) => !!n)
    .join(" ");
};
