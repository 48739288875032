import PortfolioConfigData from "config/portfolio-config.json";
import React from "react";
import PortfolioItem from "../PortfolioItem";

type SectionChildrenProps = {
  reportData: any;
};
const AssetAllocationImplicita: React.FC<SectionChildrenProps> = ({
  reportData,
}) => {
  if (!reportData) {
    return <></>;
  }
  return (
    <div style={{ marginLeft: 10, marginBottom: 30 }}>
      {/* **********CONTENT ********** */}
      {/* asset_allocation_per_macro_class */}
      <div className="mt-[10px]"></div>
      <PortfolioItem
        dataItem={{
          data: reportData.implicit_asset_allocation
            .asset_allocation_per_macro_class,
          configData:
            PortfolioConfigData.implicit_asset_allocation.data
              .asset_allocation_per_macro_class,
        }}
      />
      {/* asset_allocation_per_class */}
      <PortfolioItem
        dataItem={{
          data: reportData.implicit_asset_allocation.asset_allocation_per_class,
          configData:
            PortfolioConfigData.implicit_asset_allocation.data
              .asset_allocation_per_class,
        }}
      />
      {/* factors */}
      <PortfolioItem
        dataItem={{
          data: reportData.implicit_asset_allocation.factors,
          configData:
            PortfolioConfigData.implicit_asset_allocation.data.factors,
        }}
      />
    </div>
  );
};
export default React.memo(
  AssetAllocationImplicita,
  (prevProps: SectionChildrenProps, nextProps: SectionChildrenProps) => {
    // Custom comparison function
    return prevProps.reportData === nextProps.reportData;
  },
);
