import { useEffect } from "react";
import useRealtimePricesStore from "shared/store/realtimePricesStore";
import useRealtimeTickerSubscriptionsStore from "shared/store/realtimeTickerSubscriptionsStore";
import { auth } from "shared/utils/firebase";
import { useRealtimePrices } from "./useRealtimePrices";

export default function useRealtimeTicker(symbol: string) {
  const prices = useRealtimePrices(symbol);

  const { subscribe, unsubscribe } = useRealtimeTickerSubscriptionsStore();
  const { unsubscribe: unsubscribePrices } = useRealtimePricesStore();
  const { subscribed_to_realtime } = prices;

  useEffect(() => {
    if (!subscribed_to_realtime) {
      auth.onAuthStateChanged((user) => {
        if (user) {
          subscribe([symbol]);
        }
      });
    }
  }, [symbol, subscribed_to_realtime]);

  useEffect(() => {
    return () => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          unsubscribe([symbol]);
          unsubscribePrices(symbol);
        }
      });
    };
  }, []);

  return prices;
}
