import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import colorsStore from "shared/store/colorsStore";

type MonthlyDistributionProps = {
  data: Array<{
    label: string;
    weight: number;
  }>;
};

const MonthlyDistributionChart: React.FC<MonthlyDistributionProps> = ({
  data,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);

  const maxWeight = Math.max(...data.map((item) => item.weight));
  const buffer = 0.05; // Fixed buffer of 0.05
  const adjustedMaxWeight = Math.ceil((maxWeight + buffer) * 10) / 10; // Round to next 0.1

  const formattedData = data.map((item) => ({
    month: item.label,
    value: item.weight,
  }));

  return (
    <div className="h-[400px] w-full" style={{ marginTop: 10 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={formattedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="month" tick={{ fill: colorsConfig.black }} />
          <YAxis
            domain={[0, adjustedMaxWeight]}
            ticks={Array.from(
              { length: Math.ceil(adjustedMaxWeight / 0.05) + 1 },
              (_, i) => Number((i * 0.05).toFixed(2)),
            )}
            tick={{ fill: colorsConfig.black }}
          />
          <Tooltip
            formatter={(value: number) => [value.toFixed(2), "Distribuzione"]}
            cursor={{ fill: "transparent" }}
          />
          <Bar
            dataKey="value"
            fill={colorsConfig.finecoBluePrimary}
            radius={[2, 2, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MonthlyDistributionChart;
