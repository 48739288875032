import { AssistantTaskUpdate } from "types/assistant/AssistantTypes";
import { Processing } from "./Processing";
import colorsStore from "shared/store/colorsStore";

type Props = {
  taskUpdate?: AssistantTaskUpdate;
};

export const ChatTaskUpdate: React.FC<Props> = ({ taskUpdate }) => {
  return null;
};
