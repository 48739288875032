import { EditAllocations } from "modules/portfolio_edit_allocations/EditAlllocations";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BasePage } from "shared/components/common/BasePage";
import { Labels } from "shared/components/hoc/Labels";
import usePortfoliosStore from "shared/store/portfoliosStore";

// TODO remove this when we have portfolio assistant and new edit
export const EditPortfolioPage = () => {
  const { portfolioId } = useParams();
  const portfolio = usePortfoliosStore((state) =>
    state.portfolios.find((el) => el.id === portfolioId),
  );
  const { fetchPortfolioById } = usePortfoliosStore();
  const { t } = useTranslation();

  useEffect(() => {
    if (!portfolio && portfolioId) {
      fetchPortfolioById(portfolioId);
    }
  }, [portfolio, portfolioId]);

  if (!portfolio) {
    return null;
  }

  return (
    <BasePage>
      <Labels.H1>{t("faPortfolio.editAllocation")}</Labels.H1>
      <EditAllocations portfolio={portfolio} />
    </BasePage>
  );
};
