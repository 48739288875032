import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Proposal } from "shared/models/proposals/ProposalsModel";
import { EditMenu } from "./ProposalEditMenu";
import { ProposalsService } from "shared/services/proposals/ProposalsService";
import { AvatarImage } from "shared/components/common/AvatarImage";
import { Labels } from "shared/components/hoc/Labels";
import { formatDateTime } from "shared/utils/date";
import { capitalize } from "shared/utils/utils";
import useProposalsStore from "shared/store/proposalsStore";

type Props = {
  proposal: Proposal;
};

function removeNonAlphanumeric(str: string) {
  return str.replace(/[^a-zA-Z0-9]/g, "");
}

export const EditablePortfolioNames: React.FC<Props> = ({ proposal }) => {
  const { t } = useTranslation();
  const [saveLoading, setSaveLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const { fetchProposalById } = useProposalsStore();

  const [strategySymbol, setStrategySymbol] = useState(
    proposal.strategy_symbol,
  );
  const [strategyTitle, setStrategyTitle] = useState(proposal.strategy_title);

  useEffect(() => {
    if (!editing) {
      setStrategyTitle(proposal.strategy_title);
      setStrategySymbol(proposal.strategy_symbol);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  const isSymbolValid = () => {
    return strategySymbol.length >= 4;
  };

  const isTitleValid = () => {
    return strategyTitle.length >= 10;
  };

  const onSaveClicked = () => {
    setSaveLoading(true);
    ProposalsService.patchPortfolios(proposal.proposal_id, {
      strategy_symbol: strategySymbol,
      strategy_title: strategyTitle,
    })
      .then(() => {
        fetchProposalById(proposal.proposal_id);
        setEditing(false);
        setSaveLoading(false);
        toast.success(t("faPortfolio.portfolioUpdated"));
      })
      .finally(() => setSaveLoading(false));
  };

  const getError = () => {
    if (!isSymbolValid() || !isTitleValid())
      return t("faPortfolio.validationErrors");
    return undefined;
  };

  const formattedStatus = () => {
    if (!proposal) return "";
    if (proposal?.status === "sent") {
      return t("proposals.waitingForApproval");
    }
    return capitalize(proposal?.status);
  };

  return (
    <>
      <div className="relative">
        {saveLoading && (
          <div className="absolute bottom-0 left-0 right-0 top-0 bg-white opacity-50" />
        )}
        <div className="mb-4 mt-8 flex items-center gap-8">
          <AvatarImage
            imageSrc={proposal?.strategy_cover?.uri}
            width={80}
            height={80}
          />
          <div className="w-full">
            <div className="w-full flex-grow text-lg text-sb-gray-500">
              {editing ? (
                <input
                  type="text"
                  value={strategySymbol}
                  className="mb-2 rounded-lg border border-sb-gray-300 px-2 outline-none"
                  onChange={(e) =>
                    setStrategySymbol(
                      removeNonAlphanumeric(
                        (e.target.value || "").toUpperCase(),
                      ),
                    )
                  }
                  required
                />
              ) : (
                proposal?.strategy_symbol
              )}
            </div>
            <div className="flex w-full items-center gap-4">
              <Labels.H1 className={editing ? "w-full" : "w-fit"}>
                {editing ? (
                  <input
                    type="text"
                    value={strategyTitle}
                    className="mb-2 w-full rounded-lg border border-sb-gray-300 px-2 outline-none"
                    onChange={(e) => setStrategyTitle(e.target.value)}
                    required
                  />
                ) : (
                  proposal?.strategy_title
                )}
              </Labels.H1>
              {proposal.status === "draft" && !saveLoading && (
                <EditMenu
                  editing={editing}
                  setEditing={setEditing}
                  onSaveClicked={onSaveClicked}
                  error={getError}
                  className="ml-2 pb-2"
                  hideErrorMessage
                />
              )}
            </div>
            <div className="flex items-center gap-8">
              <div className="tracking wide my-auto w-fit rounded-full bg-sb-cyan-700 px-4 text-lg font-semibold text-white">
                {formattedStatus().toUpperCase()}
              </div>
              {editing && (
                <div className="text-sb-red-700">
                  {!isTitleValid() && (
                    <div>{t("faPortfolio.invalidTitle")}</div>
                  )}
                  {!isSymbolValid() && (
                    <div>{t("faPortfolio.invalidSymbol")}</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {proposal ? (
          <div className="text-lg text-gray-600">
            {formatDateTime(proposal?.created_at || "")}
          </div>
        ) : null}
      </div>
    </>
  );
};
