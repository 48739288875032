import {
  AssistantActionType,
  AssistantEsgFormDataType,
} from "types/assistant/AssistantTypes";
import { create } from "./store";

type PortfolioEditorStoreState = {
  activateForm: boolean;
  activatePortfolioEditor: boolean;
  actionForm: AssistantActionType;
  formUserData: AssistantEsgFormDataType;
};

type PortfolioEditorStoreActions = {
  setActivateForm: (activateForm: boolean) => void;
  setActivatePortfolioEditor: (activePortfolioEditor: boolean) => void;
  setActionForm: (action: AssistantActionType) => void;
  setFormUserData: (formData: AssistantEsgFormDataType) => void;
  resetFormStore: () => void;
};

const initialData: PortfolioEditorStoreState = {
  activateForm: false,
  activatePortfolioEditor: false,
  actionForm: null,
  formUserData: {
    userHasFilled: false,
    questions: {
      question_1: null,
      question_2: null,
      question_3: null,
      question_4: null,
      question_5: null,
      question_6: null,
    },
  },
};

const usePortfolioEditorStore = create<
  PortfolioEditorStoreState & PortfolioEditorStoreActions
>()((set, get) => ({
  ...initialData,
  setActivateForm: (activate) => {
    set({ activateForm: activate });
  },
  setActivatePortfolioEditor: (activate) => {
    set({ activatePortfolioEditor: activate });
  },
  setActionForm: (action) => {
    set({ actionForm: action });
  },
  setFormUserData: (formData) => {
    set({ formUserData: formData });
  },
  resetFormStore: () => {
    set(initialData);
  },
}));

export default usePortfolioEditorStore;
