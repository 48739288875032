import {
  TopClientPerformers,
  TopPortfolioPerformers,
} from "shared/models/top_performances/TopPerformancesModel";
import { TopPerformancesService } from "shared/services/top_performances/TopPerformancesService";
import { Loading } from "shared/types/enums";
import { create } from "zustand";

type TopPerformancesStoreState = {
  clients: TopClientPerformers;
  portfolios: TopPortfolioPerformers;
  clientsLoading: Loading;
  portfoliosLoading: Loading;
};

type TopPerformancesStoreActions = {
  fetchTopClients: () => void;
  fetchTopPortfolios: () => void;
};

const initialData: TopPerformancesStoreState = {
  clients: [],
  portfolios: [],
  clientsLoading: Loading.Idle,
  portfoliosLoading: Loading.Idle,
};

const useTopPerformancesStore = create<
  TopPerformancesStoreState & TopPerformancesStoreActions
>()((set) => ({
  ...initialData,
  fetchTopClients: async () => {
    set({ clientsLoading: Loading.InProgress });
    try {
      const clients = await TopPerformancesService.fetchTopClients();
      set({ clients });
    } finally {
      set({ clientsLoading: Loading.Finished });
    }
  },
  fetchTopPortfolios: async () => {
    set({ portfoliosLoading: Loading.InProgress });
    try {
      const portfolios = await TopPerformancesService.fetchTopPortfolios();
      set({ portfolios });
    } finally {
      set({ portfoliosLoading: Loading.Finished });
    }
  },
}));

export default useTopPerformancesStore;
