import { useTranslation } from "react-i18next";
import { PortfolioSimulationPeriod } from "shared/models/portfolio/PortfolioModel";

export type StrategyPeriods = {
  label: string;
  value: PortfolioSimulationPeriod;
}[];

export const useStrategyPeriods = () => {
  const { t } = useTranslation();

  const periods: StrategyPeriods = [
    {
      label: t("graph_periods.1m"),
      value: "last_month",
    },
    {
      label: t("graph_periods.3m"),
      value: "last_three_months",
    },
    {
      label: t("graph_periods.6m"),
      value: "last_six_months",
    },
    {
      label: t("graph_periods.1y"),
      value: "last_year",
    },
    {
      label: t("graph_periods.all"),
      value: "all_time",
    },
  ];

  return periods;
};
