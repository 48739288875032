import { ScrollArea } from "shared/components/ui/scroll-area";
import { useParams } from "react-router-dom";
import { Widget } from "shared/components/ui/widget";
import { HeaderPanel } from "../HeaderPanel";
import activityPanelStore from "shared/store/activityPanelStore";
import { PortfolioItem } from "./components/PortfolioItem";
import { FinecoPortfolioArtifactType } from "types/assistant/AssistantTypes";
import colorsStore from "shared/store/colorsStore";

type Props = {
  expanded: boolean;
  setExpanded: (e: boolean) => void;
};

export const PortfoliosPanel: React.FC<Props> = ({ expanded, setExpanded }) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  const params = useParams();
  const threadId = params.threadId;

  const finecoPortfolioArtifacts = activityPanelStore(
    (state) => state.finecoPortfolioArtifacts[threadId],
  );

  const children = () => {
    return (
      <div className="h-full space-y-8">
        {finecoPortfolioArtifacts?.map((finecoPortfolioArtifact, idx) => {
          return (
            <PortfolioItem
              finecoPortfolioArtifact={
                finecoPortfolioArtifact as FinecoPortfolioArtifactType
              }
              key={idx}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      <HeaderPanel
        expanded={expanded}
        setExpanded={setExpanded}
        title={"menu.attachments"}
      />
      <ScrollArea
        className={"flex-2 relative max-h-full min-h-[60px] rounded-xl pt-12"}
        style={{
          backgroundColor: colorsConfig.secondBackground
            ? colorsConfig.secondBackground
            : null,
          borderColor: colorsConfig.borderColor
            ? colorsConfig.borderColor
            : colorsConfig.secondBackground,
          borderWidth: "1px",
          borderStyle: "solid",
        }}
      >
        <Widget className="relative">
          {expanded && (
            <div className="relative space-y-4 text-xs lg:text-base ">
              {children()}
            </div>
          )}
        </Widget>
      </ScrollArea>
    </>
  );
};
