import styles from "./finecoButton.module.css";
import classNames from "classnames";

type FinecoButtonProps = {
  click: () => void;
  copy: string;
  disabled?: boolean;
  white?: boolean;
};
export const FinecoButton: React.FC<FinecoButtonProps> = ({
  click,
  copy,
  disabled = false,
  white = false,
}) => {
  return (
    <div
      className={classNames(
        classNames(styles.button, white ? styles.whiteVariant : null),
        disabled ? styles.notAllowed : styles.allowed,
      )}
      onClick={disabled ? undefined : click}
    >
      {copy}
    </div>
  );
};
