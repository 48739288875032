import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAssets from "shared/hooks/useAssets";
import { TradingOrder } from "shared/models/trading/TradingOrderModel";
import { OrdersService } from "shared/services/orders/OrdersService";
import useClientDetailsStore from "shared/store/clientDetailsStore";
import { CurrencyFormat } from "shared/utils/currency";
import { formatDateTime, formatDate } from "shared/utils/date";
import { capitalize } from "shared/utils/utils";

type Props = {
  order: TradingOrder;
  clientId?: string;
};

export const OrderDetails: React.FC<Props> = ({ order, clientId }) => {
  const { t } = useTranslation();
  const { fetchOrders, fetchPositions } = useClientDetailsStore();
  const { assets_map } = useAssets();

  const cancelOrder = (id: string) => {
    if (clientId) {
      OrdersService.cancel(id, clientId).then(() => {
        if (clientId) {
          fetchOrders(clientId, 10);
          fetchPositions(clientId);
        }
        toast.success(t("orders.orderCanceled"));
      });
    }
  };

  return (
    <>
      <div className="mt-4">
        <div className="text-xl font-medium">{order.symbol}</div>
        <div className="text-base">
          {
            // @ts-ignore
            assets_map[order.symbol] ? assets_map[order.symbol].name : ""
          }
        </div>
        <div className="mx-2 mb-8 mt-2">
          <div className="flex items-center justify-between border-b border-sb-gray-200 py-2">
            <div className="text-sb-gray-600">{t("orders.side")}</div>
            <div>{capitalize(order.side)}</div>
          </div>
          <div className="flex items-center justify-between border-b border-sb-gray-200 py-2">
            <div className="text-sb-gray-600">{t("orders.type")}</div>
            <div>{t(`trade.orderTypes.options.${order.type}.label`)}</div>
          </div>
          {order.notional && (
            <div className="flex items-center justify-between border-b border-sb-gray-200 py-2">
              <div className="text-sb-gray-600">
                {t("orders.notionalAmount")}
              </div>
              <div>{CurrencyFormat.format(order.notional)}</div>
            </div>
          )}
          {order.qty && (
            <div className="flex items-center justify-between border-b border-sb-gray-200 py-2">
              <div className="text-sb-gray-600">{t("orders.singleShares")}</div>
              <div>{order.qty}</div>
            </div>
          )}
          {order.limit_price && (
            <div className="flex items-center justify-between border-b border-sb-gray-200 py-2">
              <div className="text-sb-gray-600">
                {t("trade.limitPrice.label")}
              </div>
              <div>{CurrencyFormat.format(order.limit_price)}</div>
            </div>
          )}
          <div className="flex items-center justify-between border-b border-sb-gray-200 py-2">
            <div className="text-sb-gray-600">{t("orders.submitted")}</div>
            <div>{formatDateTime(order.created_at)}</div>
          </div>
          <div className="flex items-center justify-between border-b border-sb-gray-200 py-2">
            <div className="text-sb-gray-600">{t("orders.status")}</div>
            <div>{capitalize(order.status)}</div>
          </div>
          {order.filled_at && (
            <div className="flex items-center justify-between border-b border-sb-gray-200 py-2">
              <div className="text-sb-gray-600">{t("orders.filledAt")}</div>
              <div>{formatDate(order.filled_at)}</div>
            </div>
          )}
          {order.filled_qty && (
            <div className="flex items-center justify-between border-b border-sb-gray-200 py-2">
              <div className="text-sb-gray-600">
                {t("orders.filledQuantity")}
              </div>
              <div>{order.filled_qty}</div>
            </div>
          )}
          {order.filled_avg_price && (
            <div className="flex items-center justify-between border-b border-sb-gray-200 py-2">
              <div className="text-sb-gray-600">
                {t("orders.filledAvgPrice")}
              </div>
              <div>{CurrencyFormat.format(order.filled_avg_price)}</div>
            </div>
          )}
          {order.filled_avg_price && order.filled_qty ? (
            <div className="flex items-center justify-between border-b border-sb-gray-200 py-2">
              <div className="text-sb-gray-600">{t("orders.filledValue")}</div>
              <div>
                {CurrencyFormat.format(
                  order.filled_avg_price * order.filled_qty,
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {(order.status === "new" || order.status === "accepted") &&
          order.origin !== "strategy" && (
            <div
              className="my-8 cursor-pointer text-center text-sb-green-1001"
              onClick={(e) => {
                e.stopPropagation();
                cancelOrder(order.id);
              }}
            >
              {t("global.cancel")}
            </div>
          )}
      </div>
    </>
  );
};
