import { PropsWithChildren } from "react";
import { ScrollArea } from "./scroll-area";
import colorsStore from "shared/store/colorsStore";

type Props = PropsWithChildren & {
  className?: string;
  scrollable?: boolean;
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;

export const Widget: React.FC<Props> = ({
  children,
  className,
  scrollable,
  ...props
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  return (
    <div
      // className={`rounded-xl bg-gray-100 p-2 dark:bg-card lg:p-4 ${className}`}
      className={`rounded-xl p-2 lg:p-4 ${className}`}
      {...props}
      style={{
        backgroundColor: colorsConfig.secondBackground
          ? colorsConfig.secondBackground
          : null,
      }}
    >
      {scrollable && <ScrollArea>{children}</ScrollArea>}
      {!scrollable && children}
    </div>
  );
};
