type Props = {
  className?: string;
  style?: React.CSSProperties;
  isExpanded?: boolean;
};

export const ConstraintsChevronIcon: React.FC<Props> = ({
  className,
  style,
  isExpanded,
}) => {
  return (
    <svg
      className={className}
      style={{
        ...style,
        transform: isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
        transition: "transform 0.3s ease",
      }}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.05204 11.999C7.82171 11.999 7.60069 11.9081 7.43704 11.746L2.13404 6.499C1.96867 6.33576 1.87493 6.11352 1.87343 5.88116C1.87193 5.6488 1.9628 5.42537 2.12604 5.26C2.28928 5.09464 2.51152 5.0009 2.74388 4.9994C2.97624 4.9979 3.19967 5.08876 3.36504 5.252L8.04604 9.887L12.627 5.258C12.7079 5.17612 12.8042 5.11098 12.9102 5.06629C13.0163 5.0216 13.1301 4.99824 13.2452 4.99754C13.3603 4.99685 13.4744 5.01883 13.581 5.06223C13.6876 5.10563 13.7847 5.16961 13.8665 5.2505C13.9484 5.3314 14.0136 5.42763 14.0582 5.5337C14.1029 5.63977 14.1263 5.7536 14.127 5.8687C14.1277 5.9838 14.1057 6.09791 14.0623 6.20451C14.0189 6.31111 13.9549 6.40812 13.874 6.49L8.67404 11.739C8.51097 11.904 8.28904 11.9976 8.05704 11.999H8.05204Z"
        fill="#00549F"
      />
    </svg>
  );
};
