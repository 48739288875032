import PortfolioConfigData from "config/portfolio-config.json";
import React from "react";
import PortfolioItem from "../PortfolioItem";
import PortfolioEvolutionChart from "../PortfolioEvolutionChart";
import PortfolioAnalysisTable from "../PortfolioAnalysisTable";
import styles from "./../../finecoPortfolioArtifact.module.css";
import classNames from "classnames";

type SectionChildrenProps = {
  reportData: any;
  ef_data: any;
  artifact: any;
};
const AnalisiRischioRendimentoAtteso: React.FC<SectionChildrenProps> = ({
  reportData,
  ef_data,
  artifact,
}) => {
  if (!reportData) {
    return <></>;
  }
  const dataT =
    reportData.analisi_rischio_rendimento_atteso.risk_return_statistics;
  const adaptedReportDataRischioRendimento = () => {
    const adaptedData = [];
    for (let key in dataT) {
      adaptedData.push({
        label: key,
        weight: dataT[key],
      });
    }
    return adaptedData;
  };
  const adaptedData = adaptedReportDataRischioRendimento();
  const selectedPlan = ef_data[artifact.data?.financial_plan - 1];
  const portfolio = {
    risk: artifact.data?.var * 100,
    return: artifact.data?.return * 100,
  };
  const configData = PortfolioConfigData.analisi_rischio_rendimento_atteso.data;
  return (
    <div style={{ marginLeft: 10, marginBottom: 30, marginTop: 10 }}>
      {/* **********CONTENT ********** */}
      <PortfolioItem
        dataItem={{
          data: adaptedData,
          configData: configData.risk_return_statistics,
        }}
      />
      <h3 className={classNames(styles.subTitle, styles.subTitleMargin)}>
        {configData.ibbotson_cone_chart.label}
      </h3>
      <PortfolioEvolutionChart
        data={
          reportData.analisi_rischio_rendimento_atteso.ibbotson_cone_chart.data
        }
      />
      <div className="mt-[60px]"></div>
      <h3 className={classNames(styles.subTitle, styles.subTitleMargin)}>
        {configData.ibbotson_cone_table.label}
      </h3>
      <PortfolioAnalysisTable
        data={
          reportData.analisi_rischio_rendimento_atteso.ibbotson_cone_table.data
        }
      />
    </div>
  );
};
export default React.memo(
  AnalisiRischioRendimentoAtteso,
  (prevProps: SectionChildrenProps, nextProps: SectionChildrenProps) => {
    // Custom comparison function
    return prevProps.reportData === nextProps.reportData;
  },
);
