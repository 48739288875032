import { t } from "i18next";
import { useRef, useState } from "react";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { WatchlistsService } from "shared/services/watchlists/WatchlistsService";
import useWatchlistsStore from "shared/store/watchlistsStore";

type Props = {
  symbol?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};
export const CreateWatchlistModal: React.FC<Props> = ({
  open,
  setOpen,
  symbol,
}) => {
  const { fetchWatchlists } = useWatchlistsStore();
  const watchlistRef = useRef<HTMLInputElement>(null);
  const [createLoading, setCreateLoading] = useState(false);

  const onWatchlistCreate = () => {
    if (watchlistRef && watchlistRef.current?.value) {
      const watchlistName = watchlistRef.current?.value;
      setCreateLoading(true);
      WatchlistsService.post({
        name: watchlistName,
        symbols: symbol ? [symbol] : [],
      })
        .then(() => {
          setOpen(false);
          fetchWatchlists();
        })
        .finally(() => setCreateLoading(false));
    }
  };
  return (
    <Modal open={open} setOpen={setOpen}>
      <Labels.H3 className="mb-2">{t("watchlist.create")}</Labels.H3>
      <div className="mb-2">{t("watchlist.createDesc")}</div>
      <input
        ref={watchlistRef}
        type="text"
        className="w-full rounded-lg border border-sb-gray-100 p-4"
        placeholder={t("watchlist.createPlaceholder")}
      />
      <div className="mt-4 flex gap-4">
        <Button
          disabled={createLoading}
          className="w-full"
          btnStyle="blank"
          label="global.cancel"
          onClick={() => setOpen(false)}
        />
        <Button
          loading={createLoading}
          className="w-full"
          label="global.create"
          onClick={onWatchlistCreate}
        />
      </div>
    </Modal>
  );
};
