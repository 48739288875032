import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "shared/components/ui/table";
import styles from "./performanceTable.module.css";

const PerformanceTable = ({ dataObject }) => {
  if (!dataObject.data) return null;

  const formatValue = (value) => {
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      value === 0 ||
      value === "0.00"
    )
      return "";
    return value.toFixed(2).replace(".", ",");
  };

  const getValueColor = (value) => (value < 0 ? "red" : "black");

  return (
    <div
      className="w-full"
      style={{
        paddingLeft: 0,
        paddingRight: 10,
        paddingBottom: 10,
        marginTop: 20,
        // backgroundColor: "white",
        // borderRadius: 8,
        // boxShadow: "0px 4px 8px rgba(28, 34, 38, 1)",
      }}
    >
      <div
        className={styles.performanceTable}
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 10,
          backgroundColor: "white",
          borderRadius: 8,
          boxShadow: "0px 4px 18px rgba(28, 34, 38, 0.1)",
        }}
      >
        <Table className={`${styles.table} min-w-[400px]`}>
          <TableHeader>
            <TableRow>
              <TableHead colSpan={4}></TableHead>
              <TableHead colSpan={3} className="text-right">
                Rendimento
              </TableHead>
              <TableHead colSpan={2} className="text-right">
                Volatilità ann.
              </TableHead>
            </TableRow>
            <TableRow>
              <TableHead className="font-bold">Nome</TableHead>
              <TableHead>Divisa</TableHead>
              <TableHead>Peso (%)</TableHead>
              <TableHead>Microcategoria Advice</TableHead>
              <TableHead className="text-right">YtD</TableHead>
              <TableHead className="text-right">1Y</TableHead>
              <TableHead className="text-right">3Y</TableHead>
              <TableHead className="text-right">1Y</TableHead>
              <TableHead className="text-right">3Y</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow className="border-b-2 border-black">
              <TableCell>FONDI ED ETF</TableCell>
              <TableCell></TableCell>
              <TableCell className="text-right">100,0</TableCell>
              <TableCell colSpan={6}></TableCell>
            </TableRow>
            {dataObject.data.map((row, index) => (
              <TableRow key={index}>
                <TableCell className="truncate">{row.nome}</TableCell>
                <TableCell>{row.divisa}</TableCell>
                <TableCell className="text-right">
                  {formatValue(row.peso)}
                </TableCell>
                <TableCell>{row.microcategoria_advice}</TableCell>
                <TableCell
                  className="text-right"
                  style={{ color: getValueColor(row.rendimento_YTD) }}
                >
                  {formatValue(row.rendimento_YTD)}
                </TableCell>
                <TableCell
                  className="text-right"
                  style={{ color: getValueColor(row.rendimento_1Y) }}
                >
                  {formatValue(row.rendimento_1Y)}
                </TableCell>
                <TableCell
                  className="text-right"
                  style={{ color: getValueColor(row.rendimento_3Y) }}
                >
                  {formatValue(row.rendimento_3Y)}
                </TableCell>
                <TableCell
                  className="text-right"
                  style={{ color: getValueColor(row.volatilita_1Y) }}
                >
                  {formatValue(row.volatilita_1Y)}
                </TableCell>
                <TableCell
                  className="text-right"
                  style={{ color: getValueColor(row.volatilita_3Y) }}
                >
                  {formatValue(row.volatilita_3Y)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default PerformanceTable;
