import React, { useEffect, useState } from "react";
import { Eye, ChevronLeft, ChevronRight, Download } from "lucide-react";
import styles from "./suggestionsTableWidget.module.css";
import crmStore from "shared/store/crmStore";
import crmStyles from "./../../crmPage.module.css";
import { mapPriority } from "utils/misc";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const SuggestionsTableWidget = () => {
  const { t } = useTranslation();
  // const { clientPriorityContacts, fetchClientPriorityContacts } = crmStore(
  //   (state) => ({
  //     clientPriorityContacts: state.clientPriorityContacts,
  //     fetchClientPriorityContacts: state.fetchClientPriorityContacts,
  //   }),
  // );
  const { clientPriorityContacts, fetchClientPriorityContacts } = crmStore();

  useEffect(() => {
    fetchClientPriorityContacts();
  }, []);

  const ITEMS_PER_PAGE = 3;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(clientPriorityContacts.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentClients = clientPriorityContacts.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((current) => current - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((current) => current + 1);
    }
  };

  const getPriorityClass = (priority: string) => {
    const classes = {
      urgent: crmStyles.urgentPriority,
      urgente: crmStyles.urgentPriority,
      Urgente: crmStyles.urgentPriority,
      high: crmStyles.highPriority,
      alta: crmStyles.highPriority,
      Alta: crmStyles.highPriority,
      medium: crmStyles.mediumPriority,
      media: crmStyles.mediumPriority,
      Media: crmStyles.mediumPriority,
      low: crmStyles.lowPriority,
      bassa: crmStyles.lowPriority,
      Bassa: crmStyles.lowPriority,
    } as any;
    return classes[priority] || "";
  };

  const navigate = useNavigate();
  const callClient = (clientId: string) => {
    navigate(`/crm/clients/${clientId}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t("crm.clientsToBeContacted")}</h2>
        <div className={styles.headerRight}>
          <span className={styles.counter}>
            {startIndex + 1} -{" "}
            {Math.min(endIndex, clientPriorityContacts.length)} out of{" "}
            {clientPriorityContacts.length}
          </span>
          <div className={styles.navigation}>
            <button
              className={styles.navButton}
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <ChevronLeft size={20} />
            </button>
            <button
              className={styles.navButton}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <ChevronRight size={20} />
            </button>
          </div>
        </div>
      </div>
      {currentClients.length === 0 && (
        <div className={crmStyles.noContent}>{t("crm.noContentAvailable")}</div>
      )}
      {currentClients.length > 0 && (
        <div className={styles.clientsList}>
          {currentClients.map((client, index) => {
            const priorita: any = mapPriority[client.priority];
            return (
              <div
                key={client.client.client_id.toString() + index}
                className={styles.clientCard}
              >
                <div className={styles.clientInfo}>
                  <div
                    className={styles.nameSection}
                    onClick={() => {
                      callClient(client.client.client_id.toString());
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Eye size={16} className={styles.eyeIcon} />
                    <div>
                      <div className={styles.clientName}>
                        {client.client.first_name} {client.client.last_name}
                      </div>
                      <div className={styles.clientAmount}>
                        €{client.client.tfa}
                      </div>
                    </div>
                  </div>
                  <div className={styles.messageSection}>
                    <p className={styles.message}>{client.notes}</p>
                    <div
                      className={`${
                        crmStyles.priorityTagContainer
                      } ${getPriorityClass(client.priority)}`}
                    >
                      {priorita.charAt(0).toUpperCase() + priorita.slice(1)}
                    </div>
                  </div>
                  <button className={styles.downloadButton}>
                    <Download size={16} />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
