import { useEffect, useRef, useState } from "react";
import useAppStatus from "shared/store/appStatusStore";

export const TableWrapper = ({ children }) => {
  const containerRef = useRef(null);
  const [width, setWidth] = useState("100%");
  const appStatus = useAppStatus((state) => state.appStatus);
  const windowW = useAppStatus((state) => state.windowW);

  useEffect(() => {
    let adaptChatMainResize = windowW < 1050 ? 1071 : windowW;
    let containerWidth = adaptChatMainResize - 95 + 30 - 520;
    if (appStatus === "idle") {
      adaptChatMainResize = windowW < 650 ? 671 : windowW;
      containerWidth = adaptChatMainResize - 95 - 90;
    }
    setWidth(`${containerWidth}px`);
  }, [appStatus, windowW]);

  return (
    <div
      ref={containerRef}
      className="w-full"
      style={{ border: "1px solid #e5e7eb", borderRadius: "8px" }}
    >
      <div style={{ width, overflow: "auto" }}>
        <table className="min-w-full">{children}</table>
      </div>
    </div>
  );
};
