import PortfolioConfigData from "config/portfolio-config.json";
import ComplexTable from "../ComplexTable";
import React from "react";
import styles from "./../../finecoPortfolioArtifact.module.css";

type SectionChildrenProps = {
  reportData: any;
};
const Portafoglio: React.FC<SectionChildrenProps> = ({ reportData }) => {
  if (!reportData) {
    return <></>;
  }

  return (
    <div style={{ marginLeft: 10, marginBottom: 40 }}>
      {/* <h3
        className="mt-[20px] text-lg font-semibold"
        style={{ color: colorsConfig.finecoBlack }}
      >
        {PortfolioConfigData.portfolio.data.strumenti_fondi.label}
      </h3> */}
      <h3 className={styles.subTitle}>
        {PortfolioConfigData.portfolio.data.strumenti_fondi.label}
      </h3>
      <ComplexTable data={reportData.portafoglio.strumenti_fondi} />
    </div>
  );
};
export default React.memo(
  Portafoglio,
  (prevProps: SectionChildrenProps, nextProps: SectionChildrenProps) => {
    // Custom comparison function
    return prevProps.reportData === nextProps.reportData;
  },
);
