import { create } from "./store";
import { AssetClass } from "shared/models/asset/AssetModel";
import {
  TradingOrderType,
  CreateTradingOrder,
  Side,
  TakeProfit,
  StopLoss,
} from "shared/models/trading/TradingOrderModel";
import { AmountType } from "shared/utils/types";

interface CreateOrderSettings {
  buy?: { orderType: TradingOrderType; amountType: AmountType };
  sell?: { orderType: TradingOrderType; amountType: AmountType };
}

export interface InitializeOrderPayload {
  symbol: string;
  assetClass: AssetClass;
  side: Side;
  type: TradingOrderType;
  allowNotional: boolean;
  take_profit?: TakeProfit;
  stop_loss?: StopLoss;
}
interface UpdateOrderTypePayload {
  side: Side;
  orderType: TradingOrderType;
}

interface UpdateAmountTypePayload {
  side: Side;
  amountType: AmountType;
}

type CreateOrderStoreState = {
  settings?: CreateOrderSettings;
  order?: CreateTradingOrder;
};

type CreateOrderStoreActions = {
  updateOrderType: (payload: UpdateOrderTypePayload) => void;
  updateAmountType: (payload: UpdateAmountTypePayload) => void;
  initialize: (payload: InitializeOrderPayload) => void;
  update: (payload: Partial<CreateTradingOrder>) => void;
};

const initialData: CreateOrderStoreState = {
  settings: {
    buy: { orderType: "limit", amountType: "qty" },
    sell: { orderType: "limit", amountType: "qty" },
  },
};

const initializeOrder = (
  state: CreateOrderStoreState,
  {
    symbol,
    side,
    type,
    allowNotional,
    assetClass,
    take_profit,
    stop_loss,
  }: InitializeOrderPayload,
): CreateTradingOrder => {
  const time_in_force = assetClass === "crypto" ? "gtc" : "day";
  switch (type) {
    case "market":
      return {
        symbol,
        side,
        type,
        time_in_force,
        ...(allowNotional
          ? // if notional is allowed then we are populating amount based on settings amount type
            {
              ...(state.settings && state.settings[side]?.amountType === "cash"
                ? { notional: "0" }
                : { qty: "0" }),
            }
          : { qty: "0" }),
      };
    case "limit":
      // here we keep limit_price undefined so we can use realtime mid price from ask/bid spread
      return {
        symbol,
        side,
        type,
        time_in_force: "gtc",
        take_profit,
        stop_loss,
        limit_price: undefined,
        qty: "0",
      };
    case "stop":
      return {
        symbol,
        side,
        type,
        time_in_force: "gtc",
        stop_price: "0",
        qty: "0",
      };
    case "trailing_stop":
      return {
        symbol,
        side,
        type,
        time_in_force: "gtc",
        trail_percent: "0",
        qty: "0",
      };
    default:
      return { symbol, side, type: "market", time_in_force };
  }
};

const useCreateOrderStore = create<
  CreateOrderStoreState & CreateOrderStoreActions
>()((set, get) => ({
  ...initialData,
  updateOrderType: ({ side, orderType }) => {
    const { settings } = get();
    if (!settings) {
      return;
    }
    set({
      settings: {
        ...settings,
        [side]: {
          ...settings[side],
          orderType,
        },
      },
    });
  },
  updateAmountType: ({ side, amountType }) => {
    const { settings } = get();
    if (!settings) {
      return;
    }
    set({
      settings: {
        ...settings,
        [side]: {
          ...settings[side],
          amountType,
        },
      },
    });
  },
  initialize: (payload) => {
    const order = initializeOrder(get(), payload);
    set({ order });
  },
  update: (payload) => {
    const { order } = get();
    if (!order) {
      return;
    }
    set({ order: { ...order, ...payload } });
  },
}));

export default useCreateOrderStore;
