export const CrmRoutes = {
  //** CRM */
  CLIENTS_LIST: "/pro/api/v1/crm/clients",
  CLIENT_DETAIL: (clientId: string) => `/pro/api/v1/crm/clients/${clientId}`,
  CLIENT_INTERESTS: (client_id: string) =>
    `/pro/api/v1/crm/clients/${client_id}/interests`,
  CLIENT_RELATIONSHIPS: (client_id: string) =>
    `/pro/api/v1/crm/clients/${client_id}/relationships`,
  CLIENT_INTEREST_ID: (interest_id: string) =>
    `/pro/api/v1/crm/interests/${interest_id}`,
  CLIENT_RELATIONSHIP_ID: (relationship_id: string) =>
    `/pro/api/v1/crm/relationships/${relationship_id}`,
  CRM_TASKS: `/pro/api/v1/crm/tasks`,
  CRM_TASKS_CALENDAR: `/pro/api/v1/crm/tasks-calendar`,
  CRM_TASKS_CALENDAR_EVENT: `/pro/api/v1/crm/calendar-events`,
  CRM_TASKS_CALENDAR_EVENT_ID: (event_id: string) =>
    `/pro/api/v1/crm/calendar-events/${event_id}`,
  CRM_TASKS_CALENDAR_EVENT_NOTIFICATIONS: (event_id: string) =>
    `/pro/api/v1/crm/calendar-events/${event_id}/notifications`,
  CRM_TASKS_CALENDAR_EVENT_NOTIFICATION_ID: (
    event_id: string,
    notification_id: string,
  ) =>
    `/pro/api/v1/crm/calendar-events/${event_id}/notifications/${notification_id}`,
  CRM_ALERTS: `/pro/api/v1/crm/alerts`,
  CRM_GOALS: `/pro/api/v1/crm/goals`,
  CRM_PRIORITY_CONTACTS: `/pro/api/v1/crm/priority-contacts`,
  CRM_ASSISTANT_TASKS: `/pro/api/v1/crm/assistant-tasks`,
  CRM_CAMPAIGNS_SUMMARY: `/pro/api/v1/crm/campaigns/summary`,
  CRM_CAMPAIGN_PARTICIPANTS: (campaign_id: string) =>
    `/pro/api/v1/crm/campaigns/${campaign_id}/participants`,
  CRM_NOTES: "/pro/api/v1/crm/notes",
  CRM_NOTE_ID: (note_id: number) => `/pro/api/v1/crm/notes/${note_id}`,
  CRM_PARTICIPANTS: "/pro/api/v1/crm/participants",
  CRM_DEALS: "/pro/api/v1/crm/deals",
  CRM_DEAL_ID: (deal_id: string) => `/pro/api/v1/crm/deals/${deal_id}`,
};
