import { AccountModel } from "shared/models/account/AccountModel";
import { Loading } from "shared/types/enums";
import { PendingTasks } from "shared/models/pending_tasks/PendingTasksModel";
import { AccountService } from "shared/services/account/AccountService";
import { create } from "./store";

type AccountStoreState = {
  account: AccountModel | null | undefined;
  accountLoading: Loading;
  pendingTasks: {
    tasks: PendingTasks[];
    loading: Loading;
  };
};

type AccountStoreActions = {
  fetchAccount: () => Promise<void>;
  fetchPendingTasks: () => void;
};

const initialData: AccountStoreState = {
  account: undefined,
  accountLoading: Loading.Idle,
  pendingTasks: {
    tasks: [],
    loading: Loading.Idle,
  },
};

const useAccountStore = create<AccountStoreState & AccountStoreActions>()(
  (set) => ({
    ...initialData,
    fetchAccount: async () => {
      set({ accountLoading: Loading.InProgress });
      try {
        const account = await AccountService.getMe();
        set({ account });
      } finally {
        set({ accountLoading: Loading.Finished });
      }
    },
    fetchPendingTasks: async () => {
      set({ pendingTasks: { tasks: [], loading: Loading.InProgress } });
      try {
        const tasks = await AccountService.getPendingTasks();
        set({ pendingTasks: { tasks, loading: Loading.Finished } });
      } catch {
        set({ pendingTasks: { tasks: [], loading: Loading.Finished } });
      }
    },
  }),
);

export default useAccountStore;
