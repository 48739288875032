import { ProAssistantAttachment } from "shared/models/pro_assistant/ProAssistantModel";
import { PaperclipIcon } from "lucide-react";
import { ImageAttachment } from "../attachments/ImageAttachment";
import { TableAttachment } from "../attachments/TableAttachment";

type Props = {
  attachment: ProAssistantAttachment;
};

export const AttachmentResponse: React.FC<Props> = ({ attachment }) => {
  let attachmentComponent = null;
  if (attachment.content_type.startsWith("image/")) {
    attachmentComponent = <ImageAttachment attachment={attachment} />;
  } else if (attachment.content_type === "text/csv") {
    attachmentComponent = <TableAttachment attachment={attachment} />;
  }

  return (
    <div className="flex items-start gap-4">
      <AttachmentIcon />
      <div className="w-full">
        <div className="mb-4 text-lg font-bold">
          {attachment.attachment_name}
        </div>
        {attachmentComponent}
      </div>
    </div>
  );
};

export const AttachmentIcon: React.FC = () => {
  return (
    <div className="size-8 grid shrink-0 place-items-center rounded-full border border-gray-600">
      <PaperclipIcon className="size-4 text-gray-600" />
    </div>
  );
};
