type Props = {
  className?: string;
};

export const DownloadAttachmentIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.75 17.75V20.75C21.75 21.1478 21.592 21.5294 21.3107 21.8107C21.0294 22.092 20.6478 22.25 20.25 22.25H9.75C9.35218 22.25 8.97064 22.092 8.68934 21.8107C8.40804 21.5294 8.25 21.1478 8.25 20.75V17.75"
        stroke="#00549F"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 13.9988L15 17.7488L18.75 13.9988"
        stroke="#00549F"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 17.75V8.75"
        stroke="#00549F"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
