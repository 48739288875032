import useThreadsStore from "shared/store/threadsStore";
import activityPanelStore from "shared/store/activityPanelStore";
import appStatusStore from "shared/store/appStatusStore";
import { NavigateFunction } from "react-router-dom"; // Add this import
export const createNewChat = (navigate: NavigateFunction) => {
  useThreadsStore.getState().setFromHistory(false);
  appStatusStore.getState().setAppStatus("idle");
  activityPanelStore.getState().resetStore();
  navigate("/assistant");
};
