import { useTranslation } from "react-i18next";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import { cn } from "utils";
import PortfolioCoverImage from "./artifacts/PortfolioCoverImage";
import { pctFormat } from "shared/utils/currency";
import { Skeleton } from "shared/components/common/Skeleton";
import { EditPortfolioDialog } from "./EditPortfolioDialog";
import { PencilIcon } from "lucide-react";
import CompositionPieChart from "./CompositionpieChart";

type Props = {
  portfolio?: Portfolio;
  active: boolean;
  sessionId: string;
  onClick?: (portfolioId: string) => void;
};

export const SessionPortfolioHeader: React.FC<Props> = ({
  portfolio,
  sessionId,
  active,
  onClick,
}) => {
  const { t } = useTranslation();
  const cagr = +(portfolio?.simulated_metrics.all_time.cagr || 0);

  return (
    <>
      <div
        className={cn(
          "rounded-3xl p-4",
          onClick ? "cursor-pointer duration-150 hover:opacity-60" : "",
          active
            ? "cursor-default bg-sb-green-100 hover:opacity-100"
            : "bg-sb-gray-100",
        )}
        onClick={() => onClick?.(portfolio?.id || "")}
      >
        {portfolio ? (
          <div className="mb-4 flex items-center justify-between gap-2">
            <div className="flex items-start gap-2">
              <PortfolioCoverImage
                uri={portfolio?.cover?.uri}
                wrapperClassName="border-gray-90 bg-gray-90 border-[6px] shrink-0"
              />
              <div>
                <div className="pr-14 text-lg font-semibold text-black">
                  {portfolio?.title}
                </div>
                <div className="flex items-center gap-2 text-[12px]">
                  <span
                    className={cn(
                      "text-base",
                      cagr >= 0 ? "text-green-500" : "",
                    )}
                  >
                    {pctFormat.format(cagr)}
                  </span>
                  {t("dashboard.yearlyAvg").toUpperCase()}{" "}
                </div>
              </div>
            </div>
            <EditPortfolioDialog portfolio={portfolio} sessionId={sessionId}>
              {!onClick && (
                <div className="shrink-0 cursor-pointer rounded-full p-2">
                  <div className="grid place-items-center">
                    <PencilIcon className="aspect-square h-3 text-gray-500" />
                  </div>
                </div>
              )}
            </EditPortfolioDialog>
          </div>
        ) : (
          <div className="my-2 flex justify-center">
            <Skeleton />
          </div>
        )}
        {portfolio?.allocations && (
          <CompositionPieChart allocations={portfolio.allocations} />
        )}
      </div>
    </>
  );
};
