import React from "react";
import colorsStore from "shared/store/colorsStore";
type Props = {
  direction: string;
  classNameInner: string;
  classNameOuter: string;
};

export const ChatGradient: React.FC<Props> = ({
  direction,
  classNameInner,
  classNameOuter,
}) => {
  const colorsConfig = colorsStore((state) => state.colorsConfigStore);
  return (
    <div className={classNameOuter}>
      <div
        className={classNameInner}
        style={{
          background: `linear-gradient(${direction}, ${colorsConfig.white}, transparent)`,
        }}
      ></div>
    </div>
  );
};
