import { PropsWithChildren } from "react";
import { ChevronButton } from "../../shared/components/common/ChevronButton";
import React from "react";
import { Panel } from "../../shared/components/hoc/Panels";
import { ScrollArea } from "shared/components/ui/scroll-area";

type Props = PropsWithChildren & {
  className?: string;
  title: string;
  expanded: boolean;
  setExpanded: (e: boolean) => void;
};

export const ExpandableWidget: React.FC<Props> = ({
  className,
  title,
  expanded,
  setExpanded,
  children,
}) => {
  if (!expanded) {
    return (
      <Panel className={className}>
        <div className="">
          <div className="flex flex-nowrap items-center justify-center gap-4">
            <div className="" onClick={() => setExpanded(!expanded)}>
              <ChevronButton expanded={expanded} />
            </div>
          </div>
        </div>
      </Panel>
    );
  }

  return (
    <ScrollArea
      className={"flex-2 relative min-h-[60px] rounded-3xl bg-white pt-12"}
    >
      <div className="absolute left-4 right-4 top-4 bg-white">
        <div
          className={
            "flex items-center gap-4 " +
            (expanded ? "justify-between" : "justify-center")
          }
        >
          <div>
            <h3
              className={
                expanded
                  ? "visible"
                  : "hidden" + " text-gray-600 dark:text-gray-500"
              }
            >
              {title}
            </h3>
          </div>
          <div onClick={() => setExpanded(!expanded)}>
            <ChevronButton expanded={expanded} />
          </div>
        </div>
      </div>
      {expanded && (
        <div
          className="relative space-y-4 p-4 text-base"
          style={{
            maxHeight: "calc(100dvh - 250px)",
            minHeight: "calc(100dvh - 250px)",
          }}
        >
          {children}
        </div>
      )}
    </ScrollArea>
  );
};
