import React from "react";
import YieldSummaryTable from "../YieldSummaryTable";
import MonthlyDistributionChart from "../MonthlyDistributionChart";
import PortfolioConfigData from "config/portfolio-config.json";
import styles from "./../../finecoPortfolioArtifact.module.css";

type SectionChildrenProps = {
  reportData: any;
};

const CaratteristicaDiPortafoglio: React.FC<SectionChildrenProps> = ({
  reportData,
}) => {
  if (!reportData) {
    return null;
  }

  const configData = PortfolioConfigData.caratteristiche_di_portafoglio.data;

  return (
    <div style={{ marginLeft: 10, marginBottom: 30 }}>
      <div className="grid grid-cols-2 gap-8">
        <div>
          <h3 className={styles.subTitle}>{configData.yield_summary.label}</h3>
          <div style={{ marginTop: 100 }}></div>
          <YieldSummaryTable
            dataObject={reportData.caratteristiche_di_portafoglio.yield_summary}
          />
        </div>
        <div>
          <h3 className={styles.subTitle}>
            {configData.distribuzione_mensile_proventi.label}
          </h3>
          <MonthlyDistributionChart
            data={
              reportData.caratteristiche_di_portafoglio
                .distribuzione_mensile_proventi
            }
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(
  CaratteristicaDiPortafoglio,
  (prevProps: SectionChildrenProps, nextProps: SectionChildrenProps) => {
    return prevProps.reportData === nextProps.reportData;
  },
);
