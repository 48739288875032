import { InitialInvestment } from "modules/initial_investment_form/InitialInvestment";
import { useEffect, useState } from "react";
import { useAccount } from "shared/hooks/useAccount";
import { Proposal } from "shared/models/proposals/ProposalsModel";
import { ProposalsService } from "shared/services/proposals/ProposalsService";
import { EditMenu } from "./ProposalEditMenu";
import useAccountStore from "shared/store/accountStore";
import useProposalsStore from "shared/store/proposalsStore";

type Props = {
  proposal: Proposal;
};

export const EditableInitialInvestment: React.FC<Props> = ({ proposal }) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newValue, setNewValue] = useState<string>();
  const { account } = useAccount();
  const { fetchAccount } = useAccountStore();
  const { fetchProposalById } = useProposalsStore();

  const initialAmount = `${proposal.initial_deposit}`;

  const canEdit = ["draft", "sent"].includes(proposal.status);

  useEffect(() => {
    if (!account) {
      fetchAccount();
    }
  }, [account]);

  useEffect(() => {
    if (editing) {
      setNewValue(initialAmount);
    }
  }, [editing, initialAmount]);

  const onSaveClicked = () => {
    if (newValue) {
      setLoading(true);
      ProposalsService.patchProposal(proposal.proposal_id, {
        initial_deposit: parseFloat(newValue),
      })
        ?.then(async () => {
          await fetchProposalById(proposal.proposal_id);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    setEditing(false);
  };

  return (
    <InitialInvestment
      readOnlyValue={editing ? undefined : initialAmount}
      defaultValue={editing ? initialAmount : undefined}
      onChange={(value) => setNewValue(value)}
    >
      {canEdit && (
        <EditMenu
          editing={editing}
          setEditing={setEditing}
          onSaveClicked={onSaveClicked}
        />
      )}
      {loading && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"></div>
      )}
    </InitialInvestment>
  );
};
