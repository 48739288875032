import { create } from "./store";
import { AssetEarnings, AssetStats } from "shared/models/asset/AssetModel";
import { AssetsService } from "shared/services/assets/AssetsService";

type AssetStatsStoreState = {
  cache: {
    [symbol: string]: {
      stats?: AssetStats;
      earnings?: AssetEarnings[];
    };
  };
  supportLevelsPrices?: number[];
};

type AssetStatsStoreActions = {
  fetchStatsBySymbol: (symbol: string) => void;
};

const initialData: AssetStatsStoreState = {
  cache: {},
};

const useAssetStatsStore = create<
  AssetStatsStoreState & AssetStatsStoreActions
>()((set, get) => ({
  ...initialData,
  fetchStatsBySymbol: async (symbol) => {
    const cached = get().cache[symbol];
    const stats = await AssetsService.getStats(symbol);
    const earnings = await AssetsService.getEarnings(symbol);
    const supportLevelsPrices =
      await AssetsService.getSupportLevelPrices(symbol);

    set({
      cache: {
        ...get().cache,
        [symbol]: { ...cached, stats, earnings },
      },
      supportLevelsPrices,
    });
  },
}));

export default useAssetStatsStore;
