import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, MapPin, X } from "lucide-react";
import styles from "./calendarWidget.module.css";
import crmStore from "shared/store/crmStore";
import { Participants } from "./Partecipants";
import brandStore from "shared/store/brandStore";
import { useTranslation } from "react-i18next";
import { Plus } from "lucide-react";
import { EventForm } from "./EventForm";
import { CalendarEvent, CrmTaskCalendarDayType } from "types/crm/ClientTypes";

export const CalendarWidget = () => {
  const { t } = useTranslation();
  //** store */
  const { tasksCalendar, fetchTasksCalendar } = crmStore();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState<any>(null);
  const [eventsMap, setEventsMap] = useState<any>({});
  const [showNewEventForm, setShowNewEventForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState<any>(null); // New state for editing events

  let lng = brandStore.getState().languageSetName;
  if (!lng) lng = "en";

  // Fetch data whenever the month changes
  useEffect(() => {
    const year = currentDate.getFullYear().toString();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    fetchTasksCalendar(year, month);
  }, [currentDate, fetchTasksCalendar]);

  // Process tasksCalendar data whenever it changes
  useEffect(() => {
    if (!tasksCalendar) return;

    const newEventsMap: any = {};

    tasksCalendar.forEach((dayData) => {
      Object.entries(dayData.days).forEach(([date, events]) => {
        if (events && events.length > 0) {
          newEventsMap[date] = events;
        }
      });
    });

    setEventsMap(newEventsMap);
  }, [tasksCalendar]);

  const weekDaysEn = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const weekDaysIta = ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"];

  const months = {
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    it: [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ],
  };

  const getDaysInMonth = (date: any) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const startDay = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

    let days = [];
    let week = [];

    const prevMonth = new Date(year, month, 0);
    const prevMonthDays = prevMonth.getDate();
    for (let i = startDay - 1; i >= 0; i--) {
      week.push({
        date: new Date(year, month - 1, prevMonthDays - i),
        isCurrentMonth: false,
      });
    }

    for (let day = 1; day <= daysInMonth; day++) {
      week.push({
        date: new Date(year, month, day),
        isCurrentMonth: true,
      });

      if (week.length === 7) {
        days.push(week);
        week = [];
      }
    }

    let dayCount = 1;
    while (week.length < 7) {
      week.push({
        date: new Date(year, month + 1, dayCount++),
        isCurrentMonth: false,
      });
    }
    days.push(week);

    while (days.length < 6) {
      let extraWeek = [];
      for (let i = 0; i < 7; i++) {
        extraWeek.push({
          date: new Date(year, month + 1, dayCount++),
          isCurrentMonth: false,
        });
      }
      days.push(extraWeek);
    }

    return days;
  };

  const formatDateKey = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1),
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1),
    );
  };

  const handleDayClick = (date: any) => {
    const dateKey = formatDateKey(date);
    if (eventsMap[dateKey]) {
      setSelectedDay(dateKey);
    }
  };

  const calendar = getDaysInMonth(currentDate);

  const [selectedParticipants, setSelectedParticipants] = useState<any>(null);

  let weekDays;
  if (lng === "it") {
    weekDays = weekDaysIta;
  } else {
    weekDays = weekDaysEn;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.monthDisplay}>
          {months[lng as keyof typeof months][currentDate.getMonth()]}{" "}
          {currentDate.getFullYear()}
        </div>
        <div className={styles.navigation}>
          <button
            className={`${styles.navButton} ${styles.addButton}`}
            onClick={() => setShowNewEventForm(true)}
          >
            <Plus size={18} />
          </button>
          <button className={styles.navButton} onClick={handlePrevMonth}>
            <ChevronLeft size={18} />
          </button>
          <button className={styles.navButton} onClick={handleNextMonth}>
            <ChevronRight size={18} />
          </button>
        </div>
      </div>
      <div className={styles.calendar}>
        <div className={styles.weekDays}>
          {weekDays.map((day) => (
            <div key={day} className={styles.weekDay}>
              {day}
            </div>
          ))}
        </div>

        <div className={styles.daysGrid}>
          {calendar.map((week, weekIndex) => (
            <div key={weekIndex} className={styles.week}>
              {week.map((day, dayIndex) => {
                const dateKey = formatDateKey(day.date);
                const hasEvent = eventsMap[dateKey]?.length > 0;

                return (
                  <div
                    key={`${weekIndex}-${dayIndex}`}
                    className={`${styles.day} 
              ${!day.isCurrentMonth ? styles.otherMonth : ""} 
              ${hasEvent ? styles.dayWithEvent : ""}`}
                    onClick={() => handleDayClick(day.date)}
                  >
                    <span className={styles.dateNumber}>
                      {day.date.getDate()}
                    </span>
                    {hasEvent && <div className={styles.eventDot} />}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
      {selectedDay && eventsMap[selectedDay] && (
        <div className={styles.popup}>
          <div className={styles.popupContent}>
            <div
              className={styles.closeIcon}
              onClick={() => setSelectedDay(null)}
            >
              <X size={18} />
            </div>
            <div className={styles.titleDay}>{selectedDay}</div>
            {eventsMap[selectedDay].map((event: any) => {
              const participants = event.participants;
              const parts: any = [...participants];
              return (
                <div key={event.event_id} className={styles.eventItem}>
                  <div className={styles.eventContent}>
                    <div className={styles.eventTitle}>{event.title}</div>
                    <div className={styles.eventTime}>
                      {new Date(event.time_start).toLocaleString("default", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      -
                      {new Date(event.time_end).toLocaleString("default", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </div>
                    {event.location && (
                      <div className={styles.eventLocation}>
                        <MapPin size={16} />
                        <span>{event.location}</span>
                      </div>
                    )}
                    <div className={styles.eventType}>{event.event_type}</div>
                    <div className={styles.eventType}>{event.description}</div>

                    <Participants
                      participants={parts}
                      onShowParticipants={setSelectedParticipants}
                    />
                  </div>
                  <button
                    className={styles.editButton}
                    onClick={() => {
                      setShowEditForm(event);
                    }}
                  >
                    {t("crm.edit")}
                  </button>
                </div>
              );
            })}
            <button
              className={styles.closeButton}
              onClick={() => setSelectedDay(null)}
            >
              {t("crm.close")}
            </button>
          </div>
        </div>
      )}
      {showNewEventForm && (
        <EventForm
          onClose={() => setShowNewEventForm(false)}
          onEventAdded={() => {
            const year = currentDate.getFullYear().toString();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            fetchTasksCalendar(year, month);
          }}
        />
      )}
      {showEditForm && (
        <EventForm
          onClose={() => setShowEditForm(null)}
          onEventAdded={() => {
            const year = currentDate.getFullYear().toString();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            fetchTasksCalendar(year, month);
          }}
          initialEvent={showEditForm} // Pass selected event for editing
        />
      )}
    </div>
  );
};
