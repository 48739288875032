import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DataTable } from "shared/components/common/datatable/DataTable";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { Client } from "shared/models/client/ClientModel";
import { TopClientPerformers } from "shared/models/top_performances/TopPerformancesModel";
import useClientsStore from "shared/store/clientsStore";
import useTopPerformancesStore from "shared/store/topPerformancesStore";
import { Loading } from "shared/types/enums";
import { CurrencyFormat, pctFormat } from "shared/utils/currency";

export const TopClientPerformances: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    clients: unsortedClients,
    clientsLoading: loading,
    fetchTopClients,
  } = useTopPerformancesStore();
  const { clients: faClients, fetchClients } = useClientsStore();
  const [clients, setClients] = useState<TopClientPerformers>([]);

  const [sort, setSort] = useState<"top" | "bottom">("top");

  useEffect(() => {
    fetchTopClients();
    fetchClients();
  }, []);

  useEffect(() => {
    const sortedClients = [...unsortedClients].sort((a, b) =>
      sort === "top"
        ? b.performance - a.performance
        : a.performance - b.performance,
    );
    setClients(sortedClients.slice(0, 10));
  }, [sort, unsortedClients]);

  return (
    <Panel>
      <div className="flex justify-between gap-4">
        <Labels.H3>
          <span className="text-sb-green-700">
            {t("topPerformances.clients")}
          </span>{" "}
          {t("topPerformances.title")}
        </Labels.H3>
        <div>
          <select
            className="cursor-pointer rounded-xl border border-sb-gray-200 px-4 py-2 outline-none"
            onChange={(e) => setSort(e.target.value as "top" | "bottom")}
          >
            <option value="top">{t("topPerformances.title")}</option>
            <option value="bottom">{t("topPerformances.titleBottom")}</option>
          </select>
        </div>
      </div>
      <div className="mt-8">
        <DataTable
          loading={loading === Loading.InProgress}
          columns={["user_id", "aum", "cash", "performance"]}
          headerLabels={{
            user_id: t("topPerformances.clientName"),
            aum: t("topPerformances.aum"),
            cash: t("topPerformances.cash"),
            performance: t("topPerformances.performance"),
          }}
          data={clients}
          onRowClicked={(entry: any) => {
            navigate(`/clients/${entry.user_id}`);
          }}
          sortableValue={(key: string, entry: any) => {
            if (key === "aum") {
              const aum =
                faClients.find(
                  (c: Client) =>
                    String(c.user_id) === String(entry.user_id),
                )?.trading?.equity || "0";
              return +aum;
            }
            if (key === "cash") {
              const cash =
                faClients.find(
                  (c: Client) =>
                    String(c.user_id) === String(entry.user_id),
                )?.trading?.cash || "0";
              return +cash;
            }
            if (key === "performance") {
              return +entry["performance"];
            }
            if (key === "user_id") {
              return (
                faClients.find(
                  (c: Client) =>
                    String(c.user_id) === String(entry["user_id"]),
                )?.summary.client_name || "Unknown"
              );
            }
            return undefined;
          }}
          cellFormatter={(key: string, value: string, entry: any) => {
            if (key === "performance") {
              return (
                <div
                  className={
                    parseFloat(value) < 0
                      ? "text-sb-red-700"
                      : "text-sb-green-700"
                  }
                >
                  {pctFormat.format(+value)}
                </div>
              );
            }
            if (key === "user_id") {
              return (
                faClients.find(
                  (c: Client) => String(c.user_id) === String(value),
                )?.summary.client_name || "Unknown"
              );
            }
            if (key === "aum") {
              return (
                <div>
                  {CurrencyFormat.format(
                    faClients.find(
                      (c: Client) =>
                        String(c.user_id) === String(entry.user_id),
                    )?.trading?.equity || "0",
                  )}
                </div>
              );
            }
            if (key === "cash") {
              return (
                <div>
                  {CurrencyFormat.format(
                    faClients.find(
                      (c: Client) =>
                        String(c.user_id) === String(entry.user_id),
                    )?.trading?.cash || "0",
                  )}
                </div>
              );
            }
            return value;
          }}
        />
        {loading === Loading.Finished && !clients?.length ? (
          <Labels.G1>{t("global.nothingToShow")}</Labels.G1>
        ) : null}
      </div>
    </Panel>
  );
};
