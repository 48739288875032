import { Portfolio } from "../models/portfolio/PortfolioModel";
import {
  ProAssistantAttachment,
  ProAssistantMessage,
  ProAssistantThread,
  ProAssistantThreadHistoryItem,
} from "../models/pro_assistant/ProAssistantModel";
import { ProAssistantService } from "../services/pro_assistant/ProAssistantService";
import { Loading } from "../types/enums";
import { create } from "./store";

type ProAssistantStoreState = {
  threads: ProAssistantThreadHistoryItem[];
  attachments: Record<string, ProAssistantAttachment[]>;
  messages: Record<string, ProAssistantMessage[]>;
  portfolios: Record<string, Portfolio[]>;
  threadDetails: Record<string, ProAssistantThread>;
  blockInput: Record<string, boolean>;

  historyLoading: Loading;
};

type ProAssistantStoreActions = {
  fetchThreads: () => void;
  appendAttachments: (
    threadId: string,
    attachments: ProAssistantAttachment[],
  ) => void;
  appendMessages: (threadId: string, messages: ProAssistantMessage[]) => void;
  fetchPortfolios: (threadId: string) => void;
  fetchThread: (threadId: string) => void;
  setBlockInput: (threadId: string, blockInput: boolean) => void;
  resetThread: (threadId: string) => void;
};

const initialData: ProAssistantStoreState = {
  threads: [],
  attachments: {},
  portfolios: {},
  messages: {},
  threadDetails: {},
  blockInput: {},
  historyLoading: Loading.Idle,
};

const useProAssistantStore = create<
  ProAssistantStoreState & ProAssistantStoreActions
>()((set, get) => ({
  ...initialData,
  fetchThreads: async () => {
    set({ historyLoading: Loading.InProgress });
    try {
      const threads = await ProAssistantService.fetchThreads();
      set({ threads });
    } finally {
      set({ historyLoading: Loading.Finished });
    }
  },
  fetchThread: async (threadId) => {
    const thread = await ProAssistantService.fetchThread(threadId);
    set({ threadDetails: { ...get().threadDetails, [threadId]: thread } });
  },
  appendAttachments: async (threadId, attachments) => {
    const attachmentIds = get().attachments[threadId]?.map(
      (m) => m.attachment_id,
    );
    const deduplicatedAttachments =
      attachments.filter(
        (m) =>
          !attachmentIds?.includes(m.attachment_id) && m.thread_id === threadId,
      ) || [];

    set({
      attachments: {
        ...get().attachments,
        [threadId]: [
          ...(deduplicatedAttachments || []),
          ...(get().attachments[threadId] || []),
        ],
      },
    });
  },
  appendMessages: async (threadId, messages) => {
    const messageIds = get().messages[threadId]?.map((m) => m.id);
    const deduplicatedMessages =
      messages.filter(
        (m) => !messageIds?.includes(m.id) && m.thread_id === threadId,
      ) || [];

    set({
      messages: {
        ...get().messages,
        [threadId]: [
          ...(deduplicatedMessages || []),
          ...(get().messages[threadId] || []),
        ],
      },
    });
  },
  fetchPortfolios: async (threadId) => {
    const portfolios = await ProAssistantService.fetchPortfolios(threadId);
    set({
      portfolios: {
        ...get().portfolios,
        [threadId]: portfolios,
      },
    });
  },
  setBlockInput: async (threadId, blockInput) => {
    set({
      blockInput: {
        ...get().blockInput,
        [threadId]: blockInput,
      },
    });
  },
  resetThread: (threadId) => {
    set({
      messages: {
        ...get().messages,
        [threadId]: [],
      },
      attachments: { ...get().attachments, [threadId]: [] },
      blockInput: { ...get().blockInput, [threadId]: false },
    });
  },
}));

export default useProAssistantStore;
