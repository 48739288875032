import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useState } from "react";
import prettyBytes from "pretty-bytes";
import useProAssistantStore from "shared/store/proAssistantStore";
import { ProAssistantService } from "shared/services/pro_assistant/ProAssistantService";
import { cn } from "utils";
import { ScrollArea } from "shared/components/ui/scroll-area";
import { Panel } from "shared/components/hoc/Panels";

export const AttachmentsWidget: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const threadId = params.threadId;
  const attachments = useProAssistantStore(
    (state) => state.attachments[threadId || ""],
  );

  const [loading, setLoading] = useState<Record<string, boolean>>({});

  if (!threadId) {
    return null;
  }

  const downloadAttachment = async (fileName: string, attachmentId: number) => {
    setLoading({ ...loading, [attachmentId]: true });
    const response = await ProAssistantService.downloadAttachment(
      threadId,
      attachmentId,
    );

    const link = document.createElement("a");
    link.href = URL.createObjectURL(response.data);
    link.download = fileName;
    link.click();

    setLoading({ ...loading, [attachmentId]: false });
  };

  return (
    <ScrollArea
      className={cn("flex-2 relative max-h-full rounded-3xl bg-white pt-12")}
    >
      <div className="absolute left-6 right-6 top-4 z-50">
        <div
          className={cn(
            "flex items-center justify-between gap-4",
            "justify-between",
          )}
        >
          <h3>{t("proAssistant.attachments")}</h3>
        </div>
      </div>
      <Panel className={"relative"}>
        <div className="relative space-y-4 text-base">
          <div className={cn("h-full text-sm", "min-w-[180px]")}>
            {attachments?.map((attachment) => (
              <div
                key={attachment.attachment_id}
                className={cn(
                  "flex items-center justify-between gap-2 duration-150 hover:opacity-60",
                  loading[attachment.attachment_id] ? "" : "cursor-pointer",
                )}
                onClick={() =>
                  !loading[attachment.attachment_id] &&
                  downloadAttachment(
                    attachment.attachment_name,
                    attachment.attachment_id,
                  )
                }
              >
                <div className="break-all">{attachment.attachment_name}</div>
                <div className="whitespace-nowrap text-gray-500">
                  {prettyBytes(attachment.content_size)}
                </div>
              </div>
            ))}
          </div>
        </div>
        {attachments?.length === 0 && (
          <div className="my-2 text-center text-sm italic text-gray-500">
            {t("proAssistant.noAttachments")}
          </div>
        )}
      </Panel>
    </ScrollArea>
  );
};
