import { ChevronLeftIcon } from "lucide-react";
import { cn } from "utils";

type Props = {
  expanded: boolean;
};

export const ChevronButton: React.FC<Props> = ({ expanded }) => {
  return (
    <>
      <div
        className={cn(
          "grid h-8 w-8 shrink-0 cursor-pointer place-items-center rounded-full border border-gray-300",
          expanded ? "" : "bg-gray-100",
        )}
      >
        <ChevronLeftIcon
          className={cn(
            "aspect-asquare w-4 text-gray-500 duration-150",
            expanded ? "" : "rotate-180",
          )}
        />
      </div>
    </>
  );
};
