import { create } from "./store";
import { AppStatusTypes, AgentStatusTypes } from "types/AppStatusTypes";

type AppStatusStoreState = {
  appStatus: AppStatusTypes;
  agentStatus: AgentStatusTypes;
  windowW: number;
  windowH: number;
};

type AppStatusStoreActions = {
  setAppStatus: (status: AppStatusTypes) => void;
  setAgentStatus: (status: AgentStatusTypes) => void;
  setWindowW: (value: number) => void;
  setWindowH: (value: number) => void;
  clearAppStatus: () => void;
};

const initialState: AppStatusStoreState = {
  appStatus: "idle",
  agentStatus: "idle",
  windowW: 0,
  windowH: 0,
};

const appStatusStore = create<AppStatusStoreState & AppStatusStoreActions>()(
  (set) => ({
    ...initialState,
    setAppStatus: (status) => {
      set({ appStatus: status });
    },
    setAgentStatus: (status) => {
      set({ agentStatus: status });
    },
    setWindowW: (value) => {
      set({ windowW: value });
    },
    setWindowH: (value) => {
      set({ windowH: value });
    },
    clearAppStatus: async () => {
      set({ appStatus: "idle" });
    },
  }),
);

export default appStatusStore;
