import Decimal from "decimal.js";
import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { ClientsService } from "shared/services/clients/ClientsService";
import useClientDetailsStore from "shared/store/clientDetailsStore";
import { CurrencyFormat } from "shared/utils/currency";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  clientId?: string;
  portfolioId: string;
  portfolioEquity: number;
};
export const PortfolioWithdrawModal: React.FC<Props> = ({
  open,
  setOpen,
  clientId,
  portfolioId,
  portfolioEquity,
}) => {
  const { t } = useTranslation();
  const { fetchClientPortfolio, fetchRecentActivities } =
    useClientDetailsStore();
  const [value, setValue] = useState<string>();
  const [loading, setLoading] = useState(false);

  const withdrawSuccess = async () => {
    if (clientId) {
      fetchClientPortfolio(clientId, portfolioId);
      fetchRecentActivities(clientId);
    }
    setLoading(false);
    setOpen(false);
    toast.success(t("faPortfolio.withdrawSuccess"));
  };

  const onWithdraw = () => {
    if (clientId && value) {
      setLoading(true);
      const amountAsString = new Decimal(value).toFixed();
      ClientsService.withdrawFromPortfolio(
        clientId,
        portfolioId,
        amountAsString,
      ).then(withdrawSuccess);
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Labels.H3>{t("clientPortfolio.withdraw")}</Labels.H3>
      <div className="text-base text-sb-gray-600">
        {t("clientPortfolio.equity")}
        {": "}
        {CurrencyFormat.format(portfolioEquity)}
      </div>
      <div className="mt-4">
        <CurrencyInput
          prefix="$"
          className="w-full min-w-0 text-3xl caret-sb-green-600 outline-none"
          placeholder="0"
          decimalsLimit={2}
          decimalScale={2}
          onValueChange={(value) => setValue(value)}
        />
      </div>
      <div className="mt-4 flex items-center justify-center gap-4">
        <Button
          loading={loading}
          label="clientPortfolio.withdraw"
          disabled={!value || portfolioEquity < parseFloat(value)}
          onClick={onWithdraw}
        />
        <Button
          disabled={loading}
          btnStyle="blank"
          label="global.cancel"
          onClick={() => setOpen(false)}
        />
      </div>
    </Modal>
  );
};
