import { TradingOrder } from "shared/models/trading/TradingOrderModel";
import {
  OrdersService,
  RecentOrdersParams,
} from "shared/services/orders/OrdersService";
import { Loading } from "shared/types/enums";
import { create } from "zustand";

type RecentOrdersStoreState = {
  orders: TradingOrder[];
  loading: Loading;
  filters: RecentOrdersParams;
};

type RecentOrdersStoreActions = {
  fetchRecentOrders: (params?: RecentOrdersParams) => void;
};

const initialData: RecentOrdersStoreState = {
  orders: [],
  loading: Loading.Idle,
  filters: {},
};

const useRecentOrdersStore = create<
  RecentOrdersStoreState & RecentOrdersStoreActions
>()((set) => ({
  ...initialData,
  fetchRecentOrders: async (params) => {
    set({ loading: Loading.InProgress });
    try {
      const orders = await OrdersService.fetchRecent(params);
      set({ orders });
    } finally {
      set({ loading: Loading.Finished });
    }
  },
}));

export default useRecentOrdersStore;
