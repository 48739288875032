import { MarketInfo } from "shared/models/market/MarketModel";
import { create } from "./store";
import { MarketService } from "shared/services/market/MarketService";

type MarketInfoStoreState = {
  marketInfo: MarketInfo | null | undefined;
};

type MarketInfoStoreActions = {
  fetchMarketInfo: () => Promise<void>;
};

const initialData: MarketInfoStoreState = {
  marketInfo: undefined,
};

const useMarketInfoStore = create<
  MarketInfoStoreState & MarketInfoStoreActions
>()((set) => ({
  ...initialData,
  fetchMarketInfo: async () => {
    const marketInfo = await MarketService.checkMarketOpen();
    set({ marketInfo });
  },
}));

export default useMarketInfoStore;
