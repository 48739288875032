import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BasePage } from "shared/components/common/BasePage";
import { DataTable } from "shared/components/common/datatable/DataTable";
import { AddContactButton } from "shared/components/controls/buttons/AddContactButton";
import { Labels } from "shared/components/hoc/Labels";
import { Loading } from "shared/types/enums";
import { useNavigate } from "react-router-dom";
import { Panel } from "shared/components/hoc/Panels";
import { ContactStatus } from "shared/models/contact/AddContactModel";
import { pctFormatMaxTwo } from "shared/utils/currency";
import useContactsStore from "shared/store/contactsStore";

export const ContactListPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contacts, loading, fetchContacts } = useContactsStore();

  useEffect(() => {
    fetchContacts();
  }, []);

  return (
    <BasePage>
      <div className="mb-12 flex items-center justify-between">
        <Labels.H1>{t("contacts.title")}</Labels.H1>
        <AddContactButton />
      </div>
      <Panel>
        <DataTable
          loading={loading === Loading.InProgress}
          data={contacts}
          columns={[
            "name",
            "email",
            "phone",
            "fee_frequency",
            "effective_from",
            "annual_rate",
            "status",
          ]}
          onRowClicked={(entry: any) => {
            navigate(`/contacts/${entry["contact_id"]}`);
          }}
          headerLabels={{
            name: t("clients.table.name"),
            email: t("clients.table.email"),
            phone: t("clients.table.phone"),
            fee_frequency: t("clients.table.fee_frequency"),
            effective_from: t("clients.table.effective_from"),
            annual_rate: t("clients.table.annual_rate"),
            status: t("clients.table.status"),
          }}
          cellFormatter={(key: string, value: string, entry: any) => {
            if (key === "fee_frequency") {
              return t(
                `fees.frequency.${entry["config"]["fees"]["fee_frequency"]}`,
              );
            }
            if (key === "annual_rate") {
              return pctFormatMaxTwo.format(
                entry["config"]["fees"]["configuration"]["annual_rate"],
              );
            }
            if (key === "effective_from") {
              return entry["config"]["fees"]["effective_from"];
            }
            if (key === "status") {
              return <StatusLabel status={entry["status"]} />;
            }
            return value;
          }}
        />
      </Panel>
    </BasePage>
  );
};

const StatusLabel: React.FC<{ status: ContactStatus }> = ({ status }) => {
  const { t } = useTranslation();

  const color: Record<ContactStatus, string> = {
    accepted: "bg-sb-green-200",
    rejected: "bg-sb-red-200",
    deleted: "",
    no_invitation: "bg-sb-cyan-200",
    pending: "bg-sb-violet-200",
  };

  return (
    <div
      className={`rounded-lg px-2 py-1 text-sm font-medium ${color[status]}`}
    >
      {t(`clients.table.statuses.${status}`)}
    </div>
  );
};
