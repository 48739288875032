import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChevronRight from "shared/icons/ChevronRight";
import { Link } from "react-router-dom";
import { useChartColors } from "./hooks/useChartColors";
import {
  MetaPortfolio,
  PortfolioAssetAllocation,
} from "shared/models/portfolio/PortfolioModel";
import { TrashIcon } from "shared/icons/TrashIcon";
import { GlobalSearch } from "modules/global_search/GlobalSearch";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { toast } from "react-toastify";
import Decimal from "decimal.js";

type Props = {
  metaPortfolio: MetaPortfolio;
  color: string;
  editing?: boolean;
  onChange?: (newMeta: MetaPortfolio) => void;
  forceOpen?: boolean;
};

export const MetaPortfolioRow: React.FC<Props> = ({
  metaPortfolio,
  color,
  editing,
  onChange,
  forceOpen = false,
}) => {
  const { t } = useTranslation();
  const { getOuterColor } = useChartColors();
  const [open, setOpen] = useState(true);
  const [newMetaPortfolio, setNewMetaPortfolio] = useState(metaPortfolio);
  const [inputValues, setInputValues] = useState<Record<string, string>>({});

  useEffect(() => {
    setOpen((prev) => editing || prev);
    if (!editing) {
      setNewMetaPortfolio(metaPortfolio);
    }
  }, [editing, metaPortfolio]);

  const onAllocationChanged = (ticker: string, weight: string) => {
    try {
      setInputValues(prev => ({ ...prev, [ticker]: weight }));

      let decimalWeight: Decimal;
      // Handle empty or invalid input
      if (!weight || weight === '') {
        decimalWeight = new Decimal(0);
      } else {
        // Only convert to Decimal if it's a valid number
        if (/^\d*\.?\d*$/.test(weight)) {
          decimalWeight = new Decimal(weight);
        } else {
          return; // Exit if invalid input
        }
      }

      // Ensure weight is not negative
      if (decimalWeight.isNegative()) {
        decimalWeight = new Decimal(0);
      }

      const finalWeight = decimalWeight.div(100).toFixed();

      const newMeta = JSON.parse(JSON.stringify(newMetaPortfolio));
      for (let allocation of newMeta.allocations) {
        if (allocation.ticker === ticker) {
          allocation.weight = finalWeight;
        }
      }
      setNewMetaPortfolio(newMeta);
      onChange?.(newMeta);
    } catch (error) {
      // If decimal conversion fails, set weight to 0
      const newMeta = JSON.parse(JSON.stringify(newMetaPortfolio));
      for (let allocation of newMeta.allocations) {
        if (allocation.ticker === ticker) {
          allocation.weight = "0";
        }
      }
      setNewMetaPortfolio(newMeta);
      onChange?.(newMeta);
    }
  };

  const onAllocationRemoved = (ticker: string) => {
    const newMeta = { ...newMetaPortfolio };
    newMeta.allocations = newMeta.allocations.filter(
      (allocation) => allocation.ticker !== ticker,
    );
    setNewMetaPortfolio(newMeta);
    onChange?.(newMeta);
  };

  const onAssetAdded = (asset: AssetWrapper) => {
    const newMeta = JSON.parse(JSON.stringify(newMetaPortfolio));
    if (
      newMeta.allocations.find(
        (a: PortfolioAssetAllocation) => a.ticker === asset.symbol,
      )
    ) {
      toast.warning(t("faPortfolio.assetExists"));
      return;
    }
    newMeta.allocations.push({
      name: asset.name,
      symbol: asset.symbol,
      ticker: asset.symbol,
      asset_id: asset.asset_id,
      weight: "0",
    });
    setNewMetaPortfolio(newMeta);
    onChange?.(newMeta);
  };

  const getAllocation = () => {
    return newMetaPortfolio.allocations
      .map((a) => a.weight)
      .reduce((sum, weight) => sum.plus(new Decimal(weight)), new Decimal(0));
  };

  const isAllocationValid = () => {
    if (!editing) {
      return true;
    }
    const allocationSum = getAllocation();
    return allocationSum.cmp(new Decimal(1)) === 0;
  };

  return (
    <>
      <div
        className="relative cursor-pointer rounded-md bg-sb-gray-100 px-4 py-2"
        onClick={() => !editing && setOpen((prev) => !prev)}
      >
        <div className="mr-4 grid grid-cols-2 gap-4">
          <div
            className="flex flex-1 select-none items-center gap-2 text-base font-semibold"
            style={{ color: color }}
          >
            <ChevronRight
              className={
                (open ? "-rotate-90" : "rotate-90") +
                " h-4 w-4 shrink-0 text-black duration-150"
              }
            />
            {t(`portfolioCard.assetClasses.${metaPortfolio.asset_class}`)}
          </div>

        </div>
      </div>
      {open && (
        <div className="mr-4 py-1 text-base">
          {!isAllocationValid() && (
            <div className="text-center text-sb-red-700">
              {t("faPortfolio.allocationsInvalid")}
            </div>
          )}
          {editing && (
            <div className="text-center font-semibold">
              {t("faPortfolio.allocation", {
                weight: `${getAllocation().times(100).toFixed()}%`,
              })}
            </div>
          )}
          {metaPortfolio.allocations.map((allocation, idx) => {
            return (
              <div
                key={"allocation_" + idx}
                className="my-2 flex justify-between"
              >
                <div className="flex items-center gap-1">
                  <div
                    className="ml-10 h-2 w-2 shrink-0 rounded-full"
                    style={{
                      backgroundColor: getOuterColor(
                        color,
                        idx,
                        metaPortfolio.allocations.length,
                      ),
                    }}
                  ></div>
                  <Link
                    to={`/dashboard/assets/${allocation.ticker}`}
                    className={
                      "hover:rounded-lg hover:bg-black hover:px-1 hover:text-white"
                    }
                  >
                    {allocation.name} ({allocation.ticker})
                  </Link>
                </div>
                {editing ? (
                  <div className="flex items-center justify-end gap-4">
                    <input
                      className={
                        (isAllocationValid()
                          ? "border-sb-gray-100"
                          : "border-sb-red-500") +
                        " ml-8 w-[80px] rounded-lg border px-2 text-right outline-none"
                      }
                      type="text"
                      onChange={(e) => onAllocationChanged(allocation.ticker, e.target.value)}
                      value={inputValues[allocation.ticker] ?? new Decimal(allocation.weight).times(100).toFixed()}
                    />
                    <div
                      className="cursor-pointer"
                      onClick={() => onAllocationRemoved(allocation.ticker)}
                    >
                      <TrashIcon className="aspect-square w-3.5 cursor-pointer text-sb-red-700" />
                    </div>
                  </div>
                ) : (
                  <div className="text-right font-light">
                    {new Decimal(allocation.weight).times(100).toFixed(2)}%
                  </div>
                )}
              </div>
            );
          })}
          {editing && (
            <div className="ml-4 mt-4 text-sm">
              <div className="text-center font-semibold">
                {t("faPortfolio.addAsset")}
              </div>
              <GlobalSearch onClicked={onAssetAdded} wFull />
            </div>
          )}
        </div>
      )}
    </>
  );
};
