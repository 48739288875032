import {
  PortfolioPerformanceChart,
  PortfolioPerformanceChartParams,
} from "shared/models/portfolio/PortfolioModel";
import { create } from "./store";
import { PortfoliosService } from "shared/services/portfolios/PortfoliosService";

type BacktestingPortfolioChartsStoreState = {
  cache: { [id: string]: { [period: string]: PortfolioPerformanceChart } };
};

type BacktestingPortfolioChartsStoreActions = {
  fetchBacktestingPortfolioCharts: (
    id: string,
    params: PortfolioPerformanceChartParams,
  ) => Promise<void>;
};

const initialData: BacktestingPortfolioChartsStoreState = {
  cache: {},
};

const useBacktestingPortfolioChartsStore = create<
  BacktestingPortfolioChartsStoreState & BacktestingPortfolioChartsStoreActions
>()((set, get) => ({
  ...initialData,
  fetchBacktestingPortfolioCharts: async (id, params) => {
    const period = params?.period || "last_year";
    const cache = get().cache[id];
    let data = cache && cache[period];
    if (!data) {
      try {
        data = await PortfoliosService.getPortfolioBacktestingChart(id, {
          ...params,
        });
      } catch (err) {
        console.warn(err);
        data = [];
      }
    }
    set({
      cache: {
        ...get().cache,
        [id]: { ...cache, [period]: data },
      },
    });
  },
}));

export default useBacktestingPortfolioChartsStore;
