import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import "../styles.css";
import { ProAssistantPortfolio } from "shared/models/pro_assistant/ProAssistantModel";
import usePortfoliosStore from "shared/store/portfoliosStore";
import useProAssistantStore from "shared/store/proAssistantStore";
import useBacktestingPortfolioChartsStore from "shared/store/backtestingPortfolioChartsStore";
import { Loading } from "shared/types/enums";
import { ProAssistantService } from "shared/services/pro_assistant/ProAssistantService";
import { cn } from "utils";
import PortfolioCoverImage from "modules/pro_ai_assistant/artifacts/PortfolioCoverImage";
import { pctFormat } from "shared/utils/currency";
import PnlChart from "./PnlChart";
import { Button } from "shared/components/controls/Button";
import { Skeleton } from "shared/components/common/Skeleton";

type Props = {
  portfolio: ProAssistantPortfolio;
};

export const PortfolioArtifact: React.FC<Props> = ({
  portfolio: assistantPortfolio,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const threadId = params.threadId;
  const portfolio = useProAssistantStore(
    (state) =>
      state.portfolios[threadId || ""]?.find(
        (p) => p.id === assistantPortfolio.id,
      ),
  );
  const chartData = useBacktestingPortfolioChartsStore(
    (state) => state.cache[portfolio?.id || ""],
  );
  const threadDetails = useProAssistantStore(
    (state) => state.threadDetails[threadId || ""],
  );
  const { fetchPortfolioById, loading } = usePortfoliosStore();
  const { fetchBacktestingPortfolioCharts: fetchChartByPortfolioId } =
    useBacktestingPortfolioChartsStore();
  const { fetchThread, fetchPortfolios } = useProAssistantStore();

  useEffect(() => {
    if (assistantPortfolio.id) {
      fetchPortfolioById(assistantPortfolio.id);
      fetchChartByPortfolioId(assistantPortfolio.id, { period: "all_time" });
    }
  }, [assistantPortfolio.id]);

  useEffect(() => {
    if (threadId && !portfolio && loading !== Loading.InProgress) {
      fetchPortfolios(threadId);
      fetchThread(threadId);
    }
    let imageTimeout: NodeJS.Timeout | null = null;
    const refetchImage = async () => {
      if (portfolio?.id && !portfolio.cover?.uri) {
        fetchPortfolioById(portfolio.id);
        imageTimeout = setTimeout(refetchImage, 10000);
      }
    };

    refetchImage();

    return () => {
      if (imageTimeout) {
        clearTimeout(imageTimeout);
      }
    };
  }, []);

  const cagr = +(portfolio?.simulated_metrics.all_time.cagr || 0);
  const isActive =
    threadDetails?.metadata?.active_portfolio_id === portfolio?.id;

  const handlePortfolioClicked = () => {
    if (portfolio?.id && threadId) {
      ProAssistantService.activatePortfolio(threadId, portfolio.id).then(() => {
        fetchThread(threadId);
        fetchPortfolios(threadId);
      });
    }
  };

  return (
    <div
      className={cn(
        "relative my-2 max-w-2xl rounded-xl bg-sb-gray-100 p-4",
        isActive ? "activeShadow border-green-dark border" : "",
      )}
    >
      {portfolio ? (
        <>
          {isActive && (
            <div className="absolute right-4 top-4 rounded-md bg-sb-green-500 px-2 text-[10px] font-semibold text-black">
              {t("proAssistant.active").toUpperCase()}
            </div>
          )}
          <div
            className={cn(
              "flex items-center justify-between rounded-3xl duration-150",
            )}
          >
            <div className="w-full">
              <div className="flex w-full items-center justify-between">
                <div className="mb-4 flex items-center gap-2">
                  <PortfolioCoverImage
                    uri={portfolio?.cover?.uri}
                    className="size-14"
                    wrapperClassName="border-gray-90 bg-gray-90 border-[6px]"
                  />
                  <div>
                    <div className="pr-14 text-lg font-semibold text-black">
                      {portfolio?.title}
                    </div>
                    <div className="flex items-center gap-2 text-xs">
                      <span
                        className={cn(
                          "text-xl",
                          cagr >= 0 ? "text-green-500" : "",
                        )}
                      >
                        {pctFormat.format(cagr)}
                      </span>
                      {t("dashboard.yearlyAvg").toUpperCase()}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="h-[200px] w-full">
              <PnlChart
                chartData={chartData?.all_time}
                height={200}
                domain={["dataMin", "dataMax"]}
              />
            </div>
          </div>
          <div className="mt-8 flex justify-center">
            <div className="space-y-4">
              <div className="cursor-pointer text-center text-sm text-gray-500 underline">
                <Link to={`/portfolios/${portfolio?.id}`}>
                  {t("assistant.viewPortfolioDetails")}
                </Link>
              </div>
              {!isActive && (
                <Button
                  onClick={() => !isActive && handlePortfolioClicked()}
                  className="min-w-[150px]"
                  label={t("actions.activate")}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="my-2">
          <Skeleton />
        </div>
      )}
    </div>
  );
};
