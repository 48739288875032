import { CompanyLogo } from "shared/components/common/CompanyLogo";
import useAssets from "shared/hooks/useAssets";
import { TradingPosition } from "shared/models/trading/TradingPositionmodel";
import { CurrencyFormat, pctFormat } from "shared/utils/currency";

type Props = {
  position: TradingPosition;
  onClick?: (position: TradingPosition) => void;
};

export const Position: React.FC<Props> = ({ position, onClick }) => {
  const { assets_map } = useAssets();

  return (
    <div
      className={
        (onClick ? "cursor-pointer" : "") +
        " rounded-xl duration-150 hover:bg-sb-gray-100"
      }
      onClick={() => {
        onClick?.(position);
      }}
    >
      <div className="flex items-center gap-4 rounded-xl p-2">
        <CompanyLogo
          symbol={position.symbol}
          className="aspect-square w-12 rounded-full border border-sb-gray-200"
        />
        <div className="w-[200px] max-w-[200px]">
          <div className="text-lg font-semibold">{position.symbol}</div>
          <div className="text-base text-sb-gray-500">
            {assets_map[position.symbol]?.name}
          </div>
          <div
            className={
              (position.unrealized_pl < 0 ? "text-red-500" : "text-green-500") +
              " text-lg font-medium"
            }
          >
            {CurrencyFormat.format(position.unrealized_pl)} (
            {pctFormat.format(position.unrealized_plpc)})
          </div>
        </div>
      </div>
    </div>
  );
};
