import { PortfolioComposition } from "modules/portfolio_composition/PortfolioComposition";
import { useEffect, useState } from "react";
import { Panel } from "shared/components/hoc/Panels";
import { Proposal } from "shared/models/proposals/ProposalsModel";
import { EditMenu } from "./ProposalEditMenu";
import { useTranslation } from "react-i18next";
import { MetaPortfolio } from "shared/models/portfolio/PortfolioModel";
import { ProposalsService } from "shared/services/proposals/ProposalsService";
import { toast } from "react-toastify";
import { useAccount } from "shared/hooks/useAccount";
import usePortfoliosStore from "shared/store/portfoliosStore";
import useAccountStore from "shared/store/accountStore";
import useProposalsStore from "shared/store/proposalsStore";


type Props = {
  proposal: Proposal;
};

export const EditablePortfolioComposition: React.FC<Props> = ({ proposal }) => {
  const { t } = useTranslation();
  const { account } = useAccount();
  const [editing, setEditing] = useState(false);
  const [changedMetaPortfolio, setChangedMetaPortfolio] =
    useState<MetaPortfolio[]>();

  const portfolioId = proposal.strategy_type;
  const portfolio = usePortfoliosStore((state) =>
    state.portfolios.find((p) => p.id === portfolioId),
  );
  const { fetchPortfolioById } = usePortfoliosStore();
  const { fetchAccount } = useAccountStore();
  const { fetchProposalById } = useProposalsStore();

  const [saveLoading, setSaveLoading] = useState(false);
  const canEdit = ["draft", "sent"].includes(proposal.status);

  useEffect(() => {
    if (!portfolio) {
      fetchPortfolioById(portfolioId);
    }
  }, [portfolioId, portfolio]);

  useEffect(() => {
    if (!account) {
      fetchAccount();
    }
  }, [account]);

  const onSaveClicked = () => {
    if (changedMetaPortfolio) {
      setSaveLoading(true);
      ProposalsService.patchPortfolios(proposal.proposal_id, {
        meta_portfolio: changedMetaPortfolio,
      })
        ?.then(() => {
          fetchProposalById(proposal.proposal_id);
          setChangedMetaPortfolio(undefined);
          setEditing(false);
          setSaveLoading(false);
          toast.success(t("faPortfolio.portfolioUpdated"));
        })
        .finally(() => setSaveLoading(false));
    }
  };

  const getError = () => {
    return undefined;
  };

  return (
    <Panel className="mt-8">
      <div className="relative">
        {saveLoading && (
          <div className="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50" />
        )}
        <div className="mb-8 text-xl font-medium">
          {t("global.allocations")}
        </div>
        {canEdit && !saveLoading && (
          <EditMenu
            editing={editing}
            setEditing={setEditing}
            onSaveClicked={onSaveClicked}
            error={getError}
          />
        )}
        <PortfolioComposition
          portfolio={portfolio}
          editing={editing}
          onChange={setChangedMetaPortfolio}
        />
      </div>
    </Panel>
  );
};
