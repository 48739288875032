import React, { useEffect, useRef, useState } from "react";
import {
  AssistantMessage,
  FinecoPortfolioArtifactType,
} from "types/assistant/AssistantTypes";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AssistantService } from "shared/services/AssistantService";
import { createAssistantMessage } from "utils/assistantUtils";
import useThreadsStore from "shared/store/threadsStore";
import { GenerateReport } from "./components/GenerateReport";
import PortfolioConfigData from "config/portfolio-config.json";
import { useToast } from "shared/components/ui/use-toast";
import Portafoglio from "./components/Sections/Portafoglio";
import { Section } from "./components/Sections/Section";
import AssetAllocationImplicita from "./components/Sections/AssetAllocationImplicita";
import CaratteristicaDiPortafoglio from "./components/Sections/CaratteristicaDiPortafoglio";
import AnalisiRischioRendimentoAtteso from "./components/Sections/AnalisiRischioRendimentoAtteso";
import ScomposizionePortafoglioContainer from "./components/Sections/SubSections/ScomposizionePortafolioContainer";
import Stats from "./components/Sections/Stats";
import AnalisiStrumenti from "./components/Sections/AnalisiStrumenti";
import { FinecoButton } from "pages/assistant/components/FinecoButton";
import { FinecoPortfolioStoryArtifact } from "../fineco-portfolio-story-artifact/FinecoPortfolioStoryArtifact";
import EfficientFrontierContainer from "./components/Sections/SubSections/EfficientFrontierContainer";
import FinecoPortfolioTitle from "./components/FinecoPortfolioTitle";
import StatsContainer from "./components/Sections/SubSections/StatsContainer";
import DistribuzioneTipologiaStrumentiContainer from "./components/Sections/SubSections/DistribuzioneTipologiaStrumentiContainer";
import styles from "./finecoPortfolioArtifact.module.css";

type Props = {
  artifact: FinecoPortfolioArtifactType;
  message: AssistantMessage;
  finecoPortfolioAndPortfolioStoryTogether: boolean;
  onLoaded?: () => void;
};

const ef_data = [
  { risk: 2.03, return: 2.22 },
  { risk: 3.53, return: 2.67 },
  { risk: 6.48, return: 3.57 },
  { risk: 9.01, return: 4.34 },
  { risk: 12.5, return: 5.41 },
  { risk: 16.0, return: 6.47 },
  { risk: 21.02, return: 7.97 },
  { risk: 25.03, return: 9.1 },
];

export const FinecoPortfolioArtifact: React.FC<Props> = ({
  artifact,
  message,
  finecoPortfolioAndPortfolioStoryTogether,
  onLoaded,
}) => {
  const appendMessages = useThreadsStore((state) => state.appendMessages);
  const { t } = useTranslation();
  const { toast } = useToast();
  const params = useParams();
  const threadId = params.threadId;
  const [reportData, setReportData] = useState<any>(null);
  const [generatingPdf, setGeneratingPdf] = useState<boolean>(false);
  const [generatingDownloadPdf, setGeneratingDownloadPdf] =
    useState<boolean>(false);
  const [generatingExcel, setGeneratingExcel] = useState<boolean>(false);

  const generateReportRef = useRef(null);

  const sectionsConfig = {
    efficientFrontierContainer: {
      label: "",
      component: (
        <EfficientFrontierContainer
          reportData={reportData}
          ef_data={ef_data}
          artifact={artifact}
        />
      ),
      isOpen: true,
      showFoldingTitle: false,
    },
    StatsContainer: {
      label: "",
      component: (
        <StatsContainer
          reportData={reportData}
          artifact={artifact}
          label={PortfolioConfigData.stats.label}
        />
      ),
      isOpen: true,
      showFoldingTitle: false,
    },
    DistribuzioneTipologiaStrumentiContainer: {
      label: "",
      component: (
        <DistribuzioneTipologiaStrumentiContainer reportData={reportData} />
      ),
      isOpen: true,
      showFoldingTitle: false,
    },
    portfolio: {
      label: PortfolioConfigData.portfolio.label,
      component: <Portafoglio reportData={reportData} />,
      isOpen: true,
      showFoldingTitle: false,
    },
    caratteristicaDiPortafoglio: {
      label: PortfolioConfigData.caratteristiche_di_portafoglio.label,
      component: <CaratteristicaDiPortafoglio reportData={reportData} />,
      isOpen: false,
      showFoldingTitle: true,
    },
    assetAllocationImplicita: {
      label: PortfolioConfigData.implicit_asset_allocation.label,
      component: <AssetAllocationImplicita reportData={reportData} />,
      isOpen: false,
      showFoldingTitle: true,
    },
    scomposizionePortafoglio: {
      label: PortfolioConfigData.scomposizione_portafoglio.label,
      component: <ScomposizionePortafoglioContainer reportData={reportData} />,
      isOpen: false,
      showFoldingTitle: true,
    },
    analisi_rischio_rendimento_atteso: {
      label: PortfolioConfigData.analisi_rischio_rendimento_atteso.label,
      component: (
        <AnalisiRischioRendimentoAtteso
          reportData={reportData}
          ef_data={ef_data}
          artifact={artifact}
        />
      ),
      isOpen: false,
      showFoldingTitle: true,
    },
    analisi_strumenti: {
      label: PortfolioConfigData.analisi_strumenti.label,
      component: <AnalisiStrumenti reportData={reportData} />,
      isOpen: false,
      showFoldingTitle: true,
    },
    //****************** */
    portfolioStory: {
      label: "Commento al portafoglio",
      component: (
        <>
          {message.artifacts.map((artifact) => {
            if (
              artifact.type === "custom_data" &&
              artifact.version === "portfolio_story" &&
              artifact.data != null
            ) {
              return (
                <FinecoPortfolioStoryArtifact
                  key={Math.random()}
                  data={artifact.data}
                  onLoaded={onLoaded}
                />
              );
            }
            return null;
          })}
        </>
      ),
      isOpen: false,
      showFoldingTitle: true,
    },
  };

  const [openSections, setOpenSections] = useState(
    Object.fromEntries(
      Object.entries(sectionsConfig).map(([key, { isOpen }]) => [key, isOpen]),
    ),
  );

  const toggleSection = (section: string) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (threadId && artifact.portfolio_id) {
        try {
          const data = await AssistantService.fetchReportData(
            threadId,
            artifact.portfolio_id,
          );
          setReportData(data);
        } catch (error) {
          console.error("Error fetching report data:", error);
        }
      }
    };

    fetchData();
    onLoaded?.();
  }, [threadId, artifact.portfolio_id]);

  const requestGeneratePdf = async (
    reportSpec: any,
    fromReport: boolean = false,
  ) => {
    if (fromReport) {
      setGeneratingPdf(true);
    }

    try {
      const rawPdf = await AssistantService.createPdf(
        threadId,
        artifact.portfolio_id,
        reportSpec,
      );

      const blob = new Blob([rawPdf], { type: "application/pdf" });
      if (blob.type === "application/pdf") {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `portfolio_${artifact.portfolio_id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        const assistantMessage = createAssistantMessage(
          t("assistant.reportDownloaded"),
          threadId,
        );
        appendMessages(threadId, [assistantMessage]);
      } else {
        console.error("Error: Response is not a PDF");
        const assistantMessage = createAssistantMessage(
          t("assistant.reportDownloadedError"),
          threadId,
        );
        appendMessages(threadId, [assistantMessage]);
      }
    } catch (error) {
      console.error("Error generating PDF", error);
      const assistantMessage = createAssistantMessage(
        t("assistant.reportDownloadedError"),
        threadId,
      );
      appendMessages(threadId, [assistantMessage]);
    } finally {
      setGeneratingPdf(false);
      setGeneratingDownloadPdf(false);
    }
  };

  const requestGenerateExcel = async () => {
    setGeneratingExcel(true);
    try {
      const response = await AssistantService.createExcel(
        threadId,
        artifact.portfolio_id,
      );

      const filename = `portfolio_${artifact.portfolio_id}.xlsx`;
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      const assistantMessage = createAssistantMessage(
        t("assistant.excelDownloaded"),
        threadId,
      );
      appendMessages(threadId, [assistantMessage]);
    } catch (error) {
      console.error("Error generating Excel", error);
      const assistantMessage = createAssistantMessage(
        t("assistant.excelDownloadedError"),
        threadId,
      );
      appendMessages(threadId, [assistantMessage]);
    } finally {
      setGeneratingExcel(false);
    }
  };

  if (!reportData) {
    return <></>;
  }

  return (
    <div className={styles.portfolioMainContainer}>
      <div className={styles.portfolioContainer}>
        <div className={styles.portfolioContainerInner}>
          <div className={styles.portfolioContainerInnerInner}>
            <FinecoPortfolioTitle title={"Portafoglio"} />
            {Object.entries(sectionsConfig).map(
              ([sectionKey, { label, component, showFoldingTitle }]) => {
                let shouldIRender = true;
                if (component.props.children) {
                  let allNull = true;
                  for (let i = 0; i < component.props.children.length; i++) {
                    if (component.props.children[i]) {
                      allNull = false;
                    }
                  }
                  if (allNull) {
                    shouldIRender = false;
                  }
                  if (!shouldIRender) return null;
                }
                return (
                  <div key={sectionKey} className={styles.sectionContainer}>
                    <Section
                      showFoldingTitle={showFoldingTitle}
                      toggleSection={toggleSection}
                      openSections={openSections}
                      reportData={reportData}
                      label={label}
                      section={sectionKey}
                    >
                      <div className={styles.sectionComponentContainer}>
                        {component}
                      </div>
                    </Section>
                  </div>
                );
              },
            )}
          </div>
          <div className={styles.buttonContainer}>
            <FinecoButton
              click={() => {
                const subject = "Fineco Portfolio Analysis Report";
                const body = `Dear Client,

I am pleased to share with you the detailed analysis of your investment portfolio.

Please find attached the complete portfolio report which includes:
- Portfolio Overview
- Asset Allocation Analysis
- Risk and Return Assessment
- Investment Strategy Recommendations

Best regards,
Your Fineco Advisor`;

                window.location.href = `mailto:?subject=${encodeURIComponent(
                  subject,
                )}&body=${encodeURIComponent(body)}`;
              }}
              copy={t("generateReport.sendViaMail").toUpperCase()}
            />
            <FinecoButton
              click={() => {
                setGeneratingDownloadPdf(true);
                generateReportRef.current.generate(false);
              }}
              copy={
                !generatingDownloadPdf
                  ? t("generateReport.download").toUpperCase()
                  : t("generateReport.downloading").toUpperCase()
              }
            />

            <FinecoButton
              click={requestGenerateExcel}
              copy={
                !generatingExcel
                  ? t("actions.generateExcel").toUpperCase()
                  : t("actions.submitting").toUpperCase()
              }
            />
            <GenerateReport
              artifact={{
                financial_plan: artifact.data?.financial_plan,
                risk_profile: artifact.data?.risk_profile,
              }}
              onGenerate={requestGeneratePdf}
              ref={generateReportRef}
            >
              <div className={styles.generateReportButton}>
                {!generatingPdf
                  ? t("generateReport.modify").toUpperCase()
                  : t("generateReport.downloading").toUpperCase()}
              </div>
            </GenerateReport>
            <FinecoButton
              click={() => {
                toast({
                  title: t("global.success"),
                  description: t("assistant.portfolioSaved"),
                });
              }}
              copy={t("actions.saveMyPortfolio").toUpperCase()}
            />
          </div>
        </div>
      </div>
      <div
        id={`lastAdvisorMessageFinecoPortfolio${artifact.portfolio_id}`}
        className={styles.bottom}
      />
    </div>
  );
};
