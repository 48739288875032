import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyLogo } from "shared/components/common/CompanyLogo";
import CheckIcon from "shared/icons/CheckIcon";
import ChevronRight from "shared/icons/ChevronRight";
import { XMarkIcon } from "shared/icons/XMarkIcon";
import { TradingOrder } from "shared/models/trading/TradingOrderModel";
import { CurrencyFormat } from "shared/utils/currency";
import { formatDate } from "shared/utils/date";
import { capitalize } from "shared/utils/utils";
import { OrderDetails } from "./OrderDetails";

type Props = {
  order: TradingOrder;
  clientId?: string;
};

export default function OrderRow({ order, clientId }: Props) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const itemPrice = order.filled_avg_price
    ? order.filled_avg_price
    : order.limit_price
    ? order.limit_price
    : order.limit_price;

  const itemQty =
    order.filled_qty && order.filled_qty > 0
      ? order.filled_qty
      : order.qty && order.qty > 0
      ? order.qty
      : "";

  return (
    <div
      className="cursor-pointer border-b border-sb-gray-100 p-4 hover:bg-sb-gray-100"
      onClick={() => setExpanded((prev) => !prev)}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <CompanyLogo
            symbol={order.symbol}
            className="aspect-square w-12 rounded-full border border-sb-gray-200"
          />
          <div>
            <div>
              {capitalize(order.side)} {itemQty} {order.symbol}
              {!order.notional && itemPrice && itemPrice > 0 && (
                <>
                  {" "}
                  {t("orders.at_price", {
                    price: CurrencyFormat.format(itemPrice),
                  })}
                </>
              )}
            </div>
            <div className="flex items-center gap-2">
              {order.status === "filled" && (
                <CheckIcon className="text-sb-green-1001 h-3 w-3" />
              )}
              {["canceled", "expired", "rejected"].indexOf(order.status) !==
                -1 && <XMarkIcon className="h-3 w-3 text-sb-red-700" />}
              <div className="text-base text-sb-gray-600">
                {t(`trade.orderTypes.options.${order.type}.label`)}
              </div>
            </div>
            <div className="text-base text-sb-gray-600">
              {t("orders.recentHeaders.origin")}
              {" - "}
              {t(`orders.origins.${order.origin}`)}
            </div>
          </div>{" "}
        </div>
        <div className="flex items-center gap-2">
          <div>
            <div>
              {order.status === "filled"
                ? CurrencyFormat.format(
                    (order.filled_avg_price ? order.filled_avg_price : 0) *
                      order.filled_qty,
                  )
                : capitalize(order.status)}
            </div>
            {order.created_at && (
              <div className="text-base text-sb-gray-600">
                {formatDate(order.created_at)}
              </div>
            )}
          </div>
          <ChevronRight
            className={"h-4 w-4 duration-150 " + (expanded ? "rotate-90" : "")}
          />
        </div>
      </div>
      {expanded && <OrderDetails order={order} clientId={clientId} />}
    </div>
  );
}
