import { cn } from "utils/misc";

type RangeType = {
    value: any;
    label: string;
};

type Props = {
    values: RangeType[];
    activeValue: any;
    onClick: (val: any) => void;
};

export const ChartRanges: React.FC<Props> = ({
    activeValue,
    values,
    onClick,
}) => {
    return (
        <>
            <div className="my-4 flex gap-2 text-lg">
                {values.map((item, idx) => {
                    const isActive = activeValue === item.value;
                    return (
                        <div
                            key={"period_" + idx}
                            className={cn(
                                "cursor-pointer rounded-full border px-2 py-1 text-xs font-medium",
                                isActive
                                    ? "border-green-dark bg-green-dark text-gray-500"
                                    : "border-gray-800 bg-black text-gray-500",
                            )}
                            onClick={() => {
                                onClick(item.value);
                            }}
                        >
                            {item.label}
                        </div>
                    );
                })}
            </div>
        </>
    );
};
