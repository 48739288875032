import React from "react";
import styles from "./clientInterests.module.css";
import { ClientDetailType } from "types/crm/ClientTypes";
import { Tooltip } from "./Tooltip";
import { useTranslation } from "react-i18next";

type ClientInterestsProps = {
  clientData: ClientDetailType;
};
export const ClientInterests: React.FC<ClientInterestsProps> = ({
  clientData,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t("crm.interests")}</h3>
      </div>

      <div className={styles.tags}>
        {clientData.interests.map((interest) => (
          <Tooltip key={interest.interest_id} content={interest.description}>
            <span className={styles.tag}>{interest.category}</span>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};
