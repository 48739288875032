import React from "react";
import { ClientDetailType } from "types/crm/ClientTypes";
import { formatCurrency, formatDateOnlyDate, getInitials } from "utils/misc";
import styles from "./clientDetailsDeals.module.css";
import clientDetailsStyles from "./../crmClientDetailsPage.module.css";
import { useNavigate } from "react-router-dom";

type ClientDetailsDealsProps = {
  clientData: ClientDetailType;
};

export const ClientDetailsDeals: React.FC<ClientDetailsDealsProps> = ({
  clientData,
}) => {
  const deals = clientData.deals;
  return (
    <div className={styles.container}>
      <div>
        {deals.length === 0 && <></>}
        {deals.length > 0 && (
          <>
            {deals.map((deal: any) => (
              <DealCard
                key={deal.deal_id}
                title={deal.deal_type}
                description={deal.description}
                status={deal.status}
                value={deal.value}
                type={deal.deal_type}
                participants={deal.participants}
                updatedAt={deal.updated_at}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const DealCard = ({
  // @ts-ignore
  title,
  // @ts-ignore
  description,
  // @ts-ignore
  status,
  // @ts-ignore
  value,
  // @ts-ignore
  type,
  participants = [],
  // @ts-ignore
  updatedAt,
}) => {
  const navigate = useNavigate();
  const callClient = (clientId: string) => {
    navigate(`/crm/clients/${clientId}`);
  };

  // const test = [];
  // for (let i = 0; i < participants.length; i++) {
  //   const partP = participants[i];
  //   for (let i = 0; i < 8; i++) {
  //     test.push(partP);
  //   }
  // }
  const parts: any = [...participants];

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <div className={styles.cardHeader}>
            <h3 className={styles.title}>{title}</h3>
            <div className={styles.cardInfo}>
              <span className={styles.date}>
                {formatDateOnlyDate(updatedAt)}
              </span>
            </div>
            <div className={styles.description}>{description}</div>
            <div className={styles.value}>{formatCurrency(value, "€")}</div>

            <div className={styles.cardInfo}></div>
          </div>
          <div className={styles.cardFooter}>
            <span className={styles.badge}>{status}</span>

            <div className={clientDetailsStyles.participants}>
              {parts.map((participant: any, index: any) => (
                <div key={index} className={clientDetailsStyles.participant}>
                  <div
                    onClick={() => {
                      callClient(participant.client_id);
                    }}
                    className={clientDetailsStyles.participantAvatar}
                  >
                    {getInitials(participant.first_name, participant.last_name)}
                  </div>
                  <div className={clientDetailsStyles.participantTooltip}>
                    {participant.first_name} {participant.last_name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
