import { create } from "./store";
import { AssetInfo } from "shared/models/asset/AssetModel";
import { AssetsService } from "shared/services/assets/AssetsService";

type AssetInfoStoreState = {
  cache: {
    [symbol: string]: {
      info?: AssetInfo;
    };
  };
  selectedIndex?: number;
};

type AssetInfoStoreActions = {
  fetchInfoBySymbol: (symbol: string) => void;
};

const initialData: AssetInfoStoreState = {
  cache: {},
};

const useAssetInfoStore = create<AssetInfoStoreState & AssetInfoStoreActions>()(
  (set, get) => ({
    ...initialData,
    fetchInfoBySymbol: async (symbol) => {
      const cached = get().cache[symbol];
      const info = await AssetsService.getInfo(symbol);

      set({
        cache: {
          ...get().cache,
          [symbol]: { ...cached, info },
        },
      });
    },
  }),
);

export default useAssetInfoStore;
