import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ClientPortfolios } from "modules/client_portfolios/ClientPortfolios";
import { ClientProposals } from "modules/client_proposals/ClientProposals";
import { TotalInvesting } from "modules/total_investing/TotalInvesting";
import { LatestUpdates } from "modules/latest_updates/LatestUpdates";
import { Layouts } from "shared/components/hoc/Layouts";
import useClientsStore from "shared/store/clientsStore";
import useClientDetailsStore from "shared/store/clientDetailsStore";

type Props = {
  clientId: string;
};

export const ClientDashboard: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const client = useClientsStore((state) =>
    state.clients.find((c) => String(c.user_id) === String(clientId)),
  );
  const { fetchPortfolios } = useClientDetailsStore();

  useEffect(() => {
    fetchPortfolios(clientId);
  }, [clientId]);

  if (client && !client.summary.account_name) {
    return (
      <div className="text-xl font-semibold">
        {t("clientDetails.noAccount")}
      </div>
    );
  }
  return (
    <div>
      {clientId ? (
        <Layouts.Spaced>
          <TotalInvesting clientId={clientId} />
          <ClientPortfolios clientId={clientId} />
          <ClientProposals clientId={clientId} />
          <LatestUpdates clientId={clientId} />
        </Layouts.Spaced>
      ) : (
        <div
          style={{ width: 300, height: 300, backgroundColor: "yellow" }}
        ></div>
      )}
    </div>
  );
};
