import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BasePage } from "shared/components/common/BasePage";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { ProposalsTable } from "./ProposalsTable";
import useProposalsStore from "shared/store/proposalsStore";
import useClientsStore from "shared/store/clientsStore";

export const ProposalsPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    fetchProposals: fetchProposalsStore,
    proposals,
    loading,
  } = useProposalsStore();
  const { fetchClients } = useClientsStore();

  useEffect(() => {
    fetchProposalsStore();
    fetchClients();
  }, []);

  const setupInterval = useCallback(() => {
    let fetchProposalsTimeout: NodeJS.Timeout | null = null;

    const fetchProposals = async () => {
      const newProposals = await fetchProposalsStore();
      const isAnyNewBusy = newProposals.find((el) => !el.strategy_type);
      if (isAnyNewBusy) {
        fetchProposalsTimeout = setTimeout(fetchProposals, 5000);
      }
    };

    fetchProposals();

    return () => {
      if (fetchProposalsTimeout) {
        clearTimeout(fetchProposalsTimeout);
      }
    };
  }, []);

  const isAnyBusy = proposals.find((el) => !el.strategy_type) !== undefined;
  useEffect(() => {
    let clearInterval: () => void = () => {};
    if (isAnyBusy) {
      clearInterval = setupInterval();
    }

    return () => clearInterval();
  }, [isAnyBusy, setupInterval]);

  return (
    <BasePage>
      <Labels.H1>{t("proposals.title")}</Labels.H1>
      <Panel className="mt-8">
        <ProposalsTable
          key={"proposals_" + proposals.length}
          proposals={proposals}
          loading={loading}
        />
      </Panel>
    </BasePage>
  );
};
