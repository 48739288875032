import { cn } from "utils";
import { AiIcon } from "./AiIcon";

type Props = {
  className?: string;
};

export default function AiChatGradient({ className }: Props) {
  return (
    <div
      className={cn(
        `size-10 text-card grid aspect-square place-items-center rounded-full bg-gradient-to-t p-2`,
        "bg-green-500 from-[#0ccdc9] to-[#00fd86]",
        className,
      )}
    >
      <AiIcon className="aspect-square" />
    </div>
  );
}
