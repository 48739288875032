import React, { InputHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  options: {
    label: string;
    value: any;
  }[];
  className?: string;
  error?: string;
}

export const OptionsInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { name, label, className, error, options } = props;

  return (
    <div className="relative">
      <select
        id={name}
        name={name}
        inputMode="text"
        className={`peer mb-4 block w-full appearance-none rounded-xl border-0 bg-sb-gray-100 px-2.5 pb-2.5 pt-5 text-base text-gray-900 focus:border-sb-green-700 focus:outline-none focus:ring-0 ${className}`}
      >
        {options.map((o) => {
          return (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          );
        })}
      </select>
      <label
        htmlFor={name}
        className={`absolute left-2.5 top-4  z-10 origin-[0] -translate-y-4 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-sb-green-700`}
      >
        {label && t(label)}
      </label>
      {error && <p className="mb-8 text-sm text-sb-red-600">{error}</p>}
    </div>
  );
};
